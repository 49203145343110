import React, {useEffect, useState, Component} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../ReserveMng.css';
import Http from 'Utils/Axios';
import usePost from 'Utils/EndPoints/usePost';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData';
import CustomModal from 'Utils/Modal/CustomModal';
import CustomConfModal from 'Utils/Modal/CustomConfModal';
import {Form, FormControl, Button, Col, Row, Modal} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import '../../../../Utils/Common/react-datepicker.css';
import {
	MSG_CMP_ADD,
	MSG_CMP_UPD,
	MSG_CMP_DEL,
	MSG_NG_ADD,
	MSG_NG_UPD,
	MSG_NG_DEL,
	MSG_CMP_RES_CAN,
	MSG_NG_RES_CAN,
	APP_TOP,
	MSG_INFO_DATE_INPUT,
	MSG_GUIDE_SHIMEI_STAFF,
	MSG_GUIDE_WARIATE_STAFF,
	MSG_GUIDE_TANTO_STAFF, MSG_NOT_DATA,
	years, months, MSG_SYSTEM_UPD_BACK,
	MSG_GUIDE_USER_NOTE, MSG_GUIDE_STAFF_NOTE
} from "../../../../Utils/Common/ComConst";
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";
import { IoIosCalendar, IoMdTime, IoMdClose, IoMdNuclear } from "react-icons/io";
import {FormInput} from "../../../../Utils/FormHandlers/HandleFormProperties";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import VersionCheck, { GetRequestVersion } from 'Utils/version';
import Auth from 'Utils/Auth';
import InputMask from 'react-input-mask';

const dtNow = new Date();

const defaultState = {
	reserve_id : "",
	user_id : "",
	reserve_name : "",
	reserve_no : "",
	course_id : "",
	shimei_staff_id : "",
	wariate_staff_id : "",
	reserve_date : "",
	course_start_time : "",
	course_end_time : "",
	reserve_note : "",
	staff_note : "",
	reserve_dtime : "",
	reserve_status : "0",
	remind_send_cmp_flg : "0",
	thanks_send_cmp_flg : "0",
	create_id : "",
	update_id : "",
	tanto_staff_id : "",
	mail_flg : "0",
	mode : "",
	id : "",
};

const intervalTime = {
	time : 5,
}

const courseTime ={
	time : 0,
}

const defaultUserInfo = {
	user_nm_sc : "",
	user_kn_sc : "",
	user_tel_sc : "",
	user_email_sc : "",
	info_on : "0",
	info_off : "0",
	id : "",
};

function ReserveMngAddForm({ history, user, data, dbconn, submitReturn, submitReturnDC }) {
	const [fieldData, handleDataFormat, setManualField] = useHandleFormData({ ...defaultState});
	const [defData] = useHandleFormData({ ...defaultState});
	const [fieldUser, handleUserFormat] = useHandleFormData({defaultUserInfo});
	const [dataIntervalTime] = useHandleFormData({ ...intervalTime});
	const [dataCourseTime] = useHandleFormData({ ...courseTime});
	const [course, setCourse] = useState('');
	const [staff, setStaff] = useState('');
	const [seluser, setSelUser] = useState('');
	const [shoptime, setShopTime] = useState('');
	const [reserve, setReserve] = useState('');

	const [username, setUserName] = useState('');
	const [createStaff, setCreateStaff] = useState('');
	const [updateStaff, setUpdateStaff] = useState('');
	const [reserveDate, setReserveDate] = useState(null);
	const [reserveStartTime, setReserveStartTime] = useState(null);
	const [reserveEndTime, setReserveEndTime] = useState(null);

	const [showuser, setShowUser] = useState(false);
	const [showguide, setShowGuide] = useState(false);

	const [result, submitPost, errors, setErrorClear] = usePost();
	const [show, setShow] = useState(false);
	const [showConf, setShowConf] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [modalMsg2, setModalMsg2] = useState('');
	const [modalMsg3, setModalMsg3] = useState('');
	const [errMode, setErrMode] = useState('0');
	const [loading, setLoading] = useState('0');
	const [timeTable, setTimeTable] = useState('');
	const [flgInstantRsv, setFlgInstantRsv] = useState(false);
	const [rsvTypeTxt, setRsvTypeTxt] = useState('');
	const [rsvNameLabel, setRsvNameLabel] = useState('');

	const strPram = fncgSetUrlPram(dbconn);

	let idx = 0;

	/**
	 * 会員選択一覧のクリックイベント設定
	 * @type {{onClick: onClick}}
	 */
	const rowEvents = {
		onClick:(e, row, rowIndex) =>{
			fncSelectUser(row.user_id, row.user_nm);
		}
	};

	/**
	 * 会員選択一覧の列設定
	 * @type {*[]}
	 */
	const columns = [
		{
			dataField: 'user_id',
			text: '',
			headerClasses: 'th-modal-user-no',
			headerAlign: 'center',
			classes: 'td-modal-user-no',
			formatter : function callback(cell, row, rowIndex){
				idx += 1;
				return rowIndex + 1;
			},
			style: function callback(cell, row, rowIndex, colIndex) {
				if(row.user_id == fieldData.user_id){
					return {backgroundColor: '#c5e4e7'};
				}
			},
		},
		{
			dataField: 'user_no',
			text: '会員No',
			headerClasses: 'th-modal-user-kaiin',
			headerAlign: 'center',
			headerStyle:{verticalAlign: 'top'},
			classes: 'td-modal-user-kaiin',
			style: function callback(cell, row, rowIndex, colIndex) {
				if(row.user_id == fieldData.user_id){
					return {backgroundColor: '#c5e4e7'};
				}
			},
			filter: textFilter({
				style:{fontSize: '12px'},
			})
		},
		// {
		// 	dataField: 'user_no',
		// 	text: '会員No',
		// 	headerClasses: 'th-modal-user-kaiin',
		// 	headerAlign: 'center',
		// 	headerStyle:{verticalAlign: 'top'},
		// 	classes: 'td-modal-user-kaiin',
		// 	style: function callback(cell, row, rowIndex, colIndex) {
		// 		if(row.user_id == fieldData.user_id){
		// 			return {backgroundColor: '#c5e4e7'};
		// 		}
		// 	},
		// },
		{
			dataField: 'user_nm',
			text: '会員名',
			headerClasses: 'th-modal-user-nm',
			headerAlign: 'center',
			classes: 'td-modal-user-nm ',
			style: function callback(cell, row, rowIndex, colIndex) {
				if(row.user_id == fieldData.user_id){
					return {backgroundColor: '#c5e4e7'};
				}
			},
			filter: textFilter({
				style:{fontSize: '12px'},
				defaultValue: fieldData.reserve_name != "" ? fieldData.reserve_name : "",
			}),
		},
		{
			dataField: 'user_kn',
			text: '会員カナ',
			headerClasses: 'th-modal-user-kn',
			headerAlign: 'center',
			classes: 'td-modal-user-kn',
			style: function callback(cell, row, rowIndex, colIndex) {
				if(row.user_id == fieldData.user_id){
					return {backgroundColor: '#c5e4e7'};
				}
			},
			filter: textFilter({
				style:{fontSize: '12px'},
			})
		},
		{
			dataField: 'user_tel',
			text: '電話番号',
			headerClasses: 'th-modal-user-tel',
			headerAlign: 'center',
			classes: 'td-modal-user-tel',
			formatter : function callback(cell, row, rowIndex){
				return row.user_tel;
			},
			style: function callback(cell, row, rowIndex, colIndex) {
				if(row.user_id == fieldData.user_id){
					return {backgroundColor: '#c5e4e7'};
				}
			},
			filter: textFilter({
				style:{fontSize: '12px'},
			})
		},
		{
			dataField: 'email',
			text: 'メールアドレス',
			headerClasses: 'th-modal-user-mail',
			headerAlign: 'center',
			classes: 'td-modal-user-mail',
			style: function callback(cell, row, rowIndex, colIndex) {
				if(row.user_id == fieldData.user_id){
					return {backgroundColor: '#c5e4e7'};
				}
			},
			filter: textFilter({
				style:{fontSize: '12px'},
			})
		},
	];

	/**
	 * ページ離脱イベント
	 * @param e
	 */
	window.onbeforeunload = function(e){
		//--- 確認メッセージ表示(トリガーとしてのみ有効、メッセージは内容は反映されない)
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			e.returnValue = '';
		}
	}

	/**
	 * データ取得
	 */
	useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
		if(data != '' && data != undefined){
			setLoading('1');
			data.id = dbconn.id;
			if(data.mode == '1'){
				//- 新規
				Http.post('api/init-reserve', {
					...data
				}).then(response => {
					if (Object.keys(response.data).length > 0) {
						setCourse(response.data.Course);
						setStaff(response.data.Staff);
						setSelUser(response.data.SelUser);
						setShopTime(response.data.ShopTime);
						if ((response.data.TimeTable).length > 0) {
							setTimeTable(response.data.TimeTable);
						}

						//- 初期値設定
						// コース
						if(response.data.Course.length > 0){
							fieldData.course_id = response.data.Course[0].course_id;
							dataCourseTime.time = response.data.Course[0].course_time;
							defData.course_id = response.data.Course[0].course_id;
							fncSetCourseEndTime(response.data.Course[0].course_id, data.start_time, true);
						}
						// スタッフ
						if(data.staff_id == undefined || data.staff_id == ''){
							if(response.data.Staff.length > 0){
								fieldData.shimei_staff_id = response.data.Staff[0].staff_id;
								fieldData.wariate_staff_id = response.data.Staff[0].staff_id;
								fieldData.tanto_staff_id = response.data.Staff[0].staff_id;
								defData.shimei_staff_id = response.data.Staff[0].staff_id;
								defData.wariate_staff_id = response.data.Staff[0].staff_id;
								defData.tanto_staff_id = response.data.Staff[0].staff_id;
							}
						}
						// 会員設定(らっく院連携)
                        if(response.data.User.user_id != ''){
                            fieldData.user_id = response.data.User.user_id;
                            fieldData.user_nm = response.data.User.user_nm;
							defData.user_id = response.data.User.user_id;
							defData.user_nm = response.data.User.user_nm;
							setUserName(response.data.User.user_nm);
                        }
					}
                    setLoading('0');
				});
			}else{
				Http.post('api/get-reserve', {
					...data
				}).then(response => {
					if (Object.keys(response.data).length > 0) {
						setCourse(response.data.Course);
						setStaff(response.data.Staff);
						setSelUser(response.data.SelUser);
						setShopTime(response.data.ShopTime);
						setReserve(response.data.Reserve);
						if ((response.data.TimeTable).length > 0) {
							setTimeTable(response.data.TimeTable);
						}
					}
                    setLoading('0');
				});
			}
		}else{
            history.push(APP_TOP + strPram);
        }

	}, [])

	/**
	 * 予約情報設定
	 */
	useEffect(() => {
		var strDate = dtNow.getFullYear() + '/' + (dtNow.getMonth() + 1) + '/' + dtNow.getDate() + ' ';

		if(reserve != "" && reserve != undefined){
			//--- データ格納
			if(reserve[0].reserve_id != null){
				fieldData.reserve_id = reserve[0].reserve_id;
			}
			if(reserve[0].user_id != null){
				fieldData.user_id = reserve[0].user_id;
				if (reserve[0].user_id != '9999999999' && reserve[0].user_id != '9999999998') {
					// 会員名
					fieldData.user_nm = reserve[0].user_nm;
					setUserName(reserve[0].user_nm);
				} else if (reserve[0].user_id == '9999999999') {
					setFlgInstantRsv(true);
					setRsvTypeTxt('簡易予約');
					setRsvNameLabel('予約者名');
				} else if (reserve[0].user_id == '9999999998') {
					setFlgInstantRsv(true);
					setRsvTypeTxt('枠埋め');
					setRsvNameLabel('表示名称');
				}
			}
			if (reserve[0].reserve_name != null) {
				fieldData.reserve_name = reserve[0].reserve_name;
			}
			if(reserve[0].reserve_no != null){
				fieldData.reserve_no = reserve[0].reserve_no;
			}
			if(reserve[0].course_id != null){
				fieldData.course_id = reserve[0].course_id;
				for(var intIdx in course){
					if(course[intIdx].course_id == fieldData.course_id){
						dataCourseTime.time = course[intIdx].course_time;
						break;
					}
				}
			}
			if(reserve[0].shimei_staff_id != null){
				fieldData.shimei_staff_id = reserve[0].shimei_staff_id;
			}
			if(reserve[0].wariate_staff_id != null){
				fieldData.wariate_staff_id = reserve[0].wariate_staff_id;
			}
			if(reserve[0].reserve_date != null){
				fieldData.reserve_date = reserve[0].reserve_date;
				setReserveDate(new Date(reserve[0].reserve_date));
			}
			if(reserve[0].course_start_time != null){
				fieldData.course_start_time = reserve[0].course_start_time;
				setReserveStartTime(reserve[0].course_start_time);
			}
			if(reserve[0].course_end_time != null){
				fieldData.course_end_time = reserve[0].course_end_time;
				setReserveEndTime(reserve[0].course_end_time);
			}
			if(reserve[0].reserve_note != null){
				fieldData.reserve_note = reserve[0].reserve_note;
			}
			if(reserve[0].staff_note != null){
				fieldData.staff_note = reserve[0].staff_note;
			}
			if(reserve[0].reserve_dtime != null){
				fieldData.reserve_dtime = reserve[0].reserve_dtime;
			}
			if(reserve[0].reserve_status != null){
				fieldData.reserve_status = reserve[0].reserve_status;
			}
			if(reserve[0].remind_send_cmp_flg != null){
				fieldData.remind_send_cmp_flg = reserve[0].remind_send_cmp_flg;
			}
			if(reserve[0].thanks_send_cmp_flg != null){
				fieldData.thanks_send_cmp_flg = reserve[0].thanks_send_cmp_flg;
			}
			if(reserve[0].create_id != null){
				fieldData.create_id = reserve[0].create_id;
				setCreateStaff(reserve[0].staff_nm_create);
			}
			if(reserve[0].update_id != null){
				fieldData.update_id = reserve[0].update_id;
				setUpdateStaff(reserve[0].staff_nm_update);
			}
			if(reserve[0].tanto_staff_id != null){
				fieldData.tanto_staff_id = reserve[0].tanto_staff_id;
			}else{
				if(staff != ""){
					fieldData.tanto_staff_id = staff[0].staff_id;
				}
			}

			defData.user_id = reserve[0].user_id != null ? reserve[0].user_id : '';
			defData.reserve_no = reserve[0].reserve_no != null ? reserve[0].reserve_no : '';
			defData.course_id = reserve[0].course_id != null ? reserve[0].course_id : '';
			defData.shimei_staff_id = reserve[0].shimei_staff_id != null ? reserve[0].shimei_staff_id : '';
			defData.wariate_staff_id = reserve[0].wariate_staff_id != null ? reserve[0].wariate_staff_id : '';
			defData.reserve_date = reserve[0].reserve_date != null ? reserve[0].reserve_date : '';
			defData.course_start_time = reserve[0].course_start_time != null ? reserve[0].course_start_time : '';
			defData.course_end_time = reserve[0].course_end_time != null ? reserve[0].course_end_time : '';
			defData.reserve_note = reserve[0].reserve_note != null ? reserve[0].reserve_note : '';
			defData.staff_note = reserve[0].staff_note != null ? reserve[0].staff_note : '';
			defData.reserve_status = reserve[0].reserve_status != null ? reserve[0].reserve_status : '';
			defData.remind_send_cmp_flg = reserve[0].remind_send_cmp_flg != null ? reserve[0].remind_send_cmp_flg : '';
			defData.thanks_send_cmp_flg = reserve[0].thanks_send_cmp_flg != null ? reserve[0].thanks_send_cmp_flg : '';
			defData.tanto_staff_id = reserve[0].tanto_staff_id != null ? reserve[0].tanto_staff_id : staff != "" ? staff[0].staff_id : '';

		}else if(data != "" && data != undefined){
			//-- 新規登録
			//-- データ格納
			if(data.course_id != undefined){
				fieldData.course_id = data.course_id;
			}
			if(data.staff_id != undefined){
				fieldData.shimei_staff_id = data.staff_id;
				fieldData.wariate_staff_id = data.staff_id;
				fieldData.tanto_staff_id = data.staff_id;
			}
			if(data.reserve_date != undefined && data.reserve_date != ''){
				fieldData.reserve_date = data.reserve_date;
				setReserveDate(new Date(data.reserve_date));
			}
			if(data.start_time != null && data.start_time != ''){
				fieldData.course_start_time = data.start_time;
				setReserveStartTime(data.start_time);
			}

			defData.course_id = data.course_id != null ? data.course_id : '';
			defData.shimei_staff_id = data.staff_id != null ? data.staff_id : '';
			defData.wariate_staff_id = data.staff_id != null ? data.staff_id : '';
			defData.tanto_staff_id = data.staff_id != null ? data.staff_id : '';
			defData.reserve_date = data.reserve_date != null ? data.reserve_date : '';
			defData.course_start_time = data.start_time != null ? data.start_time : '';
		}

	}, [reserve])

	/**
	 * 会員選択一覧設定定期実行
	 */
	function fncIntervalStart(){
		global.interval = setInterval(function(){
			//--- 会員選択一覧設定
			fncFilterSetting();
		}, 500);
	}

	/**
	 * 会員選択一覧設定停止
	 */
	function fncIntervalStop(){
		clearInterval(global.interval);
	}

	/**
	 * 会員選択一覧設定
	 */
	function fncFilterSetting(){
		//--- 検索テキストのコメント削除
		let filCnt = 0;
		// ***20210108 会員番号を検索に追加 start***
		let colUserNo = document.getElementById("text-filter-column-user_no");
		if(colUserNo != undefined){
			colUserNo.placeholder = "";
			filCnt += 1;
		}
		// ***20210108 会員番号を検索に追加 end***
		let colUserNm = document.getElementById("text-filter-column-user_nm");
		if(colUserNm != undefined){
			colUserNm.placeholder = "";
			filCnt += 1;
		}
		var colUserKn = document.getElementById("text-filter-column-user_kn");
		if(colUserKn != undefined){
			colUserKn.placeholder = "";
			filCnt += 1;
		}
		var colUserTel = document.getElementById("text-filter-column-user_tel");
		if(colUserTel != undefined){
			colUserTel.placeholder = "";
			filCnt += 1;
		}
		var colUserMail = document.getElementById("text-filter-column-email");
		if(colUserMail != undefined){
			colUserMail.placeholder = "";
			filCnt += 1;
		}

		//--- 検索一覧のヘッダーインデックス無効
		let tagCnt = 0;
		let thUserNo = document.getElementsByTagName('th')[0];
		if(thUserNo != undefined){
			thUserNo.tabIndex = -1;
			tagCnt += 1;
		}
		var thUserKaiin = document.getElementsByTagName('th')[1];
		if(thUserKaiin != undefined){
			thUserKaiin.tabIndex = -1;
			tagCnt += 1;
		}
		var thUserNm = document.getElementsByTagName('th')[2];
		if(thUserNm != undefined){
			thUserNm.tabIndex = -1;
			tagCnt += 1;
		}
		var thUserKn = document.getElementsByTagName('th')[3];
		if(thUserKn != undefined){
			thUserKn.tabIndex = -1;
			tagCnt += 1;
		}
		var thUserTel = document.getElementsByTagName('th')[4];
		if(thUserTel != undefined){
			thUserTel.tabIndex = -1;
			tagCnt += 1;
		}
		var thUserMail = document.getElementsByTagName('th')[5];
		if(thUserMail != undefined){
			thUserMail.tabIndex = -1;
			tagCnt += 1;
		}

		//--- 全てが完了したか判定
		if(filCnt == 4 &&tagCnt == 6){
			//-- 停止
			fncIntervalStop();
		}
	}

	/**
	 * 予約日が使用期間内に存在するかどうか
	 * @returns {*}
	 */
	const isUseDate = (start, end) => {
		// 予約日が空欄の場合
		if (fieldData.reserve_date === '' || fieldData.reserve_date === null) return true
		// 使用期間が存在しない場合
		if (start === null && end === null) return true
		// 使用期間の開始日が存在しない場合
		if (start === null) return new Date(fieldData.reserve_date) <= new Date(end)
		// 使用期間の終了日が存在しない場合
		if (end === null) return new Date(start) <= new Date(fieldData.reserve_date)
		// 使用期間が両方存在する場合
		return new Date(start) <= new Date(fieldData.reserve_date) && new Date(fieldData.reserve_date) <= new Date(end)
	}

	/**
	 * コースリスト作成
	 * @returns {*}
	 */
	function fncSetCourse(){
		var lstCourse = [];
		if(course == "") return;
		for(var intIdx in course){
			//if (!isUseDate(course[intIdx].use_start_date, course[intIdx].use_end_date)) continue
			lstCourse.push(
				<option value={course[intIdx].course_id} key={course[intIdx].course_id}>
					{course[intIdx].course_nm}
				</option>
			);
			//-- コース時間取得
			if(course[intIdx].course_id == fieldData.course_id){
				dataCourseTime.time = course[intIdx].course_time;
			}
		}
		if(lstCourse.length > 0){
			return(
				<>
					{lstCourse}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * スタッフリスト作成
	 * @returns {*}
	 */
	function fncSetStaff(){
		var lstStaff = [];
		if(staff == "") return;

		for(var intIdx in staff){
			lstStaff.push(
				<option value={staff[intIdx].staff_id} key={staff[intIdx].staff_id}>
					{staff[intIdx].staff_nm}
				</option>
			);
		}
		if(lstStaff.length > 0){
			return(
				<>
					{lstStaff}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * タイムテーブル作成
	 * @returns {*}
	 */
	function fncSetTimeTable(){
		var lstTimeTable = [];
		if(timeTable == "") return;

		//最初に''を追加
		lstTimeTable.push(
			<option value={''} key={''}>
				{''}
			</option>
		);

		for(var intIdx in timeTable){
			lstTimeTable.push(
				<option value={timeTable[intIdx]} key={timeTable[intIdx]}>
					{timeTable[intIdx]}
				</option>
			);
		}
		if(lstTimeTable.length > 0){
			return(
				<>
					{lstTimeTable}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * コース選択
	 */
	function fncChangeCourse(e){
		//--- 選択コース設定
		handleDataFormat(e);

		//--- コース終了時間設定
		let selCourseID = e.currentTarget.value;
		fncSetCourseEndTime(selCourseID, fieldData.course_start_time);
	}

	/**
	 * 会員選択
	 * @param userID
	 * @param userNm
	 */
	function fncSelectUser(userID, userNm){
		setFlgInstantRsv(false);
		fieldData.reserve_name = '';

		fieldData.user_id = userID;
		setUserName(userNm);

		fncModalUserClose();
	}

	/**
	 * コース開始時間変更
	 * @param date
	 */
	function fncChangeStartTime(e){
		setReserveStartTime(e.target.value);
		fieldData.course_start_time = e.target.value;

		//--- コース終了時間設定
		fncSetCourseEndTime(fieldData.course_id, fieldData.course_start_time);
	}

	/**
	 * コース終了時間変更
	 * @param date
	 */
	function fncChangeEndTime(e){
		setReserveEndTime(e.target.value);
		fieldData.course_end_time = e.target.value;
	}

	/**
	 * コース終了時間設定
	 */
	function fncSetCourseEndTime(selCourseID, startTime, init){
		if (fieldData.reserve_date != '' && fieldData.reserve_date != null && fieldData.course_start_time != '' && fieldData.course_start_time != null ) {
			//--- 開始日時作成
			var strSymbol = '-';
			if ((fieldData.reserve_date).indexOf('/') > -1) {
				strSymbol = '/';
			}
			var dateArray = (fieldData.reserve_date).split(strSymbol);
			var timeArray = (fieldData.course_start_time).split(':');
			if (dateArray.length > 0 && timeArray.length > 0) {
				startTime = new Date(dateArray[0],dateArray[1],dateArray[2],timeArray[0],timeArray[1]);
			}

			//--- 開始時間設定
			if(startTime != '' && startTime != null){
				//-- コース時間取得
				for(var intIdx in course){
					if(course[intIdx].course_id == selCourseID){
						dataCourseTime.time = course[intIdx].course_time;
						break;
					}
				}
				//-- コース終了時間設定
				let reserveTime = new Date(startTime);
				reserveTime.setMinutes(startTime.getMinutes() + dataCourseTime.time);
				var reserveTime_hours = reserveTime.getHours();
				var reserveTime_minutes = ('0' + reserveTime.getMinutes()).slice(-2);
				var strRsvTime = reserveTime_hours + ':' + reserveTime_minutes;

				fieldData.course_end_time = strRsvTime;
				if (init) defData.course_end_time = strRsvTime;
				setReserveEndTime(strRsvTime);
			} else {
				fieldData.course_end_time = '';
				if (init) defData.course_end_time = '';
				setReserveEndTime('');
			}
		}
	}

	/**
	 * 会員選択画面オープン
	 */
	function fncOnClickSelUser(){
		setShowUser(true);
		fncIntervalStart();
	}

	/**
	 * 会員選択画面クローズ
	 */
	function fncModalUserClose() {
		setShowUser(false);
		fncIntervalStop();
	}

	/**
	 * ガイド画面表示
	 */
	function fncOnClickShowGuide(){
		setShowGuide(true);
	}

	/**
	 * ガイド画面クローズ
	 */
	function fncModalGuideClose() {
		setShowGuide(false);
	}

	/**
	 * メール送信チェック変更
	 * @param e
	 */
	function fncChangeMail(e){
		if( fieldData.mail_flg != '0' ){
			fieldData.mail_flg = '0'
		}else{
			fieldData.mail_flg = e.target.value;
		}
	}

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		//--- 変更チェック
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			if(window.confirm(MSG_SYSTEM_UPD_BACK)){
				//-- 戻る
				submitReturn();
			}
		}else{
			//-- 戻る
			submitReturn();
		}
	}

	/**
	 * 簡易予約ボタン
	 */
	function fncOnClickInstantRsv(){
		fieldData.user_id = '9999999999';
		setUserName('');
		setFlgInstantRsv(true);
		setRsvTypeTxt('簡易予約');
		setRsvNameLabel('予約者名');
	}

	/**
	 * 枠埋めボタン
	 */
	function fncOnClickFillRsv(){
		fieldData.user_id = '9999999998';
		setUserName('');
		setFlgInstantRsv(true);
		setRsvTypeTxt('枠埋め');
		setRsvNameLabel('表示名称');
		// ステータスを0に設定
		fieldData.reserve_status = "0";
	}

	/**
	 * 変更項目チェック
	 * @returns {string}
	 */
	function fncChkBackData(){
		//--- 削除モードは判定しない
		if(data.mode == '3') return '0';

		//--- データ格納
		//-- 会員
		fieldData.user_id = fieldData.user_id != null ? fieldData.user_id : '';
		defData.user_id = defData.user_id != null ? defData.user_id : '';
		//-- 予約番号
		fieldData.reserve_no  = fieldData.reserve_no != null ? fieldData.reserve_no : '';
		defData.reserve_no = defData.reserve_no != null ? defData.reserve_no : '';
		//-- コース
		fieldData.course_id = fieldData.course_id != null ? fieldData.course_id : '';
		defData.course_id = defData.course_id != null ? defData.course_id : '';
		//-- 指名スタッフ
		fieldData.shimei_staff_id = fieldData.shimei_staff_id != null ? fieldData.shimei_staff_id : '';
		defData.shimei_staff_id = defData.shimei_staff_id != null ? defData.shimei_staff_id : '';
		//-- 割当スタッフ
		fieldData.wariate_staff_id = fieldData.wariate_staff_id != null ? fieldData.wariate_staff_id : '';
		defData.wariate_staff_id = defData.wariate_staff_id != null ? defData.wariate_staff_id : '';
		//-- 予約日
		if(reserveDate != '' && reserveDate != null){
			fieldData.reserve_date = reserveDate.getFullYear() + '/' + ('0' + (reserveDate.getMonth() + 1)).slice(-2) + '/' +  ('0' + reserveDate.getDate()).slice(-2);
		}else{
			fieldData.reserve_date = '';
		}
		defData.reserve_date = defData.reserve_date != null ? defData.reserve_date : '';
		defData.reserve_date = defData.reserve_date.toString().replace('-','/');
		defData.reserve_date = defData.reserve_date.toString().replace('-','/');
		//-- 予約時間
		if(reserveStartTime != '' && reserveStartTime != null){
			fieldData.course_start_time = reserveStartTime;
		}else{
			fieldData.course_start_time = '';
		}
		defData.course_start_time = defData.course_start_time != null ? defData.course_start_time : '';
		if(reserveEndTime != '' && reserveEndTime != null){
			fieldData.course_end_time = reserveEndTime;
		}else{
			fieldData.course_end_time = '';
		}
		defData.course_end_time = defData.course_end_time != null ? defData.course_end_time : '';
		//-- 要望
		fieldData.reserve_note = fieldData.reserve_note != null ? fieldData.reserve_note : '';
		defData.reserve_note  = defData.reserve_note != null ? defData.reserve_note : '';
		//-- 店舗メモ
		fieldData.staff_note = fieldData.staff_note != null ? fieldData.staff_note : '';
		defData.staff_note  = defData.staff_note != null ? defData.staff_note : '';
		//-- ステータス
		fieldData.reserve_status = fieldData.reserve_status != null ? fieldData.reserve_status : '';
		defData.reserve_status = defData.reserve_status != null ? defData.reserve_status : '' ;
		//-- 担当スタッフ
		fieldData.tanto_staff_id = fieldData.tanto_staff_id != null ? fieldData.tanto_staff_id : '';
		defData.tanto_staff_id = defData.tanto_staff_id != null ? defData.tanto_staff_id : '';
		//-- メール送信
		fieldData.mail_flg = fieldData.mail_flg != null ? fieldData.mail_flg : '';
		defData.mail_flg = defData.mail_flg != null ? defData.mail_flg : '';

		//---　予約情報チェック
		if(fieldData.user_id != defData.user_id ||
			fieldData.reserve_no != defData.reserve_no ||
			fieldData.course_id != defData.course_id ||
			fieldData.shimei_staff_id != defData.shimei_staff_id ||
			fieldData.wariate_staff_id != defData.wariate_staff_id ||
			fieldData.reserve_date != defData.reserve_date ||
			fieldData.course_start_time != defData.course_start_time ||
			fieldData.course_end_time != defData.course_end_time ||
			fieldData.reserve_note != defData.reserve_note ||
			fieldData.staff_note != defData.staff_note ||
			fieldData.reserve_status != defData.reserve_status ||
			fieldData.tanto_staff_id != defData.tanto_staff_id ||
			fieldData.mail_flg != defData.mail_flg){
			return '1';
		}
		return '0';
	}

	/**
	 * 更新ボタン
	 */
	function fncOnClickUpdate(e){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		setLoading('1');
		//--- データ格納
		//-- 予約日
		if(reserveDate != '' && reserveDate != null){
			fieldData.reserve_date = reserveDate.getFullYear() + '/' + ('0' + (reserveDate.getMonth() + 1)).slice(-2) + '/' +  ('0' + reserveDate.getDate()).slice(-2);
		}else{
			fieldData.reserve_date = '';
		}
		//-- 予約時間
		if(reserveStartTime != '' && reserveStartTime != null){
			fieldData.course_start_time = reserveStartTime;
		}else{
			fieldData.course_start_time = '';
		}
		if(reserveEndTime != '' && reserveEndTime != null){
			fieldData.course_end_time = reserveEndTime;
		}else{
			fieldData.course_end_time = '';
		}
		//-- 更新者ID
		if(fieldData.create_id == ''){
			fieldData.create_id = user.staff_id;
		}
		fieldData.update_id = user.staff_id;
		//-- モード
		fieldData.mode = data.mode;
		fieldData.id = dbconn.id;
		e.preventDefault();

		//--- チェック
		setErrorClear();
		submitPost('api/chk-reserve',{
			...fieldData,
		});
	}

    /**
     * チェック結果エラー
     */
    useEffect(() => {
    	if(errors == undefined) return;
        if (Object.keys(errors).length > 0) {
			window.scrollTo(0, 0);
			setLoading('0');
        }

    }, [errors])

    /**
     * チェック結果正常
     */
    useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
        if (Object.keys(result).length > 0) {
        	if(result.results.status == 300) {
				setLoading('0');
				setErrMode('1');
				setModalMsg(result.results.message);
				setShow(true);
			}else if(result.results.status == 400){
				//--- コース受付時間,締切回数
				setLoading('0');
				if(result.results.message1 != ''){
					setModalMsg(result.results.message1);
				}
				setShowConf(true);
			}else{
				//--- 登録
				fncSaveReserveInfo();
			}
        }
    }, [result]);

	/**
	 * 予約情報登録
	 */
	function fncSaveReserveInfo(){
		//-- POST
		Http.post('/api/add-reserve', {
			...fieldData,
		}).then(response => {
			const strStatus = response.data.status;
			if(strStatus == '200'){
				setErrMode('0');
				if(data.mode == '1'){
					setModalMsg(MSG_CMP_ADD);
				}else if(data.mode == '2' || data.mode == '4'){
					setModalMsg(MSG_CMP_UPD);
				}else if(data.mode == '3'){
					setModalMsg(MSG_CMP_DEL);
				}else if(data.mode == '5'){
					setModalMsg(MSG_CMP_RES_CAN);
				}
			}else{
				const strError = response.data.error;
				setErrMode('1');
				if(data.mode == '1'){
					setModalMsg(MSG_NG_ADD + strError);
				}else if(data.mode == '2' || data.mode == '4'){
					setModalMsg(MSG_NG_UPD + strError);
				}else if(data.mode == '3'){
					setModalMsg(MSG_NG_DEL + strError);
				}else if(data.mode == '5'){
					setModalMsg(MSG_NG_RES_CAN + strError);
				}
			}
			//setShow(true);
		}).catch(error => {
			if (error.response) {
				setErrMode('1');
				var strErrMng = '';
				if(data.mode == '1'){
					strErrMng = MSG_NG_ADD;
				}else if(data.mode == '2' || data.mode == '4'){
					strErrMng = MSG_NG_UPD;
				}else if(data.mode == '3'){
					strErrMng = MSG_NG_DEL;
				}else if(data.mode == '5'){
					strErrMng = MSG_NG_RES_CAN;
				}
				strErrMng += error.response.data;
				setModalMsg(strErrMng);
				//alert(error.response.data)
			}
		}).finally(() => {
			setLoading('0');
			setShow(true);
		});
	}

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
        setShow(false);
		if(errMode == '0'){
			submitReturnDC ? submitReturnDC() : submitReturn();
		}
	}

	/**
	 * モーダル確認画面OK
	 */
	function fncReturnConfModalOK(){
		//--- モーダルクローズ
		setShowConf(false);

		//--- 次メッセージ表示判定
		if(modalMsg2 != ''){
			//-- 次メッセージ表示
			setLoading('0');
			setModalMsg(modalMsg2);
			setModalMsg2('');
			setShowConf(true);
		}else{
			//-- 予約登録
			fncSaveReserveInfo();
		}
	}

	/**
	 * モーダル確認画面クローズ
	 */
	function fncReturnConfModalClose(){
		//--- モーダルクローズ
		setShowConf(false);
	}

	/**
	 * 予約日カーソル移動
	 */
	function fncClickSetReserveDate(){
		document.getElementById('dtReserveDate').focus();
	}

	/**
	 * 開始時間カーソル移動
	 */
	function fncClickSetStartTime(){
		document.getElementById('dtStartTime').focus();
	}

	/**
	 * 終了時間カーソル移動
	 */
	function fncClickSetEndTime(){
		document.getElementById('dtEndTime').focus();
	}

	/**
	 * 	予約状態によってメール種別の表示
	 */
	const mailFlgText = document.getElementById('mail-flg-text');
	const changeMail = "会員に予約変更メールを送信する";
	const cancelMail = "会員に予約キャンセルメールを送信する";
	const followMail = "会員にフォローメールを送信する";

	// 初期表示設定
	if (reserve !== "" && reserve !== undefined) {
		if (fieldData.reserve_status === "0") {mailFlgText.innerText = changeMail}
		if (fieldData.reserve_status === "1" || fieldData.reserve_status === "4") {mailFlgText.innerText = followMail}
		if (fieldData.reserve_status === "2" || fieldData.reserve_status === "3") {mailFlgText.innerText = cancelMail}
	}

	const changeMailFlgText = (e) => {
		if (e.target.value === '0') {mailFlgText.innerHTML = changeMail}
		if (e.target.value === "1" || e.target.value === "4") {mailFlgText.innerText = followMail}
		if (e.target.value === "2" || e.target.value === "3") {mailFlgText.innerText = cancelMail}
	}

	/**
	 * 予約日変更時に予約時間間隔を取得する
	 */
	function fncGetTimetable(date) {
		if (date == null || date == '') {
			setReserveDate('');
			return;
		} else {
			if (isNaN(date.getTime())) return;
			//--- 日付取得
			var strCalendarDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date .getDate()).slice(-2);
			setReserveDate(date);
		}
		fieldData.reserve_date = strCalendarDate;
		var params = {
			id: dbconn.id,
			reserve_date: strCalendarDate,
			course_id: fieldData.course_id,
			ApplicationVersion: GetRequestVersion()
		};
		var strURL = process.env.REACT_APP_API_URL + '/api/get-time-table';
		let token = Auth.GetAccessToken();
		let errStatus = 0;
		setLoading('1');
		fetch(strURL,　{
			method: 'POST',
			mode: 'cors',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(params)
		})
			.then(response => {
				if (!response.ok) errStatus = response.status;
				return response.json();
			})
			.then(rs => {
				if (errStatus != 0)  {
					// バージョンチェック
					// ここだけ"何故か"axiosでなくfetch利用なので専用の前処理を挟みます。
					let _r = {
						status: errStatus,
						data: {
							message: rs.message,
							version: rs.version,
							href: rs.href
						}
					};
					VersionCheck(_r);
				}

				setCourse(rs.Course);
				setStaff(rs.Staff);

				//予約時間タイムテーブル
				if (rs.TimeTable.length > 0) {
					setTimeTable(rs.TimeTable);
				}
				
				// コース
				if(!rs.Course.some(a => a.course_id == fieldData.course_id)){
					// もとのコースが使用できない場合は初期化する
					fieldData.course_id = rs.Course[0].course_id;
					dataCourseTime.time = rs.Course[0].course_time;
					defData.course_id = rs.Course[0].course_id;
					fncSetCourseEndTime(rs.Course[0].course_id, data.start_time);
				}
				// 指名スタッフ
				if(!rs.Staff.some(a => a.staff_id == fieldData.shimei_staff_id)){
					// もとのスタッフが使用できない場合は初期化する
					fieldData.shimei_staff_id = rs.Staff[0].staff_id;
					defData.shimei_staff_id = rs.Staff[0].staff_id;
				}
				// 割当スタッフ
				if(!rs.Staff.some(a => a.staff_id == fieldData.wariate_staff_id)){
					// もとのスタッフが使用できない場合は初期化する
					fieldData.wariate_staff_id = rs.Staff[0].staff_id;
					defData.wariate_staff_id = rs.Staff[0].staff_id;
				}
				// 担当スタッフ
				if(!rs.Staff.some(a => a.staff_id == fieldData.tanto_staff_id)){
					// もとのスタッフが使用できない場合は初期化する
					fieldData.tanto_staff_id = rs.Staff[0].staff_id;
					defData.tanto_staff_id = rs.Staff[0].staff_id;
				}
			})
			.finally(() => {
				setLoading('0');
			});
	}

	return (
		<>
			<Form className="reserve-mng-form" >
				<div>
					<Row className="reserve-mng-form-row">
						<Col md={{ span: 1}}>
							<Button
								block
								className="btn-guide"
								onClick={fncOnClickShowGuide}
								disabled={loading == '1'? 'disabled' : ''}
							>
								ガイド
							</Button>
						</Col>
						<Col md={{ span: 1, offset: 10}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<Row className="reserve-mng-form-row">
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>予約番号</label>
					</div>
					<div className={"div-reserve-mng-save-area"}>
						<FormControl
							className="div-reserve-mng-save-input"
							type="text"
							value={fieldData.reserve_no}
							name="reserve_no"
							readOnly="readOnly"
						/>
					</div>
				</Row>
				<Row className="reserve-mng-form-row">
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>会員名<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<div className={"div-reserve-mng-save-kaiin"}>
						<FormControl
							className="div-reserve-mng-save-input"
							type="text"
							value={username}
							name="user_nm"
							placeholder="会員を選択してください"
							readOnly="readOnly"
						/>
						<div className={"div-reserve-mng-save-search"}>
							<Button
								block
								className="btn-sel"
								onClick={fncOnClickSelUser}
								disabled={loading == '1'? 'disabled' : (data.mode == '1' || data.mode == '2')? '' : 'disabled'}
							>
								検 索
							</Button>
						</div>
					</div>
					<div className={"div-reserve-mng-save-rsvName"}>
						<div className={"div-reserve-mng-save-search"}>
							<Button
								block
								className="btn-instantRsv"
								onClick={fncOnClickInstantRsv}
								disabled={loading == '1'? 'disabled' : (data.mode == '1' || data.mode == '2')? '' : 'disabled'}
							>
								簡易予約
							</Button>
						</div>
						<div className={"div-reserve-mng-save-search"}>
							<Button
								block
								className="btn-fillRsv"
								onClick={fncOnClickFillRsv}
								disabled={loading == '1'? 'disabled' : (data.mode == '1' || data.mode == '2')? '' : 'disabled'}
							>
								枠埋め
							</Button>
						</div>
					</div>
				</Row>
				{(errors.user_id != undefined) && (
					<Row>
						<div className={"div-reserve-mng-save-title"}></div>
						<div className={"div-reserve-mng-save-kaiin"}>
							<span className="error-span">
								{errors.user_id[0]}
							</span>
						</div>
					</Row>
				) }
				{(flgInstantRsv) && (
					<div>
						<Row>
							<div className={"div-reserve-mng-save-title"}></div>
							<div className={"div-reserve-mng-save-title-rsvNameArea"}>
								{rsvTypeTxt}
							</div>
						</Row>
						<Row>
							<div className={"div-reserve-mng-save-title"}></div>
							<div className={"div-reserve-mng-save-title-rsvNameArea"}>
								<label className={"label-reserve-mng-padding"}>{rsvNameLabel}</label>
							</div>
							<div className={"div-reserve-mng-save-rsvName-input"}>
								<textarea
									className=""
									rows="1"
									value={fieldData.reserve_name}
									onChange={handleDataFormat}
									name="reserve_name"
									readOnly={(data.mode == '1' || data.mode == '2')? '' : 'readOnly'}
								/>
							</div>
						</Row>
						{(errors.reserve_name) && (
							<Row>
								<div className={"div-reserve-mng-save-title"}></div>
								<div className={"div-reserve-mng-save-title-rsvNameArea"}></div>
									<span className="error-span">
										{errors.reserve_name? errors.reserve_name[0] : ''}
									</span>
							</Row>
						) }
					</div>
				)
				}
				<Row className="reserve-mng-form-row">
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>コース名</label>
					</div>
					<div className={"div-reserve-mng-save-area"}>
						<Form.Control
							as="select"
							className="div-reserve-mng-save-select"
							name='course_id'
							value={fieldData.course_id}
							onChange={fncChangeCourse}
							onClick={fncChangeCourse}
							disabled={(data.mode == '1' || data.mode == '2')? '' : 'disabled'}
						>
							{fncSetCourse()}
						</Form.Control>
					</div>
				</Row>
				{errors.course_id && (
					<Row>
						<div className={"div-reserve-mng-save-title"}></div>
						<div className={"div-reserve-mng-save-area"}>
							<span className="error-span">
								{errors.course_id[0]}
							</span>
						</div>
					</Row>
				) }
				<Row className="reserve-mng-form-row">
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>指名スタッフ</label>
					</div>
					<div className={"div-reserve-mng-save-area"}>
						<Form.Control
							as="select"
							className="div-reserve-mng-save-select"
							name='shimei_staff_id'
							value={fieldData.shimei_staff_id}
							onChange={handleDataFormat}
							disabled={(data.mode == '1' || data.mode == '2')? '' : 'disabled'}
						>
							{fncSetStaff()}
						</Form.Control>
					</div>
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>割当スタッフ</label>
					</div>
					<div className={"div-reserve-mng-save-area"}>
						<Form.Control
							as="select"
							className="div-reserve-mng-save-select"
							name='wariate_staff_id'
							value={fieldData.wariate_staff_id}
							onChange={handleDataFormat}
							disabled={(data.mode == '1' || data.mode == '2')? '' : 'disabled'}
						>
							{fncSetStaff()}
						</Form.Control>
					</div>
				</Row>
				{(errors.shimei_staff_id || errors.wariate_staff_id) && (
					<Row>
						<div className={"div-reserve-mng-save-title"}></div>
						<div className={"div-reserve-mng-save-area"}>
							<span className="error-span">
								{errors.shimei_staff_id? errors.shimei_staff_id[0] : ''}
							</span>
						</div>
						<div className={"div-reserve-mng-save-title"}></div>
						<div className={"div-reserve-mng-save-area"}>
							<span className="error-span">
								{errors.wariate_staff_id? errors.wariate_staff_id[0] : ''}
							</span>
						</div>
					</Row>
				) }
				<Row className="user-mng-form-row">
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>予約日<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<div className={"div-reserve-mng-save-yoyaku"}>
						<div className={"div-date-picker-width"}>
							<DatePicker
								className="date-picker"
								showPopperArrow={false}
								selected={reserveDate}
								onChange={fncGetTimetable}
								dateFormat="yyyy/MM/dd"
								customInput={<InputMask mask="9999/99/99" maskChar={null} />}
								disabled={(data.mode == '1' || data.mode == '2')? '' : 'disabled'}
								id={"dtReserveDate"}
								renderCustomHeader={({
														 date,
														 changeYear,
														 changeMonth,
													 }) =>(
									<div
										className={"div-date-picker"}
									>
										<select
											className={"select-date-picker"}
											value={date.getFullYear()}
											onChange={({target:{value}}) => changeYear(value)}
										>
											{years.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
										<select
											className={"select-date-picker"}
											value={months[date.getMonth()]}
											onChange={({ target: { value } }) =>
												changeMonth(months.indexOf(value))
											}
										>
											{months.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
									</div>
								)}
							/>
						</div>
						<div className={"div-calendar-icon"}>
							<a onClick={fncClickSetReserveDate}>
								<IoIosCalendar/>
							</a>
						</div>
					</div>
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>予約時間<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<div className={"div-reserve-mng-save-time"} style={{width: "75px"}}>
						<label className={"div-reserve-mng-save-time-lavel"} >
							{reserveStartTime}
						</label>
					</div>
					<div className={"div-reserve-mng-save-time"} style={{width: "30px"}}>
						<Form.Control
							as="select"
							className="div-reserve-mng-save-select"
							name='course_start_time'
							value={fieldData.course_start_time}
							onChange={fncChangeStartTime}
							//onClick={fncChangeStartTime}
							style={{width: "30px", textAlign: "center", borderRadius: "0rem .25rem .25rem 0rem"}}
							disabled={(data.mode == '1' || data.mode == '2')? '' : 'disabled'}
						>
							{fncSetTimeTable()}
						</Form.Control>
					</div>
					<div className={"div-reserve-mng-save-time-space"}>
						<label  className={"label-reserve-mng-padding"}>～</label>
					</div>
					<div className={"div-reserve-mng-save-time"} style={{width: "75px"}}>
						<label className={"div-reserve-mng-save-time-lavel"} >
							{reserveEndTime}
						</label>
					</div>
					<div className={"div-reserve-mng-save-time"}>
						<Form.Control
							as="select"
							className="div-reserve-mng-save-select"
							name='course_end_time'
							value={fieldData.course_end_time}
							onChange={fncChangeEndTime}
							style={{width: "30px", textAlign: "center", borderRadius: "0rem .25rem .25rem 0rem"}}
							disabled={(data.mode == '1' || data.mode == '2')? '' : 'disabled'}
						>
							{fncSetTimeTable()}
						</Form.Control>
					</div>

				</Row>
				{(errors.reserve_date || errors.course_start_time || errors.course_end_time) && (
					<Row>
						<div className={"div-reserve-mng-save-title"}></div>
						<div className={"div-reserve-mng-save-yoyaku"}>
							<span className="error-span">
								{errors.reserve_date? errors.reserve_date[0] : ''}
							</span>
						</div>
						<div className={"div-reserve-mng-save-title"}></div>
						<div className={"div-reserve-mng-save-yoyaku"}>
							<span className="error-span">
								{errors.course_start_time ? errors.course_start_time[0] :
									errors.course_end_time ? errors.course_end_time[0] : ''}
							</span>
						</div>
					</Row>
				) }
				<Row className="reserve-mng-form-row-note">
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>会員要望</label>
					</div>
					<div className={"div-reserve-mng-save-note"}>
						<textarea
							className=""
							rows="4"
							value={fieldData.reserve_note}
							name="reserve_note"
							disabled='disabled'
						/>
					</div>
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>店舗メモ</label>
					</div>
					<div className={"div-reserve-mng-save-note"}>
						{data.mode == '1' || data.mode == '2'
							? <textarea
								className=""
								rows="4"
								value={fieldData.staff_note}
								onChange={handleDataFormat}
								name="staff_note"
							/>
							: <textarea
								className=""
								rows="4"
								value={fieldData.staff_note}
								name="staff_note"
								disabled='disabled'
							/>}
					</div>
				</Row>
				{(errors.reserve_note || errors.staff_note) && (
					<Row>
						<div className={"div-reserve-mng-save-title"}></div>
						<div className={"div-reserve-mng-save-yoyaku"}>
							<span className="error-span">
								{errors.reserve_note? errors.reserve_note[0] : ''}
							</span>
						</div>
						<div className={"div-reserve-mng-save-title"}></div>
						<div className={"div-reserve-mng-save-yoyaku"}>
							<span className="error-span">
								{errors.staff_note? errors.staff_note[0] : ''}
							</span>
						</div>
					</Row>
				) }
				<Row className="reserve-mng-form-row">
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>担当スタッフ</label>
					</div>
					<div className={"div-reserve-mng-save-area"}>
						<Form.Control
							as="select"
							className="div-reserve-mng-save-select"
							name='tanto_staff_id'
							value={fieldData.tanto_staff_id}
							onChange={handleDataFormat}
							disabled={(data.mode == '1' || data.mode == '2')? '' : 'disabled'}
						>
							{fncSetStaff()}
						</Form.Control>
					</div>
				</Row>

				<Row className="reserve-mng-form-row">
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>状態</label>
					</div>
					<div className={"div-reserve-mng-save-status"}>
						<label className={"label-reserve-mng-padding"}>
							<input
								type="radio"
								className="radio-reserve-mng"
								name="reserve_status"
								value="0"
								onChange={handleDataFormat}
								onClick={changeMailFlgText}
								checked={fieldData.reserve_status === "0"}
								disabled={(data.mode == '1' || data.mode == '2')? '' : 'disabled'}
							/> 予約中
						</label>
					</div>
					<div className={"div-reserve-mng-save-status"}>
						<label className={"label-reserve-mng-padding"}>
							<input
								type="radio"
								className="radio-reserve-mng"
								name="reserve_status"
								value="1"
								onChange={handleDataFormat}
								onClick={changeMailFlgText}
								checked={fieldData.reserve_status === "1"}
								disabled={((data.mode == '1' || data.mode == '2') && (fieldData.user_id != '9999999998' ))? '' : 'disabled'}
							/> 来院
						</label>
					</div>
					<div className={"div-reserve-mng-save-status"}>
						<label className={"label-reserve-mng-padding"}>
							<input
								type="radio"
								className="radio-reserve-mng"
								name="reserve_status"
								value="2"
								onChange={handleDataFormat}
								onClick={changeMailFlgText}
								checked={fieldData.reserve_status === "2"}
								disabled={(data.mode == '1' || data.mode == '2')? '' : 'disabled'}
							/> キャンセル
						</label>
					</div>
					<div className={"div-reserve-mng-save-status"}>
						<label className={"label-reserve-mng-padding"}>
							<input
								type="radio"
								className="radio-reserve-mng"
								name="reserve_status"
								value="3"
								onChange={handleDataFormat}
								onClick={changeMailFlgText}
								checked={fieldData.reserve_status === "3"}
								disabled={((data.mode == '1' || data.mode == '2') && (fieldData.user_id != '9999999998' ))? '' : 'disabled'}
							/> 未来院
						</label>
					</div>
					<div className={"div-reserve-mng-save-status"}>
						<label className={"label-reserve-mng-padding"}>
							<input
								type="radio"
								className="radio-reserve-mng"
								name="reserve_status"
								value="4"
								onChange={handleDataFormat}
								onClick={changeMailFlgText}
								checked={fieldData.reserve_status === "4"}
								disabled={((data.mode == '1' || data.mode == '2') && (fieldData.user_id != '9999999998' ))? '' : 'disabled'}
							/> 遅刻
						</label>
					</div>
				</Row>
				<Row className="reserve-mng-form-row">
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>予約受付日時</label>
					</div>
					<Col md={{ span: 4}}>
						<label className={"label-reserve-mng-padding"}>{fieldData.reserve_dtime}</label>
					</Col>
				</Row>
				<Row className="reserve-mng-form-row">
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>登録スタッフ</label>
					</div>
					<div className={"div-reserve-mng-save-staff"}>
						<label className={"label-reserve-mng-padding"}>{createStaff}</label>
					</div>
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>更新スタッフ</label>
					</div>
					<div className={"div-reserve-mng-save-staff"}>
						<label className={"label-reserve-mng-padding"}>{updateStaff}</label>
					</div>
				</Row>
				<Row className="reserve-mng-form-row">
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>リマインド</label>
					</div>
					<div className={"div-reserve-mng-save-send"}>
						<label className={"label-reserve-mng-padding"}>
							{fieldData.remind_send_cmp_flg == '0' ? '未送信'
								:
								fieldData.remind_send_cmp_flg == '1' ? '送信済み'
									:
									fieldData.remind_send_cmp_flg == '2' ? '送信対象外' : 'メールアドレスなし'}
						</label>
					</div>
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>サンクスメール</label>
					</div>
					<div className={"div-reserve-mng-save-send"}>
						<label className={"label-reserve-mng-padding"}>
							{fieldData.thanks_send_cmp_flg == '0' ? '未送信'
								:
								fieldData.thanks_send_cmp_flg == '1' ? '送信済み'
									:
									fieldData.thanks_send_cmp_flg == '2' ? '送信対象外' : 'メールアドレスなし'}
						</label>
					</div>
				</Row>
				<Row className="reserve-mng-form-row">
					<div className={"div-reserve-mng-save-title"}>
						<label className={"label-reserve-mng-padding"}>予約方法</label>
					</div>
					<div className={"div-reserve-mng-save-staff"}>
						<label className={"label-reserve-mng-padding"}>
							{data.mode == '1' ? '院内予約' :
								createStaff == null ? 'ネット予約' :
								createStaff == '' ? 'ネット予約' : '院内予約'}
						</label>
					</div>
				</Row>
				<Row className="reserve-mng-form-row">
					<Col md={{ span: 8 }}>
						<div style={{paddingLeft: "45px"}}>
							<input
								className=""
								type="checkbox"
								value="1"
								defaultChecked={fieldData.mail_flg == '1'? 'checked' : ''}
								onChange={fncChangeMail}
								name="mail_flg"
								id="mail_flg"
								disabled={flgInstantRsv? 'disabled' : ''}
							/>
							<label className={"label-reserve-mng-save-send pl-1"} htmlFor="mail_flg" id="mail-flg-text">
								会員に予約受付メールを送信する
							</label>
						</div>
					</Col>
					<Col md={{ span: 2, offset: 1  }}>
						<Button
							block
							className="btn-save"
							onClick={fncOnClickUpdate}
							disabled={loading == '1'? 'disabled' : ''}
						>
							{data.mode == '1'? '登 録' :
								data.mode == '2'? '更 新' :
									data.mode == '3'? '削 除' :
										data.mode == '4'? '変 更' : 'キャンセル'}
						</Button>
					</Col>
					<Col md={{ span: 1 }}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
				</Row>
			</Form>

			<Modal show={showuser} onHide={fncModalUserClose} >
				<Modal.Header closeButton>
					<Modal.Title>会員選択</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="reserve-mng-value">
						<Col md={{ span: 12}}>
							<BootstrapTable
								classes={'modal-user-list'}
								headerWrapperClasses={'modal-user-scrollHead'}
								bodyClasses={'modal-user-scrollBody'}
								rowClasses={'row-modal-user'}
								keyField={idx.toString()}
								data={ seluser }
								columns={columns}
								rowEvents={rowEvents}
								hover={true}
								filter={ filterFactory() }

							/>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						block
						className="btn-back"
						onClick={fncModalUserClose}
					>
						戻 る
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showguide} onHide={fncModalGuideClose} >
				<Modal.Header closeButton>
					<Modal.Title>ガイド</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="reserve-mng-value">
						<Col md={{ span: 12}}>
							<label>{MSG_INFO_DATE_INPUT}</label>
						</Col>
					</Row>
					<Row className="reserve-mng-value">
						<Col md={{ span: 3}}>
							<label>※指名スタッフ：</label>
						</Col>
						<Col md={{ span: 9}}>
							<label>{MSG_GUIDE_SHIMEI_STAFF}</label>
						</Col>
					</Row>
					<Row className="reserve-mng-value">
						<Col md={{ span: 3}}>
							<label>※割当スタッフ：</label>
						</Col>
						<Col md={{ span: 9}}>
							<label>{MSG_GUIDE_WARIATE_STAFF}</label>
						</Col>
					</Row>
					<Row className="reserve-mng-value">
						<Col md={{ span: 3}}>
							<label>※担当スタッフ：</label>
						</Col>
						<Col md={{ span: 9}}>
							<label>{MSG_GUIDE_TANTO_STAFF}</label>
						</Col>
					</Row>
					<Row className="reserve-mng-value">
						<Col md={{ span: 3}}>
							<label>※会員要望：</label>
						</Col>
						<Col md={{ span: 9}}>
							<span>{MSG_GUIDE_USER_NOTE}</span>
						</Col>
					</Row>
					<Row className="reserve-mng-value">
						<Col md={{ span: 3}}>
							<label>※店舗メモ：</label>
						</Col>
						<Col md={{ span: 9}}>
							<span>{MSG_GUIDE_STAFF_NOTE}</span>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						block
						className="btn-back"
						onClick={fncModalGuideClose}
					>
						閉じる
					</Button>
				</Modal.Footer>
			</Modal>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>

			<CustomConfModal
				show={showConf}
				header={'メッセージ'}
				message={modalMsg}
				handleOK ={fncReturnConfModalOK}
				handleClose={fncReturnConfModalClose}
			/>


		</>
	);

}

export default ReserveMngAddForm;