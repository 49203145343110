import React, {useEffect, useState} from 'react';

import './UserMng.css';
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import UserMngListForm from "./UseMngList/UserMngListForm";
import UserMngAddForm from "./UseMngAdd/UserMngAddForm";
import {fncgGetUserLoginCheck, fncgGetDbconn} from "Utils/Common/ComCom";
import {DISP_FG, PRM_ID, SEARCH_ID} from "../../../Utils/Common/ComConst";
import {useLocation} from "react-router-dom";

let dispFlg = '0';

function UserMng({ match, history, location }) {
	const [user, setUser] = useState('');
	const [shop, setShop] = useState('');
	const [data, setData] = useState('');
	const [disp, setDisp] = useState('0');
	const [urlpram, setUrlPram] = useState('');
	const [dbconn, setDbConn] = useState('');
	const [start, setStart] = useState('0');
	const path = useLocation().pathname;
	const handleSetUser = userData => setUser(userData);
	const handleSetUrlPram = dat => setUrlPram(dat);
	const handleSetDbConn = dat => setDbConn(dat);
	const handleSetStart = dat => setStart(dat);

	/**
	 * 初期処理
	 */
	useEffect(() => {
		//-- 画面情報
		const dispFg = localStorage.getItem(DISP_FG);
		if(dispFg != '' && dispFg != undefined){
			dispFlg = dispFg;
		}else{
			dispFlg = '0';
		}
		let searchID = localStorage.getItem(SEARCH_ID);
		if(searchID != '' && searchID != undefined){
			searchID = JSON.parse(searchID);
			setData(searchID);
		}

		fncgGetDbconn({history, location, handleSetUrlPram, handleSetDbConn, handleSetStart});
	}, [])

	/**
	 * 店舗情報取得
	 */
	useEffect(() => {
		if(start == '0' || dbconn.id == '' || dbconn.id == undefined) return;
		let aryData = {id : dbconn.id};
		Http.post('api/get-shop',{...aryData}).then(response => {
			if(response.data.status == 404){
				history.push('/not-found' + urlpram);
			}else{
				setShop(response.data.Shop);
			}
		});
	}, [start])

	/**
	 * スタッフ情報取得
	 */
	useEffect(() => {
		if(start == '0') return;
		// ログインチェックを行いユーザ情報を返却する
		fncgGetUserLoginCheck({dbconn, urlpram, path, user, handleSetUser, history})
	}, [shop])

	/**
	 * 登録画面
	 * @param data
	 */
	const onSubmitMoveAddDisp = data => {
		setDisp('1');
		setData(data);
		dispFlg = '1';
		localStorage.setItem(DISP_FG, '1');
		window.scrollTo(0, 0);
	}

	/**
	 * 一覧画面表示
	 * @param data
	 */
	const onSubmitMoveListDisp = data => {
		dispFlg = '0';
		setDisp('0');
		setData(data);
		localStorage.setItem(DISP_FG, '0');
		window.scrollTo(0, 0);
	}

	return (
		<>
			{(dispFlg == '0' && shop != "" && shop != undefined && user != '' && user != undefined) &&(
				<Layout headerText="会員管理 -会員一覧-" _dbconn={dbconn} _user={user}>
					<UserMngListForm
						history={history}
						dbconn={dbconn}
						submitMove={onSubmitMoveAddDisp}
					/>
				</Layout >
			)}

			{(dispFlg == '1' && shop != "" && shop != undefined && user != '' && user != undefined) &&(
				<Layout headerText="会員管理 -会員登録-" _dbconn={dbconn} _user={user}>
					<UserMngAddForm
						history={history}
						data={data}
						staff={user}
						dbconn={dbconn}
						submitReturn={onSubmitMoveListDisp}
					/>
				</Layout>
			)}

		</>
	);

}

export default UserMng;