import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../StaffMng.css';
import Http from '../../../../Utils/Axios';
import usePost from '../../../../Utils/EndPoints/usePost';
import useHandleFormData from '../../../../Utils/FormHandlers/useHandleFormData';
import CustomModal from '../../../../Utils/Modal/CustomModal';
import CustomDatePicker from '../../../../Utils/FormHandlers/DatePicker';
import { FormInput } from '../../../../Utils/FormHandlers/HandleFormProperties';
import { Form, FormControl, Button, Col, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import '../../../../Utils/Common/react-datepicker.css';
import {
	MSG_CMP_ADD,
	MSG_CMP_UPD,
	MSG_CMP_DEL,
	MSG_NG_ADD,
	MSG_NG_UPD,
	MSG_NG_DEL,
	MSG_NG_TIME, MSG_INFO_DATE_INPUT,
	years, months, pwyears, MSG_SYSTEM_UPD_BACK
} from "../../../../Utils/Common/ComConst";
import * as AutoKana from 'vanilla-autokana';
import { IoIosCalendar, IoMdTime } from "react-icons/io";
import WeekSet from "./StaffMngAddFromWeekSet";
import InputMask from 'react-input-mask';

const dtNow = new Date();

const defaultState = {
	staff_id : "",
	staff_nm : "",
	staff_kn : "",
	staff_disp_nm : "",
	staff_tel : "",
	staff_sex : "0",
	birth_date : {
		'day' : '01',
		'month' : '01',
		'year' : dtNow.getFullYear() - 30,
	},
	staff_birthday_year : "",
	staff_birthday_month : "",
	staff_birthday_day : "",
	staff_mail : "",
	zaiseki_start_date : "",
	zaiseki_end_date : "",
	email : "",
	login_pass : "",
	kengen_kbn : "1",
	limit_date : "",
	work_start_time1 : "",
	work_end_time1 : "",
	work_start_time2 : "",
	work_end_time2 : "",
	work_start_time3 : "",
	work_end_time3 : "",
	work_start_time4 : "",
	work_end_time4 : "",
	rec_mail_kbn : "0",
	disp_no : "",
	disp_flg : "1",
	update_id : "",
	mode : "",
	id : "",
	birthday : "",
};

let autokana;
let strStartDate = '';
let strLimitDate = '';

const defaultWeekTime = [
	{staff_id : "", week_kbn : "0",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "1",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "2",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "3",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "4",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "5",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "6",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "7",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
];

const chkWeekTime = [
	{staff_id : "", week_kbn : "0",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "1",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "2",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "3",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "4",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "5",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "6",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
	{staff_id : "", week_kbn : "7",
		work_start_time1 : "", work_end_time1 : "", work_start_time2 : "", work_end_time2 : "",
		work_start_time3 : "", work_end_time3 : "", work_start_time4 : "", work_end_time4 : "", update_id : ""},
];

function StaffMngAddForm({ history, user, data, dbconn, submitReturn }) {
	const [fieldData, handleDataFormat, setManualField] = useHandleFormData({ ...defaultState});
	const fieldWeekTime = defaultWeekTime;

	const [kengen, setKengen] = useState('');
	const [staffcourse, setStaffCourse] = useState('');
	const [staffreceive, setStaffReceive] = useState('');
	const [basetime, setBaseTime] = useState('');
	const [baseweek, setBaseWeek] = useState('');
	const [initweek, setInitWeek] = useState('');
	const [weektime, setWeekTime] = useState('');
	const [staff, setStaff] = useState('');
	const [dispno, setDispNo] = useState('');

	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [result, submitPost, errors, setErrorClear] = usePost();
	const [errMode, setErrMode] = useState('0');

	const [defData] = useHandleFormData({ ...defaultState});
	const defWeekTime = chkWeekTime;
	const [defstaffcourse, setDefStaffCourse] = useState('');
	const [defstaffreceive, setDefStaffReceive] = useState('');

	/**/
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState('');
	const [limitDate, setLimitDate] = useState('');
	const [birthDate, setBirthDate] = useState('');

	/* 基本出勤時間*/
	const [baseStartTime1, setBaseStartTime1] = useState('');
	const [baseEndTime1, setBaseEndTime1] = useState('');
	const [baseStartTime2, setBaseStartTime2] = useState('');
	const [baseEndTime2, setBaseEndTime2] = useState('');
	const [baseStartTime3, setBaseStartTime3] = useState('');
	const [baseEndTime3, setBaseEndTime3] = useState('');
	const [baseStartTime4, setBaseStartTime4] = useState('');
	const [baseEndTime4, setBaseEndTime4] = useState('');

	/*曜日出勤時間*/
	const [moStartTime1, setMoStartTime1] = useState('');
	const [moEndTime1, setMoEndTime1] = useState('');
	const [moStartTime2, setMoStartTime2] = useState('');
	const [moEndTime2, setMoEndTime2] = useState('');
	const [moStartTime3, setMoStartTime3] = useState('');
	const [moEndTime3, setMoEndTime3] = useState('');
	const [moStartTime4, setMoStartTime4] = useState('');
	const [moEndTime4, setMoEndTime4] = useState('');
	const [tuStartTime1, setTuStartTime1] = useState('');
	const [tuEndTime1, setTuEndTime1] = useState('');
	const [tuStartTime2, setTuStartTime2] = useState('');
	const [tuEndTime2, setTuEndTime2] = useState('');
	const [tuStartTime3, setTuStartTime3] = useState('');
	const [tuEndTime3, setTuEndTime3] = useState('');
	const [tuStartTime4, setTuStartTime4] = useState('');
	const [tuEndTime4, setTuEndTime4] = useState('');
	const [weStartTime1, setWeStartTime1] = useState('');
	const [weEndTime1, setWeEndTime1] = useState('');
	const [weStartTime2, setWeStartTime2] = useState('');
	const [weEndTime2, setWeEndTime2] = useState('');
	const [weStartTime3, setWeStartTime3] = useState('');
	const [weEndTime3, setWeEndTime3] = useState('');
	const [weStartTime4, setWeStartTime4] = useState('');
	const [weEndTime4, setWeEndTime4] = useState('');
	const [thStartTime1, setThStartTime1] = useState('');
	const [thEndTime1, setThEndTime1] = useState('');
	const [thStartTime2, setThStartTime2] = useState('');
	const [thEndTime2, setThEndTime2] = useState('');
	const [thStartTime3, setThStartTime3] = useState('');
	const [thEndTime3, setThEndTime3] = useState('');
	const [thStartTime4, setThStartTime4] = useState('');
	const [thEndTime4, setThEndTime4] = useState('');
	const [frStartTime1, setFrStartTime1] = useState('');
	const [frEndTime1, setFrEndTime1] = useState('');
	const [frStartTime2, setFrStartTime2] = useState('');
	const [frEndTime2, setFrEndTime2] = useState('');
	const [frStartTime3, setFrStartTime3] = useState('');
	const [frEndTime3, setFrEndTime3] = useState('');
	const [frStartTime4, setFrStartTime4] = useState('');
	const [frEndTime4, setFrEndTime4] = useState('');
	const [saStartTime1, setSaStartTime1] = useState('');
	const [saEndTime1, setSaEndTime1] = useState('');
	const [saStartTime2, setSaStartTime2] = useState('');
	const [saEndTime2, setSaEndTime2] = useState('');
	const [saStartTime3, setSaStartTime3] = useState('');
	const [saEndTime3, setSaEndTime3] = useState('');
	const [saStartTime4, setSaStartTime4] = useState('');
	const [saEndTime4, setSaEndTime4] = useState('');
	const [suStartTime1, setSuStartTime1] = useState('');
	const [suEndTime1, setSuEndTime1] = useState('');
	const [suStartTime2, setSuStartTime2] = useState('');
	const [suEndTime2, setSuEndTime2] = useState('');
	const [suStartTime3, setSuStartTime3] = useState('');
	const [suEndTime3, setSuEndTime3] = useState('');
	const [suStartTime4, setSuStartTime4] = useState('');
	const [suEndTime4, setSuEndTime4] = useState('');
	const [hoStartTime1, setHoStartTime1] = useState('');
	const [hoEndTime1, setHoEndTime1] = useState('');
	const [hoStartTime2, setHoStartTime2] = useState('');
	const [hoEndTime2, setHoEndTime2] = useState('');
	const [hoStartTime3, setHoStartTime3] = useState('');
	const [hoEndTime3, setHoEndTime3] = useState('');
	const [hoStartTime4, setHoStartTime4] = useState('');
	const [hoEndTime4, setHoEndTime4] = useState('');

	const [loading, setLoading] = useState('0');

	/**
	 * ページ離脱イベント
	 * @param e
	 */
	window.onbeforeunload = function(e){
		//--- 確認メッセージ表示(トリガーとしてのみ有効、メッセージは内容は反映されない)
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			e.returnValue = '';
		}
	}

	/**
	 * 自動カナ入力
	 */
	useEffect(() => {
		handleNameInput = handleNameInput.bind(fieldData);
		autokana = AutoKana.bind('#staff_nm', '#staff_kn', { katakana: true });
	},[]);

	function handleNameInput(ev) {
		fieldData.staff_nm = ev.target.value;
		fieldData.staff_kn = autokana.getFurigana();
	}

	/**
	 * データ取得
	 */
	useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
		if(data !== '' && data !== undefined){
			data.id = dbconn.id;
			setLoading('1');
			if(data.mode === '1'){
				Http.post('api/init-staff', {...data}).then(response => {
					if (Object.keys(response.data).length > 0) {
						//-- 初期値
						let dtNow = new Date();
						strStartDate = dtNow.getFullYear() + '/' + ('0' + (dtNow.getMonth() + 1)).slice(-2) + '/' + ('0' + dtNow.getDate()).slice(-2);
						dtNow.setMonth(dtNow.getMonth() + 3);
						strLimitDate = dtNow.getFullYear() + '/' + ('0' + (dtNow.getMonth() + 1)).slice(-2) + '/' + ('0' + dtNow.getDate()).slice(-2);

						setDispNo(Number(response.data.DispNo) + 5);
						fieldData.disp_no = Number(response.data.DispNo) + 5;
						setBaseTime(response.data.BaseTime);
						setBaseWeek(response.data.BaseWeek);
						setStaff(fieldData);
						setKengen(response.data.Kengen);
						setStaffCourse(response.data.StaffCourse);
						setStaffReceive(response.data.StaffReceive);
						setDefStaffCourse(response.data.DefStaffCourse);
						setDefStaffReceive(response.data.DefStaffReceive);
						setStartDate(new Date());
						let dtDate = new Date();
						setLimitDate(new Date(dtDate.setMonth(dtDate.getMonth() + 3)));
						setInitWeek(response.data.BaseWeek);
					}
					setLoading('0');
				});
			}else{
				Http.post('api/get-staff', {...data}).then(response => {
					if (Object.keys(response.data).length > 0) {
						setKengen(response.data.Kengen);
						setStaffCourse(response.data.StaffCourse);
						setStaffReceive(response.data.StaffReceive);
						setDefStaffCourse(response.data.DefStaffCourse);
						setDefStaffReceive(response.data.DefStaffReceive);
						setBaseWeek(response.data.BaseWeek);
						setStaff(response.data.Staff);
						setWeekTime(response.data.WeekTime);
					}
					setLoading('0');
				});
			}
		}

	}, [])

	/**
	 * 日付選択反映
	 * @param date
	 */
	function handleDate(date) {
		setManualField({
			...fieldData,
			birth_date: date,
		})
	}

	/**
	 * 権限リスト作成
	 * @returns {*}
	 */
	function fncSetKengen(){
		var aryKengens = [];
		if(kengen == "") return;

		for(var intIdx in kengen){
			aryKengens.push(
				<option value={kengen[intIdx].com_kbn} key={kengen[intIdx].com_kbn}>
					{kengen[intIdx].com_val1}
				</option>
			);
		}
		if(aryKengens.length > 0){
			return(
				<>
					{aryKengens}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * スタッフ担当コース一覧作成
	 * @returns {*}
	 */
	function fncSetStaffCourse(){
		var aryStaffCourse = [];
		if(staffcourse == "") return;

		for(var intIdx in staffcourse){
			if(staffcourse[intIdx].staff_course_id == undefined){
				staffcourse[intIdx].staff_course_id = '';
			}

			aryStaffCourse.push(
				<tr key={intIdx}>
					<td className="td-staffmng-course-no">
						{Number(intIdx) + 1}
					</td>
					<td className="td-staffmng-course-sel">
						<label>
							<input
								className="staff-mng-checkbox"
								type="checkbox"
								value="1"
								defaultChecked={staffcourse[intIdx].staff_course_id != undefined &&
									staffcourse[intIdx].staff_course_id != null &&
										staffcourse[intIdx].staff_course_id != ''? 'checked' : ''
								}
								onChange={fncChangeCourse}
								name="staff_course_id"
								id={'staff-course-' + intIdx}
								data-id={intIdx}
								disabled={staffcourse[intIdx].tool_reserve_cnt_flg === '1' ? 'disabled' : data.mode === '3'? 'disabled' : ''}
							/>
						</label>
						<input type="hidden" id={'hdn-course-id-' + intIdx} value={staffcourse[intIdx].course_id}></input>
					</td>
					<td className="td-staffmng-course-nm">
						{staffcourse[intIdx].course_nm != null? staffcourse[intIdx].course_nm : ''}
					</td>
				</tr>
			);
		}

		if(aryStaffCourse.length > 0){
			return(
				<tbody>
				{aryStaffCourse}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	/**
	 * スタッフ受信メール一覧作成
	 * @returns {*}
	 */
	function fncSetStaffReceive(){
		var aryStaffReceive = [];
		if(staffreceive == "") return;

		for(var intIdx in staffreceive){
			if(staffreceive[intIdx].mail_kbn == undefined){
				staffreceive[intIdx].mail_kbn = '';
			}

			aryStaffReceive.push(
				<tr key={intIdx}>
					<td className="td-staffmng-receive-no">
						{Number(intIdx) + 1}
					</td>
					<td className="td-staffmng-receive-sel">
						<label>
							<input
								className="staff-mng-checkbox"
								type="checkbox"
								value="1"
								defaultChecked={
									staffreceive[intIdx].receive_flg != undefined &&
									staffreceive[intIdx].receive_flg != null &&
									staffreceive[intIdx].receive_flg != '' &&
									staffreceive[intIdx].receive_flg != '0' ? 'checked' : ''
								}
								onChange={fncChangeReceive}
								name="receive_flg"
								id={'staff-receive-' + intIdx}
								data-id={intIdx}
								disabled={data.mode === '3'? 'disabled' : ''}
							/>
						</label>
						<input type="hidden" id={'hdn-mail-kbn' + intIdx} value={staffreceive[intIdx].mail_kbn}></input>
					</td>
					<td className="td-staffmng-receive-nm">
						{staffreceive[intIdx].mail_nm != null? staffreceive[intIdx].mail_nm : ''}
					</td>
				</tr>
			);
		}

		if(aryStaffReceive.length > 0){
			return(
				<tbody>
				{aryStaffReceive}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	/**
	 * コース選択
	 * @param e
	 */
	function fncChangeCourse(e){
		const intID = e.currentTarget.getAttribute('data-id');

		if( staffcourse[intID].staff_course_id != undefined && staffcourse[intID].staff_course_id != '' ){
			staffcourse[intID].staff_course_id = '';
		}else{
			staffcourse[intID].staff_course_id = staffcourse[intID].course_id;
		}
	}

	/**
	 * 受信メール選択
	 * @param e
	 */
	function fncChangeReceive(e){
		const intID = e.currentTarget.getAttribute('data-id');

		if( staffreceive[intID].receive_flg != '1' ){
			staffreceive[intID].receive_flg = '1';
		}else{
			staffreceive[intID].receive_flg = "0";
		}
	}

	/**
	 * スタッフ情報設定
	 */
	useEffect(() => {
		if(staff != "" && staff != undefined) {
			//--- 現在日付
			var dtNow = new Date();
			var strDate = dtNow.getFullYear() + '/' + (dtNow.getMonth() + 1) + '/' + dtNow.getDate() + ' ';

			//--- 店舗情報
			fieldData.staff_id = staff.staff_id;
			fieldData.staff_nm = staff.staff_nm;
			fieldData.staff_kn = staff.staff_kn;
			fieldData.staff_disp_nm = staff.staff_disp_nm;
			fieldData.staff_tel = staff.staff_tel;
			fieldData.staff_sex = staff.staff_sex;
			if (staff.staff_birthday_year != null && staff.staff_birthday_year != '') {
				fieldData.birth_date.year = staff.staff_birthday_year;
				fieldData.staff_birthday_year = staff.staff_birthday_year;
			}
			if (staff.staff_birthday_month != null && staff.staff_birthday_month != '') {
				fieldData.birth_date.month = staff.staff_birthday_month;
				fieldData.staff_birthday_month = staff.staff_birthday_month;
			}
			if (staff.staff_birthday_day != null && staff.staff_birthday_day != '') {
				fieldData.birth_date.day = staff.staff_birthday_day;
				fieldData.staff_birthday_day = staff.staff_birthday_day;
			}
			if (staff.staff_birthday_year != null && staff.staff_birthday_year != '' &&
				staff.staff_birthday_month != null && staff.staff_birthday_month != '' &&
				staff.staff_birthday_day != null && staff.staff_birthday_day != '') {
				var strBirthDate = staff.staff_birthday_year + "/" + staff.staff_birthday_month + "/" + staff.staff_birthday_day + " 00:00:00";
				setBirthDate(new Date(strBirthDate));
			}
			fieldData.zaiseki_start_date = staff.zaiseki_start_date;
			if (staff.zaiseki_start_date != null && staff.zaiseki_start_date != '') {
				setStartDate(new Date(staff.zaiseki_start_date));
			}
			fieldData.zaiseki_end_date = staff.zaiseki_end_date;
			if (staff.zaiseki_end_date != null && staff.zaiseki_end_date != '') {
				setEndDate(new Date(staff.zaiseki_end_date));
			} else {
				setEndDate('');
			}
			if (basetime != '') {
				//- 新規の場合
				fieldData.work_start_time1 = basetime[0].open_time1;
				if (basetime[0].open_time1 != null && basetime[0].open_time1 != '') {
					setBaseStartTime1(new Date(strDate + basetime[0].open_time1 + ':00'));
				}
				fieldData.work_end_time1 = basetime[0].close_time1;
				if (basetime[0].close_time1 != null && basetime[0].close_time1 != '') {
					setBaseEndTime1(new Date(strDate + basetime[0].close_time1 + ':00'));
				}
				fieldData.work_start_time2 = basetime[0].open_time2;
				if (basetime[0].open_time2 != null && basetime[0].open_time2 != '') {
					setBaseStartTime2(new Date(strDate + basetime[0].open_time2 + ':00'));
				}
				fieldData.work_end_time2 = basetime[0].close_time2;
				if (basetime[0].close_time2 != null && basetime[0].close_time2 != '') {
					setBaseEndTime2(new Date(strDate + basetime[0].close_time2 + ':00'));
				}
				fieldData.work_start_time3 = basetime[0].open_time3;
				if (basetime[0].open_time3 != null && basetime[0].open_time3 != '') {
					setBaseStartTime3(new Date(strDate + basetime[0].open_time3 + ':00'));
				}
				fieldData.work_end_time3 = basetime[0].close_time3;
				if (basetime[0].close_time3 != null && basetime[0].close_time3 != '') {
					setBaseEndTime3(new Date(strDate + basetime[0].close_time3 + ':00'));
				}
				fieldData.work_start_time4 = basetime[0].open_time4;
				if (basetime[0].open_time4 != null && basetime[0].open_time4 != '') {
					setBaseStartTime4(new Date(strDate + basetime[0].open_time4 + ':00'));
				}
				fieldData.work_end_time4 = basetime[0].close_time4;
				if (basetime[0].close_time4 != null && basetime[0].close_time4 != '') {
					setBaseEndTime4(new Date(strDate + basetime[0].close_time4 + ':00'));
				}
			} else {
				//- 新規以外の場合
				fieldData.work_start_time1 = staff.work_start_time1;
				if (staff.work_start_time1 != null && staff.work_start_time1 != '') {
					setBaseStartTime1(new Date(strDate + staff.work_start_time1 + ':00'));
				}
				fieldData.work_end_time1 = staff.work_end_time1;
				if (staff.work_end_time1 != null && staff.work_end_time1 != '') {
					setBaseEndTime1(new Date(strDate + staff.work_end_time1 + ':00'));
				}
				fieldData.work_start_time2 = staff.work_start_time2;
				if (staff.work_start_time2 != null && staff.work_start_time2 != '') {
					setBaseStartTime2(new Date(strDate + staff.work_start_time2 + ':00'));
				}
				fieldData.work_end_time2 = staff.work_end_time2;
				if (staff.work_end_time2 != null && staff.work_end_time2 != '') {
					setBaseEndTime2(new Date(strDate + staff.work_end_time2 + ':00'));
				}
				fieldData.work_start_time3 = staff.work_start_time3;
				if (staff.work_start_time3 != null && staff.work_start_time3 != '') {
					setBaseStartTime3(new Date(strDate + staff.work_start_time3 + ':00'));
				}
				fieldData.work_end_time3 = staff.work_end_time3;
				if (staff.work_end_time3 != null && staff.work_end_time3 != '') {
					setBaseEndTime3(new Date(strDate + staff.work_end_time3 + ':00'));
				}
				fieldData.work_start_time4 = staff.work_start_time4;
				if (staff.work_start_time4 != null && staff.work_start_time4 != '') {
					setBaseStartTime4(new Date(strDate + staff.work_start_time4 + ':00'));
				}
				fieldData.work_end_time4 = staff.work_end_time4;
				if (staff.work_end_time4 != null && staff.work_end_time4 != '') {
					setBaseEndTime4(new Date(strDate + staff.work_end_time4 + ':00'));
				}
			}

			if(staff.staff_mail != null){
				fieldData.staff_mail = staff.staff_mail;
			}
			if(staff.email != null){
				fieldData.email = staff.email;
			}
			if(staff.login_pass != null){
				fieldData.login_pass = staff.login_pass;
			}
			if(staff.kengen_kbn != null){
				fieldData.kengen_kbn = staff.kengen_kbn;
			}
			fieldData.limit_date = staff.limit_date;
			if(staff.limit_date != null && staff.limit_date != ''){
				setLimitDate(new Date(staff.limit_date));
			}
			if(staff.disp_no != null){
				fieldData.disp_no = staff.disp_no;
			}else if(dispno != ''){
				fieldData.disp_no = dispno;
			}
			if(staff.disp_flg != null){
				fieldData.disp_flg = staff.disp_flg;
			}
			if(staff.rec_mail_kbn != null){
				fieldData.rec_mail_kbn = staff.rec_mail_kbn;
			}

			defData.staff_id = fieldData.staff_id != null ? fieldData.staff_id : '';
			defData.staff_nm = fieldData.staff_nm != null ? fieldData.staff_nm : '';
			defData.staff_kn = fieldData.staff_kn != null ? fieldData.staff_kn : '';
			defData.staff_disp_nm = fieldData.staff_disp_nm != null ? fieldData.staff_disp_nm : '';
			defData.staff_tel = fieldData.staff_tel != null ? fieldData.staff_tel : '';
			defData.staff_sex = fieldData.staff_sex != null ? fieldData.staff_sex : '';
			defData.staff_birthday_year = fieldData.staff_birthday_year != null ? fieldData.staff_birthday_year : '';
			defData.staff_birthday_month = fieldData.staff_birthday_month != null ? fieldData.staff_birthday_month : '';
			defData.staff_birthday_day = fieldData.staff_birthday_day != null ? fieldData.staff_birthday_day : '';
			defData.zaiseki_start_date = fieldData.zaiseki_start_date != null && fieldData.zaiseki_start_date != '' ? fieldData.zaiseki_start_date : strStartDate;
			defData.zaiseki_end_date = fieldData.zaiseki_end_date != null ? fieldData.zaiseki_end_date : '';
			defData.work_start_time1 = fieldData.work_start_time1 != null ? fieldData.work_start_time1 : '';
			defData.work_end_time1 = fieldData.work_end_time1 != null ? fieldData.work_end_time1 : '';
			defData.work_start_time2 = fieldData.work_start_time2 != null ? fieldData.work_start_time2 : '';
			defData.work_end_time2 = fieldData.work_end_time2 != null ? fieldData.work_end_time2 : '';
			defData.work_start_time3 = fieldData.work_start_time3 != null ? fieldData.work_start_time3 : '';
			defData.work_end_time3 = fieldData.work_end_time3 != null ? fieldData.work_end_time3 : '';
			defData.work_start_time4 = fieldData.work_start_time4 != null ? fieldData.work_start_time4 : '';
			defData.work_end_time4 = fieldData.work_end_time4 != null ? fieldData.work_end_time4 : '';
			defData.staff_mail = fieldData.staff_mail != null ? fieldData.staff_mail : '';
			defData.email = fieldData.email != null ? fieldData.email : '';
			defData.login_pass = '';
			defData.kengen_kbn = fieldData.kengen_kbn != null ? fieldData.kengen_kbn : '';
			defData.limit_date = fieldData.limit_date != null && fieldData.limit_date != '' ? fieldData.limit_date : strLimitDate;
			defData.disp_no = fieldData.disp_no != null ? fieldData.disp_no : '';
			defData.disp_flg = fieldData.disp_flg != null ? fieldData.disp_flg : '';
			defData.rec_mail_kbn = fieldData.rec_mail_kbn != null ? fieldData.rec_mail_kbn : '';

		}
	}, [staff])

	/**
	 * 店舗曜日別時間情報設定(新規)
	 */
	useEffect(() => {
		//--- 現在日付
		var dtNow = new Date();
		var strDate = dtNow.getFullYear() + '/' + (dtNow.getMonth() + 1) + '/' + dtNow.getDate() + ' ';

		//---- 曜日別時間情報
		if(initweek != "" && initweek != undefined){
			for(var intIdx in initweek){
				//--- 曜日判定
				if(initweek[intIdx].week_kbn == '0'){
					//-- 月
					if(initweek[intIdx].regular_holiday != '1'){
						if(initweek[intIdx].open_time1 != null && initweek[intIdx].open_time1 != ''){
							setMoStartTime1(new Date(strDate + initweek[intIdx].open_time1 + ':00'));
						}
						if(initweek[intIdx].open_time2 != null && initweek[intIdx].open_time2 != ''){
							setMoStartTime2(new Date(strDate + initweek[intIdx].open_time2 + ':00'));
						}
						if(initweek[intIdx].open_time3 != null && initweek[intIdx].open_time3 != ''){
							setMoStartTime3(new Date(strDate + initweek[intIdx].open_time3 + ':00'));
						}
						if(initweek[intIdx].open_time4 != null && initweek[intIdx].open_time4 != ''){
							setMoStartTime4(new Date(strDate + initweek[intIdx].open_time4 + ':00'));
						}
						if(initweek[intIdx].close_time1 != null && initweek[intIdx].close_time1 != ''){
							setMoEndTime1(new Date(strDate + initweek[intIdx].close_time1 + ':00'));
						}
						if(initweek[intIdx].close_time2 != null && initweek[intIdx].close_time2 != ''){
							setMoEndTime2(new Date(strDate + initweek[intIdx].close_time2 + ':00'));
						}
						if(initweek[intIdx].close_time3 != null && initweek[intIdx].close_time3 != ''){
							setMoEndTime3(new Date(strDate + initweek[intIdx].close_time3 + ':00'));
						}
						if(initweek[intIdx].close_time4 != null && initweek[intIdx].close_time4 != ''){
							setMoEndTime4(new Date(strDate + initweek[intIdx].close_time4 + ':00'));
						}
					}
				}else if(initweek[intIdx].week_kbn == '1'){
					//-- 火
					if(initweek[intIdx].regular_holiday != '1') {
						if (initweek[intIdx].open_time1 != null && initweek[intIdx].open_time1 != '') {
							setTuStartTime1(new Date(strDate + initweek[intIdx].open_time1 + ':00'));
						}
						if (initweek[intIdx].open_time2 != null && initweek[intIdx].open_time2 != '') {
							setTuStartTime2(new Date(strDate + initweek[intIdx].open_time2 + ':00'));
						}
						if (initweek[intIdx].open_time3 != null && initweek[intIdx].open_time3 != '') {
							setTuStartTime3(new Date(strDate + initweek[intIdx].open_time3 + ':00'));
						}
						if (initweek[intIdx].open_time4 != null && initweek[intIdx].open_time4 != '') {
							setTuStartTime4(new Date(strDate + initweek[intIdx].open_time4 + ':00'));
						}
						if (initweek[intIdx].close_time1 != null && initweek[intIdx].close_time1 != '') {
							setTuEndTime1(new Date(strDate + initweek[intIdx].close_time1 + ':00'));
						}
						if (initweek[intIdx].close_time2 != null && initweek[intIdx].close_time2 != '') {
							setTuEndTime2(new Date(strDate + initweek[intIdx].close_time2 + ':00'));
						}
						if (initweek[intIdx].close_time3 != null && initweek[intIdx].close_time3 != '') {
							setTuEndTime3(new Date(strDate + initweek[intIdx].close_time3 + ':00'));
						}
						if (initweek[intIdx].close_time4 != null && initweek[intIdx].close_time4 != '') {
							setTuEndTime4(new Date(strDate + initweek[intIdx].close_time4 + ':00'));
						}
					}
				}else if(initweek[intIdx].week_kbn == '2'){
					//-- 水
					if(initweek[intIdx].regular_holiday != '1') {
						if (initweek[intIdx].open_time1 != null && initweek[intIdx].open_time1 != '') {
							setWeStartTime1(new Date(strDate + initweek[intIdx].open_time1 + ':00'));
						}
						if (initweek[intIdx].open_time2 != null && initweek[intIdx].open_time2 != '') {
							setWeStartTime2(new Date(strDate + initweek[intIdx].open_time2 + ':00'));
						}
						if (initweek[intIdx].open_time3 != null && initweek[intIdx].open_time3 != '') {
							setWeStartTime3(new Date(strDate + initweek[intIdx].open_time3 + ':00'));
						}
						if (initweek[intIdx].open_time4 != null && initweek[intIdx].open_time4 != '') {
							setWeStartTime4(new Date(strDate + initweek[intIdx].open_time4 + ':00'));
						}
						if (initweek[intIdx].close_time1 != null && initweek[intIdx].close_time1 != '') {
							setWeEndTime1(new Date(strDate + initweek[intIdx].close_time1 + ':00'));
						}
						if (initweek[intIdx].close_time2 != null && initweek[intIdx].close_time2 != '') {
							setWeEndTime2(new Date(strDate + initweek[intIdx].close_time2 + ':00'));
						}
						if (initweek[intIdx].close_time3 != null && initweek[intIdx].close_time3 != '') {
							setWeEndTime3(new Date(strDate + initweek[intIdx].close_time3 + ':00'));
						}
						if (initweek[intIdx].close_time4 != null && initweek[intIdx].close_time4 != '') {
							setWeEndTime4(new Date(strDate + initweek[intIdx].close_time4 + ':00'));
						}
					}
				}else if(initweek[intIdx].week_kbn == '3'){
					//-- 木
					if(initweek[intIdx].regular_holiday != '1') {
						if (initweek[intIdx].open_time1 != null && initweek[intIdx].open_time1 != '') {
							setThStartTime1(new Date(strDate + initweek[intIdx].open_time1 + ':00'));
						}
						if (initweek[intIdx].open_time2 != null && initweek[intIdx].open_time2 != '') {
							setThStartTime2(new Date(strDate + initweek[intIdx].open_time2 + ':00'));
						}
						if (initweek[intIdx].open_time3 != null && initweek[intIdx].open_time3 != '') {
							setThStartTime3(new Date(strDate + initweek[intIdx].open_time3 + ':00'));
						}
						if (initweek[intIdx].open_time4 != null && initweek[intIdx].open_time4 != '') {
							setThStartTime4(new Date(strDate + initweek[intIdx].open_time4 + ':00'));
						}
						if (initweek[intIdx].close_time1 != null && initweek[intIdx].close_time1 != '') {
							setThEndTime1(new Date(strDate + initweek[intIdx].close_time1 + ':00'));
						}
						if (initweek[intIdx].close_time2 != null && initweek[intIdx].close_time2 != '') {
							setThEndTime2(new Date(strDate + initweek[intIdx].close_time2 + ':00'));
						}
						if (initweek[intIdx].close_time3 != null && initweek[intIdx].close_time3 != '') {
							setThEndTime3(new Date(strDate + initweek[intIdx].close_time3 + ':00'));
						}
						if (initweek[intIdx].close_time4 != null && initweek[intIdx].close_time4 != '') {
							setThEndTime4(new Date(strDate + initweek[intIdx].close_time4 + ':00'));
						}
					}
				}else if(initweek[intIdx].week_kbn == '4'){
					//-- 金
					if(initweek[intIdx].regular_holiday != '1') {
						if (initweek[intIdx].open_time1 != null && initweek[intIdx].open_time1 != '') {
							setFrStartTime1(new Date(strDate + initweek[intIdx].open_time1 + ':00'));
						}
						if (initweek[intIdx].open_time2 != null && initweek[intIdx].open_time2 != '') {
							setFrStartTime2(new Date(strDate + initweek[intIdx].open_time2 + ':00'));
						}
						if (initweek[intIdx].open_time3 != null && initweek[intIdx].open_time3 != '') {
							setFrStartTime3(new Date(strDate + initweek[intIdx].open_time3 + ':00'));
						}
						if (initweek[intIdx].open_time4 != null && initweek[intIdx].open_time4 != '') {
							setFrStartTime4(new Date(strDate + initweek[intIdx].open_time4 + ':00'));
						}
						if (initweek[intIdx].close_time1 != null && initweek[intIdx].close_time1 != '') {
							setFrEndTime1(new Date(strDate + initweek[intIdx].close_time1 + ':00'));
						}
						if (initweek[intIdx].close_time2 != null && initweek[intIdx].close_time2 != '') {
							setFrEndTime2(new Date(strDate + initweek[intIdx].close_time2 + ':00'));
						}
						if (initweek[intIdx].close_time3 != null && initweek[intIdx].close_time3 != '') {
							setFrEndTime3(new Date(strDate + initweek[intIdx].close_time3 + ':00'));
						}
						if (initweek[intIdx].close_time4 != null && initweek[intIdx].close_time4 != '') {
							setFrEndTime4(new Date(strDate + initweek[intIdx].close_time4 + ':00'));
						}
					}
				}else if(initweek[intIdx].week_kbn == '5'){
					//-- 土
					if(initweek[intIdx].regular_holiday != '1') {
						if (initweek[intIdx].open_time1 != null && initweek[intIdx].open_time1 != '') {
							setSaStartTime1(new Date(strDate + initweek[intIdx].open_time1 + ':00'));
						}
						if (initweek[intIdx].open_time2 != null && initweek[intIdx].open_time2 != '') {
							setSaStartTime2(new Date(strDate + initweek[intIdx].open_time2 + ':00'));
						}
						if (initweek[intIdx].open_time3 != null && initweek[intIdx].open_time3 != '') {
							setSaStartTime3(new Date(strDate + initweek[intIdx].open_time3 + ':00'));
						}
						if (initweek[intIdx].open_time4 != null && initweek[intIdx].open_time4 != '') {
							setSaStartTime4(new Date(strDate + initweek[intIdx].open_time4 + ':00'));
						}
						if (initweek[intIdx].close_time1 != null && initweek[intIdx].close_time1 != '') {
							setSaEndTime1(new Date(strDate + initweek[intIdx].close_time1 + ':00'));
						}
						if (initweek[intIdx].close_time2 != null && initweek[intIdx].close_time2 != '') {
							setSaEndTime2(new Date(strDate + initweek[intIdx].close_time2 + ':00'));
						}
						if (initweek[intIdx].close_time3 != null && initweek[intIdx].close_time3 != '') {
							setSaEndTime3(new Date(strDate + initweek[intIdx].close_time3 + ':00'));
						}
						if (initweek[intIdx].close_time4 != null && initweek[intIdx].close_time4 != '') {
							setSaEndTime4(new Date(strDate + initweek[intIdx].close_time4 + ':00'));
						}
					}
				}else if(initweek[intIdx].week_kbn == '6'){
					//-- 日
					if(initweek[intIdx].regular_holiday != '1') {
						if (initweek[intIdx].open_time1 != null && initweek[intIdx].open_time1 != '') {
							setSuStartTime1(new Date(strDate + initweek[intIdx].open_time1 + ':00'));
						}
						if (initweek[intIdx].open_time2 != null && initweek[intIdx].open_time2 != '') {
							setSuStartTime2(new Date(strDate + initweek[intIdx].open_time2 + ':00'));
						}
						if (initweek[intIdx].open_time3 != null && initweek[intIdx].open_time3 != '') {
							setSuStartTime3(new Date(strDate + initweek[intIdx].open_time3 + ':00'));
						}
						if (initweek[intIdx].open_time4 != null && initweek[intIdx].open_time4 != '') {
							setSuStartTime4(new Date(strDate + initweek[intIdx].open_time4 + ':00'));
						}
						if (initweek[intIdx].close_time1 != null && initweek[intIdx].close_time1 != '') {
							setSuEndTime1(new Date(strDate + initweek[intIdx].close_time1 + ':00'));
						}
						if (initweek[intIdx].close_time2 != null && initweek[intIdx].close_time2 != '') {
							setSuEndTime2(new Date(strDate + initweek[intIdx].close_time2 + ':00'));
						}
						if (initweek[intIdx].close_time3 != null && initweek[intIdx].close_time3 != '') {
							setSuEndTime3(new Date(strDate + initweek[intIdx].close_time3 + ':00'));
						}
						if (initweek[intIdx].close_time4 != null && initweek[intIdx].close_time4 != '') {
							setSuEndTime4(new Date(strDate + initweek[intIdx].close_time4 + ':00'));
						}
					}
				}else if(initweek[intIdx].week_kbn == '7'){
					//-- 祝
					if(initweek[intIdx].regular_holiday != '1') {
						if (initweek[intIdx].open_time1 != null && initweek[intIdx].open_time1 != '') {
							setHoStartTime1(new Date(strDate + initweek[intIdx].open_time1 + ':00'));
						}
						if (initweek[intIdx].open_time2 != null && initweek[intIdx].open_time2 != '') {
							setHoStartTime2(new Date(strDate + initweek[intIdx].open_time2 + ':00'));
						}
						if (initweek[intIdx].open_time3 != null && initweek[intIdx].open_time3 != '') {
							setHoStartTime3(new Date(strDate + initweek[intIdx].open_time3 + ':00'));
						}
						if (initweek[intIdx].open_time4 != null && initweek[intIdx].open_time4 != '') {
							setHoStartTime4(new Date(strDate + initweek[intIdx].open_time4 + ':00'));
						}
						if (initweek[intIdx].close_time1 != null && initweek[intIdx].close_time1 != '') {
							setHoEndTime1(new Date(strDate + initweek[intIdx].close_time1 + ':00'));
						}
						if (initweek[intIdx].close_time2 != null && initweek[intIdx].close_time2 != '') {
							setHoEndTime2(new Date(strDate + initweek[intIdx].close_time2 + ':00'));
						}
						if (initweek[intIdx].close_time3 != null && initweek[intIdx].close_time3 != '') {
							setHoEndTime3(new Date(strDate + initweek[intIdx].close_time3 + ':00'));
						}
						if (initweek[intIdx].close_time4 != null && initweek[intIdx].close_time4 != '') {
							setHoEndTime4(new Date(strDate + initweek[intIdx].close_time4 + ':00'));
						}
					}
				}

				if(initweek[intIdx].regular_holiday != '1') {
					defWeekTime[intIdx].work_start_time1 = initweek[intIdx].open_time1 != null ? initweek[intIdx].open_time1 : '';
					defWeekTime[intIdx].work_start_time2 = initweek[intIdx].open_time2 != null ? initweek[intIdx].open_time2 : '';
					defWeekTime[intIdx].work_start_time3 = initweek[intIdx].open_time3 != null ? initweek[intIdx].open_time3 : '';
					defWeekTime[intIdx].work_start_time4 = initweek[intIdx].open_time4 != null ? initweek[intIdx].open_time4 : '';
					defWeekTime[intIdx].work_end_time1 = initweek[intIdx].close_time1 != null ? initweek[intIdx].close_time1 : '';
					defWeekTime[intIdx].work_end_time2 = initweek[intIdx].close_time2 != null ? initweek[intIdx].close_time2 : '';
					defWeekTime[intIdx].work_end_time3 = initweek[intIdx].close_time3 != null ? initweek[intIdx].close_time3 : '';
					defWeekTime[intIdx].work_end_time4 = initweek[intIdx].close_time4 != null ? initweek[intIdx].close_time4 : '';
				}
			}
		}
	}, [initweek])

	/**
	 * 店舗曜日別時間情報設定(編集)
	 */
	useEffect(() => {
		//--- 現在日付
		var dtNow = new Date();
		var strDate = dtNow.getFullYear() + '/' + (dtNow.getMonth() + 1) + '/' + dtNow.getDate() + ' ';

		//---- 曜日別時間情報
		if(weektime != "" && weektime != undefined){
			for(var intIdx in weektime){
				//--- 曜日判定
				if(weektime[intIdx].week_kbn == '0'){
					//-- 月
					if(weektime[intIdx].work_start_time1 != null && weektime[intIdx].work_start_time1 != ''){
						setMoStartTime1(new Date(strDate + weektime[intIdx].work_start_time1 + ':00'));
					}
					if(weektime[intIdx].work_start_time2 != null && weektime[intIdx].work_start_time2 != ''){
						setMoStartTime2(new Date(strDate + weektime[intIdx].work_start_time2 + ':00'));
					}
					if(weektime[intIdx].work_start_time3 != null && weektime[intIdx].work_start_time3 != ''){
						setMoStartTime3(new Date(strDate + weektime[intIdx].work_start_time3 + ':00'));
					}
					if(weektime[intIdx].work_start_time4 != null && weektime[intIdx].work_start_time4 != ''){
						setMoStartTime4(new Date(strDate + weektime[intIdx].work_start_time4 + ':00'));
					}
					if(weektime[intIdx].work_end_time1 != null && weektime[intIdx].work_end_time1 != ''){
						setMoEndTime1(new Date(strDate + weektime[intIdx].work_end_time1 + ':00'));
					}
					if(weektime[intIdx].work_end_time2 != null && weektime[intIdx].work_end_time2 != ''){
						setMoEndTime2(new Date(strDate + weektime[intIdx].work_end_time2 + ':00'));
					}
					if(weektime[intIdx].work_end_time3 != null && weektime[intIdx].work_end_time3 != ''){
						setMoEndTime3(new Date(strDate + weektime[intIdx].work_end_time3 + ':00'));
					}
					if(weektime[intIdx].work_end_time4 != null && weektime[intIdx].work_end_time4 != ''){
						setMoEndTime4(new Date(strDate + weektime[intIdx].work_end_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '1'){
					//-- 火
					if(weektime[intIdx].work_start_time1 != null && weektime[intIdx].work_start_time1 != ''){
						setTuStartTime1(new Date(strDate + weektime[intIdx].work_start_time1 + ':00'));
					}
					if(weektime[intIdx].work_start_time2 != null && weektime[intIdx].work_start_time2 != ''){
						setTuStartTime2(new Date(strDate + weektime[intIdx].work_start_time2 + ':00'));
					}
					if(weektime[intIdx].work_start_time3 != null && weektime[intIdx].work_start_time3 != ''){
						setTuStartTime3(new Date(strDate + weektime[intIdx].work_start_time3 + ':00'));
					}
					if(weektime[intIdx].work_start_time4 != null && weektime[intIdx].work_start_time4 != ''){
						setTuStartTime4(new Date(strDate + weektime[intIdx].work_start_time4 + ':00'));
					}
					if(weektime[intIdx].work_end_time1 != null && weektime[intIdx].work_end_time1 != ''){
						setTuEndTime1(new Date(strDate + weektime[intIdx].work_end_time1 + ':00'));
					}
					if(weektime[intIdx].work_end_time2 != null && weektime[intIdx].work_end_time2 != ''){
						setTuEndTime2(new Date(strDate + weektime[intIdx].work_end_time2 + ':00'));
					}
					if(weektime[intIdx].work_end_time3 != null && weektime[intIdx].work_end_time3 != ''){
						setTuEndTime3(new Date(strDate + weektime[intIdx].work_end_time3 + ':00'));
					}
					if(weektime[intIdx].work_end_time4 != null && weektime[intIdx].work_end_time4 != ''){
						setTuEndTime4(new Date(strDate + weektime[intIdx].work_end_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '2'){
					//-- 水
					if(weektime[intIdx].work_start_time1 != null && weektime[intIdx].work_start_time1 != ''){
						setWeStartTime1(new Date(strDate + weektime[intIdx].work_start_time1 + ':00'));
					}
					if(weektime[intIdx].work_start_time2 != null && weektime[intIdx].work_start_time2 != ''){
						setWeStartTime2(new Date(strDate + weektime[intIdx].work_start_time2 + ':00'));
					}
					if(weektime[intIdx].work_start_time3 != null && weektime[intIdx].work_start_time3 != ''){
						setWeStartTime3(new Date(strDate + weektime[intIdx].work_start_time3 + ':00'));
					}
					if(weektime[intIdx].work_start_time4 != null && weektime[intIdx].work_start_time4 != ''){
						setWeStartTime4(new Date(strDate + weektime[intIdx].work_start_time4 + ':00'));
					}
					if(weektime[intIdx].work_end_time1 != null && weektime[intIdx].work_end_time1 != ''){
						setWeEndTime1(new Date(strDate + weektime[intIdx].work_end_time1 + ':00'));
					}
					if(weektime[intIdx].work_end_time2 != null && weektime[intIdx].work_end_time2 != ''){
						setWeEndTime2(new Date(strDate + weektime[intIdx].work_end_time2 + ':00'));
					}
					if(weektime[intIdx].work_end_time3 != null && weektime[intIdx].work_end_time3 != ''){
						setWeEndTime3(new Date(strDate + weektime[intIdx].work_end_time3 + ':00'));
					}
					if(weektime[intIdx].work_end_time4 != null && weektime[intIdx].work_end_time4 != ''){
						setWeEndTime4(new Date(strDate + weektime[intIdx].work_end_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '3'){
					//-- 木
					if(weektime[intIdx].work_start_time1 != null && weektime[intIdx].work_start_time1 != ''){
						setThStartTime1(new Date(strDate + weektime[intIdx].work_start_time1 + ':00'));
					}
					if(weektime[intIdx].work_start_time2 != null && weektime[intIdx].work_start_time2 != ''){
						setThStartTime2(new Date(strDate + weektime[intIdx].work_start_time2 + ':00'));
					}
					if(weektime[intIdx].work_start_time3 != null && weektime[intIdx].work_start_time3 != ''){
						setThStartTime3(new Date(strDate + weektime[intIdx].work_start_time3 + ':00'));
					}
					if(weektime[intIdx].work_start_time4 != null && weektime[intIdx].work_start_time4 != ''){
						setThStartTime4(new Date(strDate + weektime[intIdx].work_start_time4 + ':00'));
					}
					if(weektime[intIdx].work_end_time1 != null && weektime[intIdx].work_end_time1 != ''){
						setThEndTime1(new Date(strDate + weektime[intIdx].work_end_time1 + ':00'));
					}
					if(weektime[intIdx].work_end_time2 != null && weektime[intIdx].work_end_time2 != ''){
						setThEndTime2(new Date(strDate + weektime[intIdx].work_end_time2 + ':00'));
					}
					if(weektime[intIdx].work_end_time3 != null && weektime[intIdx].work_end_time3 != ''){
						setThEndTime3(new Date(strDate + weektime[intIdx].work_end_time3 + ':00'));
					}
					if(weektime[intIdx].work_end_time4 != null && weektime[intIdx].work_end_time4 != ''){
						setThEndTime4(new Date(strDate + weektime[intIdx].work_end_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '4'){
					//-- 金
					if(weektime[intIdx].work_start_time1 != null && weektime[intIdx].work_start_time1 != ''){
						setFrStartTime1(new Date(strDate + weektime[intIdx].work_start_time1 + ':00'));
					}
					if(weektime[intIdx].work_start_time2 != null && weektime[intIdx].work_start_time2 != ''){
						setFrStartTime2(new Date(strDate + weektime[intIdx].work_start_time2 + ':00'));
					}
					if(weektime[intIdx].work_start_time3 != null && weektime[intIdx].work_start_time3 != ''){
						setFrStartTime3(new Date(strDate + weektime[intIdx].work_start_time3 + ':00'));
					}
					if(weektime[intIdx].work_start_time4 != null && weektime[intIdx].work_start_time4 != ''){
						setFrStartTime4(new Date(strDate + weektime[intIdx].work_start_time4 + ':00'));
					}
					if(weektime[intIdx].work_end_time1 != null && weektime[intIdx].work_end_time1 != ''){
						setFrEndTime1(new Date(strDate + weektime[intIdx].work_end_time1 + ':00'));
					}
					if(weektime[intIdx].work_end_time2 != null && weektime[intIdx].work_end_time2 != ''){
						setFrEndTime2(new Date(strDate + weektime[intIdx].work_end_time2 + ':00'));
					}
					if(weektime[intIdx].work_end_time3 != null && weektime[intIdx].work_end_time3 != ''){
						setFrEndTime3(new Date(strDate + weektime[intIdx].work_end_time3 + ':00'));
					}
					if(weektime[intIdx].work_end_time4 != null && weektime[intIdx].work_end_time4 != ''){
						setFrEndTime4(new Date(strDate + weektime[intIdx].work_end_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '5'){
					//-- 土
					if(weektime[intIdx].work_start_time1 != null && weektime[intIdx].work_start_time1 != ''){
						setSaStartTime1(new Date(strDate + weektime[intIdx].work_start_time1 + ':00'));
					}
					if(weektime[intIdx].work_start_time2 != null && weektime[intIdx].work_start_time2 != ''){
						setSaStartTime2(new Date(strDate + weektime[intIdx].work_start_time2 + ':00'));
					}
					if(weektime[intIdx].work_start_time3 != null && weektime[intIdx].work_start_time3 != ''){
						setSaStartTime3(new Date(strDate + weektime[intIdx].work_start_time3 + ':00'));
					}
					if(weektime[intIdx].work_start_time4 != null && weektime[intIdx].work_start_time4 != ''){
						setSaStartTime4(new Date(strDate + weektime[intIdx].work_start_time4 + ':00'));
					}
					if(weektime[intIdx].work_end_time1 != null && weektime[intIdx].work_end_time1 != ''){
						setSaEndTime1(new Date(strDate + weektime[intIdx].work_end_time1 + ':00'));
					}
					if(weektime[intIdx].work_end_time2 != null && weektime[intIdx].work_end_time2 != ''){
						setSaEndTime2(new Date(strDate + weektime[intIdx].work_end_time2 + ':00'));
					}
					if(weektime[intIdx].work_end_time3 != null && weektime[intIdx].work_end_time3 != ''){
						setSaEndTime3(new Date(strDate + weektime[intIdx].work_end_time3 + ':00'));
					}
					if(weektime[intIdx].work_end_time4 != null && weektime[intIdx].work_end_time4 != ''){
						setSaEndTime4(new Date(strDate + weektime[intIdx].work_end_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '6'){
					//-- 日
					if(weektime[intIdx].work_start_time1 != null && weektime[intIdx].work_start_time1 != ''){
						setSuStartTime1(new Date(strDate + weektime[intIdx].work_start_time1 + ':00'));
					}
					if(weektime[intIdx].work_start_time2 != null && weektime[intIdx].work_start_time2 != ''){
						setSuStartTime2(new Date(strDate + weektime[intIdx].work_start_time2 + ':00'));
					}
					if(weektime[intIdx].work_start_time3 != null && weektime[intIdx].work_start_time3 != ''){
						setSuStartTime3(new Date(strDate + weektime[intIdx].work_start_time3 + ':00'));
					}
					if(weektime[intIdx].work_start_time4 != null && weektime[intIdx].work_start_time4 != ''){
						setSuStartTime4(new Date(strDate + weektime[intIdx].work_start_time4 + ':00'));
					}
					if(weektime[intIdx].work_end_time1 != null && weektime[intIdx].work_end_time1 != ''){
						setSuEndTime1(new Date(strDate + weektime[intIdx].work_end_time1 + ':00'));
					}
					if(weektime[intIdx].work_end_time2 != null && weektime[intIdx].work_end_time2 != ''){
						setSuEndTime2(new Date(strDate + weektime[intIdx].work_end_time2 + ':00'));
					}
					if(weektime[intIdx].work_end_time3 != null && weektime[intIdx].work_end_time3 != ''){
						setSuEndTime3(new Date(strDate + weektime[intIdx].work_end_time3 + ':00'));
					}
					if(weektime[intIdx].work_end_time4 != null && weektime[intIdx].work_end_time4 != ''){
						setSuEndTime4(new Date(strDate + weektime[intIdx].work_end_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '7'){
					//-- 祝
					if(weektime[intIdx].work_start_time1 != null && weektime[intIdx].work_start_time1 != ''){
						setHoStartTime1(new Date(strDate + weektime[intIdx].work_start_time1 + ':00'));
					}
					if(weektime[intIdx].work_start_time2 != null && weektime[intIdx].work_start_time2 != ''){
						setHoStartTime2(new Date(strDate + weektime[intIdx].work_start_time2 + ':00'));
					}
					if(weektime[intIdx].work_start_time3 != null && weektime[intIdx].work_start_time3 != ''){
						setHoStartTime3(new Date(strDate + weektime[intIdx].work_start_time3 + ':00'));
					}
					if(weektime[intIdx].work_start_time4 != null && weektime[intIdx].work_start_time4 != ''){
						setHoStartTime4(new Date(strDate + weektime[intIdx].work_start_time4 + ':00'));
					}
					if(weektime[intIdx].work_end_time1 != null && weektime[intIdx].work_end_time1 != ''){
						setHoEndTime1(new Date(strDate + weektime[intIdx].work_end_time1 + ':00'));
					}
					if(weektime[intIdx].work_end_time2 != null && weektime[intIdx].work_end_time2 != ''){
						setHoEndTime2(new Date(strDate + weektime[intIdx].work_end_time2 + ':00'));
					}
					if(weektime[intIdx].work_end_time3 != null && weektime[intIdx].work_end_time3 != ''){
						setHoEndTime3(new Date(strDate + weektime[intIdx].work_end_time3 + ':00'));
					}
					if(weektime[intIdx].work_end_time4 != null && weektime[intIdx].work_end_time4 != ''){
						setHoEndTime4(new Date(strDate + weektime[intIdx].work_end_time4 + ':00'));
					}
				}

				defWeekTime[intIdx].work_start_time1 = weektime[intIdx].work_start_time1 != null ? weektime[intIdx].work_start_time1 : '';
				defWeekTime[intIdx].work_start_time2 = weektime[intIdx].work_start_time2 != null ? weektime[intIdx].work_start_time2 : '';
				defWeekTime[intIdx].work_start_time3 = weektime[intIdx].work_start_time3 != null ? weektime[intIdx].work_start_time3 : '';
				defWeekTime[intIdx].work_start_time4 = weektime[intIdx].work_start_time4 != null ? weektime[intIdx].work_start_time4 : '';
				defWeekTime[intIdx].work_end_time1 = weektime[intIdx].work_end_time1 != null ? weektime[intIdx].work_end_time1 : '';
				defWeekTime[intIdx].work_end_time2 = weektime[intIdx].work_end_time2 != null ? weektime[intIdx].work_end_time2 : '';
				defWeekTime[intIdx].work_end_time3 = weektime[intIdx].work_end_time3 != null ? weektime[intIdx].work_end_time3 : '';
				defWeekTime[intIdx].work_end_time4 = weektime[intIdx].work_end_time4 != null ? weektime[intIdx].work_end_time4 : '';
			}
		}else{
			for(var intIdx = 0; intIdx <= 7; intIdx++) {
				defWeekTime[intIdx].work_start_time1 = '';
				defWeekTime[intIdx].work_start_time2 = '';
				defWeekTime[intIdx].work_start_time3 = '';
				defWeekTime[intIdx].work_start_time4 = '';
				defWeekTime[intIdx].work_end_time1 = '';
				defWeekTime[intIdx].work_end_time2 = '';
				defWeekTime[intIdx].work_end_time3 = '';
				defWeekTime[intIdx].work_end_time4 = '';
			}
		}

	}, [weektime])

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		//--- 変更チェック
		let flgCng = fncChkBackData();
		if(flgCng == '1') {
			if (window.confirm(MSG_SYSTEM_UPD_BACK)) {
				//-- 戻る
				submitReturn(dbconn);
			}
		}else{
			//-- 戻る
			submitReturn(dbconn);
		}
	}

	/**
	 * 変更項目チェック
	 * @returns {string}
	 */
	function fncChkBackData(){
		//--- 削除モードは判定しない
		if(data.mode == '3') return '0';

		//--- データ格納
		fncSetDataStaffTime();
		fncSetDataWeekTime();

		//--- フォーマット変換
		defData.zaiseki_start_date = defData.zaiseki_start_date.toString().replace('-','/');
		defData.zaiseki_start_date = defData.zaiseki_start_date.toString().replace('-','/');
		defData.zaiseki_end_date = defData.zaiseki_end_date.toString().replace('-','/');
		defData.zaiseki_end_date = defData.zaiseki_end_date.toString().replace('-','/');
		defData.limit_date = defData.limit_date.toString().replace('-','/');
		defData.limit_date = defData.limit_date.toString().replace('-','/');

		//--- NULL変換
		fieldData.staff_nm = fieldData.staff_nm != null ? fieldData.staff_nm : '';
		fieldData.staff_kn = fieldData.staff_kn != null ? fieldData.staff_kn : '';
		fieldData.staff_disp_nm = fieldData.staff_disp_nm != null ? fieldData.staff_disp_nm : '';
		fieldData.staff_tel = fieldData.staff_tel != null ? fieldData.staff_tel : '';
		fieldData.staff_sex = fieldData.staff_sex != null ? fieldData.staff_sex : '';
		fieldData.staff_birthday_year = fieldData.staff_birthday_year != null ? fieldData.staff_birthday_year : '';
		fieldData.staff_birthday_month = fieldData.staff_birthday_month != null ? fieldData.staff_birthday_month : '';
		fieldData.staff_birthday_day = fieldData.staff_birthday_day != null ? fieldData.staff_birthday_day : '';
		fieldData.zaiseki_start_date = fieldData.zaiseki_start_date != null ? fieldData.zaiseki_start_date : '';
		fieldData.zaiseki_end_date = fieldData.zaiseki_end_date != null ? fieldData.zaiseki_end_date : '';
		fieldData.work_start_time1 = fieldData.work_start_time1 != null ? fieldData.work_start_time1 : '';
		fieldData.work_end_time1 = fieldData.work_end_time1 != null ? fieldData.work_end_time1 : '';
		fieldData.work_start_time2 = fieldData.work_start_time2 != null ? fieldData.work_start_time2 : '';
		fieldData.work_end_time2 = fieldData.work_end_time2 != null ? fieldData.work_end_time2 : '';
		fieldData.work_start_time3 = fieldData.work_start_time3 != null ? fieldData.work_start_time3 : '';
		fieldData.work_end_time3 = fieldData.work_end_time3 != null ? fieldData.work_end_time3 : '';
		fieldData.work_start_time4 = fieldData.work_start_time4 != null ? fieldData.work_start_time4 : '';
		fieldData.work_end_time4 = fieldData.work_end_time4 != null ? fieldData.work_end_time4 : '';
		fieldData.staff_mail = fieldData.staff_mail != null ? fieldData.staff_mail : '';
		fieldData.email = fieldData.email != null ? fieldData.email : '';
		fieldData.login_pass = fieldData.login_pass != null ? fieldData.login_pass : '';
		fieldData.kengen_kbn = fieldData.kengen_kbn != null ? fieldData.kengen_kbn : '';
		fieldData.limit_date = fieldData.limit_date != null ? fieldData.limit_date : '';
		fieldData.disp_no = fieldData.disp_no != null ? fieldData.disp_no : '';
		fieldData.disp_flg = fieldData.disp_flg != null ? fieldData.disp_flg : '';
		fieldData.rec_mail_kbn = fieldData.rec_mail_kbn != null ? fieldData.rec_mail_kbn : '';

		//--- スタッフ情報
		if(fieldData.staff_nm != defData.staff_nm ||
			fieldData.staff_kn != defData.staff_kn ||
			fieldData.staff_disp_nm != defData.staff_disp_nm ||
			fieldData.staff_tel != defData.staff_tel ||
			fieldData.staff_sex != defData.staff_sex ||
			fieldData.staff_birthday_year != defData.staff_birthday_year ||
			fieldData.staff_birthday_month != defData.staff_birthday_month ||
			fieldData.staff_birthday_day != defData.staff_birthday_day ||
			fieldData.zaiseki_start_date != defData.zaiseki_start_date ||
			fieldData.zaiseki_end_date != defData.zaiseki_end_date ||
			fieldData.work_start_time1 != defData.work_start_time1 ||
			fieldData.work_end_time1 != defData.work_end_time1 ||
			fieldData.work_start_time2 != defData.work_start_time2 ||
			fieldData.work_end_time2 != defData.work_end_time2 ||
			fieldData.work_start_time3 != defData.work_start_time3 ||
			fieldData.work_end_time3 != defData.work_end_time3 ||
			fieldData.work_start_time4 != defData.work_start_time4 ||
			fieldData.work_end_time4 != defData.work_end_time4 ||
			fieldData.staff_mail != defData.staff_mail ||
			fieldData.email != defData.email ||
			(fieldData.login_pass != defData.login_pass && fieldData.login_pass != "AbCd1234") ||
			fieldData.kengen_kbn != defData.kengen_kbn ||
			fieldData.limit_date != defData.limit_date ||
			fieldData.disp_no != defData.disp_no ||
			fieldData.disp_flg != defData.disp_flg ||
			fieldData.rec_mail_kbn != defData.rec_mail_kbn){
			return '1';
		}

		//--- 曜日別出勤時間
		for(let idx = 0; idx <= 7; idx++){
			if(fieldWeekTime[idx].work_start_time1 != defWeekTime[idx].work_start_time1 ||
				fieldWeekTime[idx].work_end_time1 != defWeekTime[idx].work_end_time1 ||
				fieldWeekTime[idx].work_start_time2 != defWeekTime[idx].work_start_time2 ||
				fieldWeekTime[idx].work_end_time2 != defWeekTime[idx].work_end_time2 ||
				fieldWeekTime[idx].work_start_time3 != defWeekTime[idx].work_start_time3 ||
				fieldWeekTime[idx].work_end_time3 != defWeekTime[idx].work_end_time3 ||
				fieldWeekTime[idx].work_start_time4 != defWeekTime[idx].work_start_time4 ||
				fieldWeekTime[idx].work_end_time4 != defWeekTime[idx].work_end_time4){
				return '1';
			}
		}

		//--- スタッフコース
		for(let idxCourse in staffcourse){
			staffcourse[idxCourse].staff_course_id = staffcourse[idxCourse].staff_course_id != null ? staffcourse[idxCourse].staff_course_id : '';
			defstaffcourse[idxCourse].staff_course_id = defstaffcourse[idxCourse].staff_course_id != null ? defstaffcourse[idxCourse].staff_course_id : '';
			if(staffcourse[idxCourse].staff_course_id != defstaffcourse[idxCourse].staff_course_id){
				return '1';
			}
		}

		//--- 受信メール
		for(let idxReceive in staffreceive){
			staffreceive[idxReceive].receive_flg = staffreceive[idxReceive].receive_flg != null ? staffreceive[idxReceive].receive_flg : '';
			defstaffreceive[idxReceive].receive_flg = defstaffreceive[idxReceive].receive_flg != null ? defstaffreceive[idxReceive].receive_flg : '';
			if(staffreceive[idxReceive].receive_flg != defstaffreceive[idxReceive].receive_flg){
				return '1';
			}
		}

		return '0';
	}

	/**
	 * 時間反映ボタン
	 */
	function fncOnClickBaseTime(){
		//--- 出勤時間1
		if(baseStartTime1 != '' && baseStartTime1 != null){
			//- 曜日別データ判定
			if(baseweek == '' || baseweek == undefined || baseweek == null){
				// データが存在しない場合、無条件でコピー
				setMoStartTime1(baseStartTime1);
				setTuStartTime1(baseStartTime1);
				setWeStartTime1(baseStartTime1);
				setThStartTime1(baseStartTime1);
				setFrStartTime1(baseStartTime1);
				setSaStartTime1(baseStartTime1);
				setSuStartTime1(baseStartTime1);
				setHoStartTime1(baseStartTime1);
			}else {
				// データが存在する場合、休日以外コピー
				if(baseweek[0].regular_holiday == '0'){
					setMoStartTime1(baseStartTime1);
				}else{
					setMoStartTime1('');
				}
				if(baseweek[1].regular_holiday == '0'){
					setTuStartTime1(baseStartTime1);
				}else{
					setTuStartTime1('');
				}
				if(baseweek[2].regular_holiday == '0'){
					setWeStartTime1(baseStartTime1);
				}else{
					setWeStartTime1('');
				}
				if(baseweek[3].regular_holiday == '0') {
					setThStartTime1(baseStartTime1);
				}else{
					setThStartTime1('');
				}
				if(baseweek[4].regular_holiday == '0') {
					setFrStartTime1(baseStartTime1);
				}else{
					setFrStartTime1('');
				}
				if(baseweek[5].regular_holiday == '0') {
					setSaStartTime1(baseStartTime1);
				}else{
					setSaStartTime1('');
				}
				if(baseweek[6].regular_holiday == '0') {
					setSuStartTime1(baseStartTime1);
				}else{
					setSuStartTime1('');
				}
				if(baseweek[7].regular_holiday == '0') {
					setHoStartTime1(baseStartTime1);
				}else{
					setHoStartTime1('');
				}
			}
		}else{
			setMoStartTime1('');
			setTuStartTime1('');
			setWeStartTime1('');
			setThStartTime1('');
			setFrStartTime1('');
			setSaStartTime1('');
			setSuStartTime1('');
			setHoStartTime1('');
		}
		if(baseEndTime1 != '' && baseEndTime1 != null){
			//- 曜日別データ判定
			if(baseweek == '' || baseweek == undefined || baseweek == null){
				// データが存在しない場合、無条件でコピー
				setMoEndTime1(baseEndTime1);
				setTuEndTime1(baseEndTime1);
				setWeEndTime1(baseEndTime1);
				setThEndTime1(baseEndTime1);
				setFrEndTime1(baseEndTime1);
				setSaEndTime1(baseEndTime1);
				setSuEndTime1(baseEndTime1);
				setHoEndTime1(baseEndTime1);
			}else{
				// データが存在する場合、休日以外コピー
				if(baseweek[0].regular_holiday == '0'){
					setMoEndTime1(baseEndTime1);
				}else{
					setMoEndTime1('');
				}
				if(baseweek[1].regular_holiday == '0'){
					setTuEndTime1(baseEndTime1);
				}else{
					setTuEndTime1('');
				}
				if(baseweek[2].regular_holiday == '0'){
					setWeEndTime1(baseEndTime1);
				}else{
					setWeEndTime1('');
				}
				if(baseweek[3].regular_holiday == '0'){
					setThEndTime1(baseEndTime1);
				}else{
					setThEndTime1('');
				}
				if(baseweek[4].regular_holiday == '0'){
					setFrEndTime1(baseEndTime1);
				}else{
					setFrEndTime1('');
				}
				if(baseweek[5].regular_holiday == '0'){
					setSaEndTime1(baseEndTime1);
				}else{
					setSaEndTime1('');
				}
				if(baseweek[6].regular_holiday == '0'){
					setSuEndTime1(baseEndTime1);
				}else{
					setSuEndTime1('');
				}
				if(baseweek[7].regular_holiday == '0'){
					setHoEndTime1(baseEndTime1);
				}else{
					setHoEndTime1('');
				}
			}
		}else{
			setMoEndTime1('');
			setTuEndTime1('');
			setWeEndTime1('');
			setThEndTime1('');
			setFrEndTime1('');
			setSaEndTime1('');
			setSuEndTime1('');
			setHoEndTime1('');
		}

		//--- 出勤時間2
		if(baseStartTime2 != '' && baseStartTime2 != null){
			//- 曜日別データ判定
			if(baseweek == '' || baseweek == undefined || baseweek == null){
				// データが存在しない場合、無条件でコピー
				setMoStartTime2(baseStartTime2);
				setTuStartTime2(baseStartTime2);
				setWeStartTime2(baseStartTime2);
				setThStartTime2(baseStartTime2);
				setFrStartTime2(baseStartTime2);
				setSaStartTime2(baseStartTime2);
				setSuStartTime2(baseStartTime2);
				setHoStartTime2(baseStartTime2);
			}else{
				// データが存在する場合、休日以外コピー
				if(baseweek[0].regular_holiday == '0'){
					setMoStartTime2(baseStartTime2);
				}else{
					setMoStartTime2('');
				}
				if(baseweek[1].regular_holiday == '0'){
					setTuStartTime2(baseStartTime2);
				}else{
					setTuStartTime2('');
				}
				if(baseweek[2].regular_holiday == '0'){
					setWeStartTime2(baseStartTime2);
				}else{
					setWeStartTime2('');
				}
				if(baseweek[3].regular_holiday == '0'){
					setThStartTime2(baseStartTime2);
				}else{
					setThStartTime2('');
				}
				if(baseweek[4].regular_holiday == '0'){
					setFrStartTime2(baseStartTime2);
				}else{
					setFrStartTime2('');
				}
				if(baseweek[5].regular_holiday == '0'){
					setSaStartTime2(baseStartTime2);
				}else{
					setSaStartTime2('');
				}
				if(baseweek[6].regular_holiday == '0'){
					setSuStartTime2(baseStartTime2);
				}else{
					setSuStartTime2('');
				}
				if(baseweek[7].regular_holiday == '0'){
					setHoStartTime2(baseStartTime2);
				}else{
					setHoStartTime2('');
				}
			}
		}else{
			setMoStartTime2('');
			setTuStartTime2('');
			setWeStartTime2('');
			setThStartTime2('');
			setFrStartTime2('');
			setSaStartTime2('');
			setSuStartTime2('');
			setHoStartTime2('');
		}
		if(baseEndTime2 != '' && baseEndTime2 != null){
			//- 曜日別データ判定
			if(baseweek == '' || baseweek == undefined || baseweek == null){
				// データが存在しない場合、無条件でコピー
				setMoEndTime2(baseEndTime2);
				setTuEndTime2(baseEndTime2);
				setWeEndTime2(baseEndTime2);
				setThEndTime2(baseEndTime2);
				setFrEndTime2(baseEndTime2);
				setSaEndTime2(baseEndTime2);
				setSuEndTime2(baseEndTime2);
				setHoEndTime2(baseEndTime2);
			}else{
				// データが存在する場合、休日以外コピー
				if(baseweek[0].regular_holiday == '0'){
					setMoEndTime2(baseEndTime2);
				}else{
					setMoEndTime2('');
				}
				if(baseweek[1].regular_holiday == '0'){
					setTuEndTime2(baseEndTime2);
				}else{
					setTuEndTime2('');
				}
				if(baseweek[2].regular_holiday == '0'){
					setWeEndTime2(baseEndTime2);
				}else{
					setWeEndTime2('');
				}
				if(baseweek[3].regular_holiday == '0'){
					setThEndTime2(baseEndTime2);
				}else{
					setThEndTime2('');
				}
				if(baseweek[4].regular_holiday == '0'){
					setFrEndTime2(baseEndTime2);
				}else{
					setFrEndTime2('');
				}
				if(baseweek[5].regular_holiday == '0'){
					setSaEndTime2(baseEndTime2);
				}else{
					setSaEndTime2('');
				}
				if(baseweek[6].regular_holiday == '0'){
					setSuEndTime2(baseEndTime2);
				}else{
					setSuEndTime2('');
				}
				if(baseweek[7].regular_holiday == '0'){
					setHoEndTime2(baseEndTime2);
				}else{
					setHoEndTime2('');
				}
			}
		}else{
			setMoEndTime2('');
			setTuEndTime2('');
			setWeEndTime2('');
			setThEndTime2('');
			setFrEndTime2('');
			setSaEndTime2('');
			setSuEndTime2('');
			setHoEndTime2('');
		}

		//--- 出勤時間3
		if(baseStartTime3 != '' && baseStartTime3 != null){
			//- 曜日別データ判定
			if(baseweek == '' || baseweek == undefined || baseweek == null){
				// データが存在しない場合、無条件でコピー
				setMoStartTime3(baseStartTime3);
				setTuStartTime3(baseStartTime3);
				setWeStartTime3(baseStartTime3);
				setThStartTime3(baseStartTime3);
				setFrStartTime3(baseStartTime3);
				setSaStartTime3(baseStartTime3);
				setSuStartTime3(baseStartTime3);
				setHoStartTime3(baseStartTime3);
			}else{
				// データが存在する場合、休日以外コピー
				if(baseweek[0].regular_holiday == '0'){
					setMoStartTime3(baseStartTime3);
				}else{
					setMoStartTime3('');
				}
				if(baseweek[1].regular_holiday == '0'){
					setTuStartTime3(baseStartTime3);
				}else{
					setTuStartTime3('');
				}
				if(baseweek[2].regular_holiday == '0'){
					setWeStartTime3(baseStartTime3);
				}else{
					setWeStartTime3('');
				}
				if(baseweek[3].regular_holiday == '0'){
					setThStartTime3(baseStartTime3);
				}else{
					setThStartTime3('');
				}
				if(baseweek[4].regular_holiday == '0'){
					setFrStartTime3(baseStartTime3);
				}else{
					setFrStartTime3('');
				}
				if(baseweek[5].regular_holiday == '0'){
					setSaStartTime3(baseStartTime3);
				}else{
					setSaStartTime3('');
				}
				if(baseweek[6].regular_holiday == '0'){
					setSuStartTime3(baseStartTime3);
				}else{
					setSuStartTime3('');
				}
				if(baseweek[7].regular_holiday == '0'){
					setHoStartTime3(baseStartTime3);
				}else{
					setHoStartTime3('');
				}
			}
		}else{
			setMoStartTime3('');
			setTuStartTime3('');
			setWeStartTime3('');
			setThStartTime3('');
			setFrStartTime3('');
			setSaStartTime3('');
			setSuStartTime3('');
			setHoStartTime3('');
		}
		if(baseEndTime3 != '' && baseEndTime3 != null){
			//- 曜日別データ判定
			if(baseweek == '' || baseweek == undefined || baseweek == null){
				// データが存在しない場合、無条件でコピー
				setMoEndTime3(baseEndTime3);
				setTuEndTime3(baseEndTime3);
				setWeEndTime3(baseEndTime3);
				setThEndTime3(baseEndTime3);
				setFrEndTime3(baseEndTime3);
				setSaEndTime3(baseEndTime3);
				setSuEndTime3(baseEndTime3);
				setHoEndTime3(baseEndTime3);
			}else{
				// データが存在する場合、休日以外コピー
				if(baseweek[0].regular_holiday == '0'){
					setMoEndTime3(baseEndTime3);
				}else{
					setMoEndTime3('');
				}
				if(baseweek[1].regular_holiday == '0'){
					setTuEndTime3(baseEndTime3);
				}else{
					setTuEndTime3('');
				}
				if(baseweek[2].regular_holiday == '0'){
					setWeEndTime3(baseEndTime3);
				}else{
					setWeEndTime3('');
				}
				if(baseweek[3].regular_holiday == '0'){
					setThEndTime3(baseEndTime3);
				}else{
					setThEndTime3('');
				}
				if(baseweek[4].regular_holiday == '0'){
					setFrEndTime3(baseEndTime3);
				}else{
					setFrEndTime3('');
				}
				if(baseweek[5].regular_holiday == '0'){
					setSaEndTime3(baseEndTime3);
				}else{
					setSaEndTime3('');
				}
				if(baseweek[6].regular_holiday == '0'){
					setSuEndTime3(baseEndTime3);
				}else{
					setSuEndTime3('');
				}
				if(baseweek[7].regular_holiday == '0'){
					setHoEndTime3(baseEndTime3);
				}else{
					setHoEndTime3('');
				}
			}
		}else{
			setMoEndTime3('');
			setTuEndTime3('');
			setWeEndTime3('');
			setThEndTime3('');
			setFrEndTime3('');
			setSaEndTime3('');
			setSuEndTime3('');
			setHoEndTime3('');
		}

		//--- 出勤時間3
		if(baseStartTime4 != '' && baseStartTime4 != null){
			//- 曜日別データ判定
			if(baseweek == '' || baseweek == undefined || baseweek == null){
				// データが存在しない場合、無条件でコピー
				setMoStartTime4(baseStartTime4);
				setTuStartTime4(baseStartTime4);
				setWeStartTime4(baseStartTime4);
				setThStartTime4(baseStartTime4);
				setFrStartTime4(baseStartTime4);
				setSaStartTime4(baseStartTime4);
				setSuStartTime4(baseStartTime4);
				setHoStartTime4(baseStartTime4);
			}else{
				// データが存在する場合、休日以外コピー
				if(baseweek[0].regular_holiday == '0'){
					setMoStartTime4(baseStartTime4);
				}else{
					setMoStartTime4('');
				}
				if(baseweek[1].regular_holiday == '0'){
					setTuStartTime4(baseStartTime4);
				}else{
					setTuStartTime4('');
				}
				if(baseweek[2].regular_holiday == '0'){
					setWeStartTime4(baseStartTime4);
				}else{
					setWeStartTime4('');
				}
				if(baseweek[3].regular_holiday == '0'){
					setThStartTime4(baseStartTime4);
				}else{
					setThStartTime4('');
				}
				if(baseweek[4].regular_holiday == '0'){
					setFrStartTime4(baseStartTime4);
				}else{
					setFrStartTime4('');
				}
				if(baseweek[5].regular_holiday == '0'){
					setSaStartTime4(baseStartTime4);
				}else{
					setSaStartTime4('');
				}
				if(baseweek[6].regular_holiday == '0'){
					setSuStartTime4(baseStartTime4);
				}else{
					setSuStartTime4('');
				}
				if(baseweek[7].regular_holiday == '0'){
					setHoStartTime4(baseStartTime4);
				}else{
					setHoStartTime4('');
				}
			}
		}else{
			setMoStartTime4('');
			setTuStartTime4('');
			setWeStartTime4('');
			setThStartTime4('');
			setFrStartTime4('');
			setSaStartTime4('');
			setSuStartTime4('');
			setHoStartTime4('');
		}
		if(baseEndTime4 != '' && baseEndTime4 != null){
			//- 曜日別データ判定
			if(baseweek == '' || baseweek == undefined || baseweek == null){
				// データが存在しない場合、無条件でコピー
				setMoEndTime4(baseEndTime4);
				setTuEndTime4(baseEndTime4);
				setWeEndTime4(baseEndTime4);
				setThEndTime4(baseEndTime4);
				setFrEndTime4(baseEndTime4);
				setSaEndTime4(baseEndTime4);
				setSuEndTime4(baseEndTime4);
				setHoEndTime4(baseEndTime4);
			}else{
				// データが存在する場合、休日以外コピー
				if(baseweek[0].regular_holiday == '0'){
					setMoEndTime4(baseEndTime4);
				}else{
					setMoEndTime4('');
				}
				if(baseweek[1].regular_holiday == '0'){
					setTuEndTime4(baseEndTime4);
				}else{
					setTuEndTime4('');
				}
				if(baseweek[2].regular_holiday == '0'){
					setWeEndTime4(baseEndTime4);
				}else{
					setWeEndTime4('');
				}
				if(baseweek[3].regular_holiday == '0'){
					setThEndTime4(baseEndTime4);
				}else{
					setThEndTime4('');
				}
				if(baseweek[4].regular_holiday == '0'){
					setFrEndTime4(baseEndTime4);
				}else{
					setFrEndTime4('');
				}
				if(baseweek[5].regular_holiday == '0'){
					setSaEndTime4(baseEndTime4);
				}else{
					setSaEndTime4('');
				}
				if(baseweek[6].regular_holiday == '0'){
					setSuEndTime4(baseEndTime4);
				}else{
					setSuEndTime4('');
				}
				if(baseweek[7].regular_holiday == '0'){
					setHoEndTime4(baseEndTime4);
				}else{
					setHoEndTime4('');
				}
			}
		}else{
			setMoEndTime4('');
			setTuEndTime4('');
			setWeEndTime4('');
			setThEndTime4('');
			setFrEndTime4('');
			setSaEndTime4('');
			setSuEndTime4('');
			setHoEndTime4('');
		}
	}

	/**
	 * 全選択
	 */
	function fncOnClickAllSel(){
		if(staffcourse == undefined) return;
		if(staffcourse.length > 0){
			for(var intIdx = 0; intIdx < staffcourse.length; intIdx++){
				if(staffcourse[intIdx].tool_reserve_cnt_flg != '1'){
					staffcourse[intIdx].staff_course_id = staffcourse[intIdx].course_id;
					document.getElementById('staff-course-' + intIdx).checked = true;
				}
			}
		}
	}

	/**
	 * 全解除
	 */
	function fncOnClickAllKaijo(){
		if(staffcourse == undefined) return;
		if(staffcourse.length > 0){
			for(var intIdx = 0; intIdx < staffcourse.length; intIdx++){
				if(staffcourse[intIdx].tool_reserve_cnt_flg != '1'){
					staffcourse[intIdx].staff_course_id = '';
					document.getElementById('staff-course-' + intIdx).checked = false;
				}
			}
		}
	}

	/**
	 *
	 * 更新ボタン
	 */
	function fncOnClickUpdate(e){
		if(dbconn.id == '' || dbconn.id == undefined) return;

		//--- データ格納
		fncSetDataStaffTime();
		fncSetDataWeekTime();

		e.preventDefault();

		//--- エラーメッセージクリア
		setErrorClear();
		setLoading('1');

		//-- リクエストデータ
		fieldData.id = dbconn.id;
		var aryData = {
			staff_nm : fieldData.staff_nm,
			staff_kn: fieldData.staff_kn,
			zaiseki_start_date : fieldData.zaiseki_start_date,
			disp_no : fieldData.disp_no,
			limit_date : fieldData.limit_date,
			staff : [],
			staffcourse:[],
		}
		aryData.staff.push(fieldData);
		aryData.staffcourse.push(staffcourse);

		//--- チェック
		submitPost('api/chk-staff',{
			...aryData,
		});
	}

	/**
	 * チェック結果エラー
	 */
	useEffect(() => {
		if(errors == undefined) return;
		if (Object.keys(errors).length > 0) {
			if(errors.staff_course){
				let errMsg = '';
				let idx = 0;
				for(idx = 0; idx < errors.staff_course.length; idx++){
					errMsg += errors.staff_course[idx];
					errMsg += '\n';
				}
				setErrMode('1');
				setModalMsg(errMsg);
				setLoading('0');
				setShow(true);
			}else{
				window.scrollTo(0, 0);
				setLoading('0');
			}

		}
	}, [errors])

	/**
	 * チェック結果正常
	 */
	useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
		if (Object.keys(result).length > 0) {
			//--- データ格納
			fieldData.update_id = user.staff_id;

			//--- 登録
			//-- リクエストデータ
			var aryData = {
				staff : [],
				weektime : [],
				staffcourse:[],
				staffreceive:[],
				id : dbconn.id,
			}
			aryData.staff.push(fieldData);
			aryData.weektime.push(fieldWeekTime);
			aryData.staffcourse.push(staffcourse);
			aryData.staffreceive.push(staffreceive);
			//-- POST
			Http.post('/api/add-staff', {
				...aryData,
			}).then(response => {
				const strStatus = response.data.status;
				if(strStatus == '200'){
					setErrMode('0');
					if(data.mode == '1'){
						setModalMsg(MSG_CMP_ADD);
					}else if(data.mode == '2'){
						setModalMsg(MSG_CMP_UPD);
					}else if(data.mode == '3'){
						setModalMsg(MSG_CMP_DEL);
					}
				}else if(strStatus == '300'){
					setErrMode('1');
					setModalMsg(MSG_NG_TIME);
					setShow(true);
				}else{
					const strError = response.data.error;
					setErrMode('1');
					if(data.mode == '1'){
						setModalMsg(MSG_NG_ADD + strError);
					}else if(data.mode == '2'){
						setModalMsg(MSG_NG_UPD + strError);
					}else if(data.mode == '3'){
						setModalMsg(MSG_NG_DEL + strError);
					}
				}
				//setShow(true);
			}).catch(error => {
				if (error.response) {
					setErrMode('1');
					var strErrMng = '';
					if(data.mode == '1'){
						strErrMng = MSG_NG_ADD;
					}else if(data.mode == '2'){
						strErrMng = MSG_NG_UPD;
					}else if(data.mode == '3'){
						strErrMng = MSG_NG_DEL;
					}
					strErrMng += error.response.data;
					setModalMsg(strErrMng);
				}
			}).finally(() => {
				setLoading('0');
				setShow(true);
			})
		}
	}, [result]);

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);
		if(errMode == '0'){
			submitReturn(dbconn);
		}
	}

	/**
	 * スタッフ時間情報格納
	 */
	function fncSetDataStaffTime(){
		//--- データ格納
		//-- 生年月日
		if(birthDate != '' && birthDate != null){
			fieldData.staff_birthday_year = birthDate.getFullYear().toString(); //fieldData.birth_date.year;
			fieldData.staff_birthday_month = ('0' + (birthDate.getMonth() + 1)).slice(-2); //fieldData.birth_date.month;
			fieldData.staff_birthday_day = ('0' + birthDate.getDate()).slice(-2); //fieldData.birth_date.day;
		}else{
			fieldData.staff_birthday_year = '';
			fieldData.staff_birthday_month = '';
			fieldData.staff_birthday_day = '';
		}
		//-- 在籍開始日
		if(startDate != '' && startDate != null){
			fieldData.zaiseki_start_date = startDate.getFullYear() + '/' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '/' +('0' + startDate.getDate()).slice(-2);
		}else{
			fieldData.zaiseki_start_date = '';
		}
		//-- 在籍終了日
		if(endDate != '' && endDate != null){
			fieldData.zaiseki_end_date = endDate.getFullYear() + '/' + ('0' + (endDate.getMonth() + 1)).slice(-2) + '/' +('0' + endDate.getDate()).slice(-2);
		}else{
			fieldData.zaiseki_end_date = '';
		}
		//-- 出勤開始時間1
		if(baseStartTime1 != '' && baseStartTime1 != null){
			fieldData.work_start_time1 = ('0' + baseStartTime1.getHours()).slice(-2) + ':' + ('0' + baseStartTime1.getMinutes()).slice(-2);
		}else{
			fieldData.work_start_time1 = '';
		}
		//-- 出勤終了時間1
		if(baseEndTime1 != '' && baseEndTime1 != null){
			fieldData.work_end_time1 = ('0' + baseEndTime1.getHours()).slice(-2) + ':' + ('0' + baseEndTime1.getMinutes()).slice(-2);
		}else{
			fieldData.work_end_time1 = '';
		}
		//-- 出勤開始時間2
		if(baseStartTime2 != '' && baseStartTime2 != null){
			fieldData.work_start_time2 = ('0' + baseStartTime2.getHours()).slice(-2) + ':' + ('0' + baseStartTime2.getMinutes()).slice(-2);
		}else{
			fieldData.work_start_time2 = '';
		}
		//-- 出勤終了時間2
		if(baseEndTime2 != '' && baseEndTime2 != null){
			fieldData.work_end_time2 = ('0' + baseEndTime2.getHours()).slice(-2) + ':' + ('0' + baseEndTime2.getMinutes()).slice(-2);
		}else{
			fieldData.work_end_time2 = '';
		}
		//-- 出勤開始時間3
		if(baseStartTime3 != '' && baseStartTime3 != null){
			fieldData.work_start_time3 = ('0' + baseStartTime3.getHours()).slice(-2) + ':' + ('0' + baseStartTime3.getMinutes()).slice(-2);
		}else{
			fieldData.work_start_time3 = '';
		}
		//-- 出勤終了時間3
		if(baseEndTime3 != '' && baseEndTime3 != null){
			fieldData.work_end_time3 = ('0' + baseEndTime3.getHours()).slice(-2) + ':' + ('0' + baseEndTime3.getMinutes()).slice(-2);
		}else{
			fieldData.work_end_time3 = '';
		}
		//-- 出勤開始時間4
		if(baseStartTime4 != '' && baseStartTime4 != null){
			fieldData.work_start_time4 = ('0' + baseStartTime4.getHours()).slice(-2) + ':' + ('0' + baseStartTime4.getMinutes()).slice(-2);
		}else{
			fieldData.work_start_time4 = '';
		}
		//-- 出勤終了時間4
		if(baseEndTime4 != '' && baseEndTime4 != null){
			fieldData.work_end_time4 = ('0' + baseEndTime4.getHours()).slice(-2) + ':' + ('0' + baseEndTime4.getMinutes()).slice(-2);
		}else{
			fieldData.work_end_time4 = '';
		}
		//-- パスワード有効期限
		if(limitDate != '' && limitDate != null){
			fieldData.limit_date = limitDate.getFullYear() + '/' + ('0' + (limitDate.getMonth() + 1)).slice(-2) + '/' +('0' + limitDate.getDate()).slice(-2);
		}else{
			fieldData.limit_date = '';
		}

		//-- 更新者ID
		fieldData.update_id = '';
		//-- モード
		fieldData.mode = data.mode;
	}

	/**
	 * スタッフ曜日別時間格納
	 */
	function fncSetDataWeekTime() {
		//--- 共通
		var strStartDate = '';
		var strEndDate = '';
		//-- 開始日
		if(startDate != '' && startDate != null){
			strStartDate = startDate.getFullYear() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getDate();
		}
		//-- 終了日
		if(endDate != '' && endDate != null){
			strEndDate = endDate.getFullYear() + '/' + (endDate.getMonth() + 1) + '/' + endDate.getDate();
		}

		//--- 月
		//-- 店舗ID
		fieldWeekTime[0].staff_id = data.staff_id;
		//-- 曜日区分
		fieldWeekTime[0].week_kbn = '0';
		//-- 出勤開始時間1
		if(moStartTime1 != '' && moStartTime1 != null){
			fieldWeekTime[0].work_start_time1 = ('0' + moStartTime1.getHours()).slice(-2) + ':' +('0' + moStartTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[0].work_start_time1 = '';
		}
		//-- 出勤終了時間1
		if(moEndTime1 != '' && moEndTime1 != null){
			fieldWeekTime[0].work_end_time1 = ('0' + moEndTime1.getHours()).slice(-2) + ':' + ('0' + moEndTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[0].work_end_time1 = '';
		}
		//-- 出勤開始時間2
		if(moStartTime2 != '' && moStartTime2 != null){
			fieldWeekTime[0].work_start_time2 = ('0' + moStartTime2.getHours()).slice(-2) + ':' + ('0' + moStartTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[0].work_start_time2 = '';
		}
		//-- 出勤終了時間2
		if(moEndTime2 != '' && moEndTime2 != null){
			fieldWeekTime[0].work_end_time2 = ('0' + moEndTime2.getHours()).slice(-2) + ':' + ('0' + moEndTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[0].work_end_time2 = '';
		}
		//-- 出勤開始時間3
		if(moStartTime3 != '' && moStartTime3 != null){
			fieldWeekTime[0].work_start_time3 = ('0' + moStartTime3.getHours()).slice(-2) + ':' + ('0' + moStartTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[0].work_start_time3 = '';
		}
		//-- 出勤終了時間3
		if(moEndTime3 != '' && moEndTime3 != null){
			fieldWeekTime[0].work_end_time3 = ('0' + moEndTime3.getHours()).slice(-2) + ':' + ('0' + moEndTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[0].work_end_time3 = '';
		}
		//-- 出勤開始時間4
		if(moStartTime4 != '' && moStartTime4 != null){
			fieldWeekTime[0].work_start_time4 = ('0' + moStartTime4.getHours()).slice(-2) + ':' + ('0' + moStartTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[0].work_start_time4 = '';
		}
		//-- 出勤終了時間4
		if(moEndTime4 != '' && moEndTime4 != null){
			fieldWeekTime[0].work_end_time4 = ('0' + moEndTime4.getHours()).slice(-2) + ':' + ('0' + moEndTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[0].work_end_time4 = '';
		}
		//-- 更新者ID
		fieldWeekTime[0].update_id = '';

		//--- 火
		//-- スタッフID
		fieldWeekTime[1].staff_id = data.staff_id;
		//-- 曜日区分
		fieldWeekTime[1].week_kbn = '1';
		//-- 出勤開始時間1
		if(tuStartTime1 != '' && tuStartTime1 != null){
			fieldWeekTime[1].work_start_time1 = ('0' + tuStartTime1.getHours()).slice(-2) + ':' + ('0' + tuStartTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[1].work_start_time1 = '';
		}
		//-- 出勤終了時間1
		if(tuEndTime1 != '' && tuEndTime1 != null){
			fieldWeekTime[1].work_end_time1 = ('0' + tuEndTime1.getHours()).slice(-2) + ':' + ('0' + tuEndTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[1].work_end_time1 = '';
		}
		//-- 出勤開始時間2
		if(tuStartTime2 != '' && tuStartTime2 != null){
			fieldWeekTime[1].work_start_time2 = ('0' + tuStartTime2.getHours()).slice(-2) + ':' + ('0' + tuStartTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[1].work_start_time2 = '';
		}
		//-- 出勤終了時間2
		if(tuEndTime2 != '' && tuEndTime2 != null){
			fieldWeekTime[1].work_end_time2 = ('0' + tuEndTime2.getHours()).slice(-2) + ':' + ('0' + tuEndTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[1].work_end_time2 = '';
		}
		//-- 出勤開始時間3
		if(tuStartTime3 != '' && tuStartTime3 != null){
			fieldWeekTime[1].work_start_time3 = ('0' + tuStartTime3.getHours()).slice(-2) + ':' + ('0' + tuStartTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[1].work_start_time3 = '';
		}
		//-- 出勤終了時間3
		if(tuEndTime3 != '' && tuEndTime3 != null){
			fieldWeekTime[1].work_end_time3 = ('0' + tuEndTime3.getHours()).slice(-2) + ':' + ('0' + tuEndTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[1].work_end_time3 = '';
		}
		//-- 出勤開始時間4
		if(tuStartTime4 != '' && tuStartTime4 != null){
			fieldWeekTime[1].work_start_time4 = ('0' + tuStartTime4.getHours()).slice(-2) + ':' + ('0' + tuStartTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[1].work_start_time4 = '';
		}
		//-- 出勤終了時間4
		if(tuEndTime4 != '' && tuEndTime4 != null){
			fieldWeekTime[1].work_end_time4 = ('0' + tuEndTime4.getHours()).slice(-2) + ':' + ('0' + tuEndTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[1].work_end_time4 = '';
		}
		//-- 更新者ID
		fieldWeekTime[1].update_id = '';

		//--- 水
		//-- スタッフID
		fieldWeekTime[2].staff_id = data.staff_id;
		//-- 曜日区分
		fieldWeekTime[2].week_kbn = '2';
		//-- 出勤開始時間1
		if(weStartTime1 != '' && weStartTime1 != null){
			fieldWeekTime[2].work_start_time1 = ('0' + weStartTime1.getHours()).slice(-2) + ':' + ('0' + weStartTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[2].work_start_time1 = '';
		}
		//-- 出勤終了時間1
		if(weEndTime1 != '' && weEndTime1 != null){
			fieldWeekTime[2].work_end_time1 = ('0' + weEndTime1.getHours()).slice(-2) + ':' + ('0' + weEndTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[2].work_end_time1 = '';
		}
		//-- 出勤開始時間2
		if(weStartTime2 != '' && weStartTime2 != null){
			fieldWeekTime[2].work_start_time2 = ('0' + weStartTime2.getHours()).slice(-2) + ':' + ('0' + weStartTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[2].work_start_time2 = '';
		}
		//-- 出勤終了時間2
		if(weEndTime2 != '' && weEndTime2 != null){
			fieldWeekTime[2].work_end_time2 = ('0' + weEndTime2.getHours()).slice(-2) + ':' + ('0' + weEndTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[2].work_end_time2 = '';
		}
		//-- 出勤開始時間3
		if(weStartTime3 != '' && weStartTime3 != null){
			fieldWeekTime[2].work_start_time3 = ('0' + weStartTime3.getHours()).slice(-2) + ':' + ('0' + weStartTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[2].work_start_time3 = '';
		}
		//-- 出勤終了時間3
		if(weEndTime3 != '' && weEndTime3 != null){
			fieldWeekTime[2].work_end_time3 = ('0' + weEndTime3.getHours()).slice(-2) + ':' + ('0' + weEndTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[2].work_end_time3 = '';
		}
		//-- 出勤開始時間4
		if(weStartTime4 != '' && weStartTime4 != null){
			fieldWeekTime[2].work_start_time4 = ('0' + weStartTime4.getHours()).slice(-2) + ':' + ('0' + weStartTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[2].work_start_time4 = '';
		}
		//-- 出勤終了時間4
		if(weEndTime4 != '' && weEndTime4 != null){
			fieldWeekTime[2].work_end_time4 = ('0' + weEndTime4.getHours()).slice(-2) + ':' + ('0' + weEndTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[2].work_end_time4 = '';
		}
		//-- 更新者ID
		fieldWeekTime[2].update_id = '';

		//--- 木
		//-- スタッフID
		fieldWeekTime[3].staff_id = data.staff_id;
		//-- 曜日区分
		fieldWeekTime[3].week_kbn = '3';
		//-- 出勤開始時間1
		if(thStartTime1 != '' && thStartTime1 != null){
			fieldWeekTime[3].work_start_time1 = ('0' + thStartTime1.getHours()).slice(-2) + ':' + ('0' + thStartTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[3].work_start_time1 = '';
		}
		//-- 出勤終了時間1
		if(thEndTime1 != '' && thEndTime1 != null){
			fieldWeekTime[3].work_end_time1 = ('0' + thEndTime1.getHours()).slice(-2) + ':' + ('0' + thEndTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[3].work_end_time1 = '';
		}
		//-- 出勤開始時間2
		if(thStartTime2 != '' && thStartTime2 != null){
			fieldWeekTime[3].work_start_time2 = ('0' + thStartTime2.getHours()).slice(-2) + ':' + ('0' + thStartTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[3].work_start_time2 = '';
		}
		//-- 出勤終了時間2
		if(thEndTime2 != '' && thEndTime2 != null){
			fieldWeekTime[3].work_end_time2 = ('0' + thEndTime2.getHours()).slice(-2) + ':' + ('0' + thEndTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[3].work_end_time2 = '';
		}
		//-- 出勤開始時間3
		if(thStartTime3 != '' && thStartTime3 != null){
			fieldWeekTime[3].work_start_time3 = ('0' + thStartTime3.getHours()).slice(-2) + ':' + ('0' + thStartTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[3].work_start_time3 = '';
		}
		//-- 出勤終了時間3
		if(thEndTime3 != '' && thEndTime3 != null){
			fieldWeekTime[3].work_end_time3 = ('0' + thEndTime3.getHours()).slice(-2) + ':' + ('0' + thEndTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[3].work_end_time3 = '';
		}
		//-- 出勤開始時間4
		if(thStartTime4 != '' && thStartTime4 != null){
			fieldWeekTime[3].work_start_time4 = ('0' + thStartTime4.getHours()).slice(-2) + ':' + ('0' + thStartTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[3].work_start_time4 = '';
		}
		//-- 出勤終了時間4
		if(thEndTime4 != '' && thEndTime4 != null){
			fieldWeekTime[3].work_end_time4 = ('0' + thEndTime4.getHours()).slice(-2) + ':' + ('0' + thEndTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[3].work_end_time4 = '';
		}
		//-- 更新者ID
		fieldWeekTime[3].update_id = '';

		//--- 金
		//-- スタッフID
		fieldWeekTime[4].staff_id = data.staff_id;
		//-- 曜日区分
		fieldWeekTime[4].week_kbn = '4';
		//-- 出勤開始時間1
		if(frStartTime1 != '' && frStartTime1 != null){
			fieldWeekTime[4].work_start_time1 = ('0' + frStartTime1.getHours()).slice(-2) + ':' + ('0' + frStartTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[4].work_start_time1 = '';
		}
		//-- 出勤終了時間1
		if(frEndTime1 != '' && frEndTime1 != null){
			fieldWeekTime[4].work_end_time1 = ('0' + frEndTime1.getHours()).slice(-2) + ':' + ('0' + frEndTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[4].work_end_time1 = '';
		}
		//-- 出勤開始時間2
		if(frStartTime2 != '' && frStartTime2 != null){
			fieldWeekTime[4].work_start_time2 = ('0' + frStartTime2.getHours()).slice(-2) + ':' + ('0' + frStartTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[4].work_start_time2 = '';
		}
		//-- 出勤終了時間2
		if(frEndTime2 != '' && frEndTime2 != null){
			fieldWeekTime[4].work_end_time2 = ('0' + frEndTime2.getHours()).slice(-2) + ':' + ('0' + frEndTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[4].work_end_time2 = '';
		}
		//-- 出勤開始時間3
		if(frStartTime3 != '' && frStartTime3 != null){
			fieldWeekTime[4].work_start_time3 = ('0' + frStartTime3.getHours()).slice(-2) + ':' + ('0' + frStartTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[4].work_start_time3 = '';
		}
		//-- 出勤終了時間3
		if(frEndTime3 != '' && frEndTime3 != null){
			fieldWeekTime[4].work_end_time3 = ('0' + frEndTime3.getHours()).slice(-2) + ':' + ('0' + frEndTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[4].work_end_time3 = '';
		}
		//-- 出勤開始時間4
		if(frStartTime4 != '' && frStartTime4 != null){
			fieldWeekTime[4].work_start_time4 = ('0' + frStartTime4.getHours()).slice(-2) + ':' + ('0' + frStartTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[4].work_start_time4 = '';
		}
		//-- 出勤終了時間4
		if(frEndTime4 != '' && frEndTime4 != null){
			fieldWeekTime[4].work_end_time4 = ('0' + frEndTime4.getHours()).slice(-2) + ':' + ('0' + frEndTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[4].work_end_time4 = '';
		}
		//-- 更新者ID
		fieldWeekTime[4].update_id = '';

		//--- 土
		//-- スタッフID
		fieldWeekTime[5].staff_id = data.staff_id;
		//-- 曜日区分
		fieldWeekTime[5].week_kbn = '5';
		//-- 出勤開始時間1
		if(saStartTime1 != '' && saStartTime1 != null){
			fieldWeekTime[5].work_start_time1 = ('0' + saStartTime1.getHours()).slice(-2) + ':' + ('0' + saStartTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[5].work_start_time1 = '';
		}
		//-- 出勤終了時間1
		if(saEndTime1 != '' && saEndTime1 != null){
			fieldWeekTime[5].work_end_time1 = ('0' + saEndTime1.getHours()).slice(-2) + ':' + ('0' + saEndTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[5].work_end_time1 = '';
		}
		//-- 出勤開始時間2
		if(saStartTime2 != '' && saStartTime2 != null){
			fieldWeekTime[5].work_start_time2 = ('0' + saStartTime2.getHours()).slice(-2) + ':' + ('0' + saStartTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[5].work_start_time2 = '';
		}
		//-- 出勤終了時間2
		if(saEndTime2 != '' && saEndTime2 != null){
			fieldWeekTime[5].work_end_time2 = ('0' + saEndTime2.getHours()).slice(-2) + ':' + ('0' + saEndTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[5].work_end_time2 = '';
		}
		//-- 出勤開始時間3
		if(saStartTime3 != '' && saStartTime3 != null){
			fieldWeekTime[5].work_start_time3 = ('0' + saStartTime3.getHours()).slice(-2) + ':' + ('0' + saStartTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[5].work_start_time3 = '';
		}
		//-- 出勤終了時間3
		if(saEndTime3 != '' && saEndTime3 != null){
			fieldWeekTime[5].work_end_time3 = ('0' + saEndTime3.getHours()).slice(-2) + ':' + ('0' + saEndTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[5].work_end_time3 = '';
		}
		//-- 出勤開始時間4
		if(saStartTime4 != '' && saStartTime4 != null){
			fieldWeekTime[5].work_start_time4 = ('0' + saStartTime4.getHours()).slice(-2) + ':' + ('0' + saStartTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[5].work_start_time4 = '';
		}
		//-- 出勤終了時間4
		if(saEndTime4 != '' && saEndTime4 != null){
			fieldWeekTime[5].work_end_time4 = ('0' + saEndTime4.getHours()).slice(-2) + ':' + ('0' + saEndTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[5].work_end_time4 = '';
		}
		//-- 更新者ID
		fieldWeekTime[5].update_id = '';

		//--- 日
		//-- スタッフID
		fieldWeekTime[6].staff_id = data.staff_id;
		//-- 曜日区分
		fieldWeekTime[6].week_kbn = '6';
		//-- 出勤開始時間1
		if(suStartTime1 != '' && suStartTime1 != null){
			fieldWeekTime[6].work_start_time1 = ('0' + suStartTime1.getHours()).slice(-2) + ':' + ('0' + suStartTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[6].work_start_time1 = '';
		}
		//-- 出勤終了時間1
		if(suEndTime1 != '' && suEndTime1 != null){
			fieldWeekTime[6].work_end_time1 = ('0' + suEndTime1.getHours()).slice(-2) + ':' + ('0' + suEndTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[6].work_end_time1 = '';
		}
		//-- 出勤開始時間2
		if(suStartTime2 != '' && suStartTime2 != null){
			fieldWeekTime[6].work_start_time2 = ('0' + suStartTime2.getHours()).slice(-2) + ':' + ('0' + suStartTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[6].work_start_time2 = '';
		}
		//-- 出勤終了時間2
		if(suEndTime2 != '' && suEndTime2 != null){
			fieldWeekTime[6].work_end_time2 = ('0' + suEndTime2.getHours()).slice(-2) + ':' + ('0' + suEndTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[6].work_end_time2 = '';
		}
		//-- 出勤開始時間3
		if(suStartTime3 != '' && suStartTime3 != null){
			fieldWeekTime[6].work_start_time3 = ('0' + suStartTime3.getHours()).slice(-2) + ':' + ('0' + suStartTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[6].work_start_time3 = '';
		}
		//-- 出勤終了時間3
		if(suEndTime3 != '' && suEndTime3 != null){
			fieldWeekTime[6].work_end_time3 = ('0' + suEndTime3.getHours()).slice(-2) + ':' + ('0' + suEndTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[6].work_end_time3 = '';
		}
		//-- 出勤開始時間4
		if(suStartTime4 != '' && suStartTime4 != null){
			fieldWeekTime[6].work_start_time4 = ('0' + suStartTime4.getHours()).slice(-2) + ':' + ('0' + suStartTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[6].work_start_time4 = '';
		}
		//-- 出勤終了時間4
		if(suEndTime4 != '' && suEndTime4 != null){
			fieldWeekTime[6].work_end_time4 = ('0' + suEndTime4.getHours()).slice(-2) + ':' + ('0' + suEndTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[6].work_end_time4 = '';
		}
		//-- 更新者ID
		fieldWeekTime[6].update_id = '';

		//--- 祝
		//-- スタッフID
		fieldWeekTime[7].staff_id = data.staff_id;
		//-- 曜日区分
		fieldWeekTime[7].week_kbn = '7';
		//-- 出勤開始時間1
		if(hoStartTime1 != '' && hoStartTime1 != null){
			fieldWeekTime[7].work_start_time1 = ('0' + hoStartTime1.getHours()).slice(-2) + ':' + ('0' + hoStartTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[7].work_start_time1 = '';
		}
		//-- 出勤終了時間1
		if(hoEndTime1 != '' && hoEndTime1 != null){
			fieldWeekTime[7].work_end_time1 = ('0' + hoEndTime1.getHours()).slice(-2) + ':' + ('0' + hoEndTime1.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[7].work_end_time1 = '';
		}
		//-- 出勤開始時間2
		if(hoStartTime2 != '' && hoStartTime2 != null){
			fieldWeekTime[7].work_start_time2 = ('0' + hoStartTime2.getHours()).slice(-2) + ':' + ('0' + hoStartTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[7].work_start_time2 = '';
		}
		//-- 出勤終了時間2
		if(hoEndTime2 != '' && hoEndTime2 != null){
			fieldWeekTime[7].work_end_time2 = ('0' + hoEndTime2.getHours()).slice(-2) + ':' + ('0' + hoEndTime2.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[7].work_end_time2 = '';
		}
		//-- 出勤開始時間3
		if(hoStartTime3 != '' && hoStartTime3 != null){
			fieldWeekTime[7].work_start_time3 = ('0' + hoStartTime3.getHours()).slice(-2) + ':' + ('0' + hoStartTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[7].work_start_time3 = '';
		}
		//-- 出勤終了時間3
		if(hoEndTime3 != '' && hoEndTime3 != null){
			fieldWeekTime[7].work_end_time3 = ('0' + hoEndTime3.getHours()).slice(-2) + ':' + ('0' + hoEndTime3.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[7].work_end_time3 = '';
		}
		//-- 出勤開始時間4
		if(hoStartTime4 != '' && hoStartTime4 != null){
			fieldWeekTime[7].work_start_time4 = ('0' + hoStartTime4.getHours()).slice(-2) + ':' + ('0' + hoStartTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[7].work_start_time4 = '';
		}
		//-- 出勤終了時間4
		if(hoEndTime4 != '' && hoEndTime4 != null){
			fieldWeekTime[7].work_end_time4 = ('0' + hoEndTime4.getHours()).slice(-2) + ':' + ('0' + hoEndTime4.getMinutes()).slice(-2);
		}else{
			fieldWeekTime[7].work_end_time4 = '';
		}
		//-- 更新者ID
		fieldWeekTime[7].update_id = '';
	}

	/**
	 * 生年月日カーソル移動
	 */
	function fncSetBirthDateFocus(){
		document.getElementById('dtBirthDate').focus();
	}

	/**
	 * 在籍開始日カーソル移動
	 */
	function fncSetStartDateFocus(){
		document.getElementById('dtStartDate').focus();
	}

	/**
	 * 在籍終了日カーソル移動
	 */
	function fncSetEndDateFocus(){
		document.getElementById('dtEndDate').focus();
	}

	/**
	 * パスワード有効期限カーソル移動
	 */
	function fncSetLimitDateFocus(){
		document.getElementById('dtLimitDate').focus();
	}

	return (
		<>
			<Form className="staff-mng-form" >
				<Row className="staff-mng-form-row">
					<Col md={{ span: 11}}>
					</Col>
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
				</Row>
				<Row className="staff-mng-form-row">
					<div className={"div-staff-mng-title"}>
						<label className={"label-staff-mng-padding"}>名前<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<div className={"div-staff-mng-save-nm"}>
						<FormControl
							className="input-staff-mng-save-nm"
							type="text"
							value={fieldData.staff_nm}
							onChange={handleDataFormat}
							onInput={handleNameInput}
							name="staff_nm"
							id="staff_nm"
							maxLength="50"
							readOnly={data.mode === '3'? 'readOnly' : ''}
							{...FormInput}
						/>
					</div>
					<div className={"div-staff-mng-title"}>
						<label className={"label-staff-mng-padding"}>フリガナ<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<div className={"div-staff-mng-save-kn"}>
						<FormControl
							className="input-staff-mng-save-kn"
							type="text"
							value={fieldData.staff_kn}
							onChange={handleDataFormat}
							name="staff_kn"
							id="staff_kn"
							maxLength="50"
							readOnly={data.mode === '3'? 'readOnly' : ''}
							{...FormInput}
						/>
					</div>
				</Row>
				{(errors.staff_nm || errors.staff_kn ) && (
					<Row className="staff-mng-error-row">
						<div className={"div-staff-mng-title"}></div>
						<div className={"div-staff-mng-save-nm"}>
							<span className="error-span">{errors.staff_nm? errors.staff_nm[0] : ''}</span>
						</div>
						<div className={"div-staff-mng-title"}></div>
						<div className={"div-staff-mng-save-kn"}>
							<span className="error-span">{errors.staff_kn? errors.staff_kn[0] : ''}</span>
						</div>
					</Row>
				) }
				<Row className="staff-mng-form-row">
					<div className={"div-staff-mng-title"}>
						<label className={"label-staff-mng-padding"}>表示名称</label>
					</div>
					<div className={"div-staff-mng-save-dispnm"}>
						<FormControl
							className="input-staff-mng-save-dispnm"
							type="text"
							value={fieldData.staff_disp_nm}
							onChange={handleDataFormat}
							name="staff_disp_nm"
							maxLength="50"
							readOnly={data.mode === '3'? 'readOnly' : ''}
							{...FormInput}
						/>
					</div>
				</Row>
				<Row className="staff-mng-form-row">
					<div className={"div-staff-mng-title"}>
						<label className={"label-staff-mng-padding"}>性別</label>
					</div>
					<div className={"div-staff-mng-save-man"}>
						<label className={"label-staff-mng-padding"}>
							<input
								type="radio"
								className=""
								name="staff_sex"
								value="0"
								onChange={handleDataFormat}
								checked={fieldData.staff_sex === "0"}
								disabled={data.mode === '3'? 'disabled' : ''}
							/> 男 性
						</label>
					</div>
					<div className={"div-staff-mng-save-woman"}>
						<label className={"label-staff-mng-padding"}>
							<input
								type="radio"
								className=""
								name="staff_sex"
								value="1"
								onChange={handleDataFormat}
								checked={fieldData.staff_sex === "1"}
								disabled={data.mode === '3'? 'disabled' : ''}
							/> 女 性
						</label>
					</div>
					<div className={"div-staff-mng-title"}>
						<label className={"label-staff-mng-padding"}>生年月日</label>
					</div>
					<div className={"div-date-picker-width"}>
						<DatePicker
							className="date-picker"
							showPopperArrow={false}
							selected={birthDate}
							onChange={date => setBirthDate(date)}
							dateFormat="yyyy/MM/dd"
							customInput={<InputMask mask="9999/99/99" maskChar={null} />}
							disabled={data.mode === '3'? 'disabled' : ''}
							id={"dtBirthDate"}
							renderCustomHeader={({
													 date,
													 changeYear,
													 changeMonth,
												 }) =>(
								<div
									className={"div-date-picker"}
								>
									<select
										className={"select-date-picker"}
										value={date.getFullYear()}
										onChange={({target:{value}}) => changeYear(value)}
									>
										{years.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
									<select
										className={"select-date-picker"}
										value={months[date.getMonth()]}
										onChange={({ target: { value } }) =>
											changeMonth(months.indexOf(value))
										}
									>
										{months.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
								</div>
							)}
						/>
					</div>
					<div className={"div-calendar-icon"}>
						<a onClick={fncSetBirthDateFocus}>
							<IoIosCalendar/>
						</a>
					</div>
				</Row>
				<Row className="staff-mng-form-row">
					<div className={"div-staff-mng-title"}>
						<label className="label-staff-mng-padding">電話番号</label>
					</div>
					<div className={"div-staff-mng-save-tel"}>
						<FormControl
							className="input-staff-mng-tel"
							type="text"
							value={fieldData.staff_tel != null ? fieldData.staff_tel : ''}
							onChange={handleDataFormat}
							name="staff_tel"
							maxLength="15"
							readOnly={data.mode === '3'? 'readOnly' : ''}
							{...FormInput}
						/>
					</div>
					<div className={"div-staff-mng-title"}>
						<label className={"label-staff-mng-padding"}>メールアドレス</label>
					</div>
					<div className={"div-staff-mng-save-mail"}>
						<FormControl
							className="input-staff-mng-save-mail"
							type="text"
							value={fieldData.staff_mail}
							onChange={handleDataFormat}
							name="staff_mail"
							maxLength="255"
							readOnly={data.mode === '3'? 'readOnly' : ''}
							{...FormInput}
						/>
					</div>
				</Row>
				<Row className="staff-mng-form-row">
					<div className={"div-staff-mng-title"}>
						<label className={"label-staff-mng-padding"}>在籍開始日</label>
					</div>
					<div className={"div-staff-mng-save-saiseki"}>
						<div className={"div-date-picker-width"}>
							<DatePicker
								className="date-picker"
								showPopperArrow={false}
								selected={startDate}
								onChange={date => setStartDate(date)}
								dateFormat="yyyy/MM/dd"
								customInput={<InputMask mask="9999/99/99" maskChar={null} />}
								disabled={data.mode === '3'? 'disabled' : ''}
								id={"dtStartDate"}
								renderCustomHeader={({
														 date,
														 changeYear,
														 changeMonth,
													 }) =>(
									<div
										className={"div-date-picker"}
									>
										<select
											className={"select-date-picker"}
											value={date.getFullYear()}
											onChange={({target:{value}}) => changeYear(value)}
										>
											{years.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
										<select
											className={"select-date-picker"}
											value={months[date.getMonth()]}
											onChange={({ target: { value } }) =>
												changeMonth(months.indexOf(value))
											}
										>
											{months.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
									</div>
								)}
							/>
						</div>
						<div className={"div-calendar-icon"}>
							<a onClick={fncSetStartDateFocus}>
								<IoIosCalendar/>
							</a>
						</div>
					</div>
					<div className={"div-staff-mng-title"}>
						<label className={"label-staff-mng-padding"}>在籍終了日</label>
					</div>
					<div className={"div-date-picker-width"}>
						<DatePicker
							className="date-picker"
							showPopperArrow={false}
							selected={endDate}
							onChange={date => setEndDate(date)}
							dateFormat="yyyy/MM/dd"
							customInput={<InputMask mask="9999/99/99" maskChar={null} />}
							disabled={data.mode === '3'? 'disabled' : ''}
							id={"dtEndDate"}
							renderCustomHeader={({
													 date,
													 changeYear,
													 changeMonth,
												 }) =>(
								<div
									className={"div-date-picker"}
								>
									<select
										className={"select-date-picker"}
										value={date.getFullYear()}
										onChange={({target:{value}}) => changeYear(value)}
									>
										{years.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
									<select
										className={"select-date-picker"}
										value={months[date.getMonth()]}
										onChange={({ target: { value } }) =>
											changeMonth(months.indexOf(value))
										}
									>
										{months.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
								</div>
							)}
						/>
					</div>
					<div className={"div-calendar-icon"}>
						<a onClick={fncSetEndDateFocus}>
							<IoIosCalendar/>
						</a>
					</div>
				</Row>
				{errors.zaiseki_start_date && (
					<Row className="staff-mng-error-row">
						<div className={"div-staff-mng-title"}></div>
						<div className={"div-staff-mng-save-saiseki"}>
							<span className="error-span">{errors.zaiseki_start_date[0]}</span>
						</div>
					</Row>
				) }

				<Row className="staff-mng-form-row">
					<div className={"div-staff-mng-title"}>
						<label className={"label-staff-mng-padding"}>表示順</label>
					</div>
					<div className={"div-staff-mng-save-order"}>
						<FormControl
							className="input-staff-mng-save-order"
							type="text"
							value={fieldData.disp_no}
							onChange={handleDataFormat}
							name="disp_no"
							maxLength="4"
							readOnly={data.mode === '3'? 'readOnly' : ''}
							{...FormInput}
						/>
					</div>
					<div className={"div-staff-mng-title"}>
						<label className={"label-staff-mng-padding"}>表示有無</label>
					</div>
					<div className={"div-staff-mng-save-disp"}>
						<label className={"label-staff-mng-padding"}>
							<input
								type="radio"
								className=""
								name="disp_flg"
								value="1"
								onChange={handleDataFormat}
								checked={fieldData.disp_flg === "1"}
								disabled={data.mode === '3'? 'disabled' : ''}
							/> 表示する
						</label>
					</div>
					<div className={"div-staff-mng-save-nodisp"}>
						<label className={"label-staff-mng-padding"}>
							<input
								type="radio"
								className=""
								name="disp_flg"
								value="0"
								onChange={handleDataFormat}
								checked={fieldData.disp_flg === "0"}
								disabled={data.mode === '3'? 'disabled' : ''}
							/> 表示しない
						</label>
					</div>
				</Row>

				{errors.disp_no && (
					<Row className="staff-mng-error-row">
						<div className={"div-staff-mng-title"}></div>
						<div className={"div-staff-mng-save-nm"}>
							<span className="error-span">{errors.disp_no[0]}</span>
						</div>
					</Row>
				) }

				<Row className="staff-mng-form-row">
					<div className={"div-staff-mng-message"}>
						<label>{MSG_INFO_DATE_INPUT}</label>
					</div>
				</Row>

				<div className="text-header first-header">▼基本出勤時間</div>
				<Row className="staff-mng-value">
					<Col md={{ span: 12}}>
						<label className="staff-mng-message">※スタッフの出勤時間を入力し曜日別出勤時間に反映してください。</label>
					</Col>
				</Row>
				<Row className="staff-mng-value">
					<Col md={{ span: 12}}>
						<table className={"staff-mng-base"}>
							<thead>
								<tr>
									<th className={"th-base-time-0"}></th>
									<th className={"th-base-time-1"}>出勤時間1</th>
									<th className={"th-base-time-2"}>出勤時間2</th>
									<th className={"th-base-time-3"}>出勤時間3</th>
									<th className={"th-base-time-4"}>出勤時間4</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className={"td-base-time-0"}>
										<Button
											className={"btn-return"}
											onClick={fncOnClickBaseTime}
											disabled={loading == '1'? 'disabled' : data.mode === '3'? 'disabled' : ''}
										>
											時間<br/>反映
										</Button>
									</td>
									<WeekSet
										errors={errors}
										disabled={data.mode == '3'? 'disabled' : ''}
										strIDBaseS="dtBaseStartTime"
										strIDBaseE="dtBaseEndTime"
										valS1={baseStartTime1} fncSetS1={date => setBaseStartTime1(date)}
										valE1={baseEndTime1}   fncSetE1={date => setBaseEndTime1(date)}
										valS2={baseStartTime2} fncSetS2={date => setBaseStartTime2(date)}
										valE2={baseEndTime2}   fncSetE2={date => setBaseEndTime2(date)}
										valS3={baseStartTime3} fncSetS3={date => setBaseStartTime3(date)}
										valE3={baseEndTime3}   fncSetE3={date => setBaseEndTime3(date)}
										valS4={baseStartTime4} fncSetS4={date => setBaseStartTime4(date)}
										valE4={baseEndTime4}   fncSetE4={date => setBaseEndTime4(date)}
									/>
								</tr>
							</tbody>
						</table>
					</Col>
				</Row>

				<div className="text-header first-header">▼曜日別出勤時間</div>
				<Row className="staff-mng-value">
					<Col md={{ span: 12}}>
						<table className={"staff-mng-base"}>
							<thead>
								<tr>
									<th className={"th-base-yobi-staff-mng"}></th>
									<th className={"th-base-time-1"}>出勤時間1</th>
									<th className={"th-base-time-2"}>出勤時間2</th>
									<th className={"th-base-time-3"}>出勤時間3</th>
									<th className={"th-base-time-4"}>出勤時間4</th>
								</tr>
							</thead>
							<tbody>
								<WeekSet
									strYobi="月"
									disabled={data.mode == '3'? 'disabled' : ''}
									strIDBaseS="dtMoStartTime"
									strIDBaseE="dtMoEndTime"
									valS1={moStartTime1} fncSetS1={date => setMoStartTime1(date)}
									valE1={moEndTime1}   fncSetE1={date => setMoEndTime1(date)}
									valS2={moStartTime2} fncSetS2={date => setMoStartTime2(date)}
									valE2={moEndTime2}   fncSetE2={date => setMoEndTime2(date)}
									valS3={moStartTime3} fncSetS3={date => setMoStartTime3(date)}
									valE3={moEndTime3}   fncSetE3={date => setMoEndTime3(date)}
									valS4={moStartTime4} fncSetS4={date => setMoStartTime4(date)}
									valE4={moEndTime4}   fncSetE4={date => setMoEndTime4(date)}
								/>
								<WeekSet
									strYobi="火"
									disabled={data.mode == '3'? 'disabled' : ''}
									strIDBaseS="dtTuStartTime"
									strIDBaseE="dtTuEndTime"
									valS1={tuStartTime1} fncSetS1={date => setTuStartTime1(date)}
									valE1={tuEndTime1}   fncSetE1={date => setTuEndTime1(date)}
									valS2={tuStartTime2} fncSetS2={date => setTuStartTime2(date)}
									valE2={tuEndTime2}   fncSetE2={date => setTuEndTime2(date)}
									valS3={tuStartTime3} fncSetS3={date => setTuStartTime3(date)}
									valE3={tuEndTime3}   fncSetE3={date => setTuEndTime3(date)}
									valS4={tuStartTime4} fncSetS4={date => setTuStartTime4(date)}
									valE4={tuEndTime4}   fncSetE4={date => setTuEndTime4(date)}
								/>
								<WeekSet
									strYobi="水"
									disabled={data.mode == '3'? 'disabled' : ''}
									strIDBaseS="dtWeStartTime"
									strIDBaseE="dtWeEndTime"
									valS1={weStartTime1} fncSetS1={date => setWeStartTime1(date)}
									valE1={weEndTime1}   fncSetE1={date => setWeEndTime1(date)}
									valS2={weStartTime2} fncSetS2={date => setWeStartTime2(date)}
									valE2={weEndTime2}   fncSetE2={date => setWeEndTime2(date)}
									valS3={weStartTime3} fncSetS3={date => setWeStartTime3(date)}
									valE3={weEndTime3}   fncSetE3={date => setWeEndTime3(date)}
									valS4={weStartTime4} fncSetS4={date => setWeStartTime4(date)}
									valE4={weEndTime4}   fncSetE4={date => setWeEndTime4(date)}
								/>
								<WeekSet
									strYobi="木"
									disabled={data.mode == '3'? 'disabled' : ''}
									strIDBaseS="dtThStartTime"
									strIDBaseE="dtThEndTime"
									valS1={thStartTime1} fncSetS1={date => setThStartTime1(date)}
									valE1={thEndTime1}   fncSetE1={date => setThEndTime1(date)}
									valS2={thStartTime2} fncSetS2={date => setThStartTime2(date)}
									valE2={thEndTime2}   fncSetE2={date => setThEndTime2(date)}
									valS3={thStartTime3} fncSetS3={date => setThStartTime3(date)}
									valE3={thEndTime3}   fncSetE3={date => setThEndTime3(date)}
									valS4={thStartTime4} fncSetS4={date => setThStartTime4(date)}
									valE4={thEndTime4}   fncSetE4={date => setThEndTime4(date)}
								/>
								<WeekSet
									strYobi="金"
									disabled={data.mode == '3'? 'disabled' : ''}
									strIDBaseS="dtFrStartTime"
									strIDBaseE="dtFrEndTime"
									valS1={frStartTime1} fncSetS1={date => setFrStartTime1(date)}
									valE1={frEndTime1}   fncSetE1={date => setFrEndTime1(date)}
									valS2={frStartTime2} fncSetS2={date => setFrStartTime2(date)}
									valE2={frEndTime2}   fncSetE2={date => setFrEndTime2(date)}
									valS3={frStartTime3} fncSetS3={date => setFrStartTime3(date)}
									valE3={frEndTime3}   fncSetE3={date => setFrEndTime3(date)}
									valS4={frStartTime4} fncSetS4={date => setFrStartTime4(date)}
									valE4={frEndTime4}   fncSetE4={date => setFrEndTime4(date)}
								/>
								<WeekSet
									strYobi="土"
									disabled={data.mode == '3'? 'disabled' : ''}
									strIDBaseS="dtSaStartTime"
									strIDBaseE="dtSaEndTime"
									valS1={saStartTime1} fncSetS1={date => setSaStartTime1(date)}
									valE1={saEndTime1}   fncSetE1={date => setSaEndTime1(date)}
									valS2={saStartTime2} fncSetS2={date => setSaStartTime2(date)}
									valE2={saEndTime2}   fncSetE2={date => setSaEndTime2(date)}
									valS3={saStartTime3} fncSetS3={date => setSaStartTime3(date)}
									valE3={saEndTime3}   fncSetE3={date => setSaEndTime3(date)}
									valS4={saStartTime4} fncSetS4={date => setSaStartTime4(date)}
									valE4={saEndTime4}   fncSetE4={date => setSaEndTime4(date)}
								/>
								<WeekSet
									strYobi="日"
									disabled={data.mode == '3'? 'disabled' : ''}
									strIDBaseS="dtSuStartTime"
									strIDBaseE="dtSuEndTime"
									valS1={suStartTime1} fncSetS1={date => setSuStartTime1(date)}
									valE1={suEndTime1}   fncSetE1={date => setSuEndTime1(date)}
									valS2={suStartTime2} fncSetS2={date => setSuStartTime2(date)}
									valE2={suEndTime2}   fncSetE2={date => setSuEndTime2(date)}
									valS3={suStartTime3} fncSetS3={date => setSuStartTime3(date)}
									valE3={suEndTime3}   fncSetE3={date => setSuEndTime3(date)}
									valS4={suStartTime4} fncSetS4={date => setSuStartTime4(date)}
									valE4={suEndTime4}   fncSetE4={date => setSuEndTime4(date)}
								/>
								<WeekSet
									strYobi="祝"
									disabled={data.mode == '3'? 'disabled' : ''}
									strIDBaseS="dtHoStartTime"
									strIDBaseE="dtHoEndTime"
									valS1={hoStartTime1} fncSetS1={date => setHoStartTime1(date)}
									valE1={hoEndTime1}   fncSetE1={date => setHoEndTime1(date)}
									valS2={hoStartTime2} fncSetS2={date => setHoStartTime2(date)}
									valE2={hoEndTime2}   fncSetE2={date => setHoEndTime2(date)}
									valS3={hoStartTime3} fncSetS3={date => setHoStartTime3(date)}
									valE3={hoEndTime3}   fncSetE3={date => setHoEndTime3(date)}
									valS4={hoStartTime4} fncSetS4={date => setHoStartTime4(date)}
									valE4={hoEndTime4}   fncSetE4={date => setHoEndTime4(date)}
								/>
							</tbody>
						</table>
					</Col>
				</Row>
				<Row>
					<Col md={{ span: 6}}>
						<Row className="staff-mng-form-row">
							<Col md={{ span: 4}}>
								<label className={"label-staff-mng-padding"}>ユーザーID</label>
							</Col>
							<Col md={{ span: 8}}>
								<FormControl
									className="shop-mng-form-input-field"
									type="text"
									value={fieldData.email}
									onChange={handleDataFormat}
									name="email"
									maxLength="190"
									autocomplete ="new-password"
									readOnly={data.mode === '3'? 'readOnly' : ''}
									{...FormInput}
								/>
							</Col>
						</Row>
						{errors.user_id && (
							<Row className="staff-mng-error-row">
								<Col md={{ span: 4}}></Col>
								<Col md={{ span: 8}}>
									<span className="error-span">{errors.user_id[0]}</span>
								</Col>
							</Row>
						) }
						<Row className="staff-mng-form-row">
							<Col md={{ span: 4}}>
								<label className={"label-staff-mng-padding"}>パスワード</label>
							</Col>
							<Col md={{ span: 8}}>
								<FormControl
									className="shop-mng-form-input-field"
									type="password"
									value={fieldData.login_pass}
									onChange={handleDataFormat}
									name="login_pass"
									maxLength="20"
									autocomplete ="new-password"
									readOnly={data.mode === '3'? 'readOnly' : ''}
									{...FormInput}
								/>
							</Col>
						</Row>
						<Row className="staff-mng-form-row">
							<Col md={{ span: 4}}>
								<label className={"label-staff-mng-padding"}>権限</label>
							</Col>
							<Col md={{ span: 4}}>
								<Form.Control
									as="select"
									className="sel-kengen"
									name='kengen_kbn'
									value={fieldData.kengen_kbn}
									onChange={handleDataFormat}
									disabled={data.mode === '3'? 'disabled' : ''}
								>
									{fncSetKengen()}
								</Form.Control>
							</Col>
						</Row>
						<Row className="staff-mng-form-row">
							<Col md={{ span: 4}}>
								<label className={"label-staff-mng-padding"}>パスワードの有効期限</label>
							</Col>
							<Col md={{ span: 4}}>
								<DatePicker
									className="date-picker"
									showPopperArrow={false}
									selected={limitDate}
									onChange={date => setLimitDate(date)}
									dateFormat="yyyy/MM/dd"
									customInput={<InputMask mask="9999/99/99" maskChar={null} />}
									disabled={data.mode === '3'? 'disabled' : ''}
									id={"dtLimitDate"}
									renderCustomHeader={({
															 date,
															 changeYear,
															 changeMonth,
														 }) =>(
										<div
											className={"div-date-picker"}
										>
											<select
												className={"select-date-picker"}
												value={date.getFullYear()}
												onChange={({target:{value}}) => changeYear(value)}
											>
												{pwyears.map(option => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>
											<select
												className={"select-date-picker"}
												value={months[date.getMonth()]}
												onChange={({ target: { value } }) =>
													changeMonth(months.indexOf(value))
												}
											>
												{months.map(option => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>
										</div>
									)}
								/>
							</Col>
							<div className={"div-calendar-icon"}>
								<a onClick={fncSetLimitDateFocus}>
									<IoIosCalendar/>
								</a>
							</div>
						</Row>
						{errors.limit_date && (
							<Row className="staff-mng-error-row">
								<Col md={{ span: 4}}></Col>
								<Col md={{ span: 8}}>
									<span className="error-span">{errors.limit_date[0]}</span>
								</Col>
							</Row>
						) }
					</Col>
					<Col md={{ span: 6}}>
						<Row className="staff-mng-form-row">
							<Col md={{ span: 4}}>
								<label className={"text-header"}>▼担当コース</label>
							</Col>
							<Col md={{ span: 2}}></Col>
							<Col md={{ span: 3}}>
								<Button
									block
									className="btn-all-sel"
									onClick={fncOnClickAllSel}
									disabled={loading == '1'? 'disabled' : data.mode === '3'? 'disabled' : ''}
								>
									全選択
								</Button>
							</Col>
							<Col md={{ span: 3}}>
								<Button
									block
									className="btn-all-rel"
									onClick={fncOnClickAllKaijo}
									disabled={loading == '1'? 'disabled' : data.mode === '3'? 'disabled' : ''}
								>
									全解除
								</Button>
							</Col>
						</Row>
						<Row className="staff-mng-value">
							<Col md={{ span: 12}}>
								<table className={"staff-mng-course"}>
									<thead>
										<tr>
											<th className={"th-staffmng-course-0"}>No</th>
											<th className={"th-staffmng-course-1"}>選択</th>
											<th className={"th-staffmng-course-2"}>コース名</th>
										</tr>
									</thead>
									{fncSetStaffCourse()}
								</table>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row>
					<Col md={{ span: 6}}>
						<Row className="staff-mng-form-row">
							<Col md={{ span: 2}}>
								<label className={"label-staff-mng-padding"}>受信種別</label>
							</Col>
							<Col md={{ span: 3}}>
								<label className={"label-staff-mng-padding"}>
									<input
										type="radio"
										className="user-update-detail-radio"
										name="rec_mail_kbn"
										value="0"
										onChange={handleDataFormat}
										checked={fieldData.rec_mail_kbn === "0"}
										disabled={data.mode === '3'? 'disabled' : ''}
									/> 受信しない
								</label>
							</Col>
							<Col md={{ span: 3}}>
								<label className={"label-staff-mng-padding"}>
									<input
										type="radio"
										className="user-update-detail-radio"
										name="rec_mail_kbn"
										value="1"
										onChange={handleDataFormat}
										checked={fieldData.rec_mail_kbn === "1"}
										disabled={data.mode === '3'? 'disabled' : ''}
									/> 担当のみ受信
								</label>
							</Col>
							<Col md={{ span: 3}}>
								<label className={"label-staff-mng-padding"}>
									<input
										type="radio"
										className="user-update-detail-radio"
										name="rec_mail_kbn"
										value="2"
										onChange={handleDataFormat}
										checked={fieldData.rec_mail_kbn === "2"}
										disabled={data.mode === '3'? 'disabled' : ''}
									/> 全て受信
								</label>
							</Col>
						</Row>
					</Col>
					<Col md={{ span: 6}}>
						<Row className="staff-mng-form-row">
							<Col md={{ span: 4}}>
								<label className={"text-header"}>▼受信メール設定</label>
							</Col>
						</Row>
						<Row className="staff-mng-value">
							<Col md={{ span: 12}}>
								<table className={"staff-mng-receive"}>
									<thead>
									<tr>
										<th className={"th-staffmng-receive-0"}>No</th>
										<th className={"th-staffmng-receive-1"}>選択</th>
										<th className={"th-staffmng-receive-2"}>受信メール</th>
									</tr>
									</thead>
									{fncSetStaffReceive()}
								</table>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row className="staff-mng-button-container">
					<Col md={{ span: 2, offset: 9 }}>
						<Button
							block
							className="btn-save"
							onClick={fncOnClickUpdate}
							disabled={loading == '1'? 'disabled' : ''}
						>
							{data.mode == '1'? '登 録' : data.mode == '2'? '更 新' : '削 除'}
						</Button>
					</Col>
					<Col md={{ span: 1 }}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
				</Row>
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>

		</>
	);

}

export default StaffMngAddForm;