import React, {useEffect, useState} from 'react';

import './ReserveSts.css';
import Http from 'Utils/Axios';
import Auth, { GetOlderSettingID, GetOlderSettingPW, IsOlderSettings } from 'Utils/Auth';
import {fncgGetUrlPramInfo, fncgSetUrlPramInfo, fncgGetDbconn} from "../../../Utils/Common/ComCom";
import {APP_TOP, DISP_FG, PRM_ID, PRM_USER_ID, SEARCH_ID} from "../../../Utils/Common/ComConst";

// らっく院起動のログイン処理
function ReserveLack({ match, history, location }) {
	const [urlpram, setUrlPram] = useState('');
	const [urluserid, setUrlUserId] = useState('');
	const [dbconn, setDbConn] = useState('');
	const [start, setStart] = useState('0');
	const handleSetUrlPram = dat => setUrlPram(dat);
	const handleSetDbConn = dat => setDbConn(dat);
	const handleSetStart = dat => setStart(dat);

	/**
	 * 初期処理
	 */
	useEffect(() => {
		//--- URLパラメータ取得
		//-- 会員ID(らっく院連携)
		let prmUserID = fncgGetUrlPramInfo(document.location.href, PRM_USER_ID);
		let urlUserID = fncgSetUrlPramInfo(PRM_USER_ID, prmUserID);
		setUrlUserId(urlUserID);

		fncgGetDbconn({history, location, handleSetUrlPram, handleSetDbConn, handleSetStart}, true);
	}, [])

	/**
	 * 予約状況(スタッフ状況)遷移
	 */
	useEffect(() => {
		if(start == '0') return;
		// トークンが存在する場合、ユーザ情報を取得する。
		// 存在しない場合、ログイン画面にリダイレクトする。
		let goLogin = () => {
			Auth.SetAccessToken();
			localStorage.setItem(DISP_FG, '0');
			localStorage.setItem(SEARCH_ID, '');
			history.replace(APP_TOP + urlpram, { from: location });
		};
		
		if(Auth.HasToken()){
			const aryData ={
				id : dbconn.id,
			}
			Http.post(
				'api/login-user',
				{...aryData}
			).then(response => {
				if (response.data) {
					localStorage.setItem(DISP_FG, '0');
					localStorage.setItem(SEARCH_ID, '');
					history.push('/reservests' + urlpram + urluserid);
				} else {
					goLogin();
				}
			}).catch(error => {
				goLogin();
			})
		} else if (IsOlderSettings()) {
			// 古い設定が残っている場合は認証を差し替え
			let data  = {
				password : GetOlderSettingPW(),
				username : GetOlderSettingID(),
				id : dbconn.id
			};
			Http.post('/api/login', {
				...data
			}).then(response => {
				if(response.status == '200'){
					Auth.SetAccessToken(response.data.token);
					localStorage.setItem(DISP_FG, '0');
					localStorage.setItem(SEARCH_ID, '');
					history.push('/reservests' + urlpram + urluserid);
				} else {
					goLogin();
				}
			}).catch(error => {
				goLogin();
			}).finally(() => {
			})
		} else {
			goLogin();
		}
	}, [start])
	
	return (
		<p style={{padding: '15px'}}>お待ちください。。。</p>
	);
}

export default ReserveLack;