import React, {useEffect, useState} from 'react';

import '../../../Utils/Common/com-style.css';
import '../DashBoard.css';
import Http from 'Utils/Axios';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData';
import {Button, Col, Row, Form, Modal, FormControl} from 'react-bootstrap';
import {Pie, Line, Bar} from 'react-chartjs-2';
import "react-datepicker/dist/react-datepicker.css";
import {fncgSetUrlPram} from "../../../Utils/Common/ComCom";

var dtNow = new Date();

const defaultState = {
	date_year : dtNow.getFullYear(),
	id : '',
}

const backcolor = {
	color:[
		'rgb(255,238,47)',
		'rgb(88,255,167)',
		'rgb(87,186,255)',
		'rgb(255,139,158)',
	],
}

const chartBackColor = {
	color:[
		'rgb(255,238,47)',
		'rgb(88,255,167)',
		'rgb(87,186,255)',
		'rgb(255,139,158)',
		'rgb(255,249,168)',
		'rgb(59,255,119)',
		'rgb(17,151,255)',
		'rgb(255,99,173)',
		'rgb(255,213,52)',
		'rgb(45,255,76)',
		'rgb(57,119,255)',
		'rgb(255,46,174)',
		'rgb(255,150,11)',
		'rgb(90,255,16)',
		'rgb(36,15,255)',
		'rgb(255,11,94)',
		'rgb(255,251,17)',
		'rgb(97,255,38)',
		'rgb(128,62,255)',
		'rgb(255,21,89)',
	],
}

const linedtl = {
	label: '',
	//fill: true,
	lineTension: 0.1,
	backgroundColor: 'rgba(255,255,255,0)',
	borderColor: 'rgb(176,110,192)',
	//borderCapStyle: 'round',
	//borderDash: [],
	//borderDashOffset: 0.0,
	//borderJoinStyle: 'square',
	//pointBorderColor: 'rgba(75,192,192,1)',
	//pointBackgroundColor: '#eee',
	pointBorderWidth: 5,
	//pointHoverRadius: 5,
	//pointHoverBackgroundColor: 'rgba(75,192,192,1)',
	//pointHoverBorderColor: 'rgba(220,220,220,1)',
	//pointHoverBorderWidth: 1,
	//pointRadius: 1,
	//pointHitRadius: 10,
	data:'',
}

const pieage ={
	labels: ['10代', '20代', '30代', '40代以上', '不明'],
	datasets: []
}

const piegender ={
	labels: ['男性', '女性'],
	datasets: []
}

const linechart = {
	labels: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
	datasets: []
};

const barchart = {
	labels: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
	datasets: []
};

const coursebarchart = {
	labels: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
	datasets: []
};

function DashBoardForm({ history, dbconn }) {
	const [fieldData, handleDataFormat] = useHandleFormData({...defaultState});
	const [todaycnt, setTodayCnt] = useState(0);
	const [tomorrowcnt, setTomorrowCnt] = useState(0);
	const [agedata, setAgeData] = useState('');
	const [genderdata, setGenderData] = useState('');
	const [listrepeat, setListRepeat] = useState('');
	const [barrepeat, setBarRepeat] = useState('');
	const [courserepeat, setCourseRepeat] = useState('');
	const strPram = fncgSetUrlPram(dbconn);
	const [loading, setLoading] = useState('0');

	let years = [];
	var intStartY = dtNow.getFullYear() - 2;
	for (var i = intStartY; i <= dtNow.getFullYear(); i++) {
		years.push(i);
	}

	/**
	 * 起動時処理
	 */
	useEffect(() => {
		fieldData.date_year = dtNow.getFullYear();
		fncOnClickSearch();
	}, [])

	/**
	 * 年代比率集計
	 */
	useEffect(() => {
		if(agedata == '') return;
		var idxColor = 0;

		//---
		pieage.datasets[0] = {
			backgroundColor:[
				'rgb(255,46,174)',
				'rgb(255,95,180)',
				'rgb(255,150,201)',
				'rgb(255,196,228)',
				'rgb(255,218,220)',
			],
			hoverBackgroundColor:[
				'rgb(255,46,174)',
				'rgb(255,95,180)',
				'rgb(255,150,201)',
				'rgb(255,196,228)',
				'rgb(255,218,220)',
			],
			data:[0,0,0,0,0],
		}
		for(var intDtl in agedata){
			var intTotalCnt = agedata[intDtl].TotalCnt;
			var intReservCnt = agedata[intDtl].DataCnt;
			var intRate = 0;
			if(Number(intTotalCnt) != 0 && Number(intReservCnt) != 0){
				intRate = (intReservCnt / intTotalCnt) * 100;
			}
			pieage.datasets[0].data[intDtl] = intRate;
		}

	}, [agedata])

	/**
	 * 男女比率集計
	 */
	useEffect(() => {
		if(genderdata == '') return;
		var idxColor = 0;

		//---
		piegender.datasets[0] = {
			backgroundColor:[
				'rgb(255,46,174)',
				'rgb(255,145,220)'
			],
			hoverBackgroundColor:[
				'rgb(255,46,174)',
				'rgb(255,145,220)'
			],
			data:[0,0],
		}
		for(var intDtl in genderdata){
			var intTotalCnt = genderdata[intDtl].TotalCnt;
			var intReservCnt = genderdata[intDtl].DataCnt;
			var intRate = 0;
			if(Number(intTotalCnt) != 0 && Number(intReservCnt) != 0){
				intRate = (intReservCnt / intTotalCnt) * 100;
			}
			piegender.datasets[0].data[intDtl] = intRate;
		}

	}, [genderdata])

	/**
	 * 再来店率集計
	 */
	useEffect(() => {
		//if(listrepeat == '') return;

		//--- 再来店率
		for(var intIdx in listrepeat){
			linechart.datasets[intIdx] = {
				label: listrepeat[intIdx][0].RepeatNm,
				lineTension: 0.1,
				backgroundColor: 'rgba(255,255,255,0)',
				borderColor: backcolor.color[intIdx],
				pointBorderWidth: 5,
				data:[0,0,0,0,0,0,0,0,0,0,0,0],
			}
			for(var intDtl in listrepeat[intIdx]){
				var intTotalCnt = listrepeat[intIdx][intDtl].TotalCnt;
				var intReservCnt = listrepeat[intIdx][intDtl].DataCnt;
				var intRate = 0;
				if(Number(intTotalCnt) != 0 && Number(intReservCnt) != 0){
					intRate = Math.round(((intReservCnt / intTotalCnt) * 10) * 100) / 10;
				}
				linechart.datasets[intIdx].data[intDtl] = intRate;
			}
		}

	}, [listrepeat])

	/**
	 *　再来店数集計
	 */
	useEffect(() => {
		//if(barrepeat == '') return;
		//--- 来店数
		for(var intIdx in barrepeat){
			barchart.datasets[intIdx] = {
				label: barrepeat[intIdx][0].RepeatNm,
				backgroundColor: backcolor.color[intIdx],
				borderColor: backcolor.color[intIdx],
				borderWidth: 1,
				hoverBackgroundColor: backcolor.color[intIdx],
				hoverBorderColor: backcolor.color[intIdx],
				data:[0,0,0,0,0,0,0,0,0,0,0,0],
			}
			for(var intDtl in barrepeat[intIdx]){
				var intReservCnt = barrepeat[intIdx][intDtl].DataCnt;
				barchart.datasets[intIdx].data[intDtl] = intReservCnt;
			}
		}

	}, [barrepeat])

	/**
	 * コース別来店数集計
	 */
	useEffect(() => {
		//if(courserepeat == '') return;
		var idxColor = 0;
		for(var intIdx in courserepeat){
			//-- カラー設定
			var strColor = '';
			if(idxColor >= 20){
				idxColor = 0;
			}
			strColor = chartBackColor.color[idxColor];
			idxColor += 1;
			//-- ヘッダー情報設定
			coursebarchart.datasets[intIdx] = {
				label: courserepeat[intIdx][0].CourseNm,
				backgroundColor: strColor,
				borderColor: strColor,
				borderWidth: 1,
				hoverBackgroundColor: strColor,
				hoverBorderColor: strColor,
				data:[0,0,0,0,0,0,0,0,0,0,0,0],
			}
			//-- 詳細情報設定
			for(var intDtl in courserepeat[intIdx]){
				var intReservCnt = courserepeat[intIdx][intDtl].ReserveCnt;
				coursebarchart.datasets[intIdx].data[intDtl] = intReservCnt;
			}
		}
	}, [courserepeat])

	/**
	 * 表示年切替
	 * @param e
	 */
	function fncOnChangeYear(e) {
		fieldData.date_year = e.target.value;
		fncOnClickSearch();
	}

	/**
	 * 検索
	 */
	function fncOnClickSearch(){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		setLoading('1');
		fieldData.id = dbconn.id;
		Http.post('api/get-dashboard', {
			...fieldData
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setListRepeat(response.data.Repeat);
				setBarRepeat(response.data.Repeat);
				setCourseRepeat(response.data.Course);
				setAgeData(response.data.Age);
				setGenderData(response.data.Gender);
				setTodayCnt(response.data.Today[0].data_cnt);
				setTomorrowCnt(response.data.Tomorrow[0].data_cnt);
			}else{
			}

			setLoading('0');
		});
	}

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		history.push('/menu' + strPram);
	}

	return (
		<>
			<Form>
				<div>
					<Row className="dash-board-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<div>
					<Row className="dash-board-pie-row">
						<Col md={{ span: 2 }}>
							<label className="dash-board-label">{'本日の予約件数：' + todaycnt + '件'}</label>
							<br/>
							<label className="dash-board-label">{'明日の予約件数：' + tomorrowcnt + '件'}</label>
						</Col>
						<Col md={{ span: 4 }}>
							<div className="text-header first-header">▼年代比（％）</div>
							<Row>
								<Col md={{ span: 12 }}>
									{(agedata != '' && agedata[0].TotalCnt > 0) &&(
										<Pie
											data={pieage}
										/>
									)}
								</Col>
							</Row>
						</Col>
						<Col md={{ span: 4 }}>
							<div className="text-header first-header">▼男女比（％）</div>
							<Row>
								<Col md={{ span: 12 }}>
									{(genderdata != '' && genderdata[0].TotalCnt > 0) &&(
										<Pie
											data={piegender}
										/>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="dash-board-form-row">
						<Col md={{ span: 2 }}>
							<Form.Control
								as="select"
								className="datepicker-year"
								name='year'
								value={fieldData.date_year}
								onChange={ fncOnChangeYear }
							>
								{years.map((value, key) => (
									<option value={value} key={key}>{value}</option>
								))}
							</Form.Control>
						</Col>
					</Row>
					<Row className="dash-board-chart-row">
						<Col md={{ span: 6 }}>
							<div className="text-header first-header">▼再来店率（％）</div>
							<Row>
								<Col md={{ span: 12 }}>
									{(listrepeat != '') &&(
										<Line
											data={linechart}
										/>
									)}
								</Col>
							</Row>
						</Col>
						<Col md={{ span: 6 }}>
							<div className="text-header first-header">▼再来店客数（人）</div>
							<Row>
								<Col md={{ span: 12 }}>
									{(barrepeat != '') &&(
										<Bar
											data={barchart}
										/>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="dash-board-form-row">
						<Col md={{ span: 12 }}>
							<label>
								<a style={{color:'rgb(255,238,47)'}}>[新規]</a>：1回目の来店のお客様　
								<a style={{color:'rgb(88,255,167)'}}>[再来]</a>：2回目の来店のお客様　
								<a style={{color:'rgb(87,186,255)'}}>[固定]</a>：3回目以上の来店のお客様　
								<a style={{color:'rgb(255,139,158)'}}>[リターン]</a>：6ヶ月以上来店がない状態で再度来店してくれたお客様
							</label>
						</Col>
					</Row>
					<Row className="dash-board-course-chart-row">
						<Col md={{ span: 6 }}>
							<div className="text-header first-header">▼コース別来客数（人）</div>
							<Row>
								<Col md={{ span: 12 }}>
									{(courserepeat != '') > 0 &&(
										<Bar
											data={coursebarchart}
										/>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
				<div>
					<Row className="dash-board-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
			</Form>
		</>
	);

}

export default DashBoardForm;