import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../ShopMng.css';
import Http from 'Utils/Axios';
import usePost from 'Utils/EndPoints/usePost';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData';
import CustomModal from 'Utils/Modal/CustomModal';
import { FormInput } from 'Utils/FormHandlers/HandleFormProperties';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row, FormControl, Form } from 'react-bootstrap';
import ColorPicker from 'material-ui-rc-color-picker';
import 'material-ui-rc-color-picker/assets/index.css';
import {
	MSG_CMP_ADD,
	MSG_NG_ADD, MSG_SYSTEM_UPD_BACK,
} from "../../../../Utils/Common/ComConst";
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";
import DatePicker from "react-datepicker";
import { IoMdTime } from "react-icons/io";
import TimePicker from "../../../../Utils/FormHandlers/TimePicker";

const defaultState = {
	shop_id : "",
	shop_nm : "",
	shop_post_cd1 : "",
	shop_post_cd2 : "",
	shop_address1 : "",
	shop_address2 : "",
	shop_tel : "",
	tel_link_flg : "0",
	shop_fax : "",
	shop_mail : "",
	shop_url : "",
	header_logo : "",
	header_color : "#FFFFFF",
	security_flg : "0",
	remind_send_flg: "1",
	remind_send_kbn: "1",
	remind_send_time: 60,
	remind_send_day_kbn: "01",
	remind_send_start_time: "",
	thanks_send_flg : "1",
	thanks_send_kbn : "1",
	thanks_send_time: 60,
	thanks_send_day_kbn : "01",
	thanks_send_start_time : "",
	line_reserve_flg: "1",
	line_cancel_flg: "1",
	line_remind_flg: "0",
	line_thanks_flg: "0",
	registration_stop_flg: "0",
	disp_zan_flg: "1",
	shop_note : "",
	update_id : "",
	id : "",
}

function ShopMngForm({ history, user, data, time, sendfut, sendpas, dbconn }) {
	const defaultData = Object.keys(data).length > 0 ? data : defaultState;
	const [fieldData, handleDataFormat, setManualField] = useHandleFormData({ ...defaultData});
	const [defData] = useHandleFormData({ ...defaultData});
	const [result, submitPost, errors, setErrorClear] = usePost();
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [errMode, setErrMode] = useState('0');
	const [loading, setLoading] = useState('0');
	const fileInput = React.createRef();
	const strPram = fncgSetUrlPram(dbconn);

	const [remindSendTime, setRemindSendTime] = useState('');
	const [thanksSendTime, setThanksSendTime] = useState('');

	/**
	 * ページ離脱イベント
	 * @param e
	 */
	window.onbeforeunload = function(e){
		//--- 確認メッセージ表示(トリガーとしてのみ有効、メッセージは内容は反映されない)
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			e.returnValue = '';
		}
	}

	/**
	 * 初期処理
	 */
	useEffect(() => {
		//--- ロゴ
		if(fieldData.header_logo != null && fieldData.header_logo != ''){
			document.getElementById("imgLogo").src = fieldData.header_logo;
		}else{
			document.getElementById("imgLogo").src = '';
		}

		//--- 送信時間
		let dtNow = new Date();
		let strDate = dtNow.getFullYear() + '/' + (dtNow.getMonth() + 1) + '/' + dtNow.getDate() + ' ';
		//-- リマインドメール
		if(fieldData.remind_send_start_time != null && fieldData.remind_send_start_time != ''){
			setRemindSendTime(new Date(strDate + fieldData.remind_send_start_time + ':00'));
		}
		//-- サンクスメール
		if(fieldData.thanks_send_start_time != null && fieldData.thanks_send_start_time != ''){
			setThanksSendTime(new Date(strDate + fieldData.thanks_send_start_time + ':00'));
		}
	},[]);

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack() {
		//--- 変更チェック
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			if(window.confirm(MSG_SYSTEM_UPD_BACK)){
				//-- 戻る
				history.push('/menu' + strPram);
			}
		}else{
			//-- 戻る
			history.push('/menu' + strPram);
		}
	}

	/**
	 * 項目変更チェック
	 */
	function fncChkBackData(){
		if(fieldData.shop_id != defData.shop_id ||
			fieldData.shop_nm != defData.shop_nm ||
			fieldData.shop_post_cd1 != defData.shop_post_cd1 ||
			fieldData.shop_post_cd2 != defData.shop_post_cd2 ||
			fieldData.shop_address1 != defData.shop_address1 ||
			fieldData.shop_address2 != defData.shop_address2 ||
			fieldData.shop_tel != defData.shop_tel ||
			fieldData.tel_link_flg != defData.tel_link_flg ||
			fieldData.shop_fax != defData.shop_fax ||
			fieldData.shop_mail != defData.shop_mail ||
			fieldData.shop_url != defData.shop_url ||
			fieldData.header_logo != defData.header_logo ||
			fieldData.header_color != defData.header_color ||
			fieldData.security_flg != defData.security_flg ||
			fieldData.remind_send_flg != defData.remind_send_flg ||
			fieldData.remind_send_time != defData.remind_send_time ||
			fieldData.thanks_send_flg != defData.thanks_send_flg ||
			fieldData.thanks_send_time != defData.thanks_send_time ||
			fieldData.line_reserve_flg !== defData.line_reserve_flg ||
			fieldData.line_cancel_flg !== defData.line_cancel_flg ||
			fieldData.line_remind_flg !== defData.line_remind_flg ||
			fieldData.line_thanks_flg !== defData.line_thanks_flg ||
			fieldData.registration_stop_flg !== defData.registration_stop_flg ||
			fieldData.disp_zan_flg !== defData.disp_zan_flg ||
			fieldData.shop_note != defData.shop_note){
			return '1';
		}
		return '0';
	}

	/**
	 * ヘッダーロゴ選択ボタン
	 */
	function fncOnClickImageFile(){
		document.getElementById("header_logo").click();
	}

	/**
	 * ヘッダーロゴ変更
	 * @param e
	 */
	function fncOnChangeImage(e){
		const objFile = e.target.files[0];
		const objFileReader = new FileReader();

		objFileReader.onload = () => {
			document.getElementById("imgLogo").src = objFileReader.result;
			fieldData.header_logo = objFileReader.result;
		};
		objFileReader.readAsDataURL(objFile);
	}

	/**
	 * ヘッダーロゴ取消
	 * @param e
	 */
	function fncOnClickImageCancel(e){
		document.getElementById("imgLogo").src = "";
		fieldData.header_logo = "";
	}

	/**
	 * カラー変更
	 * @param e
	 */
	function fncOnChangeColor(e){
		fieldData.header_color = e.color;
		document.getElementById("header_color").value = e.color;
	};

	/**
	 * カラークリック
	 */
	function fncClickColor(){
		document.getElementById('colorpicker').focus();
	}

	/**
	 * 仮登録チェックボタン
	 * @param e
	 */
	function fncChangeSecurityFlg(e){
		if( fieldData.security_flg != '0' ){
			fieldData.security_flg = '0'
		}else{
			fieldData.security_flg = e.target.value;
		}
	}

	/**
	 * リマインドメール送信チェックボタン
	 * @param e
	 */
	function fncChangeRemindSendFlg(e) {
		const lineRemindFlg = document.getElementById('line_remind_flg');

		if (fieldData.remind_send_flg === '1') {
			fieldData.remind_send_flg = '0';
			fieldData.line_remind_flg = '0';
			lineRemindFlg.disabled = true;
			lineRemindFlg.checked = false;
		} else {
			fieldData.remind_send_flg = e.target.value;
			lineRemindFlg.disabled = false;
		}
	}

	/**
	 * サンクスメール送信チェックボタン
	 * @param e
	 */
	function fncChangeThanksSendFlg(e) {
		const lineThanksFlg = document.getElementById('line_thanks_flg');

		if (fieldData.thanks_send_flg === '1') {
			fieldData.thanks_send_flg = '0';
			fieldData.line_thanks_flg = '0';
			lineThanksFlg.disabled = true;
			lineThanksFlg.checked = false;
		} else {
			fieldData.thanks_send_flg = e.target.value;
			lineThanksFlg.disabled = false;
		}
	}

	/**
	 * リマインドメール送信時間作成
	 * @returns {*}
	 */
	function fncRemindSendTime(){
		var aryTimes = [];
		if(time == "") return;

		for(var intIdx in time){
			aryTimes.push(
				<option value={time[intIdx].com_val2} key={time[intIdx].com_kbn}>
					{time[intIdx].com_val1}
				</option>
			);
		}
		if(aryTimes.length > 0){
			return(
				<>
					{aryTimes}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * リマインドメール送信日作成
	 * @returns {*}
	 */
	function fncRemindSendDays(){
		var aryDays = [];
		if(sendpas == "") return;

		for(var intIdx in sendpas){
			aryDays.push(
				<option value={sendpas[intIdx].com_kbn} key={sendpas[intIdx].com_kbn}>
					{sendpas[intIdx].com_val1}
				</option>
			);
		}
		if(aryDays.length > 0){
			return(
				<>
					{aryDays}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * サンクスメール送信時間作成
	 * @returns {*}
	 */
	function fncThanksSendTime(){
		var aryTimes = [];
		if(time == "") return;

		for(var intIdx in time){
			aryTimes.push(
				<option value={time[intIdx].com_val2} key={time[intIdx].com_kbn}>
					{time[intIdx].com_val1}
				</option>
			);
		}
		if(aryTimes.length > 0){
			return(
				<>
					{aryTimes}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * サンクスメール送信日作成
	 * @returns {*}
	 */
	function fncThanksSendDays(){
		var aryDays = [];
		if(sendfut == "") return;

		for(var intIdx in sendfut){
			aryDays.push(
				<option value={sendfut[intIdx].com_kbn} key={sendfut[intIdx].com_kbn}>
					{sendfut[intIdx].com_val1}
				</option>
			);
		}
		if(aryDays.length > 0){
			return(
				<>
					{aryDays}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * 登録ボタン
	 * @param e
	 */
	function fncOnClickSave(e){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		e.preventDefault();
		//--- エラーメッセージクリア
		setErrorClear();
		setLoading('1');

		//--- 送信時間設定
		if(remindSendTime != '' && remindSendTime != null){
			fieldData.remind_send_start_time = ('0' + remindSendTime.getHours()).slice(-2) + ':' + ('0' + remindSendTime.getMinutes()).slice(-2);
		}else{
			fieldData.remind_send_start_time = '';
		}
		if(thanksSendTime != '' && thanksSendTime != null){
			fieldData.thanks_send_start_time = ('0' + thanksSendTime.getHours()).slice(-2) + ':' + ('0' + thanksSendTime.getMinutes()).slice(-2);
		}else{
			fieldData.thanks_send_start_time = '';
		}

		//--- チェック
		fieldData.id = dbconn.id;
		submitPost('api/chk-shop',{
			...fieldData,
		});
	}

    /**
     * チェック結果エラー
     */
    useEffect(() => {
        if (Object.keys(errors).length > 0) {
			window.scrollTo(0, 0);
			setLoading('0');
        }
    }, [errors])

    /**
     * チェック結果正常
     */
    useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;

        if (Object.keys(result).length > 0) {
			//--- データ格納
        	const submitData = new FormData();
			submitData.append('shop_id', fieldData.shop_id);
			submitData.append('shop_nm', fieldData.shop_nm);
			submitData.append('shop_post_cd1', fieldData.shop_post_cd1);
			submitData.append('shop_post_cd2', fieldData.shop_post_cd2);
			submitData.append('shop_address1', fieldData.shop_address1);
			submitData.append('shop_address2', fieldData.shop_address2);
			submitData.append('shop_tel', fieldData.shop_tel);
			submitData.append('tel_link_flg', fieldData.tel_link_flg);
			submitData.append('shop_fax', fieldData.shop_fax);
			submitData.append('shop_mail', fieldData.shop_mail);
			submitData.append('shop_url', fieldData.shop_url);
			submitData.append('header_logo', fieldData.header_logo);
			submitData.append('header_color', fieldData.header_color);
			submitData.append('security_flg', fieldData.security_flg);
			submitData.append('remind_send_flg', fieldData.remind_send_flg);
			submitData.append('remind_send_kbn', fieldData.remind_send_kbn);
			submitData.append('remind_send_time', fieldData.remind_send_time);
			submitData.append('remind_send_day_kbn', fieldData.remind_send_day_kbn);
			submitData.append('remind_send_start_time', fieldData.remind_send_start_time);
			submitData.append('thanks_send_flg', fieldData.thanks_send_flg);
			submitData.append('thanks_send_kbn', fieldData.thanks_send_kbn);
			submitData.append('thanks_send_time', fieldData.thanks_send_time);
			submitData.append('thanks_send_day_kbn', fieldData.thanks_send_day_kbn);
			submitData.append('thanks_send_start_time', fieldData.thanks_send_start_time);
			submitData.append('line_reserve_flg', fieldData.line_reserve_flg);
			submitData.append('line_cancel_flg', fieldData.line_cancel_flg);
			submitData.append('line_remind_flg', fieldData.line_remind_flg);
			submitData.append('line_thanks_flg', fieldData.line_thanks_flg);
			submitData.append('registration_stop_flg', fieldData.registration_stop_flg);
			submitData.append('disp_zan_flg', fieldData.disp_zan_flg);
			submitData.append('shop_note', fieldData.shop_note);
			submitData.append('update_id', user.staff_id);
			submitData.append("image", fileInput.current.files[0]);
			submitData.append("id", dbconn.id);
			//--- 実行
			Http.post('/api/add-shop', submitData, {
				headers: {
					'content-type': 'multipart/form-data',
				},
			}).then(response => {
				//-- 結果
				const strStatus = response.data.status;
				if(strStatus == '200'){
					global.shop_nm = fieldData.shop_nm;
					setErrMode('0');
					setModalMsg(MSG_CMP_ADD);
				}else{
					const strError = response.data.error;
					setErrMode('1');
					setModalMsg(MSG_NG_ADD + strError);
				}
				//setShow(true);
			}).catch(error => {
				if (error.response) {
					setModalMsg(MSG_NG_ADD + error.response.data);
					//alert(error.response.data)
				}
			}).finally(() => {
				setLoading('0');
				setShow(true);
			})
        }
    }, [result]);

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		//--- 子画面を閉じる
		setShow(false);
		//--- エラーでない場合、画面を閉じる
		if(errMode == '0'){
			//-- 戻る
			history.push('/menu' + strPram);
		}
	}


	return (
		<>
			<Form className="shop-mng-form">
				<div>
					<Row className="shop-mng-form-row">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding">店舗名<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<FormControl
						className="shop-mng-form-input-field"
						type="text"
						value={fieldData.shop_nm}
						onChange={handleDataFormat}
						name="shop_nm"
						maxLength="50"
						{...FormInput}
					/>
				</Row>
				{errors.shop_nm && (
					<Row className="shop-mng-form-row">
						<div className={"div-shop-mng-title"} ></div>
						<div>
							<span className="error-span">{errors.shop_nm[0]}</span>
						</div>
					</Row>
				)}
				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding">郵便番号<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<FormControl
						className="input-shop-mng-post1"
						type="text"
						value={fieldData.shop_post_cd1}
						onChange={handleDataFormat}
						name="shop_post_cd1"
						maxLength="3"
						{...FormInput}
					/>
					<FormControl
						className="input-shop-mng-post2"
						type="text"
						value={fieldData.shop_post_cd2}
						onChange={handleDataFormat}
						name="shop_post_cd2"
						maxLength="4"
						{...FormInput}
					/>
				</Row>
				{(errors.shop_post_cd1 || errors.shop_post_cd2) && (
					<Row className="shop-mng-form-row">
						<div className={"div-shop-mng-title"} ></div>
						<div>
							<span className="error-span">
								{errors.shop_post_cd1? errors.shop_post_cd1[0] :
								 errors.shop_post_cd2? errors.shop_post_cd2[0] : ''}
							</span>
						</div>
					</Row>
				)}
				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding">住所１<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<FormControl
						className="shop-mng-form-input-field"
						type="text"
						value={fieldData.shop_address1}
						onChange={handleDataFormat}
						name="shop_address1"
						maxLength="255"
						{...FormInput}
					/>
				</Row>
				{errors.shop_address1 && (
					<Row className="shop-mng-form-row">
						<div className={"div-shop-mng-title"} ></div>
						<div>
							<span className="error-span">{errors.shop_address1[0]}</span>
						</div>
					</Row>
				)}
				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding">住所２</label>
					</div>
					<FormControl
						className="shop-mng-form-input-field"
						type="text"
						value={fieldData.shop_address2}
						onChange={handleDataFormat}
						name="shop_address2"
						maxLength="255"
						{...FormInput}
					/>
				</Row>
				{errors.shop_address2 && (
					<Row className="shop-mng-form-row">
						<div className={"div-shop-mng-title"} ></div>
						<div>
							<span className="error-span">{errors.shop_address2[0]}</span>
						</div>
					</Row>
				)}
				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding">電話番号<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<FormControl
						className="input-shop-mng-tel"
						type="text"
						value={fieldData.shop_tel}
						onChange={handleDataFormat}
						name="shop_tel"
						maxLength="15"
						{...FormInput}
					/>
				</Row>
				{(errors.shop_tel)&& (
					<Row className="shop-mng-form-row">
						<div className={"div-shop-mng-title"} ></div>
						<div>
							<span className="error-span">
								{errors.shop_tel? errors.shop_tel[0] : ''}
							</span>
						</div>
					</Row>
				)}
				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} ></div>
					<label>
						<input
							type="checkbox"
							value="1"
							defaultChecked={fieldData.tel_link_flg === '1' ? 'checked' : ''}
							onChange={() => (fieldData.tel_link_flg !== '1' ? fieldData.tel_link_flg = '1' : fieldData.tel_link_flg = '0')}
							name="tel_link_flg"
						/> 予約の営業時間外に表示される「TEL」から電話をかけられるようにする
					</label>
				</Row>
				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding">FAX番号</label>
					</div>
					<FormControl
						className="input-shop-mng-fax"
						type="text"
						value={fieldData.shop_fax}
						onChange={handleDataFormat}
						name="shop_fax"
						maxLength="15"
						{...FormInput}
					/>
				</Row>
				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding">ﾒｰﾙｱﾄﾞﾚｽ<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<FormControl
						className="shop-mng-form-input-field"
						type="text"
						value={fieldData.shop_mail}
						onChange={handleDataFormat}
						name="shop_mail"
						maxLength="512"
						{...FormInput}
					/>
				</Row>
				{errors.shop_mail && (
					<Row className="shop-mng-form-row">
						<div className={"div-shop-mng-title"} ></div>
						<div>
							<span className="error-span">{errors.shop_mail[0]}</span>
						</div>
					</Row>
				)}
				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding">サイトURL</label>
					</div>
					<FormControl
						className="shop-mng-form-input-field"
						type="text"
						value={fieldData.shop_url}
						onChange={handleDataFormat}
						name="shop_url"
						maxLength="255"
						{...FormInput}
					/>
				</Row>
				{errors.shop_url && (
					<Row className="shop-mng-form-image-row">
						<div className={"div-shop-mng-title"} ></div>
						<div>
							<span className="error-span">{errors.shop_url[0]}</span>
						</div>
					</Row>
				)}
				<Row className="shop-mng-form-image-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding">ヘッダーロゴ</label>
					</div>
					<Col md={{ span: 2}}>
						<input
							type="file"
							className="shop-mng-logo"
							id="header_logo"
							name="header_logo"
							accept=".jpg,.jpeg,.png"
							onChange={fncOnChangeImage}
							ref={fileInput}
						/>
						<img
							className="shop-mng-img"
							id="imgLogo"
						/>
					</Col>
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-sel"
							onClick={fncOnClickImageFile}
							disabled={loading == '1'? 'disabled' : ''}
						>
							選 択
						</Button>
					</Col>
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-cancel"
							onClick={fncOnClickImageCancel}
							disabled={loading == '1'? 'disabled' : ''}
						>
							取 消
						</Button>
					</Col>
					<div className={"div-shop-color"} >
						<label className="label-shop-mng-send-padding">ヘッダーカラー</label>
					</div>
					<div className={"div-shop-color-input"}>
						<FormControl
							className="shop-mng-input-color"
							type="text"
							id="header_color"
							name="header_color"
							value={fieldData.header_color}
							readOnly="readOnly"
						/>
					</div>
					<div>
						<ColorPicker
							className="shop-mng-color-picker"
							id="colorpicker"
							name="colorpicker"
							defaultColor="#000000"
							color={fieldData.header_color}
							onClose={fncOnChangeColor}
						>
						</ColorPicker>
					</div>
					<div>
						<label
							className={"shop-mng-right-label-color"}
						>
							色を選択
						</label>
					</div>
				</Row>
				<Row className="shop-mng-form-note-row">
					<div className={"div-shop-mng-title"} >
						<label>注記</label>
					</div>
					<textarea
						className="shop-mng-form-textare-field"
						rows="4"
						value={fieldData.shop_note}
						onChange={handleDataFormat}
						name="shop_note"
						maxLength="500"
					/>
				</Row>
				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label>会員登録</label>
					</div>
					<label>
						<input
							className="shop-mng-checkbox"
							type="checkbox"
							value="1"
							defaultChecked={fieldData.security_flg == '1'? 'checked' : ''}
							onChange={fncChangeSecurityFlg}
							name="security_flg"
						/> 会員登録のメールを送信せずに会員登録を行う。(直接、会員登録画面を表示する)
					</label>
				</Row>

				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding">ﾘﾏｲﾝﾄﾞﾒｰﾙ送信</label>
					</div>
					<label className="label-shop-mng-send label-shop-mng-send-padding">
						<input
							className=""
							type="checkbox"
							value="1"
							defaultChecked={fieldData.remind_send_flg == '1'? 'checked' : ''}
							onChange={fncChangeRemindSendFlg}
							name="remind_send_flg"
						/> 送信する
					</label>
					<label className="label-shop-mng-send-padding">
						<input
							type="radio"
							className=""
							name="remind_send_kbn"
							value="1"
							onChange={handleDataFormat}
							checked={fieldData.remind_send_kbn === "1"}

						/> 予約日当日の予約開始時間
					</label>
					<Form.Control
						as="select"
						className="select-shop-mng"
						name='remind_send_time'
						value={fieldData.remind_send_time}
						onChange={handleDataFormat}
					>
						{fncRemindSendTime()}
					</Form.Control>
					<label className="label-right-marge shop-mng-right-label-send">前</label>
				</Row>

				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding"></label>
					</div>
					<label className="label-shop-mng-send label-shop-mng-send-padding">
					</label>
					<label className="label-shop-mng-send-padding">
						<input
							type="radio"
							className=""
							name="remind_send_kbn"
							value="2"
							onChange={handleDataFormat}
							checked={fieldData.remind_send_kbn === "2"}
						/>
					</label>
					<Form.Control
						as="select"
						className="select-shop-send"
						name='remind_send_day_kbn'
						value={fieldData.remind_send_day_kbn}
						onChange={handleDataFormat}
					>
						{fncRemindSendDays()}
					</Form.Control>
					<label className="label-right-marge shop-mng-right-label-send">の</label>
					<TimePicker
						baseClassName="div-shop-send-time"
						selected={remindSendTime}
						onChange={date => setRemindSendTime(date)}
						disabled={data.mode == '3'? 'disabled' : ''}
						id={"dtRemindSendTime"}
					/>
				</Row>

				{errors.remind_send_time && (
					<Row className="shop-mng-form-row">
						<div className={"div-shop-mng-title"} >
							<label className="label-shop-mng-send-padding"></label>
						</div>
						<label className="label-shop-mng-send label-shop-mng-send-padding">
						</label>
						<div>
							<span className="error-span">{errors.remind_send_time[0]}</span>
						</div>
					</Row>
				)}

				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding">ｻﾝｸｽﾒｰﾙ送信</label>
					</div>
					<label className="label-shop-mng-send label-shop-mng-send-padding">
						<input
							className=""
							type="checkbox"
							value="1"
							defaultChecked={fieldData.thanks_send_flg == '1'? 'checked' : ''}
							onChange={fncChangeThanksSendFlg}
							name="thanks_send_flg"
						/> 送信する
					</label>
					<label className="label-shop-mng-send-padding">
						<input
							type="radio"
							className=""
							name="thanks_send_kbn"
							value="1"
							onChange={handleDataFormat}
							checked={fieldData.thanks_send_kbn === "1"}

						/> 予約日当日の予約終了時間
					</label>
					<Form.Control
						as="select"
						className="select-shop-mng"
						name='thanks_send_time'
						value={fieldData.thanks_send_time}
						onChange={handleDataFormat}
					>
						{fncThanksSendTime()}
					</Form.Control>
					<label className="label-right-marge shop-mng-right-label-send">後</label>
				</Row>

				<Row className="shop-mng-form-row">
					<div className={"div-shop-mng-title"} >
						<label className="label-shop-mng-send-padding"></label>
					</div>
					<label className="label-shop-mng-send label-shop-mng-send-padding">
					</label>
					<label className="label-shop-mng-send-padding">
						<input
							type="radio"
							className=""
							name="thanks_send_kbn"
							value="2"
							onChange={handleDataFormat}
							checked={fieldData.thanks_send_kbn === "2"}
						/>
					</label>
					<Form.Control
						as="select"
						className="select-shop-send"
						name='thanks_send_day_kbn'
						value={fieldData.thanks_send_day_kbn}
						onChange={handleDataFormat}
					>
						{fncThanksSendDays()}
					</Form.Control>
					<label className="label-right-marge shop-mng-right-label-send">の</label>
					<TimePicker
						baseClassName="div-shop-send-time"
						selected={thanksSendTime}
						onChange={date => setThanksSendTime(date)}
						disabled={data.mode == '3'? 'disabled' : ''}
						id={"dtThanksSendTime"}
					/>
				</Row>
				
				{errors.thanks_send_time && (
					<Row className="shop-mng-form-row">
						<div className={"div-shop-mng-title"} >
							<label className="label-shop-mng-send-padding"></label>
						</div>
						<label className="label-shop-mng-send label-shop-mng-send-padding">
						</label>
						<div>
							<span className="error-span">{errors.thanks_send_time[0]}</span>
						</div>
					</Row>
				)}

				{/*新規会員受付*/}
				<Row style={{padding: '5px 15px', height: '60px'}}>
					<div className="div-shop-mng-title">
						<label>新規会員受付</label>
					</div>
					<label>
						<input
							type="checkbox"
							value="1"
							defaultChecked={fieldData.registration_stop_flg === '1' ? 'checked' : ''}
							onChange={ (e) => {fieldData.registration_stop_flg === '1' ? fieldData.registration_stop_flg = '0' : fieldData.registration_stop_flg = e.target.value} }
							name="registration_stop_flg"
						/> 新規会員の登録受付を停止する<br/>(停止した場合、予約システムの「会員登録へ」ボタンがクリックできなくなります。)
					</label>
				</Row>
				{/* スタッフ `選択なし` 選択時の表示有無 */}
				<Row className="shop-mng-form-row">
					<div className="div-shop-mng-title">
						<label>予約残り枠</label>
					</div>
					<label>
						<input
							type="checkbox"
							value="1"
							defaultChecked={fieldData.disp_zan_flg === '1' ? 'checked' : ''}
							onChange={() => (fieldData.disp_zan_flg !== '1' ? fieldData.disp_zan_flg = '1' : fieldData.disp_zan_flg = '0')}
							name="disp_zan_flg"
						/> スタッフ「選択なし」の残り枠を表示する
					</label>
				</Row>
				{/* LINEメッセージ送信制御*/}
				<Row className="py-2 px-3" style={!fieldData.is_line_access_token ? {display: 'none'} : {}}>
					<div className="div-shop-mng-title">
						<label>LINEﾒｯｾｰｼﾞ対象</label>
					</div>
					<div style={{display: 'flex', flexDirection: 'column'}}>
						<label>
							<input
								type="checkbox"
								value="1"
								defaultChecked={fieldData.line_reserve_flg === '1' ? 'checked' : ''}
								onChange={() => (fieldData.line_reserve_flg !== '1' ? fieldData.line_reserve_flg = '1' : fieldData.line_reserve_flg = '0')}
								name="line_reserve_flg"
							/> 予約受付メール
						</label>
						<label>
							<input
								type="checkbox"
								value="1"
								defaultChecked={fieldData.line_cancel_flg === '1' ? 'checked' : ''}
								onChange={() => (fieldData.line_cancel_flg !== '1' ? fieldData.line_cancel_flg = '1' : fieldData.line_cancel_flg = '0')}
								name="line_cancel_flg"
							/> 予約キャンセルメール
						</label>
						<label>
							<input
								id="line_remind_flg"
								type="checkbox"
								value="1"
								defaultChecked={fieldData.line_remind_flg === '1' ? 'checked' : ''}
								onChange={() => (fieldData.line_remind_flg !== '1' ? fieldData.line_remind_flg = '1' : fieldData.line_remind_flg = '0')}
								disabled={fieldData.remind_send_flg === '0'}
								name="line_remind_flg"
							/> リマインドメール
						</label>
						<label>
							<input
								id="line_thanks_flg"
								type="checkbox"
								value="1"
								defaultChecked={fieldData.line_thanks_flg === '1' ? 'checked' : ''}
								onChange={() => (fieldData.line_thanks_flg !== '1' ? fieldData.line_thanks_flg = '1' : fieldData.line_thanks_flg = '0')}
								disabled={fieldData.thanks_send_flg === '0'}
								name="line_thanks_flg"
							/> サンクスメール
						</label>
					</div>
				</Row>

				<Row className="shop-mng-button-container">
					<Col md={{ span: 2, offset: 9 }}>
						<Button
							block
							className="shop-mng-button"
							onClick={fncOnClickSave}
							disabled={loading == '1'? 'disabled' : ''}
						>
							更 新
						</Button>
					</Col>
					<Col md={{ span: 1 }}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
				</Row>

				<input type="text" className="dmy-text" name="dummy" />
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>

		</>
	)
}

export default withRouter(ShopMngForm);
