import React from 'react';

import './StaffShift.css';
import Layout from 'layouts/Default/Default';
import StaffShiftForm from "./StaffShift/StaffShiftForm";

function StaffShift({ history, user, shop, data, dbconn, submitReturn}) {

	return (
		<>
			{shop != "" && shop != undefined && user != '' && user != undefined &&(
				<Layout headerText="スタッフ管理 -シフト登録-" _dbconn={dbconn} _user={user}>
					<StaffShiftForm
						history={history}
						user={user}
						shop={shop}
						data={data}
						dbconn={dbconn}
						submitReturn={submitReturn}
					/>
				</Layout >
			)}
		</>
	);

}

export default StaffShift;