import React, {useEffect, useState } from 'react';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { FormControl } from 'react-bootstrap';
import { FormInput } from './HandleFormProperties';
import './PasswordForm.css';

function PasswordForm(props) {
    const [isRevealPassword, setIsRevealPassword] = useState(false); 

    const togglePassword = () => {
        setIsRevealPassword((prevState) => !prevState);
    }

    return (
    <div className={props.className + ' password-form'}>
        <FormControl
            className={'password-form-input'}
            type={isRevealPassword ? 'text' : 'password'}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            name={props.name}
            maxLength={props.maxLength}
            autocomplete={props.autocomplete}
            readOnly={props.readOnly}
            {...FormInput}
        />
        {(props.hasEye) && (
        <span
            onClick={togglePassword}
            role="presentation"
            className={'password-form-toggle-btn'}
            >
            {isRevealPassword ? (
                <IoMdEye/>
            ) : (
                <IoMdEyeOff/>
            )}
        </span>
        )}
    </div>
);
}
export default PasswordForm;