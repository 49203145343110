import React, { useState, useEffect } from 'react';

import '../../Utils/Common/com-style.css';
import './Login.css';
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import LoginForm from './components/LoginForm';
import {fncgGetDbconn} from "../../Utils/Common/ComCom";
import {DISP_FG, PRM_ID, SEARCH_ID, USER_ID, SHIFT_ID, RESERVE_ID} from "../../Utils/Common/ComConst";
import Auth, { GetOlderSettingID, GetOlderSettingPW, IsOlderSettings } from 'Utils/Auth';

function Login({ history, location }) {
	const [errors, setErrors] = useState({});
	const [urlpram, setUrlPram] = useState('');
	const [dbconn, setDbConn] = useState('');
	const [start, setStart] = useState('0');
	const handleSetUrlPram = dat => setUrlPram(dat);
	const handleSetDbConn = dat => setDbConn(dat);
	const handleSetStart = dat => setStart(dat);

	/**
	 * URLパラメータ取得
	 */
	useEffect(() => {
		localStorage.setItem(USER_ID, "");
		localStorage.setItem(DISP_FG, "");
		localStorage.setItem(SEARCH_ID, "");
		localStorage.setItem(SHIFT_ID, "");
		localStorage.setItem(RESERVE_ID, "");

		//--- ページ離脱イベントOFF
		window.onbeforeunload = null;

		//--- URLのハッシュ削除
		let strUrl = document.location.href;
		strUrl = strUrl.replace('#/', '');
		if(document.location.href != strUrl){
			document.location.href = strUrl;
		}

		fncgGetDbconn({history, location, handleSetUrlPram, handleSetDbConn, handleSetStart}, true);
	}, [])

	useEffect(() => {
		if(start == '0') return;
		// トークンが存在する場合、ユーザ情報を取得する。
		if(Auth.HasToken()){
			const aryData ={
				id : dbconn.id,
			}
			Http.post(
				'api/login-user',
				{...aryData}
			).then(response => {
				if (response.data) {
					history.push('/menu' + urlpram);
				}
			}).catch(error => {
			})
		} else if (IsOlderSettings()) {
			// 古い設定が残っている場合は認証を差し替え
			let data  = {
				password : GetOlderSettingPW(),
				username : GetOlderSettingID(),
				id : dbconn.id
			};
			Http.post('/api/login', {
				...data
			}).then(response => {
				if(response.status == '200'){
					Auth.SetAccessToken(response.data.token);
					history.push('/menu' + urlpram);
				}
			}).catch(error => {
			}).finally(() => {
			})
		}
	}, [start])

	/**
	 * ログイン
	 * @param data
	 */
	const onSubmit = data => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
		setErrors({});
		//setLoading(true);

		Http.post('/api/login', {
			...data,
		}).then(response => {
			const strStatus = response.data.status;
			if(strStatus === 200) {
				// localstorageに保存
				Auth.SetAccessToken(response.data.token)

				if (location.state) {
					let { from } = location.state;
					if (from) {
						history.replace(from);
						return;
					}
				}

				history.push('/menu' + urlpram);
			}else if(strStatus === 404){
				history.push('/not-found' + urlpram);
			}else{
				setErrors(response.data)
			}
		}).catch(error => {
			if (error.response) {
				setErrors(error.response.data)
			}
		}).finally(() => {
            //setLoading(false);
        })
	}

	return (
		<>
			{(start == "1" && dbconn.id != '' && dbconn.id != undefined) &&(
				<Layout headerText="ロ グ イ ン" _dbconn={dbconn}>
					<p className="text-header">
						登 録 さ れ た ユ ー ザ ー I D と パ ス ワ ー ド を 入 力 し て ロ グ イ ン ボ タ ン を 押 し て く だ さ い 。
					</p>
					<hr />

					<LoginForm
						onSubmitData={onSubmit}
						errors={errors}
						dbconn={dbconn}
					/>
					<hr />
				</Layout >
			)}
		</>
	);
}

export default Login;
