import React from 'react';
import {Row} from "react-bootstrap";
import TimePicker from "../../../../Utils/FormHandlers/TimePicker";

function CourseMngAddFormWeekSet(
    props
) {
    const ParentClassName = "div-course-mng-save-base-time";

    const index = props.index; 
    const strYobi = props.strYobi;
    const defaultChecked = props.defaultChecked;
    const disabled = props.disabled;
    const fncOnClickHoliday = props.fncOnClickHoliday;
    const strIDBaseS = props.strIDBaseS;
    const strIDBaseE = props.strIDBaseE;
    const valS1 = props.valS1, fncSetS1 = props.fncSetS1;
    const valE1 = props.valE1, fncSetE1 = props.fncSetE1;
    const valS2 = props.valS2, fncSetS2 = props.fncSetS2;
    const valE2 = props.valE2, fncSetE2 = props.fncSetE2;
    const valS3 = props.valS3, fncSetS3 = props.fncSetS3;
    const valE3 = props.valE3, fncSetE3 = props.fncSetE3;
    const valS4 = props.valS4, fncSetS4 = props.fncSetS4;
    const valE4 = props.valE4, fncSetE4 = props.fncSetE4;

    function exportBaseTime() {
        return (
            <>
            <td className={"td-base-time-1"}>
                <Row>
                    <TimePicker
                        baseClassName={ParentClassName}
                        selected={valS1}
                        onChange={fncSetS1}
                        disabled={disabled}
                        id={strIDBaseS + "1"}
                    />
                    <div className="col-md-time-2">
                        <label className={"label-course-mng-padding"}>～</label>
                    </div>
                    <TimePicker
                        baseClassName={ParentClassName}
                        selected={valE1}
                        onChange={fncSetE1}
                        disabled={disabled}
                        id={strIDBaseE + "1"}
                    />
                </Row>
            </td>
            <td className={"td-base-time-2"}>
                <Row>
                    <TimePicker
                        baseClassName={ParentClassName}
                        selected={valS2}
                        onChange={fncSetS2}
                        disabled={disabled}
                        id={strIDBaseS + "2"}
                    />
                    <div className="col-md-time-2">
                        <label className={"label-course-mng-padding"}>～</label>
                    </div>
                    <TimePicker
                        baseClassName={ParentClassName}
                        selected={valE2}
                        onChange={fncSetE2}
                        disabled={disabled}
                        id={strIDBaseE + "2"}
                    />
                </Row>
            </td>
            <td className={"td-base-time-3"}>
                <Row>
                    <TimePicker
                        baseClassName={ParentClassName}
                        selected={valS3}
                        onChange={fncSetS3}
                        disabled={disabled}
                        id={strIDBaseS + "3"}
                    />
                    <div className="col-md-time-2">
                        <label className={"label-course-mng-padding"}>～</label>
                    </div>
                    <TimePicker
                        baseClassName={ParentClassName}
                        selected={valE3}
                        onChange={fncSetE3}
                        disabled={disabled}
                        id={strIDBaseE + "3"}
                    />
                </Row>
            </td>
            <td className={"td-base-time-4"}>
                <Row>
                    <TimePicker
                        baseClassName={ParentClassName}
                        selected={valS4}
                        onChange={fncSetS4}
                        disabled={disabled}
                        id={strIDBaseS + "4"}
                    />
                    <div className="col-md-time-2">
                        <label className={"label-course-mng-padding"}>～</label>
                    </div>
                    <TimePicker
                        baseClassName={ParentClassName}
                        selected={valE4}
                        onChange={fncSetE4}
                        disabled={disabled}
                        id={strIDBaseE + "4"}
                    />
                </Row>
            </td>
            </>
        );
    }

    if (index) {
        return (
            <>
            <tr key={index}>
            <td className={"td-base-yobi"}>
                {strYobi}
            </td>
            <td className={"td-base-holiday"}>
                <input
                    className=""
                    type="checkbox"
                    value={index}
                    defaultChecked={defaultChecked}
                    onChange={fncOnClickHoliday}
                    name={"holiday_kbn" + index}
                    id={"holiday_kbn" + index}
                    disabled={disabled}
                />
            </td>
            {exportBaseTime()}
            </tr>
            </>
        );
    } else {
        return exportBaseTime();
    }
}
export default CourseMngAddFormWeekSet;