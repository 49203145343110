import React, { useState, useEffect } from 'react';

import './Course.css';
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import CourseMngList from "./CourseMngList/CourseMngList";
import CourseMngAddForm from "./CourseMngAdd/CourseMngAddForm";
import {fncgGetUserLoginCheck, fncgGetDbconn} from "Utils/Common/ComCom";
import {DISP_FG, PRM_ID, SEARCH_ID} from "../../../Utils/Common/ComConst";
import { useLocation } from 'react-router-dom';

let dispFlg = '0';

function Course({ match, history, location }) {
    const [user, setUser] = useState('');
    const [urlpram, setUrlPram] = useState('');
    const [dbconn, setDbConn] = useState('');
    const [start, setStart] = useState('0');
    const [data, setData] = useState('');
    const [disp, setDisp] = useState('0');
    const path = useLocation().pathname;
    const handleSetUser = userData => setUser(userData);
	const handleSetUrlPram = dat => setUrlPram(dat);
	const handleSetDbConn = dat => setDbConn(dat);
	const handleSetStart = dat => setStart(dat);

    /**
     * 初期処理
     */
    useEffect(() => {
        //-- 画面情報
        const dispFg = localStorage.getItem(DISP_FG);
        if(dispFg != '' && dispFg != undefined){
            dispFlg = dispFg;
        }else{
            dispFlg = '0';
        }
        let searchID = localStorage.getItem(SEARCH_ID);
        if(searchID != '' && searchID != undefined){
            searchID = JSON.parse(searchID);
            setData(searchID);
        }

		fncgGetDbconn({history, location, handleSetUrlPram, handleSetDbConn, handleSetStart});
    }, [])

    /**
     * スタッフ情報取得
     */
    useEffect(() => {
        if(start == '0') return;
        // ログインチェックを行いユーザ情報を返却する
        fncgGetUserLoginCheck({dbconn, urlpram, path, user, handleSetUser, history})
    }, [start])

    /**
     * 新規登録
     * @param data
     */
    const onSubmitMoveAddDisp = data => {
        setDisp('1');
        data.id = dbconn.id;
        setData(data);
        dispFlg = '1';
        localStorage.setItem(DISP_FG, '1');
        window.scrollTo(0, 0);
    }

    /**
     * 一覧画面表示
     * @param data
     */
    const onSubmitMoveListDisp = data => {
        dispFlg = '0';
        setDisp('0');
        data.id = dbconn.id;
        setData(data);
        localStorage.setItem(DISP_FG, '0');
        window.scrollTo(0, 0);
    }

    return (
        <>
            {(dispFlg == '0' && user != "" && user != undefined && dbconn.id != '' && dbconn.id != undefined) && (
                <Layout headerText="コース管理 -コース一覧-" _dbconn={dbconn} _user={user}>
                    <CourseMngList
                        history={history}
                        user={user}
                        dbconn={dbconn}
                        submitMove={onSubmitMoveAddDisp}
                    />
                </Layout >
            )}
            {(dispFlg == '1' && user != "" && user != undefined && dbconn.id != '' && dbconn.id != undefined) && (
                <Layout headerText="コース管理 -コース登録-" _dbconn={dbconn} _user={user}>
                    <CourseMngAddForm
                        history={history}
                        user={user}
                        data={data}
                        dbconn={dbconn}
                        submitReturn={onSubmitMoveListDisp}
                    />
                </Layout>
            )}
        </>
    );
}

export default Course;
