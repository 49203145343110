import Http from 'Utils/Axios';
import Auth, { GetOlderSettingID, GetOlderSettingPW, IsOlderSettings } from 'Utils/Auth';

/**
 * URLパラメータ取得
 * @param urlval
 * @param search
 * @returns {string}
 */
import {APP_TOP, PRM_ID} from "./ComConst";

/**
 * ID取得
 * @param urlval
 * @param search
 * @returns {string|{id: string}}
 */
export function fncgGetUrlPrams(urlval, search)
{

    let idxSearch = urlval.indexOf( search );
    if(idxSearch < 0){
        return '';
    }else{
        let strUrlPram = urlval.substring(idxSearch);
        let params = {id : ''}

        //urlパラメータをオブジェクトにまとめる
        let aryPram = strUrlPram.split('&');
        let aryTemp = aryPram[0].split('=');
        params.id = aryTemp[1];
        /*
        strUrlPram.split('&').forEach( param => {
            const temp = param.split('=')
            //pramsオブジェクトにパラメータを追加
            params = {
                ...params,
                [temp[0]]: temp[1]
            }
        })
        */
        return params;
    }
}

/**
 * パラメータ取得(ID以外)
 * @param urlval
 * @param search
 * @returns {string}
 */
export function fncgGetUrlPramInfo(urlval, search)
{
    let idxSearch = urlval.indexOf( search );
    if(idxSearch < 0){
        return '';
    }else{
        let strUrlPram = urlval.substring(idxSearch);
        let params = '';

        //urlパラメータをオブジェクトにまとめる
        let aryPram = strUrlPram.split('&');
        let aryTemp = aryPram[0].split('=');
        params = aryTemp[1];

        return params;
    }
}

/**
 * パラメータ作成
 */
export function fncgSetUrlPram(urlpram){
    let strPram = '';
    if(urlpram != '' && urlpram.id != undefined){
        strPram = '?' + PRM_ID + '=' + urlpram.id;
    }
    return strPram;
}

/**
 * パラメータ作成(ID以外)
 */
export function fncgSetUrlPramInfo(codepram, urlpram){
    let strPram = '';
    if(urlpram != '' && urlpram != undefined){
        strPram = '&' + codepram + '=' + urlpram;
    }
    return strPram;
}

export function fncgGetID() {
    let pram = fncgGetUrlPrams(document.location.href, PRM_ID);
    return pram != '' ? pram.id : '';
}

/**
 * ログインチェック
 * ※fncgGetDbconn()を事前呼び出ししている前提の処理です。
 */
export function fncgGetUserLoginCheck({dbconn, urlpram, path, handleSetUser, history}){
    // DB接続確認
    if (!isDbconnId(dbconn)) return

    // トークンが存在する場合、ユーザ情報を取得する。
    // 存在しない場合、ログイン画面にリダイレクトする。
    if(Auth.HasToken()){
        const aryData ={
            id : dbconn.id,
        }
        Http.post(
            'api/login-user',
            {...aryData}
        ).then(response => {
            response.data ? handleSetUser(response.data) : transitionToLogin({urlpram, history})
        }).catch(error => {
            transitionToLogin({urlpram, history});
        })
    } else {
        transitionToLogin({urlpram, history});
    }
}

/**
 * DB兼ログインチェック
 */
export function fncgGetDbconn({history, location, handleSetUrlPram, handleSetDbConn, handleSetStart}, unSecure) {
    let pram = fncgGetUrlPrams(document.location.href, PRM_ID);
    let strPram = fncgSetUrlPram(pram);
    handleSetDbConn(pram);
    handleSetUrlPram(strPram);

    //--- DBチェック
    let aryData ={
        id : pram.id,
    }
    Http.post('api/' + (unSecure ? '' : 'auth/') + 'db_name',{...aryData}).then(response => {
        if(response.data.dbname == 'error'){
            history.push('/not-found' + strPram);
        }else if (unSecure || response.data.secure){
            handleSetStart('1');
        }else{
            // ログイン（トップページ）へ
            history.replace(APP_TOP + strPram, { from: location });
        }
    }).catch(error => {
        if (!unSecure) {
            // 旧ログイン確認
            if (fncCheckAndReplacedLogin( pram.id, () => {
                handleSetStart('1');
            }, () => {
                history.replace(APP_TOP + strPram, { from: location });
            }) ) return;
        }
        history.replace(APP_TOP + strPram, { from: location });
    });
}

/**
 * 旧方式ログインを確認し、新ログインに移行します。
 * 戻り値trueの場合、非同期でコールバックが実行されます。
 */
 function fncCheckAndReplacedLogin(_id, callbackOK, callbackNG) {
    if (IsOlderSettings()) {
        // 古い設定が残っている場合は認証を差し替え
        let data  = {
            password : GetOlderSettingPW(),
            username : GetOlderSettingID(),
            id : _id
        };
        Http.post('/api/login', {
            ...data,
        }).then(response => {
            if(response.status == '200'){
                Auth.SetAccessToken(response.data.token);
                return callbackOK();
            } else {
                Auth.SetAccessToken();
                return callbackNG();
            }
        }).catch(error => {
            Auth.SetAccessToken();
            return callbackNG();
        }).finally(() => {
        });
        return true;
    }
    return false;
}

/**
 * DB接続チェック
 */
export const isDbconnId = dbconn => { return dbconn !== '' && dbconn !== undefined }

/**
 * StaffIDチェック
 */
export const isStaffId = () => { return global.staff_id !== '' && global.staff_id !== undefined }

/**
 * ローカルストレージのログインチェック
 */
export const isLocalStorage = () => { return localStorage.getItem('dummy') && localStorage.getItem('test') }

/**
 * ログイン画面へ遷移処理
 */
export const transitionToLogin = ({urlpram, history}) => {
    localStorage.clear();
    history.push(APP_TOP + urlpram)
}