import React, { useState	 } from 'react';

export default function useHandleFormData(initialValue) {
	const [data, setData] = useState(initialValue);

	function handleDataFormat(e) {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	return [data, handleDataFormat, setData]
}
