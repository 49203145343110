import React, {useEffect, useState} from 'react';
import '../../../../Utils/Common/com-style.css';
import '../ReserveMng.css';
import Http from 'Utils/Axios';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData';
import { FormInput } from 'Utils/FormHandlers/HandleFormProperties';
import {Button, Col, Row, Form, Modal, FormControl} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import '../../../../Utils/Common/react-datepicker.css';
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";
import {
	MSG_INFO_DATE_INPUT,
	MSG_NOT_DATA,
	years,
	months,
	SEARCH_ID,
	RESERVE_ID
} from "../../../../Utils/Common/ComConst";
import { CSVLink, CSVDownload } from "react-csv";
import { IoIosCalendar } from "react-icons/io";
import MaterialPagination from '@material-ui/lab/Pagination';
import CustomModal from '../../../../Utils/Modal/CustomModal';
import InputMask from 'react-input-mask';

const dtNow = new Date();

const defaultState = {
	user_nm : "",
	user_kn : "",
	start_date : "",
	end_date : "",
	sts_reserve : "",
	sts_comp : "",
	sts_cancel : "",
	sts_not : "",
	sts_late : "",
	search_normal_rsv : "",
	search_instant_rsv : "",
	search_fill_rsv : "",
	id : "",
}

const defaultReserve = {
	reserve_id : "",
	user_id : "",
	mode : "",
	id : "",
}

function ReserveMngListForm({ history, dbconn, submitMove }) {
	const [fieldData, handleDataFormat] = useHandleFormData({...defaultState});
	const [fieldReserve] = useState({defaultReserve});
	const [listData, setListData] = useState('');
	const [csvData, setCsvData] = useState('');
	const [csvFileNm, setCsvFileNm] = useState('');
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndtDate] = useState('');
	const [modalShow, setModalShow] = useState(false);
	const [note, setNote] = useState('');
	const [staffNote, setStaffNote] = useState('');
	const [loading, setLoading] = useState('0');
	const strPram = fncgSetUrlPram(dbconn);
	const [searchNormal, setSearchNormal] = useState('');
	const [searchInstant, setSearchInstant] = useState('');
	const [searchFill, setSearchFill] = useState('');
	const [customModalShow, setCustomModalShow] = useState(false);
	const [modalMsg, setCustomModalMsg] = useState('');

	/**
	 * 起動時データ取得
	 */
	useEffect(() => {
		//--- ページ離脱イベントOFF
		window.onbeforeunload = null;

		//--- 検索条件保持判定
		if((global.g_user_nm != '' && global.g_user_nm != undefined && global.g_user_nm != null) ||
			(global.g_user_kn != '' && global.g_user_kn != undefined && global.g_user_kn != null) ||
			(global.g_start_date != '' && global.g_start_date != undefined && global.g_start_date != null) ||
			(global.g_end_date != '' && global.g_end_date != undefined && global.g_end_date != null) ||
			(global.g_sts_reserve != '' && global.g_sts_reserve != undefined && global.g_sts_reserve != null) ||
			(global.g_sts_comp != '' && global.g_sts_comp != undefined && global.g_sts_comp != null) ||
			(global.g_sts_cancel != '' && global.g_sts_cancel != undefined && global.g_sts_cancel != null) ||
			(global.g_sts_not != '' && global.g_sts_not != undefined && global.g_sts_not != null) ||
			(global.g_sts_late != '' && global.g_sts_late != undefined && global.g_sts_late != null)){
			//-- 検索条件設定
			fieldData.user_nm = global.g_user_nm;
			fieldData.user_kn = global.g_user_kn;
			fieldData.start_date = global.g_start_date;
			fieldData.end_date = global.g_end_date;
			fieldData.sts_reserve = global.g_sts_reserve;
			fieldData.sts_comp = global.g_sts_comp;
			fieldData.sts_cancel = global.g_sts_cancel;
			fieldData.sts_not = global.g_sts_not;
			fieldData.sts_late = global.g_sts_late;
			if (global.g_search_normal_rsv != undefined ) {
				fieldData.search_normal_rsv = global.g_search_normal_rsv;
			} else {
				fieldData.search_normal_rsv = '1';
				global.g_search_normal_rsv = fieldData.search_normal_rsv;
				setSearchNormal(fieldData.search_normal_rsv);
			}
			if (global.g_search_instant_rsv != undefined) {
				fieldData.search_instant_rsv = global.g_search_instant_rsv;
			} else {
				fieldData.search_instant_rsv = '1';
				global.g_search_instant_rsv = fieldData.search_instant_rsv;
				setSearchInstant(fieldData.search_instant_rsv);
			}
			if (global.g_search_fill_rsv != undefined) {
				fieldData.search_fill_rsv = global.g_search_fill_rsv;
			} else {
				fieldData.search_fill_rsv = '';
				global.g_search_fill_rsv = fieldData.search_fill_rsv;
			}

			//-- 日付設定
			if(global.g_start_date != '' && global.g_start_date != undefined && global.g_start_date != null){
				setStartDate(new Date(fieldData.start_date ));
			}
			if((global.g_end_date != '' && global.g_end_date != undefined && global.g_end_date != null)){
				setEndtDate(new Date(fieldData.end_date));
			}
		}else{
			//-- 日付の設定
			fieldData.start_date = dtNow.getFullYear() + '-' + (dtNow.getMonth() + 1) + '-' + dtNow.getDate();
			fieldData.end_date = dtNow.getFullYear() + '-' + (dtNow.getMonth() + 1) + '-' + dtNow.getDate();
			//-- 検索条件の設定
			fieldData.search_normal_rsv = '1';
			fieldData.search_instant_rsv = '1';
			fieldData.search_fill_rsv = '';
			//-- 検索条件初期化
			global.g_user_nm = '';
			global.g_user_kn = '';
			global.g_start_date = '';
			global.g_end_date = '';
			global.g_sts_reserve = '';
			global.g_sts_comp = '';
			global.g_sts_cancel = '';
			global.g_sts_not = '';
			global.g_sts_late = '';
			global.g_search_normal_rsv = '1';
			global.g_search_instant_rsv = '1';
			global.g_search_fill_rsv = '';
			//-- 日付設定
			setStartDate(new Date(fieldData.start_date ));
			setEndtDate(new Date(fieldData.end_date));
			//-- 検索条件初期値設定
			setSearchNormal(fieldData.search_normal_rsv);
			setSearchInstant(fieldData.search_instant_rsv);
		}

		//--- 検索
		fncOnClickSearch(null, true);
	}, [])

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		//--- 検索条件初期化
		global.g_user_nm = '';
		global.g_user_kn = '';
		global.g_start_date = '';
		global.g_end_date = '';
		global.g_sts_reserve = '';
		global.g_sts_comp = '';
		global.g_sts_cancel = '';
		global.g_sts_not = '';
		global.g_sts_late = '';
		global.g_search_normal_rsv = '1';
		global.g_search_instant_rsv = '1';
		global.g_search_fill_rsv = '';
		localStorage.setItem(SEARCH_ID, '');
		localStorage.setItem(RESERVE_ID, '');
		//--- 戻る
		history.push('/menu' + strPram);
	}

	/**
	 * 検索
	 */
	function fncOnClickSearch(e, init){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		setLoading('1');

		//--- 日付の設定
		if(startDate != '' && startDate != null){
			fieldData.start_date = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
		}
		if(endDate != '' && endDate != null){
			fieldData.end_date = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
		}
		fieldData.id = dbconn.id;

		//--- 検索条件退避
		global.g_user_nm = fieldData.user_nm;
		global.g_user_kn = fieldData.user_kn;
		global.g_start_date = fieldData.start_date;
		global.g_end_date = fieldData.end_date;
		global.g_sts_reserve = fieldData.sts_reserve;
		global.g_sts_comp = fieldData.sts_comp;
		global.g_sts_cancel = fieldData.sts_cancel;
		global.g_sts_not = fieldData.sts_not;
		global.g_sts_late = fieldData.sts_late;
		global.g_search_normal_rsv = fieldData.search_normal_rsv;
		global.g_search_instant_rsv = fieldData.search_instant_rsv;
		global.g_search_fill_rsv = fieldData.search_fill_rsv;
		localStorage.setItem(RESERVE_ID, JSON.stringify(fieldData));

		//--- 検索
		Http.post('api/search-reserve', {
			...fieldData
		}).then(response => {
			if (Object.keys(response.data.list).length > 0) {
				setListData(response.data.list);
				setCsvData(response.data.csv);
			}else{
				setListData('');
				setCsvData('');
				if (!init) {
					setCustomModalMsg(MSG_NOT_DATA);
					setCustomModalShow(true);
				}
			}
			fieldData.start_date = '';
			fieldData.end_date = '';
			setLoading('0');
		});
	}

	/**
	 * CSVボタン
	 */
	function fncOnClickCsv(){
		//--- ファイル名作成
		let dtNowCsv = new Date();
		let year = dtNowCsv.getFullYear().toString();
		let month = (dtNowCsv.getMonth() + 1).toString();
		let day = dtNowCsv.getDate().toString();
		// let hours = dtNowCsv.getHours().toString();
		// let minutes = dtNowCsv.getMinutes().toString();
		// let secounds = dtNowCsv.getSeconds().toString();
		//-- 0埋め
		month = ('0' + (month)).slice(-2);
		day = ('0' + (day)).slice(-2);
		// hours = ('0' + (hours)).slice(-2);
		// minutes = ('0' + (minutes)).slice(-2);
		// secounds = ('0' + (secounds)).slice(-2);
		//-- ファイル日時
		let fileTime = year + month + day;

		setCsvFileNm('予約一覧_' + fileTime + '.csv');
	}

	/**
	 * 新規ボタン
	 */
	function fncOnClickAddNew(){
		fieldReserve.reserve_id = '';
		fieldReserve.user_id = '';
		fieldReserve.mode = '1';
		fieldReserve.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldReserve));
		submitMove(fieldReserve);
	}

	/**
	 * 編集ボタン
	 */
	function fncOnClickMngUpd(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strReserveID = document.getElementById('hdn-reserve-id-' + strID ).value;
		var strUserID = document.getElementById('hdn-user-id-' + strID ).value;
		fieldReserve.reserve_id = strReserveID;
		fieldReserve.user_id = strUserID;
		fieldReserve.mode = '2';
		fieldReserve.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldReserve));
		submitMove(fieldReserve);
	}

	/**
	 * 削除ボタン
	 */
	function fncOnClickMngDel(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strReserveID = document.getElementById('hdn-reserve-id-' + strID ).value;
		var strUserID = document.getElementById('hdn-user-id-' + strID ).value;
		fieldReserve.reserve_id = strReserveID;
		fieldReserve.user_id = strUserID;
		fieldReserve.mode = '3';
		fieldReserve.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldReserve));
		submitMove(fieldReserve);
	}

	/**
	 * 要望表示ボタン
	 * @param e
	 */
	function fncOnClickNoteShow(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strReserveNote = document.getElementById('hdn-note-' + strID ).value;
		var strStaffNote = document.getElementById('hdn-staff-note-' + strID ).value;
		setNote(strReserveNote);
		setStaffNote(strStaffNote);
		setModalShow(true);
	}

	/**
	 * 状態更新ボタン
	 * @param e
	 */
	function fncOnClickStatusUpd(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strReserveID = document.getElementById('hdn-reserve-id-' + strID ).value;
		var strUserID = document.getElementById('hdn-user-id-' + strID ).value;
		fieldReserve.reserve_id = strReserveID;
		fieldReserve.user_id = strUserID;
		fieldReserve.mode = '4';
		fieldReserve.id = dbconn.id;
		submitMove(fieldReserve);
	}

	/**
	 * キャンセルボタン
	 * @param e
	 */
	function fncOnClickMngCancel(e) {
		const strID = e.currentTarget.getAttribute('data-id');
		var strReserveID = document.getElementById('hdn-reserve-id-' + strID ).value;
		var strUserID = document.getElementById('hdn-user-id-' + strID ).value;
		fieldReserve.reserve_id = strReserveID;
		fieldReserve.user_id = strUserID;
		fieldReserve.mode = '5';
		fieldReserve.id = dbconn.id;
		submitMove(fieldReserve);
	}

	/**
	 * 検索条件-予約中
	 * @param e
	 */
	function fncChangeReserve(e){
		if( fieldData.sts_reserve != '' ){
			fieldData.sts_reserve = ''
		}else{
			fieldData.sts_reserve = e.target.value;
		}
	}

    /**
     * 検索条件-来院
     * @param e
     */
    function fncChangeComp(e){
        if( fieldData.sts_comp != '' ){
            fieldData.sts_comp = ''
        }else{
            fieldData.sts_comp = e.target.value;
        }
    }

    /**
     * 検索条件-キャンセル
     * @param e
     */
    function fncChangeCancel(e){
        if( fieldData.sts_cancel != '' ){
            fieldData.sts_cancel = ''
        }else{
            fieldData.sts_cancel = e.target.value;
        }
    }

    /**
     * 検索条件-未来院
     * @param e
     */
    function fncChangeNot(e){
        if( fieldData.sts_not != '' ){
            fieldData.sts_not = ''
        }else{
            fieldData.sts_not = e.target.value;
        }
    }

    /**
     * 検索条件-未来院
     * @param e
     */
    function fncChangeLate(e){
        if( fieldData.sts_late != '' ){
            fieldData.sts_late = ''
        }else{
            fieldData.sts_late = e.target.value;
        }
    }

	/**
	 * 検索対象-通常
	 * @param e
	 */
	function fncSelectNormal(e){
		if( fieldData.search_normal_rsv != '' ){
			fieldData.search_normal_rsv = '';
		}else{
			fieldData.search_normal_rsv = e.target.value;
		}
	}

	/**
	 * 検索対象-簡易
	 * @param e
	 */
	function fncSelectInstant(e){
		if( fieldData.search_instant_rsv != '' ){
			fieldData.search_instant_rsv = '';
		}else{
			fieldData.search_instant_rsv = e.target.value;
		}
	}

	/**
	 * 検索対象-枠埋め
	 * @param e
	 */
	function fncSelectFill(e){
		if( fieldData.search_fill_rsv != '' ){
			fieldData.search_fill_rsv = '';
		}else{
			fieldData.search_fill_rsv = e.target.value;
		}
	}

	/**
	 * モーダルクローズ
	 */
	function fncOnclickModalClose(){
    	setModalShow(false);
	}

	/**
	 * モーダルクローズ（CustomModal）
	 */
	function fncReturnModalClose(){
		setCustomModalShow(false);
	}
	
	/**
	 * 開始日にカーソルを設定
	 */
	function fncClickSetStartDate(){
		document.getElementById('dtStartDate').focus();
	}

	/**
	 * 終了日にカーソルを設定
	 */
	function fncClickSetEndDate(){
		document.getElementById('dtEndDate').focus();
	}

	/**
	 * Pagination
	 * @returns {*}
	 */
	const [currentPage, setCurrentPage] = useState(1);
	const listsPerPage = 300;
	const indexOfLastList = currentPage * listsPerPage;
	const indexOfFirstList = indexOfLastList - listsPerPage;
	const currentLists = listData.slice(indexOfFirstList, indexOfLastList);

	const handleChange = (event, value) => {
		setCurrentPage(value);
	}

	return (
		<>
			<Form >
				<div>
					<Row className="reserve-mng-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<div className="text-header first-header">▼検索</div>
				<Row className="reserve-mng-value">
					<div className="width-search-label">
						<label className="label-reserve-mng-title">会員名</label>
					</div>
					<Col md={{ span: 3}}>
						<FormControl
							className="input-reserve-mng-field"
							type="text"
							value={fieldData.user_nm || ''}
							onChange={handleDataFormat}
							name="user_nm"
							{...FormInput}
						/>
					</Col>
					<Col md={{ span: 1}}></Col>
					<div className="width-search-label">
						<label className="label-reserve-mng-title">会員カナ</label>
					</div>
					<Col md={{ span: 3}}>
						<FormControl
							className="input-reserve-mng-field"
							type="text"
							value={fieldData.user_kn || ''}
							onChange={handleDataFormat}
							name="user_kn"
							{...FormInput}
						/>
					</Col>
				</Row>
				<Row className="reserve-mng-value">
					<div className="width-search-label">
						<label className="label-reserve-mng-title">予約日</label>
					</div>
					<div className="padding-left-15">
						<label className={"label-reserve-mng-padding"}>From</label>
					</div>
					<div className={"div-date-picker-width"}>
						<DatePicker
							className="date-picker"
							showPopperArrow={false}
							selected={startDate}
							onChange={date => setStartDate(date)}
							dateFormat="yyyy/MM/dd"
							customInput={<InputMask mask="9999/99/99" maskChar={null} />}
							id={"dtStartDate"}
							renderCustomHeader={({
								date,
								changeYear,
								changeMonth,
							}) =>(
								<div
									className={"div-date-picker"}
								>
									<select
										className={"select-date-picker"}
										value={date.getFullYear()}
										onChange={({target:{value}}) => changeYear(value)}
									>
										{years.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
									<select
										className={"select-date-picker"}
										value={months[date.getMonth()]}
										onChange={({ target: { value } }) =>
											changeMonth(months.indexOf(value))
										}
									>
										{months.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
								</div>
							)}
						/>
					</div>
					<div className={"div-calendar-icon"}>
						<a onClick={fncClickSetStartDate}>
							<IoIosCalendar/>
						</a>
					</div>
					<div className="width-30 padding-left-15 text-center">
						<label className={"label-reserve-mng-padding"}>～</label>
					</div>
					<div className="padding-left-15">
						<label className={"label-reserve-mng-padding"}>To</label>
					</div>
					<div className={"div-date-picker-width"}>
						<DatePicker
							className="date-picker"
							showPopperArrow={false}
							selected={endDate}
							onChange={date => setEndtDate(date)}
							dateFormat="yyyy/MM/dd"
							customInput={<InputMask mask="9999/99/99" maskChar={null} />}
							id={"dtEndDate"}
							renderCustomHeader={({
													 date,
													 changeYear,
													 changeMonth,
												 }) =>(
								<div
									className={"div-date-picker"}
								>
									<select
										className={"select-date-picker"}
										value={date.getFullYear()}
										onChange={({target:{value}}) => changeYear(value)}
									>
										{years.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
									<select
										className={"select-date-picker"}
										value={months[date.getMonth()]}
										onChange={({ target: { value } }) =>
											changeMonth(months.indexOf(value))
										}
									>
										{months.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
								</div>
							)}
						/>
					</div>
					<div className={"div-calendar-icon"}>
						<a onClick={fncClickSetEndDate}>
							<IoIosCalendar/>
						</a>
					</div>
				</Row>

				<Row className="reserve-mng-value">
					<div className="width-search-label">
						<label className="label-reserve-mng-title">予約状況</label>
					</div>
					<div className="width-search-label">
                        <label className="padding-left-15">
                            <input
                                className=""
                                type="checkbox"
                                value="0"
                                onChange={fncChangeReserve}
								defaultChecked={global.g_sts_reserve == '0' ? 'defaultChecked' : ''}
                                name="sts_reserve"
                            /> 予約中
                        </label>
					</div>
					<div className="width-search-label">
                        <label>
                            <input
                                className=""
                                type="checkbox"
                                value="1"
                                onChange={fncChangeComp}
								defaultChecked={global.g_sts_comp == '1' ? 'defaultChecked' : ''}
                                name="sts_comp"
                            /> 来院
                        </label>
					</div>
					<div className="width-search-label">
						<label>
							<input
								className=""
								type="checkbox"
								value="2"
								onChange={fncChangeCancel}
								defaultChecked={global.g_sts_cancel == '2' ? 'defaultChecked' : ''}
								name="sts_cancel"
							/> キャンセル
						</label>
					</div>
					<div className="width-search-label">
						<label>
							<input
								className=""
								type="checkbox"
								value="3"
								onChange={fncChangeNot}
								defaultChecked={global.g_sts_not == '3' ? 'defaultChecked' : ''}
								name="sts_not"
							/> 未来院
						</label>
					</div>
					<div className="width-search-label">
                        <label>
                            <input
                                className=""
                                type="checkbox"
                                value="4"
                                onChange={fncChangeLate}
								defaultChecked={global.g_sts_late == '4' ? 'defaultChecked' : ''}
                                name="sts_late"
                            /> 遅刻
                        </label>
                    </div>
				</Row>

				<Row className="reserve-mng-value">
					<div className="width-search-label">
						<label className="label-reserve-mng-title">検索対象</label>
					</div>
					<div className="width-search-label">
						<label className="padding-left-15">
							<input
								className=""
								type="checkbox"
								value="1"
								onChange={fncSelectNormal}
								defaultChecked={global.g_search_normal_rsv == '1' ? 'defaultChecked' : (searchNormal == '1' ? 'defaultChecked' : '')}
								// defaultChecked={searchNormal == '1' ? 'defaultChecked' : ''}
								name="search_normal_rsv"
							/> 通常予約
						</label>
					</div>
					<div className="width-search-label">
						<label>
							<input
								className=""
								type="checkbox"
								value="1"
								onChange={fncSelectInstant}
								defaultChecked={global.g_search_instant_rsv == '1' ? 'defaultChecked' : (searchInstant == '1' ? 'defaultChecked' : '')}
								// defaultChecked={searchInstant == '1' ? 'defaultChecked' : ''}
								name="search_instant_rsv"
							/> 簡易予約
						</label>
					</div>
					<div className="width-search-label">
						<label>
							<input
								className=""
								type="checkbox"
								value="1"
								onChange={fncSelectFill}
								defaultChecked={global.g_search_fill_rsv == '1' ? 'defaultChecked' : ''}
								name="search_fill_rsv"
							/> 枠埋め予約
						</label>
					</div>
				</Row>

				<Row className="reserve-mng-value">
					<Col md={{ span: 11}}>
						<label>{MSG_INFO_DATE_INPUT}</label>
					</Col>
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-search"
							onClick={fncOnClickSearch}
							disabled={loading == '1'? 'disabled' : ''}
						>
							検 索
						</Button>
					</Col>
				</Row>

				<div>
					<Row className="reserve-mng-row-header">
						<Col md={{ span: 10}}></Col>
						<Col md={{ span: 1}}>
							{csvData.length == 0 ?
								<Button
									className="btn btn-block btn-csv"
									disabled={'disabled'}
								>
									CSV
								</Button>
								:
								loading == '1'?
									<Button
										className="btn btn-block btn-csv"
										disabled={'disabled'}
									>
										CSV
									</Button>
									:
									<CSVLink
										className="btn btn-block btn-csv"
										onClick={fncOnClickCsv}
										filename={csvFileNm}
										data={csvData}
									>
										CSV
									</CSVLink>
							}
						</Col>
						<Col md={{ span: 1}}>
							<Button
								block
								className="btn-new"
								onClick={fncOnClickAddNew}
								disabled={loading == '1'? 'disabled' : ''}
							>
								新 規
							</Button>
						</Col>
					</Row>
				</div>

				<Row className="justify-content-md-center pb-0" >
					<Col md={{ span: 1 }} className="text-left">
						<div className="text-header first-header">▼一覧</div>
					</Col>
					<Col md={{ span: 4, offset:3 }} className="px-4">
						{listData && (<MaterialPagination count={Math.ceil(listData.length / listsPerPage)}
														  color="primary"
														  onChange={handleChange}
														  page={currentPage}
														  className="px-auto mx-4"
						/>)}
					</Col>
					<Col  md={{ span: 2, offset: 2 }} className="pt-3 text-right">
						<p className="mb-0">総件数： {listData.length}件</p>
					</Col>
				</Row>
				<Row className="reserve-mng-value">
					<Col md={{ span: 12}}>
						<label
							className={"reserve-list-msg"}
						>
							【ﾘﾏｲﾝﾄﾞ、ｻﾝｸｽﾒｰﾙ】未：メール未送信 　 済：メール送信済 　 なし：ﾒｰﾙｱﾄﾞﾚｽ未登録 　 －：ﾘﾏｲﾝﾄﾞ・ｻﾝｸｽﾒｰﾙの送信機能未使用
						</label>
					</Col>
				</Row>

				<Row className="reserve-mng-value">
					<Col md={{ span: 12}}>
						<table className={"reserve-mng-list"}>
							<thead className={"reserve-mng-thead"}>
								<tr>
									<th className={"th-reservemng-no"}>No</th>
									<th className={"th-reservemng-res"}>予約番号</th>
									<th className={"th-reservemng-userNo"}>会員番号</th>
									<th className={"th-reservemng-user"}>会員名</th>
									<th className={"th-reservemng-course"}>コース</th>
									<th className={"th-reservemng-date"}>日付</th>
									<th className={"th-reservemng-time"}>時間</th>
									<th className={"th-reservemng-staff"}>スタッフ</th>
									<th className={"th-reservemng-note"}>会員要望<br />店舗メモ</th>
									<th className={"th-reservemng-status"}>状態</th>
									<th className={"th-reservemng-yoyaku"}>予約<br />方法</th>
									<th className={"th-reservemng-send"}>ﾘﾏｲﾝﾄﾞ<br />ｻﾝｸｽ</th>
									<th className={"th-reservemng-upd"}>編集</th>
									<th className={"th-reservemng-del"}>削除</th>
								</tr>
							</thead>
							{listData !== '' && (
								<ReserveMngList listData={currentLists}
									   			loading={loading}
									   			fnconclicknoteshow={fncOnClickNoteShow}
									   			fnconclickmngupd={fncOnClickMngUpd}
									   			fnconclickmngdel={fncOnClickMngDel}
								/>
							)}
						</table>
					</Col>
				</Row>
				<Row className="justify-content-md-center">
					<Col md={{ span: 4 }} className="px-4">
						{listData && (<MaterialPagination count={Math.ceil(listData.length / listsPerPage)}
														  color="primary"
														  onChange={handleChange}
														  page={currentPage}
														  className="px-auto mx-4"
						/>)}
					</Col>
				</Row>
				<div>
					<Row className="reserve-mng-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
			</Form>

			<Modal show={modalShow} onHide={fncOnclickModalClose} >
				<Modal.Header closeButton>
					<Modal.Title>会員要望・店舗メモ</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<span style={{paddingLeft:"15px", marginBottom:"-15px"}}>会員要望</span>
					</Row>
					<Row>
						<Col md={{ span: 12}}>
						<textarea
							className="input-reserve-mng-field"
							rows="4"
							value={note}
							name="note"
							readOnly="readOnly"
						/>
						</Col>
					</Row>
					<Row>
						<span style={{paddingLeft:"15px", marginBottom:"-15px"}}>店舗メモ</span>
					</Row>
					<Row>
						<Col md={{ span: 12}}>
						<textarea
							className="input-reserve-mng-field"
							rows="4"
							value={staffNote}
							name="staff_note"
							readOnly="readOnly"
						/>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button block className="btn-back" onClick={fncOnclickModalClose}>
						戻 る
					</Button>
				</Modal.Footer>
			</Modal>

			<CustomModal
				show={customModalShow}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>
		</>
	);

}

export default ReserveMngListForm;

/**
 * 一覧作成
 * @returns {*}
 */
export const ReserveMngList = (props) => {
	return (
		<tbody className="reserve-mng-tbody">
		{props.listData.map((list, index) => (
			<tr key={index}>
				<td className="td-reservemng-no">
					{Number(index) + 1}
				</td>
				<td className="td-reservemng-res">
					{list.reserve_no != null ? list.reserve_no : ''}
				</td>
				<td className="td-reservemng-userNo">
					{(list.user_id != '9999999998' && list.user_id != '9999999999')? (list.user_no != null? list.user_no : '')
						:
						list.user_id === '9999999998'? '枠埋め'
							:
							list.user_id === '9999999999'? '簡易' : ''}
				</td>
				<td className="td-reservemng-user">
					{(list.user_id != '9999999998' && list.user_id != '9999999999')? (list.user_nm != null? list.user_nm : '')
						:
						((list.reserve_name != null && list.reserve_name != '')? list.reserve_name : list.user_nm)}
				</td>
				<td className="td-reservemng-course">
					{list.course_nm != null? list.course_nm : ''}
				</td>
				<td className="td-reservemng-date">
					{list.reserve_date != null? list.reserve_date : ''}
				</td>
				<td className="td-reservemng-time">
					{(list.course_start_time != null ? list.course_start_time : '')
					+ (list.course_end_time != null ? '~' + list.course_end_time : '')}
				</td>
				<td className="td-reservemng-staff">
					{list.staff_nm != null ? list.staff_nm : ''}
				</td>
				<td className="td-reservemng-note">
					{((list.reserve_note != null && list.reserve_note !== '') || (list.staff_note != null && list.staff_note !== '')) ?
						<Button
							block
							className="btn-list"
							onClick={props.fnconclicknoteshow}
							data-id={index}
							disabled={props.loading === '1' ? 'disabled' : ''}
						>
							あ り
						</Button>
						:
						<></>
					}
					<input type="hidden" id={'hdn-note-' + index}
						   value={list.reserve_note !== null ? list.reserve_note : ''}>
					</input>
					<input type="hidden" id={'hdn-staff-note-' + index}
						   value={list.staff_note !== null ? list.staff_note : ''}>
					</input>
				</td>
				<td className="td-reservemng-status">
					{list.reserve_status === '0' ? '予約中' :
						list.reserve_status === '1' ? '来院' :
							list.reserve_status === '2' ? 'ｷｬﾝｾﾙ' :
								list.reserve_status === '3' ? '未来院' :
									list.reserve_status === '4' ? '遅刻' :''}
				</td>
				<td className="td-reservemng-yoyaku">
					{list.create_id === '' ? 'ﾈｯﾄ' : '院内'}
				</td>
				<td className="td-reservemng-send">
					{list.remind_send_cmp_flg === '0' ? '未'
						:
						list.remind_send_cmp_flg === '1' ? '済'
							:
							list.remind_send_cmp_flg === '2' ? '－' : 'なし'
					}
					<br />
					{list.thanks_send_cmp_flg === '0' ? '未'
						:
						list.thanks_send_cmp_flg === '1' ? '済'
							:
							list.thanks_send_cmp_flg === '2' ? '－' : 'なし'
					}
				</td>
				<td className="td-reservemng-upd">
					<Button
						block
						className="btn-edit"
						onClick={props.fnconclickmngupd}
						data-id={index}
						disabled={props.loading === '1' ? 'disabled' : ''}
					>
						編 集
					</Button>
					<input type="hidden" id={'hdn-reserve-id-' + index}
						   value={list.reserve_id}>
					</input>
					<input type="hidden" id={'hdn-user-id-' + index}
						   value={list.user_id}>
					</input>
				</td>
				<td className="td-reservemng-del">
					<Button
						block
						className="btn-delete"
						onClick={props.fnconclickmngdel}
						data-id={index}
						disabled={props.loading === '1' ? 'disabled' : ''}
					>
						削 除
					</Button>
				</td>
			</tr>
		))}
		</tbody>
	)
}