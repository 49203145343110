import React, {useEffect, useState} from 'react';

import './Holiday.css';
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import HolidayForm from "./HolidayForm/HolidayForm";
import {fncgGetUserLoginCheck, fncgGetDbconn} from "Utils/Common/ComCom";
import {PRM_ID} from "../../Utils/Common/ComConst";
import {useLocation} from "react-router-dom";

function Holiday({ match, history, location }) {
	const [shop, setShop] = useState('');
	const [user, setUser] = useState('');
	const [urlpram, setUrlPram] = useState('');
	const [dbconn, setDbConn] = useState('');
	const [start, setStart] = useState('0');
	const path = useLocation().pathname;
	const handleSetUser = userData => setUser(userData);
	const handleSetUrlPram = dat => setUrlPram(dat);
	const handleSetDbConn = dat => setDbConn(dat);
	const handleSetStart = dat => setStart(dat);

	/**
	 * 初期処理
	 */
	useEffect(() => {
		fncgGetDbconn({history, location, handleSetUrlPram, handleSetDbConn, handleSetStart});
	}, [])

	/**
	 * スタッフ情報取得
	 */
	useEffect(() => {
		if(start == '0') return;
		// ログインチェックを行いユーザ情報を返却する
		fncgGetUserLoginCheck({dbconn, urlpram, path, user, handleSetUser, history})
	}, [start])

	/**
	 * 店舗情報取得
	 */
	useEffect(() => {
		if(start == '0') return;
		let aryData = {
			id : dbconn.id,
		};
		Http.post('api/get-shop', {
			...aryData
		}).then(response => {
			setShop(response.data.Shop);
		});
	}, [start])

	return (
		<>
			{(shop != "" && shop != undefined && user != "" && user != undefined && dbconn.id != '' && dbconn.id != undefined) &&(
				<Layout headerText="祝日管理 -祝日登録-" _dbconn={dbconn} _user={user}>
					<HolidayForm
						history={history}
						user={user}
						shop={shop}
						dbconn={dbconn}
					/>
				</Layout >
			)}
		</>
	);

}

export default Holiday;