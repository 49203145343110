import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../DmMng.css';
import Http from 'Utils/Axios';
import usePost from 'Utils/EndPoints/usePost';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData';
import CustomModal from 'Utils/Modal/CustomModal';
import { FormInput } from 'Utils/FormHandlers/HandleFormProperties';
import {Form, FormControl, Button, Col, Row, Modal} from 'react-bootstrap';
import {
	months,
	MSG_CMP_SEND_MAIL,
	MSG_NG_SEND_MAIL, MSG_NOT_DATA, years
} from "../../../../Utils/Common/ComConst";
import DatePicker from "react-datepicker";
import { IoIosCalendar } from "react-icons/io";
import {CSVLink} from "react-csv";
import InputMask from 'react-input-mask';

const defaultState = {
	mail_id : "",
	mail_kind : "0",
	mail_subject : "",
	mail_body : "",
	use_stop_flg : "0",
	update_id : "",
	mode : "",
	id : '',
};

const max_list = 10;
const dm_max_list = 3;
const item_max_list = 9;

const dtNow = new Date();

function DmMngForm({ history, user, data, dbconn, submitReturn }) {
	const [fieldData, handleDataFormat] = useHandleFormData({ ...defaultState});
	const [mailuser, setMailUser] = useState('');
	const [dmmail, setDmMail] = useState('');
	const [item, setItem] = useState('');
	const [subject, setSubject] = useState('');
	const [mailbody, setMailBody] = useState('');

	const [userlistflg, setUserListFlg] = useState('0');
	const [userlistindex, setUserListIndex] = useState(0);
	const [dmlistflg, setDmListFlg] = useState('0');
	const [dmlistindex, setDmListIndex] = useState(0);
	const [textflg, setTextFlg] = useState('0');
	const [itemlistflg, setItemListFlg] = useState('0');
	const [itemlistindex, setItemListIndex] = useState(0);

	const [result, submitPost, errors] = usePost();
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [loading, setLoading] = useState('0');

	const [historyData, setHistoryData] =  useState('');
	const [csvData, setCsvData] =  useState('');
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [csvFileNm, setCsvFileNm] = useState('');
	const [showCsvModal, setShowCsvModal] = useState(false);
	const [showModalDmHistory, setShowModalDmHistory] = useState(false);
	const [mailId,setMailId] = useState('');
	const [checkedFlg, setCheckedFlg] = useState(true);
	const [waiting, setWaiting] = useState('0');
	const [dateErrorMsg, setDateErrorMsg] = useState('');

	/**
	 * データ取得
	 */
	useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
		setLoading('1');
		let aryData = {
			id : dbconn.id,
		};
		Http.post('api/init-dm', {
			...aryData
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setItem(response.data.Item);
				setMailUser(response.data.User);
				setDmMail(response.data.DmMail);
				if (Object.keys(response.data.DmMail).length > 0) {
					setSubject(response.data.DmMail[0].mail_subject);
					setMailBody(response.data.DmMail[0].mail_body);
					fieldData.mail_subject = response.data.DmMail[0].mail_subject;
					fieldData.mail_body = response.data.DmMail[0].mail_body;
					if(Object.keys(response.data.DmMail).length <= dm_max_list){
						setDmListFlg('2');
					}
				}
				if (Object.keys(response.data.User).length > 0) {
					if(Object.keys(response.data.User).length <= max_list){
						setUserListFlg('2');
					}
				}
				if (Object.keys(response.data.Item).length > 0) {
					if(Object.keys(response.data.Item).length <= item_max_list){
						setItemListFlg('2');
					}
				}
			}
			setLoading('0');
			setWaiting('0');
		});

		var strDate = dtNow.getFullYear() + '-' + (dtNow.getMonth() + 1) + '-' + dtNow.getDate();
		setStartDate(new Date(strDate));
		setEndDate(new Date(strDate));

	}, [])

	/**
	 * 会員一覧作成
	 * @returns {*}
	 */
	function fncSetMailUserList(){
		var lstUser = [];

		for(var intIdx in mailuser){
			//if(intIdx < userlistindex) continue;
			//if(intIdx >= (userlistindex + max_list)) break;

			lstUser.push(
				<tr key={intIdx}>
					<td className="td-dm-mng-user-no" >
						{Number(intIdx) + 1}
					</td>
					<td className="td-dm-mng-user-sel" >
						<input
							className="chk-dm-mng-user"
							type="checkbox"
							value="1"
							defaultChecked={mailuser[intIdx].check_flg == 1? 'defaultChecked' : ''}
							onChange={fncCheckUser}
							data-id={intIdx}
							id={'chk-user-' + intIdx}
						/>
						<input type="hidden" id={'hdn-user-id-' + intIdx} value={mailuser[intIdx].user_id}></input>
					</td>
					<td className="td-dm-mng-user-nm" >
						{mailuser[intIdx].user_nm != null? mailuser[intIdx].user_nm : ''}
					</td>
					<td className="td-dm-mng-user-kn" >
						{mailuser[intIdx].user_kn != null? mailuser[intIdx].user_kn : ''}
					</td>
					<td className="td-dm-mng-user-mail" >
						{mailuser[intIdx].email != null? mailuser[intIdx].email : ''}
					</td>
					<td className="td-dm-mng-user-send_kbn" >
						{mailuser[intIdx].send_kbn == '2'? 'LINE' : 'メール'}
					</td>
					<td className="td-dm-mng-user-info" >
						{mailuser[intIdx].info_mailflg == 0? '希望しない' : '希望する'}
					</td>
				</tr>
			);
		}

		if(lstUser.length > 0){
			return(
				<tbody className="tbody-user-mng">
					{lstUser}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	/**
	 * 会員選択
	 * @param e
	 */
	function fncCheckUser(e){
		const intID = e.currentTarget.getAttribute('data-id');
		if(mailuser[intID].check_flg == '0' ){
			mailuser[intID].check_flg = '1';
		}else{
			mailuser[intID].check_flg = '0';
		}
	}

	/**
	 * 全選択
	 */
	function fncOnClickAllSel(){
		if(mailuser == undefined) return;
		if(mailuser.length > 0){
			for(var intIdx = 0; intIdx < mailuser.length; intIdx++){
				mailuser[intIdx].check_flg = '1';
				//if(userlistindex <= intIdx &&  userlistindex + 10 > intIdx){
					document.getElementById('chk-user-' + intIdx).checked = true;
				//}
			}
		}
	}

	/**
	 * 全解除
	 */
	function fncOnClickAllKaijo(){
		if(mailuser == undefined) return;
		if(mailuser.length > 0){
			for(var intIdx = 0; intIdx < mailuser.length; intIdx++){
				mailuser[intIdx].check_flg = '0';
				//if(userlistindex <= intIdx &&  userlistindex + 10 > intIdx){
					document.getElementById('chk-user-' + intIdx).checked = false;
				//}
			}
		}
	}

	/**
	 * 会員一覧前へ
	 * @param e
	 */
	function fncOnClickUserBef(e){
		var intIdx = userlistindex - max_list;
		//--- 表示位置判定
		if(intIdx < 0){
			intIdx = 0;
		}
		//--- フラグ判定
		if(intIdx == 0){
			//-- 前ボタン使用不可
			setUserListFlg('0');
		}else{
			setUserListFlg('3');
		}
		setUserListIndex(intIdx);
	}

	/**
	 * 会員一覧次へ
	 * @param e
	 */
	function fncOnClickUserNext(e){
		var intIdx = userlistindex + max_list;
		//--- 表示位置判定
		if(mailuser != '' && mailuser != undefined){
			if(intIdx > mailuser.length - 1){
				intIdx = mailuser.length - 1;
			}
		}else{
			intIdx = 0;
		}
		//--- フラグ判定
		if(intIdx + max_list >= mailuser.length - 1){
			setUserListFlg('1');
		}else{
			setUserListFlg('3');
		}
		setUserListIndex(intIdx);
	}

	/**
	 * DM一覧作成
	 * @returns {*}
	 */
	function fncSetDmMailList(){
		var lstDmMail = [];

		for(var intIdx in dmmail){
			//if(intIdx < dmlistindex) continue;
			//if(intIdx >= (dmlistindex + dm_max_list)) break;
			lstDmMail.push(
				<tr key={intIdx}>
					<td className="td-dm-mng-no" >
						{Number(intIdx) + 1}
					</td>
					<td className="td-dm-mng-sel" >
						<label>
							<input
								className={"chk-dm-mng"}
								type={"radio"}
								name="rdo-dmmail"
								id={'rdo-dmmail-' + intIdx}
								onClick={fncOnClickDmMail}
								data-id={intIdx}
								defaultChecked={intIdx == 0? true : false}
							/>
						</label>
						<input type="hidden" id={'hdn-mail-subject-' + intIdx} value={dmmail[intIdx].mail_subject}></input>
						<input type="hidden" id={'hdn-mail-body-' + intIdx} value={dmmail[intIdx].mail_body}></input>
						<input type="hidden" id={'hdn-mail-id-' + intIdx} value={dmmail[intIdx].mail_id}></input>
					</td>
					<td className="td-dm-mng-subject" >
						{dmmail[intIdx].mail_subject != null? dmmail[intIdx].mail_subject : ''}
					</td>
				</tr>
			);
		}

		if(lstDmMail.length > 0){
			return(
				<tbody className={"tbody-dm-mng"}>
					{lstDmMail}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	/**
	 * DM選択
	 */
	function fncOnClickDmMail(e){
		const intID = e.currentTarget.getAttribute('data-id');
		var strMailSubject = document.getElementById('hdn-mail-subject-' + intID).value;
		var strMailBody = document.getElementById('hdn-mail-body-' + intID).value;
		var strMailId = document.getElementById('hdn-mail-id-' + intID).value;
		fieldData.mail_subject = strMailSubject;
		fieldData.mail_body = strMailBody;
		setSubject(strMailSubject);
		setMailBody(strMailBody);
		setMailId(strMailId);
	}

	/**
	 * DM一覧前へ
	 * @param e
	 */
	function fncOnClickDmBef(e){
		var intIdx = dmlistindex - dm_max_list;
		//--- 表示位置判定
		if(intIdx < 0){
			intIdx = 0;
		}
		//--- フラグ判定
		if(intIdx == 0){
			//-- 前ボタン使用不可
			setDmListFlg('0');
		}else{
			setDmListFlg('3');
		}
		setDmListIndex(intIdx);
	}

	/**
	 * DM一覧次へ
	 * @param e
	 */
	function fncOnClickDmNext(e){
		var intIdx = dmlistindex + dm_max_list;
		//--- 表示位置判定
		if(dmmail != '' && dmmail != undefined){
			if(intIdx > dmmail.length - 1){
				intIdx = dmmail.length - 1;
			}
		}else{
			intIdx = 0;
		}
		//--- フラグ判定
		if(intIdx + dm_max_list >= dmmail.length - 1){
			setDmListFlg('1');
		}else{
			setDmListFlg('3');
		}
		setDmListIndex(intIdx);
	}

	/**
	 * 項目一覧作成
	 * @returns {*}
	 */
	function fncGetItemList(){
		var lstItem = [];

		for(var intIdx in item){
			//if(intIdx < itemlistindex) continue;
			//if(intIdx >= (itemlistindex + item_max_list)) break;
			lstItem.push(
				<tr key={intIdx}>
					<td className="td-item-no" >
						{Number(intIdx) + 1}
					</td>
					<td className="td-item-sel" >
						<Button
							block
							className="btn_item_sel"
							onClick={fncOnClickSelItem}
							data-id={intIdx}
							disabled={(loading == '1' || waiting == '1')? 'disabled' : ''}
						>
							選 択
						</Button>
						<input type="hidden" id={'hdn-mail-mark-' + intIdx} value={item[intIdx].com_val2}></input>
					</td>
					<td className="td-item-nm" >
						{item[intIdx].com_val1 != null? item[intIdx].com_val1 : ''}
					</td>
					<td className="td-item-mark" >
						{item[intIdx].com_val2 != null? item[intIdx].com_val2 : ''}
					</td>
				</tr>
			);
		}

		if(lstItem.length > 0){
			return(
				<tbody className={"tbody-tmp-mng"}>
					{lstItem}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	/**
	 * 件名クリック
	 * @param e
	 */
	function fncOnClickSubject(e){
		setTextFlg('1');
	}

	/**
	 * 本文クリック
	 * @param e
	 */
	function fncOnClickBody(e){
		setTextFlg('2');
	}

	/**
	 * 項目選択ボタン
	 */
	function fncOnClickSelItem(e){
		const intID = e.currentTarget.getAttribute('data-id');
		var strMailMark = document.getElementById('hdn-mail-mark-' + intID).value;
		var objTextArea = document.getElementById('mail_body');
		var objText = document.getElementById('mail_subject');

		if(textflg == '1'){
			insertTextAtCursor(objText, strMailMark, textflg);
		}else if(textflg == '2'){
			insertTextAtCursor(objTextArea, strMailMark, textflg);
		}

	}

	/**
	 * 項目挿入
	 * @param el
	 * @param text
	 */
	function insertTextAtCursor(el, text, flg) {
		var val = el.value, endIndex, range, doc = el.ownerDocument;
		if (typeof el.selectionStart == "number"
			&& typeof el.selectionEnd == "number") {
			endIndex = el.selectionEnd;
			el.value = val.slice(0, endIndex) + text + val.slice(endIndex);
			el.selectionStart = el.selectionEnd = endIndex + text.length;
			if(flg == '1'){
				fieldData.mail_subject = el.value;
			}else if(flg == '2'){
				fieldData.mail_body = el.value;
			}
		} else if (doc.selection != "undefined" && doc.selection.createRange) {
			el.focus();
			range = doc.selection.createRange();
			range.collapse(false);
			range.text = text;
			range.select();
		}
	}

	/**
	 * 項目一覧前へ
	 * @param e
	 */
	function fncOnClickItemBef(e){
		var intIdx = itemlistindex - item_max_list;
		//--- 表示位置判定
		if(intIdx < 0){
			intIdx = 0;
		}
		//--- フラグ判定
		if(intIdx == 0){
			//-- 前ボタン使用不可
			setItemListFlg('0');
		}else{
			setItemListFlg('3');
		}
		setItemListIndex(intIdx);
	}

	/**
	 * 項目一覧次へ
	 * @param e
	 */
	function fncOnClickItemNext(e){
		var intIdx = itemlistindex + item_max_list;
		//--- 表示位置判定
		if(item != '' && item != undefined){
			if(intIdx > item.length - 1){
				intIdx = item.length - 1;
			}
		}else{
			intIdx = 0;
		}
		//--- フラグ判定
		if(intIdx + item_max_list >= item.length - 1){
			setItemListFlg('1');
		}else{
			setItemListFlg('3');
		}
		setItemListIndex(intIdx);
	}

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		submitReturn(dbconn);
	}

	/**
	 *
	 * 送信ボタン
	 */
	function fncOnClickUpdate(e){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		e.preventDefault();
		fieldData.id = dbconn.id;
		//--- チェック
		submitPost('api/chk-dm',{
			...fieldData,
		});
	}

    /**
     * チェック結果エラー
     */
    useEffect(() => {
    	if(errors == undefined) return;
        if (Object.keys(errors).length > 0) {
			window.scrollTo(0, 0);
			setLoading('0');
			setWaiting('0');
        }

    }, [errors])

    /**
     * チェック結果正常
     */
    useEffect(() => {
		setWaiting('1');
		if(dbconn.id == '' || dbconn.id == undefined) return;
        if (Object.keys(result).length > 0) {
        	//--- 送信
			//-- データ格納
			var aryData = {
				mailuser : [],
				mailinfo : [],
				id : dbconn.id,
				mailId : mailId,
				staffId : user.staff_id,
			}
			aryData.mailuser.push(mailuser);
			aryData.mailinfo.push(fieldData);

            //-- POST
            Http.post('/api/send-dm', {
                ...aryData,
            }).then(response => {
                const strStatus = response.data.status;
                if(strStatus == '200'){
					setModalMsg(MSG_CMP_SEND_MAIL);
                }else{
                    setModalMsg(MSG_NG_SEND_MAIL);
                }
                setShow(true);
            }).catch(error => {
                if (error.response) {
                    alert(error.response.data)
                }
            }).finally(() => {
				setLoading('0');
				setWaiting('0');
            })
        }

    }, [result]);

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
        setShow(false);
	}

	/**
	 * DM送信履歴子画面モーダル開く
	 */
	function fncOnClickDmHistory() {
		var strDate = dtNow.getFullYear() + '-' + (dtNow.getMonth() + 1) + '-' + dtNow.getDate();
		setStartDate(new Date(strDate));
		setEndDate(new Date(strDate));
		fncOnClickSearch();
		setShowModalDmHistory(true);
	}

	/**
	 * DM送信履歴子画面モーダル閉じる
	 */
	function fncOnClickDmHistoryClose() {
		setShowModalDmHistory(false);
		setCheckedFlg(true);
		setDateErrorMsg('');
		var strDate = dtNow.getFullYear() + '-' + (dtNow.getMonth() + 1) + '-' + dtNow.getDate();
		setStartDate(new Date(strDate));
		setEndDate(new Date(strDate));
	}

	/**
	 * CSV出力確認画面モーダル開く
	 */
	function fncOnClickCsvInfoBtn() {
		setShowCsvModal(true);
	}

	/**
	 * CSV出力確認画面モーダル閉じる
	 */
	function fncOnClickCsvInfoBtnClose() {
		setShowCsvModal(false);
		//setCheckedFlg(true);
		fncOnClickDmHistoryClose();
	}

	/**
	 * 検索ボタン
	 */
	function fncOnClickSearch() {

		setLoading('1');

		if ((startDate == null || startDate == undefined || startDate == '')
			&& (endDate == null || endDate == undefined || endDate == '')) {
			var strDate = dtNow.getFullYear() + '-' + (dtNow.getMonth() + 1) + '-' + dtNow.getDate();
			setStartDate(new Date(strDate));
			setEndDate(new Date(strDate));
		}

		// 初期化
		setHistoryData('');
		setCheckedFlg(true);

		var aryData = {
			id : dbconn.id,
			send_start_date : '',
			send_end_date : ''
		};

		//日付論理チェック
		if (startDate != null && endDate != null) {
			if (startDate.getTime() > endDate.getTime()) {
				setDateErrorMsg('送信日を正しく入力してください。');
				setLoading('0');
				return;
			}
		}

		//--- 日付の設定
		if(startDate != '' && startDate != null){
			aryData.send_start_date = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
		}
		if(endDate != '' && endDate != null){
			aryData.send_end_date = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
		}

		Http.post('api/search-dm-history', {
			...aryData
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				var list = response.data.list;
				setHistoryData(list);
				setDateErrorMsg('');
			}else{
				setHistoryData('');
			}
			setLoading('0');
		});
	}

	/**
	 * CSV取得ボタン
	 */
	function fncGetCSVInfo() {
		setLoading('1');

		// CSVデータクリア
		setCsvData('');

		// チェックされた履歴ID取得
		var aryCheckedHistory = [];
		var tmpcheckedFlg = false;
		for (var intIdx = 0; intIdx < historyData.length; intIdx++) {
			if (historyData[intIdx].check_flg == '1') {
				var aryHst = {
					history_id: '',
					mail_subject: '',
					created_at: ''
				};
				tmpcheckedFlg = true;
				aryHst.history_id = historyData[intIdx].history_id;
				aryHst.mail_subject = historyData[intIdx].mail_subject;
				aryHst.created_at = historyData[intIdx].created_at;
				aryCheckedHistory.push(aryHst);
			}
		}
		//1件もチェックされていない場合エラーを返却
		setCheckedFlg(tmpcheckedFlg);
		setLoading('0');
		if (!tmpcheckedFlg) return;

		var aryData = {
			id : dbconn.id,
			checked_list : aryCheckedHistory
		};

		Http.post('api/get-dm-csvData', {
			...aryData
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setCsvData(response.data.csv);
			}else{
				setCsvData('');
			}

		});

		// CSV確認画面開く
		setShowCsvModal(true);

	}

	/**
	 * CSVボタン
	 */
	function fncOnClickCSV() {
		//--- ファイル名作成
		//--- ファイル名作成
		let dtNowCsv = new Date();
		let year = dtNowCsv.getFullYear().toString();
		let month = (dtNowCsv.getMonth() + 1).toString();
		let day = dtNowCsv.getDate().toString();
		//-- 0埋め
		month = ('0' + (month)).slice(-2);
		day = ('0' + (day)).slice(-2);
		//-- ファイル日時
		let fileTime = year + month + day;
		setCsvFileNm('DM送信履歴_' + fileTime + '.csv');

		setCheckedFlg(true);
		setShowCsvModal(false);
		fncOnClickDmHistoryClose();
	}

	/**
	 * 開始日にカーソルを設定
	 */
	function fncClickSetStartDate(){
		document.getElementById('dtStartDate').focus();
	}

	/**
	 * 終了日にカーソルを設定
	 */
	function fncClickSetEndDate(){
		document.getElementById('dtEndDate').focus();
	}

	/**
	 * 履歴選択
	 * @param e
	 */
	function fncCheckDmHistory(e){
		const intID = e.currentTarget.getAttribute('data-id');
		if(historyData[intID].check_flg == '0' ){
			historyData[intID].check_flg = '1';
		}else{
			historyData[intID].check_flg = '0';
		}
	}

	/**
	 * 履歴一覧作成
	 * @returns {*}
	 */
	function fncSetHistoryList() {
		var lstHistory = [];

		for(var intIdx in historyData){

			historyData[intIdx].check_flg = '0';

			lstHistory.push(
				<tr key={intIdx}>
					<td className="td-dm-mng-hst-sel" >
						<input
							className="chk-dm-hst"
							type="checkbox"
							value={historyData[intIdx].history_id}
							defaultChecked={historyData[intIdx].check_flg == 1? 'defaultChecked' : ''}
							onChange={fncCheckDmHistory}
							data-id={intIdx}
							id={'chk-user-' + intIdx}
						/>
						<input type="hidden" id={'hdn-user-id-' + intIdx} value={historyData[intIdx].history_id}></input>
					</td>
					<td className="td-dm-mng-hst-no" >
						{Number(intIdx) + 1}
					</td>

					<td className="td-dm-mng-hst-date" >
						{historyData[intIdx].created_at != null? historyData[intIdx].created_at : ''}
					</td>
					<td className="td-dm-mng-hst-mailsbj" >
						{historyData[intIdx].mail_subject != null? historyData[intIdx].mail_subject : ''}
					</td>
					<td className="td-dm-mng-hst-target_no" >
						{historyData[intIdx].target_no != null? historyData[intIdx].target_no + '人' : ''}
					</td>
				</tr>
			);
		}

		if(lstHistory.length > 0){
			return(
				<tbody className="tbody-hst-mng">
				{lstHistory}
				</tbody>
			);
		}else{
			return(
				<tbody className="tbody-hst-mng">
				<td style={{textAlign:"center", background:"#E5EDE0", height:"40px", width:"670px", border:"3px solid white"}}>
					{loading == '1' ? '履歴取得中...' : '送信履歴は0件です。'}
				</td>
				</tbody>
			);
		}

	}

	return (
		<>
			<Form className="dm-mng-form" >
				<div>
					<Row className="dm-mng-form-row">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={(loading == '1' || waiting == '1')? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<Row className="dm-mng-form-row">
					<div className="col-md-mail-8">
						<label className={"text-header"}>▼会員一覧</label>
					</div>
					<Col md={{ span: 2}}></Col>
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-all-sel"
							onClick={fncOnClickAllSel}
							disabled={(loading == '1' || waiting == '1')? 'disabled' : ''}
						>
							全選択
						</Button>
					</Col>
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-all-rel"
							onClick={fncOnClickAllKaijo}
							disabled={(loading == '1' || waiting == '1')? 'disabled' : ''}
						>
							全解除
						</Button>
					</Col>
				</Row>

				<Row className="dm-mng-value">
					<Col md={{ span: 12}}>
						<table className={"dm-mng-user-list"}>
							<thead className={"thead-user-mng"}>
								<tr>
									<th className={"th-dm-mng-user-no"}>No</th>
									<th className={"th-dm-mng-user-sel"}>選択</th>
									<th className={"th-dm-mng-user-nm"}>名前</th>
									<th className={"th-dm-mng-user-kn"}>フリガナ</th>
									<th className={"th-dm-mng-user-mail"}>メールアドレス</th>
									<th className={"th-dm-mng-user-send_kbn"}>配信先</th>
									<th className={"th-dm-mng-user-info"}>お知らせ</th>

								</tr>
							</thead>
							{fncSetMailUserList()}
						</table>
					</Col>
				</Row>
				<Row className="dm-mng-form-row">
					<Col md={{ span: 10}}>
						<label className={"text-header"}>▼DMメール一覧</label>
					</Col>
				</Row>
				<Row className="dm-mng-value">
					<Col md={{ span: 12}}>
						<table className={"dm-mng-list"}>
							<thead className={"thead-dm-mng"}>
								<tr>
									<th className={"th-dm-mng-no"}>No</th>
									<th className={"th-dm-mng-sel"}>選択</th>
									<th className={"th-dm-mng-subject"}>件名</th>
								</tr>
							</thead>
							{fncSetDmMailList()}
						</table>
					</Col>
				</Row>

				<Row className="dm-mng-form-row">
					<div className="width-mail-label padding-left-15">
						<label>件名<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<Col md={{ span: 6}}>
						<FormControl
							className="input-dm-mng-field"
							type="text"
							value={fieldData.mail_subject || subject}
							name="mail_subject"
							onChange={handleDataFormat}
							onClick={fncOnClickSubject}
							maxLength="50"
							id="mail_subject"
							{...FormInput}
						/>
					</Col>
					<div className="col-md-mail-3">
						<label className={"text-header"}>　▼置換項目</label>
					</div>
				</Row>
				{(errors.mail_subject) && (
					<Row className="dm-mng-error-row">
						<Col md={{ span: 1}}></Col>
						<Col md={{ span: 4}}>
							<span className="error-span">{errors.mail_subject[0]}</span>
						</Col>
					</Row>
				) }

				<Row className="dm-mng-body-row">
					<div className="width-mail-label padding-left-15">
						<label>本文<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<Col md={{ span: 6}}>
						<textarea
							className="input-dm-mng-field"
							rows="20"
							value={fieldData.mail_body || mailbody}
							onChange={handleDataFormat}
							onClick={fncOnClickBody}
							name="mail_body"
							maxLength="1000"
							id="mail_body"
						/>
					</Col>
					<div className="col-md-mail-5">
						<table className={"mail-tmp-mng-item"}>
							<thead className={"thead-tmp-mng"}>
								<tr>
									<th className={"th-item-no"}>No</th>
									<th className={"th-item-sel"}>選択</th>
									<th className={"th-item-nm"}>項目名</th>
									<th className={"th-item-mark"}>記号</th>
								</tr>
							</thead>
							{fncGetItemList()}
						</table>
					</div>
				</Row>
				{(errors.mail_body) && (
					<Row className="dm-mng-error-row">
						<Col md={{ span: 1}}></Col>
						<Col md={{ span: 4}}>
							<span className="error-span">{errors.mail_body[0]}</span>
						</Col>
					</Row>
				) }
				<Row className="dm-mng-button-container">
					<Col md={{ span: 2　}}>
						<Button
							block
							className="menu-button-reserve"
							onClick={fncOnClickDmHistory}
							disabled={(loading == '1' || waiting == '1')? 'disabled' : ''}
						>
							DM送信履歴
						</Button>
					</Col>
					<Col md={{ span: 2, offset: 7}}>
						<Button
							block
							className="btn-nomal"
							onClick={fncOnClickUpdate}
							disabled={(loading == '1' || waiting == '1')? 'disabled' : ''}
						>
							{waiting == '1' ? '送信中...' : '送 信'}

						</Button>
					</Col>
					<Col md={{ span: 1 }}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={(loading == '1' || waiting == '1')? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
				</Row>

				<input type="text" className="dmy-text" name="dummy" />
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>

			<Modal show={showModalDmHistory} onHide={fncOnClickDmHistoryClose}>
				<Modal.Header closeButton>
					<Modal.Title>DM送信履歴</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<div className="width-search-label">
							<label className="label-reserve-mng-title">送信日</label>
						</div>
						<div className="padding-left-15">
							<label className={"label-reserve-mng-padding"}>From</label>
						</div>
						<div className={"div-date-picker-width"}>
							<DatePicker
								className="date-picker"
								showPopperArrow={false}
								selected={startDate}
								onChange={date => setStartDate(date)}
								dateFormat="yyyy/MM/dd"
								customInput={<InputMask mask="9999/99/99" maskChar={null} />}
								id={"dtStartDate"}
								renderCustomHeader={({
														 date,
														 changeYear,
														 changeMonth,
													 }) =>(
									<div
										className={"div-date-picker"}
									>
										<select
											className={"select-date-picker"}
											value={date.getFullYear()}
											onChange={({target:{value}}) => changeYear(value)}
										>
											{years.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
										<select
											className={"select-date-picker"}
											value={months[date.getMonth()]}
											onChange={({ target: { value } }) =>
												changeMonth(months.indexOf(value))
											}
										>
											{months.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
									</div>
								)}
							/>
						</div>
						<div className={"div-calendar-icon"}>
							<a onClick={fncClickSetStartDate}>
								<IoIosCalendar/>
							</a>
						</div>
						<div className="width-30 padding-left-15 text-center">
							<label className={"label-reserve-mng-padding"}>～</label>
						</div>
						<div className="padding-left-15">
							<label className={"label-reserve-mng-padding"}>To</label>
						</div>
						<div className={"div-date-picker-width"}>
							<DatePicker
								className="date-picker"
								showPopperArrow={false}
								selected={endDate}
								onChange={date => setEndDate(date)}
								dateFormat="yyyy/MM/dd"
								customInput={<InputMask mask="9999/99/99" maskChar={null} />}
								id={"dtEndDate"}
								renderCustomHeader={({
														 date,
														 changeYear,
														 changeMonth,
													 }) =>(
									<div
										className={"div-date-picker"}
									>
										<select
											className={"select-date-picker"}
											value={date.getFullYear()}
											onChange={({target:{value}}) => changeYear(value)}
										>
											{years.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
										<select
											className={"select-date-picker"}
											value={months[date.getMonth()]}
											onChange={({ target: { value } }) =>
												changeMonth(months.indexOf(value))
											}
										>
											{months.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
									</div>
								)}
							/>
						</div>
						<div className={"div-calendar-icon"}>
							<a onClick={fncClickSetEndDate}>
								<IoIosCalendar/>
							</a>
						</div>
						<Col>
							<Button
								block
								className="btn-search"
								onClick={fncOnClickSearch}
								disabled={loading == '1'? 'disabled' : ''}
							>
								検 索
							</Button>
						</Col>
					</Row>
					<Row>
						{dateErrorMsg != '' &&
						<span style={{color:"#ff0000", paddingLeft:"18px"}}>
							{dateErrorMsg}
						</span>
						}
					</Row>
					<Row>
						<Col>
							<table className={"dm-mng-hst-list"}>
								<thead className={"thead-hst-mng"}>
								<tr>
									<th className={"th-dm-mng-hst-sel"}>選択</th>
									<th className={"th-dm-mng-hst-no"}>no</th>
									<th className={"th-dm-mng-hst-date"}>送信日時</th>
									<th className={"th-dm-mng-hst-mailsbj"}>件名</th>
									<th className={"th-dm-mng-hst-target_no"}>送信対象人数</th>
								</tr>
								</thead>
								{fncSetHistoryList()}
							</table>
						</Col>
					</Row>
					<Row className="user-mng-row-header">
						<Col md={{ span: 9 }}>
							{!checkedFlg &&
							<span style={{color:"#ff0000"}}>履歴を選択してください</span>
							}
						</Col>
						<Col md={{ span: 3 }}>
							{historyData.length == 0 ?
								<Button
									className="btn btn-block btn-csv"
									disabled={'disabled'}
								>
									CSV情報取得
								</Button>
								:
								loading == '1'?
									<Button
										className="btn btn-block btn-csv"
										disabled={'disabled'}
									>
										CSV情報取得
									</Button>
									:
									<Button
										className="btn btn-block btn-csv"
										onClick={fncGetCSVInfo}
										disabled={loading == '1'? 'disabled' : ''}
									>
										CSV情報取得
									</Button>
							}
						</Col>
					</Row>
				</Modal.Body>
			</Modal>

			<Modal show={showCsvModal} onHide={fncOnClickCsvInfoBtnClose}>
				<Modal.Header closeButton>
					<Modal.Title>CSV出力</Modal.Title>
				</Modal.Header>
				<Modal.Body className="mx-auto" style={{width: '90%'}}>
					選択した履歴のCSVを出力します。<br/>
					よろしいですか？
				</Modal.Body>
				<Modal.Footer>
					<Row style={{width: '100%'}}>
						<Col style={{width: '40%'}}>
							<Button block className="btn-nomal" onClick={fncOnClickCsvInfoBtnClose}>
								戻る
							</Button>
						</Col>
						<Col style={{width: '40%'}}>
							{csvData.length == 0 ?
								<Button
									className="btn btn-block btn-csv"
									disabled={'disabled'}
								>
									CSVを出力する
								</Button>
								:
								loading == '1'?
									<Button
										className="btn btn-block btn-csv"
										disabled={'disabled'}
									>
										CSVを出力する
									</Button>
									:
									<CSVLink
										className="btn btn-block btn-csv"
										onClick={fncOnClickCSV}
										filename={csvFileNm}
										data={csvData}
									>
										CSVを出力する
									</CSVLink>
							}
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>

		</>
	);

}

export default DmMngForm;
