import React from 'react';
import { Route,  Redirect, BrowserRouter as Router, Switch } from 'react-router-dom'
//import { Route,  Redirect, HashRouter as Router, Switch } from 'react-router-dom'
//import { Route,  Redirect, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import Login from './Screens/Login/Login'
import Menu from './Screens/Menu/Menu'
import ShopMng from './Screens/Shop/ShopMng/ShopMng'
import ShopTime from './Screens/Shop/ShopTime/ShopTime'
import ShopSale from './Screens/Shop/ShopSale/ShopSale'
import StaffMng from "./Screens/Staff/StaffMng/StaffMng";
import StaffShift from "./Screens/Staff/StaffShift/StaffShift";
import UserMng from "./Screens/User/UserMng/UserMng";
import ToolMng from "./Screens/Tool/ToolMng/ToolMng";
import CourseMng from "./Screens/Course/CourseMng/Course";
import ReserveMng from "./Screens/Reserve/ReserveMng/ReserveMng";
import ReserveSts from "./Screens/Reserve/ReserveSts/ReserveSts";
import ReserveLack from "./Screens/Reserve/ReserveSts/ReserveLack";
import MailTmpMng from "./Screens/Mail/MailTmpMng/MailTmpMng";
import DmMng from "./Screens/Mail/DmMng/DmMng";
import Holiday from './Screens/Holiday/Holiday';
import NotFound from './Screens/NotFound/NotFound';
import DashBoard from './Screens/DashBoard/DashBoard';
//import Course from './Screens/Course/CourseMng/Course';

const history = createBrowserHistory();

function App() {
	/*
	function PrivateRoute({ component: Component, ...rest }) {
		return (
			<Route
				{...rest}
				render={props => {
					const accessToken = localStorage.getItem('access_token');
					if (accessToken) {
						return <Component {...props} />
					}
					return <Redirect
						to={{
							pathname: '/',
							state: { from: props.location },
						}}
					/>
				}}
			/>
		);
	}
	*/
	return (
		<Router history={history}>
			<Switch>
				<Route exact path="/" component={Login} />
				<Route exact path="/menu" component={Menu} />
				<Route exact path="/shop" component={ShopMng} />
				<Route exact path="/shoptime" component={ShopTime} />
				<Route exact path="/shopsale" component={ShopSale} />
				<Route exact path="/staff" component={StaffMng} />
                <Route exact path="/user" component={UserMng} />
				<Route exact path="/tool" component={ToolMng} />
				<Route exact path="/course" component={CourseMng}/>
				<Route exact path="/reserve" component={ReserveMng}/>
				<Route exact path="/reservests" component={ReserveSts}/>
				<Route exact path="/reservelack" component={ReserveLack}/>
				<Route exact path="/mailtmp" component={MailTmpMng}/>
				<Route exact path="/dm" component={DmMng}/>
				<Route exact path="/holiday" component={Holiday}/>
				<Route exact path="/dashboard" component={DashBoard} />
				<Route exact path="/not-found" component={NotFound} />	
				<Route component={NotFound} />
			</Switch>
		</Router>
	)
}

export default App;
