import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../ShopTime.css';
import Http from '../../../../Utils/Axios';
import useHandleFormData from '../../../../Utils/FormHandlers/useHandleFormData';
import CustomModal from '../../../../Utils/Modal/CustomModal';
import { Form, FormControl, Button, Col, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import '../../../../Utils/Common/react-datepicker.css';
import { IoIosCalendar, IoMdTime } from "react-icons/io";
import InputMask from 'react-input-mask';

import {
	MSG_CMP_ADD,
	MSG_CMP_UPD,
	MSG_CMP_DEL,
	MSG_NG_ADD,
	MSG_NG_UPD,
	MSG_NG_DEL,
	MSG_NG_TIME, MSG_NG_DUP_DATE, MSG_NG_DEL_RESERVE, MSG_UPD_SHOP_SALE, MSG_INFO_DATE_INPUT,
	years, shopyears, months, MSG_NG_SHOP_WEEK_INPUT, MSG_SYSTEM_UPD_BACK, MSG_SYSTEM_BEFORE_UNLOAD
} from "../../../../Utils/Common/ComConst";

import WeekSet from "./ShopTimeAddFromWeekSet";
import TimePicker from "../../../../Utils/FormHandlers/TimePicker";

const defaultState = {
	shop_id : "",
	shop_nm : "",
	start_date : "",
	end_date : "",
	open_time1 : "",
	close_time1 : "",
	open_time2 : "",
	close_time2 : "",
	open_time3 : "",
	close_time3 : "",
	open_time4 : "",
	close_time4 : "",
	reserve_interval : "15",
	reserve_limit_day : "0",
	reserve_limit_time : "10",
	week_mon_limit_flg : "",
	week_tue_limit_flg : "",
	week_wed_limit_flg : "",
	week_thu_limit_flg : "",
	week_fri_limit_flg : "",
	week_sat_limit_flg : "",
	week_sun_limit_flg : "",
	week_hol_limit_flg : "",
	week_reserve_limit_day : "-1",
	week_reserve_limit_time : "",
	last_order_time : "",
	reserve_week_cnt : "1",
	reserve_cnt_week_kbn : "",
	reserve_cnt_kbn : "",
	reserve_max_cnt_kbn : "",
	update_id : "",
	mode : "",
	id : "",
	bef_start_date : "",
	bef_end_date : "",
};

const defaultWeekTime = [
    {shop_id : "", start_date : "", end_date : "", week_kbn : "0", regular_holiday : "",
        open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
        open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
    {shop_id : "", start_date : "", end_date : "", week_kbn : "1", regular_holiday : "",
        open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
        open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
    {shop_id : "", start_date : "", end_date : "", week_kbn : "2", regular_holiday : "",
        open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
        open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
    {shop_id : "", start_date : "", end_date : "", week_kbn : "3", regular_holiday : "",
        open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
        open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
    {shop_id : "", start_date : "", end_date : "", week_kbn : "4", regular_holiday : "",
        open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
        open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
    {shop_id : "", start_date : "", end_date : "", week_kbn : "5", regular_holiday : "",
        open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
        open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
    {shop_id : "", start_date : "", end_date : "", week_kbn : "6", regular_holiday : "",
        open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
        open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
    {shop_id : "", start_date : "", end_date : "", week_kbn : "7", regular_holiday : "",
        open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
        open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""}
];

const chkWeekTime = [
	{shop_id : "", start_date : "", end_date : "", week_kbn : "0", regular_holiday : "",
		open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
		open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
	{shop_id : "", start_date : "", end_date : "", week_kbn : "1", regular_holiday : "",
		open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
		open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
	{shop_id : "", start_date : "", end_date : "", week_kbn : "2", regular_holiday : "",
		open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
		open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
	{shop_id : "", start_date : "", end_date : "", week_kbn : "3", regular_holiday : "",
		open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
		open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
	{shop_id : "", start_date : "", end_date : "", week_kbn : "4", regular_holiday : "",
		open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
		open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
	{shop_id : "", start_date : "", end_date : "", week_kbn : "5", regular_holiday : "",
		open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
		open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
	{shop_id : "", start_date : "", end_date : "", week_kbn : "6", regular_holiday : "",
		open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
		open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""},
	{shop_id : "", start_date : "", end_date : "", week_kbn : "7", regular_holiday : "",
		open_time1 : "", close_time1 : "", open_time2 : "", close_time2 : "",
		open_time3 : "", close_time3 : "", open_time4 : "", close_time4 : "", update_id : ""}
];

const defaultHoliday = {
	regular_holiday1 : "",
	regular_holiday2 : "",
	regular_holiday3 : "",
	regular_holiday4 : "",
	regular_holiday5 : "",
	regular_holiday6 : "",
	regular_holiday7 : "",
	regular_holiday8 : "",
};

function ShopTimeAddForm({ history, user, shop, data, dbconn, pra_shoptime, pra_weektime, pra_interval, pra_limit, pra_weekcnt, submitReturn }) {
	const [fieldData, handleDataFormat] = useHandleFormData({ ...defaultState});
	const [fieldHoliday, handleHolidayFormat] = useHandleFormData({ ...defaultHoliday});
	const fieldWeekTime = defaultWeekTime;
	const [defData] = useHandleFormData({ ...defaultState});
	const defWeekTime = chkWeekTime;

	const [shoptime, setShopTime] = useState('');
	const [weektime, setWeekTime] = useState('');
	const [interval, setInterval] = useState('');
	const [limit, setLimit] = useState('');
	const [limitday, setLimitDay] = useState('');
	const [weekcnt, setWeekCnt] = useState('');
	const [limitweek, setLimitWeek] = useState('');
	const [limitcnt, setLimitCnt] = useState('');
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [errors, setErrors] = useState('');
	const [errMode, setErrMode] = useState('0');
	const [reserveflg, setReserveFlg] = useState('0');
	const [weekflg, setWeekFlg] = useState('0');
	const [loading, setLoading] = useState('0');

	/* 運用日 */
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

	/* 曜日別締切時間 */
	const [weekLimitTime, setWeekLimitTime] = useState('');

	/* 基本営業時間*/
	const [baseStartTime1, setBaseStartTime1] = useState('');
	const [baseEndTime1, setBaseEndTime1] = useState('');
	const [baseStartTime2, setBaseStartTime2] = useState('');
	const [baseEndTime2, setBaseEndTime2] = useState('');
	const [baseStartTime3, setBaseStartTime3] = useState('');
	const [baseEndTime3, setBaseEndTime3] = useState('');
	const [baseStartTime4, setBaseStartTime4] = useState('');
	const [baseEndTime4, setBaseEndTime4] = useState('');

	/*曜日営業時間*/
	const [moStartTime1, setMoStartTime1] = useState('');
	const [moEndTime1, setMoEndTime1] = useState('');
	const [moStartTime2, setMoStartTime2] = useState('');
	const [moEndTime2, setMoEndTime2] = useState('');
	const [moStartTime3, setMoStartTime3] = useState('');
	const [moEndTime3, setMoEndTime3] = useState('');
	const [moStartTime4, setMoStartTime4] = useState('');
	const [moEndTime4, setMoEndTime4] = useState('');
	const [tuStartTime1, setTuStartTime1] = useState('');
	const [tuEndTime1, setTuEndTime1] = useState('');
	const [tuStartTime2, setTuStartTime2] = useState('');
	const [tuEndTime2, setTuEndTime2] = useState('');
	const [tuStartTime3, setTuStartTime3] = useState('');
	const [tuEndTime3, setTuEndTime3] = useState('');
	const [tuStartTime4, setTuStartTime4] = useState('');
	const [tuEndTime4, setTuEndTime4] = useState('');
	const [weStartTime1, setWeStartTime1] = useState('');
	const [weEndTime1, setWeEndTime1] = useState('');
	const [weStartTime2, setWeStartTime2] = useState('');
	const [weEndTime2, setWeEndTime2] = useState('');
	const [weStartTime3, setWeStartTime3] = useState('');
	const [weEndTime3, setWeEndTime3] = useState('');
	const [weStartTime4, setWeStartTime4] = useState('');
	const [weEndTime4, setWeEndTime4] = useState('');
	const [thStartTime1, setThStartTime1] = useState('');
	const [thEndTime1, setThEndTime1] = useState('');
	const [thStartTime2, setThStartTime2] = useState('');
	const [thEndTime2, setThEndTime2] = useState('');
	const [thStartTime3, setThStartTime3] = useState('');
	const [thEndTime3, setThEndTime3] = useState('');
	const [thStartTime4, setThStartTime4] = useState('');
	const [thEndTime4, setThEndTime4] = useState('');
	const [frStartTime1, setFrStartTime1] = useState('');
	const [frEndTime1, setFrEndTime1] = useState('');
	const [frStartTime2, setFrStartTime2] = useState('');
	const [frEndTime2, setFrEndTime2] = useState('');
	const [frStartTime3, setFrStartTime3] = useState('');
	const [frEndTime3, setFrEndTime3] = useState('');
	const [frStartTime4, setFrStartTime4] = useState('');
	const [frEndTime4, setFrEndTime4] = useState('');
	const [saStartTime1, setSaStartTime1] = useState('');
	const [saEndTime1, setSaEndTime1] = useState('');
	const [saStartTime2, setSaStartTime2] = useState('');
	const [saEndTime2, setSaEndTime2] = useState('');
	const [saStartTime3, setSaStartTime3] = useState('');
	const [saEndTime3, setSaEndTime3] = useState('');
	const [saStartTime4, setSaStartTime4] = useState('');
	const [saEndTime4, setSaEndTime4] = useState('');
	const [suStartTime1, setSuStartTime1] = useState('');
	const [suEndTime1, setSuEndTime1] = useState('');
	const [suStartTime2, setSuStartTime2] = useState('');
	const [suEndTime2, setSuEndTime2] = useState('');
	const [suStartTime3, setSuStartTime3] = useState('');
	const [suEndTime3, setSuEndTime3] = useState('');
	const [suStartTime4, setSuStartTime4] = useState('');
	const [suEndTime4, setSuEndTime4] = useState('');
	const [hoStartTime1, setHoStartTime1] = useState('');
	const [hoEndTime1, setHoEndTime1] = useState('');
	const [hoStartTime2, setHoStartTime2] = useState('');
	const [hoEndTime2, setHoEndTime2] = useState('');
	const [hoStartTime3, setHoStartTime3] = useState('');
	const [hoEndTime3, setHoEndTime3] = useState('');
	const [hoStartTime4, setHoStartTime4] = useState('');
	const [hoEndTime4, setHoEndTime4] = useState('');

	/**
	 * ページ離脱イベント
	 * @param e
	 */
	window.onbeforeunload = function(e){
		//--- 確認メッセージ表示(トリガーとしてのみ有効、メッセージは内容は反映されない)
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			e.returnValue = '';
		}
	}

	/**
	 * データ取得
	 */
	useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
		if(data != '' && data != undefined){
			setLoading('1');
			if(data.mode == '1'){
				//-- 新規
				fieldData.shop_id = shop.shop_id;
				fieldData.id = dbconn.id;
				Http.post('api/init-shoptime',{
					...fieldData
				}).then(response => {
					if (Object.keys(response.data).length > 0) {
						setInterval(response.data.Interval);
						setLimit(response.data.Limit);
						setWeekCnt(response.data.WeekCnt);
						setLimitDay(response.data.LimitDay);
						setLimitWeek(response.data.LimitWeek);
						setLimitCnt(response.data.LimitCnt);
					}
					setLoading('0');
				});
			} else{
				data.id = dbconn.id;
				Http.post('api/get-shoptime', {
					...data
				}).then(response => {
					if (Object.keys(response.data).length > 0) {
						setShopTime(response.data.ShopTime);
						setInterval(response.data.Interval);
						setLimit(response.data.Limit);
						setWeekCnt(response.data.WeekCnt);
						setWeekTime(response.data.WeekTime);
						setLimitDay(response.data.LimitDay);
						setLimitWeek(response.data.LimitWeek);
						setLimitCnt(response.data.LimitCnt);
					}
					setLoading('0');
				});
			}
		}
	}, [])

	/**
	 * 店舗時間情報設定
	 */
	useEffect(() => {
		if (shoptime != "" && shoptime != undefined) {
			//--- 現在日付
			var dtNow = new Date();
			var strDate = dtNow.getFullYear() + '/' + (dtNow.getMonth() + 1) + '/' + dtNow.getDate() + ' ';

			//--- 店舗情報
			fieldData.shop_id = shop.shop_id;
			fieldData.shop_nm = shop.shop_nm;

			//--- 曜日別情報
			//-- 適用開始日
			if (data.mode != '4') {
				//-- コピー以外
				fieldData.start_date = shoptime[0].start_date;
				setStartDate(new Date(shoptime[0].start_date));
				fieldData.end_date = shoptime[0].end_date;
				setEndDate(new Date(shoptime[0].end_date));
				fieldData.bef_start_date = shoptime[0].start_date;
				fieldData.bef_end_date = shoptime[0].end_date;

				defData.start_date = shoptime[0].start_date;
				defData.end_date = shoptime[0].end_date;
			} else {
				//-- コピー
				fieldData.start_date = shoptime[0].start_date;
				setStartDate(new Date(shoptime[0].start_date));
				fieldData.end_date = shoptime[0].end_date;
				setEndDate(new Date(shoptime[0].end_date));
				fieldData.bef_start_date = '';
				fieldData.bef_end_date = '';

				defData.start_date = shoptime[0].start_date;
				defData.end_date = shoptime[0].end_date;
			}
			//-- その他
			fieldData.reserve_interval = shoptime[0].reserve_interval == null ? '' : shoptime[0].reserve_interval;
			fieldData.reserve_limit_day = shoptime[0].reserve_limit_day == null ? '' : shoptime[0].reserve_limit_day;
			fieldData.reserve_limit_time = shoptime[0].reserve_limit_time == null ? '' : shoptime[0].reserve_limit_time;
			fieldData.last_order_time = shoptime[0].last_order_time == null ? '' : shoptime[0].last_order_time;
			fieldData.reserve_week_cnt = shoptime[0].reserve_week_cnt == null ? '' : shoptime[0].reserve_week_cnt;
			fieldData.week_mon_limit_flg = shoptime[0].week_mon_limit_flg == null ? '0' : shoptime[0].week_mon_limit_flg;
			fieldData.week_tue_limit_flg = shoptime[0].week_tue_limit_flg == null ? '0' : shoptime[0].week_tue_limit_flg;
			fieldData.week_wed_limit_flg = shoptime[0].week_wed_limit_flg == null ? '0' : shoptime[0].week_wed_limit_flg;
			fieldData.week_thu_limit_flg = shoptime[0].week_thu_limit_flg == null ? '0' : shoptime[0].week_thu_limit_flg;
			fieldData.week_fri_limit_flg = shoptime[0].week_fri_limit_flg == null ? '0' : shoptime[0].week_fri_limit_flg;
			fieldData.week_sat_limit_flg = shoptime[0].week_sat_limit_flg == null ? '0' : shoptime[0].week_sat_limit_flg;
			fieldData.week_sun_limit_flg = shoptime[0].week_sun_limit_flg == null ? '0' : shoptime[0].week_sun_limit_flg;
			fieldData.week_hol_limit_flg = shoptime[0].week_hol_limit_flg == null ? '0' : shoptime[0].week_hol_limit_flg;
			fieldData.week_reserve_limit_day = shoptime[0].week_reserve_limit_day == null ? '-1' : shoptime[0].week_reserve_limit_day;
			fieldData.week_reserve_limit_time = shoptime[0].week_reserve_limit_time == null ? '' : shoptime[0].week_reserve_limit_time;
			fieldData.reserve_cnt_week_kbn = shoptime[0].reserve_cnt_week_kbn == null ? '' : shoptime[0].reserve_cnt_week_kbn;
			fieldData.reserve_cnt_kbn = shoptime[0].reserve_cnt_kbn == null ? '' : shoptime[0].reserve_cnt_kbn;
			fieldData.reserve_max_cnt_kbn = shoptime[0].reserve_max_cnt_kbn == null ? '' : shoptime[0].reserve_max_cnt_kbn;

			defData.reserve_interval = shoptime[0].reserve_interval == null ? '' : shoptime[0].reserve_interval;
			defData.reserve_limit_day = shoptime[0].reserve_limit_day == null ? '' : shoptime[0].reserve_limit_day;
			defData.reserve_limit_time = shoptime[0].reserve_limit_time == null ? '' : shoptime[0].reserve_limit_time;
			defData.last_order_time = shoptime[0].last_order_time == null ? '' : shoptime[0].last_order_time;
			defData.reserve_week_cnt = shoptime[0].reserve_week_cnt == null ? '' : shoptime[0].reserve_week_cnt;
			defData.week_mon_limit_flg = shoptime[0].week_mon_limit_flg == null ? '0' : shoptime[0].week_mon_limit_flg;
			defData.week_tue_limit_flg = shoptime[0].week_tue_limit_flg == null ? '0' : shoptime[0].week_tue_limit_flg;
			defData.week_wed_limit_flg = shoptime[0].week_wed_limit_flg == null ? '0' : shoptime[0].week_wed_limit_flg;
			defData.week_thu_limit_flg = shoptime[0].week_thu_limit_flg == null ? '0' : shoptime[0].week_thu_limit_flg;
			defData.week_fri_limit_flg = shoptime[0].week_fri_limit_flg == null ? '0' : shoptime[0].week_fri_limit_flg;
			defData.week_sat_limit_flg = shoptime[0].week_sat_limit_flg == null ? '0' : shoptime[0].week_sat_limit_flg;
			defData.week_sun_limit_flg = shoptime[0].week_sun_limit_flg == null ? '0' : shoptime[0].week_sun_limit_flg;
			defData.week_hol_limit_flg = shoptime[0].week_hol_limit_flg == null ? '0' : shoptime[0].week_hol_limit_flg;
			defData.week_reserve_limit_day = shoptime[0].week_reserve_limit_day == null ? '-1' : shoptime[0].week_reserve_limit_day;
			defData.week_reserve_limit_time = shoptime[0].week_reserve_limit_time == null ? '' : shoptime[0].week_reserve_limit_time;
			defData.reserve_cnt_week_kbn = shoptime[0].reserve_cnt_week_kbn == null ? '' : shoptime[0].reserve_cnt_week_kbn;
			defData.reserve_cnt_kbn = shoptime[0].reserve_cnt_kbn == null ? '' : shoptime[0].reserve_cnt_kbn;
			defData.reserve_max_cnt_kbn = shoptime[0].reserve_max_cnt_kbn == null ? '' : shoptime[0].reserve_max_cnt_kbn;

			//-- 曜日別締切時間
			if (shoptime[0].week_reserve_limit_time != null && shoptime[0].week_reserve_limit_time != '') {
				setWeekLimitTime(new Date(strDate + shoptime[0].week_reserve_limit_time + ':00'));
			}

			//--- 基本営業時間
			fieldData.open_time1 = shoptime[0].open_time1;
			defData.open_time1 = shoptime[0].open_time1;
			if (shoptime[0].open_time1 != null && shoptime[0].open_time1 != '') {
				setBaseStartTime1(new Date(strDate + shoptime[0].open_time1 + ':00'));
			}
			fieldData.close_time1 = shoptime[0].close_time1;
			defData.close_time1 = shoptime[0].close_time1;
			if (shoptime[0].close_time1 != null && shoptime[0].close_time1 != '') {
				setBaseEndTime1(new Date(strDate + shoptime[0].close_time1 + ':00'));
			}
			fieldData.open_time2 = shoptime[0].open_time2;
			defData.open_time2 = shoptime[0].open_time2;
			if (shoptime[0].open_time2 != null && shoptime[0].open_time2 != '') {
				setBaseStartTime2(new Date(strDate + shoptime[0].open_time2 + ':00'));
			}
			fieldData.close_time2 = shoptime[0].close_time2;
			defData.close_time2 = shoptime[0].close_time2;
			if (shoptime[0].close_time2 != null && shoptime[0].close_time2 != '') {
				setBaseEndTime2(new Date(strDate + shoptime[0].close_time2 + ':00'));
			}
			fieldData.open_time3 = shoptime[0].open_time3;
			defData.open_time3 = shoptime[0].open_time3;
			if (shoptime[0].open_time3 != null && shoptime[0].open_time3 != '') {
				setBaseStartTime3(new Date(strDate + shoptime[0].open_time3 + ':00'));
			}
			fieldData.close_time3 = shoptime[0].close_time3;
			defData.close_time3 = shoptime[0].close_time3;
			if (shoptime[0].close_time3 != null && shoptime[0].close_time3 != '') {
				setBaseEndTime3(new Date(strDate + shoptime[0].close_time3 + ':00'));
			}
			fieldData.open_time4 = shoptime[0].open_time4;
			defData.open_time4 = shoptime[0].open_time4;
			if (shoptime[0].open_time4 != null && shoptime[0].open_time4 != '') {
				setBaseStartTime4(new Date(strDate + shoptime[0].open_time4 + ':00'));
			}
			fieldData.close_time4 = shoptime[0].close_time4;
			defData.close_time4 = shoptime[0].close_time4;
			if (shoptime[0].close_time4 != null && shoptime[0].close_time4 != '') {
				setBaseEndTime4(new Date(strDate + shoptime[0].close_time4 + ':00'));
			}

			setReserveFlg('1');
		}
	}, [shoptime])

	/**
	 * 店舗曜日別時間情報設定
	 */
	useEffect(() => {
		//--- 現在日付
		var dtNow = new Date();
		var strDate = dtNow.getFullYear() + '/' + (dtNow.getMonth() + 1) + '/' + dtNow.getDate() + ' ';

		//---- 曜日別時間情報
		if(weektime != "" && weektime != undefined){
			for(var intIdx in weektime){
				//--- 曜日判定
				if(weektime[intIdx].week_kbn == '0'){
					//-- 月
					fieldHoliday.regular_holiday1 = weektime[intIdx].regular_holiday;
					if(weektime[intIdx].open_time1 != null && weektime[intIdx].open_time1 != ''){
						setMoStartTime1(new Date(strDate + weektime[intIdx].open_time1 + ':00'));
					}
					if(weektime[intIdx].open_time2 != null && weektime[intIdx].open_time2 != ''){
						setMoStartTime2(new Date(strDate + weektime[intIdx].open_time2 + ':00'));
					}
					if(weektime[intIdx].open_time3 != null && weektime[intIdx].open_time3 != ''){
						setMoStartTime3(new Date(strDate + weektime[intIdx].open_time3 + ':00'));
					}
					if(weektime[intIdx].open_time4 != null && weektime[intIdx].open_time4 != ''){
						setMoStartTime4(new Date(strDate + weektime[intIdx].open_time4 + ':00'));
					}
					if(weektime[intIdx].close_time1 != null && weektime[intIdx].close_time1 != ''){
						setMoEndTime1(new Date(strDate + weektime[intIdx].close_time1 + ':00'));
					}
					if(weektime[intIdx].close_time2 != null && weektime[intIdx].close_time2 != ''){
						setMoEndTime2(new Date(strDate + weektime[intIdx].close_time2 + ':00'));
					}
					if(weektime[intIdx].close_time3 != null && weektime[intIdx].close_time3 != ''){
						setMoEndTime3(new Date(strDate + weektime[intIdx].close_time3 + ':00'));
					}
					if(weektime[intIdx].close_time4 != null && weektime[intIdx].close_time4 != ''){
						setMoEndTime4(new Date(strDate + weektime[intIdx].close_time4 + ':00'));
					}
				}else if(weektime[intIdx].week_kbn == '1'){
					//-- 火
					fieldHoliday.regular_holiday2 = weektime[intIdx].regular_holiday;
					if(weektime[intIdx].open_time1 != null && weektime[intIdx].open_time1 != ''){
						setTuStartTime1(new Date(strDate + weektime[intIdx].open_time1 + ':00'));
					}
					if(weektime[intIdx].open_time2 != null && weektime[intIdx].open_time2 != ''){
						setTuStartTime2(new Date(strDate + weektime[intIdx].open_time2 + ':00'));
					}
					if(weektime[intIdx].open_time3 != null && weektime[intIdx].open_time3 != ''){
						setTuStartTime3(new Date(strDate + weektime[intIdx].open_time3 + ':00'));
					}
					if(weektime[intIdx].open_time4 != null && weektime[intIdx].open_time4 != ''){
						setTuStartTime4(new Date(strDate + weektime[intIdx].open_time4 + ':00'));
					}
					if(weektime[intIdx].close_time1 != null && weektime[intIdx].close_time1 != ''){
						setTuEndTime1(new Date(strDate + weektime[intIdx].close_time1 + ':00'));
					}
					if(weektime[intIdx].close_time2 != null && weektime[intIdx].close_time2 != ''){
						setTuEndTime2(new Date(strDate + weektime[intIdx].close_time2 + ':00'));
					}
					if(weektime[intIdx].close_time3 != null && weektime[intIdx].close_time3 != ''){
						setTuEndTime3(new Date(strDate + weektime[intIdx].close_time3 + ':00'));
					}
					if(weektime[intIdx].close_time4 != null && weektime[intIdx].close_time4 != ''){
						setTuEndTime4(new Date(strDate + weektime[intIdx].close_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '2'){
					//-- 水
					fieldHoliday.regular_holiday3 = weektime[intIdx].regular_holiday;
					if(weektime[intIdx].open_time1 != null && weektime[intIdx].open_time1 != ''){
						setWeStartTime1(new Date(strDate + weektime[intIdx].open_time1 + ':00'));
					}
					if(weektime[intIdx].open_time2 != null && weektime[intIdx].open_time2 != ''){
						setWeStartTime2(new Date(strDate + weektime[intIdx].open_time2 + ':00'));
					}
					if(weektime[intIdx].open_time3 != null && weektime[intIdx].open_time3 != ''){
						setWeStartTime3(new Date(strDate + weektime[intIdx].open_time3 + ':00'));
					}
					if(weektime[intIdx].open_time4 != null && weektime[intIdx].open_time4 != ''){
						setWeStartTime4(new Date(strDate + weektime[intIdx].open_time4 + ':00'));
					}
					if(weektime[intIdx].close_time1 != null && weektime[intIdx].close_time1 != ''){
						setWeEndTime1(new Date(strDate + weektime[intIdx].close_time1 + ':00'));
					}
					if(weektime[intIdx].close_time2 != null && weektime[intIdx].close_time2 != ''){
						setWeEndTime2(new Date(strDate + weektime[intIdx].close_time2 + ':00'));
					}
					if(weektime[intIdx].close_time3 != null && weektime[intIdx].close_time3 != ''){
						setWeEndTime3(new Date(strDate + weektime[intIdx].close_time3 + ':00'));
					}
					if(weektime[intIdx].close_time4 != null && weektime[intIdx].close_time4 != ''){
						setWeEndTime4(new Date(strDate + weektime[intIdx].close_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '3'){
					//-- 木
					fieldHoliday.regular_holiday4 = weektime[intIdx].regular_holiday;
					if(weektime[intIdx].open_time1 != null && weektime[intIdx].open_time1 != ''){
						setThStartTime1(new Date(strDate + weektime[intIdx].open_time1 + ':00'));
					}
					if(weektime[intIdx].open_time2 != null && weektime[intIdx].open_time2 != ''){
						setThStartTime2(new Date(strDate + weektime[intIdx].open_time2 + ':00'));
					}
					if(weektime[intIdx].open_time3 != null && weektime[intIdx].open_time3 != ''){
						setThStartTime3(new Date(strDate + weektime[intIdx].open_time3 + ':00'));
					}
					if(weektime[intIdx].open_time4 != null && weektime[intIdx].open_time4 != ''){
						setThStartTime4(new Date(strDate + weektime[intIdx].open_time4 + ':00'));
					}
					if(weektime[intIdx].close_time1 != null && weektime[intIdx].close_time1 != ''){
						setThEndTime1(new Date(strDate + weektime[intIdx].close_time1 + ':00'));
					}
					if(weektime[intIdx].close_time2 != null && weektime[intIdx].close_time2 != ''){
						setThEndTime2(new Date(strDate + weektime[intIdx].close_time2 + ':00'));
					}
					if(weektime[intIdx].close_time3 != null && weektime[intIdx].close_time3 != ''){
						setThEndTime3(new Date(strDate + weektime[intIdx].close_time3 + ':00'));
					}
					if(weektime[intIdx].close_time4 != null && weektime[intIdx].close_time4 != ''){
						setThEndTime4(new Date(strDate + weektime[intIdx].close_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '4'){
					//-- 金
					fieldHoliday.regular_holiday5 = weektime[intIdx].regular_holiday;
					if(weektime[intIdx].open_time1 != null && weektime[intIdx].open_time1 != ''){
						setFrStartTime1(new Date(strDate + weektime[intIdx].open_time1 + ':00'));
					}
					if(weektime[intIdx].open_time2 != null && weektime[intIdx].open_time2 != ''){
						setFrStartTime2(new Date(strDate + weektime[intIdx].open_time2 + ':00'));
					}
					if(weektime[intIdx].open_time3 != null && weektime[intIdx].open_time3 != ''){
						setFrStartTime3(new Date(strDate + weektime[intIdx].open_time3 + ':00'));
					}
					if(weektime[intIdx].open_time4 != null && weektime[intIdx].open_time4 != ''){
						setFrStartTime4(new Date(strDate + weektime[intIdx].open_time4 + ':00'));
					}
					if(weektime[intIdx].close_time1 != null && weektime[intIdx].close_time1 != ''){
						setFrEndTime1(new Date(strDate + weektime[intIdx].close_time1 + ':00'));
					}
					if(weektime[intIdx].close_time2 != null && weektime[intIdx].close_time2 != ''){
						setFrEndTime2(new Date(strDate + weektime[intIdx].close_time2 + ':00'));
					}
					if(weektime[intIdx].close_time3 != null && weektime[intIdx].close_time3 != ''){
						setFrEndTime3(new Date(strDate + weektime[intIdx].close_time3 + ':00'));
					}
					if(weektime[intIdx].close_time4 != null && weektime[intIdx].close_time4 != ''){
						setFrEndTime4(new Date(strDate + weektime[intIdx].close_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '5'){
					//-- 土
					fieldHoliday.regular_holiday6 = weektime[intIdx].regular_holiday;
					if(weektime[intIdx].open_time1 != null && weektime[intIdx].open_time1 != ''){
						setSaStartTime1(new Date(strDate + weektime[intIdx].open_time1 + ':00'));
					}
					if(weektime[intIdx].open_time2 != null && weektime[intIdx].open_time2 != ''){
						setSaStartTime2(new Date(strDate + weektime[intIdx].open_time2 + ':00'));
					}
					if(weektime[intIdx].open_time3 != null && weektime[intIdx].open_time3 != ''){
						setSaStartTime3(new Date(strDate + weektime[intIdx].open_time3 + ':00'));
					}
					if(weektime[intIdx].open_time4 != null && weektime[intIdx].open_time4 != ''){
						setSaStartTime4(new Date(strDate + weektime[intIdx].open_time4 + ':00'));
					}
					if(weektime[intIdx].close_time1 != null && weektime[intIdx].close_time1 != ''){
						setSaEndTime1(new Date(strDate + weektime[intIdx].close_time1 + ':00'));
					}
					if(weektime[intIdx].close_time2 != null && weektime[intIdx].close_time2 != ''){
						setSaEndTime2(new Date(strDate + weektime[intIdx].close_time2 + ':00'));
					}
					if(weektime[intIdx].close_time3 != null && weektime[intIdx].close_time3 != ''){
						setSaEndTime3(new Date(strDate + weektime[intIdx].close_time3 + ':00'));
					}
					if(weektime[intIdx].close_time4 != null && weektime[intIdx].close_time4 != ''){
						setSaEndTime4(new Date(strDate + weektime[intIdx].close_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '6'){
					//-- 日
					fieldHoliday.regular_holiday7 = weektime[intIdx].regular_holiday;
					if(weektime[intIdx].open_time1 != null && weektime[intIdx].open_time1 != ''){
						setSuStartTime1(new Date(strDate + weektime[intIdx].open_time1 + ':00'));
					}
					if(weektime[intIdx].open_time2 != null && weektime[intIdx].open_time2 != ''){
						setSuStartTime2(new Date(strDate + weektime[intIdx].open_time2 + ':00'));
					}
					if(weektime[intIdx].open_time3 != null && weektime[intIdx].open_time3 != ''){
						setSuStartTime3(new Date(strDate + weektime[intIdx].open_time3 + ':00'));
					}
					if(weektime[intIdx].open_time4 != null && weektime[intIdx].open_time4 != ''){
						setSuStartTime4(new Date(strDate + weektime[intIdx].open_time4 + ':00'));
					}
					if(weektime[intIdx].close_time1 != null && weektime[intIdx].close_time1 != ''){
						setSuEndTime1(new Date(strDate + weektime[intIdx].close_time1 + ':00'));
					}
					if(weektime[intIdx].close_time2 != null && weektime[intIdx].close_time2 != ''){
						setSuEndTime2(new Date(strDate + weektime[intIdx].close_time2 + ':00'));
					}
					if(weektime[intIdx].close_time3 != null && weektime[intIdx].close_time3 != ''){
						setSuEndTime3(new Date(strDate + weektime[intIdx].close_time3 + ':00'));
					}
					if(weektime[intIdx].close_time4 != null && weektime[intIdx].close_time4 != ''){
						setSuEndTime4(new Date(strDate + weektime[intIdx].close_time4 + ':00'));
					}

				}else if(weektime[intIdx].week_kbn == '7'){
					//-- 祝
					fieldHoliday.regular_holiday8 = weektime[intIdx].regular_holiday;
					if(weektime[intIdx].open_time1 != null && weektime[intIdx].open_time1 != ''){
						setHoStartTime1(new Date(strDate + weektime[intIdx].open_time1 + ':00'));
					}
					if(weektime[intIdx].open_time2 != null && weektime[intIdx].open_time2 != ''){
						setHoStartTime2(new Date(strDate + weektime[intIdx].open_time2 + ':00'));
					}
					if(weektime[intIdx].open_time3 != null && weektime[intIdx].open_time3 != ''){
						setHoStartTime3(new Date(strDate + weektime[intIdx].open_time3 + ':00'));
					}
					if(weektime[intIdx].open_time4 != null && weektime[intIdx].open_time4 != ''){
						setHoStartTime4(new Date(strDate + weektime[intIdx].open_time4 + ':00'));
					}
					if(weektime[intIdx].close_time1 != null && weektime[intIdx].close_time1 != ''){
						setHoEndTime1(new Date(strDate + weektime[intIdx].close_time1 + ':00'));
					}
					if(weektime[intIdx].close_time2 != null && weektime[intIdx].close_time2 != ''){
						setHoEndTime2(new Date(strDate + weektime[intIdx].close_time2 + ':00'));
					}
					if(weektime[intIdx].close_time3 != null && weektime[intIdx].close_time3 != ''){
						setHoEndTime3(new Date(strDate + weektime[intIdx].close_time3 + ':00'));
					}
					if(weektime[intIdx].close_time4 != null && weektime[intIdx].close_time4 != ''){
						setHoEndTime4(new Date(strDate + weektime[intIdx].close_time4 + ':00'));
					}
				}

				defWeekTime[intIdx].week_kbn = weektime[intIdx].week_kbn;
				defWeekTime[intIdx].regular_holiday = weektime[intIdx].regular_holiday;
				defWeekTime[intIdx].open_time1 = weektime[intIdx].open_time1;
				defWeekTime[intIdx].close_time1 = weektime[intIdx].close_time1;
				defWeekTime[intIdx].open_time2 = weektime[intIdx].open_time2;
				defWeekTime[intIdx].close_time2 = weektime[intIdx].close_time2;
				defWeekTime[intIdx].open_time3 = weektime[intIdx].open_time3;
				defWeekTime[intIdx].close_time3 = weektime[intIdx].close_time3;
				defWeekTime[intIdx].open_time4 = weektime[intIdx].open_time4;
				defWeekTime[intIdx].close_time4 = weektime[intIdx].close_time4;
			}

			setWeekFlg('1');
		}else{
			for(let intIdx = 0; intIdx <= 7; intIdx++){
				defWeekTime[intIdx].regular_holiday = '';
				defWeekTime[intIdx].open_time1 = '';
				defWeekTime[intIdx].close_time1 = '';
				defWeekTime[intIdx].open_time2 = '';
				defWeekTime[intIdx].close_time2 = '';
				defWeekTime[intIdx].open_time3 = '';
				defWeekTime[intIdx].close_time3 = '';
				defWeekTime[intIdx].open_time4 = '';
				defWeekTime[intIdx].close_time4 = '';
			}
		}
	}, [weektime])

	/**
	 * 予約時間間隔設定
	 * @returns {*}
	 */
	function fncSetInterval(){
		var aryIntervals = [];
		if(interval == "") return;

		//aryIntervals.push(
		//	<option value='' key='0'></option>
		//);
		for(var intIdx in interval){
			aryIntervals.push(
				<option value={interval[intIdx].com_kbn} key={interval[intIdx].com_kbn}>
					{interval[intIdx].com_val1}
				</option>
			);
		}
		if(aryIntervals.length <= 0){
			aryIntervals.push(<option value='' key='0'></option>);
		}

		return(
			<>
				{aryIntervals}
			</>
		);
	}

	/**
	 * 予約締切日設定
	 */
	function fncSetLimitDay(){
		var aryLimitDays = [];
		if(limit == "") return;

		for(var intIdx in limitday){
			aryLimitDays.push(
				<option value={limitday[intIdx].com_val2} key={limitday[intIdx].com_kbn}>
					{limitday[intIdx].com_val1}
				</option>
			);
		}
		if(aryLimitDays.length > 0){
			return(
				<>
					{aryLimitDays}
				</>
			);
		}else{
			return(<option value='0' key='0'>0日</option>);
		}
	}

	/**
	 * 予約締切日設定(曜日別設定)
	 */
	function fncSetLimitWeekDay(){
		var aryLimitDays = [];
		if(limit == "") return;

		aryLimitDays.push(
			<option value='-1' key='0'></option>
		);

		for(var intIdx in limitday){
			// #3087 0日 はリストから除外する。
			if (limitday[intIdx].com_kbn === '001') continue;
			aryLimitDays.push(
				<option value={limitday[intIdx].com_val2} key={limitday[intIdx].com_kbn}>
					{limitday[intIdx].com_val1}
				</option>
			);
		}
		if(aryLimitDays.length > 0){
			return(
				<>
					{aryLimitDays}
				</>
			);
		}else{
			return(<option value='-1' key='0'></option>);
		}
	}

	/**
	 * 予約締切時間設定
	 */
	function fncSetLimit(){
		var aryLimits = [];
		if(limit == "") return;

		//aryLimits.push(
		//	<option value='' key='0'></option>
		//);
		for(var intIdx in limit){
			aryLimits.push(
				<option value={limit[intIdx].com_val2} key={limit[intIdx].com_kbn}>
					{limit[intIdx].com_val1}
				</option>
			);
		}
		if(aryLimits.length > 0){
			return(
				<>
					{aryLimits}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * 予約可能期間設定
	 * @returns {*}
	 */
	function fncSetWeekCnt(){
		var aryWeekCnts = [];
		if(weekcnt == "") return;

		//aryWeekCnts.push(
		//	<option value='' key='0'></option>
		//);
		for(var intIdx in weekcnt){
			aryWeekCnts.push(
				<option value={weekcnt[intIdx].com_kbn} key={weekcnt[intIdx].com_kbn}>
					{weekcnt[intIdx].com_val1}
				</option>
			);
		}
		if(aryWeekCnts.length > 0){
			return(
				<>
					{aryWeekCnts}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * 予約制限週設定
	 * @returns {*}
	 */
	function fncSetLimitWeek(){
		var aryLimitWeeks = [];
		if(limitweek == "") return;

		aryLimitWeeks.push(
			<option value='' key='0'></option>
		);
		for(var intIdx in limitweek){
			aryLimitWeeks.push(
				<option value={limitweek[intIdx].com_kbn} key={limitweek[intIdx].com_kbn}>
					{limitweek[intIdx].com_val1}
				</option>
			);
		}
		if(aryLimitWeeks.length > 0){
			return(
				<>
					{aryLimitWeeks}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * 予約制限回数設定
	 * @returns {*}
	 */
	function fncSetLimitCnt(){
		var aryLimitCnts = [];
		if(limitcnt == "") return;

		aryLimitCnts.push(
			<option value='' key='0'></option>
		);
		for(var intIdx in limitcnt){
			aryLimitCnts.push(
				<option value={limitcnt[intIdx].com_kbn} key={limitcnt[intIdx].com_kbn}>
					{limitcnt[intIdx].com_val1}
				</option>
			);
		}
		if(aryLimitCnts.length > 0){
			return(
				<>
					{aryLimitCnts}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * 曜日別時間作成
	 * @returns {*}
	 */
	function fncSetWeekTime(){
		var lstWeek = [];

		if(weekflg != '1' && data.mode != '1') return;
        lstWeek.push(
            <WeekSet
                index="1"
                strYobi="月"
                defaultChecked={fieldHoliday.regular_holiday1 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '2' || data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtMoStartTime"
                strIDBaseE="dtMoEndTime"
                valS1={moStartTime1} fncSetS1={date => setMoStartTime1(date)}
                valE1={moEndTime1}   fncSetE1={date => setMoEndTime1(date)}
                valS2={moStartTime2} fncSetS2={date => setMoStartTime2(date)}
                valE2={moEndTime2}   fncSetE2={date => setMoEndTime2(date)}
                valS3={moStartTime3} fncSetS3={date => setMoStartTime3(date)}
                valE3={moEndTime3}   fncSetE3={date => setMoEndTime3(date)}
                valS4={moStartTime4} fncSetS4={date => setMoStartTime4(date)}
                valE4={moEndTime4}   fncSetE4={date => setMoEndTime4(date)}
            />
        );
        lstWeek.push(
            <WeekSet
                index="2"
                strYobi="火"
                defaultChecked={fieldHoliday.regular_holiday2 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '2' || data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtTuStartTime"
                strIDBaseE="dtTuEndTime"
                valS1={tuStartTime1} fncSetS1={date => setTuStartTime1(date)}
                valE1={tuEndTime1}   fncSetE1={date => setTuEndTime1(date)}
                valS2={tuStartTime2} fncSetS2={date => setTuStartTime2(date)}
                valE2={tuEndTime2}   fncSetE2={date => setTuEndTime2(date)}
                valS3={tuStartTime3} fncSetS3={date => setTuStartTime3(date)}
                valE3={tuEndTime3}   fncSetE3={date => setTuEndTime3(date)}
                valS4={tuStartTime4} fncSetS4={date => setTuStartTime4(date)}
                valE4={tuEndTime4}   fncSetE4={date => setTuEndTime4(date)}
            />
        );
        lstWeek.push(
            <WeekSet
                index="3"
                strYobi="水"
                defaultChecked={fieldHoliday.regular_holiday3 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '2' || data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtWeStartTime"
                strIDBaseE="dtWeEndTime"
                valS1={weStartTime1} fncSetS1={date => setWeStartTime1(date)}
                valE1={weEndTime1}   fncSetE1={date => setWeEndTime1(date)}
                valS2={weStartTime2} fncSetS2={date => setWeStartTime2(date)}
                valE2={weEndTime2}   fncSetE2={date => setWeEndTime2(date)}
                valS3={weStartTime3} fncSetS3={date => setWeStartTime3(date)}
                valE3={weEndTime3}   fncSetE3={date => setWeEndTime3(date)}
                valS4={weStartTime4} fncSetS4={date => setWeStartTime4(date)}
                valE4={weEndTime4}   fncSetE4={date => setWeEndTime4(date)}
            />
        );
        lstWeek.push(
            <WeekSet
                index="4"
                strYobi="木"
                defaultChecked={fieldHoliday.regular_holiday4 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '2' || data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtThStartTime"
                strIDBaseE="dtThEndTime"
                valS1={thStartTime1} fncSetS1={date => setThStartTime1(date)}
                valE1={thEndTime1}   fncSetE1={date => setThEndTime1(date)}
                valS2={thStartTime2} fncSetS2={date => setThStartTime2(date)}
                valE2={thEndTime2}   fncSetE2={date => setThEndTime2(date)}
                valS3={thStartTime3} fncSetS3={date => setThStartTime3(date)}
                valE3={thEndTime3}   fncSetE3={date => setThEndTime3(date)}
                valS4={thStartTime4} fncSetS4={date => setThStartTime4(date)}
                valE4={thEndTime4}   fncSetE4={date => setThEndTime4(date)}
            />
        );
        lstWeek.push(
            <WeekSet
                index="5"
                strYobi="金"
                defaultChecked={fieldHoliday.regular_holiday5 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '2' || data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtFrStartTime"
                strIDBaseE="dtFrEndTime"
                valS1={frStartTime1} fncSetS1={date => setFrStartTime1(date)}
                valE1={frEndTime1}   fncSetE1={date => setFrEndTime1(date)}
                valS2={frStartTime2} fncSetS2={date => setFrStartTime2(date)}
                valE2={frEndTime2}   fncSetE2={date => setFrEndTime2(date)}
                valS3={frStartTime3} fncSetS3={date => setFrStartTime3(date)}
                valE3={frEndTime3}   fncSetE3={date => setFrEndTime3(date)}
                valS4={frStartTime4} fncSetS4={date => setFrStartTime4(date)}
                valE4={frEndTime4}   fncSetE4={date => setFrEndTime4(date)}
            />
        );
        lstWeek.push(
            <WeekSet
                index="6"
                strYobi="土"
                defaultChecked={fieldHoliday.regular_holiday6 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '2' || data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtSaStartTime"
                strIDBaseE="dtSaEndTime"
                valS1={saStartTime1} fncSetS1={date => setSaStartTime1(date)}
                valE1={saEndTime1}   fncSetE1={date => setSaEndTime1(date)}
                valS2={saStartTime2} fncSetS2={date => setSaStartTime2(date)}
                valE2={saEndTime2}   fncSetE2={date => setSaEndTime2(date)}
                valS3={saStartTime3} fncSetS3={date => setSaStartTime3(date)}
                valE3={saEndTime3}   fncSetE3={date => setSaEndTime3(date)}
                valS4={saStartTime4} fncSetS4={date => setSaStartTime4(date)}
                valE4={saEndTime4}   fncSetE4={date => setSaEndTime4(date)}
            />
        );
        lstWeek.push(
            <WeekSet
                index="7"
                strYobi="日"
                defaultChecked={fieldHoliday.regular_holiday7 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '2' || data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtSuStartTime"
                strIDBaseE="dtSuEndTime"
                valS1={suStartTime1} fncSetS1={date => setSuStartTime1(date)}
                valE1={suEndTime1}   fncSetE1={date => setSuEndTime1(date)}
                valS2={suStartTime2} fncSetS2={date => setSuStartTime2(date)}
                valE2={suEndTime2}   fncSetE2={date => setSuEndTime2(date)}
                valS3={suStartTime3} fncSetS3={date => setSuStartTime3(date)}
                valE3={suEndTime3}   fncSetE3={date => setSuEndTime3(date)}
                valS4={suStartTime4} fncSetS4={date => setSuStartTime4(date)}
                valE4={suEndTime4}   fncSetE4={date => setSuEndTime4(date)}
            />
        );
        lstWeek.push(
            <WeekSet
                index="8"
                strYobi="祝"
                defaultChecked={fieldHoliday.regular_holiday8 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '2' || data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtHoStartTime"
                strIDBaseE="dtHoEndTime"
                valS1={hoStartTime1} fncSetS1={date => setHoStartTime1(date)}
                valE1={hoEndTime1}   fncSetE1={date => setHoEndTime1(date)}
                valS2={hoStartTime2} fncSetS2={date => setHoStartTime2(date)}
                valE2={hoEndTime2}   fncSetE2={date => setHoEndTime2(date)}
                valS3={hoStartTime3} fncSetS3={date => setHoStartTime3(date)}
                valE3={hoEndTime3}   fncSetE3={date => setHoEndTime3(date)}
                valS4={hoStartTime4} fncSetS4={date => setHoStartTime4(date)}
                valE4={hoEndTime4}   fncSetE4={date => setHoEndTime4(date)}
            />
        );

		return(
			<tbody>
				{lstWeek}
			</tbody>
		);
	}

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		//--- 変更チェック
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			if(window.confirm(MSG_SYSTEM_UPD_BACK)){
				//-- 戻る
				submitReturn();
			}
		}else{
			//-- 戻る
			submitReturn();
		}
	}

	/**
	 * 変更項目チェック
	 * @returns {string}
	 */
	function fncChkBackData(){
		//--- 削除モードは判定しない
		if(data.mode == '3') return '0';

		//--- データ格納
		fncSetDataShopTime();
		fncSetDataWeekTime();

		//--- フォーマット変換
		defData.start_date = defData.start_date.toString().replace('-','/');
		defData.start_date = defData.start_date.toString().replace('-','/');
		defData.end_date = defData.end_date.toString().replace('-','/');
		defData.end_date = defData.end_date.toString().replace('-','/');

		//--- 曜日別情報チェック
		if(fieldData.start_date != defData.start_date ||
			fieldData.end_date != defData.end_date ||
			fieldData.reserve_interval != defData.reserve_interval ||
			fieldData.reserve_limit_day != defData.reserve_limit_day ||
			fieldData.reserve_limit_time != defData.reserve_limit_time ||
			fieldData.last_order_time != defData.last_order_time ||
			fieldData.reserve_week_cnt != defData.reserve_week_cnt ||
			fieldData.week_mon_limit_flg != defData.week_mon_limit_flg ||
			fieldData.week_tue_limit_flg != defData.week_tue_limit_flg ||
			fieldData.week_wed_limit_flg != defData.week_wed_limit_flg ||
			fieldData.week_thu_limit_flg != defData.week_thu_limit_flg ||
			fieldData.week_fri_limit_flg != defData.week_fri_limit_flg ||
			fieldData.week_sat_limit_flg != defData.week_sat_limit_flg ||
			fieldData.week_sun_limit_flg != defData.week_sun_limit_flg ||
			fieldData.week_hol_limit_flg != defData.week_hol_limit_flg ||
			fieldData.week_reserve_limit_day != defData.week_reserve_limit_day ||
			fieldData.week_reserve_limit_time != defData.week_reserve_limit_time ||
			fieldData.reserve_cnt_week_kbn != defData.reserve_cnt_week_kbn ||
			fieldData.reserve_cnt_kbn != defData.reserve_cnt_kbn ||
			fieldData.reserve_max_cnt_kbn != defData.reserve_max_cnt_kbn ||
			fieldData.open_time1 != defData.open_time1 ||
			fieldData.close_time1 != defData.close_time1 ||
			fieldData.open_time2 != defData.open_time2 ||
			fieldData.close_time2 != defData.close_time2 ||
			fieldData.open_time3 != defData.open_time3 ||
			fieldData.close_time3 != defData.close_time3 ||
			fieldData.open_time4 != defData.open_time4 ||
			fieldData.close_time4 != defData.close_time4){
			return '1';
		}

		//--- 曜日別時間チェック
		for(let idx = 0; idx <= 7; idx++){
			if(fieldWeekTime[idx].week_kbn != defWeekTime[idx].week_kbn ||
				fieldWeekTime[idx].regular_holiday != defWeekTime[idx].regular_holiday ||
				fieldWeekTime[idx].open_time1 != defWeekTime[idx].open_time1 ||
				fieldWeekTime[idx].close_time1 != defWeekTime[idx].close_time1 ||
				fieldWeekTime[idx].open_time2 != defWeekTime[idx].open_time2 ||
				fieldWeekTime[idx].close_time2 != defWeekTime[idx].close_time2 ||
				fieldWeekTime[idx].open_time3 != defWeekTime[idx].open_time3 ||
				fieldWeekTime[idx].close_time3 != defWeekTime[idx].close_time3 ||
				fieldWeekTime[idx].open_time4 != defWeekTime[idx].open_time4 ||
				fieldWeekTime[idx].close_time4 != defWeekTime[idx].close_time4){
				return '1';
			}
		}
		return '0';
	}

	/**
	 * 時間反映ボタン
	 */
	function fncOnClickBaseTime(){
		//--- 営業時間1
		if(baseStartTime1 != '' && baseStartTime1 != null){
			setMoStartTime1(baseStartTime1);
			setTuStartTime1(baseStartTime1);
			setWeStartTime1(baseStartTime1);
			setThStartTime1(baseStartTime1);
			setFrStartTime1(baseStartTime1);
			setSaStartTime1(baseStartTime1);
			setSuStartTime1(baseStartTime1);
			setHoStartTime1(baseStartTime1);
		}else{
			setMoStartTime1('');
			setTuStartTime1('');
			setWeStartTime1('');
			setThStartTime1('');
			setFrStartTime1('');
			setSaStartTime1('');
			setSuStartTime1('');
			setHoStartTime1('');
		}
		if(baseEndTime1 != '' && baseEndTime1 != null){
			setMoEndTime1(baseEndTime1);
			setTuEndTime1(baseEndTime1);
			setWeEndTime1(baseEndTime1);
			setThEndTime1(baseEndTime1);
			setFrEndTime1(baseEndTime1);
			setSaEndTime1(baseEndTime1);
			setSuEndTime1(baseEndTime1);
			setHoEndTime1(baseEndTime1);
		}else{
			setMoEndTime1('');
			setTuEndTime1('');
			setWeEndTime1('');
			setThEndTime1('');
			setFrEndTime1('');
			setSaEndTime1('');
			setSuEndTime1('');
			setHoEndTime1('');
		}

		//--- 営業時間2
		if(baseStartTime2 != '' && baseStartTime2 != null){
			setMoStartTime2(baseStartTime2);
			setTuStartTime2(baseStartTime2);
			setWeStartTime2(baseStartTime2);
			setThStartTime2(baseStartTime2);
			setFrStartTime2(baseStartTime2);
			setSaStartTime2(baseStartTime2);
			setSuStartTime2(baseStartTime2);
			setHoStartTime2(baseStartTime2);
		}else{
			setMoStartTime2('');
			setTuStartTime2('');
			setWeStartTime2('');
			setThStartTime2('');
			setFrStartTime2('');
			setSaStartTime2('');
			setSuStartTime2('');
			setHoStartTime2('');
		}
		if(baseEndTime2 != '' && baseEndTime2 != null){
			setMoEndTime2(baseEndTime2);
			setTuEndTime2(baseEndTime2);
			setWeEndTime2(baseEndTime2);
			setThEndTime2(baseEndTime2);
			setFrEndTime2(baseEndTime2);
			setSaEndTime2(baseEndTime2);
			setSuEndTime2(baseEndTime2);
			setHoEndTime2(baseEndTime2);
		}else{
			setMoEndTime2('');
			setTuEndTime2('');
			setWeEndTime2('');
			setThEndTime2('');
			setFrEndTime2('');
			setSaEndTime2('');
			setSuEndTime2('');
			setHoEndTime2('');
		}

		//--- 営業時間3
		if(baseStartTime3 != '' && baseStartTime3 != null){
			setMoStartTime3(baseStartTime3);
			setTuStartTime3(baseStartTime3);
			setWeStartTime3(baseStartTime3);
			setThStartTime3(baseStartTime3);
			setFrStartTime3(baseStartTime3);
			setSaStartTime3(baseStartTime3);
			setSuStartTime3(baseStartTime3);
			setHoStartTime3(baseStartTime3);
		}else{
			setMoStartTime3('');
			setTuStartTime3('');
			setWeStartTime3('');
			setThStartTime3('');
			setFrStartTime3('');
			setSaStartTime3('');
			setSuStartTime3('');
			setHoStartTime3('');
		}
		if(baseEndTime3 != '' && baseEndTime3 != null){
			setMoEndTime3(baseEndTime3);
			setTuEndTime3(baseEndTime3);
			setWeEndTime3(baseEndTime3);
			setThEndTime3(baseEndTime3);
			setFrEndTime3(baseEndTime3);
			setSaEndTime3(baseEndTime3);
			setSuEndTime3(baseEndTime3);
			setHoEndTime3(baseEndTime3);
		}else{
			setMoEndTime3('');
			setTuEndTime3('');
			setWeEndTime3('');
			setThEndTime3('');
			setFrEndTime3('');
			setSaEndTime3('');
			setSuEndTime3('');
			setHoEndTime3('');
		}

		//--- 営業時間3
		if(baseStartTime4 != '' && baseStartTime4 != null){
			setMoStartTime4(baseStartTime4);
			setTuStartTime4(baseStartTime4);
			setWeStartTime4(baseStartTime4);
			setThStartTime4(baseStartTime4);
			setFrStartTime4(baseStartTime4);
			setSaStartTime4(baseStartTime4);
			setSuStartTime4(baseStartTime4);
			setHoStartTime4(baseStartTime4);
		}else{
			setMoStartTime4('');
			setTuStartTime4('');
			setWeStartTime4('');
			setThStartTime4('');
			setFrStartTime4('');
			setSaStartTime4('');
			setSuStartTime4('');
			setHoStartTime4('');
		}
		if(baseEndTime4 != '' && baseEndTime4 != null){
			setMoEndTime4(baseEndTime4);
			setTuEndTime4(baseEndTime4);
			setWeEndTime4(baseEndTime4);
			setThEndTime4(baseEndTime4);
			setFrEndTime4(baseEndTime4);
			setSaEndTime4(baseEndTime4);
			setSuEndTime4(baseEndTime4);
			setHoEndTime4(baseEndTime4);
		}else{
			setMoEndTime4('');
			setTuEndTime4('');
			setWeEndTime4('');
			setThEndTime4('');
			setFrEndTime4('');
			setSaEndTime4('');
			setSuEndTime4('');
			setHoEndTime4('');
		}
	}

	/**
	 * 休日チェックボックス()
	 * @param e
	 */
	function fncOnClickHoliday(e){
		//--- ボタン判定
		if(e.currentTarget.value == '1'){
			//-- 月
			//-- チェック判定
			if(fieldHoliday.regular_holiday1 == '1'){
				//- チェックON
				// チェックOFFに変更
				fieldHoliday.regular_holiday1 = '0';
			}else{
				//- チェックOFF
				// チェックONに変更
				fieldHoliday.regular_holiday1 = '1';
				setMoStartTime1(null);
				setMoStartTime2(null);
				setMoStartTime3(null);
				setMoStartTime4(null);
				setMoEndTime1(null);
				setMoEndTime2(null);
				setMoEndTime3(null);
				setMoEndTime4(null);
			}
		}else if(e.currentTarget.value == '2'){
			//-- 火
			//-- チェック判定
			if(fieldHoliday.regular_holiday2 == '1'){
				//- チェックON
				// チェックOFFに変更
				fieldHoliday.regular_holiday2 = '0';
			}else{
				//- チェックOFF
				// チェックONに変更
				fieldHoliday.regular_holiday2 = '1';
				setTuStartTime1(null);
				setTuStartTime2(null);
				setTuStartTime3(null);
				setTuStartTime4(null);
				setTuEndTime1(null);
				setTuEndTime2(null);
				setTuEndTime3(null);
				setTuEndTime4(null);
			}
		}else if(e.currentTarget.value == '3'){
			//-- 水
			//-- チェック判定
			if(fieldHoliday.regular_holiday3 == '1'){
				//- チェックON
				// チェックOFFに変更
				fieldHoliday.regular_holiday3 = '0';
			}else{
				//- チェックOFF
				// チェックONに変更
				fieldHoliday.regular_holiday3 = '1';
				setWeStartTime1(null);
				setWeStartTime2(null);
				setWeStartTime3(null);
				setWeStartTime4(null);
				setWeEndTime1(null);
				setWeEndTime2(null);
				setWeEndTime3(null);
				setWeEndTime4(null);
			}
		}else if(e.currentTarget.value == '4'){
			//-- 木
			//-- チェック判定
			if(fieldHoliday.regular_holiday4 == '1'){
				//- チェックON
				// チェックOFFに変更
				fieldHoliday.regular_holiday4 = '0';
			}else{
				//- チェックOFF
				// チェックONに変更
				fieldHoliday.regular_holiday4 = '1';
				setThStartTime1(null);
				setThStartTime2(null);
				setThStartTime3(null);
				setThStartTime4(null);
				setThEndTime1(null);
				setThEndTime2(null);
				setThEndTime3(null);
				setThEndTime4(null);
			}
		}else if(e.currentTarget.value == '5'){
			//-- 金
			//-- チェック判定
			if(fieldHoliday.regular_holiday5 == '1'){
				//- チェックON
				// チェックOFFに変更
				fieldHoliday.regular_holiday5 = '0';
			}else{
				//- チェックOFF
				// チェックONに変更
				fieldHoliday.regular_holiday5 = '1';
				setFrStartTime1(null);
				setFrStartTime2(null);
				setFrStartTime3(null);
				setFrStartTime4(null);
				setFrEndTime1(null);
				setFrEndTime2(null);
				setFrEndTime3(null);
				setFrEndTime4(null);
			}
		}else if(e.currentTarget.value == '6'){
			//-- 土
			//-- チェック判定
			if(fieldHoliday.regular_holiday6 == '1'){
				//- チェックON
				// チェックOFFに変更
				fieldHoliday.regular_holiday6 = '0';
			}else{
				//- チェックOFF
				// チェックONに変更
				fieldHoliday.regular_holiday6 = '1';
				setSaStartTime1(null);
				setSaStartTime2(null);
				setSaStartTime3(null);
				setSaStartTime4(null);
				setSaEndTime1(null);
				setSaEndTime2(null);
				setSaEndTime3(null);
				setSaEndTime4(null);
			}
		}else if(e.currentTarget.value == '7'){
			//-- 日
			//-- チェック判定
			if(fieldHoliday.regular_holiday7 == '1'){
				//- チェックON
				// チェックOFFに変更
				fieldHoliday.regular_holiday7 = '0';
			}else{
				//- チェックOFF
				// チェックONに変更
				fieldHoliday.regular_holiday7 = '1';
				setSuStartTime1(null);
				setSuStartTime2(null);
				setSuStartTime3(null);
				setSuStartTime4(null);
				setSuEndTime1(null);
				setSuEndTime2(null);
				setSuEndTime3(null);
				setSuEndTime4(null);
			}
		}else if(e.currentTarget.value == '8'){
			//-- 祝
			//-- チェック判定
			if(fieldHoliday.regular_holiday8 == '1'){
				//- チェックON
				// チェックOFFに変更
				fieldHoliday.regular_holiday8 = '0';
			}else{
				//- チェックOFF
				// チェックONに変更
				fieldHoliday.regular_holiday8 = '1';
				setHoStartTime1(null);
				setHoStartTime2(null);
				setHoStartTime3(null);
				setHoStartTime4(null);
				setHoEndTime1(null);
				setHoEndTime2(null);
				setHoEndTime3(null);
				setHoEndTime4(null);
			}
		}

	}

	/**
	 *
	 * 更新ボタン
	 */
	function fncOnClickUpdate(){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		//--- データ格納
		fncSetDataShopTime();
		fncSetDataWeekTime();

		setLoading('1');

		//--- 登録
		//-- リクエストデータ
		var aryData ={
			shoptime : [],
			weektime : [],
			id : dbconn.id,
		}
		aryData.shoptime.push(fieldData);
		aryData.weektime.push(fieldWeekTime);

		document.body.style.cursor = 'wait';

		//-- POST
		Http.post('/api/upd-shoptime', {
			...aryData,
		}).then(response => {
			const strStatus = response.data.status;
			if(strStatus == '200') {
				setErrMode('0');
				if (data.mode == '1') {
					setModalMsg(MSG_CMP_ADD);
				} else if (data.mode == '2') {
					setModalMsg(MSG_CMP_UPD);
				} else if (data.mode == '3') {
					setModalMsg(MSG_CMP_DEL);
				} else if (data.mode == '4') {
					setModalMsg(MSG_CMP_ADD);
				}
				setShow(true);
			}else if(strStatus == '201'){
				setErrMode('0');
				if(data.mode == '1'){
					setModalMsg(MSG_CMP_ADD + MSG_UPD_SHOP_SALE);
				}else if(data.mode == '2'){
					setModalMsg(MSG_CMP_UPD);
				}else if(data.mode == '4') {
					setModalMsg(MSG_CMP_ADD + MSG_UPD_SHOP_SALE);
				}
				setShow(true);
			}else if(strStatus == '300'){
				//-- 営業時間
				setErrMode('1');
				setModalMsg(MSG_NG_TIME);
				setShow(true);
			}else if(strStatus == '301') {
				//-- 適用日
				setErrMode('1');
				setModalMsg(MSG_NG_DUP_DATE);
				setShow(true);
			}else if(strStatus == '302') {
				//-- 予約済み
				setErrMode('1');
				setModalMsg(MSG_NG_DEL_RESERVE);
				setShow(true);
			}else if(strStatus == '303') {
				//-- 曜日別未入力
				setErrMode('1');
				setModalMsg(MSG_NG_SHOP_WEEK_INPUT);
				setShow(true);
			}else if(strStatus == '422') {
				setErrMode('1');
				setErrors(response.data.errors);
				window.scrollTo(0, 0);
			}else{
				const strError = response.data.error;
				setErrMode('1');
				if(data.mode == '1'){
					setModalMsg(MSG_NG_ADD + strError);
				}else if(data.mode == '2'){
					setModalMsg(MSG_NG_UPD + strError);
				}else if(data.mode == '3'){
					setModalMsg(MSG_NG_DEL + strError);
				}else if(data.mode == '4'){
					setModalMsg(MSG_NG_ADD + strError);
				}
				setShow(true);
			}
			//setShow(true);
		}).catch(error => {
			if (error.response) {
				setErrMode('1');
				var strErrMng = '';
				if(data.mode == '1'){
					strErrMng = MSG_NG_ADD;
				}else if(data.mode == '2'){
					strErrMng = MSG_NG_UPD;
				}else if(data.mode == '3'){
					strErrMng = MSG_NG_DEL;
				}else if(data.mode == '4'){
					strErrMng = MSG_NG_ADD;
				}
				strErrMng += error.response.data;
				setModalMsg(strErrMng);
				//alert(error.response.data)
				setShow(true);
			}
		}).finally(() => {
			setLoading('0');
			document.body.style.cursor = 'auto';
		})
	}

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);
		if(errMode == '0'){
			submitReturn();
		}
	}

	/**
	 * 店舗時間情報格納
	 */
	function fncSetDataShopTime(){
		//---
		//-- 適用開始日
		if(startDate != '' && startDate != null){
			fieldData.start_date = startDate.getFullYear() + '/' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '/' +  ('0' + startDate.getDate()).slice(-2);
		}else{
			fieldData.start_date = '';
		}
		//-- 適用終了日
		if(endDate != '' && endDate != null){
			fieldData.end_date = endDate.getFullYear() + '/' + ('0' + (endDate.getMonth() + 1)).slice(-2) + '/' +  ('0' + endDate.getDate()).slice(-2);
		}else{
			fieldData.end_date = '';
		}
		//-- 曜日別締切時間
		if(weekLimitTime != '' && weekLimitTime != null){
			fieldData.week_reserve_limit_time = ('0' + weekLimitTime.getHours()).slice(-2) + ':' + ('0' + weekLimitTime.getMinutes()).slice(-2);
		}else{
			fieldData.week_reserve_limit_time = '';
		}
		//-- 営業開始時間1
		if(baseStartTime1 != '' && baseStartTime1 != null){
			fieldData.open_time1 = ('0' + baseStartTime1.getHours()).slice(-2) + ':' + ('0' + baseStartTime1.getMinutes()).slice(-2);
		}else{
			fieldData.open_time1 = '';
		}
		//-- 営業終了時間1
		if(baseEndTime1 != '' && baseEndTime1 != null){
			fieldData.close_time1 = ('0' + baseEndTime1.getHours()).slice(-2) + ':' + ('0' + baseEndTime1.getMinutes()).slice(-2);
		}else{
			fieldData.close_time1 = '';
		}
		//-- 営業開始時間2
		if(baseStartTime2 != '' && baseStartTime2 != null){
			fieldData.open_time2 = ('0' + baseStartTime2.getHours()).slice(-2) + ':' + ('0' + baseStartTime2.getMinutes()).slice(-2);
		}else{
			fieldData.open_time2 = '';
		}
		//-- 営業終了時間2
		if(baseEndTime2 != '' && baseEndTime2 != null){
			fieldData.close_time2 = ('0' + baseEndTime2.getHours()).slice(-2) + ':' + ('0' + baseEndTime2.getMinutes()).slice(-2);
		}else{
			fieldData.close_time2 = '';
		}
		//-- 営業開始時間3
		if(baseStartTime3 != '' && baseStartTime3 != null){
			fieldData.open_time3 = ('0' + baseStartTime3.getHours()).slice(-2) + ':' + ('0' + baseStartTime3.getMinutes()).slice(-2);
		}else{
			fieldData.open_time3 = '';
		}
		//-- 営業終了時間3
		if(baseEndTime3 != '' && baseEndTime3 != null){
			fieldData.close_time3 = ('0' + baseEndTime3.getHours()).slice(-2) + ':' + ('0' + baseEndTime3.getMinutes()).slice(-2);
		}else{
			fieldData.close_time3 = '';
		}
		//-- 営業開始時間4
		if(baseStartTime4 != '' && baseStartTime4 != null){
			fieldData.open_time4 = ('0' + baseStartTime4.getHours()).slice(-2) + ':' + ('0' + baseStartTime4.getMinutes()).slice(-2);
		}else{
			fieldData.open_time4 = '';
		}
		//-- 営業終了時間4
		if(baseEndTime4 != '' && baseEndTime4 != null){
			fieldData.close_time4 = ('0' + baseEndTime4.getHours()).slice(-2) + ':' + ('0' + baseEndTime4.getMinutes()).slice(-2);
		}else{
			fieldData.close_time4 = '';
		}
		//-- 更新者ID
		fieldData.update_id = user.staff_id;
		//-- モード
		fieldData.mode = data.mode;
	}

	/**
	 * 店舗曜日別時間格納
	 */
	function fncSetDataWeekTime() {
		//--- 共通
		var strStartDate = '';
		var strEndDate = '';
		//-- 開始日
		if(startDate != '' && startDate != null){
			strStartDate = startDate.getFullYear() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getDate();
		}
		//-- 終了日
		if(endDate != '' && endDate != null){
			strEndDate = endDate.getFullYear() + '/' + (endDate.getMonth() + 1) + '/' + endDate.getDate();
		}

		//--- 月
		//-- 店舗ID
        fieldWeekTime[0].shop_id = shop.shop_id;
		//-- 適用開始日
        fieldWeekTime[0].start_date = strStartDate;
		//-- 適用終了日
        fieldWeekTime[0].end_date = strEndDate;
		//-- 曜日区分
        fieldWeekTime[0].week_kbn = '0';
		//-- 休業区分
        fieldWeekTime[0].regular_holiday = fieldHoliday.regular_holiday1;
		//-- 営業開始時間1
		if(moStartTime1 != '' && moStartTime1 != null){
            fieldWeekTime[0].open_time1 = ('0' + moStartTime1.getHours()).slice(-2) + ':' +  ('0' + moStartTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[0].open_time1 = '';
		}
		//-- 営業終了時間1
		if(moEndTime1 != '' && moEndTime1 != null){
            fieldWeekTime[0].close_time1 = ('0' + moEndTime1.getHours()).slice(-2) + ':' + ('0' + moEndTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[0].close_time1 = '';
		}
		//-- 営業開始時間2
		if(moStartTime2 != '' && moStartTime2 != null){
            fieldWeekTime[0].open_time2 = ('0' + moStartTime2.getHours()).slice(-2) + ':' + ('0' + moStartTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[0].open_time2 = '';
		}
		//-- 営業終了時間2
		if(moEndTime2 != '' && moEndTime2 != null){
            fieldWeekTime[0].close_time2 = ('0' + moEndTime2.getHours()).slice(-2) + ':' + ('0' + moEndTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[0].close_time2 = '';
		}
		//-- 営業開始時間3
		if(moStartTime3 != '' && moStartTime3 != null){
            fieldWeekTime[0].open_time3 = ('0' + moStartTime3.getHours()).slice(-2) + ':' + ('0' + moStartTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[0].open_time3 = '';
		}
		//-- 営業終了時間3
		if(moEndTime3 != '' && moEndTime3 != null){
            fieldWeekTime[0].close_time3 = ('0' + moEndTime3.getHours()).slice(-2) + ':' + ('0' + moEndTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[0].close_time3 = '';
		}
		//-- 営業開始時間4
		if(moStartTime4 != '' && moStartTime4 != null){
            fieldWeekTime[0].open_time4 = ('0' + moStartTime4.getHours()).slice(-2) + ':' + ('0' + moStartTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[0].open_time4 = '';
		}
		//-- 営業終了時間4
		if(moEndTime4 != '' && moEndTime4 != null){
            fieldWeekTime[0].close_time4 = ('0' + moEndTime4.getHours()).slice(-2) + ':' + ('0' + moEndTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[0].close_time4 = '';
		}
		//-- 更新者ID
        fieldWeekTime[0].update_id = user.staff_id;

		//--- 火
		//-- 店舗ID
        fieldWeekTime[1].shop_id = shop.shop_id;
		//-- 適用開始日
        fieldWeekTime[1].start_date = strStartDate;
		//-- 適用終了日
        fieldWeekTime[1].end_date = strEndDate
		//-- 曜日区分
        fieldWeekTime[1].week_kbn = '1';
		//-- 休業区分
        fieldWeekTime[1].regular_holiday = fieldHoliday.regular_holiday2;
		//-- 営業開始時間1
		if(tuStartTime1 != '' && tuStartTime1 != null){
            fieldWeekTime[1].open_time1 = ('0' + tuStartTime1.getHours()).slice(-2) + ':' + ('0' + tuStartTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[1].open_time1 = '';
		}
		//-- 営業終了時間1
		if(tuEndTime1 != '' && tuEndTime1 != null){
            fieldWeekTime[1].close_time1 = ('0' + tuEndTime1.getHours()).slice(-2) + ':' + ('0' + tuEndTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[1].close_time1 = '';
		}
		//-- 営業開始時間2
		if(tuStartTime2 != '' && tuStartTime2 != null){
            fieldWeekTime[1].open_time2 = ('0' + tuStartTime2.getHours()).slice(-2) + ':' + ('0' + tuStartTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[1].open_time2 = '';
		}
		//-- 営業終了時間2
		if(tuEndTime2 != '' && tuEndTime2 != null){
            fieldWeekTime[1].close_time2 = ('0' + tuEndTime2.getHours()).slice(-2) + ':' + ('0' + tuEndTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[1].close_time2 = '';
		}
		//-- 営業開始時間3
		if(tuStartTime3 != '' && tuStartTime3 != null){
            fieldWeekTime[1].open_time3 = ('0' + tuStartTime3.getHours()).slice(-2) + ':' + ('0' + tuStartTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[1].open_time3 = '';
		}
		//-- 営業終了時間3
		if(tuEndTime3 != '' && tuEndTime3 != null){
            fieldWeekTime[1].close_time3 = ('0' + tuEndTime3.getHours()).slice(-2) + ':' + ('0' + tuEndTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[1].close_time3 = '';
		}
		//-- 営業開始時間4
		if(tuStartTime4 != '' && tuStartTime4 != null){
            fieldWeekTime[1].open_time4 = ('0' + tuStartTime4.getHours()).slice(-2) + ':' + ('0' + tuStartTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[1].open_time4 = '';
		}
		//-- 営業終了時間4
		if(tuEndTime4 != '' && tuEndTime4 != null){
            fieldWeekTime[1].close_time4 = ('0' + tuEndTime4.getHours()).slice(-2) + ':' + ('0' + tuEndTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[1].close_time4 = '';
		}
		//-- 更新者ID
        fieldWeekTime[1].update_id = user.staff_id;

		//--- 水
		//-- 店舗ID
        fieldWeekTime[2].shop_id = shop.shop_id;
		//-- 適用開始日
        fieldWeekTime[2].start_date = strStartDate;
		//-- 適用終了日
        fieldWeekTime[2].end_date = strEndDate;
		//-- 曜日区分
        fieldWeekTime[2].week_kbn = '2';
		//-- 休業区分
        fieldWeekTime[2].regular_holiday = fieldHoliday.regular_holiday3;
		//-- 営業開始時間1
		if(weStartTime1 != '' && weStartTime1 != null){
            fieldWeekTime[2].open_time1 = ('0' + weStartTime1.getHours()).slice(-2) + ':' + ('0' + weStartTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[2].open_time1 = '';
		}
		//-- 営業終了時間1
		if(weEndTime1 != '' && weEndTime1 != null){
            fieldWeekTime[2].close_time1 = ('0' + weEndTime1.getHours()).slice(-2) + ':' + ('0' + weEndTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[2].close_time1 = '';
		}
		//-- 営業開始時間2
		if(weStartTime2 != '' && weStartTime2 != null){
            fieldWeekTime[2].open_time2 = ('0' + weStartTime2.getHours()).slice(-2) + ':' + ('0' + weStartTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[2].open_time2 = '';
		}
		//-- 営業終了時間2
		if(weEndTime2 != '' && weEndTime2 != null){
            fieldWeekTime[2].close_time2 = ('0' + weEndTime2.getHours()).slice(-2) + ':' + ('0' + weEndTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[2].close_time2 = '';
		}
		//-- 営業開始時間3
		if(weStartTime3 != '' && weStartTime3 != null){
            fieldWeekTime[2].open_time3 = ('0' + weStartTime3.getHours()).slice(-2) + ':' + ('0' + weStartTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[2].open_time3 = '';
		}
		//-- 営業終了時間3
		if(weEndTime3 != '' && weEndTime3 != null){
            fieldWeekTime[2].close_time3 = ('0' + weEndTime3.getHours()).slice(-2) + ':' + ('0' + weEndTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[2].close_time3 = '';
		}
		//-- 営業開始時間4
		if(weStartTime4 != '' && weStartTime4 != null){
            fieldWeekTime[2].open_time4 = ('0' + weStartTime4.getHours()).slice(-2) + ':' + ('0' + weStartTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[2].open_time4 = '';
		}
		//-- 営業終了時間4
		if(weEndTime4 != '' && weEndTime4 != null){
            fieldWeekTime[2].close_time4 = ('0' + weEndTime4.getHours()).slice(-2) + ':' + ('0' + weEndTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[2].close_time4 = '';
		}
		//-- 更新者ID
        fieldWeekTime[2].update_id = user.staff_id;

		//--- 木
		//-- 店舗ID
        fieldWeekTime[3].shop_id = shop.shop_id;
		//-- 適用開始日
        fieldWeekTime[3].start_date = strStartDate;
		//-- 適用終了日
        fieldWeekTime[3].end_date = strEndDate;
		//-- 曜日区分
        fieldWeekTime[3].week_kbn = '3';
		//-- 休業区分
        fieldWeekTime[3].regular_holiday = fieldHoliday.regular_holiday4;
		//-- 営業開始時間1
		if(thStartTime1 != '' && thStartTime1 != null){
            fieldWeekTime[3].open_time1 = ('0' + thStartTime1.getHours()).slice(-2) + ':' + ('0' + thStartTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[3].open_time1 = '';
		}
		//-- 営業終了時間1
		if(thEndTime1 != '' && thEndTime1 != null){
            fieldWeekTime[3].close_time1 = ('0' + thEndTime1.getHours()).slice(-2) + ':' + ('0' + thEndTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[3].close_time1 = '';
		}
		//-- 営業開始時間2
		if(thStartTime2 != '' && thStartTime2 != null){
            fieldWeekTime[3].open_time2 = ('0' + thStartTime2.getHours()).slice(-2) + ':' + ('0' + thStartTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[3].open_time2 = '';
		}
		//-- 営業終了時間2
		if(thEndTime2 != '' && thEndTime2 != null){
            fieldWeekTime[3].close_time2 = ('0' + thEndTime2.getHours()).slice(-2) + ':' + ('0' + thEndTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[3].close_time2 = '';
		}
		//-- 営業開始時間3
		if(thStartTime3 != '' && thStartTime3 != null){
            fieldWeekTime[3].open_time3 = ('0' + thStartTime3.getHours()).slice(-2) + ':' + ('0' + thStartTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[3].open_time3 = '';
		}
		//-- 営業終了時間3
		if(thEndTime3 != '' && thEndTime3 != null){
            fieldWeekTime[3].close_time3 = ('0' + thEndTime3.getHours()).slice(-2) + ':' + ('0' + thEndTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[3].close_time3 = '';
		}
		//-- 営業開始時間4
		if(thStartTime4 != '' && thStartTime4 != null){
            fieldWeekTime[3].open_time4 = ('0' + thStartTime4.getHours()).slice(-2) + ':' + ('0' + thStartTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[3].open_time4 = '';
		}
		//-- 営業終了時間4
		if(thEndTime4 != '' && thEndTime4 != null){
            fieldWeekTime[3].close_time4 = ('0' + thEndTime4.getHours()).slice(-2) + ':' + ('0' + thEndTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[3].close_time4 = '';
		}
		//-- 更新者ID
        fieldWeekTime[3].update_id = user.staff_id;

		//--- 金
		//-- 店舗ID
        fieldWeekTime[4].shop_id = shop.shop_id;
		//-- 適用開始日
        fieldWeekTime[4].start_date = strStartDate;
		//-- 適用終了日
        fieldWeekTime[4].end_date = strEndDate;
		//-- 曜日区分
        fieldWeekTime[4].week_kbn = '4';
		//-- 休業区分
        fieldWeekTime[4].regular_holiday = fieldHoliday.regular_holiday5;
		//-- 営業開始時間1
		if(frStartTime1 != '' && frStartTime1 != null){
            fieldWeekTime[4].open_time1 = ('0' + frStartTime1.getHours()).slice(-2) + ':' + ('0' + frStartTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[4].open_time1 = '';
		}
		//-- 営業終了時間1
		if(frEndTime1 != '' && frEndTime1 != null){
            fieldWeekTime[4].close_time1 = ('0' + frEndTime1.getHours()).slice(-2) + ':' + ('0' + frEndTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[4].close_time1 = '';
		}
		//-- 営業開始時間2
		if(frStartTime2 != '' && frStartTime2 != null){
            fieldWeekTime[4].open_time2 = ('0' + frStartTime2.getHours()).slice(-2) + ':' + ('0' + frStartTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[4].open_time2 = '';
		}
		//-- 営業終了時間2
		if(frEndTime2 != '' && frEndTime2 != null){
            fieldWeekTime[4].close_time2 = ('0' + frEndTime2.getHours()).slice(-2) + ':' + ('0' + frEndTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[4].close_time2 = '';
		}
		//-- 営業開始時間3
		if(frStartTime3 != '' && frStartTime3 != null){
            fieldWeekTime[4].open_time3 = ('0' + frStartTime3.getHours()).slice(-2) + ':' + ('0' + frStartTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[4].open_time3 = '';
		}
		//-- 営業終了時間3
		if(frEndTime3 != '' && frEndTime3 != null){
            fieldWeekTime[4].close_time3 = ('0' + frEndTime3.getHours()).slice(-2) + ':' + ('0' + frEndTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[4].close_time3 = '';
		}
		//-- 営業開始時間4
		if(frStartTime4 != '' && frStartTime4 != null){
            fieldWeekTime[4].open_time4 = ('0' + frStartTime4.getHours()).slice(-2) + ':' + ('0' + frStartTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[4].open_time4 = '';
		}
		//-- 営業終了時間4
		if(frEndTime4 != '' && frEndTime4 != null){
            fieldWeekTime[4].close_time4 = ('0' + frEndTime4.getHours()).slice(-2) + ':' + ('0' + frEndTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[4].close_time4 = '';
		}
		//-- 更新者ID
        fieldWeekTime[4].update_id = user.staff_id;

		//--- 土
		//-- 店舗ID
        fieldWeekTime[5].shop_id = shop.shop_id;
		//-- 適用開始日
        fieldWeekTime[5].start_date = strStartDate;
		//-- 適用終了日
        fieldWeekTime[5].end_date = strEndDate;
		//-- 曜日区分
        fieldWeekTime[5].week_kbn = '5';
		//-- 休業区分
        fieldWeekTime[5].regular_holiday = fieldHoliday.regular_holiday6;
		//-- 営業開始時間1
		if(saStartTime1 != '' && saStartTime1 != null){
            fieldWeekTime[5].open_time1 = ('0' + saStartTime1.getHours()).slice(-2) + ':' + ('0' + saStartTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[5].open_time1 = '';
		}
		//-- 営業終了時間1
		if(saEndTime1 != '' && saEndTime1 != null){
            fieldWeekTime[5].close_time1 = ('0' + saEndTime1.getHours()).slice(-2) + ':' + ('0' + saEndTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[5].close_time1 = '';
		}
		//-- 営業開始時間2
		if(saStartTime2 != '' && saStartTime2 != null){
            fieldWeekTime[5].open_time2 = ('0' + saStartTime2.getHours()).slice(-2) + ':' + ('0' + saStartTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[5].open_time2 = '';
		}
		//-- 営業終了時間2
		if(saEndTime2 != '' && saEndTime2 != null){
            fieldWeekTime[5].close_time2 = ('0' + saEndTime2.getHours()).slice(-2) + ':' + ('0' + saEndTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[5].close_time2 = '';
		}
		//-- 営業開始時間3
		if(saStartTime3 != '' && saStartTime3 != null){
            fieldWeekTime[5].open_time3 = ('0' + saStartTime3.getHours()).slice(-2) + ':' + ('0' + saStartTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[5].open_time3 = '';
		}
		//-- 営業終了時間3
		if(saEndTime3 != '' && saEndTime3 != null){
            fieldWeekTime[5].close_time3 = ('0' + saEndTime3.getHours()).slice(-2) + ':' + ('0' + saEndTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[5].close_time3 = '';
		}
		//-- 営業開始時間4
		if(saStartTime4 != '' && saStartTime4 != null){
            fieldWeekTime[5].open_time4 = ('0' + saStartTime4.getHours()).slice(-2) + ':' + ('0' + saStartTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[5].open_time4 = '';
		}
		//-- 営業終了時間4
		if(saEndTime4 != '' && saEndTime4 != null){
            fieldWeekTime[5].close_time4 = ('0' + saEndTime4.getHours()).slice(-2) + ':' + ('0' + saEndTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[5].close_time4 = '';
		}
		//-- 更新者ID
        fieldWeekTime[5].update_id = user.staff_id;

		//--- 日
		//-- 店舗ID
        fieldWeekTime[6].shop_id = shop.shop_id;
		//-- 適用開始日
        fieldWeekTime[6].start_date = strStartDate;
		//-- 適用終了日
        fieldWeekTime[6].end_date = strEndDate;
		//-- 曜日区分
        fieldWeekTime[6].week_kbn = '6';
		//-- 休業区分
        fieldWeekTime[6].regular_holiday = fieldHoliday.regular_holiday7;
		//-- 営業開始時間1
		if(suStartTime1 != '' && suStartTime1 != null){
            fieldWeekTime[6].open_time1 = ('0' + suStartTime1.getHours()).slice(-2) + ':' + ('0' + suStartTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[6].open_time1 = '';
		}
		//-- 営業終了時間1
		if(suEndTime1 != '' && suEndTime1 != null){
            fieldWeekTime[6].close_time1 = ('0' + suEndTime1.getHours()).slice(-2) + ':' + ('0' + suEndTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[6].close_time1 = '';
		}
		//-- 営業開始時間2
		if(suStartTime2 != '' && suStartTime2 != null){
            fieldWeekTime[6].open_time2 = ('0' + suStartTime2.getHours()).slice(-2) + ':' + ('0' + suStartTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[6].open_time2 = '';
		}
		//-- 営業終了時間2
		if(suEndTime2 != '' && suEndTime2 != null){
            fieldWeekTime[6].close_time2 = ('0' + suEndTime2.getHours()).slice(-2) + ':' + ('0' + suEndTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[6].close_time2 = '';
		}
		//-- 営業開始時間3
		if(suStartTime3 != '' && suStartTime3 != null){
            fieldWeekTime[6].open_time3 = ('0' + suStartTime3.getHours()).slice(-2) + ':' + ('0' + suStartTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[6].open_time3 = '';
		}
		//-- 営業終了時間3
		if(suEndTime3 != '' && suEndTime3 != null){
            fieldWeekTime[6].close_time3 = ('0' + suEndTime3.getHours()).slice(-2) + ':' + ('0' + suEndTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[6].close_time3 = '';
		}
		//-- 営業開始時間4
		if(suStartTime4 != '' && suStartTime4 != null){
            fieldWeekTime[6].open_time4 = ('0' + suStartTime4.getHours()).slice(-2) + ':' + ('0' + suStartTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[6].open_time4 = '';
		}
		//-- 営業終了時間4
		if(suEndTime4 != '' && suEndTime4 != null){
            fieldWeekTime[6].close_time4 = ('0' + suEndTime4.getHours()).slice(-2) + ':' + ('0' + suEndTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[6].close_time4 = '';
		}
		//-- 更新者ID
        fieldWeekTime[6].update_id = user.staff_id;

		//--- 祝
		//-- 店舗ID
        fieldWeekTime[7].shop_id = shop.shop_id;
		//-- 適用開始日
        fieldWeekTime[7].start_date = strStartDate;
		//-- 適用終了日
        fieldWeekTime[7].end_date = strEndDate;
		//-- 曜日区分
        fieldWeekTime[7].week_kbn = '7';
		//-- 休業区分
        fieldWeekTime[7].regular_holiday = fieldHoliday.regular_holiday8;
		//-- 営業開始時間1
		if(hoStartTime1 != '' && hoStartTime1 != null){
            fieldWeekTime[7].open_time1 = ('0' + hoStartTime1.getHours()).slice(-2) + ':' + ('0' + hoStartTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[7].open_time1 = '';
		}
		//-- 営業終了時間1
		if(hoEndTime1 != '' && hoEndTime1 != null){
            fieldWeekTime[7].close_time1 = ('0' + hoEndTime1.getHours()).slice(-2) + ':' + ('0' + hoEndTime1.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[7].close_time1 = '';
		}
		//-- 営業開始時間2
		if(hoStartTime2 != '' && hoStartTime2 != null){
            fieldWeekTime[7].open_time2 = ('0' + hoStartTime2.getHours()).slice(-2) + ':' + ('0' + hoStartTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[7].open_time2 = '';
		}
		//-- 営業終了時間2
		if(hoEndTime2 != '' && hoEndTime2 != null){
            fieldWeekTime[7].close_time2 = ('0' + hoEndTime2.getHours()).slice(-2) + ':' + ('0' + hoEndTime2.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[7].close_time2 = '';
		}
		//-- 営業開始時間3
		if(hoStartTime3 != '' && hoStartTime3 != null){
            fieldWeekTime[7].open_time3 = ('0' + hoStartTime3.getHours()).slice(-2) + ':' + ('0' + hoStartTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[7].open_time3 = '';
		}
		//-- 営業終了時間3
		if(hoEndTime3 != '' && hoEndTime3 != null){
            fieldWeekTime[7].close_time3 = ('0' + hoEndTime3.getHours()).slice(-2) + ':' + ('0' + hoEndTime3.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[7].close_time3 = '';
		}
		//-- 営業開始時間4
		if(hoStartTime4 != '' && hoStartTime4 != null){
            fieldWeekTime[7].open_time4 = ('0' + hoStartTime4.getHours()).slice(-2) + ':' + ('0' + hoStartTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[7].open_time4 = '';
		}
		//-- 営業終了時間4
		if(hoEndTime4 != '' && hoEndTime4 != null){
            fieldWeekTime[7].close_time4 = ('0' + hoEndTime4.getHours()).slice(-2) + ':' + ('0' + hoEndTime4.getMinutes()).slice(-2);
		}else{
            fieldWeekTime[7].close_time4 = '';
		}
		//-- 更新者ID
        fieldWeekTime[7].update_id = user.staff_id;
	}

	/**
	 * 設定した適用開始日を適用終了日の初期値にする
	 * @param e
	 */
	function fncSetEndDate(e)
	{
		//--- 開始日の格納
		setStartDate(e);
		//--- 終了日の格納
		setEndDate(e);
	}

	/**
	 * 適用開始日カーソル移動
	 */
	function fncSetStartDateFocus(){
		document.getElementById('dtStartDate').focus();
	}

	/**
	 * 適用終了日カーソル移動
	 */
	function fncSetEndDateFocus(){
		document.getElementById('dtEndDate').focus();
	}

	return (
		<>
			<Form className="shop-time-form" >
				<Row className="shop-time-form-row">
					<Col md={{ span: 11}}>
					</Col>
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
				</Row>
				<Row className="shop-time-form-row">
					<div className={"div-shop-time-title"} >
						<label className={"label-shop-time-padding"}>店舗名</label>
					</div>
					<FormControl
						className="shop-time-form-input-field"
						type="text"
						value={shop.shop_nm}
						name="shop_nm"
						readOnly="readOnly"
					/>
				</Row>
				<div className={"div-shop-time-main"}>
					<div className={"div-shop-time-left"}>
						<Row className="shop-time-form-row">
							<div className={"div-shop-time-title"} >
								<label className="label-shop-time-padding">適用開始日</label>
							</div>
							<div className={"div-date-picker-width"}>
								<DatePicker
									className="date-picker"
									showPopperArrow={false}
									selected={startDate}
									onChange={date => fncSetEndDate(date)}
									dateFormat="yyyy/MM/dd"
									customInput={<InputMask mask="9999/99/99" maskChar={null} />}
									disabled={data.mode == '3'? 'disabled' : ''}
									id={"dtStartDate"}
									renderCustomHeader={({
															 date,
															 changeYear,
															 changeMonth,
														 }) =>(
										<div
											className={"div-date-picker"}
										>
											<select
												className={"select-date-picker"}
												value={date.getFullYear()}
												onChange={({target:{value}}) => changeYear(value)}
											>
												{shopyears.map(option => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>
											<select
												className={"select-date-picker"}
												value={months[date.getMonth()]}
												onChange={({ target: { value } }) =>
													changeMonth(months.indexOf(value))
												}
											>
												{months.map(option => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>
										</div>
									)}
								/>
							</div>
							<div className={"div-calendar-icon"}>
								<a onClick={fncSetStartDateFocus}>
									<IoIosCalendar/>
								</a>
							</div>

							<div className={"div-shop-time-title-end"} >
								<label className="label-shop-time-padding">適用終了日</label>
							</div>
							<div className={"div-date-picker-width"}>
								<DatePicker
									className="date-picker"
									showPopperArrow={false}
									selected={endDate}
									onChange={date => setEndDate(date)}
									dateFormat="yyyy/MM/dd"
									customInput={<InputMask mask="9999/99/99" maskChar={null} />}
									disabled={data.mode == '3'? 'disabled' : ''}
									id={"dtEndDate"}
									renderCustomHeader={({
															 date,
															 changeYear,
															 changeMonth,
														 }) =>(
										<div
											className={"div-date-picker"}
										>
											<select
												className={"select-date-picker"}
												value={date.getFullYear()}
												onChange={({target:{value}}) => changeYear(value)}
											>
												{shopyears.map(option => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>
											<select
												className={"select-date-picker"}
												value={months[date.getMonth()]}
												onChange={({ target: { value } }) =>
													changeMonth(months.indexOf(value))
												}
											>
												{months.map(option => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>
										</div>
									)}
								/>
							</div>
							<div className={"div-calendar-icon"}>
								<a onClick={fncSetEndDateFocus}>
									<IoIosCalendar/>
								</a>
							</div>
						</Row>
						{(errors.start_date || errors.end_date) && (
							<Row className="shop-time-form-row">
								<div className={"div-shop-time-title"} ></div>
								<div className={"div-shop-time-err-width"}>
									{errors.start_date && (
										<span className="error-span">{errors.start_date[0]}</span>
									)}
								</div>
								<div className={"div-shop-time-title-err"} ></div>
								<div className={"div-shop-time-err-width"}>
									{errors.end_date && (
										<span className="error-span">{errors.end_date[0]}</span>
									)}
								</div>
							</Row>
						)}

						<Row className="shop-time-form-row">
							<div className={"div-shop-time-title"} >
								<label className={"label-shop-time-padding"}>予約時間間隔</label>
							</div>
							<div className={"div-shop-time-cmb-width"}>
								<Form.Control
									as="select"
									className="sel-interval"
									name='reserve_interval'
									value={fieldData.reserve_interval}
									onChange={handleDataFormat}
									disabled={data.mode == '3'? 'disabled' : ''}
								>
									{fncSetInterval()}
								</Form.Control>
							</div>
							<div>
								<label className={"label-right-marge shop-time-right-label-time"}>分</label>
							</div>
						</Row>
						{(errors.reserve_interval) && (
							<Row className="shop-time-form-row">
								<div className={"div-shop-time-title"} ></div>
								<div className={"div-shop-time-err-width"}>
									{errors.reserve_interval && (
										<span className="error-span">{errors.reserve_interval[0]}</span>
									)}
								</div>
							</Row>
						)}

						<Row className="shop-time-form-row">
							<div className={"div-shop-time-title"} >
								<label className={"label-shop-time-padding"}>予約可能期間</label>
							</div>
							<div className={"div-shop-time-cmb-width"}>
								<Form.Control
									as="select"
									className="sel-weekcnt"
									name='reserve_week_cnt'
									value={fieldData.reserve_week_cnt}
									onChange={handleDataFormat}
									disabled={data.mode == '3'? 'disabled' : ''}
								>
									{fncSetWeekCnt()}
								</Form.Control>
							</div>
							<div>
								<label className={"label-right-marge shop-time-right-label-week"}>週間</label>
							</div>

						</Row>
						{errors.reserve_week_cnt && (
							<Row className="shop-time-form-row">
								<div className={"div-shop-time-title"} ></div>
								<div>
									<span className="error-span">{errors.reserve_week_cnt[0]}</span>
								</div>
							</Row>
						)}

						<Row className="shop-time-form-row">
							<div className={"div-shop-time-title"} >
								<label className={"label-shop-time-padding"}>予約回数制限</label>
							</div>
							<div className={"div-shop-time-cmb-width"}>
								<Form.Control
									as="select"
									className="sel-limit"
									name='reserve_cnt_week_kbn'
									value={fieldData.reserve_cnt_week_kbn}
									onChange={handleDataFormat}
									disabled={data.mode == '3'? 'disabled' : ''}
								>
									{fncSetLimitWeek()}
								</Form.Control>
							</div>
							<div>
								<label className={"label-right-marge shop-time-right-label-week-cnt"}>に</label>
							</div>
							<div className={"div-shop-time-cnt-cmb-width"}>
								<Form.Control
									as="select"
									className="sel-limit"
									name='reserve_cnt_kbn'
									value={fieldData.reserve_cnt_kbn}
									onChange={handleDataFormat}
									disabled={data.mode == '3'? 'disabled' : ''}
								>
									{fncSetLimitCnt()}
								</Form.Control>
							</div>
							<div>
								<label className={"label-right-marge shop-time-right-label-cnt"}>回まで</label>
							</div>
						</Row>

						{errors.limit_cnt && (
							<Row className="shop-time-form-row">
								<div className={"div-shop-time-title"} ></div>
								<div>
									<span className="error-span">{errors.limit_cnt[0]}</span>
								</div>
							</Row>
						)}

						<Row className="shop-time-form-row">
							<div className={"div-shop-time-title"} >
								<label className={"label-shop-time-padding"}>最大予約回数</label>
							</div>
							<div className={"div-shop-time-cmb-width"}>
								<Form.Control
									as="select"
									className="sel-limit"
									name='reserve_max_cnt_kbn'
									value={fieldData.reserve_max_cnt_kbn}
									onChange={handleDataFormat}
									disabled={data.mode == '3'? 'disabled' : ''}
								>
									{fncSetLimitCnt()}
								</Form.Control>
							</div>
							<div>
								<label className={"label-right-marge shop-time-right-label-cnt"}>回まで</label>
							</div>
							<div className={"pc"}>
								<label className={"label-right-marge"}>　(一人の会員様が同時に予約できる回数を指定します。)</label>
							</div>
						</Row>

						<Row className="sp shop-time-form-row">
							<div className={"div-shop-time-limit-max-msg"} >
								<label className={""}>(一人の会員様が同時に予約できる回数を指定します。)</label>
							</div>
						</Row>

						<Row className="shop-time-form-row">
							<div className={"div-shop-time-message"}>
								<label className={"label-shop-time-padding"}>{MSG_INFO_DATE_INPUT}</label>
							</div>
						</Row>
					</div>

					<div className={"div-shop-time-right"}>
						<div className={"div-shop-time-right-limit"}>
							<Row className="shop-time-form-row">
								<div className={"div-shop-time-title-limit"} >
									<label className={"label-shop-time-padding"}>予約締切時間</label>
								</div>
							</Row>
							<Row className="shop-time-form-row">
									<div className={"div-shop-time-title-limit-base "} >
										<label className={"label-shop-time-padding"}>基本設定</label>
									</div>
									<div className={"div-shop-time-day-cmb-width "}>
										<Form.Control
											as="select"
											className="sel-limit-day"
											name='reserve_limit_day'
											value={fieldData.reserve_limit_day}
											onChange={handleDataFormat}
											disabled={data.mode == '3'? 'disabled' : ''}
										>
											{fncSetLimitDay()}
										</Form.Control>
									</div>
									<div className={"div-shop-time-cmb-width "}>
										<Form.Control
											as="select"
											className="sel-limit"
											name='reserve_limit_time'
											value={fieldData.reserve_limit_time}
											onChange={handleDataFormat}
											disabled={data.mode == '3'? 'disabled' : ''}
										>
											{fncSetLimit()}
										</Form.Control>
									</div>
									<div className={""}>
										<label className={"label-right-marge shop-time-right-label-time"}>前</label>
									</div>
							</Row>

                            {(errors.reserve_limit_time) && (
                                <Row className="shop-time-form-row">
                                    <div className={"div-shop-time-limit-base-err"}>
                                        {errors.reserve_limit_time && (
                                            <span className="error-span">{errors.reserve_limit_time[0]}</span>
                                        )}
                                    </div>
                                </Row>
                            )}

							<Row className="shop-time-form-row">
								<div className={"div-shop-time-title-limit-base"} >
									<label className={"label-shop-time-padding"}>曜日別設定</label>
								</div>
							</Row>
							{errors.week_limit_sel && (
								<Row className="shop-time-form-row">
									<div className={"div-shop-time-limit-week-err"} >
										<span className="error-span">{errors.week_limit_sel[0]}</span>
									</div>
								</Row>
							)}
							<Row className="shop-time-form-row-week">
								<div className={"div-shop-time-week-limit"}>
									<label className={"label-shop-time-padding"}>
										<input
											className="shop-time-checkbox"
											type="checkbox"
											value={fieldData.week_mon_limit_flg != '1'? '1' : '0'}
											checked={fieldData.week_mon_limit_flg == "1"? "checked" : ""}
											onChange={handleDataFormat}
											name="week_mon_limit_flg"
											disabled={data.mode == '3'? 'disabled' : ''}
										/> 月
									</label>
									<label>　</label>
									<label className={"label-shop-time-padding"}>
										<input
											className="shop-time-checkbox"
											type="checkbox"
											value={fieldData.week_tue_limit_flg != '1'? '1' : '0'}
											checked={fieldData.week_tue_limit_flg == "1"? "checked" : ""}
											onChange={handleDataFormat}
											name="week_tue_limit_flg"
											disabled={data.mode == '3'? 'disabled' : ''}
										/> 火
									</label>
									<label>　</label>
									<label className={"label-shop-time-padding"}>
										<input
											className="shop-time-checkbox"
											type="checkbox"
											value={fieldData.week_wed_limit_flg != '1'? '1' : '0'}
											checked={fieldData.week_wed_limit_flg == "1"? "checked" : ""}
											onChange={handleDataFormat}
											name="week_wed_limit_flg"
											disabled={data.mode == '3'? 'disabled' : ''}
										/> 水
									</label>
									<label>　</label>
									<label className={"label-shop-time-padding"}>
										<input
											className="shop-time-checkbox"
											type="checkbox"
											value={fieldData.week_thu_limit_flg != '1'? '1' : '0'}
											checked={fieldData.week_thu_limit_flg == "1"? "checked" : ""}
											onChange={handleDataFormat}
											name="week_thu_limit_flg"
											disabled={data.mode == '3'? 'disabled' : ''}
										/> 木
									</label>
									<label>　</label>
									<label className={"label-shop-time-padding"}>
										<input
											className="shop-time-checkbox"
											type="checkbox"
											value={fieldData.week_fri_limit_flg != '1'? '1' : '0'}
											checked={fieldData.week_fri_limit_flg == "1"? "checked" : ""}
											onChange={handleDataFormat}
											name="week_fri_limit_flg"
											disabled={data.mode == '3'? 'disabled' : ''}
										/> 金
									</label>
									<label>　</label>
									<label className={"label-shop-time-padding"}>
										<input
											className="shop-time-checkbox"
											type="checkbox"
											value={fieldData.week_sat_limit_flg != '1'? '1' : '0'}
											checked={fieldData.week_sat_limit_flg == "1"? "checked" : ""}
											onChange={handleDataFormat}
											name="week_sat_limit_flg"
											disabled={data.mode == '3'? 'disabled' : ''}
										/> 土
									</label>
									<label>　</label>
									<label className={"label-shop-time-padding"}>
										<input
											className="shop-time-checkbox"
											type="checkbox"
											value={fieldData.week_sun_limit_flg != '1'? '1' : '0'}
											checked={fieldData.week_sun_limit_flg == "1"? "checked" : ""}
											onChange={handleDataFormat}
											name="week_sun_limit_flg"
											disabled={data.mode == '3'? 'disabled' : ''}
										/> 日
									</label>
									<label>　</label>
									<label className={"label-shop-time-padding"}>
										<input
											className="shop-time-checkbox"
											type="checkbox"
											value={fieldData.week_hol_limit_flg != '1'? '1' : '0'}
											checked={fieldData.week_hol_limit_flg == "1"? "checked" : ""}
											onChange={handleDataFormat}
											name="week_hol_limit_flg"
											disabled={data.mode == '3'? 'disabled' : ''}
										/> 祝
									</label>
								</div>
							</Row>
							<Row className="shop-time-form-row">
								<div className={"div-shop-time-day-cmb-limit"}>
									<Form.Control
										as="select"
										className="sel-week-limit-day"
										name='week_reserve_limit_day'
										value={fieldData.week_reserve_limit_day}
										onChange={handleDataFormat}
										disabled={data.mode == '3'? 'disabled' : ''}
									>
										{fncSetLimitWeekDay()}
									</Form.Control>
								</div>
                                <div>
                                    <label className={"label-right-marge shop-time-center-label-time"}>前の</label>
                                </div>
								<TimePicker
									baseClassName="div-shop-time-week-limit-time"
									selected={weekLimitTime}
									onChange={date => setWeekLimitTime(date)}
									disabled={data.mode == '3'? 'disabled' : ''}
									id={"dtWeekLimitTime"}
								/>
							</Row>

							{errors.week_limit && (
								<Row className="shop-time-form-row">
									<div className={"div-shop-time-day-limit-err"}>
										<span className="error-span">{errors.week_limit[0]}</span>
									</div>
								</Row>
							)}
							<Row className="shop-time-form-row-msg">
								<div className={"div-shop-time-week-limit-msg"} >
									<label className={"font-size-13"}>基本設定の場合、当日の締め切りになります。<br />曜日別設定の場合、指定された予約曜日の<br />xx日前：xx時になります。</label>
								</div>
							</Row>
						</div>

					</div>
				</div>

				<Row className="shop-time-msg-row">
					<div className={"div-shop-time-message"}>
						<label className={"label-shop-time-padding font-color-red font-weight-bold"}>一度登録した営業時間は後から編集できません。</label>
						<textarea
							className={"textarea-non font-weight-bold"}
							cols="200"
							rows="10"
							readOnly="readOnly"
							value={"登録済の営業時間を変更したい場合は、「営業時間管理（日別）」から個別に行うか、" +
									"以下の手順でお願いいたします。\n\n" +
									"【営業時間変更の手順】\n" +
									"例）2020/01/01～2020/12/31までの営業時間設定を2020/10/01から変更したい場合\n" +
									"①登録済の2020/01/01～2020/12/31までの営業時間設定の[編集]ボタンを押して開く。\n" +
									"②「適用終了日」を2020/09/30に変更し、[更新]ボタンで登録する。\n" +
									"③[新規]ボタンまたは[コピー]ボタンから新しく2020/10/01からの営業時間を作成する。\n\n" +
									"上記手順で2020/10/01からの営業時間設定を新しく作成できます。"
							}
						/>
					</div>
				</Row>

				<div className="text-header first-header">▼基本営業時間</div>
				{data.mode == '1' ?
					<Row className="shop-time-form-row">
						<Col md={{span: 11}}>
							<label className="shop-time-message">※店舗の営業時間を入力し曜日別営業時間に反映してください。</label>
						</Col>
					</Row>
					:
					<></>
				}

				<Row className="shop-time-value">
					<Col md={{ span: 12}}>
						<table className={"shop-time-base"}>
							<thead>
								<tr>
									<th className={"th-base-time-0"}></th>
									<th className={"th-base-time-1"}>営業時間1</th>
									<th className={"th-base-time-2"}>営業時間2</th>
									<th className={"th-base-time-3"}>営業時間3</th>
									<th className={"th-base-time-4"}>営業時間4</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className={"td-base-time-0"}>
										<Button
											className={"btn-return"}
											onClick={fncOnClickBaseTime}
											disabled={loading == '1'? 'disabled' : data.mode == '2' || data.mode == '3' ? 'disabled' : ''}
										>
											時間<br/>反映
										</Button>
									</td>
									<WeekSet
										errors={errors}
										disabled={data.mode == '2' || data.mode == '3'? 'disabled' : ''}
										strIDBaseS="dtBaseStartTime"
										strIDBaseE="dtBaseEndTime"
										valS1={baseStartTime1} fncSetS1={date => setBaseStartTime1(date)}
										valE1={baseEndTime1}   fncSetE1={date => setBaseEndTime1(date)}
										valS2={baseStartTime2} fncSetS2={date => setBaseStartTime2(date)}
										valE2={baseEndTime2}   fncSetE2={date => setBaseEndTime2(date)}
										valS3={baseStartTime3} fncSetS3={date => setBaseStartTime3(date)}
										valE3={baseEndTime3}   fncSetE3={date => setBaseEndTime3(date)}
										valS4={baseStartTime4} fncSetS4={date => setBaseStartTime4(date)}
										valE4={baseEndTime4}   fncSetE4={date => setBaseEndTime4(date)}
									/>
								</tr>
							</tbody>
						</table>
					</Col>
				</Row>

				<div className="text-header first-header">▼曜日別営業時間</div>
				<Row className="shop-time-value">
					<Col md={{ span: 12}}>
						<table className={"shop-time-base"}>
							<thead>
								<tr>
									<th className={"th-base-yobi"}>曜</th>
									<th className={"th-base-holiday"}>休</th>
									<th className={"th-base-time-1"}>営業時間1</th>
									<th className={"th-base-time-2"}>営業時間2</th>
									<th className={"th-base-time-3"}>営業時間3</th>
									<th className={"th-base-time-4"}>営業時間4</th>
								</tr>
							</thead>
							{fncSetWeekTime()}
						</table>
					</Col>
				</Row>

				<Row className="shop-time-button-container">
					<Col md={{ span: 2, offset: 9 }}>
						<Button
							block
							className="btn-save"
							onClick={fncOnClickUpdate}
							disabled={loading == '1'? 'disabled' : ''}
						>
							{data.mode == '1'? '登 録' : data.mode == '2'? '更 新' : data.mode == '3'? '削 除' : '登 録'}
						</Button>
					</Col>
					<Col md={{ span: 1 }}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
				</Row>
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>

		</>
	);

}

export default ShopTimeAddForm;