import React, {useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import '../../../../Utils/Common/com-style.css';
import '../UserMng.css';
import Http from '../../../../Utils/Axios';
import usePost from '../../../../Utils/EndPoints/usePost';
import useHandleFormData from '../../../../Utils/FormHandlers/useHandleFormData';
import CustomModal from '../../../../Utils/Modal/CustomModal';
import CustomDatePicker from '../../../../Utils/FormHandlers/DatePicker';
import { FormInput } from '../../../../Utils/FormHandlers/HandleFormProperties';
import { Form, FormControl, Button, Col, Row } from 'react-bootstrap';
import {
	MSG_CMP_ADD,
	MSG_CMP_UPD,
	MSG_CMP_DEL,
	MSG_CMP_PW_UPD,
	MSG_NG_ADD,
	MSG_NG_UPD,
	MSG_NG_DEL, APP_TOP,
	years, months, MSG_SYSTEM_UPD_BACK
} from "../../../../Utils/Common/ComConst";
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";
import * as AutoKana from 'vanilla-autokana';
import DatePicker from "react-datepicker";
import { IoIosCalendar, IoMdTime } from "react-icons/io";
import PasswordForm from '../../../../Utils/FormHandlers/PasswordForm';

const dtNow = new Date();

const defaultState = {
	user_id : "",
	user_no : "",
	user_nm : "",
	user_kn : "",
	user_tel : "",
	user_sex : "0",
	user_birthday_year : "",
	user_birthday_month : "",
	user_birthday_day : "",
	info_mailflg : "1",
	email : "",
	password : "",
	confirm_password : "",
	pass_reissue_at : "",
	add_kind : "1",
	entry_status : "0",
	update_id : "",
	line_id : "",
	send_kbn : "",
	birth_date : {
		'day' : '01',
		'month' : '01',
		'year' : dtNow.getFullYear() - 30,
	},
	birthday : "",
	mode : "",
	id : "",
};

let autokana;

function UserMngAddForm({ history, data, staff, dbconn, submitReturn }) {
	const [fieldData, handleDataFormat, setManualField] = useHandleFormData({ ...defaultState});
	const [defData] = useHandleFormData({ ...defaultState});
	const [addkind, setAddKind] = useState('');
	const [entrystatus, setEntryStatus] = useState('');
	const [user, setUser] = useState('');

	const [result, submitPost, errors, setErrorClear] = usePost();
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [errMode, setErrMode] = useState('0');
	const [loading, setLoading] = useState('0');
	const [birthDate, setBirthDate] = useState('');

	const [isPassChange, setPassChange] = useState(false);
	const [passSettingMode, setPassSettingMode] = useState(false);
	const [bkPassword, setBkPassword] = useState('');

	const strPram = fncgSetUrlPram(dbconn);

	// data.mode
	const MODE_NEW = '1';
	const MODE_EDIT = '2';
	const MODE_DELETE = '3';

	/**
	 * ページ離脱イベント
	 * @param e
	 */
	window.onbeforeunload = function(e){
		//--- 確認メッセージ表示(トリガーとしてのみ有効、メッセージは内容は反映されない)
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			e.returnValue = '';
		}
	}

	/**
	 * 自動カナ入力
	 */
	useEffect(() => {
		handleNameInput = handleNameInput.bind(fieldData);
		autokana = AutoKana.bind('#user_nm', '#user_kn', { katakana: true });
	},[]);

	function handleNameInput(ev) {
		fieldData.user_nm = ev.target.value;
		fieldData.user_kn = autokana.getFurigana();
	}

	/**
	 * データ取得
	 */
	useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
		if(data != '' && data != undefined){
			setLoading('1');
			data.id = dbconn.id;
			if(data.mode == MODE_NEW){
				Http.post('api/init-user', {...data}).then(response => {
					if (Object.keys(response.data).length > 0) {
						setAddKind(response.data.Kind);
						setEntryStatus(response.data.Status);
					}
					setLoading('0');
				});
			}else{
				Http.post('api/get-user', {...data}).then(response => {
					if (Object.keys(response.data).length > 0) {
                        setUser(response.data.User);
					    setAddKind(response.data.Kind);
						setEntryStatus(response.data.Status);
					}
					setLoading('0');
				});
			}
		}else{
            history.push(APP_TOP + strPram);
        }

	}, [])

	/**
	 * 日付選択反映
	 * @param date
	 */
	function handleDate(date) {
		setManualField({
			...fieldData,
			birth_date: date,
		})
	}

	/**
	 * 登録種別リスト作成
	 * @returns {*}
	 */
	function fncSetAddKind(){
		var aryKinds = [];
		if(addkind == "") return;

		for(var intIdx in addkind){
			aryKinds.push(
				<option value={addkind[intIdx].com_kbn} key={addkind[intIdx].com_kbn}>
					{addkind[intIdx].com_val1}
				</option>
			);
		}
		if(aryKinds.length > 0){
			return(
				<>
					{aryKinds}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * 状態リスト作成
	 * @returns {*}
	 */
	function fncSetEntryStatus(){
		var aryStatuss = [];
		if(entrystatus == "") return;

		for(var intIdx in entrystatus){
			aryStatuss.push(
				<option value={entrystatus[intIdx].com_kbn} key={entrystatus[intIdx].com_kbn}>
					{entrystatus[intIdx].com_val1}
				</option>
			);
		}
		if(aryStatuss.length > 0){
			return(
				<>
					{aryStatuss}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * 会員情報設定
	 */
	useEffect(() => {
		if(user != "" && user != undefined){
			//--- データ格納
			//-- 会員ID
			fieldData.user_id = user[0].user_id;
			//-- 会員番号
			if(user[0].user_no != null){
				fieldData.user_no = user[0].user_no;
			}
			//-- 会員名称
			if(user[0].user_nm != null){
				fieldData.user_nm = user[0].user_nm;
			}
			//-- 会員名称(フリガナ)
			if(user[0].user_kn != null){
				fieldData.user_kn = user[0].user_kn;
			}
			//-- 電話番号
			if(user[0].user_tel != null){
				fieldData.user_tel = user[0].user_tel;
			}
			//-- 性別
            if(user[0].user_sex != null){
                fieldData.user_sex = user[0].user_sex;
            }
			//-- 生年月日_年
			if(user[0].user_birthday_year != null && user[0].user_birthday_year != ''){
				fieldData.birth_date.year = user[0].user_birthday_year;
				fieldData.user_birthday_year = user[0].user_birthday_year;
			}
			//-- 生年月日_月
			if(user[0].user_birthday_month != null && user[0].user_birthday_month != ''){
				fieldData.birth_date.month = user[0].user_birthday_month;
				fieldData.user_birthday_month = user[0].user_birthday_month;
			}
			//-- 生年月日_日
			if(user[0].user_birthday_day != null && user[0].user_birthday_day != ''){
				fieldData.birth_date.day = user[0].user_birthday_day;
				fieldData.user_birthday_day = user[0].user_birthday_day;
			}
			if (user[0].user_birthday_year != null && user[0].user_birthday_year != '' &&
				user[0].user_birthday_month != null && user[0].user_birthday_month != '' &&
				user[0].user_birthday_day != null && user[0].user_birthday_day != '') {
				var strBirthDate = user[0].user_birthday_year + "/" + user[0].user_birthday_month + "/" + user[0].user_birthday_day + " 00:00:00";
				setBirthDate(new Date(strBirthDate));
			}
			//-- お知らせメール希望有無
			if(user[0].info_mailflg != null){
				fieldData.info_mailflg = user[0].info_mailflg;
			}
			//-- メールアドレス
			if(user[0].email != null){
				fieldData.email = user[0].email;
			}
			//-- パスワード
			if(user[0].pass_word != null){
				fieldData.password = user[0].pass_word;
				fieldData.confirm_password = user[0].pass_word;
			}
			//-- パスワード再発行日時
			if(user[0].pass_reissue_at != null){
				fieldData.pass_reissue_at = user[0].pass_reissue_at;
			}
			//-- 登録種別
			if(user[0].add_kind != null){
				fieldData.add_kind = user[0].add_kind;
			}
			//-- 状態
			if(user[0].entry_status != null){
				fieldData.entry_status = user[0].entry_status;
			}

			// 配信先
			fieldData.send_kbn = user[0].send_kbn != null ? user[0].send_kbn : '';

			defData.user_no = user[0].user_no != null ? user[0].user_no : '';
			defData.user_nm = user[0].user_nm != null ? user[0].user_nm : '';
			defData.user_kn = user[0].user_kn != null ? user[0].user_kn : '';
			defData.user_tel = user[0].user_tel != null ? user[0].user_tel : '';
			defData.user_sex = user[0].user_sex != null ? user[0].user_sex : '';
			defData.user_birthday_year = user[0].user_birthday_year != null ? user[0].user_birthday_year : '';
			defData.user_birthday_month = user[0].user_birthday_month != null ? user[0].user_birthday_month : '';
			defData.user_birthday_day = user[0].user_birthday_day != null ? user[0].user_birthday_day : '';
			defData.info_mailflg = user[0].info_mailflg != null ? user[0].info_mailflg : '';
			defData.email = user[0].email != null ? user[0].email : '';
			defData.password = user[0].pass_word != null ? user[0].pass_word : '';
			defData.add_kind = user[0].add_kind != null ? user[0].add_kind : '';
			defData.entry_status = user[0].entry_status ? user[0].entry_status : '';
			defData.line_id = user[0].line_id != null? user[0].line_id : '';
			//ロールバック用パスワード設定
			setBkPassword(defData.password);
		}
	}, [user])

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		//--- パスワード設定時
		if (passSettingMode) {
			fieldData.password = fieldData.confirm_password = bkPassword;
			setPassSettingMode(false);
			//--- エラーメッセージクリア
			setErrorClear();
			return;
		}
		//--- 変更チェック
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			if(window.confirm(MSG_SYSTEM_UPD_BACK)){
				//-- 戻る
				submitReturn();
			}
		}else{
			//-- 戻る
			submitReturn();
		}
	}

	/**
	 * 変更項目チェック
	 * @returns {string}
	 */
	function fncChkBackData(){
		//--- 削除モードは判定しない
		if(data.mode == MODE_DELETE) return '0';

		//--- 生年月日編集
		if(birthDate != '' && birthDate != null){
			fieldData.user_birthday_year = birthDate.getFullYear().toString();
			fieldData.user_birthday_month = ('0' + (birthDate.getMonth() + 1)).slice(-2);
			fieldData.user_birthday_day = ('0' + birthDate.getDate()).slice(-2);
		}else{
			fieldData.user_birthday_year = '';
			fieldData.user_birthday_month = '';
			fieldData.user_birthday_day = '';
		}

		//--- 会員情報チェック
		if(fieldData.user_no != defData.user_no ||
			fieldData.user_nm != defData.user_nm ||
			fieldData.user_kn != defData.user_kn ||
			fieldData.user_tel != defData.user_tel ||
			fieldData.user_sex != defData.user_sex ||
			fieldData.user_birthday_year != defData.user_birthday_year ||
			fieldData.user_birthday_month != defData.user_birthday_month ||
			fieldData.user_birthday_day != defData.user_birthday_day ||
			fieldData.info_mailflg != defData.info_mailflg ||
			fieldData.email != defData.email ||
			fieldData.password != defData.password ||
			fieldData.add_kind != defData.add_kind ||
			fieldData.entry_status != defData.entry_status){
			return '1';
		}
		return '0';
	}
	
	/**
	 *
	 * 更新ボタン
	 */
	function fncOnClickUpdate(e){
	if(dbconn.id == '' || dbconn.id == undefined) return;
		//--- パスワード設定時
		if (passSettingMode) {
			//パスワードの整合性確認
			e.preventDefault();

			//--- エラーメッセージクリア
			setErrorClear();
			setLoading('1');

			//--- チェックと更新
			let aryData = {
				user_id : fieldData.user_id,
				password : fieldData.password,
				confirm_password : fieldData.confirm_password,
				update_id : staff.staff_id,
				mode : data.mode,
				id : dbconn.id,
			}
			submitPost('api/upd-user-pass',{
				...aryData,
			});

			return;
		}
		//--- データ格納
		//-- 生年月日
		if(birthDate != '' && birthDate != null){
			fieldData.user_birthday_year = birthDate.getFullYear();
			fieldData.user_birthday_month = birthDate.getMonth() + 1;
			fieldData.user_birthday_day = birthDate.getDate();
			fieldData.birthday = fieldData.birth_date.year
									+ '-' + fieldData.birth_date.month
									+ '-' + fieldData.birth_date.day;
		}else{
			fieldData.user_birthday_year = '';
			fieldData.user_birthday_month = '';
			fieldData.user_birthday_day = '';
			fieldData.birthday = '';
		}
		// 配信先
		if (fieldData.send_kbn != '') {
			if (fieldData.email == '' && defData.line_id == '') {
				fieldData.send_kbn = '';
			} else if (fieldData.email != '' && defData.line_id == '') {
				fieldData.send_kbn = '1';
			} else if (defData.line_id != '' && fieldData.email == '') {
				fieldData.send_kbn = '2';
			}
		}
		if (fieldData.send_kbn == '') {
			if (fieldData.email == '' && defData.line_id == '') {
				fieldData.send_kbn = '';
			} else if (fieldData.email != '') {
				fieldData.send_kbn = '1';
			} else if (defData.line_id != '') {
				fieldData.send_kbn = '2';
			}
		}

		//-- 更新者ID
		fieldData.update_id = staff.staff_id;
		//-- モード
		fieldData.mode = data.mode;
		fieldData.id= dbconn.id;
		e.preventDefault();

		//--- エラーメッセージクリア
		setErrorClear();
		setLoading('1');

		//--- チェック
		submitPost('api/chk-user',{
			...fieldData,
		});
	}

    /**
     * パスワード設定ボタン
     */
	function fncOnClickPassChange() {
		// 初期のパスワードでない場合はセット済みにします。
		let pw = fieldData.password === defData.password ? '' : fieldData.password;
		fieldData.password = pw;
		fieldData.confirm_password = pw;
		setPassSettingMode(true);
	}

    /**
     * チェック結果エラー
     */
    useEffect(() => {
    	if(errors == undefined) return;
        if (Object.keys(errors).length > 0) {
			if (!passSettingMode) window.scrollTo(0, 0);
            setLoading('0');
        }
    }, [errors])

    /**
     * チェック結果正常
     */
    useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
        if (Object.keys(result).length > 0) {
			//--- パスワード設定時
			if (passSettingMode) {
				try {
					//ロールバック領域更新
					setBkPassword(fieldData.password);
					//新規以外ではパスワードを更新済みのため後処理を実施
					if (data.mode != MODE_NEW) {
						defData.password = fieldData.password;
						setModalMsg(MSG_CMP_PW_UPD);
						setShow(true);
					}
				}
				finally {
					setLoading('0');
					setPassSettingMode(false);
					setPassChange(true);
				}
				return;
			}
        	//--- 登録
			setPassChange(false);
            //-- POST
            Http.post('/api/add-user', {
                ...fieldData,
            }).then(response => {
                const strStatus = response.data.status;
                if(strStatus == '200'){
                    if(data.mode == MODE_NEW){
                        setModalMsg(MSG_CMP_ADD);
                    }else if(data.mode == MODE_EDIT){
                        setModalMsg(MSG_CMP_UPD);
                    }else if(data.mode == MODE_DELETE){
                        setModalMsg(MSG_CMP_DEL);
                    }
                }else{
					const strError = response.data.error;
                    setErrMode('1');
                    if(data.mode == MODE_NEW){
                        setModalMsg(MSG_NG_ADD + strError);
                    }else if(data.mode == MODE_EDIT){
                        setModalMsg(MSG_NG_UPD + strError);
                    }else if(data.mode == MODE_DELETE){
                        setModalMsg(MSG_NG_DEL + strError);
                    }
                }
            }).catch(error => {
                if (error.response) {
					setErrMode('1');
                	var strErrMng = '';
					if(data.mode == MODE_NEW){
						strErrMng = MSG_NG_ADD;
					}else if(data.mode == MODE_EDIT){
						strErrMng = MSG_NG_UPD;
					}else if(data.mode == MODE_DELETE){
						strErrMng = MSG_NG_DEL;
					}
					strErrMng += error.response.data;
					setModalMsg(strErrMng);
                }
            }).finally(() => {
				setLoading('0');
				setShow(true);
            })
        }
    }, [result]);

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
        setShow(false);
		if(!isPassChange && errMode == '0'){
			submitReturn();
		}
	}

	/**
	 * 生年月日カーソル移動
	 */
	function fncSetBirthDateFocus(){
		document.getElementById('dtBirthDate').focus();
	}

	function getFormReadonly(typ) {
		if (!typ) return (data.mode === MODE_DELETE || passSettingMode)? 'readOnly' : '';
		if (typ === 1) return (data.mode === MODE_DELETE || !passSettingMode)? 'readOnly' : '';
	}

	function getFormDisabled(typ) {
		if (!typ) return (data.mode === MODE_DELETE || passSettingMode)? 'disabled' : '';
	}

	function getButtonDisabled(typ) {
		if (!typ) return loading == '1'? 'disabled' : '';
	}

	function getImportMark() {
		return (<>{!passSettingMode && (<a style={{color:"#FF0000"}}>[必須]</a>)}</>);
	}

	return (
		<>
			<Form className="user-mng-form" >
                <div>
                    <Row className="user-mng-form-row">
                        <Col md={{ span: 1, offset: 11}}>
                            <Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={getButtonDisabled()}
							>
                                戻 る
                            </Button>
                        </Col>
                    </Row>
                </div>
				<Row className="user-mng-form-row">
					<div className={"div-user-mng-save-title"}>
						<label className={"label-user-mng-padding"}>会員ID</label>
					</div>
					<div className={"div-user-mng-save-id"}>
						<FormControl
							className="input-staff-mng-save-id"
							type="text"
							value={fieldData.user_id}
							name="user_id"
							readOnly="readOnly"
						/>
					</div>
					<div className={"div-user-mng-save-title"}>
						<label className={"label-user-mng-padding"}>会員番号</label>
					</div>
					<div className={"div-user-mng-save-no"}>
						<FormControl
							className="input-staff-mng-save-no"
							type="text"
							value={fieldData.user_no}
							name="user_no"
							onChange={handleDataFormat}
							maxLength="20"
							readOnly={getFormReadonly()}
							{...FormInput}
						/>
					</div>
				</Row>

				{(errors.user_no) && (
					<Row className="user-mng-error-row">
						<div className={"div-user-mng-save-title"}></div>
						<div className={"div-user-mng-save-id"}></div>
						<div className={"div-user-mng-save-title"}></div>
						<div className={"div-user-mng-save-no"}>
							<span className="error-span">{errors.user_no? errors.user_no[0] : ''}</span>
						</div>
					</Row>
				) }
				<Row className="user-mng-form-row">
					<div className={"div-user-mng-save-title"}>
						<label className={"label-user-mng-padding"}>名前{getImportMark()}</label>
					</div>
					<div className={"div-user-mng-save-nm"}>
						<FormControl
							className="input-staff-mng-save-nm"
							type="text"
							value={fieldData.user_nm}
							onChange={handleDataFormat}
							onInput={handleNameInput}
							name="user_nm"
							id="user_nm"
							maxLength="50"
							readOnly={getFormReadonly()}
							{...FormInput}
						/>
					</div>
					<div className={"div-user-mng-save-title"}>
						<label className={"label-user-mng-padding"}>フリガナ{getImportMark()}</label>
					</div>
					<div className={"div-user-mng-save-kn"}>
						<FormControl
							className="input-staff-mng-save-kn"
							type="text"
							value={fieldData.user_kn}
							onChange={handleDataFormat}
							name="user_kn"
							id="user_kn"
							maxLength="50"
							readOnly={getFormReadonly()}
							{...FormInput}
						/>
					</div>
				</Row>
				{(errors.user_nm || errors.user_kn ) && (
					<Row className="user-mng-error-row">
						<div className={"div-user-mng-save-title"}></div>
						<div className={"div-user-mng-save-nm"}>
							<span className="error-span">{errors.user_nm? errors.user_nm[0] : ''}</span>
						</div>
						<div className={"div-user-mng-save-title"}></div>
						<div className={"div-user-mng-save-kn"}>
							<span className="error-span">{errors.user_kn? errors.user_kn[0] : ''}</span>
						</div>
					</Row>
				) }
				<Row className="user-mng-form-row">
					<div className={"div-user-mng-save-title"}>
						<label className={"label-user-mng-padding"}>性別</label>
					</div>
					<div className={"div-user-mng-save-man"}>
						<label className={"label-user-mng-padding"}>
							<input
								type="radio"
								className=""
								name="user_sex"
								value="0"
								onChange={handleDataFormat}
								checked={fieldData.user_sex === "0"}
								disabled={getFormDisabled()}
							/> 男 性
						</label>
					</div>
					<div className={"div-user-mng-save-woman"}>
						<label className={"label-user-mng-padding"}>
							<input
								type="radio"
								className=""
								name="user_sex"
								value="1"
								onChange={handleDataFormat}
								checked={fieldData.user_sex === "1"}
								disabled={getFormDisabled()}
							/> 女 性
						</label>
					</div>
					<div className={"div-user-mng-save-title"}>
						<label className={"label-user-mng-padding"}>生年月日</label>
					</div>
					<div className={"div-date-picker-width"}>
						<DatePicker
							className="date-picker"
							showPopperArrow={false}
							selected={birthDate}
							onChange={date => setBirthDate(date)}
							customInput={<InputMask mask="9999/99/99" maskChar={null} />}
							dateFormat="yyyy/MM/dd"
							disabled={getFormDisabled()}
							id={"dtBirthDate"}
							renderCustomHeader={({
													 date,
													 changeYear,
													 changeMonth,
												 }) =>(
								<div
									className={"div-date-picker"}
								>
									<select
										className={"select-date-picker"}
										value={date.getFullYear()}
										onChange={({target:{value}}) => changeYear(value)}
									>
										{years.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
									<select
										className={"select-date-picker"}
										value={months[date.getMonth()]}
										onChange={({ target: { value } }) =>
											changeMonth(months.indexOf(value))
										}
									>
										{months.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
								</div>
							)}
						/>
					</div>
					{!passSettingMode && (
					<div className={"div-calendar-icon"}>
						<a onClick={fncSetBirthDateFocus}>
							<IoIosCalendar/>
						</a>
					</div>
					)}
				</Row>
				<Row className="user-mng-form-row">
					<div className={"div-user-mng-save-title"}>
						<label className={"label-user-mng-padding"}>電話番号</label>
					</div>
					<div className={"div-user-mng-save-tel"}>
						<FormControl
							className="input-user-mng-save-tel"
							type="text"
							value={fieldData.user_tel}
							onChange={handleDataFormat}
							name="user_tel"
							maxLength="15"
							readOnly={getFormReadonly()}
							{...FormInput}
						/>
					</div>
				</Row>

				<Row className="user-mng-form-row">
					<div className={"div-user-mng-save-title"}>
						<label className={"label-user-mng-padding"}>メールアドレス</label>
					</div>
					<div className={"div-user-mng-save-mail"}>
						<FormControl
							className="input-staff-mng-save-mail"
							type="text"
							value={fieldData.email}
							onChange={handleDataFormat}
							name="email"
							maxLength="255"
							autocomplete ="new-password"
							readOnly={getFormReadonly()}
							{...FormInput}
						/>
					</div>
					{!passSettingMode && (
					<>
						<div className={"div-user-mng-save-title"}>
							<label className={"label-user-mng-padding"}>パスワード</label>
						</div>
						<div className={"div-user-mng-save-pw"}>
							<PasswordForm
								className="input-staff-mng-save-pw"
								type="password"
								value={fieldData.password}
								onChange={handleDataFormat}
								name="password"
								maxLength="20"
								autocomplete ="new-password"
								readOnly="readOnly"
							/>
						</div>
					</>
					)}
				</Row>
				{(errors.email || (!passSettingMode && (errors.password || errors.confirm_password))) && (
					<Row className="user-mng-error-row">
						<div className={"div-user-mng-save-title"}></div>
						<div className={"div-user-mng-save-mail"}>
							<span className="error-span">{errors.email? errors.email[0] : ''}</span>
						</div>
						<div className={"div-user-mng-save-title"}></div>
						<div className={"div-user-mng-save-pw"}>
							<span className="error-span">{errors.password? errors.password[0] : ''}</span>
						</div>
					</Row>
				) }
				<Row className="user-mng-form-row">
					<div className={"div-user-mng-save-title"}>
						<label className={"label-user-mng-padding"}>お知らせ</label>
					</div>
					<div className={"div-user-mng-save-info-on"}>
						<label className={"label-user-mng-padding"}>
							<input
								type="radio"
								className="radio-user-mng"
								name="info_mailflg"
								value="1"
								onChange={handleDataFormat}
								checked={fieldData.info_mailflg === "1"}
								disabled={getFormDisabled()}
							/> 希望する
						</label>
					</div>
					<div className={"div-user-mng-save-info-off"}>
						<label className={"label-user-mng-padding"}>
							<input
								type="radio"
								className="radio-user-mng"
								name="info_mailflg"
								value="0"
								onChange={handleDataFormat}
								checked={fieldData.info_mailflg === "0"}
								disabled={getFormDisabled()}
							/> 希望しない
						</label>
					</div>
					{(data.mode != MODE_DELETE && !passSettingMode) && (
					<>
						<div className={"div-user-mng-save-title"}>
							<label className={"label-user-mng-padding"}></label>
						</div>
						<div className={"div-user-mng-save-pw"}>
							<div className={"div-user-mng-save-pw-btn"}>
							<Button
								block
								className="btn-pass-change"
								onClick={fncOnClickPassChange}
								disabled={getButtonDisabled()}
							>
								パスワード設定
							</Button>
							</div>
						</div>
					</>
					)}
				</Row>
				{errors.entry_status && (
					<Row className="user-mng-error-row">
						<div className={"div-user-mng-save-title"}></div>
						<div className={"div-user-mng-save-status"}>
							<span className="error-span">{errors.entry_status? errors.entry_status[0] : ''}</span>
						</div>
					</Row>
				) }
				{passSettingMode ? (
				<>
					<Row>
						<div className={"div-user-mng-save-title"}>
						</div>
						<div className={"div-user-mng-save-pw long"}>
							<p>パスワード(半角英数字の組み合わせで8文字以上20文字以内で記載してください)</p>
							<PasswordForm
								className="input-staff-mng-save-pw long"
								placeholder="新しいパスワードを入力してください"
								value={fieldData.password}
								onChange={handleDataFormat}
								name="password"
								maxLength="20"
								autocomplete ="new-password"
								hasEye
							/>
						</div>
					</Row>
					{(errors.password) && (
						<Row className="user-mng-error-row">
							<div className={"div-user-mng-save-title"}></div>
							<div className={"div-user-mng-save-pw long"}>
								<span className="error-span">{errors.password? errors.password[0] : ''}</span>
							</div>
						</Row>
					)}
					<Row>
						<div className={"div-user-mng-save-title"}>
						</div>
						<div className={"div-user-mng-save-pw long"}>
							<p>パスワード(確認のため、もう一度入力してください)</p>
							<PasswordForm
								className="input-staff-mng-save-pw long"
								placeholder="上記と同じパスワードを再入力してください"
								value={fieldData.confirm_password}
								onChange={handleDataFormat}
								name="confirm_password"
								maxLength="20"
								autocomplete ="new-password"
								hasEye
							/>
						</div>
					</Row>
					{(errors.confirm_password) && (
						<Row className="user-mng-error-row">
							<div className={"div-user-mng-save-title"}></div>
							<div className={"div-user-mng-save-pw long"}>
								<span className="error-span">{errors.confirm_password? errors.confirm_password[0] : ''}</span>
							</div>
						</Row>
					)}
				</>
				) : (
				<Row className="user-mng-form-row">
					<div className={"div-user-mng-save-title"}>
						<label className={"label-user-mng-padding"}>登録種別</label>
					</div>
					<div className={"div-user-mng-save-kind"}>
						<Form.Control
							as="select"
							className="sel-kind"
							name='add_kind'
							value={fieldData.add_kind}
							onChange={handleDataFormat}
							disabled={getFormDisabled()}
						>
							{fncSetAddKind()}
						</Form.Control>
					</div>
					<div className={"div-user-mng-save-title"}>
						<label className={"label-user-mng-padding"}>状態</label>
					</div>
					<div className={"div-user-mng-save-status"}>
						<Form.Control
							as="select"
							className="sel-status"
							name='entry_status'
							value={fieldData.entry_status}
							onChange={handleDataFormat}
							disabled={getFormDisabled()}
						>
							{fncSetEntryStatus()}
						</Form.Control>
					</div>
				</Row>
				)}

				<Row className="">
					<Col md={{ span: 2, offset: 9 }}>
						<Button
							block
							className="btn-save"
							onClick={fncOnClickUpdate}
							disabled={getButtonDisabled()}
						>
							{passSettingMode? '設定' : data.mode == MODE_NEW? '登 録' : data.mode == MODE_EDIT? '更 新' : '削 除'}
						</Button>
					</Col>
					<Col md={{ span: 1 }}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={getButtonDisabled()}
						>
							戻 る
						</Button>
					</Col>
				</Row>

			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>

		</>
	);

}

export default UserMngAddForm;