import React from 'react';
import './CardHeader.css';

function CardHeader(props) { 
	return (
		<div className="login-card-header-container">
			<div className="login-card-header">
					{props.text}
			</div>
		</div>
	
	);
}

 export default CardHeader;