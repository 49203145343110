import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../MailTmpMng.css';
import Http from 'Utils/Axios';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData';
import { FormInput } from 'Utils/FormHandlers/HandleFormProperties';
import CustomModal from 'Utils/Modal/CustomModal';
import {Button, Col, Row, Form, Modal, FormControl} from 'react-bootstrap';
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";
import {
	MSG_NOT_DATA, SEARCH_ID,
} from "../../../../Utils/Common/ComConst";

const defaultState = {
	mail_kind : "",
	mail_subject : "",
	user_nm : "",
	user_kn : "",
	user_tel : "",
	info_on : "0",
	info_off : "0",
	id : '',
}

const defaultMail ={
	mail_id : '',
	mode : '',
	id : '',
}

function MailTmpMngListForm({ history, user, dbconn, submitMove }) {
	const [fieldData, handleDataFormat] = useHandleFormData({defaultState});
	const [fieldMail] = useState({defaultMail});
	const [kind, setKind] = useState('');
	const [listData, setListData] = useState('');
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [loading, setLoading] = useState('0');
	const strPram = fncgSetUrlPram(dbconn);

	/**
	 * 起動時
	 */
	useEffect(() => {
		//--- ページ離脱イベントOFF
		window.onbeforeunload = null;

		//--- データ初期化
		localStorage.setItem(SEARCH_ID, '');

		if(dbconn.id == '' || dbconn.id == undefined) return;
		setLoading('1');

		//--- 初期処理
		let aryData = {
			id : dbconn.id,
		};
		Http.post('api/init-mail', {
			...aryData
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setKind(response.data.Kind);
			}
			//-- 全検索
			fncOnClickSearch();
		});
	}, [])

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		localStorage.setItem(SEARCH_ID, '');
		history.push('/menu' + strPram);
	}

	/**
	 * 検索
	 */
	function fncOnClickSearch(){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		setLoading('1');
		fieldData.id = dbconn.id;
		Http.post('api/search-mail', {
			...fieldData
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setListData(response.data);
			}else{
				setListData('');
				setModalMsg(MSG_NOT_DATA);
				setShow(true);
			}
			setLoading('0');
		});
	}

	/**
	 * 新規ボタン
	 */
	function fncOnClickAddNew(){
		fieldMail.mail_id = '';
		fieldMail.mode = '1';
		fieldMail.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldMail));
		submitMove(fieldMail);
	}

	/**
	 * 編集ボタン
	 */
	function fncOnClickMngUpd(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strMailID = document.getElementById('hdn-mail-id-' + strID ).value;
		fieldMail.mail_id = strMailID;
		fieldMail.mode = '2';
		fieldMail.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldMail));
		submitMove(fieldMail);
	}

	/**
	 * 削除ボタン
	 */
	function fncOnClickMngDel(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strMailID = document.getElementById('hdn-mail-id-' + strID ).value;
		fieldMail.mail_id = strMailID;
		fieldMail.mode = '3';
		fieldMail.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldMail));
		submitMove(fieldMail);
	}

	/**
	 * 検索条件-希望する
	 * @param e
	 */
	function fncChangeInfoOn(e){
		if( fieldData.info_on == '1' ){
			fieldData.info_on = '0'
		}else{
			fieldData.info_on = e.target.value;
		}
	}

	/**
	 * 検索条件-希望しない
	 * @param e
	 */
	function fncChangeInfoOff(e){
		if( fieldData.info_off == '1' ){
			fieldData.info_off = '0'
		}else{
			fieldData.info_off = e.target.value;
		}
	}

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);
	}

	/**
	 * メール種別作成
	 * @returns {*}
	 */
	function fncSetKind(){
		var aryKind = [];
		if(kind == "") return;

		aryKind.push(
			<option value='' key='00'></option>
		);
		for(var intIdx in kind){
			aryKind.push(
				<option value={kind[intIdx].com_kbn} key={intIdx}>
					{kind[intIdx].com_val1}
				</option>
			);
		}
		if(aryKind.length > 0){
			return(
				<>
					{aryKind}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * 一覧作成
	 * @returns {*}
	 */
	function fncGetMailTmpMngList(){
		var lstMailTmpMng = [];

		for(var intIdx in listData){
			lstMailTmpMng.push(
				<tr key={intIdx}>
					<td className="td-mail-tmp-mng-no">
						{Number(intIdx) + 1}
					</td>
					<td className="td-mail-tmp-mng-kind">
						{listData[intIdx].mail_kind_mn != null? listData[intIdx].mail_kind_mn : ''}
					</td>
					<td className="td-mail-tmp-mng-subject">
						{listData[intIdx].mail_subject != null? listData[intIdx].mail_subject : ''}
					</td>
					<td className="td-mail-tmp-mng-use">
						{listData[intIdx].use_stop_flg == '0'? '使用' : '使用停止'}
					</td>
					<td className="td-mail-tmp-mng-upd">
						<Button
							block
							className="btn-edit"
							onClick={fncOnClickMngUpd}
							data-id={intIdx}
							disabled={loading == '1'? 'disabled' : ''}
						>
							編 集
						</Button>
						<input type="hidden" id={'hdn-mail-id-' + intIdx} value={listData[intIdx].mail_id}></input>
					</td>
					<td className="td-mail-tmp-mng-del">
						{listData[intIdx].mail_kind == '7' ?
							<Button
								block
								className="btn-delete"
								onClick={fncOnClickMngDel}
								data-id={intIdx}
								disabled={loading == '1'? 'disabled' : ''}
							>
								削 除
							</Button>
							:
							<>
							</>
						}

					</td>
				</tr>
			);
		}

		if(lstMailTmpMng.length > 0){
			return(
				<tbody>
					{lstMailTmpMng}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	return (
		<>
			<Form>
				<div>
					<Row className="staff-mng-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<div className="text-header first-header">▼検索</div>
				<Row className="mail-tmp-mng-value">
					<div className="width-search-label">
						<label className="label-mail-tmp-mng-title">メール種別</label>
					</div>
					<Col md={{ span: 2}}>
						<Form.Control
							as="select"
							className="sel-limit"
							name='mail_kind'
							value={fieldData.mail_kind}
							onChange={handleDataFormat}
						>
							{fncSetKind()}
						</Form.Control>
					</Col>
					<Col md={{ span: 1}}></Col>
					<div className="width-search-label">
						<label className="label-mail-tmp-mng-title">件名</label>
					</div>
					<Col md={{ span: 4}}>
						<FormControl
							className="input-mail-tmp-mng-field"
							type="text"
							value={fieldData.mail_subject || ''}
							onChange={handleDataFormat}
							name="mail_subject"
							{...FormInput}
						/>
					</Col>
				</Row>
				<Row className="mail-tmp-mng-value">
					<div className="width-search-label">
						<label>
							<input
								className="mail-tmp-mng-checkbox"
								type="checkbox"
								value="1"
								onChange={fncChangeInfoOn}
								name="info_on"
							/> 使用
						</label>
					</div>
					<div className="width-search-label">
						<label>
							<input
								className="mail-tmp-mng-checkbox"
								type="checkbox"
								value="1"
								onChange={fncChangeInfoOff}
								name="info_off"
							/> 使用停止
						</label>
					</div>
				</Row>
				<Row className="mail-tmp-mng-value">
					<Col md={{ span: 1, offset: 11}}>
						<Button
							block
							className="btn-search"
							onClick={fncOnClickSearch}
							disabled={loading == '1'? 'disabled' : ''}
						>
							検 索
						</Button>
					</Col>
				</Row>

				<div>
					<Row className="mail-tmp-mng-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-new"
								onClick={fncOnClickAddNew}
								disabled={loading == '1'? 'disabled' : ''}
							>
								新 規
							</Button>
						</Col>
					</Row>
				</div>

				<div className="text-header first-header">▼一覧</div>
				<Row className="mail-tmp-mng-value">
					<Col md={{ span: 12}}>
						<table className={"mail-tmp-mng-list"}>
							<thead>
								<tr>
									<th className={"th-mail-tmp-mng-no"}>No</th>
									<th className={"th-mail-tmp-mng-kind"}>メール種別</th>
									<th className={"th-mail-tmp-mng-subject"}>件名</th>
									<th className={"th-mail-tmp-mng-use"}>使用状態</th>
									<th className={"th-usermng-upd"}>編集</th>
									<th className={"th-usermng-del"}>削除</th>
								</tr>
							</thead>
							{fncGetMailTmpMngList()}
						</table>
					</Col>
				</Row>

				<div>
					<Row className="staff-mng-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>

				<input type="text" className="dmy-text" name="dummy" />
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>

		</>
	);

}

export default MailTmpMngListForm;