import React, {useEffect, useState} from 'react';

import './ReserveSts.css';
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import ReserveStsForm from "./ReserveStsForm/ReserveStsForm";
import ReserveMngAddForm from "../ReserveMng/ReserveMngAdd/ReserveMngAddForm";
import {fncgGetUrlPramInfo, fncgGetUserLoginCheck, fncgGetDbconn} from "Utils/Common/ComCom";
import {DISP_FG, PRM_ID, PRM_USER_ID, SEARCH_ID} from "../../../Utils/Common/ComConst";
import {useLocation} from "react-router-dom";

let dispFlg = '0';

function ReserveSts({ match, history, location }) {
	const [user, setUser] = useState('');
	const [data, setData] = useState('');
	const [disp, setDisp] = useState('0');
	const [urlpram, setUrlPram] = useState('');
	const [dbconn, setDbConn] = useState('');
	const [start, setStart] = useState('0');
	const path = useLocation().pathname;
	const handleSetUser = userData => setUser(userData);
	const handleSetUrlPram = dat => setUrlPram(dat);
	const handleSetDbConn = dat => setDbConn(dat);
	const handleSetStart = dat => setStart(dat);

	/**
	 * らっく院経由での表示かを判定します。
	 */
	const isShownLack = () => {
		return global.prmUserID != '' && global.prmUserID != undefined;
	}

	/**
	 * 初期処理
	 */
	useEffect(() => {
		//-- 画面情報
		const dispFg = localStorage.getItem(DISP_FG);
		if(dispFg != '' && dispFg != undefined){
			dispFlg = dispFg;
		}else{
			dispFlg = '0';
		}
		let searchID = localStorage.getItem(SEARCH_ID);
		if(searchID != '' && searchID != undefined){
			searchID = JSON.parse(searchID);
			//- 新規時のリロード対策
			if (dispFlg == '1' && searchID.mode == '1' && searchID.sel_date) {
				searchID.reserve_date = searchID.sel_date;
			}
			setData(searchID);
		}

		//-- 会員ID(らっく院連携)
		global.prmUserID = fncgGetUrlPramInfo(document.location.href, PRM_USER_ID);

		fncgGetDbconn({history, location, handleSetUrlPram, handleSetDbConn, handleSetStart});
	}, [])

	/**
	 * スタッフ情報取得
	 */
	useEffect(() => {
		if(start == '0') return;
		// ログインチェックを行いユーザ情報を返却する
		fncgGetUserLoginCheck({dbconn, urlpram, path, user, handleSetUser, history})
	}, [start])

	/**
	 * 登録画面
	 * @param data
	 */
	const onSubmitMoveAddDisp = data => {
		setDisp('1');
		setData(data);
		dispFlg = '1';
		localStorage.setItem(DISP_FG, '1');
		window.scrollTo(0, 0);
	}

	/**
	 * 一覧画面表示
	 * @param data
	 */
	const onSubmitMoveListDisp = data => {
		//--- ページ離脱イベントOFF
		window.onbeforeunload = null;

		dispFlg = '0';
		setDisp('0');
		setData(data);
		localStorage.setItem(DISP_FG, '0');
		window.scrollTo(0, 0);
	}

	/**
	 * 一覧画面表示（データ変更後の呼び出し）
	 * @param data
	 */
	const onSubmitMoveListDispFromDataChanged = data => {
		onSubmitMoveListDisp(data);
		if (isShownLack()) {
			window.close();
		}
	}

	return (
		<>
			{(dispFlg == '0' && user != "" && dbconn.id != '' && dbconn.id != undefined) &&(
				<Layout headerText="予約管理(スタッフ状況) -予約状況-" _dbconn={dbconn} _user={user}>
					<ReserveStsForm
						history={history}
						dbconn={dbconn}
						submitMove={onSubmitMoveAddDisp}
					/>
				</Layout >
			)}

			{(dispFlg == '1' && user != "" && dbconn.id != '' && dbconn.id != undefined) &&(
				<Layout headerText="予約管理(スタッフ状況) -予約登録-" _dbconn={dbconn} _user={user}>
					<ReserveMngAddForm
						history={history}
						user={user}
						data={data}
						dbconn={dbconn}
						submitReturn={onSubmitMoveListDisp}
						submitReturnDC={onSubmitMoveListDispFromDataChanged}
					/>
				</Layout>
			)}

		</>
	);

}

export default ReserveSts;