import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../ToolMng.css';
import Http from '../../../../Utils/Axios';
import usePost from '../../../../Utils/EndPoints/usePost';
import useHandleFormData from '../../../../Utils/FormHandlers/useHandleFormData';
import CustomModal from '../../../../Utils/Modal/CustomModal';
import { FormInput } from '../../../../Utils/FormHandlers/HandleFormProperties';
import {Button, Col, Row, Form, Modal, FormControl} from 'react-bootstrap';
import {
	MSG_CMP_ADD,
	MSG_CMP_DEL,
	MSG_CMP_UPD,
	MSG_NG_ADD,
	MSG_NG_DEL,
	MSG_NG_UPD,
	MSG_NOT_DATA, MSG_SYSTEM_UPD_BACK,
} from "../../../../Utils/Common/ComConst";
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";

const defaultState = {
	tool_nm : "",
	id : "",
};

const defaultTool = {
	tool_id : "",
	tool_nm : "",
	tool_cnt : "",
	update_id : "",
	mode : "",
	id : "",
};

function ToolMngListForm({ history, user, dbconn }) {
	const [listData, setListData] = useState('');
	const [fieldData, handleDataFormat] = useHandleFormData({defaultState});
	const [toolData, handleToolFormat] = useHandleFormData({defaultTool});
	const [defData] = useHandleFormData({defaultTool});
	const [modalShow, setModalShow] = useState(false);
	const [mode, setMode] = useState('');
	const [result, submitPost, errors, setErrorClear] = usePost();

	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [errMode, setErrMode] = useState('0');
	const [loading, setLoading] = useState('0');
	const strPram = fncgSetUrlPram(dbconn);

	/**
	 * ページ離脱イベント
	 * @param e
	 */
	window.onbeforeunload = function(e){
		//--- 確認メッセージ表示(トリガーとしてのみ有効、メッセージは内容は反映されない)
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			e.returnValue = '';
		}
	}

	/**
	 * 初期処理
	 */
	useEffect(() => {
		//--- 検索
		fncOnClickSearch();
	}, [])

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		history.push('/menu' + strPram);
	}

	/**
	 * 検索
	 */
	function fncOnClickSearch(){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		fieldData.id = dbconn.id;
		setLoading('1');

		Http.post('api/search-tool', {
			...fieldData
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setListData(response.data);
				setErrMode('0');
			}else{
				setListData('');
				setErrMode('1');
				setModalMsg(MSG_NOT_DATA);
				setShow(true);
			}
			setLoading('0');
		});
	}

	/**
	 * 新規ボタン
	 */
	function fncOnClickAddNew(){
		//--- 機材情報設定
		toolData.tool_id = '';
		toolData.tool_nm = '';
		toolData.tool_cnt = '';
		toolData.id = dbconn.id;
		defData.tool_id = '';
		defData.tool_nm = '';
		defData.tool_cnt = '';

		//--- モード設定
		setMode('1');
		//--- モーダル表示
		setErrorClear();
		setModalShow(true);
	}

	/**
	 * 編集ボタン
	 */
	function fncOnClickMngUpd(e){
		//--- 機材情報設定
		const strID = e.currentTarget.getAttribute('data-id');
		var strToolID = document.getElementById('hdn-tool-id-' + strID ).value;
		var strToolNm = document.getElementById('hdn-tool-nm-' + strID ).value;
		var strToolCnt = document.getElementById('hdn-tool-cnt-' + strID ).value;
		toolData.tool_id = strToolID;
		toolData.tool_nm = strToolNm;
		toolData.tool_cnt = strToolCnt;
		toolData.id = dbconn.id;
		defData.tool_id = strToolID;
		defData.tool_nm = strToolNm;
		defData.tool_cnt = strToolCnt;
		//--- モード設定
		setMode('2');
		//--- モーダル表示
		setErrorClear();
		setModalShow(true);
	}

	/**
	 * 削除ボタン
	 */
	function fncOnClickMngDel(e){
		//--- 機材情報設定
		const strID = e.currentTarget.getAttribute('data-id');
		var strToolID = document.getElementById('hdn-tool-id-' + strID ).value;
		var strToolNm = document.getElementById('hdn-tool-nm-' + strID ).value;
		var strToolCnt = document.getElementById('hdn-tool-cnt-' + strID ).value;
		toolData.tool_id = strToolID;
		toolData.tool_nm = strToolNm;
		toolData.tool_cnt = strToolCnt;
		toolData.id = dbconn.id;
		//--- モード設定
		setMode('3');
		//--- モーダル表示
		setErrorClear();
		setModalShow(true);
	}

	/**
	 * 機材登録画面クローズ
	 */
	function fncOnClickToolModalClose() {
		//--- 変更チェック
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			if(window.confirm(MSG_SYSTEM_UPD_BACK)){
				//-- 閉じる
				setModalShow(false);
				fncOnClickSearch();
			}
		}else{
			//-- 閉じる
			setModalShow(false);
			fncOnClickSearch();
		}
	}

	/**
	 * 変更項目チェック
	 * @returns {string}
	 */
	function fncChkBackData(){
		if(toolData.tool_id != defData.tool_id ||
			toolData.tool_nm != defData.tool_nm ||
			toolData.tool_cnt != defData.tool_cnt){
			return '1';
		}
		return '0'
	}

	/**
	 * 機材情報登録
	 */
	function fncOnClickToolModalOk(e){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		//--- チェック
		e.preventDefault();
		toolData.id = dbconn.id;
		toolData.mode = mode;
		submitPost('api/chk-tool',{
			...toolData,
		});
	}

	/**
	 * チェック結果正常
	 */
	useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
		if (Object.keys(result).length > 0) {
			setLoading('1');
			//-- データ格納
			toolData.mode = mode;
			toolData.update_id = user.staff_id;

			//-- POST
			Http.post('/api/add-tool', {
				...toolData,
			}).then(response => {
				const strStatus = response.data.status;
				if(strStatus == '200'){
					setErrMode('0');
					if(mode == '1'){
						setModalMsg(MSG_CMP_ADD);
					}else if(mode == '2'){
						setModalMsg(MSG_CMP_UPD);
					}else if(mode == '3'){
						setModalMsg(MSG_CMP_DEL);
					}
				}else{
					const strError = response.data.error;
					setErrMode('1');
					if(mode == '1'){
						setModalMsg(MSG_NG_ADD + strError);
					}else if(mode == '2'){
						setModalMsg(MSG_NG_UPD + strError);
					}else if(mode == '3'){
						setModalMsg(MSG_NG_DEL + strError);
					}
				}
				//setShow(true);
			}).catch(error => {
				if (error.response) {
					setErrMode('1');
					var strErrMng = '';
					if(mode == '1'){
						strErrMng = MSG_NG_ADD;
					}else if(mode == '2'){
						strErrMng = MSG_NG_UPD;
					}else if(mode == '3'){
						strErrMng = MSG_NG_DEL;
					}
					strErrMng += error.response.data;
					setModalMsg(strErrMng);
				}
			}).finally(() => {
				setLoading('0');
				setShow(true);
			})
		}
	}, [result]);

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);
		if(errMode == '0'){
			setModalShow(false);
			fncOnClickSearch();
		}
	}

	/**
	 * 一覧作成
	 * @returns {*}
	 */
	function fncGetToolMngList(){
		var lstToolMng = [];

		for(var intIdx in listData){
			lstToolMng.push(
				<tr key={intIdx}>
					<td className="td-toolmng-no">
						{Number(intIdx) + 1}
					</td>
					<td className="td-toolmng-name">
						{listData[intIdx].tool_nm != null? listData[intIdx].tool_nm : ''}
					</td>
					<td className="td-toolmng-cnt">
						{listData[intIdx].tool_cnt != null? listData[intIdx].tool_cnt : ''}
					</td>
					<td className="td-toolmng-upd">
						<Button
							block
							className="btn-edit"
							onClick={fncOnClickMngUpd}
							data-id={intIdx}
							disabled={loading == '1'? 'disabled' : ''}
						>
							編 集
						</Button>
						<input type="hidden" id={'hdn-tool-id-' + intIdx} value={listData[intIdx].tool_id} />
						<input type="hidden" id={'hdn-tool-nm-' + intIdx} value={listData[intIdx].tool_nm} />
						<input type="hidden" id={'hdn-tool-cnt-' + intIdx} value={listData[intIdx].tool_cnt} />
					</td>
					<td className="td-toolmng-del">
						<Button
							block
							className="btn-delete"
							onClick={fncOnClickMngDel}
							data-id={intIdx}
							disabled={loading == '1'? 'disabled' : ''}
						>
							削 除
						</Button>
					</td>
				</tr>
			);
		}

		if(lstToolMng.length > 0){
			return(
				<tbody>
				{lstToolMng}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	return (
		<>
			<Form>
				<div>
					<Row className="tool-mng-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<div className="text-header first-header">▼検索</div>
				<Row className="tool-mng-value">
					<div className="width-search-label">
						<label className="label-tool-mng-title">機材名</label>
					</div>
					<FormControl
						className="input-tool-mng"
						type="text"
						value={fieldData.tool_nm || ''}
						onChange={handleDataFormat}
						name="tool_nm"
						{...FormInput}
					/>
					<div className={"div-tool-mng-space"}></div>
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-search"
							onClick={fncOnClickSearch}
							disabled={loading == '1'? 'disabled' : ''}
						>
							検 索
						</Button>
					</Col>
				</Row>

				<div>
					<Row className="tool-mng-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-new"
								onClick={fncOnClickAddNew}
								disabled={loading == '1'? 'disabled' : ''}
							>
								新 規
							</Button>
						</Col>
					</Row>
				</div>

				<div className="text-header first-header">▼一覧</div>
				<Row className="tool-mng-value">
					<Col md={{ span: 12}}>
						<table className={"tool-mng-list"}>
							<thead>
								<tr>
									<th className={"th-toolmng-no"}>No</th>
									<th className={"th-toolmng-name"}>機材名</th>
									<th className={"th-toolmng-cnt"}>台数</th>
									<th className={"th-toolmng-upd"}>更新</th>
									<th className={"th-toolmng-del"}>削除</th>
								</tr>
							</thead>
							{fncGetToolMngList()}
						</table>
					</Col>
				</Row>

				<div>
					<Row className="tool-mng-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>

				<input type="text" className="dmy-text" name="dummy" />
			</Form>

			<Modal show={modalShow} onHide={fncOnClickToolModalClose} >
				<Modal.Header closeButton>
					<Modal.Title>{mode == '1'? '-機材登録-' : mode == '2'? '-機材更新-' : '-機材削除-'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{errors.tool && (
						<Row className="tool-mng-error-row">
							<Col md={{ span: 12}}>
								<span className="error-span">{errors.tool[0]}</span>
							</Col>
						</Row>
					) }
					<Row className="tool-mng-value">
						<div className={"div-tool-mng-title"}>
							<label className={"label-tool-mng-padding"}>機材名<a style={{color:"#FF0000"}}>[必須]</a></label>
						</div>
						<FormControl
							className="input-tool-mng"
							type="text"
							value={toolData.tool_nm || ''}
							onChange={handleToolFormat}
							name="tool_nm"
							readOnly={mode == '3'? 'readOnly' : ''}
							{...FormInput}
						/>
					</Row>
					{errors.tool_nm && (
						<Row className="tool-mng-error-row">
							<div className={"div-tool-mng-title"}></div>
							<div>
								<span className="error-span">{errors.tool_nm[0]}</span>
							</div>
						</Row>
					) }
					<Row className="tool-mng-value">
						<div className={"div-tool-mng-title"}>
							<label className={"label-tool-mng-padding"}>台数<a style={{color:"#FF0000"}}>[必須]</a></label>
						</div>
						<FormControl
							className="input-tool-mng-cnt"
							type="text"
							value={toolData.tool_cnt || ''}
							onChange={handleToolFormat}
							name="tool_cnt"
							readOnly={mode == '3'? 'readOnly' : ''}
							{...FormInput}
						/>
					</Row>
					{errors.tool_cnt && (
						<Row className="tool-mng-error-row">
							<div className={"div-tool-mng-title"}></div>
							<div>
								<span className="error-span">{errors.tool_cnt[0]}</span>
							</div>
						</Row>
					) }
				</Modal.Body>
				<Modal.Footer>
					<Row className="tool-mng-value">
						<Col md={{ span: 6}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickToolModalClose}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
						<Col md={{ span: 6}}>
							<Button
								block
								className="btn-save"
								onClick={fncOnClickToolModalOk}
								disabled={loading == '1'? 'disabled' : ''}
							>
								{mode == '1'? '登 録' : mode == '2'? '更 新' : '削 除'}
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>
		</>
	);

}

export default ToolMngListForm;