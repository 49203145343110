import React from 'react';
import './CustomModal.css';
import { Button, Modal } from 'react-bootstrap';

function CustomModal({ show, message, header, handleClose }) {

  /**
   * モーダルクローズ
   */
  function handleModalClose() {
    show = false;
    handleClose();
  }

  return (
    <>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
              className="modal_text_area"
              cols="100"
              rows="3"
              value={message}
              readOnly="readOnly"
          />

        </Modal.Body>
        <Modal.Footer>
          <Button block className="btn-nomal" onClick={handleModalClose}>
            O K
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CustomModal;