import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../ShopTime.css';
import Http from 'Utils/Axios';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData';
import CustomModal from 'Utils/Modal/CustomModal';
import { Button, Col, Row, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";
import { IoIosCalendar } from "react-icons/io";
import {
	MSG_NOT_DATA, MSG_INFO_DATE_INPUT, years, months, SEARCH_ID,
} from "../../../../Utils/Common/ComConst";
import InputMask from 'react-input-mask';

const defaultState = {
	start_date : "",
	end_date : "",
	mode : "",
	id : "",
};

function ShopTimeListForm({ history, dbconn, submitMove }) {
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndtDate] = useState('');
	const [listData, setListData] = useState('');
	const [fieldData] = useHandleFormData({defaultState});

	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [loading, setLoading] = useState('0');
	const strPram = fncgSetUrlPram(dbconn);

	/**
	 * 初期処理
	 */
	useEffect(() => {
		//--- ページ離脱イベントOFF
		window.onbeforeunload = null;

		//--- データ初期化
		localStorage.setItem(SEARCH_ID, '');

		//--- 検索
		fncOnClickSearch();
	}, []);

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		localStorage.setItem(SEARCH_ID, '');
		history.push('/menu' + strPram);
	}

	/**
	 * 新規ボタン
	 */
	function fncOnClickAddNew(){
		fieldData.start_date = '';
		fieldData.end_date = '';
		fieldData.mode = '1';
		fieldData.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldData));
		submitMove(fieldData);
	}

	/**
	 * コピーボタン
	 */
	function fncOnClickShopTimeCopy(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strStartDate = document.getElementById('hdn-start-date-' + strID ).value;
		var strEndDate = document.getElementById('hdn-end-date-' + strID ).value;
		fieldData.start_date = strStartDate;
		fieldData.end_date = strEndDate;
		fieldData.mode = '4';
		fieldData.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldData));
		submitMove(fieldData);
	}

	/**
	 * 編集ボタン
	 */
	function fncOnClickShopTimeUpd(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strStartDate = document.getElementById('hdn-start-date-' + strID ).value;
		var strEndDate = document.getElementById('hdn-end-date-' + strID ).value;
		fieldData.start_date = strStartDate;
		fieldData.end_date = strEndDate;
		fieldData.mode = '2';
		fieldData.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldData));
		submitMove(fieldData);
	}

	/**
	 * 削除ボタン
	 */
	function fncOnClickShopTimeDel(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strStartDate = document.getElementById('hdn-start-date-' + strID ).value;
		var strEndDate = document.getElementById('hdn-end-date-' + strID ).value;
		fieldData.start_date = strStartDate;
		fieldData.end_date = strEndDate;
		fieldData.mode = '3';
		fieldData.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldData));
		submitMove(fieldData);
	}

	/**
	 * 検索
	 */
	function fncOnClickSearch(){
		setLoading('1');
		if(startDate != '' && startDate != null){
			fieldData.start_date = startDate.getFullYear() + '/' + (startDate.getMonth() + 1) + '/' +  startDate.getDate();
		}else{
			fieldData.start_date = '';
		}
		if(endDate != '' && endDate != null){
			fieldData.end_date = endDate.getFullYear() + '/' + (endDate.getMonth() + 1) + '/' +  endDate.getDate();
		}else{
			fieldData.end_date = '';
		}
		fieldData.id = dbconn.id;

		Http.post('api/search-shoptime', {...fieldData}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setListData(response.data);
			}else{
				setListData('');
				setModalMsg(MSG_NOT_DATA);
				setShow(true);
			}
			setLoading('0');
		});
	}

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);
	}

	/**
	 * 適用開始日カーソル移動
	 */
	function fncSetStartDateFocus(){
		document.getElementById('dtStartDate').focus();
	}

	/**
	 * 適用終了日カーソル移動
	 */
	function fncSetEndDateFocus(){
		document.getElementById('dtEndDate').focus();
	}

	/**
	 * 一覧作成
	 * @returns {*}
	 */
	function fncGetShopTimeList(){
		var lstShopTime = [];

		for(var intIdx in listData){
			let limitDay = listData[intIdx].reserve_limit_day != null ? listData[intIdx].limit_day : '';
			let limitTime = listData[intIdx].reserve_limit_time != null ? listData[intIdx].com_val1 : '';

			lstShopTime.push(
				<tr key={intIdx}>
					<td className="td-shoptime-no">
						{Number(intIdx) + 1}
					</td>
					<td className="td-shoptime-startend">
						{listData[intIdx].start_date + ' ～ ' + listData[intIdx].end_date}
					</td>
					<td className="td-shoptime-interval">
						{listData[intIdx].reserve_interval != null? listData[intIdx].reserve_interval + '分' : ''}
					</td>
					<td className="td-shoptime-limit">
						{limitDay + ' ' + limitTime + '前'}
					</td>
					<td className="td-shoptime-week-cnt">
						{listData[intIdx].reserve_week_cnt != null? listData[intIdx].reserve_week_cnt + '週間' : ''}
					</td>
					<td className="td-shoptime-copy">
						<Button
							block
							className="btn-copy"
							onClick={fncOnClickShopTimeCopy}
							data-id={listData[intIdx].id}
							disabled={loading == '1'? 'disabled' : ''}
						>
							コピー
						</Button>
					</td>
					<td className="td-shoptime-upd">
						<Button
							block
							className="btn-edit"
							onClick={fncOnClickShopTimeUpd}
							data-id={listData[intIdx].id}
							disabled={loading == '1'? 'disabled' : ''}
						>
							編 集
						</Button>
						<input type="hidden" id={'hdn-shop-id-' + listData[intIdx].id} value={listData[intIdx].shop_id}></input>
						<input type="hidden" id={'hdn-start-date-' + listData[intIdx].id} value={listData[intIdx].start_date}></input>
						<input type="hidden" id={'hdn-end-date-' + listData[intIdx].id} value={listData[intIdx].end_date}></input>
					</td>
					<td className="td-shoptime-del">
						<Button
							block
							className="btn-delete"
							onClick={fncOnClickShopTimeDel}
							data-id={listData[intIdx].id}
							disabled={loading == '1'? 'disabled' : ''}
						>
							削 除
						</Button>
					</td>
				</tr>
			);
		}

		if(lstShopTime.length > 0){
			return(
				<tbody>
				{lstShopTime}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	return (
		<>
			<Form>
				<div>
					<Row className="shop-time-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<div className="text-header first-header">▼検索</div>
				<Row className="shop-time-value">
					<div className="width-search-label">
						<label className="label-shop-time-date-width">適用開始日</label>
					</div>
					<div className={"div-date-picker-width"}>
						<DatePicker
							className="date-picker"
							showPopperArrow={false}
							selected={startDate}
							onChange={date => setStartDate(date)}
							dateFormat="yyyy/MM/dd"
							customInput={<InputMask mask="9999/99/99" maskChar={null} />}
							id={"dtStartDate"}
							renderCustomHeader={({
													 date,
													 changeYear,
													 changeMonth,
												 }) =>(
								<div
									className={"div-date-picker"}
								>
									<select
										className={"select-date-picker"}
										value={date.getFullYear()}
										onChange={({target:{value}}) => changeYear(value)}
									>
										{years.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
									<select
										className={"select-date-picker"}
										value={months[date.getMonth()]}
										onChange={({ target: { value } }) =>
											changeMonth(months.indexOf(value))
										}
									>
										{months.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
								</div>
							)}
						/>
					</div>
					<div className={"div-calendar-icon"}>
						<a onClick={fncSetStartDateFocus}>
							<IoIosCalendar/>
						</a>
					</div>
					<Col md={{ span: 1}}></Col>
					<div className="width-search-label">
						<label className="label-shop-time-date-width">適用終了日</label>
					</div>
					<div className={"div-date-picker-width"}>
						<DatePicker
							className="date-picker"
							showPopperArrow={false}
							selected={endDate}
							onChange={date => setEndtDate(date)}
							dateFormat="yyyy/MM/dd"
							customInput={<InputMask mask="9999/99/99" maskChar={null} />}
							id={"dtEndDate"}
							renderCustomHeader={({
													 date,
													 changeYear,
													 changeMonth,
												 }) =>(
								<div
									className={"div-date-picker"}
								>
									<select
										className={"select-date-picker"}
										value={date.getFullYear()}
										onChange={({target:{value}}) => changeYear(value)}
									>
										{years.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
									<select
										className={"select-date-picker"}
										value={months[date.getMonth()]}
										onChange={({ target: { value } }) =>
											changeMonth(months.indexOf(value))
										}
									>
										{months.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
								</div>
							)}
						/>
					</div>
					<div className={"div-calendar-icon"}>
						<a onClick={fncSetEndDateFocus}>
							<IoIosCalendar/>
						</a>
					</div>
				</Row>
				<Row className="shop-time-value">
					<Col md={{ span: 11}}>
						<label>{MSG_INFO_DATE_INPUT}</label>
					</Col>
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-search"
							onClick={fncOnClickSearch}
							disabled={loading == '1'? 'disabled' : ''}
						>
							検 索
						</Button>
					</Col>
				</Row>

				<div>
					<Row className="shop-time-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-new"
								onClick={fncOnClickAddNew}
								disabled={loading == '1'? 'disabled' : ''}
							>
								新 規
							</Button>
						</Col>
					</Row>
				</div>

				<div className="text-header first-header">▼一覧</div>
				<Row className="shop-time-value">
					<Col md={{ span: 12}}>
						<table className={"shop-time-list"}>
							<thead>
								<tr>
									<th className={"th-shoptime-0"}>No</th>
									<th className={"th-shoptime-1"}>適用日</th>
									<th className={"th-shoptime-2"}>時間間隔</th>
									<th className={"th-shoptime-3"}>締切時間</th>
									<th className={"th-shoptime-4"}>予約可能期間</th>
									<th className={"th-shoptime-5"}>コピー</th>
									<th className={"th-shoptime-6"}>編集</th>
									<th className={"th-shoptime-7"}>削除</th>
								</tr>
							</thead>
							{fncGetShopTimeList()}
						</table>
					</Col>
				</Row>
				<div>
					<Row className="shop-time-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>

		</>
	);

}

export default ShopTimeListForm;