import React from 'react';
import './Footer.css';
import { Container } from 'react-bootstrap';
import {APP_VER} from 'Utils/version';

class Footer extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				<div className="footer">
					<div className="footer-text">
						(c) DIGIT SYSTEM All Rights Reserved.
					</div>
					<div className="footer-version">
						version {APP_VER}
					</div>
				</div>
			</>
		);
	}
}

export default Footer;