import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../Course.css';
import Http from 'Utils/Axios';
import usePost from 'Utils/EndPoints/usePost';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData';
import CustomModal from 'Utils/Modal/CustomModal';
import { FormInput } from 'Utils/FormHandlers/HandleFormProperties';
import {Button, Col, Row, Form, Modal, FormControl} from 'react-bootstrap';
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";
import {
    MSG_CMP_ADD,
    MSG_CMP_DEL,
    MSG_CMP_UPD,
    MSG_NG_ADD,
    MSG_NG_DEL, MSG_NG_DUP_NUMBER,
    MSG_NG_NUM,
    MSG_NG_UPD,
    MSG_NOT_DATA, SEARCH_ID,
} from "../../../../Utils/Common/ComConst";

const defaultState = {
    course_nm : "",
    use_on : "",
    use_off : "",
    id : "",
    mode : "",
}

const defaultCourse = {
    course_id : "",
    course_nm : "",
    course_time : "",
    disp_no : "",
    user_flg : "",
    tool_reserve_cnt_flg : "",
    reserve_over_kbn: "",
    update_id : "",
    mode : "",
    id : "",
    tool_cnt : 0,
}

function CourseMngList({ history, user, dbconn, submitMove }) {
    const [listData, setListData] = useState('');
    const [fieldData, handleDataFormat] = useHandleFormData({defaultState});
    const [courseData, handleCourseFormat] = useHandleFormData({defaultCourse});
    const [coursetool, setCourseTool] = useState('');
    const [coursestaff, setCourseStaff] = useState('');
    const [dispno, setDispNo] = useState('');

    const [modalShow, setModalShow] = useState(false);
    const [mode, setMode] = useState('');
    const [result, submitPost, errors, setErrorClear] = usePost();

    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [errMode, setErrMode] = useState('0');

    const strPram = fncgSetUrlPram(dbconn);
    const [loading, setLoading] = useState('0');

    /**
     * 起動時処理
     */
    useEffect(() => {
        //--- ページ離脱イベントOFF
        window.onbeforeunload = null;

        //--- データ初期化
        localStorage.setItem(SEARCH_ID, '');

        //--- 検索
        fncOnClickSearch();
    }, [])

    /**
     * 戻るボタン
     */
    function fncOnClickBack(){
        localStorage.setItem(SEARCH_ID, '');
        history.push('/menu' + strPram);
    }

    /**
     * 検索条件-表示する
     * @param e
     */
    function fncChangeUseOn(e){
        if( fieldData.use_on == '1' ){
            fieldData.use_on = '0'
        }else{
            fieldData.use_on = e.target.value;
        }
    }

    /**
     * 検索条件-表示する
     * @param e
     */
    function fncChangeUseOff(e){
        if( fieldData.use_off == '1' ){
            fieldData.use_off = '0'
        }else{
            fieldData.use_off = e.target.value;
        }
    }

    /**
     * 検索
     */
    function fncOnClickSearch(){
        if(dbconn.id == '' || dbconn.id == undefined) return;
        setLoading('1');
        fieldData.id = dbconn.id;
        Http.post('api/search-course', {
            ...fieldData
        }).then(response => {
            if (Object.keys(response.data).length > 0) {
                setDispNo(response.data.DispNo);
                setListData(response.data.Course);
                setErrMode('0');
                if (Object.keys(response.data.Course).length <= 0) {
                    setErrMode('1');
                    setModalMsg(MSG_NOT_DATA);
                    setShow(true);
                }
            }else{
                setListData('');
                setErrMode('1');
                setModalMsg(MSG_NOT_DATA);
                setShow(true);
            }
            setLoading('0');
        });
    }

    /**
     * 新規ボタン
     */
    function fncOnClickAddNew(){
        if(dbconn.id == '' || dbconn.id == undefined) return;
        //--- コース情報設定
        courseData.course_id = '';
        courseData.course_nm = '';
        courseData.course_time = '';
        courseData.disp_no = Number(dispno) + 5;
        courseData.user_flg = '1';
        courseData.tool_reserve_cnt_flg = '0';
        courseData.reserve_over_kbn = '3';
        courseData.update_id = user.staff_id;
        courseData.id = dbconn.id;
        courseData.mode = '1';
        localStorage.setItem(SEARCH_ID, JSON.stringify(courseData));
        submitMove(courseData);
    }

    /**
     * 編集ボタン
     */
    function fncOnClickMngUpd(e){
        if(dbconn.id == '' || dbconn.id == undefined) return;
        //--- コース情報設定
        const strID = e.currentTarget.getAttribute('data-id');
        var strCourseID = document.getElementById('hdn-course-id-' + strID ).value;
        var strCourseNm = document.getElementById('hdn-course-nm-' + strID ).value;
        var strCourseTime = document.getElementById('hdn-course-time-' + strID ).value;
        var strDispNo = listData[strID].disp_no;;
        var strUserFlg = document.getElementById('hdn-course-use-' + strID ).value;
        var strToolReserveCntFlg = document.getElementById('hdn-course-cnt-flg-' + strID ).value;
        var strReserveOverKbn = document.getElementById('hdn-course-reserve-over-kbn-' + strID).value;
        courseData.course_id = strCourseID;
        courseData.course_nm = strCourseNm;
        courseData.course_time = strCourseTime;
        courseData.disp_no = strDispNo;
        courseData.user_flg = strUserFlg;
        courseData.tool_reserve_cnt_flg = strToolReserveCntFlg;
        courseData.reserve_over_kbn = strReserveOverKbn;
        courseData.update_id = user.staff_id;
        courseData.id = dbconn.id;
        courseData.mode = '2';
        localStorage.setItem(SEARCH_ID, JSON.stringify(courseData));
        submitMove(courseData);
    }

    /**
     * 削除ボタン
     */
    function fncOnClickMngDel(e){
        if(dbconn.id == '' || dbconn.id == undefined) return;
        //--- コース情報設定
        const strID = e.currentTarget.getAttribute('data-id');
        var strCourseID = document.getElementById('hdn-course-id-' + strID ).value;
        var strCourseNm = document.getElementById('hdn-course-nm-' + strID ).value;
        var strCourseTime = document.getElementById('hdn-course-time-' + strID ).value;
        var strDispNo = listData[strID].disp_no;
        var strUserFlg = document.getElementById('hdn-course-use-' + strID ).value;
        var strToolReserveCntFlg = document.getElementById('hdn-course-cnt-flg-' + strID ).value;
        var strReserveOverKbn = document.getElementById('hdn-course-reserve-over-kbn-' + strID).value;

        courseData.course_id = strCourseID;
        courseData.course_nm = strCourseNm;
        courseData.course_time = strCourseTime;
        courseData.disp_no = strDispNo;
        courseData.user_flg = strUserFlg;
        courseData.tool_reserve_cnt_flg = strToolReserveCntFlg;
        courseData.reserve_over_kbn = strReserveOverKbn;
        courseData.update_id = user.staff_id;
        courseData.id = dbconn.id;
        courseData.mode = '3';
        localStorage.setItem(SEARCH_ID, JSON.stringify(courseData));
        submitMove(courseData);
    }

    /**
     * 表示順更新ボタン
     */
    function fncOnClickOrderUpdate(){
        if(dbconn.id == '' || dbconn.id == undefined) return;
        setLoading('1');
        //-- POST
        var aryData = {
            listdata : [],
            id : dbconn.id,
        }
        aryData.listdata.push(listData);

        Http.post('/api/upd-course-no', {
            ...aryData,
        }).then(response => {
            const strStatus = response.data.status;
            setErrMode('1');
            if(strStatus == '200'){
                setErrMode('0');
                setModalMsg(MSG_CMP_ADD);
            }else if(strStatus == '300'){
                setModalMsg(MSG_NG_NUM);
            }else if(strStatus == '301'){
                //- 表示順重複
                setModalMsg(MSG_NG_DUP_NUMBER);
            }else{
                setModalMsg(MSG_NG_ADD);
            }
            setShow(true);
        }).catch(error => {
            if (error.response) {
                alert(error.response.data)
            }
        }).finally(() => {
            setLoading('0');
        })
    }

    /**
     * 表示順変更
     * @param e
     */
    function fncChangeOrder(e){
        const intID = e.currentTarget.getAttribute('data-id');
		listData[intID].disp_no = e.target.value;
    }

    /**
     * コース登録画面クローズ
     */
    function fncOnClickCourseModalClose() {
        setModalShow(false);
        fncOnClickSearch();
    }

    /**
     * コース情報登録
     */
    function fncOnClickCourseModalOk(e){
        if(dbconn.id == '' || dbconn.id == undefined) return;

        //--- 機材選択件数
        let intToolCnt = 0;
        for(var intIdx = 0; intIdx < coursetool.length; intIdx++){
            if(coursetool[intIdx].course_tool_id != ''){
                intToolCnt += 1;
            }
        }

        //--- スタッフ選択
        let intStaffCnt = 0;
        for(var intIdx = 0; intIdx < coursestaff.length; intIdx++){
            if(coursestaff[intIdx].courses_staff_id != ''){
                intStaffCnt += 1;
            }
        }

        //--- 追加設定
        courseData.tool_cnt = intToolCnt;
        courseData.staff_cnt = intStaffCnt;
        courseData.id = dbconn.id;
        courseData.mode = mode;

        //--- チェック
        setErrorClear();
        e.preventDefault();
        submitPost('api/chk-course',{
            ...courseData,
        });
    }

    /**
     * チェック結果正常
     */
    useEffect(() => {
        if(dbconn.id == '' || dbconn.id == undefined) return;
        if (Object.keys(result).length > 0) {
            setLoading('1');
            //-- データ格納
            courseData.mode = mode;
            courseData.update_id = user.staff_id;

            //-- リクエストデータ
            var aryData = {
                course : [],
                tool : [],
                staff : [],
                id : dbconn.id,
            }
            aryData.course.push(courseData);
            aryData.tool.push(coursetool);
            aryData.staff.push(coursestaff);

            //-- POST
            Http.post('/api/add-course', {
                ...aryData,
            }).then(response => {
                const strStatus = response.data.status;
                if(strStatus == '200'){
                    setErrMode('0');
                    if(mode == '1'){
                        setModalMsg(MSG_CMP_ADD);
                    }else if(mode == '2'){
                        setModalMsg(MSG_CMP_UPD);
                    }else if(mode == '3'){
                        setModalMsg(MSG_CMP_DEL);
                    }
                }else{
                    setErrMode('1');
                    if(mode == '1'){
                        setModalMsg(MSG_NG_ADD);
                    }else if(mode == '2'){
                        setModalMsg(MSG_NG_UPD);
                    }else if(mode == '3'){
                        setModalMsg(MSG_NG_DEL);
                    }
                }
                setShow(true);
            }).catch(error => {
                if (error.response) {
                    alert(error.response.data)
                }
            }).finally(() => {
                setLoading('0');
            })
        }
    }, [result]);

    /**
     * モーダル画面クローズ
     */
    function fncReturnModalClose(){
        setShow(false);
        if(errMode == '0'){
            setModalShow(false);
            setListData('');
            fncOnClickSearch();
        }
    }

    /**
     * 一覧作成
     * @returns {*}
     */
    function fncGetCourseMngList(){
        var lstCourseMng = [];

        for(var intIdx in listData){
            lstCourseMng.push(
                <tr key={intIdx}>
                    <td className="td-coursemng-no">
                        {Number(intIdx) + 1}
                    </td>
                    <td className="td-coursemng-name">
                        {listData[intIdx].course_nm != null? listData[intIdx].course_nm : ''}
                    </td>
                    <td className="td-coursemng-time">
                        {listData[intIdx].course_time != null? listData[intIdx].course_time : ''}
                    </td>
                    <td className="td-coursemng-use">
                        {listData[intIdx].user_flg != null? listData[intIdx].user_flg == '1'? '使用する' : '使用しない' : ''}
                    </td>
                    <td className="td-coursemng-upd">
                        <Button
                            block className="btn-edit"
                            onClick={fncOnClickMngUpd}
                            data-id={intIdx}
                            disabled={loading == '1'? 'disabled' : ''}
                        >
                            編 集
                        </Button>
                        <input type="hidden" id={'hdn-course-id-' + intIdx} value={listData[intIdx].course_id} />
                        <input type="hidden" id={'hdn-course-nm-' + intIdx} value={listData[intIdx].course_nm} />
                        <input type="hidden" id={'hdn-course-time-' + intIdx} value={listData[intIdx].course_time} />
                        <input type="hidden" id={'hdn-course-no-' + intIdx} value={listData[intIdx].disp_no} />
                        <input type="hidden" id={'hdn-course-use-' + intIdx} value={listData[intIdx].user_flg} />
                        <input type="hidden" id={'hdn-course-cnt-flg-' + intIdx} value={listData[intIdx].tool_reserve_cnt_flg} />
                        <input type="hidden" id={'hdn-course-reserve-over-kbn-' + intIdx} value={listData[intIdx].reserve_over_kbn} />
                    </td>
                    <td className="td-coursemng-del">
                        <Button
                            block
                            className="btn-delete"
                            onClick={fncOnClickMngDel}
                            data-id={intIdx}
                            disabled={loading == '1'? 'disabled' : ''}
                        >
                            削 除
                        </Button>
                    </td>
                    <td className="td-coursemng-order">
                        <FormControl
                            key={listData[intIdx].course_id || ''}
                            className="input-course-mng-order"
                            type="text"
                            defaultValue={listData[intIdx].disp_no || ''}
                            onChange={fncChangeOrder}
                            name={"disp_no"}
                            data-id={intIdx}
                        />
                    </td>
                </tr>
            );
        }

        if(lstCourseMng.length > 0){
            return(
                <tbody>
                {lstCourseMng}
                </tbody>
            );
        }else{
            return(<tbody></tbody>);
        }
    }

    /**
     * 機材一覧作成
     */
    function fncGetCourseToolList(){
        var lstCourseTool = [];

        for(var intIdx in coursetool){
            if(coursetool[intIdx].course_tool_id == undefined){
                coursetool[intIdx].course_tool_id = '';
            }

            lstCourseTool.push(
                <tr key={intIdx}>
                    <td className="td-coursetool-no">
                        {Number(intIdx) + 1}
                    </td>
                    <td className="td-coursetool-sel">
                        <label className={"label-course-radio"}>
                            <input
                                className="course-mng-checkbox"
                                type="checkbox"
                                value="1"
                                defaultChecked={coursetool[intIdx].course_tool_id != undefined &&
                                coursetool[intIdx].course_tool_id != null &&
                                coursetool[intIdx].course_tool_id != ''? 'checked' : ''
                                }
                                onChange={fncChangeTool}
                                name="course_tool_id"
                                id={'course-tool-' + intIdx}
                                data-id={intIdx}
                                disabled={mode == '3'? 'disabled' : ''}
                            />
                        </label>
                        <input type="hidden" id={'hdn-tool-id-' + intIdx} value={coursetool[intIdx].tool_id}></input>
                    </td>
                    <td className="td-coursetool-name">
                        {coursetool[intIdx].tool_nm != null? coursetool[intIdx].tool_nm : ''}
                    </td>
                </tr>
            );
        }

        if(lstCourseTool.length > 0){
            return(
                <tbody className={"course-tool-tbody"}>
                {lstCourseTool}
                </tbody>
            );
        }else{
            return(<tbody></tbody>);
        }
    }

    /**
     * スタッフ一覧作成
     */
    function fncGetCourseStaffList(){
        var lstCourseStaff = [];
        if(coursestaff == "") return;

        for(var intIdx in coursestaff){
            lstCourseStaff.push(
                <tr key={intIdx}>
                    <td className="td-coursestaff-no">
                        {Number(intIdx) + 1}
                    </td>
                    <td className="td-coursestaff-sel">
                        <label className={"label-course-radio"}>
                            <input
                                className="course-mng-checkbox"
                                type="checkbox"
                                value="1"
                                defaultChecked={coursestaff[intIdx].courses_staff_id != ''? 'checked' : ''}
                                onChange={fncChangeStaff}
                                name="courses_staff_id"
                                id={'course-staff-' + intIdx}
                                data-id={intIdx}
                                disabled={mode == '3'? 'disabled' : ''}
                            />
                        </label>
                        <input type="hidden" id={'hdn-staff-id-' + intIdx} value={coursestaff[intIdx].staff_id}></input>
                    </td>
                    <td className="td-coursestaff-name">
                        {coursestaff[intIdx].staff_nm != null? coursestaff[intIdx].staff_nm : ''}
                    </td>
                </tr>
            );
        }

        if(lstCourseStaff.length > 0){
            return(
                <tbody className={"course-staff-tbody"}>
                {lstCourseStaff}
                </tbody>
            );
        }else{
            return(<tbody></tbody>);
        }
    }

    /**
     * 機材選択
     * @param e
     */
    function fncChangeTool(e){
        const intID = e.currentTarget.getAttribute('data-id');

        if( coursetool[intID].course_tool_id != undefined && coursetool[intID].course_tool_id != '' ){
            coursetool[intID].course_tool_id = '';
        }else{
            coursetool[intID].course_tool_id = coursetool[intID].tool_id;
        }
    }

    /**
     * スタッフ選択
     * @param e
     */
    function fncChangeStaff(e){
        const intID = e.currentTarget.getAttribute('data-id');

        if( coursestaff[intID].courses_staff_id != '' ){
            coursestaff[intID].courses_staff_id = '';
        }else{
            coursestaff[intID].courses_staff_id = coursestaff[intID].staff_id;
        }
    }

    /**
     * 全選択
     */
    function fncOnClickAllSel(){
        if(coursestaff == undefined) return;
        if(coursestaff.length > 0){
            for(var intIdx = 0; intIdx < coursestaff.length; intIdx++){
                coursestaff[intIdx].courses_staff_id = coursestaff[intIdx].staff_id;
                document.getElementById('course-staff-' + intIdx).checked = true;
            }
        }
    }

    /**
     * 全解除
     */
    function fncOnClickAllKaijo(){
        if(coursestaff == undefined) return;
        if(coursestaff.length > 0){
            for(var intIdx = 0; intIdx < coursestaff.length; intIdx++){
                coursestaff[intIdx].courses_staff_id = '';
                document.getElementById('course-staff-' + intIdx).checked = false;
            }
        }
    }

    return (
        <>
            <Form>
                <div>
                    <Row className="course-mng-row-header">
                        <Col md={{ span: 1, offset: 11}}>
                            <Button
                                block
                                className="btn-back"
                                onClick={fncOnClickBack}
                                disabled={loading == '1'? 'disabled' : ''}
                            >
                                戻 る
                            </Button>
                        </Col>
                    </Row>
                    <div className="text-header first-header">▼検索</div>
                    <Row className="course-mng-value">
                        <div className="width-search-label div-course-mng-search-course">
                            <label className="label-course-mng-title">コース名</label>
                        </div>
                        <div className={"div-course-mng-search-nm"}>
                            <FormControl
                                className="input-course-mng-nm"
                                type="text"
                                value={fieldData.course_nm || ''}
                                onChange={handleDataFormat}
                                name="course_nm"
                                {...FormInput}
                            />
                        </div>
                        <div className={"div-course-mng-search-used"}>
                            <label className={"label-course-mng-padding"}>
                                <input
                                    className=""
                                    type="checkbox"
                                    value="1"
                                    onChange={fncChangeUseOn}
                                    name="use_on"
                                /> 使用する
                            </label>
                        </div>
                        <div className={"div-course-mng-search-notuse"}>
                            <label className={"label-course-mng-padding"}>
                                <input
                                    className=""
                                    type="checkbox"
                                    value="1"
                                    onChange={fncChangeUseOff}
                                    name="use_off"
                                /> 使用しない
                            </label>
                        </div>
                        <div className={"div-course-mng-search-search"}>
                            <Button
                                block
                                className="btn-search"
                                onClick={fncOnClickSearch}
                                disabled={loading == '1'? 'disabled' : ''}
                            >
                                検 索
                            </Button>
                        </div>
                    </Row>
                    <div>
                        <Row className="course-mng-row-header">
                            <Col md={{ span: 1, offset: 9}}>
                                <Button
                                    block
                                    className="btn-new"
                                    onClick={fncOnClickAddNew}
                                    disabled={loading == '1'? 'disabled' : ''}
                                >
                                    新 規
                                </Button>
                            </Col>
                            <Col md={{ span: 2}}>
                                <Button
                                    block
                                    className="btn-edit"
                                    onClick={fncOnClickOrderUpdate}
                                    disabled={loading == '1'? 'disabled' : ''}
                                >
                                    表示順更新
                                </Button>
                            </Col>
                        </Row>
                    </div>

                    <div className="text-header first-header">▼一覧</div>
                    <Row className="course-mng-value">
                        <Col md={{ span: 12}}>
                            <table className={"course-mng-list"}>
                                <thead>
                                    <tr>
                                        <th className={"th-coursemng-no"}>No</th>
                                        <th className={"th-coursemng-name"}>コース名</th>
                                        <th className={"th-coursemng-time"}>所要時間</th>
                                        <th className={"th-coursemng-use"}>使用有無</th>
                                        <th className={"th-coursemng-upd"}>更新</th>
                                        <th className={"th-coursemng-del"}>削除</th>
                                        <th className={"th-coursemng-order"}>表示順</th>
                                    </tr>
                                </thead>
                                {fncGetCourseMngList()}
                            </table>
                        </Col>
                    </Row>

                    <Row className="course-mng-row-header">
                        <Col md={{ span: 1, offset: 11}}>
                            <Button
                                block
                                className="btn-back"
                                onClick={fncOnClickBack}
                                disabled={loading == '1'? 'disabled' : ''}
                            >
                                戻 る
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>

            <Modal show={modalShow} onHide={fncOnClickCourseModalClose} >
                <Modal.Header closeButton>
                    <Modal.Title>{mode == '1'? '-コース登録-' : mode == '2'? '-コース更新-' : '-コース削除-'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(errors.course != undefined) && (
                        <Row className="course-mng-error-row">
                            <Col md={{ span: 12}}>
                                <span className="error-span">{errors.course[0]}</span>
                            </Col>
                        </Row>
                    ) }
                    <Row className="modal-course-mng-value">
                        <div className="width-search-label">
                            <label className={"modal-label-course-mng-title"}>コース名<a style={{color:"#FF0000"}}>[必須]</a></label>
                        </div>
                        <FormControl
                            className="input-course-mng-course"
                            type="text"
                            value={courseData.course_nm || ''}
                            onChange={handleCourseFormat}
                            name="course_nm"
                            maxLength="50"
                            readOnly={mode == '3'? 'readOnly' : ''}
                            {...FormInput}
                        />
                    </Row>
                    {(errors.course_nm != undefined) && (
                        <Row className="course-mng-error-row">
                            <div className="width-search-label"></div>
                            <div>
                                <span className="error-span">{errors.course_nm[0]}</span>
                            </div>
                        </Row>
                    ) }
                    <Row className="modal-course-mng-value">
                        <div className="width-search-label">
                            <label className={"modal-label-course-mng-title"}>所要時間<a style={{color:"#FF0000"}}>[必須]</a></label>
                        </div>
                        <FormControl
                            className="input-course-mng-cnt"
                            type="text"
                            value={courseData.course_time || ''}
                            onChange={handleCourseFormat}
                            name="course_time"
                            maxLength="3"
                            readOnly={mode == '3'? 'readOnly' : ''}
                            {...FormInput}
                        />
                        <div>
                            <label className={"label-course-mng-right-time"}>分</label>
                        </div>
                    </Row>
                    {(errors.course_time != undefined) && (
                        <Row className="course-mng-error-row">
                            <div className="width-search-label"></div>
                            <div>
                                <span className="error-span">{errors.course_time? errors.course_time[0] : ''}</span>
                            </div>
                        </Row>
                    ) }
                    <Row className="modal-course-mng-value">
                        <div className="width-search-label">
                            <label className={"modal-label-course-mng-title"}>表示順</label>
                        </div>
                        <FormControl
                            className="input-course-mng-cnt"
                            type="text"
                            value={courseData.disp_no || ''}
                            onChange={handleCourseFormat}
                            name="disp_no"
                            maxLength={"4"}
                            readOnly={mode == '3'? 'readOnly' : ''}
                            {...FormInput}
                        />
                    </Row>
                    {(errors.disp_no != undefined) && (
                        <Row className="course-mng-error-row">
                            <div className="width-search-label"></div>
                            <div>
                                <span className="error-span">{errors.disp_no? errors.disp_no[0] : ''}</span>
                            </div>
                        </Row>
                    ) }
                    <Row className="modal-course-mng-value">
                        <div className="div-modal-course-mng-disp">
                            <label className={"modal-label-course-mng-title"}>表示有無</label>
                        </div>
                        <div className={"div-modal-course-mng-used"}>
                            <label>
                                <input
                                    type="radio"
                                    className="user-update-detail-radio"
                                    name="user_flg"
                                    value="1"
                                    onChange={handleCourseFormat}
                                    checked={courseData.user_flg === "1"}
                                    disabled={mode == '3'? 'disabled' : ''}
                                /> 使用する
                            </label>
                        </div>
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    className="user-update-detail-radio"
                                    name="user_flg"
                                    value="0"
                                    onChange={handleCourseFormat}
                                    checked={courseData.user_flg === "0"}
                                    disabled={mode == '3'? 'disabled' : ''}
                                /> 使用しない
                            </label>
                        </div>
                    </Row>
                    <Row className="modal-course-mng-value">
                        <div className="div-modal-course-mng-disp">
                            <label className={"modal-label-course-mng-title"}>予約可能数</label>
                        </div>
                        <div className={"div-modal-course-mng-used"}>
                            <label>
                                <input
                                    type="checkbox"
                                    className="user-update-detail-radio"
                                    name="user_flg"
                                    value="1"
                                    onChange={handleCourseFormat}
                                    checked={courseData.user_flg === "1"}
                                    disabled={mode == '3'? 'disabled' : ''}
                                /> 機材数を予約可能数とする
                            </label>
                        </div>
                    </Row>
                    <Row className="modal-course-mng-value">
                        <div className="div-modal-course-mng-disp">
                            <label className={"modal-label-course-mng-title"}></label>
                        </div>
                        <div className={"div-modal-course-mng-used"}>
                            <label>
                                ※担当スタッフが必要ないコースの場合にチェックを付けてください
                            </label>
                        </div>
                    </Row>
                    <br />
                    <Row className="modal-course-mng-value">
                        <Col md={{ span: 12}}>
                            <div className="text-header first-header course-modal-title">▼機材</div>
                        </Col>
                    </Row>
                    {(errors.tool_cnt != undefined) && (
                        <Row className="course-mng-error-row">
                            <Col md={{ span: 12}}>
                                <span className="error-span">{errors.tool_cnt[0]}</span>
                            </Col>
                        </Row>
                    ) }
                    <Row className="course-mng-value">
                        <Col md={{ span: 12}}>
                            <table className={"course-tool-list"}>
                                <thead className={"course-tool-thead"}>
                                <tr>
                                    <th className={"th-coursetool-no"}>No</th>
                                    <th className={"th-coursetool-sel"}>選択</th>
                                    <th className={"th-coursetool-name"}>機材名</th>
                                </tr>
                                </thead>
                                {fncGetCourseToolList()}
                            </table>
                        </Col>
                    </Row>
                    <br />
                    <Row className="modal-course-mng-value">
                        <Col md={{ span: 4}}>
                            <div className="text-header first-header course-modal-title">▼スタッフ</div>
                        </Col>
                        <Col md={{ span: 2}}></Col>
                        <Col md={{ span: 3}}>
                            <Button
                                block
                                className="btn-all-sel modal-btn-format"
                                onClick={fncOnClickAllSel}
                                disabled={loading == '1'? 'disabled' : ''}
                            >
                                全選択
                            </Button>
                        </Col>
                        <Col md={{ span: 3}}>
                            <Button
                                block
                                className="btn-all-rel modal-btn-format"
                                onClick={fncOnClickAllKaijo}
                                disabled={loading == '1'? 'disabled' : ''}
                            >
                                全解除
                            </Button>
                        </Col>
                    </Row>
                    {(errors.staff_cnt != undefined) && (
                        <Row className="course-mng-error-row">
                            <Col md={{ span: 12}}>
                                <span className="error-span">{errors.staff_cnt[0]}</span>
                            </Col>
                        </Row>
                    ) }
                    <Row className="course-mng-value">
                        <Col md={{ span: 12}}>
                            <table className={"course-staff-list"}>
                                <thead className={"course-staff-thead"}>
                                <tr>
                                    <th className={"th-coursestaff-no"}>No</th>
                                    <th className={"th-coursestaff-sel"}>選択</th>
                                    <th className={"th-coursestaff-name"}>スタッフ名</th>
                                </tr>
                                </thead>
                                {fncGetCourseStaffList()}
                            </table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="course-mng-value">
                        <Col md={{ span: 6}}>
                            <Button
                                block
                                className="btn-back modal-btn-format"
                                onClick={fncOnClickCourseModalClose}
                                disabled={loading == '1'? 'disabled' : ''}
                            >
                                戻 る
                            </Button>
                        </Col>
                        <Col md={{ span: 6}}>
                            <Button
                                block
                                className="btn-save modal-btn-format"
                                onClick={fncOnClickCourseModalOk}
                                disabled={loading == '1'? 'disabled' : ''}
                            >
                                {mode == '1'? '登 録' : mode == '2'? '更 新' : '削 除'}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <CustomModal
                show={show}
                header={'メッセージ'}
                message={modalMsg}
                handleClose={fncReturnModalClose}
            />
        </>
    );
}

export default CourseMngList;