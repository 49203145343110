import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../ReserveSts.css';
import Http from 'Utils/Axios';
import {Button, Col, Row, Form, Modal, FormControl} from 'react-bootstrap';
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";
import {
	months,
	MSG_RESERVE_STAFF_HOLIDAY_SHOP,
	MSG_RESERVE_STAFF_NON_SHOPTIME,
	MSG_RESERVE_STAFF_NON_STAFFTIME, SEARCH_ID, years
} from "../../../../Utils/Common/ComConst";
import DatePicker from 'react-datepicker';
import '../../../../Utils/Common/react-datepicker.css';
import { IoIosCalendar } from "react-icons/io";

const dtNow = new Date();

const defaultState = {
	reserve_date : dtNow.getFullYear() + '-' + (dtNow.getMonth() + 1) + '-' + dtNow.getDate(),
	course_id : "",
	week_kbn : "0",
	sel_date : "",
	id : "",
	startDate : dtNow.getFullYear() + '-' + (dtNow.getMonth() + 1) + '-' + dtNow.getDate(),
}

const defaultReserve = {
	reserve_id : "",
	reserve_date : "",
	start_time : "",
	course_id : "",
	staff_id : "",
	user_id : "",
	week_kbn : "0",
	sel_date : "",
	mode : "",
	id : "",
}

function ReserveStsForm({ history, dbconn, submitMove }) {
	const [calendar, setCalendar] = useState('');
	const [data, setData] = useState(defaultState);
	const [reservedata, setReserveData] = useState(defaultReserve);
	const [selcalendar, setSelCalendar] = useState('');
	const [selcourse, setSelCourse] = useState('');
	const [reserve, setReserve] = useState('');
	const [loading, setLoading] = useState('0');
	const strPram = fncgSetUrlPram(dbconn);
	const [startDate, setStartDate] = useState(dtNow);

	/**
	 * 初期処理
	 */
	useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;

		//--- ストレージ取得
		//-- 画面情報
		let searchID = localStorage.getItem(SEARCH_ID);
		if(searchID != '' && searchID != undefined){
			searchID = JSON.parse(searchID);
			if(searchID.week_kbn != '' && searchID.week_kbn != undefined){
				data.week_kbn = searchID.week_kbn;
			}else{
				data.week_kbn = '0';
			}
			if(searchID.reserve_date != '' && searchID.reserve_date != undefined){
				global.ReserveDate = searchID.reserve_date;
				data.reserve_date = searchID.reserve_date;
				reservedata.reserve_date = searchID.reserve_date;
			}
			if(searchID.sel_date != '' && searchID.sel_date != undefined){
				global.SelDate = searchID.sel_date;
				data.sel_date = global.SelDate;
				reservedata.sel_date = global.SelDate;
			}
		}else{
			if(global.WeekKbn != '' && global.WeekKbn != undefined){
				data.week_kbn = global.WeekKbn;
			}else{
				data.week_kbn = '0';
			}
			if(global.ReserveDate != '' && global.ReserveDate != undefined){
				data.reserve_date = global.ReserveDate;
			}
		}
		data.id = dbconn.id;

		//--- カレンダー情報取得
		document.body.style.cursor = 'wait';
		Http.post('api/get-ressts-calendar',{
			...data
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setCalendar(response.data);
				//setSelCalendar(dtNow.getFullYear() + '-' + (dtNow.getMonth() + 1) + '-' + dtNow.getDate());
				if(global.SelDate != '' && global.SelDate != undefined){
					setSelCalendar(global.SelDate);
					data.sel_date = global.SelDate;
					reservedata.sel_date = global.SelDate;
					//global.ReserveDate = global.SelDate;
					//data.reserve_date = global.SelDate;
					//reservedata.reserve_date = global.SelDate;
				}else{
					setSelCalendar(response.data[0].calendar_date);
					global.SelDate = response.data[0].calendar_date;
					data.sel_date = response.data[0].calendar_date;
					reservedata.sel_date = response.data[0].calendar_date;
					global.ReserveDate = response.data[0].calendar_date;
					data.reserve_date = response.data[0].calendar_date;
					reservedata.reserve_date = response.data[0].calendar_date;
				}
				data.week_kbn = '3';
				localStorage.setItem(SEARCH_ID, JSON.stringify(data));
			}
		}).finally(() => {
			document.body.style.cursor = 'auto';
			global.WeekKbn = '0';
			data.week_kbn = global.WeekKbn;
			reservedata.week_kbn = global.WeekKbn;
		});
	}, [])

	/**
	 * 予約状況情報取得
	 */
	useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
		if(global.SelDate != undefined && global.SelDate != ''){
			setSelCalendar(global.SelDate);
		}
		if(selcalendar == undefined || selcalendar == '' ) return;
		setLoading('1');
		data.sel_date = selcalendar;
		data.startDate = selcalendar;
		data.course_id = selcourse;
		data.id = dbconn.id;
		reservedata.reserve_date = global.ReserveDate
		reservedata.sel_date = selcalendar
		reservedata.startDate = selcalendar
		//localStorage.setItem(SEARCH_ID, JSON.stringify(data));

		document.body.style.cursor = 'wait';
		Http.post('api/get-ressts-data', {
			...data
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				if(response.data.error == '' || response.data.error == undefined){
					setReserve(response.data);
					window.scrollTo(0, 0);
					if(response.data[0].holiday == '0'){
						setLoading('0');
					}else{
						// 休業日
						setLoading('4');
					}
				}else if(response.data.error == '1'){
					// 店舗時間なし
					setReserve('');
					setLoading('2');
				}else if(response.data.error == '2'){
					// シフトなし
					setReserve('');
					setLoading('3')
				}
			}else{
				setReserve('');
				setLoading('2');
			}
		}).finally(() => {
			document.body.style.cursor = 'auto';
		});

	}, [selcalendar])

	/**
	 * 定期的に最新
	 */
	//useEffect(() => {
	//	const interval = setInterval(() => {
	//		fncOnClickNewDisp();
	//	}, 300000);
	//	return () => clearInterval(interval);
	//}, []);

	/**
	 *　カレンダー作成
	 */
	function fncGetCalendarList(){
		var lstCalendar = [];

		if(calendar == '' || calendar == undefined) return;

		//--- 前週
		lstCalendar.push(
			<td className={"td-calendar"} key={'00'}>
				<Button
					block
					className={"btn-ago"}
					onClick={fncOnClickBefWeek}
					data-id="00"
					disabled={loading == '1'? 'disabled' : ''}
				>
					{'<'}
				</Button>
				<input type="hidden" id={'hdn-calendar-date-00'} value={calendar[0].calendar_date}></input>
				<input type="hidden" id={'hdn-week-kbn-00'} value={calendar[0].week_kbn}></input>
			</td>
		);

		//--- カレンダー
		for(var intIdx in calendar){
			lstCalendar.push(
				<td className={"td-calendar"} key={intIdx}>
					<Button
						block
						className={ calendar[intIdx].calendar_date == data.sel_date ? "btn-select-date" :
									calendar[intIdx].week_kbn == '5' ? "btn_saturday" :
									calendar[intIdx].week_kbn == '6' ? "btn_sunday" :
									calendar[intIdx].week_kbn == '7' ? "btn_holiday" : "btn_weekday"}
						onClick={fncOnClickCalendar}
						data-id={intIdx}
						disabled={loading == '1'? 'disabled' : ''}
					>
						{calendar[intIdx].week_day}
					</Button>
					<input type="hidden" id={'hdn-calendar-date-' + intIdx} value={calendar[intIdx].calendar_date}></input>
					<input type="hidden" id={'hdn-week-kbn-' + intIdx} value={calendar[intIdx].week_kbn}></input>
				</td>
			);
		}

		//--- 次週
		lstCalendar.push(
			<td className={"td-calendar"} key={'88'}>
				<Button
					block
					className={"btn-next"}
					onClick={fncOnClickNextWeek}
					data-id="88"
					disabled={loading == '1'? 'disabled' : ''}
				>
					{'>'}
				</Button>
				<input type="hidden" id={'hdn-calendar-date-88'} value={calendar[0].calendar_date}></input>
				<input type="hidden" id={'hdn-week-kbn-88'} value={calendar[0].week_kbn}></input>
			</td>
		);

		//--- 本日
		lstCalendar.push(
			<td className={"td-calendar"} key={'99'}>
				<Button
					block
					className={"btn-now"}
					onClick={fncOnClickNowWeek}
					data-id="99"
					disabled={loading == '1'? 'disabled' : ''}
				>
					本日へ
				</Button>
				<input type="hidden" id={'hdn-calendar-date-99'} value={calendar[0].calendar_date}></input>
				<input type="hidden" id={'hdn-week-kbn-99'} value={calendar[0].week_kbn}></input>
			</td>
		);

		if(lstCalendar.length > 0){
			return(
				<>
					{lstCalendar}
				</>
			);
		}else{
			return(
				<></>
			);
		}
	}

	/**
	 * 予約状況作成
	 * @returns {*}
	 */
	function fncGetReserveList(){
		var lstReserve = [];

		if(reserve == '' || reserve == undefined) return(<table className={"reserve-list"}></table>);
		if(reserve[0]['ResTime'] == undefined) return(<table className={"reserve-list"}></table>);

		//--- ヘッダー
		var lstHeader = [];
		lstHeader.push(<th className={"th-user-time"} key={'00'}></th>);
		for(var intIdx in reserve[0]['ResTime']){
			if (reserve[0]['ResTime'][intIdx].OnlyToolCourseFlg != '1') {
				lstHeader.push(
					<th className={"th-user"} key={intIdx}>
						{reserve[0]['ResTime'][intIdx].StaffDispNm? reserve[0]['ResTime'][intIdx].StaffDispNm : reserve[0]['ResTime'][intIdx].StaffNm}
					</th>
				);
			} else {
				lstHeader.push(
					<th className={"th-user-tool"} key={intIdx}>
						{reserve[0]['ResTime'][intIdx].StaffDispNm? reserve[0]['ResTime'][intIdx].StaffDispNm : reserve[0]['ResTime'][intIdx].StaffNm}
					</th>
				);
			}
			// lstHeader.push(
			// 	<th className={"th-user"} key={intIdx}>
			// 		{reserve[0]['ResTime'][intIdx].StaffDispNm? reserve[0]['ResTime'][intIdx].StaffDispNm : reserve[0]['ResTime'][intIdx].StaffNm}
			// 	</th>
			// );
		}

		//--- ヘッダー格納
		if(lstHeader.length > 0){
			lstReserve.push(
				<thead key="000">
				<tr>
					{lstHeader}
				</tr>
				</thead>
			);
		}

		//--- 詳細
		var lstDetail = [];
		for(var intIdx in reserve){
			var lstData = [];
			//-- 時間
			lstData.push(
				<td className={"td-user-time"} key={'dtl-' + intIdx + '-000'}>
					{reserve[intIdx]['ResTime'][0].StartTime}
				</td>
			);

			// var strToolFlg = '';
			// if (reserve[intIdx]['ResTime'][intIdx].OnlyToolCourseFlg == '1') {
			// 	var strToolFlg = '-tool';
			// }

			for(var intDtl in reserve[0]['ResTime']){
				//-- 予約済み会員
				var lstRes = [];

				var strToolFlg = '';
				if (reserve[intIdx]['ResTime'][intDtl].OnlyToolCourseFlg == '1') {
					var strToolFlg = '-tool';
				}

				if(reserve[intIdx]['ResTime'][intDtl]['Reserve'] != undefined){
					for(var intRes in reserve[intIdx]['ResTime'][intDtl]['Reserve']){
						for(var intUser in reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['ReserveID']){
							if(reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['ReserveID'] != undefined){
								var strUserID = reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['UserID'][intUser];
								// 予約者名
								var strUserNm = reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['UserNm'][intUser];
								var strReserveNm = reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['ReserveNm'][intUser];
								// 要望・店舗メモ
								let reservNote = reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['ReserveNote'][intUser];
								if(reservNote == null || reservNote == undefined) reservNote = '';
								let staffNote = reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['StaffNote'][intUser];
								if(staffNote == null || staffNote == undefined) staffNote = '';
								var noteFlg = '';
								if (reservNote != '' || staffNote != '') {
									noteFlg = '【！】';
								}
								lstRes.push(
									<Button
										className="reserve-button"
										key={'res-btn-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser}
										onClick={fncOnClickReserveUser}
										onMouseOver={fncMouseOver}
										onMouseOut ={fncMouseOut}
										data-id={intIdx + '-' + intDtl + '-' + intRes + '-' + intUser}
									>
										{(strUserID != "9999999998" && strUserID != "9999999999")?
											strUserNm + noteFlg : (strReserveNm != ""? strReserveNm + noteFlg : strUserNm + noteFlg)}
									</Button>
								);
								lstRes.push(
									<input type="hidden" key={'res-usernm-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} id={'hdn-user-nm-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} value={reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['UserNm'][intUser] + "（" + reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['UserKn'][intUser] + "）"}></input>
								);
								lstRes.push(
									<input type="hidden" key={'res-coursenm-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} id={'hdn-course-nm-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} value={reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['RsvCourse'][intUser]}></input>
								);
								lstRes.push(
									<input type="hidden" key={'res-res-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} id={'hdn-reserve-id-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} value={reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['ReserveID'][intUser]}></input>
								);
								lstRes.push(
									<input type="hidden" key={'res-user-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} id={'hdn-user-id-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} value={reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['UserID'][intUser]}></input>
								);
								lstRes.push(
									<input type="hidden" key={'res-reserve-nm-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} id={'hdn-resserve-nm-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} value={reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['ReserveNm'][intUser]}></input>
								);
								lstRes.push(
									<input type="hidden" key={'res-userno-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} id={'hdn-user-no-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} value={reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['UserNo'][intUser]}></input>
								);
								lstRes.push(
									<input type="hidden" key={'res-tel-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} id={'hdn-tel-id-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} value={reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['UserTel'][intUser]}></input>
								);
								lstRes.push(
									<input type="hidden" key={'res-note-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} id={'hdn-note-id-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} value={reservNote}></input>
								);
								lstRes.push(
									<input type="hidden" key={'res-staffnote-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} id={'hdn-staffnote-id-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} value={staffNote}></input>
								);
								lstRes.push(
									<input type="hidden" key={'res-type-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} id={'hdn-type-id-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} value={reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['ReserveType'][intUser]}></input>
								);
								lstRes.push(
									<input type="hidden" key={'res-mail-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} id={'hdn-mail-id-' + intIdx + '-' + intDtl + '-' + intRes + '-' + intUser} value={reserve[intIdx]['ResTime'][intDtl]['Reserve'][intRes]['MailAddr'][intUser]}></input>
								);
							}
						}
					}
				}

				//-- 予約可否
				let lstSts = [];
				let userClassName = 'td-user' + strToolFlg;
				let strStatus = '×';
				let flgStatus = '0';
				let staffID = reserve[intIdx]['ResTime'][intDtl].StaffID;
				let startTime = reserve[intIdx]['ResTime'][intDtl].StartTime;
				let startTimeArry = startTime.split(':');
				let nowDate = new Date();
				let nowY = nowDate.getFullYear();
				let nowM = ('0' + (nowDate.getMonth() + 1)).slice(-2);
				let nowD = ('0' + nowDate.getDate()).slice(-2);
				let nowTime = ('0' + nowDate.getHours()).slice(-2);
				let nowMin = ('0' + nowDate.getMinutes()).slice(-2);
				let nowYMDhm = new Date(nowY, nowM, nowD, nowTime, nowMin);
				let startYMDhm = new Date(nowY, nowM, nowD, startTimeArry[0], startTimeArry[1]);
				for(var intRes in reserve[intIdx]['ResTime'][intDtl]['Status']){
					strStatus = reserve[intIdx]['ResTime'][intDtl]['Status'][intRes];
					if(strStatus == '×'){
						if (staffID != '9999999999') {
							flgStatus = '0';
							userClassName = 'td-user-ng' + strToolFlg;
							if(lstRes.length > 0){
								strStatus = '';
								userClassName = 'td-user' + strToolFlg;
							}
						} else {
							// 選択なしのカラムには常時≪予約を追加≫を表示
							if(lstRes.length > 0) {
								if (startYMDhm <= nowYMDhm) {
									// 過去枠には≪予約を追加≫出さない
									strStatus = '';
									userClassName = 'td-user' + strToolFlg;
								} else {
									flgStatus = '2';
								}
							}
						}
					}else{
						if(lstRes.length > 0){
							if(staffID != '9999999999'){
								strStatus = '';
								userClassName = 'td-user' + strToolFlg;
							}else{
								if(strStatus != '○'){
									// 選択なしのカラムには常時≪予約を追加≫を表示
									if (startYMDhm <= nowYMDhm) {
										// 過去枠には≪予約を追加≫出さない
										strStatus = '';
										userClassName = 'td-user' + strToolFlg;
									} else {
										flgStatus = '2';
									}
								}else{
									flgStatus = '2';
								}
							}
						}else{
							if(strStatus != '○'){
								strStatus = '×';
								userClassName = 'td-user-ng' + strToolFlg;
							}else{
								flgStatus = '1';
							}
						}
					}
				}
				if(flgStatus == '1'){
					strStatus = '○';
					userClassName = 'td-user' + strToolFlg;
				}else if(flgStatus == '2'){
					strStatus = '≪ 予約を追加 ≫';
					userClassName = 'td-user' + strToolFlg;
				}
				if(strStatus != ''){
					lstSts.push(
						<>
							{strStatus == '○' ?
								<Button className="reserve-button" onClick={fncOnClickReserve} data-id={intIdx + '-' + intDtl} key={intIdx + '-' + intDtl}>
									{strStatus}
								</Button>
								:
								strStatus == '≪ 予約を追加 ≫' ?
									<Button className="reserve-add-button" onClick={fncOnClickReserve} data-id={intIdx + '-' + intDtl} key={intIdx + '-' + intDtl}>
										{strStatus}
									</Button>
									:
									<label style={{color:'#FF0000'}}>
										{strStatus}
									</label>
							}
							<br/>
							<input type="hidden" id={'hdn-course-id-' + intIdx + '-' + intDtl} value={''}></input>
							<input type="hidden" id={'hdn-staff-id-' + intIdx + '-' + intDtl} value={reserve[intIdx]['ResTime'][intDtl].StaffID}></input>
							<input type="hidden" id={'hdn-start-time-' + intIdx + '-' + intDtl} value={reserve[intIdx]['ResTime'][intDtl].StartTime}></input>
						</>
					);
				}

				lstData.push(
					<td
						className={userClassName}
						key={'dtl-' + intIdx + '-' + intDtl}
						//onMouseOut ={fncMouseOut}
					>
						{lstSts}
						{lstRes}
					</td>
				);

			}
			lstDetail.push(
				<tr key={'row-' + intIdx}>
					{lstData}
				</tr>
			);

		}

		//--- 詳細格納
		if(lstDetail.length > 0){
			lstReserve.push(
				<tbody key={'001'}>
				{lstDetail}
				</tbody>
			);
		}

		//--- テーブル作成
		if(lstReserve.length > 0){
			return(
				<table className={"reserve-list"}>
					{lstReserve}
				</table>
			);
		}
	}

	/**
	 * カレンダー選択
	 * @param e
	 */
	function fncOnClickCalendar(e){
		//--- カレンダー日付取得
		const strID = e.currentTarget.getAttribute('data-id');
		var strCalendarDate = document.getElementById('hdn-calendar-date-' + strID ).value;
		global.SelDate = strCalendarDate;
		data.sel_date = strCalendarDate;
		reservedata.sel_date = strCalendarDate;

		//--- 選択日付設定
		//data.reserve_date = strCalendarDate;
		global.ReserveDate = data.reserve_date;
		reservedata.reserve_date = data.reserve_date;
		setSelCalendar(strCalendarDate);

		data.week_kbn = '3';
		localStorage.setItem(SEARCH_ID, JSON.stringify(data));
	}

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		global.WeekKbn = '';
		global.ReserveDate = '';
		global.SelDate = '';
		localStorage.setItem(SEARCH_ID, '');
		history.push('/menu' + strPram);
	}

	/**
	 * 前の週クリック
	 */
	function fncOnClickBefWeek(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strCalendarDate = document.getElementById('hdn-calendar-date-' + strID ).value;
		data.reserve_date = strCalendarDate;
		data.course_id = selcourse;
		data.id = dbconn.id;
		data.week_kbn = '1';

		document.body.style.cursor = 'wait';
		Http.post('api/get-ressts-calendar',{
			...data
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setCalendar(response.data);
				setSelCalendar(response.data[0].calendar_date);
				global.SelDate = response.data[0].calendar_date;
				global.ReserveDate = response.data[0].calendar_date;
				data.sel_date = response.data[0].calendar_date;
				data.reserve_date = response.data[0].calendar_date;
				data.week_kbn = '3';
				localStorage.setItem(SEARCH_ID, JSON.stringify(data));
			}
		}).finally(() => {
			document.body.style.cursor = 'auto';
		});
	}

	/**
	 * 次の週クリック
	 */
	function fncOnClickNextWeek(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strCalendarDate = document.getElementById('hdn-calendar-date-' + strID ).value;
		data.reserve_date = strCalendarDate;
		data.course_id = selcourse;
		data.id = dbconn.id;
		data.week_kbn = '2';

		document.body.style.cursor = 'wait';
		Http.post('api/get-ressts-calendar',{
			...data
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setCalendar(response.data);
				setSelCalendar(response.data[0].calendar_date);
				global.SelDate = response.data[0].calendar_date;
				global.ReserveDate = response.data[0].calendar_date;
				data.sel_date = response.data[0].calendar_date;
				data.reserve_date = response.data[0].calendar_date;
				data.week_kbn = '3';
				localStorage.setItem(SEARCH_ID, JSON.stringify(data));
			}
		}).finally(() => {
			document.body.style.cursor = 'auto';
		});
	}

	/**
	 * 今週クリック
	 */
	function fncOnClickNowWeek(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strCalendarDate = document.getElementById('hdn-calendar-date-' + strID ).value;
		data.reserve_date = strCalendarDate;
		data.course_id = selcourse;
		data.week_kbn = '0';
		data.id = dbconn.id;

		document.body.style.cursor = 'wait';
		Http.post('api/get-ressts-calendar',{
			...data
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setCalendar(response.data);
				setSelCalendar(response.data[0].calendar_date);
				global.SelDate = response.data[0].calendar_date;
				global.ReserveDate = response.data[0].calendar_date;
				data.sel_date = response.data[0].calendar_date;
				data.reserve_date = response.data[0].calendar_date;
				data.week_kbn = '3';
				localStorage.setItem(SEARCH_ID, JSON.stringify(data));
			}
		}).finally(() => {
			document.body.style.cursor = 'auto';
		});
	}

	/**
	 * 最新
 	 */
	function fncOnClickNewDisp(e) {
		if(dbconn.id == '' || dbconn.id == undefined) return;

		window.scrollTo(0, 0);
		setLoading('1');
		data.week_kbn = '3';
		data.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(data));

		document.body.style.cursor = 'wait';

		Http.post('api/get-ressts-data', {
			...data
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				if(response.data.error == '' || response.data.error == undefined){
					setReserve(response.data);
					setLoading('0');
				}else{
					setLoading('2');
				}
			}else{
				setLoading('2');
			}
		}).finally(() => {
			document.body.style.cursor = 'auto';
		});
	}

	/**
	 * 1ヶ月カレンダー選択
	 * @param e
	 */
	function fncOnClickMonthCalendar(date){
		//--- カレンダー日付取得
		var strCalendarDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date .getDate()).slice(-2);
		global.SelDate = strCalendarDate;
		data.startDate = strCalendarDate;
		data.reserve_date = strCalendarDate;
		data.course_id = selcourse;
		data.id = dbconn.id;
		data.week_kbn = '9';
		setStartDate(date);

		document.body.style.cursor = 'wait';
		Http.post('api/get-ressts-calendar',{
			...data
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setCalendar(response.data);
				setSelCalendar(response.data[0].calendar_date);
				global.SelDate = response.data[0].calendar_date;
				global.ReserveDate = response.data[0].calendar_date;
				data.sel_date = response.data[0].calendar_date;
				data.reserve_date = response.data[0].calendar_date;
				data.week_kbn = '3';
				localStorage.setItem(SEARCH_ID, JSON.stringify(data));
			}
		}).finally(() => {
			document.body.style.cursor = 'auto';
		});
	}

	/**
	 * 予約選択
	 */
	function fncOnClickReserve(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strCourseID = document.getElementById('hdn-course-id-' + strID ).value;
		var strStaffID = document.getElementById('hdn-staff-id-' + strID ).value;
		var strStartTime = document.getElementById('hdn-start-time-' + strID ).value;
		reservedata.start_time = strStartTime;
		reservedata.course_id = strCourseID;
		reservedata.staff_id = strStaffID;
		reservedata.mode = "1";
		reservedata.id = dbconn.id;
		reservedata.week_kbn = '3';
		//- 会員ID設定（らっく院連携）
		if(global.prmUserID != '' && global.prmUserID != null && global.prmUserID != undefined){
			reservedata.user_id = global.prmUserID;
		}else{
			reservedata.user_id = '';
		}
		localStorage.setItem(SEARCH_ID, JSON.stringify(reservedata));
		reservedata.reserve_date = selcalendar;
		global.WeekKbn = '3';
		submitMove(reservedata);
	}

	/**
	 * 予約済み会員選択
	 * @param e
	 */
	function fncOnClickReserveUser(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strReserveID = document.getElementById('hdn-reserve-id-' + strID ).value;
		var strUserID = document.getElementById('hdn-user-id-' + strID ).value;
		reservedata.reserve_id = strReserveID;
		reservedata.user_id = strUserID;
		reservedata.mode = "2";
		reservedata.id = dbconn.id;
		reservedata.week_kbn = '3';
		localStorage.setItem(SEARCH_ID, JSON.stringify(reservedata));
		global.WeekKbn = '3';
		submitMove(reservedata);
	}

	/**
	 * 予約済み会員名マウスオーバー
	 * @param e
	 */
	function fncMouseOver(e){
		const strID = e.currentTarget.getAttribute('data-id');
		let viewReserv = document.getElementById('reserve-detail-view');
		let viewKainNm = document.getElementById('view-kainnm');
		let viewCourse = document.getElementById('view-course');
		let viewHoho = document.getElementById('view-hoho');
		let viewKain = document.getElementById('view-kain');
		let viewTel = document.getElementById('view-tel');
		let viewMail = document.getElementById('view-mail');
		let viewNote = document.getElementById('view-note');
		let viewStaffNote = document.getElementById('view-staffnote');

		//--- 表示位置設定
		var dH = parseInt(document.body.clientHeight);
		var dif = dH - parseInt(e.pageY);
		if (dif > 700) {
			viewReserv.style.top = String(e.pageY) + 'px';
		} else {
			viewReserv.style.top = String(e.pageY - 200) + 'px';
		}
		viewReserv.style.left = String(e.pageX + 50) + 'px';
		viewReserv.style.display = 'block';

		//--- 会員情報設定
		let strUserID = document.getElementById('hdn-user-id-' + strID ).value;
		let strKainNm = document.getElementById('hdn-user-nm-' + strID ).value;
		if (strUserID >= '9999999998') {
			// 簡易または枠埋め
			let strRsvNm = document.getElementById('hdn-resserve-nm-' + strID ).value;
			if (strRsvNm != null && strRsvNm != '' && strRsvNm != undefined) {
				strKainNm = strRsvNm;
			}
		}
		let strCourse = document.getElementById('hdn-course-nm-' + strID ).value;
		let strTypeID = document.getElementById('hdn-type-id-' + strID ).value;
		let strKainNo = document.getElementById('hdn-user-no-' + strID ).value;
		let strTelID = document.getElementById('hdn-tel-id-' + strID ).value;
		let strMailID = document.getElementById('hdn-mail-id-' + strID ).value;
		let strNoteID = document.getElementById('hdn-note-id-' + strID ).value;
		let strStaffNoteID = document.getElementById('hdn-staffnote-id-' + strID ).value;
		viewKainNm.innerText = strKainNm;
		viewCourse.innerText = strCourse;
		viewHoho.innerText = strTypeID;
		viewKain.innerText = strKainNo;
		viewTel.innerText = strTelID;
		viewMail.innerText = strMailID;
		viewNote.innerText = strNoteID;
		viewStaffNote.innerText = strStaffNoteID;
	}

	/**
	 * 予約済み会員名マウスアウト
	 * @param e
	 */
	function fncMouseOut(e){
		let viewReserv = document.getElementById('reserve-detail-view');
		viewReserv.style.display = 'none';
	}


	/**
	 * モーダルクローズ
	 */
	function modalClose() {
	}

	return (
		<>
			<Form>
				<div>
					<Row className="justify-content-md-center">
						<Col md={{ span: 11 }}>
							<label>
								{loading == '1'? 'データ取得中。。。' :
									loading == '2'? MSG_RESERVE_STAFF_NON_SHOPTIME :
										loading == '3'? MSG_RESERVE_STAFF_NON_STAFFTIME :
											loading == '4'? MSG_RESERVE_STAFF_HOLIDAY_SHOP : ''}
							</label>
						</Col>

						<Col md={{ span: 1}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>

					<Row >
						<div className="padding-left-15">
						</div>
						<div className={"div-date-picker-width-sts"}>
							<DatePicker
								wrapperClassName="datePicker-stsWrapper"
								className="date-picker"
								showPopperArrow={false}
								selected={startDate}
								onChange={fncOnClickMonthCalendar}
								dateFormat="yyyy/MM/dd"
								id={"dtStartDate"}
								customInput={
									<div className="div-stsDatepicker">
										<label style={{fontSize: "1.2rem", paddingRight: "0.3rem"}}>{data.startDate}</label>
										<a style={{fontSize: "2rem"}}>
											<IoIosCalendar/>
										</a>
									</div>
								}
								renderCustomHeader={({
														 date,
														 changeYear,
														 changeMonth,
													 }) =>(
									<div
										className={"div-date-picker"}
									>
										<select
											className={"select-date-picker"}
											value={date.getFullYear()}
											onChange={({target:{value}}) => changeYear(value)}
										>
											{years.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
										<select
											className={"select-date-picker"}
											value={months[date.getMonth()]}
											onChange={({ target: { value } }) =>
												changeMonth(months.indexOf(value))
											}
										>
											{months.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
									</div>
								)}
							/>
						</div>
					</Row>

				</div>
				<div>
					<Row className="justify-content-md-center">
						<Col md={{ span: 12}}>
							<table className={"calendar-list"}>
								<tbody>
									<tr>
										{fncGetCalendarList()}
									</tr>
								</tbody>
							</table>
						</Col>
					</Row>

					<Row className="reserve-sts-form-row">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-latest"
								onClick={fncOnClickNewDisp}
								disabled={loading == '1'? 'disabled' : ''}
							>
								最 新
							</Button>
						</Col>
					</Row>
					<div id={"reserve-detail-view"}>
						<dt className={"kain-nm"} >会員名（カナ）</dt>
						<dl className={"kain-nm"} id={"view-kainnm"}></dl>
						<dt className={"rev-course"} >予約コース</dt>
						<dl className={"rev-course"} id={"view-course"}></dl>
						<dt className={"rev-hoho"} >予約方法</dt>
						<dl className={"rev-hoho"} id={"view-hoho"}></dl>
						<dt className={"kain-no"} >会員番号</dt>
						<dl className={"kain-no"} id={"view-kain"}></dl>
						<dt className={"tel"}>電話番号</dt>
						<dl className={"tel"} id={"view-tel"}></dl>
						<dt className={"mail-addr"}>メールアドレス</dt>
						<dl className={"mail-addr"} id={"view-mail"}></dl>
						<dt className={"kain-req"}>会員要望</dt>
						<dl className={"kain-req"} id={"view-note"}></dl>
						<dt className={"kain-req"}>店舗メモ</dt>
						<dl className={"kain-req"} id={"view-staffnote"}></dl>
					</div>
					<Row className="justify-content-md-left">
						<Col md={{ span: 12}}>
							{fncGetReserveList()}
						</Col>
					</Row>
				</div>
				<div>
					<Row className="justify-content-md-center">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
			</Form>

		</>
	);
}

export default ReserveStsForm;