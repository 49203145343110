import React, { useState, useEffect } from 'react';
import Http from 'Utils/Axios';

import './Header.css';
import {useLocation, withRouter} from 'react-router-dom'
import { Col, Row } from 'react-bootstrap';
import {APP_TOP, PRM_ID} from "../../Utils/Common/ComConst";
import {
	fncgGetUrlPrams,
	fncgGetUserLoginCheck,
	fncgSetUrlPram,
	isDbconnId,
	isLocalStorage,
	isStaffId
} from "../../Utils/Common/ComCom";
import Auth from '../../Utils/Auth';

function Header({ history, _dbconn, _user }) {
	const [shopnm, setShopNm] = useState('');
	const [urlpram, setUrlPram] = useState('');
	const [start, setStart] = useState('0');

	/**
	 * URLパラメータ取得
	 */
	useEffect(() => {
		//--- パラメータ取得
		setUrlPram(history.location.search);

		setStart('1');
	}, [])

	/**
	 * 店舗名取得
	 */
	 useEffect(() => {
		if(start == '0') return;
		if(_dbconn && _dbconn.id){
			let aryData ={
				id : _dbconn.id,
			}
			Http.post('api/get-shopnm',{...aryData}).then(response => {
				setShopNm(response.data.shop_nam);
			});
		}
	}, [start])

	/**
	 * ログアウト
	 */
	function logout() {
		Http.post('api/auth/logout').then(response => {
		}).catch(error => {
			
		}).finally(() => {
			Auth.SetAccessToken();
			history.push(APP_TOP + urlpram);
		});
	}

	/**
	 * ログイン
	 * @constructor
	 */
	function HandleLogin(){
		history.push(APP_TOP + urlpram);
	}

	return (
		<>
			<div className="header">
				<Row className="header-row">
					<Col md={{ span: 4 }}>				
						<span className="header-span">予 約 管 理</span>
					</Col>	
					<Col md={{ span: 4, offset: 4}}>
						<>
							<span className="header-span-user-info">
								{_user ? <a className="a-header" onClick={logout}>ログアウト</a> : <a className="a-header" onClick={HandleLogin}>ログイン</a>}
							</span>
						</>
					</Col>	
				</Row>
				<Row className="header-shop-row">
					<Col md={{ span: 12 }}>
						<span className="header-span-shop-info">{shopnm}</span>
					</Col>
				</Row>
			</div>
		</>	
	);
}

export default withRouter(Header);