import React, { useState, useEffect } from 'react';

import '../../Utils/Common/com-style.css';
import './Menu.css';
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import { Button, Col, Row, Modal } from 'react-bootstrap';
import {fncgGetUserLoginCheck, fncgGetDbconn} from "Utils/Common/ComCom";
import {
	APP_TOP,
	PRM_ID,
	DISP_FG,
	SEARCH_ID,
	SHIFT_ID, RESERVE_ID
} from "../../Utils/Common/ComConst";
import {useLocation} from "react-router-dom";

function Menu({ history, location }) {
	const [user, setUser] = useState('');
	const [shop, setShop] = useState('');
	const [urlpram, setUrlPram] = useState('');
	const [dbconn, setDbConn] = useState('');
	const [start, setStart] = useState('0');
	const [shopMsg, setShopMsg] = useState('');
	const [modalShow, setModalShow] = useState(false);
	const path = useLocation().pathname;
	const handleSetUser = userData => setUser(userData);
	const handleSetUrlPram = dat => setUrlPram(dat);
	const handleSetDbConn = dat => setDbConn(dat);
	const handleSetStart = dat => setStart(dat);

	/**
	 * ページ離脱イベント
	 * @param e
	 */
	/*
	window.onbeforeunload = function(e){
		//--- 確認メッセージ表示(トリガーとしてのみ有効、メッセージは内容は反映されない)
		e.returnValue = MSG_SYSTEM_BEFORE_UNLOAD;
	}
	*/

	/**
	 * URLパラメータ取得
	 */
	useEffect(() => {
		//--- ページ離脱イベントOFF
		window.onbeforeunload = null;

		localStorage.setItem(DISP_FG, "");
		localStorage.setItem(SEARCH_ID, "");
		localStorage.setItem(SHIFT_ID, "");
		localStorage.setItem(RESERVE_ID, "");

		//--- モーダル表示
		if(modalShow == false){
			setModalShow(true);
		}

		fncgGetDbconn({history, location, handleSetUrlPram, handleSetDbConn, handleSetStart});
	}, [])

	/**
	 * 営業情報取得
	 */
	useEffect(() => {
		if(start == '0') return;
		if(dbconn.id != '' && dbconn.id != undefined) {
			let aryData = {
				id: dbconn.id,
			}
			Http.post('api/get-eigyoinfo', {...aryData}).then(response => {
				if(response.data.status == 404){
					history.push('/not-found' + urlpram);
				}else{
					setShopMsg(response.data);
				}
			});
		}else{
			history.push(APP_TOP + urlpram);
			//history.push(APP_TOP);
		}
	}, [start])

	/**
	 * スタッフ情報取得
	 */
	useEffect(() => {
		if(start == '0') return;
		// ログインチェックを行いユーザ情報を返却する
		fncgGetUserLoginCheck({dbconn, urlpram, path, user, handleSetUser, history})
	}, [shopMsg])

	/**
	 * 店舗情報取得
	 */
	useEffect(() => {
		if(start == '0') return;
		let aryData ={
			id : dbconn.id,
		}
		Http.post('api/get-shop',{
			...aryData
		}).then(response => {
			setShop(response.data.Shop);
		});

		//--- モーダル非表示
		if(modalShow == true){
			setModalShow(false);
		}
	}, [user])

	/**
	 * 店舗管理
	 */
	function fncOnClickMoveShop()
	{
		history.push('/shop' + urlpram);
	}

	/**
	 * 店舗時間管理
	 */
	function fncOnClickMoveShopTime()
	{
		history.push('/shoptime' + urlpram);

	}

	/**
	 * 店舗営業管理
	 */
	function fncOnClickMoveShopSale()
	{
		history.push('/shopsale' + urlpram);
	}

	/**
	 * 機材管理
	 */
	function fncOnClickMoveTool()
	{
		history.push('/tool' + urlpram);
	}

	/**
	 * コース管理
	 */
	function fncOnClickMoveCourse()
	{
		history.push('/course' + urlpram);
	}

	/**
	 * スタッフ管理
	 */
	function fncOnClickMoveStaff()
	{
		history.push('/staff' + urlpram);
	}

	/**
	 * 会員管理
	 */
	function fncOnClickMoveUser()
	{
		history.push('/user' + urlpram);
	}

	/**
	 * 予約状況管理
	 */
	function fncOnClickMoveReserveInfo()
	{
		history.push('/reservests' + urlpram);
	}

	/**
	 * 予約管理
	 */
	function fncOnClickMoveReserve()
	{
		// 会員状況一覧画面の検索条件初期値を設定
		// 画面リロード時に初期値設定が飛んでしまうためここでセット
		global.g_search_normal_rsv = '1';
		global.g_search_instant_rsv = '1';
		history.push('/reserve' + urlpram);
	}

	/**
	 * メール管理
	 */
	function fncOnClickMoveMail()
	{
		history.push('/mailtmp' + urlpram);
	}

	/**
	 * DM管理
	 */
	function fncOnClickMoveDM()
	{
		history.push('/dm' + urlpram);
	}

	/**
	 * ダッシュボード
	 */
	function fncOnClickMoveDashBoard()
	{
		history.push('/dashboard' + urlpram);
	}

	/**
	 * 休日管理
	 */
	function fncOnClickMoveHoliday()
	{
		history.push('/holiday' + urlpram);
	}

	/**
	 * 営業情報表示
	 * @returns {string}
	 */
	function fncShowMessage()
	{
		let strMsg = '';
		if(shopMsg != ''){
			for(var idx = 0; idx < shopMsg.length; idx++){
				if(shopMsg[idx] != ''){
					if(strMsg != '') strMsg += '\n';
					strMsg += shopMsg[idx];
				}
			}
		}
		return strMsg;
	}

	/**
	 * モーダルクローズ
	 */
	function modalClose() {
	}

	return (
		<>
			<Layout headerText="メニュー" _dbconn={dbconn} _user={user}>
				<Row className="justify-content-md-center">
					<Col md={{ span: 6}}>
						<p className="shop-area">店舗運用</p>
					</Col>
					<Col md={{ span: 6}}>
						<p className="reserve-area">予約運用</p>
					</Col>
				</Row>

				<Row>
					<Col md={{ span: 6}}>
						<Row className="justify-content-md-center">
							<Col md={{ span: 12}}>
								<p className="shop-area">▼店舗関連</p>
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button "
									onClick={fncOnClickMoveShop}
									disabled={(user.kengen_kbn == "2" || user.kengen_kbn == null)? 'disabled' : ''}
								>
									店舗情報管理
								</Button>
							</Col>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button menu-shoptime"
									onClick={fncOnClickMoveShopTime}
									disabled={(user.kengen_kbn == "2" || user.kengen_kbn == null)? 'disabled' : ''}
								>

								</Button>
							</Col>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button menu-shopsale"
									onClick={fncOnClickMoveShopSale}
									disabled={(user.kengen_kbn == "2" || user.kengen_kbn == null)? 'disabled' : ''}
								>

								</Button>
							</Col>
						</Row>
						<Row className="justify-content-md-center">
							<Col md={{ span: 12}}>
								<p className="shop-area">▼機材関連</p>
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button "
									onClick={fncOnClickMoveTool}
									disabled={(user.kengen_kbn == "2" || user.kengen_kbn == null)? 'disabled' : ''}
								>
									機材管理
								</Button>
							</Col>
						</Row>
						<Row className="justify-content-md-center">
							<Col md={{ span: 12}}>
								<p className="shop-area">▼コース関連</p>
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button "
									onClick={fncOnClickMoveCourse}
									disabled={(user.kengen_kbn == "2" || user.kengen_kbn == null)? 'disabled' : ''}
								>
									コース管理
								</Button>
							</Col>
						</Row>
						<Row className="justify-content-md-center">
							<Col md={{ span: 12}}>
								<p className="shop-area">▼スタッフ関連</p>
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button "
									onClick={fncOnClickMoveStaff}
									disabled={(user.kengen_kbn == "2" || user.kengen_kbn == null)? 'disabled' : ''}
								>
									スタッフ管理
								</Button>
							</Col>
						</Row>
						<Row className="justify-content-md-center">
							<Col md={{ span: 12}}>
								<p className="shop-area">▼会員関連</p>
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button "
									onClick={fncOnClickMoveUser}
								>
									会員管理
								</Button>
							</Col>
						</Row>
					</Col>

					<Col md={{ span: 6}}>
						<Row className="justify-content-md-center">
							<Col md={{ span: 12}}>
								<p className="reserve-area">▼予約関連</p>
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button-reserve "
									onClick={fncOnClickMoveReserveInfo}
								>
									予約管理(ｽﾀｯﾌ状況)
								</Button>
							</Col>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button-reserve "
									onClick={fncOnClickMoveReserve}
								>
									予約管理(会員状況)
								</Button>
							</Col>
						</Row>
						<Row className="justify-content-md-center">
							<Col md={{ span: 12}}>
								<p className="reserve-area">▼メール関連</p>
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button-reserve "
									onClick={fncOnClickMoveMail}
								>
									メール管理
								</Button>
							</Col>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button-reserve "
									onClick={fncOnClickMoveDM}
								>
									DM管理
								</Button>
							</Col>
						</Row>
						<Row className="justify-content-md-center">
							<Col md={{ span: 12}}>
								<p className="reserve-area">▼その他</p>
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button-reserve menu-dashbord"
									onClick={fncOnClickMoveDashBoard}
									disabled={(user.kengen_kbn == "2" || user.kengen_kbn == null)? 'disabled' : ''}
								>

								</Button>
							</Col>
							<Col md={{ span: 4}}>
								<Button
									className="menu-button-reserve "
									onClick={fncOnClickMoveHoliday}
									disabled={(user.kengen_kbn == "2" || user.kengen_kbn == null)? 'disabled' : ''}
								>
									祝日管理
								</Button>
							</Col>
						</Row>

						<Row className="justify-content-md-center">
							<Col md={{ span: 12}}>
								<p className="shop-area">▼お知らせ</p>
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 12}}>
								<textarea
									className="info-message"
									rows="6"
									value={fncShowMessage()}
									name=""
									readOnly='readOnly'
								/>
							</Col>
						</Row>

					</Col>
				</Row>
			</Layout >

			<Modal show={modalShow} onHide={modalClose}>
			</Modal>
		</>
	);
}

export default Menu;
