import React, { useState, useEffect } from 'react';

import '../../../../Utils/Common/com-style.css';
import '../Course.css';
import Http from '../../../../Utils/Axios';
import usePost from "../../../../Utils/EndPoints/usePost";
import useHandleFormData from '../../../../Utils/FormHandlers/useHandleFormData';
import CustomModal from "../../../../Utils/Modal/CustomModal";
import { FormInput } from '../../../../Utils/FormHandlers/HandleFormProperties';
import {Form, Button, Col, FormControl, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import '../../../../Utils/Common/react-datepicker.css';
import { IoIosCalendar, IoMdTime } from "react-icons/io";
import {
    MSG_CMP_ADD,
    MSG_CMP_DEL,
    MSG_CMP_UPD,
    MSG_NG_ADD,
    MSG_NG_DEL,
    MSG_NG_UPD,
    MSG_SYSTEM_UPD_BACK,
    shopyears,
    months,
} from "../../../../Utils/Common/ComConst";
import WeekSet from "./CourseMngAddFromWeekSet";
import InputMask from 'react-input-mask';

const intervalTime = {
    time : 5,
}

const defaultCourse = {
    course_id : "",
    course_nm : "",
    course_time : "",
    disp_no : "",
    user_flg : "1",
    tool_reserve_cnt_flg : "0",
    reserve_over_kbn: "3",
    accept_start_time1 : "",
    accept_end_time1 : "",
    accept_start_time2 : "",
    accept_end_time2 : "",
    accept_start_time3 : "",
    accept_end_time3 : "",
    accept_start_time4 : "",
    accept_end_time4 : "",
    use_start_date: "",
    use_end_date: "",
    update_id : "",
    mode : "",
    id : "",
    tool_cnt : 0,
}

const defaultWeekTime = [
    {course_id : "", week_kbn : "0", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "1", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "2", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "3", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "4", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "5", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "6", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "7", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
];

const chkWeekTime = [
    {course_id : "", week_kbn : "0", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "1", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "2", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "3", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "4", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "5", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "6", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
    {course_id : "", week_kbn : "7", holiday_kbn : "0",
        accept_start_time1 : "", accept_end_time1 : "", accept_start_time2 : "", accept_end_time2 : "",
        accept_start_time3 : "", accept_end_time3 : "", accept_start_time4 : "", accept_end_time4 : "", update_id : ""},
];

const defaultHoliday = {
    holiday_kbn1 : "",
    holiday_kbn2 : "",
    holiday_kbn3 : "",
    holiday_kbn4 : "",
    holiday_kbn5 : "",
    holiday_kbn6 : "",
    holiday_kbn7 : "",
    holiday_kbn8 : "",
};

function CourseMngAddForm({history, user, data, dbconn, submitReturn}){
    const [loading, setLoading] = useState('0');
    const [fieldData, handleDataFormat, setManualField] = useHandleFormData({ ...defaultCourse});
    const [fieldHoliday, handleHolidayFormat] = useHandleFormData({ ...defaultHoliday});
    const [dataIntervalTime] = useHandleFormData({ ...intervalTime});
    const fieldWeekTime = defaultWeekTime;
    const [courseData, setCourseData] = useState('');
    const [basetime, setBaseTime] = useState('');
    const [baseweek, setBaseWeek] = useState('');
    const [weektime, setWeekTime] = useState('');
    const [coursetool, setCourseTool] = useState('');
    const [coursestaff, setCourseStaff] = useState('');
    const [weekflg, setWeekFlg] = useState('0');
    const [result, submitPost, errors, setErrorClear] = usePost();
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [errMode, setErrMode] = useState('0');

    const [defData] = useHandleFormData({ ...defaultCourse});
    const defWeekTime = chkWeekTime;
    const [deftool, setDefTool] = useState('');
    const [defstaff, setDefStaff] = useState('');

    /* 基本出勤時間*/
    const [baseStartTime1, setBaseStartTime1] = useState('');
    const [baseEndTime1, setBaseEndTime1] = useState('');
    const [baseStartTime2, setBaseStartTime2] = useState('');
    const [baseEndTime2, setBaseEndTime2] = useState('');
    const [baseStartTime3, setBaseStartTime3] = useState('');
    const [baseEndTime3, setBaseEndTime3] = useState('');
    const [baseStartTime4, setBaseStartTime4] = useState('');
    const [baseEndTime4, setBaseEndTime4] = useState('');

    /*曜日受付時間*/
    const [moStartTime1, setMoStartTime1] = useState('');
    const [moEndTime1, setMoEndTime1] = useState('');
    const [moStartTime2, setMoStartTime2] = useState('');
    const [moEndTime2, setMoEndTime2] = useState('');
    const [moStartTime3, setMoStartTime3] = useState('');
    const [moEndTime3, setMoEndTime3] = useState('');
    const [moStartTime4, setMoStartTime4] = useState('');
    const [moEndTime4, setMoEndTime4] = useState('');
    const [tuStartTime1, setTuStartTime1] = useState('');
    const [tuEndTime1, setTuEndTime1] = useState('');
    const [tuStartTime2, setTuStartTime2] = useState('');
    const [tuEndTime2, setTuEndTime2] = useState('');
    const [tuStartTime3, setTuStartTime3] = useState('');
    const [tuEndTime3, setTuEndTime3] = useState('');
    const [tuStartTime4, setTuStartTime4] = useState('');
    const [tuEndTime4, setTuEndTime4] = useState('');
    const [weStartTime1, setWeStartTime1] = useState('');
    const [weEndTime1, setWeEndTime1] = useState('');
    const [weStartTime2, setWeStartTime2] = useState('');
    const [weEndTime2, setWeEndTime2] = useState('');
    const [weStartTime3, setWeStartTime3] = useState('');
    const [weEndTime3, setWeEndTime3] = useState('');
    const [weStartTime4, setWeStartTime4] = useState('');
    const [weEndTime4, setWeEndTime4] = useState('');
    const [thStartTime1, setThStartTime1] = useState('');
    const [thEndTime1, setThEndTime1] = useState('');
    const [thStartTime2, setThStartTime2] = useState('');
    const [thEndTime2, setThEndTime2] = useState('');
    const [thStartTime3, setThStartTime3] = useState('');
    const [thEndTime3, setThEndTime3] = useState('');
    const [thStartTime4, setThStartTime4] = useState('');
    const [thEndTime4, setThEndTime4] = useState('');
    const [frStartTime1, setFrStartTime1] = useState('');
    const [frEndTime1, setFrEndTime1] = useState('');
    const [frStartTime2, setFrStartTime2] = useState('');
    const [frEndTime2, setFrEndTime2] = useState('');
    const [frStartTime3, setFrStartTime3] = useState('');
    const [frEndTime3, setFrEndTime3] = useState('');
    const [frStartTime4, setFrStartTime4] = useState('');
    const [frEndTime4, setFrEndTime4] = useState('');
    const [saStartTime1, setSaStartTime1] = useState('');
    const [saEndTime1, setSaEndTime1] = useState('');
    const [saStartTime2, setSaStartTime2] = useState('');
    const [saEndTime2, setSaEndTime2] = useState('');
    const [saStartTime3, setSaStartTime3] = useState('');
    const [saEndTime3, setSaEndTime3] = useState('');
    const [saStartTime4, setSaStartTime4] = useState('');
    const [saEndTime4, setSaEndTime4] = useState('');
    const [suStartTime1, setSuStartTime1] = useState('');
    const [suEndTime1, setSuEndTime1] = useState('');
    const [suStartTime2, setSuStartTime2] = useState('');
    const [suEndTime2, setSuEndTime2] = useState('');
    const [suStartTime3, setSuStartTime3] = useState('');
    const [suEndTime3, setSuEndTime3] = useState('');
    const [suStartTime4, setSuStartTime4] = useState('');
    const [suEndTime4, setSuEndTime4] = useState('');
    const [hoStartTime1, setHoStartTime1] = useState('');
    const [hoEndTime1, setHoEndTime1] = useState('');
    const [hoStartTime2, setHoStartTime2] = useState('');
    const [hoEndTime2, setHoEndTime2] = useState('');
    const [hoStartTime3, setHoStartTime3] = useState('');
    const [hoEndTime3, setHoEndTime3] = useState('');
    const [hoStartTime4, setHoStartTime4] = useState('');
    const [hoEndTime4, setHoEndTime4] = useState('');
    const [flgCourseTime, setFlgCourseTime] = useState('0');
    // コース使用時間
    const [useStartDate, setUseStartDate] = useState('');
    const [useEndDate, setUseEndDate] = useState('');

    /**
     * ページ離脱イベント
     * @param e
     */
    window.onbeforeunload = function(e){
        //--- 確認メッセージ表示(トリガーとしてのみ有効、メッセージは内容は反映されない)
        let flgCng = fncChkBackData();
        if(flgCng == '1'){
            e.returnValue = '';
        }
    }
    /**
     * データ取得
     */
    useEffect(() => {
        if(dbconn.id == '' || dbconn.id == undefined) return;
        if(data !== '' && data !== undefined){
            data.id = dbconn.id;
            setLoading('1');
            setCourseData(data);
            //-- モード判定
            if(data.mode === '1'){
                //- 新規
                //--- コース機材情報取得
                Http.post('api/search-course-tool', {
                    ...data
                }).then(response => {
                    if (Object.keys(response.data).length > 0) {
                        setCourseTool(response.data.Tool);
                        setCourseStaff(response.data.Staff);
                        // 店舗時間(曜日)
                        if(response.data.ShopTime.length > 0){
                            dataIntervalTime.time = response.data.ShopTime[0].reserve_interval;
                            setBaseTime(response.data.ShopTime);
                        }
                        setBaseWeek(response.data.BaseWeek);
                        setDefTool(response.data.DefTool);
                        setDefStaff(response.data.DefStaff);
                    }else{
                        setCourseTool('');
                        setCourseStaff('');
                        setDefTool('');
                        setDefStaff('');
                    }
                    //setWeekTime(fieldHoliday);
                    setWeekTime(defaultWeekTime);

                });
            }else{
                //- 編集
                //--- コース機材情報取得
                Http.post('api/search-course-tool', {
                    ...data
                }).then(response => {
                    if (Object.keys(response.data).length > 0) {
                        setCourseTool(response.data.Tool);
                        setCourseStaff(response.data.Staff);
                        if(response.data.WeekTime.length > 0){
                            setWeekTime(response.data.WeekTime);
                        }else{
                            //setWeekTime(fieldHoliday);
                            setWeekTime(defaultWeekTime);
                        }
                        // 店舗時間(曜日)
                        if(response.data.ShopTime.length > 0){
                            dataIntervalTime.time = response.data.ShopTime[0].reserve_interval;
                        }
                        setBaseTime(response.data.CourseTime);
                        setBaseWeek(response.data.BaseWeek);
                        setDefTool(response.data.DefTool);
                        setDefStaff(response.data.DefStaff);

                        // コース使用時間の設定
                        putUseDate(response.data.CourseTime)
                    }else{
                        setCourseTool('');
                        setCourseStaff('');
                        setDefTool('');
                        setDefStaff('');
                    }
                });
            }

            setLoading('0');
        }
    }, [])

    // 使用期間を各データにセット
    const putUseDate = date => {
        const start = date.use_start_date;
        const end = date.use_end_date;

        if (start !== null) {
            fieldData.use_start_date = start;
            defData.use_start_date = start;
            setUseStartDate(new Date(start));
        }
        if (end !== null) {
            fieldData.use_end_date = end;
            defData.use_end_date = end;
            setUseEndDate(new Date(end));
        }
    }

    /**
     * コース情報格納
     */
    useEffect(() => {
        if(courseData == "" || courseData == undefined) return;
        //--- コース情報
        fieldData.course_id = courseData.course_id;
        fieldData.course_nm = courseData.course_nm;
        fieldData.course_time = courseData.course_time;
        fieldData.disp_no = courseData.disp_no;
        fieldData.user_flg = courseData.user_flg;
        fieldData.tool_reserve_cnt_flg = courseData.tool_reserve_cnt_flg;
        fieldData.reserve_over_kbn = courseData.reserve_over_kbn;

        defData.course_id = courseData.course_id;
        defData.course_nm = courseData.course_nm;
        defData.course_time = courseData.course_time;
        defData.disp_no = courseData.disp_no;
        defData.user_flg = courseData.user_flg;
        defData.tool_reserve_cnt_flg = courseData.tool_reserve_cnt_flg;
        defData.reserve_over_kbn = courseData.reserve_over_kbn;

    }, [courseData])

    /**
     * コース基本時間情報格納
     */
    useEffect(() => {
        if(basetime == "" || basetime == undefined) return;
        //--- 現在日付
        var dtNow = new Date();
        var strDate = dtNow.getFullYear() + '/' + (dtNow.getMonth() + 1) + '/' + dtNow.getDate() + ' ';

        //--- 基本時間
        if(data.mode === '1'){
            //-- 新規
            fieldData.accept_start_time1 = basetime[0].open_time1;
            defData.accept_start_time1 = basetime[0].open_time1 != null ? basetime[0].open_time1 : '';
            if (basetime[0].open_time1 != null && basetime[0].open_time1 != '') {
                setBaseStartTime1(new Date(strDate + basetime[0].open_time1 + ':00'));
            }
            fieldData.accept_end_time1 = basetime[0].close_time1;
            defData.accept_end_time1 = basetime[0].close_time1 != null ? basetime[0].close_time1 : '';
            if (basetime[0].open_time1 != null && basetime[0].open_time1 != '') {
                setBaseStartTime1(new Date(strDate + basetime[0].open_time1 + ':00'));
            }
            if (basetime[0].close_time1 != null && basetime[0].close_time1 != '') {
                setBaseEndTime1(new Date(strDate + basetime[0].close_time1 + ':00'));
            }
            fieldData.accept_start_time2 = basetime[0].open_time2;
            defData.accept_start_time2 = basetime[0].open_time2 != null ? basetime[0].open_time2 : '';
            if (basetime[0].open_time2 != null && basetime[0].open_time2 != '') {
                setBaseStartTime2(new Date(strDate + basetime[0].open_time2 + ':00'));
            }
            fieldData.accept_end_time2 = basetime[0].close_time2;
            defData.accept_end_time2 = basetime[0].close_time2 != null ? basetime[0].close_time2 : '';
            if (basetime[0].close_time2 != null && basetime[0].close_time2 != '') {
                setBaseEndTime2(new Date(strDate + basetime[0].close_time2 + ':00'));
            }
            fieldData.accept_start_time3 = basetime[0].open_time3;
            defData.accept_start_time3 = basetime[0].open_time3 != null ? basetime[0].open_time3 : '';
            if (basetime[0].open_time3 != null && basetime[0].open_time3 != '') {
                setBaseStartTime3(new Date(strDate + basetime[0].open_time3 + ':00'));
            }
            fieldData.accept_end_time3 = basetime[0].close_time3;
            defData.accept_end_time3 = basetime[0].close_time3 != null ? basetime[0].close_time3 : '';
            if (basetime[0].close_time3 != null && basetime[0].close_time3 != '') {
                setBaseEndTime3(new Date(strDate + basetime[0].close_time3 + ':00'));
            }
            fieldData.accept_start_time4 = basetime[0].open_time4;
            defData.accept_start_time4 = basetime[0].open_time4 != null ? basetime[0].open_time4 : '';
            if (basetime[0].open_time4 != null && basetime[0].open_time4 != '') {
                setBaseStartTime4(new Date(strDate + basetime[0].open_time4 + ':00'));
            }
            fieldData.accept_end_time4 = basetime[0].close_time4;
            defData.accept_end_time4 = basetime[0].close_time4 != null ? basetime[0].close_time4 : '';
            if (basetime[0].close_time4 != null && basetime[0].close_time4 != '') {
                setBaseEndTime4(new Date(strDate + basetime[0].close_time4 + ':00'));
            }
        }else{
            //-- 新規以外
            fieldData.accept_start_time1 = courseData.accept_start_time1;
            defData.accept_start_time1 = courseData.accept_start_time1 != null ? courseData.accept_start_time1 : '';
            if (courseData.accept_start_time1 != null && courseData.accept_start_time1 != '') {
                setBaseStartTime1(new Date(strDate + courseData.accept_start_time1 + ':00'));
            }
            fieldData.accept_end_time1 = courseData.accept_end_time1;
            defData.accept_end_time1 = courseData.accept_end_time1 != null ? courseData.accept_end_time1 : '';
            if (courseData.accept_end_time1 != null && courseData.accept_end_time1 != '') {
                setBaseEndTime1(new Date(strDate + courseData.accept_end_time1 + ':00'));
            }
            fieldData.accept_start_time2 = courseData.accept_start_time2;
            defData.accept_start_time2 = courseData.accept_start_time2 != null ? courseData.accept_start_time2 : '';
            if (courseData.accept_start_time2 != null && courseData.accept_start_time2 != '') {
                setBaseStartTime2(new Date(strDate + courseData.accept_start_time2 + ':00'));
            }
            fieldData.accept_end_time2 = courseData.accept_end_time2;
            defData.accept_end_time2 = courseData.accept_end_time2 != null ? courseData.accept_end_time2 : '';
            if (courseData.accept_end_time2 != null && courseData.accept_end_time2 != '') {
                setBaseEndTime2(new Date(strDate + courseData.accept_end_time2 + ':00'));
            }
            fieldData.accept_start_time3 = courseData.accept_start_time3;
            defData.accept_start_time3 = courseData.accept_start_time3 != null ? courseData.accept_start_time3 : '';
            if (courseData.accept_start_time3 != null && courseData.accept_start_time3 != '') {
                setBaseStartTime3(new Date(strDate + courseData.accept_start_time3 + ':00'));
            }
            fieldData.accept_end_time3 = courseData.accept_end_time3;
            defData.accept_end_time3 = courseData.accept_end_time3 != null ? courseData.accept_end_time3 : '';
            if (courseData.accept_end_time3 != null && courseData.accept_end_time3 != '') {
                setBaseStartTime3(new Date(strDate + courseData.accept_end_time3 + ':00'));
            }
            fieldData.accept_start_time4 = courseData.accept_start_time4;
            defData.accept_start_time4 = courseData.accept_start_time4 != null ? courseData.accept_start_time4 : '';
            if (courseData.accept_start_time4 != null && courseData.accept_start_time4 != '') {
                setBaseStartTime3(new Date(strDate + courseData.accept_start_time4 + ':00'));
            }
            fieldData.accept_end_time4 = courseData.accept_end_time4;
            defData.accept_end_time4 = courseData.accept_end_time4 != null ? courseData.accept_end_time4 : '';
            if (courseData.accept_end_time4 != null && courseData.accept_end_time4 != '') {
                setBaseStartTime3(new Date(strDate + courseData.accept_end_time4 + ':00'));
            }
        }
    }, [basetime])

    /**
     * 曜日別受付時間情報設定
     */
    useEffect(() => {
        //--- 現在日付
        var dtNow = new Date();
        var strDate = dtNow.getFullYear() + '/' + (dtNow.getMonth() + 1) + '/' + dtNow.getDate() + ' ';

        //---- 曜日別時間情報
        if(weektime != "" && weektime != undefined){
            for(var intIdx in weektime){
                //--- 曜日判定
                if(weektime[intIdx].week_kbn == '0'){
                    //-- 月
                    fieldHoliday.holiday_kbn1 = weektime[intIdx].holiday_kbn;
                    if(weektime[intIdx].holiday_kbn == '1') setFlgCourseTime('1');
                    if(weektime[intIdx].accept_start_time1 != null && weektime[intIdx].accept_start_time1 != ''){
                        setMoStartTime1(new Date(strDate + weektime[intIdx].accept_start_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time2 != null && weektime[intIdx].accept_start_time2 != ''){
                        setMoStartTime2(new Date(strDate + weektime[intIdx].accept_start_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time3 != null && weektime[intIdx].accept_start_time3 != ''){
                        setMoStartTime3(new Date(strDate + weektime[intIdx].accept_start_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time4 != null && weektime[intIdx].accept_start_time4 != ''){
                        setMoStartTime4(new Date(strDate + weektime[intIdx].accept_start_time4 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time1 != null && weektime[intIdx].accept_end_time1 != ''){
                        setMoEndTime1(new Date(strDate + weektime[intIdx].accept_end_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time2 != null && weektime[intIdx].accept_end_time2 != ''){
                        setMoEndTime2(new Date(strDate + weektime[intIdx].accept_end_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time3 != null && weektime[intIdx].accept_end_time3 != ''){
                        setMoEndTime3(new Date(strDate + weektime[intIdx].accept_end_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time4 != null && weektime[intIdx].accept_end_time4 != ''){
                        setMoEndTime4(new Date(strDate + weektime[intIdx].accept_end_time4 + ':00'));
                        setFlgCourseTime('1');
                    }

                }else if(weektime[intIdx].week_kbn == '1'){
                    //-- 火
                    fieldHoliday.holiday_kbn2 = weektime[intIdx].holiday_kbn;
                    if(weektime[intIdx].holiday_kbn == '1') setFlgCourseTime('1');
                    if(weektime[intIdx].accept_start_time1 != null && weektime[intIdx].accept_start_time1 != ''){
                        setTuStartTime1(new Date(strDate + weektime[intIdx].accept_start_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time2 != null && weektime[intIdx].accept_start_time2 != ''){
                        setTuStartTime2(new Date(strDate + weektime[intIdx].accept_start_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time3 != null && weektime[intIdx].accept_start_time3 != ''){
                        setTuStartTime3(new Date(strDate + weektime[intIdx].accept_start_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time4 != null && weektime[intIdx].accept_start_time4 != ''){
                        setTuStartTime4(new Date(strDate + weektime[intIdx].accept_start_time4 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time1 != null && weektime[intIdx].accept_end_time1 != ''){
                        setTuEndTime1(new Date(strDate + weektime[intIdx].accept_end_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time2 != null && weektime[intIdx].accept_end_time2 != ''){
                        setTuEndTime2(new Date(strDate + weektime[intIdx].accept_end_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time3 != null && weektime[intIdx].accept_end_time3 != ''){
                        setTuEndTime3(new Date(strDate + weektime[intIdx].accept_end_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time4 != null && weektime[intIdx].accept_end_time4 != ''){
                        setTuEndTime4(new Date(strDate + weektime[intIdx].accept_end_time4 + ':00'));
                        setFlgCourseTime('1');
                    }

                }else if(weektime[intIdx].week_kbn == '2'){
                    //-- 水
                    fieldHoliday.holiday_kbn3 = weektime[intIdx].holiday_kbn;
                    if(weektime[intIdx].holiday_kbn == '1') setFlgCourseTime('1');
                    if(weektime[intIdx].accept_start_time1 != null && weektime[intIdx].accept_start_time1 != ''){
                        setWeStartTime1(new Date(strDate + weektime[intIdx].accept_start_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time2 != null && weektime[intIdx].accept_start_time2 != ''){
                        setWeStartTime2(new Date(strDate + weektime[intIdx].accept_start_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time3 != null && weektime[intIdx].accept_start_time3 != ''){
                        setWeStartTime3(new Date(strDate + weektime[intIdx].accept_start_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time4 != null && weektime[intIdx].accept_start_time4 != ''){
                        setWeStartTime4(new Date(strDate + weektime[intIdx].accept_start_time4 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time1 != null && weektime[intIdx].accept_end_time1 != ''){
                        setWeEndTime1(new Date(strDate + weektime[intIdx].accept_end_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time2 != null && weektime[intIdx].accept_end_time2 != ''){
                        setWeEndTime2(new Date(strDate + weektime[intIdx].accept_end_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time3 != null && weektime[intIdx].accept_end_time3 != ''){
                        setWeEndTime3(new Date(strDate + weektime[intIdx].accept_end_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time4 != null && weektime[intIdx].accept_end_time4 != ''){
                        setWeEndTime4(new Date(strDate + weektime[intIdx].accept_end_time4 + ':00'));
                        setFlgCourseTime('1');
                    }

                }else if(weektime[intIdx].week_kbn == '3'){
                    //-- 木
                    fieldHoliday.holiday_kbn4 = weektime[intIdx].holiday_kbn;
                    if(weektime[intIdx].holiday_kbn == '1') setFlgCourseTime('1');
                    if(weektime[intIdx].accept_start_time1 != null && weektime[intIdx].accept_start_time1 != ''){
                        setThStartTime1(new Date(strDate + weektime[intIdx].accept_start_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time2 != null && weektime[intIdx].accept_start_time2 != ''){
                        setThStartTime2(new Date(strDate + weektime[intIdx].accept_start_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time3 != null && weektime[intIdx].accept_start_time3 != ''){
                        setThStartTime3(new Date(strDate + weektime[intIdx].accept_start_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time4 != null && weektime[intIdx].accept_start_time4 != ''){
                        setThStartTime4(new Date(strDate + weektime[intIdx].accept_start_time4 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time1 != null && weektime[intIdx].accept_end_time1 != ''){
                        setThEndTime1(new Date(strDate + weektime[intIdx].accept_end_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time2 != null && weektime[intIdx].accept_end_time2 != ''){
                        setThEndTime2(new Date(strDate + weektime[intIdx].accept_end_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time3 != null && weektime[intIdx].accept_end_time3 != ''){
                        setThEndTime3(new Date(strDate + weektime[intIdx].accept_end_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time4 != null && weektime[intIdx].accept_end_time4 != ''){
                        setThEndTime4(new Date(strDate + weektime[intIdx].accept_end_time4 + ':00'));
                        setFlgCourseTime('1');
                    }

                }else if(weektime[intIdx].week_kbn == '4'){
                    //-- 金
                    fieldHoliday.holiday_kbn5 = weektime[intIdx].holiday_kbn;
                    if(weektime[intIdx].holiday_kbn == '1') setFlgCourseTime('1');
                    if(weektime[intIdx].accept_start_time1 != null && weektime[intIdx].accept_start_time1 != ''){
                        setFrStartTime1(new Date(strDate + weektime[intIdx].accept_start_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time2 != null && weektime[intIdx].accept_start_time2 != ''){
                        setFrStartTime2(new Date(strDate + weektime[intIdx].accept_start_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time3 != null && weektime[intIdx].accept_start_time3 != ''){
                        setFrStartTime3(new Date(strDate + weektime[intIdx].accept_start_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time4 != null && weektime[intIdx].accept_start_time4 != ''){
                        setFrStartTime4(new Date(strDate + weektime[intIdx].accept_start_time4 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time1 != null && weektime[intIdx].accept_end_time1 != ''){
                        setFrEndTime1(new Date(strDate + weektime[intIdx].accept_end_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time2 != null && weektime[intIdx].accept_end_time2 != ''){
                        setFrEndTime2(new Date(strDate + weektime[intIdx].accept_end_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time3 != null && weektime[intIdx].accept_end_time3 != ''){
                        setFrEndTime3(new Date(strDate + weektime[intIdx].accept_end_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time4 != null && weektime[intIdx].accept_end_time4 != ''){
                        setFrEndTime4(new Date(strDate + weektime[intIdx].accept_end_time4 + ':00'));
                        setFlgCourseTime('1');
                    }

                }else if(weektime[intIdx].week_kbn == '5'){
                    //-- 土
                    fieldHoliday.holiday_kbn6 = weektime[intIdx].holiday_kbn;
                    if(weektime[intIdx].holiday_kbn == '1') setFlgCourseTime('1');
                    if(weektime[intIdx].accept_start_time1 != null && weektime[intIdx].accept_start_time1 != ''){
                        setSaStartTime1(new Date(strDate + weektime[intIdx].accept_start_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time2 != null && weektime[intIdx].accept_start_time2 != ''){
                        setSaStartTime2(new Date(strDate + weektime[intIdx].accept_start_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time3 != null && weektime[intIdx].accept_start_time3 != ''){
                        setSaStartTime3(new Date(strDate + weektime[intIdx].accept_start_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time4 != null && weektime[intIdx].accept_start_time4 != ''){
                        setSaStartTime4(new Date(strDate + weektime[intIdx].accept_start_time4 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time1 != null && weektime[intIdx].accept_end_time1 != ''){
                        setSaEndTime1(new Date(strDate + weektime[intIdx].accept_end_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time2 != null && weektime[intIdx].accept_end_time2 != ''){
                        setSaEndTime2(new Date(strDate + weektime[intIdx].accept_end_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time3 != null && weektime[intIdx].accept_end_time3 != ''){
                        setSaEndTime3(new Date(strDate + weektime[intIdx].accept_end_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time4 != null && weektime[intIdx].accept_end_time4 != ''){
                        setSaEndTime4(new Date(strDate + weektime[intIdx].accept_end_time4 + ':00'));
                        setFlgCourseTime('1');
                    }

                }else if(weektime[intIdx].week_kbn == '6'){
                    //-- 日
                    fieldHoliday.holiday_kbn7 = weektime[intIdx].holiday_kbn;
                    if(weektime[intIdx].holiday_kbn == '1') setFlgCourseTime('1');
                    if(weektime[intIdx].accept_start_time1 != null && weektime[intIdx].accept_start_time1 != ''){
                        setSuStartTime1(new Date(strDate + weektime[intIdx].accept_start_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time2 != null && weektime[intIdx].accept_start_time2 != ''){
                        setSuStartTime2(new Date(strDate + weektime[intIdx].accept_start_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time3 != null && weektime[intIdx].accept_start_time3 != ''){
                        setSuStartTime3(new Date(strDate + weektime[intIdx].accept_start_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time4 != null && weektime[intIdx].accept_start_time4 != ''){
                        setSuStartTime4(new Date(strDate + weektime[intIdx].accept_start_time4 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time1 != null && weektime[intIdx].accept_end_time1 != ''){
                        setSuEndTime1(new Date(strDate + weektime[intIdx].accept_end_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time2 != null && weektime[intIdx].accept_end_time2 != ''){
                        setSuEndTime2(new Date(strDate + weektime[intIdx].accept_end_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time3 != null && weektime[intIdx].accept_end_time3 != ''){
                        setSuEndTime3(new Date(strDate + weektime[intIdx].accept_end_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time4 != null && weektime[intIdx].accept_end_time4 != ''){
                        setSuEndTime4(new Date(strDate + weektime[intIdx].accept_end_time4 + ':00'));
                        setFlgCourseTime('1');
                    }

                }else if(weektime[intIdx].week_kbn == '7'){
                    //-- 祝
                    fieldHoliday.holiday_kbn8 = weektime[intIdx].holiday_kbn;
                    if(weektime[intIdx].holiday_kbn == '1') setFlgCourseTime('1');
                    if(weektime[intIdx].accept_start_time1 != null && weektime[intIdx].accept_start_time1 != ''){
                        setHoStartTime1(new Date(strDate + weektime[intIdx].accept_start_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time2 != null && weektime[intIdx].accept_start_time2 != ''){
                        setHoStartTime2(new Date(strDate + weektime[intIdx].accept_start_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time3 != null && weektime[intIdx].accept_start_time3 != ''){
                        setHoStartTime3(new Date(strDate + weektime[intIdx].accept_start_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_start_time4 != null && weektime[intIdx].accept_start_time4 != ''){
                        setHoStartTime4(new Date(strDate + weektime[intIdx].accept_start_time4 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time1 != null && weektime[intIdx].accept_end_time1 != ''){
                        setHoEndTime1(new Date(strDate + weektime[intIdx].accept_end_time1 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time2 != null && weektime[intIdx].accept_end_time2 != ''){
                        setHoEndTime2(new Date(strDate + weektime[intIdx].accept_end_time2 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time3 != null && weektime[intIdx].accept_end_time3 != ''){
                        setHoEndTime3(new Date(strDate + weektime[intIdx].accept_end_time3 + ':00'));
                        setFlgCourseTime('1');
                    }
                    if(weektime[intIdx].accept_end_time4 != null && weektime[intIdx].accept_end_time4 != ''){
                        setHoEndTime4(new Date(strDate + weektime[intIdx].accept_end_time4 + ':00'));
                        setFlgCourseTime('1');
                    }
                }

                defWeekTime[intIdx].holiday_kbn = weektime[intIdx].holiday_kbn;
                defWeekTime[intIdx].accept_start_time1 = weektime[intIdx].accept_start_time1;
                defWeekTime[intIdx].accept_start_time2 = weektime[intIdx].accept_start_time2;
                defWeekTime[intIdx].accept_start_time3 = weektime[intIdx].accept_start_time3;
                defWeekTime[intIdx].accept_start_time4 = weektime[intIdx].accept_start_time4;
                defWeekTime[intIdx].accept_end_time1 = weektime[intIdx].accept_end_time1;
                defWeekTime[intIdx].accept_end_time2 = weektime[intIdx].accept_end_time2;
                defWeekTime[intIdx].accept_end_time3 = weektime[intIdx].accept_end_time3;
                defWeekTime[intIdx].accept_end_time4 = weektime[intIdx].accept_end_time4;
                
            }
            setWeekFlg('1');
        }
    }, [weektime])

    /**
     * 戻るボタン
     */
    function fncOnClickBack(){
        //--- 変更チェック
        let flgCng = fncChkBackData();
        if(flgCng == '1') {
            if (window.confirm(MSG_SYSTEM_UPD_BACK)) {
                //-- 戻る
                submitReturn(dbconn);
            }
        }else{
            //-- 戻る
            submitReturn(dbconn);
        }
    }

    /**
     * 変更項目チェック
     * @returns {string}
     */
    function fncChkBackData(){
        //--- 削除モードは判定しない
        if(data.mode == '3') return '0';

        //--- 受付時間格納
        fncSetDataCourseTime();
        fncSetDataWeekTime();

        //--- チェック
        //-- コース情報
        if(fieldData.course_nm != defData.course_nm ||
            fieldData.course_time != defData.course_time ||
            fieldData.disp_no != defData.disp_no ||
            fieldData.user_flg != defData.user_flg ||
            fieldData.tool_reserve_cnt_flg != defData.tool_reserve_cnt_flg ||
            fieldData.reserve_over_kbn != defData.reserve_over_kbn ||
            fieldData.accept_start_time1 != defData.accept_start_time1 ||
            fieldData.accept_end_time1 != defData.accept_end_time1 ||
            fieldData.accept_start_time2 != defData.accept_start_time2 ||
            fieldData.accept_end_time2 != defData.accept_end_time2 ||
            fieldData.accept_start_time3 != defData.accept_start_time3 ||
            fieldData.accept_end_time3 != defData.accept_end_time3 ||
            fieldData.accept_start_time4 != defData.accept_start_time4 ||
            fieldData.accept_end_time4 != defData.accept_end_time4 ||
            fieldData.use_start_date != defData.use_start_date ||
            fieldData.use_end_date != defData.use_end_date
        ){
            return '1';
        }
        //-- コース受付時間
        for(let idx = 0; idx <= 7; idx++){
            if(fieldWeekTime[idx].week_kbn != defWeekTime[idx].week_kbn ||
                fieldWeekTime[idx].holiday_kbn != defWeekTime[idx].holiday_kbn ||
                fieldWeekTime[idx].accept_start_time1 != defWeekTime[idx].accept_start_time1 ||
                fieldWeekTime[idx].accept_end_time1 != defWeekTime[idx].accept_end_time1 ||
                fieldWeekTime[idx].accept_start_time2 != defWeekTime[idx].accept_start_time2 ||
                fieldWeekTime[idx].accept_end_time2 != defWeekTime[idx].accept_end_time2 ||
                fieldWeekTime[idx].accept_start_time3 != defWeekTime[idx].accept_start_time3 ||
                fieldWeekTime[idx].accept_end_time3 != defWeekTime[idx].accept_end_time3 ||
                fieldWeekTime[idx].accept_start_time4 != defWeekTime[idx].accept_start_time4 ||
                fieldWeekTime[idx].accept_end_time4 != defWeekTime[idx].accept_end_time4){
                return '1';
            }
        }
        //-- コース機材
        for(let idxTool in coursetool){
            deftool[idxTool].course_tool_id = deftool[idxTool].course_tool_id != null ? deftool[idxTool].course_tool_id : '';
            if(coursetool[idxTool].course_tool_id != deftool[idxTool].course_tool_id){
                return '1';
            }
        }
        //-- コーススタッフ
        for(let idxStaff in coursestaff){
            defstaff[idxStaff].courses_staff_id = defstaff[idxStaff].courses_staff_id != null ? defstaff[idxStaff].courses_staff_id : '';
            if(coursestaff[idxStaff].courses_staff_id != defstaff[idxStaff].courses_staff_id){
                return '1';
            }
        }

        return '0';
    }

    /**
     * 休日チェックボックス()
     * @param e
     */
    function fncOnClickHoliday(e){
        //--- ボタン判定
        if(e.currentTarget.value == '1'){
            //-- 月
            //-- チェック判定
            if(fieldHoliday.holiday_kbn1 == '1'){
                //- チェックON
                // チェックOFFに変更
                fieldHoliday.holiday_kbn1 = '0';
            }else{
                //- チェックOFF
                // チェックONに変更
                fieldHoliday.holiday_kbn1 = '1';
                setMoStartTime1(null);
                setMoStartTime2(null);
                setMoStartTime3(null);
                setMoStartTime4(null);
                setMoEndTime1(null);
                setMoEndTime2(null);
                setMoEndTime3(null);
                setMoEndTime4(null);
            }
        }else if(e.currentTarget.value == '2'){
            //-- 火
            //-- チェック判定
            if(fieldHoliday.holiday_kbn2 == '1'){
                //- チェックON
                // チェックOFFに変更
                fieldHoliday.holiday_kbn2 = '0';
            }else{
                //- チェックOFF
                // チェックONに変更
                fieldHoliday.holiday_kbn2 = '1';
                setTuStartTime1(null);
                setTuStartTime2(null);
                setTuStartTime3(null);
                setTuStartTime4(null);
                setTuEndTime1(null);
                setTuEndTime2(null);
                setTuEndTime3(null);
                setTuEndTime4(null);
            }
        }else if(e.currentTarget.value == '3'){
            //-- 水
            //-- チェック判定
            if(fieldHoliday.holiday_kbn3 == '1'){
                //- チェックON
                // チェックOFFに変更
                fieldHoliday.holiday_kbn3 = '0';
            }else{
                //- チェックOFF
                // チェックONに変更
                fieldHoliday.holiday_kbn3 = '1';
                setWeStartTime1(null);
                setWeStartTime2(null);
                setWeStartTime3(null);
                setWeStartTime4(null);
                setWeEndTime1(null);
                setWeEndTime2(null);
                setWeEndTime3(null);
                setWeEndTime4(null);
            }
        }else if(e.currentTarget.value == '4'){
            //-- 木
            //-- チェック判定
            if(fieldHoliday.holiday_kbn4 == '1'){
                //- チェックON
                // チェックOFFに変更
                fieldHoliday.holiday_kbn4 = '0';
            }else{
                //- チェックOFF
                // チェックONに変更
                fieldHoliday.holiday_kbn4 = '1';
                setThStartTime1(null);
                setThStartTime2(null);
                setThStartTime3(null);
                setThStartTime4(null);
                setThEndTime1(null);
                setThEndTime2(null);
                setThEndTime3(null);
                setThEndTime4(null);
            }
        }else if(e.currentTarget.value == '5'){
            //-- 金
            //-- チェック判定
            if(fieldHoliday.holiday_kbn5 == '1'){
                //- チェックON
                // チェックOFFに変更
                fieldHoliday.holiday_kbn5 = '0';
            }else{
                //- チェックOFF
                // チェックONに変更
                fieldHoliday.holiday_kbn5 = '1';
                setFrStartTime1(null);
                setFrStartTime2(null);
                setFrStartTime3(null);
                setFrStartTime4(null);
                setFrEndTime1(null);
                setFrEndTime2(null);
                setFrEndTime3(null);
                setFrEndTime4(null);
            }
        }else if(e.currentTarget.value == '6'){
            //-- 土
            //-- チェック判定
            if(fieldHoliday.holiday_kbn6 == '1'){
                //- チェックON
                // チェックOFFに変更
                fieldHoliday.holiday_kbn6 = '0';
            }else{
                //- チェックOFF
                // チェックONに変更
                fieldHoliday.holiday_kbn6 = '1';
                setSaStartTime1(null);
                setSaStartTime2(null);
                setSaStartTime3(null);
                setSaStartTime4(null);
                setSaEndTime1(null);
                setSaEndTime2(null);
                setSaEndTime3(null);
                setSaEndTime4(null);
            }
        }else if(e.currentTarget.value == '7'){
            //-- 日
            //-- チェック判定
            if(fieldHoliday.holiday_kbn7 == '1'){
                //- チェックON
                // チェックOFFに変更
                fieldHoliday.holiday_kbn7 = '0';
            }else{
                //- チェックOFF
                // チェックONに変更
                fieldHoliday.holiday_kbn7 = '1';
                setSuStartTime1(null);
                setSuStartTime2(null);
                setSuStartTime3(null);
                setSuStartTime4(null);
                setSuEndTime1(null);
                setSuEndTime2(null);
                setSuEndTime3(null);
                setSuEndTime4(null);
            }
        }else if(e.currentTarget.value == '8'){
            //-- 祝
            //-- チェック判定
            if(fieldHoliday.holiday_kbn8 == '1'){
                //- チェックON
                // チェックOFFに変更
                fieldHoliday.holiday_kbn8 = '0';
            }else{
                //- チェックOFF
                // チェックONに変更
                fieldHoliday.holiday_kbn8 = '1';
                setHoStartTime1(null);
                setHoStartTime2(null);
                setHoStartTime3(null);
                setHoStartTime4(null);
                setHoEndTime1(null);
                setHoEndTime2(null);
                setHoEndTime3(null);
                setHoEndTime4(null);
            }
        }
    }

    /**
     * 機材一覧作成
     */
    function fncGetCourseToolList(){
        var lstCourseTool = [];

        for(var intIdx in coursetool){
            if(coursetool[intIdx].course_tool_id == undefined){
                coursetool[intIdx].course_tool_id = '';
            }

            lstCourseTool.push(
                <tr key={intIdx}>
                    <td className="td-coursetool-no">
                        {Number(intIdx) + 1}
                    </td>
                    <td className="td-coursetool-sel">
                        <label className={"label-course-radio"}>
                            <input
                                className="course-mng-checkbox"
                                type="checkbox"
                                value="1"
                                defaultChecked={coursetool[intIdx].course_tool_id != undefined &&
                                coursetool[intIdx].course_tool_id != null &&
                                coursetool[intIdx].course_tool_id != ''? 'checked' : ''
                                }
                                onChange={fncChangeTool}
                                name="course_tool_id"
                                id={'course-tool-' + intIdx}
                                data-id={intIdx}
                                disabled={data.mode == '3'? 'disabled' : ''}
                            />
                        </label>
                        <input type="hidden" id={'hdn-tool-id-' + intIdx} value={coursetool[intIdx].tool_id}></input>
                    </td>
                    <td className="td-coursetool-name">
                        {coursetool[intIdx].tool_nm != null? coursetool[intIdx].tool_nm : ''}
                    </td>
                </tr>
            );
        }

        if(lstCourseTool.length > 0){
            return(
                <tbody className={"course-tool-tbody"}>
                {lstCourseTool}
                </tbody>
            );
        }else{
            return(<tbody></tbody>);
        }
    }

    /**
     * スタッフ一覧作成
     */
    function fncGetCourseStaffList(){
        var lstCourseStaff = [];
        if(coursestaff == "") return;

        //--- 全選択、全解除制御
        let btnAllSel = document.getElementById('btn-all-sel');
        let btnAllKaij = document.getElementById('btn-all-kaij');
        if(data.mode != '3' && loading != '1'){
            if( fieldData.tool_reserve_cnt_flg == '1'){
                btnAllSel.disabled = true;
                btnAllKaij.disabled = true;
            }else{
                btnAllSel.disabled = false;
                btnAllKaij.disabled = false;
            }
        }


        for(var intIdx in coursestaff){
            lstCourseStaff.push(
                <tr key={intIdx}>
                    <td className="td-coursestaff-no">
                        {Number(intIdx) + 1}
                    </td>
                    <td className="td-coursestaff-sel">
                        <label className={"label-course-radio"}>
                            <input
                                className="course-mng-checkbox"
                                type="checkbox"
                                value="1"
                                defaultChecked={coursestaff[intIdx].courses_staff_id != ''? 'checked' : ''}
                                onChange={fncChangeStaff}
                                name={"courses-staff-id-" + intIdx}
                                id={'course-staff-' + intIdx}
                                data-id={intIdx}
                                disabled={data.tool_reserve_cnt_flg == '1' ? 'disabled' : data.mode == '3'? 'disabled' : ''}
                            />
                        </label>
                        <input type="hidden" id={'hdn-staff-id-' + intIdx} value={coursestaff[intIdx].staff_id}></input>
                    </td>
                    <td className="td-coursestaff-name">
                        {coursestaff[intIdx].staff_nm != null? coursestaff[intIdx].staff_nm : ''}
                    </td>
                </tr>
            );
        }

        if(lstCourseStaff.length > 0){
            return(
                <tbody className={"course-staff-tbody"}>
                {lstCourseStaff}
                </tbody>
            );
        }else{
            return(<tbody></tbody>);
        }
    }

    /**
     * 曜日別時間作成
     * @returns {*}
     */
    function fncSetWeekTime(){
        var lstWeek = [];

        if(weekflg != '1' && data.mode != '1') return;

        //--- 受付時間表示
        if(flgCourseTime == '1'){
            let btnTime = document.getElementById('btn-time');
            let divCourseTime = document.getElementById('div-course-time');
            divCourseTime.style.display = 'block';
            btnTime.innerText = '時間枠非表示';
        }

        //--- 受付時間設定
        //-- 月
        lstWeek.push(
            <WeekSet
                index="1"
                strYobi="月"
                defaultChecked={fieldHoliday.holiday_kbn1 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtMoStartTime"
                strIDBaseE="dtMoEndTime"
                valS1={moStartTime1} fncSetS1={date => setMoStartTime1(date)}
                valE1={moEndTime1}   fncSetE1={date => setMoEndTime1(date)}
                valS2={moStartTime2} fncSetS2={date => setMoStartTime2(date)}
                valE2={moEndTime2}   fncSetE2={date => setMoEndTime2(date)}
                valS3={moStartTime3} fncSetS3={date => setMoStartTime3(date)}
                valE3={moEndTime3}   fncSetE3={date => setMoEndTime3(date)}
                valS4={moStartTime4} fncSetS4={date => setMoStartTime4(date)}
                valE4={moEndTime4}   fncSetE4={date => setMoEndTime4(date)}
            />
        );

        //-- 火
        lstWeek.push(
            <WeekSet
                index="2"
                strYobi="火"
                defaultChecked={fieldHoliday.holiday_kbn2 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtTuStartTime"
                strIDBaseE="dtTuEndTime"
                valS1={tuStartTime1} fncSetS1={date => setTuStartTime1(date)}
                valE1={tuEndTime1}   fncSetE1={date => setTuEndTime1(date)}
                valS2={tuStartTime2} fncSetS2={date => setTuStartTime2(date)}
                valE2={tuEndTime2}   fncSetE2={date => setTuEndTime2(date)}
                valS3={tuStartTime3} fncSetS3={date => setTuStartTime3(date)}
                valE3={tuEndTime3}   fncSetE3={date => setTuEndTime3(date)}
                valS4={tuStartTime4} fncSetS4={date => setTuStartTime4(date)}
                valE4={tuEndTime4}   fncSetE4={date => setTuEndTime4(date)}
            />
        );

        //-- 水
        lstWeek.push(
            <WeekSet
                index="3"
                strYobi="水"
                defaultChecked={fieldHoliday.holiday_kbn3 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtWeStartTime"
                strIDBaseE="dtWeEndTime"
                valS1={weStartTime1} fncSetS1={date => setWeStartTime1(date)}
                valE1={weEndTime1}   fncSetE1={date => setWeEndTime1(date)}
                valS2={weStartTime2} fncSetS2={date => setWeStartTime2(date)}
                valE2={weEndTime2}   fncSetE2={date => setWeEndTime2(date)}
                valS3={weStartTime3} fncSetS3={date => setWeStartTime3(date)}
                valE3={weEndTime3}   fncSetE3={date => setWeEndTime3(date)}
                valS4={weStartTime4} fncSetS4={date => setWeStartTime4(date)}
                valE4={weEndTime4}   fncSetE4={date => setWeEndTime4(date)}
            />
        );

        //-- 木
        lstWeek.push(
            <WeekSet
                index="4"
                strYobi="木"
                defaultChecked={fieldHoliday.holiday_kbn4 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtThStartTime"
                strIDBaseE="dtThEndTime"
                valS1={thStartTime1} fncSetS1={date => setThStartTime1(date)}
                valE1={thEndTime1}   fncSetE1={date => setThEndTime1(date)}
                valS2={thStartTime2} fncSetS2={date => setThStartTime2(date)}
                valE2={thEndTime2}   fncSetE2={date => setThEndTime2(date)}
                valS3={thStartTime3} fncSetS3={date => setThStartTime3(date)}
                valE3={thEndTime3}   fncSetE3={date => setThEndTime3(date)}
                valS4={thStartTime4} fncSetS4={date => setThStartTime4(date)}
                valE4={thEndTime4}   fncSetE4={date => setThEndTime4(date)}
            />
        );

        //-- 金
        lstWeek.push(
            <WeekSet
                index="5"
                strYobi="金"
                defaultChecked={fieldHoliday.holiday_kbn5 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtFrStartTime"
                strIDBaseE="dtFrEndTime"
                valS1={frStartTime1} fncSetS1={date => setFrStartTime1(date)}
                valE1={frEndTime1}   fncSetE1={date => setFrEndTime1(date)}
                valS2={frStartTime2} fncSetS2={date => setFrStartTime2(date)}
                valE2={frEndTime2}   fncSetE2={date => setFrEndTime2(date)}
                valS3={frStartTime3} fncSetS3={date => setFrStartTime3(date)}
                valE3={frEndTime3}   fncSetE3={date => setFrEndTime3(date)}
                valS4={frStartTime4} fncSetS4={date => setFrStartTime4(date)}
                valE4={frEndTime4}   fncSetE4={date => setFrEndTime4(date)}
            />
        );

        //-- 土
        lstWeek.push(
            <WeekSet
                index="6"
                strYobi="土"
                defaultChecked={fieldHoliday.holiday_kbn6 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtSaStartTime"
                strIDBaseE="dtSaEndTime"
                valS1={saStartTime1} fncSetS1={date => setSaStartTime1(date)}
                valE1={saEndTime1}   fncSetE1={date => setSaEndTime1(date)}
                valS2={saStartTime2} fncSetS2={date => setSaStartTime2(date)}
                valE2={saEndTime2}   fncSetE2={date => setSaEndTime2(date)}
                valS3={saStartTime3} fncSetS3={date => setSaStartTime3(date)}
                valE3={saEndTime3}   fncSetE3={date => setSaEndTime3(date)}
                valS4={saStartTime4} fncSetS4={date => setSaStartTime4(date)}
                valE4={saEndTime4}   fncSetE4={date => setSaEndTime4(date)}
            />
        );

        //-- 日
        lstWeek.push(
            <WeekSet
                index="7"
                strYobi="日"
                defaultChecked={fieldHoliday.holiday_kbn7 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtSuStartTime"
                strIDBaseE="dtSuEndTime"
                valS1={suStartTime1} fncSetS1={date => setSuStartTime1(date)}
                valE1={suEndTime1}   fncSetE1={date => setSuEndTime1(date)}
                valS2={suStartTime2} fncSetS2={date => setSuStartTime2(date)}
                valE2={suEndTime2}   fncSetE2={date => setSuEndTime2(date)}
                valS3={suStartTime3} fncSetS3={date => setSuStartTime3(date)}
                valE3={suEndTime3}   fncSetE3={date => setSuEndTime3(date)}
                valS4={suStartTime4} fncSetS4={date => setSuStartTime4(date)}
                valE4={suEndTime4}   fncSetE4={date => setSuEndTime4(date)}
            />
        );

        //-- 祝
        lstWeek.push(
            <WeekSet
                index="8"
                strYobi="祝"
                defaultChecked={fieldHoliday.holiday_kbn8 == '1'? 'defaultChecked' : ''}
                disabled={data.mode == '3'? 'disabled' : ''}
                fncOnClickHoliday={fncOnClickHoliday}
                strIDBaseS="dtHoStartTime"
                strIDBaseE="dtHoEndTime"
                valS1={hoStartTime1} fncSetS1={date => setHoStartTime1(date)}
                valE1={hoEndTime1}   fncSetE1={date => setHoEndTime1(date)}
                valS2={hoStartTime2} fncSetS2={date => setHoStartTime2(date)}
                valE2={hoEndTime2}   fncSetE2={date => setHoEndTime2(date)}
                valS3={hoStartTime3} fncSetS3={date => setHoStartTime3(date)}
                valE3={hoEndTime3}   fncSetE3={date => setHoEndTime3(date)}
                valS4={hoStartTime4} fncSetS4={date => setHoStartTime4(date)}
                valE4={hoEndTime4}   fncSetE4={date => setHoEndTime4(date)}
            />
        );

        return(
            <tbody>
            {lstWeek}
            </tbody>
        );
    }

    /**
     * 予約可能数選択
     * @param e
     */
    function fncChangeReserveCntFlg(e){
        if( fieldData.tool_reserve_cnt_flg == '1' ){
            fieldData.tool_reserve_cnt_flg = '0'
        }else{
            fieldData.tool_reserve_cnt_flg = e.target.value;
        }

        //--- 全選択、全解除制御
        let btnAllSel = document.getElementById('btn-all-sel');
        let btnAllKaij = document.getElementById('btn-all-kaij');
        if( fieldData.tool_reserve_cnt_flg == '1'){
            btnAllSel.disabled = true;
            btnAllKaij.disabled = true;
        }else{
            btnAllSel.disabled = false;
            btnAllKaij.disabled = false;
        }

        //--- スタッフ一覧制御
        for(var intIdx in coursestaff){
            let lstStaff = document.getElementById('course-staff-' + intIdx);
            let lstStaffID = document.getElementById('hdn-staff-id-' + intIdx);
            if( fieldData.tool_reserve_cnt_flg == '1'){
                if(lstStaffID.value == '9999999999'){
                    lstStaff.checked = true;
                    coursestaff[intIdx].courses_staff_id = lstStaffID.value;
                }else{
                    lstStaff.checked = false;
                    coursestaff[intIdx].courses_staff_id = '';
                }
                lstStaff.disabled = true;
            }else{
                lstStaff.disabled = false;
            }
        }
        //fncGetCourseStaffList();
    }

    /**
     * 受付時間表示
     * @param e
     */
    function fncOnClickDispTime(e){
        //--- 時間枠表示
        let btnTime = document.getElementById('btn-time');
        let divCourseTime = document.getElementById('div-course-time');
        if(divCourseTime.style.display == 'block'){
            divCourseTime.style.display = 'none';
            btnTime.innerText = '時間枠表示';
        }else{
            divCourseTime.style.display = 'block';
            btnTime.innerText = '時間枠非表示';
        }
    }

    /**
     * 時間反映ボタン
     */
    function fncOnClickBaseTime(){
        //--- 出勤時間1
        if(baseStartTime1 != '' && baseStartTime1 != null){
            //- 曜日別データ判定
            if(baseweek == '' || baseweek == undefined || baseweek == null){
                // データが存在しない場合、無条件でコピー
                setMoStartTime1(baseStartTime1);
                setTuStartTime1(baseStartTime1);
                setWeStartTime1(baseStartTime1);
                setThStartTime1(baseStartTime1);
                setFrStartTime1(baseStartTime1);
                setSaStartTime1(baseStartTime1);
                setSuStartTime1(baseStartTime1);
                setHoStartTime1(baseStartTime1);
            }else {
                // データが存在する場合、休日以外コピー
                if(baseweek[0].regular_holiday == '0'){
                    setMoStartTime1(baseStartTime1);
                    fieldHoliday.holiday_kbn1 = '0';
                    document.getElementById('holiday_kbn1').checked = false;
                }else{
                    setMoStartTime1('');
                    fieldHoliday.holiday_kbn1 = '1';
                    document.getElementById('holiday_kbn1').checked = true;
                }
                if(baseweek[1].regular_holiday == '0'){
                    setTuStartTime1(baseStartTime1);
                    fieldHoliday.holiday_kbn2 = '0';
                    document.getElementById('holiday_kbn2').checked = false;
                }else{
                    setTuStartTime1('');
                    fieldHoliday.holiday_kbn2 = '1';
                    document.getElementById('holiday_kbn2').checked = true;
                }
                if(baseweek[2].regular_holiday == '0'){
                    setWeStartTime1(baseStartTime1);
                    fieldHoliday.holiday_kbn3 = '0';
                    document.getElementById('holiday_kbn3').checked = false;
                }else{
                    setWeStartTime1('');
                    fieldHoliday.holiday_kbn3 = '1';
                    document.getElementById('holiday_kbn3').checked = true;
                }
                if(baseweek[3].regular_holiday == '0') {
                    setThStartTime1(baseStartTime1);
                    fieldHoliday.holiday_kbn4 = '0';
                    document.getElementById('holiday_kbn4').checked = false;
                }else{
                    setThStartTime1('');
                    fieldHoliday.holiday_kbn4 = '1';
                    document.getElementById('holiday_kbn4').checked = true;
                }
                if(baseweek[4].regular_holiday == '0') {
                    setFrStartTime1(baseStartTime1);
                    fieldHoliday.holiday_kbn5 = '0';
                    document.getElementById('holiday_kbn5').checked = false;
                }else{
                    setFrStartTime1('');
                    fieldHoliday.holiday_kbn5 = '1';
                    document.getElementById('holiday_kbn5').checked = true;
                }
                if(baseweek[5].regular_holiday == '0') {
                    setSaStartTime1(baseStartTime1);
                    fieldHoliday.holiday_kbn6 = '0';
                    document.getElementById('holiday_kbn6').checked = false;
                }else{
                    setSaStartTime1('');
                    fieldHoliday.holiday_kbn6 = '1';
                    document.getElementById('holiday_kbn6').checked = true;
                }
                if(baseweek[6].regular_holiday == '0') {
                    setSuStartTime1(baseStartTime1);
                    fieldHoliday.holiday_kbn7 = '0';
                    document.getElementById('holiday_kbn7').checked = false;
                }else{
                    setSuStartTime1('');
                    fieldHoliday.holiday_kbn7 = '1';
                    document.getElementById('holiday_kbn7').checked = true;
                }
                if(baseweek[7].regular_holiday == '0') {
                    setHoStartTime1(baseStartTime1);
                    fieldHoliday.holiday_kbn8 = '0';
                    document.getElementById('holiday_kbn8').checked = false;
                }else{
                    setHoStartTime1('');
                    fieldHoliday.holiday_kbn8 = '1';
                    document.getElementById('holiday_kbn8').checked = true;
                }
            }
        }else{
            setMoStartTime1('');
            setTuStartTime1('');
            setWeStartTime1('');
            setThStartTime1('');
            setFrStartTime1('');
            setSaStartTime1('');
            setSuStartTime1('');
            setHoStartTime1('');
        }
        if(baseEndTime1 != '' && baseEndTime1 != null){
            //- 曜日別データ判定
            if(baseweek == '' || baseweek == undefined || baseweek == null){
                // データが存在しない場合、無条件でコピー
                setMoEndTime1(baseEndTime1);
                setTuEndTime1(baseEndTime1);
                setWeEndTime1(baseEndTime1);
                setThEndTime1(baseEndTime1);
                setFrEndTime1(baseEndTime1);
                setSaEndTime1(baseEndTime1);
                setSuEndTime1(baseEndTime1);
                setHoEndTime1(baseEndTime1);
            }else{
                // データが存在する場合、休日以外コピー
                if(baseweek[0].regular_holiday == '0'){
                    setMoEndTime1(baseEndTime1);
                }else{
                    setMoEndTime1('');
                }
                if(baseweek[1].regular_holiday == '0'){
                    setTuEndTime1(baseEndTime1);
                }else{
                    setTuEndTime1('');
                }
                if(baseweek[2].regular_holiday == '0'){
                    setWeEndTime1(baseEndTime1);
                }else{
                    setWeEndTime1('');
                }
                if(baseweek[3].regular_holiday == '0'){
                    setThEndTime1(baseEndTime1);
                }else{
                    setThEndTime1('');
                }
                if(baseweek[4].regular_holiday == '0'){
                    setFrEndTime1(baseEndTime1);
                }else{
                    setFrEndTime1('');
                }
                if(baseweek[5].regular_holiday == '0'){
                    setSaEndTime1(baseEndTime1);
                }else{
                    setSaEndTime1('');
                }
                if(baseweek[6].regular_holiday == '0'){
                    setSuEndTime1(baseEndTime1);
                }else{
                    setSuEndTime1('');
                }
                if(baseweek[7].regular_holiday == '0'){
                    setHoEndTime1(baseEndTime1);
                }else{
                    setHoEndTime1('');
                }
            }
        }else{
            setMoEndTime1('');
            setTuEndTime1('');
            setWeEndTime1('');
            setThEndTime1('');
            setFrEndTime1('');
            setSaEndTime1('');
            setSuEndTime1('');
            setHoEndTime1('');
        }

        //--- 出勤時間2
        if(baseStartTime2 != '' && baseStartTime2 != null){
            //- 曜日別データ判定
            if(baseweek == '' || baseweek == undefined || baseweek == null){
                // データが存在しない場合、無条件でコピー
                setMoStartTime2(baseStartTime2);
                setTuStartTime2(baseStartTime2);
                setWeStartTime2(baseStartTime2);
                setThStartTime2(baseStartTime2);
                setFrStartTime2(baseStartTime2);
                setSaStartTime2(baseStartTime2);
                setSuStartTime2(baseStartTime2);
                setHoStartTime2(baseStartTime2);
            }else{
                // データが存在する場合、休日以外コピー
                if(baseweek[0].regular_holiday == '0'){
                    setMoStartTime2(baseStartTime2);
                }else{
                    setMoStartTime2('');
                }
                if(baseweek[1].regular_holiday == '0'){
                    setTuStartTime2(baseStartTime2);
                }else{
                    setTuStartTime2('');
                }
                if(baseweek[2].regular_holiday == '0'){
                    setWeStartTime2(baseStartTime2);
                }else{
                    setWeStartTime2('');
                }
                if(baseweek[3].regular_holiday == '0'){
                    setThStartTime2(baseStartTime2);
                }else{
                    setThStartTime2('');
                }
                if(baseweek[4].regular_holiday == '0'){
                    setFrStartTime2(baseStartTime2);
                }else{
                    setFrStartTime2('');
                }
                if(baseweek[5].regular_holiday == '0'){
                    setSaStartTime2(baseStartTime2);
                }else{
                    setSaStartTime2('');
                }
                if(baseweek[6].regular_holiday == '0'){
                    setSuStartTime2(baseStartTime2);
                }else{
                    setSuStartTime2('');
                }
                if(baseweek[7].regular_holiday == '0'){
                    setHoStartTime2(baseStartTime2);
                }else{
                    setHoStartTime2('');
                }
            }
        }else{
            setMoStartTime2('');
            setTuStartTime2('');
            setWeStartTime2('');
            setThStartTime2('');
            setFrStartTime2('');
            setSaStartTime2('');
            setSuStartTime2('');
            setHoStartTime2('');
        }
        if(baseEndTime2 != '' && baseEndTime2 != null){
            //- 曜日別データ判定
            if(baseweek == '' || baseweek == undefined || baseweek == null){
                // データが存在しない場合、無条件でコピー
                setMoEndTime2(baseEndTime2);
                setTuEndTime2(baseEndTime2);
                setWeEndTime2(baseEndTime2);
                setThEndTime2(baseEndTime2);
                setFrEndTime2(baseEndTime2);
                setSaEndTime2(baseEndTime2);
                setSuEndTime2(baseEndTime2);
                setHoEndTime2(baseEndTime2);
            }else{
                // データが存在する場合、休日以外コピー
                if(baseweek[0].regular_holiday == '0'){
                    setMoEndTime2(baseEndTime2);
                }else{
                    setMoEndTime2('');
                }
                if(baseweek[1].regular_holiday == '0'){
                    setTuEndTime2(baseEndTime2);
                }else{
                    setTuEndTime2('');
                }
                if(baseweek[2].regular_holiday == '0'){
                    setWeEndTime2(baseEndTime2);
                }else{
                    setWeEndTime2('');
                }
                if(baseweek[3].regular_holiday == '0'){
                    setThEndTime2(baseEndTime2);
                }else{
                    setThEndTime2('');
                }
                if(baseweek[4].regular_holiday == '0'){
                    setFrEndTime2(baseEndTime2);
                }else{
                    setFrEndTime2('');
                }
                if(baseweek[5].regular_holiday == '0'){
                    setSaEndTime2(baseEndTime2);
                }else{
                    setSaEndTime2('');
                }
                if(baseweek[6].regular_holiday == '0'){
                    setSuEndTime2(baseEndTime2);
                }else{
                    setSuEndTime2('');
                }
                if(baseweek[7].regular_holiday == '0'){
                    setHoEndTime2(baseEndTime2);
                }else{
                    setHoEndTime2('');
                }
            }
        }else{
            setMoEndTime2('');
            setTuEndTime2('');
            setWeEndTime2('');
            setThEndTime2('');
            setFrEndTime2('');
            setSaEndTime2('');
            setSuEndTime2('');
            setHoEndTime2('');
        }

        //--- 出勤時間3
        if(baseStartTime3 != '' && baseStartTime3 != null){
            //- 曜日別データ判定
            if(baseweek == '' || baseweek == undefined || baseweek == null){
                // データが存在しない場合、無条件でコピー
                setMoStartTime3(baseStartTime3);
                setTuStartTime3(baseStartTime3);
                setWeStartTime3(baseStartTime3);
                setThStartTime3(baseStartTime3);
                setFrStartTime3(baseStartTime3);
                setSaStartTime3(baseStartTime3);
                setSuStartTime3(baseStartTime3);
                setHoStartTime3(baseStartTime3);
            }else{
                // データが存在する場合、休日以外コピー
                if(baseweek[0].regular_holiday == '0'){
                    setMoStartTime3(baseStartTime3);
                }else{
                    setMoStartTime3('');
                }
                if(baseweek[1].regular_holiday == '0'){
                    setTuStartTime3(baseStartTime3);
                }else{
                    setTuStartTime3('');
                }
                if(baseweek[2].regular_holiday == '0'){
                    setWeStartTime3(baseStartTime3);
                }else{
                    setWeStartTime3('');
                }
                if(baseweek[3].regular_holiday == '0'){
                    setThStartTime3(baseStartTime3);
                }else{
                    setThStartTime3('');
                }
                if(baseweek[4].regular_holiday == '0'){
                    setFrStartTime3(baseStartTime3);
                }else{
                    setFrStartTime3('');
                }
                if(baseweek[5].regular_holiday == '0'){
                    setSaStartTime3(baseStartTime3);
                }else{
                    setSaStartTime3('');
                }
                if(baseweek[6].regular_holiday == '0'){
                    setSuStartTime3(baseStartTime3);
                }else{
                    setSuStartTime3('');
                }
                if(baseweek[7].regular_holiday == '0'){
                    setHoStartTime3(baseStartTime3);
                }else{
                    setHoStartTime3('');
                }
            }
        }else{
            setMoStartTime3('');
            setTuStartTime3('');
            setWeStartTime3('');
            setThStartTime3('');
            setFrStartTime3('');
            setSaStartTime3('');
            setSuStartTime3('');
            setHoStartTime3('');
        }
        if(baseEndTime3 != '' && baseEndTime3 != null){
            //- 曜日別データ判定
            if(baseweek == '' || baseweek == undefined || baseweek == null){
                // データが存在しない場合、無条件でコピー
                setMoEndTime3(baseEndTime3);
                setTuEndTime3(baseEndTime3);
                setWeEndTime3(baseEndTime3);
                setThEndTime3(baseEndTime3);
                setFrEndTime3(baseEndTime3);
                setSaEndTime3(baseEndTime3);
                setSuEndTime3(baseEndTime3);
                setHoEndTime3(baseEndTime3);
            }else{
                // データが存在する場合、休日以外コピー
                if(baseweek[0].regular_holiday == '0'){
                    setMoEndTime3(baseEndTime3);
                }else{
                    setMoEndTime3('');
                }
                if(baseweek[1].regular_holiday == '0'){
                    setTuEndTime3(baseEndTime3);
                }else{
                    setTuEndTime3('');
                }
                if(baseweek[2].regular_holiday == '0'){
                    setWeEndTime3(baseEndTime3);
                }else{
                    setWeEndTime3('');
                }
                if(baseweek[3].regular_holiday == '0'){
                    setThEndTime3(baseEndTime3);
                }else{
                    setThEndTime3('');
                }
                if(baseweek[4].regular_holiday == '0'){
                    setFrEndTime3(baseEndTime3);
                }else{
                    setFrEndTime3('');
                }
                if(baseweek[5].regular_holiday == '0'){
                    setSaEndTime3(baseEndTime3);
                }else{
                    setSaEndTime3('');
                }
                if(baseweek[6].regular_holiday == '0'){
                    setSuEndTime3(baseEndTime3);
                }else{
                    setSuEndTime3('');
                }
                if(baseweek[7].regular_holiday == '0'){
                    setHoEndTime3(baseEndTime3);
                }else{
                    setHoEndTime3('');
                }
            }
        }else{
            setMoEndTime3('');
            setTuEndTime3('');
            setWeEndTime3('');
            setThEndTime3('');
            setFrEndTime3('');
            setSaEndTime3('');
            setSuEndTime3('');
            setHoEndTime3('');
        }

        //--- 出勤時間4
        if(baseStartTime4 != '' && baseStartTime4 != null){
            //- 曜日別データ判定
            if(baseweek == '' || baseweek == undefined || baseweek == null){
                // データが存在しない場合、無条件でコピー
                setMoStartTime4(baseStartTime4);
                setTuStartTime4(baseStartTime4);
                setWeStartTime4(baseStartTime4);
                setThStartTime4(baseStartTime4);
                setFrStartTime4(baseStartTime4);
                setSaStartTime4(baseStartTime4);
                setSuStartTime4(baseStartTime4);
                setHoStartTime4(baseStartTime4);
            }else{
                // データが存在する場合、休日以外コピー
                if(baseweek[0].regular_holiday == '0'){
                    setMoStartTime4(baseStartTime4);
                }else{
                    setMoStartTime4('');
                }
                if(baseweek[1].regular_holiday == '0'){
                    setTuStartTime4(baseStartTime4);
                }else{
                    setTuStartTime4('');
                }
                if(baseweek[2].regular_holiday == '0'){
                    setWeStartTime4(baseStartTime4);
                }else{
                    setWeStartTime4('');
                }
                if(baseweek[3].regular_holiday == '0'){
                    setThStartTime4(baseStartTime4);
                }else{
                    setThStartTime4('');
                }
                if(baseweek[4].regular_holiday == '0'){
                    setFrStartTime4(baseStartTime4);
                }else{
                    setFrStartTime4('');
                }
                if(baseweek[5].regular_holiday == '0'){
                    setSaStartTime4(baseStartTime4);
                }else{
                    setSaStartTime4('');
                }
                if(baseweek[6].regular_holiday == '0'){
                    setSuStartTime4(baseStartTime4);
                }else{
                    setSuStartTime4('');
                }
                if(baseweek[7].regular_holiday == '0'){
                    setHoStartTime4(baseStartTime4);
                }else{
                    setHoStartTime4('');
                }
            }
        }else{
            setMoStartTime4('');
            setTuStartTime4('');
            setWeStartTime4('');
            setThStartTime4('');
            setFrStartTime4('');
            setSaStartTime4('');
            setSuStartTime4('');
            setHoStartTime4('');
        }
        if(baseEndTime4 != '' && baseEndTime4 != null){
            //- 曜日別データ判定
            if(baseweek == '' || baseweek == undefined || baseweek == null){
                // データが存在しない場合、無条件でコピー
                setMoEndTime4(baseEndTime4);
                setTuEndTime4(baseEndTime4);
                setWeEndTime4(baseEndTime4);
                setThEndTime4(baseEndTime4);
                setFrEndTime4(baseEndTime4);
                setSaEndTime4(baseEndTime4);
                setSuEndTime4(baseEndTime4);
                setHoEndTime4(baseEndTime4);
            }else{
                // データが存在する場合、休日以外コピー
                if(baseweek[0].regular_holiday == '0'){
                    setMoEndTime4(baseEndTime4);
                }else{
                    setMoEndTime4('');
                }
                if(baseweek[1].regular_holiday == '0'){
                    setTuEndTime4(baseEndTime4);
                }else{
                    setTuEndTime4('');
                }
                if(baseweek[2].regular_holiday == '0'){
                    setWeEndTime4(baseEndTime4);
                }else{
                    setWeEndTime4('');
                }
                if(baseweek[3].regular_holiday == '0'){
                    setThEndTime4(baseEndTime4);
                }else{
                    setThEndTime4('');
                }
                if(baseweek[4].regular_holiday == '0'){
                    setFrEndTime4(baseEndTime4);
                }else{
                    setFrEndTime4('');
                }
                if(baseweek[5].regular_holiday == '0'){
                    setSaEndTime4(baseEndTime4);
                }else{
                    setSaEndTime4('');
                }
                if(baseweek[6].regular_holiday == '0'){
                    setSuEndTime4(baseEndTime4);
                }else{
                    setSuEndTime4('');
                }
                if(baseweek[7].regular_holiday == '0'){
                    setHoEndTime4(baseEndTime4);
                }else{
                    setHoEndTime4('');
                }
            }
        }else{
            setMoEndTime4('');
            setTuEndTime4('');
            setWeEndTime4('');
            setThEndTime4('');
            setFrEndTime4('');
            setSaEndTime4('');
            setSuEndTime4('');
            setHoEndTime4('');
        }
    }

    /**
     * 機材選択
     * @param e
     */
    function fncChangeTool(e){
        const intID = e.currentTarget.getAttribute('data-id');

        if( coursetool[intID].course_tool_id != undefined && coursetool[intID].course_tool_id != '' ){
            coursetool[intID].course_tool_id = '';
        }else{
            coursetool[intID].course_tool_id = coursetool[intID].tool_id;
        }
    }

    /**
     * スタッフ選択
     * @param e
     */
    function fncChangeStaff(e){
        const intID = e.currentTarget.getAttribute('data-id');

        if( coursestaff[intID].courses_staff_id != '' ){
            coursestaff[intID].courses_staff_id = '';
        }else{
            coursestaff[intID].courses_staff_id = coursestaff[intID].staff_id;
        }
    }

    /**
     * 全選択
     */
    function fncOnClickAllSel(){
        if(coursestaff == undefined) return;
        if(coursestaff.length > 0){
            for(var intIdx = 0; intIdx < coursestaff.length; intIdx++){
                coursestaff[intIdx].courses_staff_id = coursestaff[intIdx].staff_id;
                document.getElementById('course-staff-' + intIdx).checked = true;
            }
        }
    }

    /**
     * 全解除
     */
    function fncOnClickAllKaijo(){
        if(coursestaff == undefined) return;
        if(coursestaff.length > 0){
            for(var intIdx = 0; intIdx < coursestaff.length; intIdx++){
                coursestaff[intIdx].courses_staff_id = '';
                document.getElementById('course-staff-' + intIdx).checked = false;
            }
        }
    }

    /**
     * 日付フォーマット変更
     */
    const dateFormatChange = date => {
        return date.getFullYear() + '/'
            + ('0' + (date.getMonth() + 1)).slice(-2)
            + '/' +  ('0' + date.getDate()).slice(-2);
    }

    /**
     * コース情報登録
     */
    function fncOnClickUpdate(e){
        if(dbconn.id == '' || dbconn.id == undefined) return;

        //--- 受付時間格納
        fncSetDataCourseTime();
        fncSetDataWeekTime();

        //--- 機材選択件数
        let intToolCnt = 0;
        for(var intIdx = 0; intIdx < coursetool.length; intIdx++){
            if(coursetool[intIdx].course_tool_id != ''){
                intToolCnt += 1;
            }
        }

        //--- スタッフ選択
        let intStaffCnt = 0;
        for(var intIdx = 0; intIdx < coursestaff.length; intIdx++){
            if(coursestaff[intIdx].courses_staff_id != ''){
                intStaffCnt += 1;
            }
        }

        //--- 追加設定
        fieldData.tool_cnt = intToolCnt;
        fieldData.staff_cnt = intStaffCnt;
        fieldData.id = dbconn.id;
        fieldData.mode = data.mode;
        // 使用期間のフォーマットを変更し、値をセット
        fieldData.use_start_date =
            useStartDate !== '' && useStartDate !== null ? dateFormatChange(useStartDate) : '';
        fieldData.use_end_date =
            useEndDate !== '' && useEndDate !== null ? dateFormatChange(useEndDate) : '';
        var aryData ={
            course_nm : fieldData.course_nm,
            course_time : fieldData.course_time,
            disp_no : fieldData.disp_no,
            course : [],
            weektime : [],
            staff : [],
        }
        aryData.course.push(fieldData);
        aryData.weektime.push(fieldWeekTime);
        aryData.staff.push(coursestaff);

        //--- チェック
        setErrorClear();
        e.preventDefault();
        submitPost('api/chk-course',{
            ...aryData,
        });
    }

    /**
     * コース基本時間情報
     */
    function fncSetDataCourseTime(){

        //--- データ格納
        //-- 開始時間1
        if(baseStartTime1 != '' && baseStartTime1 != null){
            fieldData.accept_start_time1 = ('0' + baseStartTime1.getHours()).slice(-2) + ':' + ('0' + baseStartTime1.getMinutes()).slice(-2);
        }else{
            fieldData.accept_start_time1 = '';
        }
        //-- 終了時間1
        if(baseEndTime1 != '' && baseEndTime1 != null){
            fieldData.accept_end_time1 = ('0' + baseEndTime1.getHours()).slice(-2) + ':' + ('0' + baseEndTime1.getMinutes()).slice(-2);
        }else{
            fieldData.accept_end_time1 = '';
        }
        //-- 開始時間2
        if(baseStartTime2 != '' && baseStartTime2 != null){
            fieldData.accept_start_time2 = ('0' + baseStartTime2.getHours()).slice(-2) + ':' + ('0' + baseStartTime2.getMinutes()).slice(-2);
        }else{
            fieldData.accept_start_time2 = '';
        }
        //-- 終了時間2
        if(baseEndTime2 != '' && baseEndTime2 != null){
            fieldData.accept_end_time2 = ('0' + baseEndTime2.getHours()).slice(-2) + ':' + ('0' + baseEndTime2.getMinutes()).slice(-2);
        }else{
            fieldData.accept_end_time2 = '';
        }
        //-- 開始時間3
        if(baseStartTime3 != '' && baseStartTime3 != null){
            fieldData.accept_start_time3 = ('0' + baseStartTime3.getHours()).slice(-2) + ':' + ('0' + baseStartTime3.getMinutes()).slice(-2);
        }else{
            fieldData.accept_start_time3 = '';
        }
        //-- 終了時間3
        if(baseEndTime3 != '' && baseEndTime3 != null){
            fieldData.accept_end_time3 = ('0' + baseEndTime3.getHours()).slice(-2) + ':' + ('0' + baseEndTime3.getMinutes()).slice(-2);
        }else{
            fieldData.accept_end_time3 = '';
        }
        //-- 開始時間4
        if(baseStartTime4 != '' && baseStartTime4 != null){
            fieldData.accept_start_time4 = ('0' + baseStartTime4.getHours()).slice(-2) + ':' + ('0' + baseStartTime4.getMinutes()).slice(-2);
        }else{
            fieldData.accept_start_time4 = '';
        }
        //-- 終了時間4
        if(baseEndTime4 != '' && baseEndTime4 != null){
            fieldData.accept_end_time4 = ('0' + baseEndTime4.getHours()).slice(-2) + ':' + ('0' + baseEndTime4.getMinutes()).slice(-2);
        }else{
            fieldData.accept_end_time4 = '';
        }
    }

    /**
     * 曜日別受付時間格納
     */
    function fncSetDataWeekTime() {
        //--- 月
        //-- コースID
        fieldWeekTime[0].course_id = data.course_id;
        //-- 曜日区分
        fieldWeekTime[0].week_kbn = '0';
        //-- 休業区分
        fieldWeekTime[0].holiday_kbn = fieldHoliday.holiday_kbn1;
        //-- 受付開始時間1
        if(moStartTime1 != '' && moStartTime1 != null){
            fieldWeekTime[0].accept_start_time1 = ('0' + moStartTime1.getHours()).slice(-2) + ':' +  ('0' + moStartTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[0].accept_start_time1 = '';
        }
        //-- 受付終了時間1
        if(moEndTime1 != '' && moEndTime1 != null){
            fieldWeekTime[0].accept_end_time1 = ('0' + moEndTime1.getHours()).slice(-2) + ':' + ('0' + moEndTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[0].accept_end_time1 = '';
        }
        //-- 受付開始時間2
        if(moStartTime2 != '' && moStartTime2 != null){
            fieldWeekTime[0].accept_start_time2 = ('0' + moStartTime2.getHours()).slice(-2) + ':' + ('0' + moStartTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[0].accept_start_time2 = '';
        }
        //-- 受付終了時間2
        if(moEndTime2 != '' && moEndTime2 != null){
            fieldWeekTime[0].accept_end_time2 = ('0' + moEndTime2.getHours()).slice(-2) + ':' + ('0' + moEndTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[0].accept_end_time2 = '';
        }
        //-- 受付開始時間3
        if(moStartTime3 != '' && moStartTime3 != null){
            fieldWeekTime[0].accept_start_time3 = ('0' + moStartTime3.getHours()).slice(-2) + ':' + ('0' + moStartTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[0].accept_start_time3 = '';
        }
        //-- 受付終了時間3
        if(moEndTime3 != '' && moEndTime3 != null){
            fieldWeekTime[0].accept_end_time3 = ('0' + moEndTime3.getHours()).slice(-2) + ':' + ('0' + moEndTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[0].accept_end_time3 = '';
        }
        //-- 受付開始時間4
        if(moStartTime4 != '' && moStartTime4 != null){
            fieldWeekTime[0].accept_start_time4 = ('0' + moStartTime4.getHours()).slice(-2) + ':' + ('0' + moStartTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[0].accept_start_time4 = '';
        }
        //-- 受付終了時間4
        if(moEndTime4 != '' && moEndTime4 != null){
            fieldWeekTime[0].accept_end_time4 = ('0' + moEndTime4.getHours()).slice(-2) + ':' + ('0' + moEndTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[0].accept_end_time4 = '';
        }
        //-- 更新者ID
        fieldWeekTime[0].update_id = '';

        //--- 火
        //-- コースID
        fieldWeekTime[1].course_id = data.course_id;
        //-- 曜日区分
        fieldWeekTime[1].week_kbn = '1';
        //-- 休業区分
        fieldWeekTime[1].holiday_kbn = fieldHoliday.holiday_kbn2;
        //-- 受付開始時間1
        if(tuStartTime1 != '' && tuStartTime1 != null){
            fieldWeekTime[1].accept_start_time1 = ('0' + tuStartTime1.getHours()).slice(-2) + ':' + ('0' + tuStartTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[1].accept_start_time1 = '';
        }
        //-- 受付終了時間1
        if(tuEndTime1 != '' && tuEndTime1 != null){
            fieldWeekTime[1].accept_end_time1 = ('0' + tuEndTime1.getHours()).slice(-2) + ':' + ('0' + tuEndTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[1].accept_end_time1 = '';
        }
        //-- 受付開始時間2
        if(tuStartTime2 != '' && tuStartTime2 != null){
            fieldWeekTime[1].accept_start_time2 = ('0' + tuStartTime2.getHours()).slice(-2) + ':' + ('0' + tuStartTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[1].accept_start_time2 = '';
        }
        //-- 受付終了時間2
        if(tuEndTime2 != '' && tuEndTime2 != null){
            fieldWeekTime[1].accept_end_time2 = ('0' + tuEndTime2.getHours()).slice(-2) + ':' + ('0' + tuEndTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[1].accept_end_time2 = '';
        }
        //-- 受付開始時間3
        if(tuStartTime3 != '' && tuStartTime3 != null){
            fieldWeekTime[1].accept_start_time3 = ('0' + tuStartTime3.getHours()).slice(-2) + ':' + ('0' + tuStartTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[1].accept_start_time3 = '';
        }
        //-- 受付終了時間3
        if(tuEndTime3 != '' && tuEndTime3 != null){
            fieldWeekTime[1].accept_end_time3 = ('0' + tuEndTime3.getHours()).slice(-2) + ':' + ('0' + tuEndTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[1].accept_end_time3 = '';
        }
        //-- 受付開始時間4
        if(tuStartTime4 != '' && tuStartTime4 != null){
            fieldWeekTime[1].accept_start_time4 = ('0' + tuStartTime4.getHours()).slice(-2) + ':' + ('0' + tuStartTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[1].accept_start_time4 = '';
        }
        //-- 受付終了時間4
        if(tuEndTime4 != '' && tuEndTime4 != null){
            fieldWeekTime[1].accept_end_time4 = ('0' + tuEndTime4.getHours()).slice(-2) + ':' + ('0' + tuEndTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[1].accept_end_time4 = '';
        }
        //-- 更新者ID
        fieldWeekTime[1].update_id = '';

        //--- 水
        //-- コースID
        fieldWeekTime[2].course_id = data.course_id;
        //-- 曜日区分
        fieldWeekTime[2].week_kbn = '2';
        //-- 休業区分
        fieldWeekTime[2].holiday_kbn = fieldHoliday.holiday_kbn3;
        //-- 受付開始時間1
        if(weStartTime1 != '' && weStartTime1 != null){
            fieldWeekTime[2].accept_start_time1 = ('0' + weStartTime1.getHours()).slice(-2) + ':' + ('0' + weStartTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[2].accept_start_time1 = '';
        }
        //-- 受付終了時間1
        if(weEndTime1 != '' && weEndTime1 != null){
            fieldWeekTime[2].accept_end_time1 = ('0' + weEndTime1.getHours()).slice(-2) + ':' + ('0' + weEndTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[2].accept_end_time1 = '';
        }
        //-- 受付開始時間2
        if(weStartTime2 != '' && weStartTime2 != null){
            fieldWeekTime[2].accept_start_time2 = ('0' + weStartTime2.getHours()).slice(-2) + ':' + ('0' + weStartTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[2].accept_start_time2 = '';
        }
        //-- 受付終了時間2
        if(weEndTime2 != '' && weEndTime2 != null){
            fieldWeekTime[2].accept_end_time2 = ('0' + weEndTime2.getHours()).slice(-2) + ':' + ('0' + weEndTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[2].accept_end_time2 = '';
        }
        //-- 受付開始時間3
        if(weStartTime3 != '' && weStartTime3 != null){
            fieldWeekTime[2].accept_start_time3 = ('0' + weStartTime3.getHours()).slice(-2) + ':' + ('0' + weStartTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[2].accept_start_time3 = '';
        }
        //-- 受付終了時間3
        if(weEndTime3 != '' && weEndTime3 != null){
            fieldWeekTime[2].accept_end_time3 = ('0' + weEndTime3.getHours()).slice(-2) + ':' + ('0' + weEndTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[2].accept_end_time3 = '';
        }
        //-- 受付開始時間4
        if(weStartTime4 != '' && weStartTime4 != null){
            fieldWeekTime[2].accept_start_time4 = ('0' + weStartTime4.getHours()).slice(-2) + ':' + ('0' + weStartTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[2].accept_start_time4 = '';
        }
        //-- 受付終了時間4
        if(weEndTime4 != '' && weEndTime4 != null){
            fieldWeekTime[2].accept_end_time4 = ('0' + weEndTime4.getHours()).slice(-2) + ':' + ('0' + weEndTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[2].accept_end_time4 = '';
        }
        //-- 更新者ID
        fieldWeekTime[2].update_id = '';

        //--- 木
        //-- コースID
        fieldWeekTime[3].course_id = data.course_id;
        //-- 曜日区分
        fieldWeekTime[3].week_kbn = '3';
        //-- 休業区分
        fieldWeekTime[3].holiday_kbn = fieldHoliday.holiday_kbn4;
        //-- 受付開始時間1
        if(thStartTime1 != '' && thStartTime1 != null){
            fieldWeekTime[3].accept_start_time1 = ('0' + thStartTime1.getHours()).slice(-2) + ':' + ('0' + thStartTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[3].accept_start_time1 = '';
        }
        //-- 受付終了時間1
        if(thEndTime1 != '' && thEndTime1 != null){
            fieldWeekTime[3].accept_end_time1 = ('0' + thEndTime1.getHours()).slice(-2) + ':' + ('0' + thEndTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[3].accept_end_time1 = '';
        }
        //-- 受付開始時間2
        if(thStartTime2 != '' && thStartTime2 != null){
            fieldWeekTime[3].accept_start_time2 = ('0' + thStartTime2.getHours()).slice(-2) + ':' + ('0' + thStartTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[3].accept_start_time2 = '';
        }
        //-- 受付終了時間2
        if(thEndTime2 != '' && thEndTime2 != null){
            fieldWeekTime[3].accept_end_time2 = ('0' + thEndTime2.getHours()).slice(-2) + ':' + ('0' + thEndTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[3].accept_end_time2 = '';
        }
        //-- 受付開始時間3
        if(thStartTime3 != '' && thStartTime3 != null){
            fieldWeekTime[3].accept_start_time3 = ('0' + thStartTime3.getHours()).slice(-2) + ':' + ('0' + thStartTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[3].accept_start_time3 = '';
        }
        //-- 受付終了時間3
        if(thEndTime3 != '' && thEndTime3 != null){
            fieldWeekTime[3].accept_end_time3 = ('0' + thEndTime3.getHours()).slice(-2) + ':' + ('0' + thEndTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[3].accept_end_time3 = '';
        }
        //-- 受付開始時間4
        if(thStartTime4 != '' && thStartTime4 != null){
            fieldWeekTime[3].accept_start_time4 = ('0' + thStartTime4.getHours()).slice(-2) + ':' + ('0' + thStartTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[3].accept_start_time4 = '';
        }
        //-- 受付終了時間4
        if(thEndTime4 != '' && thEndTime4 != null){
            fieldWeekTime[3].accept_end_time4 = ('0' + thEndTime4.getHours()).slice(-2) + ':' + ('0' + thEndTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[3].accept_end_time4 = '';
        }
        //-- 更新者ID
        fieldWeekTime[3].update_id = '';

        //--- 金
        //-- コースID
        fieldWeekTime[4].course_id = data.course_id;
        //-- 曜日区分
        fieldWeekTime[4].week_kbn = '4';
        //-- 休業区分
        fieldWeekTime[4].holiday_kbn = fieldHoliday.holiday_kbn5;
        //-- 受付開始時間1
        if(frStartTime1 != '' && frStartTime1 != null){
            fieldWeekTime[4].accept_start_time1 = ('0' + frStartTime1.getHours()).slice(-2) + ':' + ('0' + frStartTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[4].accept_start_time1 = '';
        }
        //-- 受付終了時間1
        if(frEndTime1 != '' && frEndTime1 != null){
            fieldWeekTime[4].accept_end_time1 = ('0' + frEndTime1.getHours()).slice(-2) + ':' + ('0' + frEndTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[4].accept_end_time1 = '';
        }
        //-- 受付開始時間2
        if(frStartTime2 != '' && frStartTime2 != null){
            fieldWeekTime[4].accept_start_time2 = ('0' + frStartTime2.getHours()).slice(-2) + ':' + ('0' + frStartTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[4].accept_start_time2 = '';
        }
        //-- 受付終了時間2
        if(frEndTime2 != '' && frEndTime2 != null){
            fieldWeekTime[4].accept_end_time2 = ('0' + frEndTime2.getHours()).slice(-2) + ':' + ('0' + frEndTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[4].accept_end_time2 = '';
        }
        //-- 受付開始時間3
        if(frStartTime3 != '' && frStartTime3 != null){
            fieldWeekTime[4].accept_start_time3 = ('0' + frStartTime3.getHours()).slice(-2) + ':' + ('0' + frStartTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[4].accept_start_time3 = '';
        }
        //-- 受付終了時間3
        if(frEndTime3 != '' && frEndTime3 != null){
            fieldWeekTime[4].accept_end_time3 = ('0' + frEndTime3.getHours()).slice(-2) + ':' + ('0' + frEndTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[4].accept_end_time3 = '';
        }
        //-- 受付開始時間4
        if(frStartTime4 != '' && frStartTime4 != null){
            fieldWeekTime[4].accept_start_time4 = ('0' + frStartTime4.getHours()).slice(-2) + ':' + ('0' + frStartTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[4].accept_start_time4 = '';
        }
        //-- 受付終了時間4
        if(frEndTime4 != '' && frEndTime4 != null){
            fieldWeekTime[4].accept_end_time4 = ('0' + frEndTime4.getHours()).slice(-2) + ':' + ('0' + frEndTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[4].accept_end_time4 = '';
        }
        //-- 更新者ID
        fieldWeekTime[4].update_id = '';

        //--- 土
        //-- コースID
        fieldWeekTime[5].course_id = data.course_id;
        //-- 曜日区分
        fieldWeekTime[5].week_kbn = '5';
        //-- 休業区分
        fieldWeekTime[5].holiday_kbn = fieldHoliday.holiday_kbn6;
        //-- 受付開始時間1
        if(saStartTime1 != '' && saStartTime1 != null){
            fieldWeekTime[5].accept_start_time1 = ('0' + saStartTime1.getHours()).slice(-2) + ':' + ('0' + saStartTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[5].accept_start_time1 = '';
        }
        //-- 受付終了時間1
        if(saEndTime1 != '' && saEndTime1 != null){
            fieldWeekTime[5].accept_end_time1 = ('0' + saEndTime1.getHours()).slice(-2) + ':' + ('0' + saEndTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[5].accept_end_time1 = '';
        }
        //-- 受付開始時間2
        if(saStartTime2 != '' && saStartTime2 != null){
            fieldWeekTime[5].accept_start_time2 = ('0' + saStartTime2.getHours()).slice(-2) + ':' + ('0' + saStartTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[5].accept_start_time2 = '';
        }
        //-- 受付終了時間2
        if(saEndTime2 != '' && saEndTime2 != null){
            fieldWeekTime[5].accept_end_time2 = ('0' + saEndTime2.getHours()).slice(-2) + ':' + ('0' + saEndTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[5].accept_end_time2 = '';
        }
        //-- 受付開始時間3
        if(saStartTime3 != '' && saStartTime3 != null){
            fieldWeekTime[5].accept_start_time3 = ('0' + saStartTime3.getHours()).slice(-2) + ':' + ('0' + saStartTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[5].accept_start_time3 = '';
        }
        //-- 受付終了時間3
        if(saEndTime3 != '' && saEndTime3 != null){
            fieldWeekTime[5].accept_end_time3 = ('0' + saEndTime3.getHours()).slice(-2) + ':' + ('0' + saEndTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[5].accept_end_time3 = '';
        }
        //-- 受付開始時間4
        if(saStartTime4 != '' && saStartTime4 != null){
            fieldWeekTime[5].accept_start_time4 = ('0' + saStartTime4.getHours()).slice(-2) + ':' + ('0' + saStartTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[5].accept_start_time4 = '';
        }
        //-- 受付終了時間4
        if(saEndTime4 != '' && saEndTime4 != null){
            fieldWeekTime[5].accept_end_time4 = ('0' + saEndTime4.getHours()).slice(-2) + ':' + ('0' + saEndTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[5].accept_end_time4 = '';
        }
        //-- 更新者ID
        fieldWeekTime[5].update_id = '';

        //--- 日
        //-- コースID
        fieldWeekTime[6].course_id = data.course_id;
        //-- 曜日区分
        fieldWeekTime[6].week_kbn = '6';
        //-- 休業区分
        fieldWeekTime[6].holiday_kbn = fieldHoliday.holiday_kbn7;
        //-- 受付開始時間1
        if(suStartTime1 != '' && suStartTime1 != null){
            fieldWeekTime[6].accept_start_time1 = ('0' + suStartTime1.getHours()).slice(-2) + ':' + ('0' + suStartTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[6].accept_start_time1 = '';
        }
        //-- 受付終了時間1
        if(suEndTime1 != '' && suEndTime1 != null){
            fieldWeekTime[6].accept_end_time1 = ('0' + suEndTime1.getHours()).slice(-2) + ':' + ('0' + suEndTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[6].accept_end_time1 = '';
        }
        //-- 受付開始時間2
        if(suStartTime2 != '' && suStartTime2 != null){
            fieldWeekTime[6].accept_start_time2 = ('0' + suStartTime2.getHours()).slice(-2) + ':' + ('0' + suStartTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[6].accept_start_time2 = '';
        }
        //-- 受付終了時間2
        if(suEndTime2 != '' && suEndTime2 != null){
            fieldWeekTime[6].accept_end_time2 = ('0' + suEndTime2.getHours()).slice(-2) + ':' + ('0' + suEndTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[6].accept_end_time2 = '';
        }
        //-- 受付開始時間3
        if(suStartTime3 != '' && suStartTime3 != null){
            fieldWeekTime[6].accept_start_time3 = ('0' + suStartTime3.getHours()).slice(-2) + ':' + ('0' + suStartTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[6].accept_start_time3 = '';
        }
        //-- 受付終了時間3
        if(suEndTime3 != '' && suEndTime3 != null){
            fieldWeekTime[6].accept_end_time3 = ('0' + suEndTime3.getHours()).slice(-2) + ':' + ('0' + suEndTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[6].accept_end_time3 = '';
        }
        //-- 受付開始時間4
        if(suStartTime4 != '' && suStartTime4 != null){
            fieldWeekTime[6].accept_start_time4 = ('0' + suStartTime4.getHours()).slice(-2) + ':' + ('0' + suStartTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[6].accept_start_time4 = '';
        }
        //-- 受付終了時間4
        if(suEndTime4 != '' && suEndTime4 != null){
            fieldWeekTime[6].accept_end_time4 = ('0' + suEndTime4.getHours()).slice(-2) + ':' + ('0' + suEndTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[6].accept_end_time4 = '';
        }
        //-- 更新者ID
        fieldWeekTime[6].update_id = '';

        //--- 祝
        //-- コースID
        fieldWeekTime[7].course_id = data.course_id;
        //-- 曜日区分
        fieldWeekTime[7].week_kbn = '7';
        //-- 休業区分
        fieldWeekTime[7].holiday_kbn = fieldHoliday.holiday_kbn8;
        //-- 受付開始時間1
        if(hoStartTime1 != '' && hoStartTime1 != null){
            fieldWeekTime[7].accept_start_time1 = ('0' + hoStartTime1.getHours()).slice(-2) + ':' + ('0' + hoStartTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[7].accept_start_time1 = '';
        }
        //-- 受付終了時間1
        if(hoEndTime1 != '' && hoEndTime1 != null){
            fieldWeekTime[7].accept_end_time1 = ('0' + hoEndTime1.getHours()).slice(-2) + ':' + ('0' + hoEndTime1.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[7].accept_end_time1 = '';
        }
        //-- 受付開始時間2
        if(hoStartTime2 != '' && hoStartTime2 != null){
            fieldWeekTime[7].accept_start_time2 = ('0' + hoStartTime2.getHours()).slice(-2) + ':' + ('0' + hoStartTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[7].accept_start_time2 = '';
        }
        //-- 受付終了時間2
        if(hoEndTime2 != '' && hoEndTime2 != null){
            fieldWeekTime[7].accept_end_time2 = ('0' + hoEndTime2.getHours()).slice(-2) + ':' + ('0' + hoEndTime2.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[7].accept_end_time2 = '';
        }
        //-- 受付開始時間3
        if(hoStartTime3 != '' && hoStartTime3 != null){
            fieldWeekTime[7].accept_start_time3 = ('0' + hoStartTime3.getHours()).slice(-2) + ':' + ('0' + hoStartTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[7].accept_start_time3 = '';
        }
        //-- 受付終了時間3
        if(hoEndTime3 != '' && hoEndTime3 != null){
            fieldWeekTime[7].accept_end_time3 = ('0' + hoEndTime3.getHours()).slice(-2) + ':' + ('0' + hoEndTime3.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[7].accept_end_time3 = '';
        }
        //-- 受付開始時間4
        if(hoStartTime4 != '' && hoStartTime4 != null){
            fieldWeekTime[7].accept_start_time4 = ('0' + hoStartTime4.getHours()).slice(-2) + ':' + ('0' + hoStartTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[7].accept_start_time4 = '';
        }
        //-- 受付終了時間4
        if(hoEndTime4 != '' && hoEndTime4 != null){
            fieldWeekTime[7].accept_end_time4 = ('0' + hoEndTime4.getHours()).slice(-2) + ':' + ('0' + hoEndTime4.getMinutes()).slice(-2);
        }else{
            fieldWeekTime[7].accept_end_time4 = '';
        }
        //-- 更新者ID
        fieldWeekTime[7].update_id = '';
    }

    /**
     * チェック結果エラー
     */
    useEffect(() => {
        if(errors == undefined) return;
        if (Object.keys(errors).length > 0) {
            if(errors.course != undefined){
                setErrMode('1');
                setLoading('0');
                setModalMsg(errors.course);
                setShow(true);
            }else{
                window.scrollTo(0, 0);
                setLoading('0');
            }
        }
    }, [errors])

    /**
     * チェック結果正常
     */
    useEffect(() => {
        if(dbconn.id == '' || dbconn.id == undefined) return;
        if (Object.keys(result).length > 0) {
            setLoading('1');
            //-- データ格納
            fieldData.mode = data.mode;
            fieldData.update_id = user.staff_id;
            // 使用期間のフォーマットを変更し、fieldDataに値をセット
            if (useStartDate !== '' && useStartDate !== null) fieldData.use_start_date = dateFormatChange(useStartDate);
            if (useEndDate !== '' && useEndDate !== null) fieldData.use_end_date = dateFormatChange(useEndDate);

            //-- リクエストデータ
            var aryData = {
                course : [],
                weektime : [],
                tool : [],
                staff : [],
                id : dbconn.id,
            }
            aryData.course.push(fieldData);
            aryData.weektime.push(fieldWeekTime);
            aryData.tool.push(coursetool);
            aryData.staff.push(coursestaff);

            //-- POST
            Http.post('/api/add-course', {
                ...aryData,
            }).then(response => {
                const strStatus = response.data.status;
                if(strStatus == '200'){
                    setErrMode('0');
                    if(data.mode == '1'){
                        setModalMsg(MSG_CMP_ADD);
                    }else if(data.mode == '2'){
                        setModalMsg(MSG_CMP_UPD);
                    }else if(data.mode == '3'){
                        setModalMsg(MSG_CMP_DEL);
                    }
                }else{
                    setErrMode('1');
                    if(data.mode == '1'){
                        setModalMsg(MSG_NG_ADD);
                    }else if(data.mode == '2'){
                        setModalMsg(MSG_NG_UPD);
                    }else if(data.mode == '3'){
                        setModalMsg(MSG_NG_DEL);
                    }
                }
                setShow(true);
            }).catch(error => {
                if (error.response) {
                    alert(error.response.data)
                }
            }).finally(() => {
                setLoading('0');
            })
        }
    }, [result]);

    /**
     * モーダル画面クローズ
     */
    function fncReturnModalClose(){
        setShow(false);
        if(errMode == '0'){
            submitReturn(dbconn);
        }
    }

    return (
        <>
            <Form>
                <Row className={"course-mng-form-row"}>
                    <Col md={{span:11}}></Col>
                    <Col md={{ span: 1}}>
                        <Button
                            block
                            className="btn-back"
                            onClick={fncOnClickBack}
                            disabled={loading == '1'? 'disabled' : ''}
                        >
                            戻 る
                        </Button>
                    </Col>
                </Row>
                <Row className={"course-mng-form-row"}>
                    <div className={"div-course-mng-title"}>
                        <label className={"label-course-mng-padding"}>コース名<a style={{color:"#FF0000"}}>[必須]</a></label>
                    </div>
                    <div className={"div-course-mng-save-nm"}>
                        <FormControl
                            className="input-course-mng-course"
                            type="text"
                            value={fieldData.course_nm || ''}
                            onChange={handleDataFormat}
                            name="course_nm"
                            maxLength="50"
                            readOnly={data.mode === '3'? 'readOnly' : ''}
                            {...FormInput}
                        />
                    </div>
                </Row>
                {(errors.course_nm != undefined) && (
                    <Row className="course-mng-form-row">
                        <div className="div-course-mng-title"></div>
                        <div>
                            <span className="error-span">{errors.course_nm[0]}</span>
                        </div>
                    </Row>
                ) }
                <Row className={"course-mng-form-row"}>
                    <div className={"div-course-mng-title"}>
                        <label className={"label-course-mng-padding"}>所要時間<a style={{color:"#FF0000"}}>[必須]</a></label>
                    </div>
                    <div className={"div-course-mng-save-time"}>
                        <FormControl
                            className="input-course-mng-time"
                            type="text"
                            value={fieldData.course_time || ''}
                            onChange={handleDataFormat}
                            name="course_time"
                            maxLength="3"
                            readOnly={data.mode == '3'? 'readOnly' : ''}
                            {...FormInput}
                        />
                    </div>
                    <div>
                        <label className={"label-course-mng-right-time"}>分</label>
                    </div>
                </Row>
                {(errors.course_time != undefined) && (
                    <Row className="course-mng-form-row">
                        <div className="div-course-mng-title"></div>
                        <div>
                            <span className="error-span">{errors.course_time? errors.course_time[0] : ''}</span>
                        </div>
                    </Row>
                ) }
                <Row className={"course-mng-form-row"}>
                    <div className={"div-course-mng-title"}>
                        <label className={"label-course-mng-padding"}>表示順</label>
                    </div>
                    <div className={"div-course-mng-save-cnt"}>
                        <FormControl
                            className="input-course-mng-cnt"
                            type="text"
                            value={fieldData.disp_no || ''}
                            onChange={handleDataFormat}
                            name="disp_no"
                            maxLength={"4"}
                            readOnly={data.mode == '3'? 'readOnly' : ''}
                            {...FormInput}
                        />
                    </div>
                </Row>
                {(errors.disp_no != undefined) && (
                    <Row className="course-mng-form-row">
                        <div className="div-course-mng-title"></div>
                        <div>
                            <span className="error-span">{errors.disp_no? errors.disp_no[0] : ''}</span>
                        </div>
                    </Row>
                ) }
                <Row className={"course-mng-form-row"}>
                    <div className={"div-course-mng-title"}>
                        <label className={"label-course-mng-padding"}>表示有無</label>
                    </div>
                    <div className={"div-course-mng-save-used"}>
                        <label className={"label-course-mng-padding"}>
                            <input
                                type="radio"
                                className=""
                                name="user_flg"
                                value="1"
                                onChange={handleDataFormat}
                                checked={fieldData.user_flg === "1"}
                                disabled={data.mode == '3'? 'disabled' : ''}
                            /> 使用する
                        </label>
                    </div>
                    <div>
                        <label className={"label-course-mng-padding"}>
                            <input
                                type="radio"
                                className=""
                                name="user_flg"
                                value="0"
                                onChange={handleDataFormat}
                                checked={fieldData.user_flg === "0"}
                                disabled={data.mode == '3'? 'disabled' : ''}
                            /> 使用しない
                        </label>
                    </div>
                </Row>
                <Row className={"course-mng-form-row"}>
                    <div className={"div-course-mng-title"}>
                        <label className={"label-course-mng-padding"}>予約可能数</label>
                    </div>
                    <div className={"div-course-mng-save-rev"}>
                        <label className={"label-course-mng-padding"}>
                            <input
                                type="checkbox"
                                className=""
                                name="tool_reserve_cnt_flg"
                                value="1"
                                defaultChecked={data.tool_reserve_cnt_flg == "1"? "checked" : ""}
                                onChange={fncChangeReserveCntFlg}
                                disabled={data.mode == '3'? 'disabled' : ''}
                            /> 機材数を予約可能数とする
                        </label>
                    </div>
                </Row>
                <Row className={"course-mng-form-row"}>
                    <div className={"div-course-mng-title"}>
                        <label className={"label-course-mng-padding"}></label>
                    </div>
                    <div className={"div-course-mng-save-rev-msg"}>
                        <label className={"label-course-mng-padding-msg"}>
                            ※担当スタッフが必要ないコースの場合にチェックを付けてください
                        </label>
                    </div>
                </Row>
                {/*営業時間を超える予約対応*/}
                <Row style={{padding: "5px 15px"}}>
                    <div className="div-course-mng-title">
                        <label className="label-course-mng-padding">営業時間を超える予約対応</label>
                    </div>
                    <div className="pr-3">
                        <label className="label-course-mng-padding">
                            <input
                                type="radio"
                                name="reserve_over_kbn"
                                value="1"
                                onChange={handleDataFormat}
                                checked={fieldData.reserve_over_kbn === "1"}
                                disabled={data.mode === '3' && 'disabled'}
                            /> 予約を受け付ける
                        </label>
                    </div>
                    <div className="pr-3">
                        <label className="label-course-mng-padding">
                            <input
                                type="radio"
                                name="reserve_over_kbn"
                                value="2"
                                onChange={handleDataFormat}
                                checked={fieldData.reserve_over_kbn === "2"}
                                disabled={data.mode === '3' && 'disabled'}
                            /> 要電話確認
                        </label>
                    </div>
                    <div>
                        <label className="label-course-mng-padding">
                            <input
                                type="radio"
                                name="reserve_over_kbn"
                                value="3"
                                onChange={handleDataFormat}
                                checked={fieldData.reserve_over_kbn === "3"}
                                disabled={data.mode === '3' && 'disabled'}
                            /> 予約を受け付けない
                        </label>
                    </div>
                </Row>

                {/*コースの使用期間*/}
                <Row  style={{padding: "5px 15px"}}>
                    <div className="div-course-mng-title">
                        <label className="label-course-mng-padding">使用期間</label>
                    </div>
                    <div className="div-date-picker-width">
                        <DatePicker
                            className="date-picker"
                            showPopperArrow={false}
                            selected={useStartDate}
                            onChange={date => setUseStartDate(date)}
                            dateFormat="yyyy/MM/dd"
                            customInput={<InputMask mask="9999/99/99" maskChar={null} />}
                            id='use-start-date'
                            renderCustomHeader={({date, changeYear, changeMonth}) => (
                                <div className="div-date-picker">
                                    <select
                                        className="select-date-picker"
                                        value={new Date(date).getFullYear()}
                                        onChange={({target:{value}}) => changeYear(value)}
                                    >
                                        {shopyears.map(option => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        className="select-date-picker"
                                        value={months[new Date(date).getMonth()]}
                                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                                    >
                                        {months.map(option => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        />
                    </div>
                    <div className="div-calendar-icon">
                        <a onClick={() => {document.getElementById('use-start-date').focus()}}>
                            <IoIosCalendar/>
                        </a>
                    </div>
                    <div className="width-30 padding-left-15 text-center">
                        <label className="label-reserve-mng-padding">～</label>
                    </div>
                    <div className="div-date-picker-width ml-3">
                        <DatePicker
                            className="date-picker"
                            showPopperArrow={false}
                            selected={useEndDate}
                            onChange={date => setUseEndDate(date)}
                            dateFormat="yyyy/MM/dd"
                            customInput={<InputMask mask="9999/99/99" maskChar={null} />}
                            id='use-end-date'
                            renderCustomHeader={({date, changeYear, changeMonth}) => (
                                <div className="div-date-picker">
                                    <select
                                        className="select-date-picker"
                                        value={new Date(date).getFullYear()}
                                        onChange={({target:{value}}) => changeYear(value)}
                                    >
                                        {shopyears.map(option => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        className="select-date-picker"
                                        value={months[new Date(date).getMonth()]}
                                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                                    >
                                        {months.map(option => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        />
                    </div>
                    <div className="div-calendar-icon">
                        <a onClick={() => {document.getElementById('use-end-date').focus()}}>
                            <IoIosCalendar/>
                        </a>
                    </div>
                </Row>
                {(errors.use_date !== undefined) && (
                    <Row className="course-mng-form-row">
                        <div className="div-course-mng-title"></div>
                        <div>
                            <span className="error-span">{errors.use_date? errors.use_date[0] : ''}</span>
                        </div>
                    </Row>
                ) }

                <Row className={"course-mng-form-row"}>
                    <div className={"div-course-mng-title"}>
                        <Button
                            block
                            className="btn-course-time"
                            onClick={fncOnClickDispTime}
                            id={'btn-time'}
                            disabled={loading == '1'? 'disabled' : ''}
                        >
                            時間枠表示
                        </Button>
                    </div>
                    <div className={"div-course-mng-save-rev-msg"}>
                        <label className={"label-course-mng-padding-msg"}>
                            ※コース受付時間を設定する場合はクリックしてください。
                        </label>
                    </div>
                </Row>
                <br />

                <div className={"disp-aceppt-none"} id={"div-course-time"}>
                    <div className="text-header first-header " id={"div-base-time"}>▼基本受付時間</div>
                    <Row className={"course-mng-value "} id={"row-base-msg-1"}>
                        <label className={""}>　※コースの枠時間を入力し曜日別営業時間に反映してください。</label>
                    </Row>
                    <Row className={"course-mng-value "} id={"row-base-msg-2"}>
                        <label className={""}>　　特定の曜日のみ変更する場合は曜日別営業時間に直接入力してください。</label>
                    </Row>
                    {(errors.base_time != undefined) && (
                        <Row className="course-mng-error-time-row" id={"row-week-err"}>
                            <div>
                                <span className="error-span">{errors.base_time? errors.base_time[0] : ''}</span>
                            </div>
                        </Row>
                    ) }
                    <Row className={"course-mng-value "} id={"row-base-time"}>
                        <Col md={{ span: 12}}>
                            <table className={"course-mng-base"}>
                                <thead>
                                    <tr>
                                        <th className={"th-base-time-0"}></th>
                                        <th className={"th-base-time-1"}>受付時間1</th>
                                        <th className={"th-base-time-2"}>受付時間2</th>
                                        <th className={"th-base-time-3"}>受付時間3</th>
                                        <th className={"th-base-time-4"}>受付時間4</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={"td-base-time-0"}>
                                            <Button
                                                className={"btn-return"}
                                                onClick={fncOnClickBaseTime}
                                                disabled={loading == '1'? 'disabled' : data.mode === '3'? 'disabled' : ''}
                                            >
                                                時間<br/>反映
                                            </Button>
                                        </td>
                                        <WeekSet
                                            disabled={data.mode == '3'? 'disabled' : ''}
                                            strIDBaseS="dtBaseStartTime"
                                            strIDBaseE="dtBaseEndTime"
                                            valS1={baseStartTime1} fncSetS1={date => setBaseStartTime1(date)}
                                            valE1={baseEndTime1}   fncSetE1={date => setBaseEndTime1(date)}
                                            valS2={baseStartTime2} fncSetS2={date => setBaseStartTime2(date)}
                                            valE2={baseEndTime2}   fncSetE2={date => setBaseEndTime2(date)}
                                            valS3={baseStartTime3} fncSetS3={date => setBaseStartTime3(date)}
                                            valE3={baseEndTime3}   fncSetE3={date => setBaseEndTime3(date)}
                                            valS4={baseStartTime4} fncSetS4={date => setBaseStartTime4(date)}
                                            valE4={baseEndTime4}   fncSetE4={date => setBaseEndTime4(date)}
                                        />
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                    <div className="text-header first-header " id={"div-week-time"}>▼曜日別受付時間</div>
                    {(errors.week_time != undefined) && (
                        <Row className="course-mng-error-time-row" id={"row-week-err"}>
                            <div>
                                <span className="error-span">{errors.week_time? errors.week_time[0] : ''}</span>
                            </div>
                        </Row>
                    ) }
                    <Row className={"course-mng-value "} id={"row-week-time"}>
                        <Col md={{ span: 12}}>
                            <table className={"course-mng-week"}>
                                <thead>
                                    <tr>
                                        <th className={"th-base-yobi"}>曜</th>
                                        <th className={"th-base-holiday"}>休</th>
                                        <th className={"th-base-time-1"}>受付時間1</th>
                                        <th className={"th-base-time-2"}>受付時間2</th>
                                        <th className={"th-base-time-3"}>受付時間3</th>
                                        <th className={"th-base-time-4"}>受付時間4</th>
                                    </tr>
                                </thead>
                                {fncSetWeekTime()}
                            </table>
                        </Col>
                    </Row>
                </div>
                <br />

                <div className="text-header first-header">▼機材</div>
                {(errors.tool_cnt != undefined) && (
                    <Row className="course-mng-error-row">
                        <Col md={{ span: 12}}>
                            <span className="error-span">{errors.tool_cnt[0]}</span>
                        </Col>
                    </Row>
                ) }
                <Row className={"course-mng-value"}>
                    <Col md={{ span: 12}}>
                        <table className={"course-tool-list"}>
                            <thead className={"course-tool-thead"}>
                                <tr>
                                    <th className={"th-coursetool-no"}>No</th>
                                    <th className={"th-coursetool-sel"}>選択</th>
                                    <th className={"th-coursetool-name"}>機材名</th>
                                </tr>
                            </thead>
                            {fncGetCourseToolList()}
                        </table>
                    </Col>
                </Row>
                <br />

                <div className="text-header first-header">▼スタッフ</div>
                <Row className={"course-mng-value"}>
                    <Col md={{ span: 8}}></Col>
                    <Col md={{ span: 2}}>
                        <Button
                            block
                            className="btn-all-sel modal-btn-format"
                            id={"btn-all-sel"}
                            onClick={fncOnClickAllSel}
                            disabled={ data.mode == '3'? 'disabled' : loading == '1'? 'disabled' : ''}
                        >
                            全選択
                        </Button>
                    </Col>
                    <Col md={{ span: 2}}>
                        <Button
                            block
                            className="btn-all-rel modal-btn-format"
                            id={"btn-all-kaij"}
                            onClick={fncOnClickAllKaijo}
                            disabled={data.mode == '3'? 'disabled' : loading == '1'? 'disabled' : ''}
                        >
                            全解除
                        </Button>
                    </Col>
                </Row>
                {(errors.staff_cnt != undefined) && (
                    <Row className="course-mng-error-row">
                        <Col md={{ span: 12}}>
                            <span className="error-span">{errors.staff_cnt[0]}</span>
                        </Col>
                    </Row>
                ) }
                <Row className="course-mng-value">
                    <Col md={{ span: 12}}>
                        <table className={"course-staff-list"}>
                            <thead className={"course-staff-thead"}>
                            <tr>
                                <th className={"th-coursestaff-no"}>No</th>
                                <th className={"th-coursestaff-sel"}>選択</th>
                                <th className={"th-coursestaff-name"}>スタッフ名</th>
                            </tr>
                            </thead>
                            {fncGetCourseStaffList()}
                        </table>
                    </Col>
                </Row>
                <Row className="course-mng-value">
                    <Col md={{ span: 2, offset: 9 }}>
                        <Button
                            block
                            className="btn-save"
                            onClick={fncOnClickUpdate}
                            disabled={loading == '1'? 'disabled' : ''}
                        >
                            {data.mode == '1'? '登 録' : data.mode == '2'? '更 新' : '削 除'}
                        </Button>
                    </Col>
                    <Col md={{ span: 1 }}>
                        <Button
                            block
                            className="btn-back"
                            onClick={fncOnClickBack}
                            disabled={loading == '1'? 'disabled' : ''}
                        >
                            戻 る
                        </Button>
                    </Col>
                </Row>
            </Form>

            <CustomModal
                show={show}
                header={'メッセージ'}
                message={modalMsg}
                handleClose={fncReturnModalClose}
            />
        </>
    );
}

export default CourseMngAddForm;