import React, {useEffect, useState} from 'react';

import './ShopMng.css';
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import ShopMngForm from "./components/ShopMngForm";
import {fncgGetUserLoginCheck, fncgGetDbconn} from "Utils/Common/ComCom";
import {PRM_ID} from "../../../Utils/Common/ComConst";
import {useLocation} from "react-router-dom";

function ShopMng({ match, history, location }) {
	const [user, setUser] = useState('');
	const [shop, setShop] = useState('');
	const [sendTime, setSendTime] = useState('');
	const [sendFut, setSendFut] = useState('');
	const [sendPas, setSendPas] = useState('');
	const [urlpram, setUrlPram] = useState('');
	const [dbconn, setDbConn] = useState('');
	const [start, setStart] = useState('0');
	const path = useLocation().pathname;
	const handleSetUser = userData => setUser(userData);
	const handleSetUrlPram = dat => setUrlPram(dat);
	const handleSetDbConn = dat => setDbConn(dat);
	const handleSetStart = dat => setStart(dat);

	/**
	 * 初期処理
	 */
	useEffect(() => {
		fncgGetDbconn({history, location, handleSetUrlPram, handleSetDbConn, handleSetStart});
	}, [])

	/**
	 * スタッフ情報取得
	 */
	useEffect(() => {
		if(start == '0') return;
		// ログインチェックを行いユーザ情報を返却する
		fncgGetUserLoginCheck({dbconn, urlpram, path, user, handleSetUser, history})
	}, [start])

	/**
	 * 店舗情報取得
	 */
	useEffect(() => {
		if(start == '0') return;
		let aryData ={
			id : dbconn.id,
		}
		Http.post('api/get-shop',{
			...aryData
		}).then(response => {
			setShop(response.data.Shop);
			setSendTime(response.data.Send);
			setSendFut(response.data.SendFut);
			setSendPas(response.data.SendPas);
		});
	}, [start])


	return (
		<>
			<Layout headerText="店舗管理 -店舗登録-" _dbconn={dbconn} _user={user}>
				{(shop != "" && shop != undefined && user != "" && user != undefined && dbconn.id != '' && dbconn.id != undefined) &&(
					<ShopMngForm
						history={history}
						user={user}
						data={shop}
						time={sendTime}
						sendfut={sendFut}
						sendpas={sendPas}
						dbconn={dbconn}
					/>
				)}
			</Layout >
		</>
	);

}

export default ShopMng;