import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../UserMng.css';
import Http from '../../../../Utils/Axios';
import useHandleFormData from '../../../../Utils/FormHandlers/useHandleFormData';
import { FormInput } from '../../../../Utils/FormHandlers/HandleFormProperties';
import CustomModal from '../../../../Utils/Modal/CustomModal';
import {Button, Col, Row, Form, FormControl} from 'react-bootstrap';
import {
	MSG_NOT_DATA, SEARCH_ID,
} from "../../../../Utils/Common/ComConst";
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";
import MaterialPagination from '@material-ui/lab/Pagination';
import {Lists} from "../../../Reserve/ReserveMng/ReserveMngList/ReserveMngListForm";
import {CSVLink} from "react-csv";

const defaultState = {
	user_no : "",
	user_nm : "",
	user_kn : "",
	user_tel : "",
	info_on : "0",
	info_off : "0",
	id : "",
};

const defaultUser ={
	user_id : "",
	mode : "",
	id : "",
};

function UserMngListForm({ history, dbconn, submitMove }) {
	const [fieldData, handleDataFormat] = useHandleFormData({defaultState});
	const [fieldUser] = useState({defaultUser});
	const [listData, setListData] = useState('');
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [loading, setLoading] = useState('0');
	const strPram = fncgSetUrlPram(dbconn);
	const [csvData, setCsvData] = useState('');
	const [csvFileNm, setCsvFileNm] = useState('');

	/**
	 * 初期処理
	 */
	useEffect(() => {
		//--- ページ離脱イベントOFF
		window.onbeforeunload = null;

		//--- データ初期化
		localStorage.setItem(SEARCH_ID, '');

		//--- 検索
		fncOnClickSearch();
	}, [])

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		localStorage.setItem(SEARCH_ID, '');
		history.push('/menu' + strPram);
	}

	/**
	 * 検索
	 */
	function fncOnClickSearch(){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		fieldData.id = dbconn.id;
		setLoading('1');

		Http.post('api/search-user', {
			...fieldData
		}).then(response => {
			if (Object.keys(response.data.list).length > 0) {
				setListData(response.data.list);
				setCsvData(response.data.csv);
			}else{
				setListData('');
				setCsvData('');
				setModalMsg(MSG_NOT_DATA);
				setShow(true);
			}
			setLoading('0');
		});
	}

	/**
	 * 新規ボタン
	 */
	function fncOnClickAddNew(){
		fieldUser.user_id = '';
		fieldUser.mode = '1';
		fieldUser.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldUser));
		submitMove(fieldUser);
	}

	/**
	 * 編集ボタン
	 */
	function fncOnClickMngUpd(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strUserID = document.getElementById('hdn-user-id-' + strID ).value;
		fieldUser.user_id = strUserID;
		fieldUser.mode = '2';
		fieldUser.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldUser));
		submitMove(fieldUser);
	}

	/**
	 * 削除ボタン
	 */
	function fncOnClickMngDel(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strUserID = document.getElementById('hdn-user-id-' + strID ).value;
		fieldUser.user_id = strUserID;
		fieldUser.mode = '3';
		fieldUser.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldUser));
		submitMove(fieldUser);
	}

	/**
	 * 検索条件-希望する
	 * @param e
	 */
	function fncChangeInfoOn(e){
		if( fieldData.info_on == '1' ){
			fieldData.info_on = '0'
		}else{
			fieldData.info_on = e.target.value;
		}
	}

	/**
	 * 検索条件-希望しない
	 * @param e
	 */
	function fncChangeInfoOff(e){
		if( fieldData.info_off == '1' ){
			fieldData.info_off = '0'
		}else{
			fieldData.info_off = e.target.value;
		}
	}

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);
	}

	/**
	 * CSVボタン
	 */
	function fncOnClickCsv(){
		//--- ファイル名作成
		let dtNowCsv = new Date();
		let year = dtNowCsv.getFullYear().toString();
		let month = (dtNowCsv.getMonth() + 1).toString();
		let day = dtNowCsv.getDate().toString();
		//-- 0埋め
		month = ('0' + (month)).slice(-2);
		day = ('0' + (day)).slice(-2);
		//-- ファイル日時
		let fileTime = year + month + day;

		setCsvFileNm('会員一覧_' + fileTime + '.csv');
	}

	/**
	 * Pagination
	 * @returns {*}
	 */
	const [currentPage, setCurrentPage] = useState(1);
	const listsPerPage = 100;
	const indexOfLastList = currentPage * listsPerPage;
	const indexOfFirstList = indexOfLastList - listsPerPage;
	const currentLists = listData.slice(indexOfFirstList, indexOfLastList);

	const handleChange = (event, value) => {
		setCurrentPage(value);
	}

	return (
		<>
			<Form>
				<div>
					<Row className="staff-mng-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<div className="text-header first-header">▼検索</div>
				<Row className="user-mng-value">
					<div className="width-search-label div-user-mng-search-title-no">
						<label className="label-user-mng-title">会員番号</label>
					</div>
					<div className={"div-user-mng-search-no"}>
						<FormControl
							className="input-user-mng-search-no"
							type="text"
							value={fieldData.user_no || ''}
							onChange={handleDataFormat}
							name="user_no"
							{...FormInput}
						/>
					</div>
				</Row>
				<Row className="user-mng-value">
					<div className="width-search-label div-user-mng-search-title-nm">
						<label className="label-user-mng-title">会員名</label>
					</div>
					<div className={"div-user-mng-search-nm"}>
						<FormControl
							className="input-user-mng-search-nm"
							type="text"
							value={fieldData.user_nm || ''}
							onChange={handleDataFormat}
							name="user_nm"
							{...FormInput}
						/>
					</div>
					<div className="width-search-label div-user-mng-search-title-kn">
						<label className="label-user-mng-title">フリガナ</label>
					</div>
					<div className={"div-user-mng-search-kn"}>
						<FormControl
							className="input-user-mng-search-kn"
							type="text"
							value={fieldData.user_kn || ''}
							onChange={handleDataFormat}
							name="user_kn"
							{...FormInput}
						/>
					</div>
				</Row>
				<Row className="user-mng-value">
					<div className="width-search-label div-user-mng-search-title-tel">
						<label className="label-user-mng-title">電話番号</label>
					</div>
					<div className={"div-user-mng-search-tel"}>
						<FormControl
							className="input-user-mng-search-tel"
							type="text"
							value={fieldData.user_tel}
							onChange={handleDataFormat}
							name="user_tel"
							maxLength="15"
							{...FormInput}
						/>
					</div>
					<div className="width-search-label div-user-mng-search-title-info">
						<label className="label-user-mng-title">お知らせ</label>
					</div>
					<div className={"div-user-mng-search-info-on"}>
						<label className="label-user-mng-padding">
							<input
								className="user-mng-checkbox"
								type="checkbox"
								value="1"
								onChange={fncChangeInfoOn}
								name="info_on"
							/> 希望する
						</label>
					</div>
					<div className={"div-user-mng-search-info-off"}>
						<label className="label-user-mng-padding">
							<input
								className="user-mng-checkbox"
								type="checkbox"
								value="1"
								onChange={fncChangeInfoOff}
								name="info_off"
							/> 希望しない
						</label>
					</div>
				</Row>
				<Row className="user-mng-value">
					<Col md={{ span: 1, offset: 11}}>
						<Button
							block
							className="btn-search"
							onClick={fncOnClickSearch}
							disabled={loading == '1'? 'disabled' : ''}
						>
							検 索
						</Button>
					</Col>
				</Row>

				<div>
					<Row className="user-mng-row-header">
						<Col md={{ span: 10 }}></Col>
						<Col md={{ span:1 }}>
							{csvData.length == 0 ?
								<Button
									className="btn btn-block btn-csv"
									disabled={'disabled'}
								>
									CSV
								</Button>
								:
								loading == '1'?
									<Button
										className="btn btn-block btn-csv"
										disabled={'disabled'}
									>
										CSV
									</Button>
									:
									<CSVLink
										className="btn btn-block btn-csv"
										onClick={fncOnClickCsv}
										filename={csvFileNm}
										data={csvData}
									>
										CSV
									</CSVLink>
							}
						</Col>
						<Col md={{ span: 1 }}>
							<Button
								block
								className="btn-new"
								onClick={fncOnClickAddNew}
								disabled={loading == '1'? 'disabled' : ''}
							>
								新 規
							</Button>
						</Col>
					</Row>
				</div>

				<Row className="justify-content-md-center pb-0">
					<Col md={{ span: 1}} className="text-left">
						<div className="text-header first-header">▼一覧</div>
					</Col>
					<Col md={{ span: 4, offset:3 }} className="px-4">
						<div className="pagination-wrapper">
							{listData && (<MaterialPagination count={Math.ceil(listData.length / listsPerPage)}
															  color="primary"
															  onChange={handleChange}
															  page={currentPage}
															  className="px-auto mx-4"
							/>)}
						</div>
					</Col>
					<Col  md={{ span: 2, offset: 2 }} className="pt-3 text-right">
						<p className="mb-0">総件数： {listData.length}件</p>
					</Col>
				</Row>
				<Row className="user-mng-value">
					<Col md={{ span: 12}}>
						<table className={"user-mng-list"}>
							<thead>
								<tr>
									<th className={"th-usermng-no"}>No</th>
									<th className={"th-usermng-userNo"}>会員番号</th>
									<th className={"th-usermng-nm"}>会員名</th>
									<th className={"th-usermng-kn"}>カナ</th>
									<th className={"th-usermng-tel"}>電話番号</th>
									<th className={"th-usermng-sex"}>性別</th>
									<th className={"th-usermng-birth"}>生年月日</th>
									<th className={"th-usermng-info"}>お知らせ</th>
									<th className={"th-usermng-upd"}>編集</th>
									<th className={"th-usermng-del"}>削除</th>
								</tr>
							</thead>
							{listData !== '' && (
								<UserMngList listData={currentLists}
									   loading={loading}
									   fnconclickmngupd={fncOnClickMngUpd}
									   fnconclickmngdel={fncOnClickMngDel}
								/>
							)}
						</table>
					</Col>
				</Row>
				<Row className="justify-content-md-center">
					<Col md={{ span: 4 }} className="px-4">
						{listData && (<MaterialPagination count={Math.ceil(listData.length / listsPerPage)}
														  color="primary"
														  onChange={handleChange}
														  page={currentPage}
														  className="px-auto mx-4"
						/>)}
					</Col>
				</Row>
				<div>
					<Row className="staff-mng-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>

		</>
	);

}

export default UserMngListForm;

/**
 * 一覧作成
 * @returns {*}
 */
export const UserMngList = (props) => {
	return (
		<tbody>
		{props.listData.map((list, index) => (
			<tr key={index}>
				<td className="td-usermng-no">
					{Number(index) + 1}
				</td>
				<td className="td-usermng-userNo">
					{list.user_no != null? list.user_no : ''}
				</td>
				<td className="td-usermng-nm">
					{list.user_nm != null? list.user_nm : ''}
				</td>
				<td className="td-usermng-kn">
					{list.user_kn != null? list.user_kn : ''}
				</td>
				<td className="td-usermng-tel">
					{(list.user_tel != null? list.user_tel : '')}
				</td>
				<td className="td-usermng-sex">
					{list.user_sex === '0'? '男' : list.user_sex === '1'? '女' : ''}
				</td>
				<td className="td-usermng-birth">
					{(list.user_birthday_year != null? list.user_birthday_year : '')
					+ (list.user_birthday_month != null? '/' + list.user_birthday_month : '')
					+ (list.user_birthday_day != null? '/' + list.user_birthday_day : '')
					}
				</td>
				<td className="td-usermng-info">
					{list.info_mailflg === '1'? '希望する' : '希望しない'}
				</td>
				<td className="td-usermng-upd">
					<Button
						block
						className="btn-edit"
						onClick={props.fnconclickmngupd}
						data-id={index}
						disabled={props.loading === '1'? 'disabled' : ''}
					>
						編 集
					</Button>
					<input type="hidden" id={'hdn-user-id-' + index}
						   value={list.user_id}>
					</input>
				</td>
				<td className="td-usermng-del">
					<Button
						block
						className="btn-delete"
						onClick={props.fnconclickmngdel}
						data-id={index}
						disabled={props.loading === '1'? 'disabled' : ''}
					>
						削 除
					</Button>
				</td>
			</tr>
		))}
		</tbody>
	)
}