import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../ShopSale.css';
import Http from 'Utils/Axios';
import CustomModal from 'Utils/Modal/CustomModal';
import DatePicker from 'react-datepicker';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import '../../../../Utils/Common/react-datepicker.css';
import {
	DISP_FG,
	MSG_CMP_ADD,
	MSG_INFO_SHOP_SALE_MIDATA,
	MSG_INFO_SHOP_SALE_NON_BASE,
	MSG_INFO_SHOP_SALE_NON_COURSE,
	MSG_INFO_SHOP_SALE_NON_SHIFT,
	MSG_INFO_SHOP_SALE_SAVE,
	MSG_NG_ADD,
	MSG_NG_TIME,
	MSG_SYSTEM_UPD_BACK,
	MST_SHOP_SALE_UPD_SHIFT,
	SEARCH_ID,
} from "../../../../Utils/Common/ComConst";
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";
import { IoIosCalendar, IoMdTime } from "react-icons/io";
import TimePicker from "../../../../Utils/FormHandlers/TimePicker";

const defaultState = {
	start_date : "",
	id : "",
}
let eigyoKbn = '';
let eigyoTime = {
	start_time1 : '',
	end_time1 : '',
	start_time2 : '',
	end_time2 : '',
	start_time3 : '',
	end_time3 : '',
	start_time4 : '',
	end_time4 : '',
	holiday_kbn : '',
}

let dialogFlg = '';

function ShopSaleForm({ history, user, shop, dbconn }) {
	const [fieldData] = useState({defaultState});
	const [defData] = useState({eigyoTime});
	const [listData, setListData] = useState('');
	const [calendar, setCalendar] = useState('');
	const [dateSearch, setDateSearch] = useState('');

	const [defdate, setDefDate] = useState('');
	const [nodata, setNoData] = useState('');
	const [courseFlg, setCourseFlg] = useState('');
	const [shiftFlg, setShiftFlg] = useState('');
	const [showsale, setShowSale] = useState(false);
	const [id, setID] = useState(0);
	const [holidaykbn, setHolidayKbn] = useState('0');
	const [startTime1, setStartTime1] = useState('');
	const [startTime2, setStartTime2] = useState('');
	const [startTime3, setStartTime3] = useState('');
	const [startTime4, setStartTime4] = useState('');
	const [closeTime1, setCloseTime1] = useState('');
	const [closeTime2, setCloseTime2] = useState('');
	const [closeTime3, setCloseTime3] = useState('');
	const [closeTime4, setCloseTime4] = useState('');

	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [loading, setLoading] = useState('0');

	const strPram = fncgSetUrlPram(dbconn);

	/**
	 * ページ離脱イベント
	 * @param e
	 */
	window.onbeforeunload = function(e){
		//--- 確認メッセージ表示(トリガーとしてのみ有効、メッセージは内容は反映されない)
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			e.returnValue = '';
		}
	}

	/**
	 * 初期表示
	 */
	useEffect(() => {
        var objDate = new Date();
		//--- ストレージ取得
		let searchID = localStorage.getItem(SEARCH_ID);
		if(searchID != '' && searchID != undefined){
			searchID = JSON.parse(searchID);
            objDate = new Date(searchID);
        }

		//--- 日付設定
		var strDate = objDate.getFullYear() + '/' + (objDate.getMonth() + 1) + '/01';
		var strDateTitle = objDate.getFullYear() + '年' + (objDate.getMonth() + 1) + '月';

		//--- カレンダータイトル
		setCalendar(strDateTitle);

		//--- 検索日
		setDateSearch(objDate);

		//--- 検索
		fncSearchCalendar(strDate);

		dialogFlg = '0';
	}, [])

	/**
	 * 前月
	 */
	function fncOnClickBefore(){
		//--- 前月計算
		dateSearch.setDate(1);
		dateSearch.setMonth(dateSearch.getMonth() - 1);

		//--- フォーマット変換
		var strDate = dateSearch.getFullYear() + '/' + (dateSearch.getMonth() + 1 ) + '/01';
		var strDateTitle = dateSearch.getFullYear() + '年' + (dateSearch.getMonth() + 1 ) + '月';
		var objDate = new Date(strDate);

		//--- 検索条件保存
		localStorage.setItem(SEARCH_ID, JSON.stringify(objDate));

		//--- カレンダータイトル
		setCalendar(strDateTitle);

		//--- 検索日
		setDateSearch(objDate);

		//--- 検索
		fncSearchCalendar(strDate);
	}

	/**
	 * 次月
	 */
	function fncOnClickNext(){
		//--- 次月計算
		dateSearch.setDate(1);
		dateSearch.setMonth(dateSearch.getMonth() + 1);

		//--- フォーマット変換
		var strDate = dateSearch.getFullYear() + '/' + (dateSearch.getMonth() + 1) + '/01';
		var strDateTitle = dateSearch.getFullYear() + '年' + (dateSearch.getMonth() + 1) + '月';
		var objDate = new Date(strDate);

		//--- 検索条件保存
		localStorage.setItem(SEARCH_ID, JSON.stringify(objDate));

		//--- カレンダータイトル
		setCalendar(strDateTitle);

		//--- 検索日
		setDateSearch(objDate);

		//--- 検索
		fncSearchCalendar(strDate);
	}

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		eigyoTime.start_time1 = '';
		eigyoTime.end_time1 = '';
		eigyoTime.start_time2 = '';
		eigyoTime.end_time2 = '';
		eigyoTime.start_time3 = '';
		eigyoTime.end_time3 = '';
		eigyoTime.start_time4 = '';
		eigyoTime.end_time4 = '';
		localStorage.setItem(SEARCH_ID, "");
		history.push('/menu' + strPram);
	}

	/**
	 * 更新ボタン
	 */
	function fncOnClickUpdate(){
		if(dbconn.id == '' || dbconn.id == undefined) return;

		setLoading('1');

		//--- データ格納
		var aryData ={
			shop: shop.shop_id,
			update_id : user.staff_id,
			shopsale : [],
			id : dbconn.id,
			sel_index : id,
		};
		aryData.shopsale.push(listData);

		//-- POST
		Http.post('/api/upd-shopsale', {
			...aryData,
		}).then(response => {
			const strStatus = response.data.status;
			if(strStatus == '200'){
				if(response.data.staffnm != ''){
					setModalMsg(MSG_CMP_ADD + '\n' + response.data.staffnm + MST_SHOP_SALE_UPD_SHIFT);
				}else{
					setModalMsg(MSG_CMP_ADD);
				}
			}else{
				const strError = response.data.error;
				setModalMsg(MSG_NG_ADD + strError);
			}
			//setShow(true);
		}).catch(error => {
			if (error.response) {
				var strErrMng = '';
				strErrMng = MSG_NG_ADD;
				strErrMng += error.response.data;
				setModalMsg(strErrMng);
				//alert(error.response.data)
			}
		}).finally(() => {
			setLoading('0');
			setShow(true);
		})
	}

	/**
	 * 営業時間変更
	 */
	function fncOnClickModalOpen(e){
		//--- 表示データ取得
		var strID = e.currentTarget.getAttribute('data-id');
		var strSaleKbn = document.getElementById('hdn-sale-kbn-' + strID ).value;
		var strOpenTime1 = document.getElementById('hdn-open-time1-' + strID ).value;
		var strCloseTime1 = document.getElementById('hdn-close-time1-' + strID ).value;
		var strOpenTime2 = document.getElementById('hdn-open-time2-' + strID ).value;
		var strCloseTime2 = document.getElementById('hdn-close-time2-' + strID ).value;
		var strOpenTime3 = document.getElementById('hdn-open-time3-' + strID ).value;
		var strCloseTime3 = document.getElementById('hdn-close-time3-' + strID ).value;
		var strOpenTime4 = document.getElementById('hdn-open-time4-' + strID ).value;
		var strCloseTime4 = document.getElementById('hdn-close-time4-' + strID ).value;

		defData.start_time1 = strOpenTime1;
		defData.end_time1 = strCloseTime1;
		defData.start_time2 = strOpenTime2;
		defData.end_time2 = strCloseTime2;
		defData.start_time3 = strOpenTime3;
		defData.end_time3  = strCloseTime3;
		defData.start_time4 = strOpenTime4;
		defData.end_time4  = strCloseTime4;
		defData.holiday_kbn = strSaleKbn;

		//--- ID格納
		setID(strID);

		//--- 現在日付取得
		var dtNow = new Date();
		var strDate = dtNow.getFullYear() + '/' + (dtNow.getMonth() + 1) + '/' + dtNow.getDate() + ' ';

		//--- 表示データ格納
		eigyoKbn = strSaleKbn;
		setHolidayKbn(strSaleKbn);
		if(strOpenTime1 != ''){
			setStartTime1(new Date(strDate + strOpenTime1 + ':00'));
		}else{
			setStartTime1('');
		}
		if(strOpenTime2 != ''){
			setStartTime2(new Date(strDate + strOpenTime2 + ':00'));
		}else{
			setStartTime2('');
		}
		if(strOpenTime3 != ''){
			setStartTime3(new Date(strDate + strOpenTime3 + ':00'));
		}else{
			setStartTime3('');
		}
		if(strOpenTime4 != ''){
			setStartTime4(new Date(strDate + strOpenTime4 + ':00'));
		}else{
			setStartTime4('');
		}
		if(strCloseTime1 != ''){
			setCloseTime1(new Date(strDate + strCloseTime1 + ':00'));
		}else{
			setCloseTime1('');
		}
		if(strCloseTime2 != ''){
			setCloseTime2(new Date(strDate + strCloseTime2 + ':00'));
		}else{
			setCloseTime2('');
		}
		if(strCloseTime3 != ''){
			setCloseTime3(new Date(strDate + strCloseTime3 + ':00'));
		}else{
			setCloseTime3('');
		}
		if(strCloseTime4 != ''){
			setCloseTime4(new Date(strDate + strCloseTime4 + ':00'));
		}else{
			setCloseTime4('');
		}

		//--- モーダル画面表示
		dialogFlg = '1';
		setShowSale(true);

	}

	/**
	 * モーダル営業時間設定
	 */
	function fncOnClickModalOk(){
		var strHours = '';
		var strMinutes = '';
		//--- 変更内容を反映
		listData[id].sale_kbn = holidaykbn;

		//-- 営業開始時間1
		if(startTime1 != '' && startTime1 != null){
			strHours = ('00' + startTime1.getHours()).slice(-2);
			strMinutes = ('00' + startTime1.getMinutes()).slice(-2);
			listData[id].open_time1 = strHours + ':' + strMinutes;
			if(holidaykbn == '0'){
				eigyoTime.start_time1 = strHours + ':' + strMinutes;
			}
		}else{
			listData[id].open_time1 = '';
			if(holidaykbn == '0'){
				eigyoTime.start_time1 = '';
			}
		}
		//-- 営業開始時間2
		strHours = '';
		strMinutes = '';
		if(startTime2 != '' && startTime2 != null){
			strHours = ('00' + startTime2.getHours()).slice(-2);
			strMinutes = ('00' + startTime2.getMinutes()).slice(-2);
			listData[id].open_time2 = strHours + ':' + strMinutes;
			if(holidaykbn == '0'){
				eigyoTime.start_time2 = strHours + ':' + strMinutes;
			}
		}else{
			listData[id].open_time2 = '';
			if(holidaykbn == '0') {
				eigyoTime.start_time2 = '';
			}
		}
		//-- 営業開始時間3
		strHours = '';
		strMinutes = '';
		if(startTime3 != '' && startTime3 != null){
			strHours = ('00' + startTime3.getHours()).slice(-2);
			strMinutes = ('00' + startTime3.getMinutes()).slice(-2);
			listData[id].open_time3 = strHours + ':' + strMinutes;
			if(holidaykbn == '0') {
				eigyoTime.start_time3 = strHours + ':' + strMinutes;
			}
		}else{
			listData[id].open_time3 = '';
			if(holidaykbn == '0') {
				eigyoTime.start_time3 = '';
			}
		}
		//-- 営業開始時間4
		strHours = '';
		strMinutes = '';
		if(startTime4 != '' && startTime4 != null){
			strHours = ('00' + startTime4.getHours()).slice(-2);
			strMinutes = ('00' + startTime4.getMinutes()).slice(-2);
			listData[id].open_time4 = strHours + ':' + strMinutes;
			if(holidaykbn == '0') {
				eigyoTime.start_time4 = strHours + ':' + strMinutes;
			}
		}else{
			listData[id].open_time4 = '';
			if(holidaykbn == '0') {
				eigyoTime.start_time4 = '';
			}
		}
		//-- 営業終了時間1
		strHours = '';
		strMinutes = '';
		if(closeTime1 != '' && closeTime1 != null){
			strHours = ('00' + closeTime1.getHours()).slice(-2);
			strMinutes = ('00' + closeTime1.getMinutes()).slice(-2);
			listData[id].close_time1 = strHours + ':' + strMinutes;
			if(holidaykbn == '0') {
				eigyoTime.end_time1 = strHours + ':' + strMinutes;
			}
		}else{
			listData[id].close_time1 = '';
			if(holidaykbn == '0') {
				eigyoTime.end_time1 = '';
			}
		}
		//-- 営業終了時間2
		strHours = '';
		strMinutes = '';
		if(closeTime2 != '' && closeTime2 != null){
			strHours = ('00' + closeTime2.getHours()).slice(-2);
			strMinutes = ('00' + closeTime2.getMinutes()).slice(-2);
			listData[id].close_time2 = strHours + ':' + strMinutes;
			if(holidaykbn == '0') {
				eigyoTime.end_time2 = strHours + ':' + strMinutes;
			}
		}else{
			listData[id].close_time2 = '';
			if(holidaykbn == '0') {
				eigyoTime.end_time2 = '';
			}
		}
		//-- 営業終了時間3
		strHours = '';
		strMinutes = '';
		if(closeTime3 != '' && closeTime3 != null){
			strHours = ('00' + closeTime3.getHours()).slice(-2);
			strMinutes = ('00' + closeTime3.getMinutes()).slice(-2);
			listData[id].close_time3 = strHours + ':' + strMinutes;
			if(holidaykbn == '0') {
				eigyoTime.end_time3 = strHours + ':' + strMinutes;
			}
		}else{
			listData[id].close_time3 = '';
			if(holidaykbn == '0') {
				eigyoTime.end_time3 = '';
			}
		}
		//-- 営業終了時間4
		strHours = '';
		strMinutes = '';
		if(closeTime4 != '' && closeTime4 != null){
			strHours = ('00' + closeTime4.getHours()).slice(-2);
			strMinutes = ('00' + closeTime4.getMinutes()).slice(-2);
			listData[id].close_time4 = strHours + ':' + strMinutes;
			if(holidaykbn == '0') {
				eigyoTime.end_time4 = strHours + ':' + strMinutes;
			}
		}else{
			listData[id].close_time4 = '';
			if(holidaykbn == '0') {
				eigyoTime.end_time4 = '';
			}
		}

		//--- データチェック
		//-- POST
		Http.post('/api/chk-shopsale', {
			...listData[id],
		}).then(response => {
			const strStatus = response.data.status;
			if(strStatus == '200'){
				//- データ格納
				setListData(listData);
				//- モーダルクローズ
				setShowSale(false);

				fncOnClickUpdate();
			}else if(strStatus == '300'){
				setModalMsg(MSG_NG_TIME);
				setShow(true);
			}

		}).catch(error => {
			if (error.response) {
				alert(error.response.data)
			}
		}).finally(() => {

		})
	}

	/**
	 * 休業区分選択
	 * @param e
	 */
	function fncOnChangeHoliday(e){
		setHolidayKbn(e.currentTarget.value);

		//--- 登録済みの時間が存在する場合は上書きしない
		if(listData[id].open_time1 != '' || listData[id].open_time2 != '' || listData[id].open_time3 != '' || listData[id].open_time4 != '' ||
			listData[id].close_time1 != '' || listData[id].close_time2 != '' || listData[id].close_time3 != '' || listData[id].close_time4 != ''){
			return;
		}

		//--- 休業から営業へ変更した場合、直前に入力した時間を表示
		if(eigyoKbn == '1' && e.currentTarget.value == '0'){
			//-- 現在日付取得
			var dtNow = new Date();
			var strDate = dtNow.getFullYear() + '/' + (dtNow.getMonth() + 1) + '/' + dtNow.getDate() + ' ';
			//-- 営業時間1
			if(eigyoTime.start_time1 == '' || eigyoTime.start_time1 == null){
				setStartTime1(null);
			}else{
				setStartTime1(new Date(strDate + eigyoTime.start_time1 + ':00'));
			}
			if(eigyoTime.end_time1 == '' || eigyoTime.end_time1 == null){
				setCloseTime1(null);
			}else{
				setCloseTime1(new Date(strDate + eigyoTime.end_time1 + ':00'));
			}
			//-- 営業時間2
			if(eigyoTime.start_time2 == '' || eigyoTime.start_time2 == null){
				setStartTime2(null);
			}else{
				setStartTime2(new Date(strDate + eigyoTime.start_time2 + ':00'));
			}
			if(eigyoTime.end_time2 == '' || eigyoTime.end_time2 == null){
				setCloseTime2(null);
			}else{
				setCloseTime2(new Date(strDate + eigyoTime.end_time2 + ':00'));
			}
			//-- 営業時間3
			if(eigyoTime.start_time3 == '' || eigyoTime.start_time3 == null){
				setStartTime3(null);
			}else{
				setStartTime3(new Date(strDate + eigyoTime.start_time3 + ':00'));
			}
			if(eigyoTime.end_time3 == '' || eigyoTime.end_time3 == null){
				setCloseTime3(null);
			}else{
				setCloseTime3(new Date(strDate + eigyoTime.end_time3 + ':00'));
			}
			//-- 営業時間4
			if(eigyoTime.start_time4 == '' || eigyoTime.start_time4 == null){
				setStartTime4(null);
			}else{
				setStartTime4(new Date(strDate + eigyoTime.start_time4 + ':00'));
			}
			if(eigyoTime.end_time4 == '' || eigyoTime.end_time4 == null){
				setCloseTime4(null);
			}else{
				setCloseTime4(new Date(strDate + eigyoTime.end_time4 + ':00'));
			}
		}
		/*
		if(e.currentTarget.value == '1'){
			setStartTime1(null);
			setStartTime2(null);
			setStartTime3(null);
			setStartTime4(null);
			setCloseTime1(null);
			setCloseTime2(null);
			setCloseTime3(null);
			setCloseTime4(null);
		}
		*/
	}

	/**
	 * モーダルクローズ
	 */
	function fncOnClickSaleModalClose() {
		//--- 変更チェック
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			if(window.confirm(MSG_SYSTEM_UPD_BACK)){
				//-- 初期に戻す
				listData[id].open_time1 = defData.start_time1;
				listData[id].close_time1 = defData.end_time1;
				listData[id].open_time2 = defData.start_time2;
				listData[id].close_time2 = defData.end_time2;
				listData[id].open_time3 = defData.start_time3;
				listData[id].close_time3 = defData.end_time3;
				listData[id].open_time4 = defData.start_time4;
				listData[id].close_time4 = defData.end_time4;
				listData[id].sale_kbn = defData.holiday_kbn;
				//-- 閉じる
				dialogFlg = '0';
				setShowSale(false);
			}
		}else{
			//-- 閉じる
			dialogFlg = '0';
			setShowSale(false);
		}
	}

	/**
	 * 変更項目チェック
	 * @returns {string}
	 */
	function fncChkBackData(){
		//--- ダイアログが表示されていない場合は判定しない
		if(dialogFlg != '1') return '0';

		//--- 時間設定
		fncSetCalenderData();

		//--- 判定
		if(listData[id].open_time1 != defData.start_time1 ||
			listData[id].close_time1 != defData.end_time1 ||
			listData[id].open_time2 != defData.start_time2 ||
			listData[id].close_time2 != defData.end_time2 ||
			listData[id].open_time3 != defData.start_time3 ||
			listData[id].close_time3 != defData.end_time3 ||
			listData[id].open_time4 != defData.start_time4 ||
			listData[id].close_time4 != defData.end_time4 ||
			listData[id].sale_kbn != defData.holiday_kbn){
			return '1';
		}
		return '0';
	}

	/**
	 * 日別営業時間情報設定
	 */
	function fncSetCalenderData(){
		let strHours = '';
		let strMinutes = '';
		//--- 変更内容を反映
		listData[id].sale_kbn = holidaykbn;

		//-- 営業開始時間1
		if(startTime1 != '' && startTime1 != null){
			strHours = ('00' + startTime1.getHours()).slice(-2);
			strMinutes = ('00' + startTime1.getMinutes()).slice(-2);
			listData[id].open_time1 = strHours + ':' + strMinutes;
		}else{
			listData[id].open_time1 = '';
		}
		//-- 営業開始時間2
		strHours = '';
		strMinutes = '';
		if(startTime2 != '' && startTime2 != null){
			strHours = ('00' + startTime2.getHours()).slice(-2);
			strMinutes = ('00' + startTime2.getMinutes()).slice(-2);
			listData[id].open_time2 = strHours + ':' + strMinutes;
		}else{
			listData[id].open_time2 = '';
		}
		//-- 営業開始時間3
		strHours = '';
		strMinutes = '';
		if(startTime3 != '' && startTime3 != null){
			strHours = ('00' + startTime3.getHours()).slice(-2);
			strMinutes = ('00' + startTime3.getMinutes()).slice(-2);
			listData[id].open_time3 = strHours + ':' + strMinutes;
		}else{
			listData[id].open_time3 = '';
		}
		//-- 営業開始時間4
		strHours = '';
		strMinutes = '';
		if(startTime4 != '' && startTime4 != null){
			strHours = ('00' + startTime4.getHours()).slice(-2);
			strMinutes = ('00' + startTime4.getMinutes()).slice(-2);
			listData[id].open_time4 = strHours + ':' + strMinutes;
		}else{
			listData[id].open_time4 = '';
		}
		//-- 営業終了時間1
		strHours = '';
		strMinutes = '';
		if(closeTime1 != '' && closeTime1 != null){
			strHours = ('00' + closeTime1.getHours()).slice(-2);
			strMinutes = ('00' + closeTime1.getMinutes()).slice(-2);
			listData[id].close_time1 = strHours + ':' + strMinutes;
		}else{
			listData[id].close_time1 = '';
		}
		//-- 営業終了時間2
		strHours = '';
		strMinutes = '';
		if(closeTime2 != '' && closeTime2 != null){
			strHours = ('00' + closeTime2.getHours()).slice(-2);
			strMinutes = ('00' + closeTime2.getMinutes()).slice(-2);
			listData[id].close_time2 = strHours + ':' + strMinutes;
		}else{
			listData[id].close_time2 = '';
		}
		//-- 営業終了時間3
		strHours = '';
		strMinutes = '';
		if(closeTime3 != '' && closeTime3 != null){
			strHours = ('00' + closeTime3.getHours()).slice(-2);
			strMinutes = ('00' + closeTime3.getMinutes()).slice(-2);
			listData[id].close_time3 = strHours + ':' + strMinutes;
		}else{
			listData[id].close_time3 = '';
		}
		//-- 営業終了時間4
		strHours = '';
		strMinutes = '';
		if(closeTime4 != '' && closeTime4 != null){
			strHours = ('00' + closeTime4.getHours()).slice(-2);
			strMinutes = ('00' + closeTime4.getMinutes()).slice(-2);
			listData[id].close_time4 = strHours + ':' + strMinutes;
		}else{
			listData[id].close_time4 = '';
		}
	}

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);
	}

	/**
	 * 検索
	 */
	function fncSearchCalendar($strDate){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		setLoading('1');
		fieldData.start_date = $strDate;
		fieldData.id = dbconn.id;

		Http.post('api/get-shopsale', {
			...fieldData
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setListData(response.data);
				setDefDate(response.data[0].def_date_flg);
				setNoData(response.data[0].no_data_flg);
				setCourseFlg(response.data[0].course_flg);
				setShiftFlg(response.data[0].shift_flg);
			}else{
				setListData('');
				setNoData('2');
			}
			setLoading('0');
		});
	}

	/**
	 * 一覧作成
	 * @returns {*}
	 */
	function fncGetShopSaleCalendar(){
		var lstShopSale = [];
		var lstDay = [];
		var intDay = 0;
		for(var intIdx in listData){
			intDay += 1;

			lstDay.push(
				<td className="td-shop-sale" key={intIdx}>
					{listData[intIdx].calender_day == ''?
						<></>
						:
						<Button
							block
							className={
								listData[intIdx].sale_kbn == '1'? "btn-shop-sale-day-holiday"
									:
									listData[intIdx].course_flg == '0' || listData[intIdx].shift_flg == '0' ? "btn-shop-sale-day-non"
									:
									 "btn-shop-sale-day"
							}
							onClick={fncOnClickModalOpen}
							data-id={intIdx}
							disabled={listData[intIdx].def_date_flg == '2' ? 'disabled' : ''}
						>
							<input type="hidden" id={'hdn-calendar-date-' + intIdx} value={listData[intIdx].calendar_date} />
							<input type="hidden" id={'hdn-sale-kbn-' + intIdx} value={listData[intIdx].sale_kbn} />
							<input type="hidden" id={'hdn-open-time1-' + intIdx} value={listData[intIdx].open_time1} />
							<input type="hidden" id={'hdn-close-time1-' + intIdx} value={listData[intIdx].close_time1} />
							<input type="hidden" id={'hdn-open-time2-' + intIdx} value={listData[intIdx].open_time2} />
							<input type="hidden" id={'hdn-close-time2-' + intIdx} value={listData[intIdx].close_time2} />
							<input type="hidden" id={'hdn-open-time3-' + intIdx} value={listData[intIdx].open_time3} />
							<input type="hidden" id={'hdn-close-time3-' + intIdx} value={listData[intIdx].close_time3} />
							<input type="hidden" id={'hdn-open-time4-' + intIdx} value={listData[intIdx].open_time4} />
							<input type="hidden" id={'hdn-close-time4-' + intIdx} value={listData[intIdx].close_time4} />
							<input type="hidden" id={'hdn-def-date-flg-' + intIdx} value={listData[intIdx].def_date_flg} />

							<div>
								<span>
									{listData[intIdx].calender_day}
								</span>
							</div>
							{listData[intIdx].sale_kbn == '1'?
								<>
									<div><span >{'休業日'}</span></div>
									<div><span><br/></span></div>
									<div><span><br/></span></div>
									<div><span><br/></span></div>
								</>
								:
								<>
									<div>
										<span>
											{listData[intIdx].open_time1 == ''? <br/> : listData[intIdx].open_time1 + '~' + listData[intIdx].close_time1}
										</span>
									</div>
									<div>
										<span>
											{listData[intIdx].open_time2 == ''? <br/> : listData[intIdx].open_time2 + '~' + listData[intIdx].close_time2}
										</span>
									</div>
									<div>
										<span>
										{listData[intIdx].open_time3 == ''? <br/> : listData[intIdx].open_time3 + '~' + listData[intIdx].close_time3}
										</span>
									</div>
									<div>
										<span>
										{listData[intIdx].open_time4 == ''? <br/> : listData[intIdx].open_time4 + '~' + listData[intIdx].close_time4}
										</span>
									</div>
								</>
							}
						</Button>
					}
				</td>
			);

			if(intDay != 7){
				continue;
			}

			lstShopSale.push(
				<tr key={intIdx}>
					{lstDay}
				</tr>
			);

			intDay = 0;
			lstDay = [];
		}

		if(lstShopSale.length > 0){
			return(
				<tbody>
				{lstShopSale}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	return (
		<>
			<Form>
				<div>
					<Row className="shop-sale-form-row">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<Row className="shop-sale-value">
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-ago"
							onClick={fncOnClickBefore}
							disabled={loading == '1'? 'disabled' : ''}
						>
							前 月
						</Button>
					</Col>
					<Col md={{ span: 10}}>
						<label className="label-shop-sale-date">{calendar}</label>
					</Col>
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-next"
							onClick={fncOnClickNext}
							disabled={loading == '1'? 'disabled' : ''}
						>
							次 月
						</Button>
					</Col>
				</Row>
				{defdate == '1' && (
					<Row className="shop-sale-value">
						<Col md={{ span: 12}}>
							<label className="shop-sale-message">{MSG_INFO_SHOP_SALE_MIDATA}</label>
						</Col>
					</Row>
				)}
				{nodata == '2' && (
					<Row className="shop-sale-value">
						<Col md={{ span: 12}}>
							<label className="shop-sale-message">{MSG_INFO_SHOP_SALE_NON_BASE}</label>
						</Col>
					</Row>
				)}
				{courseFlg == '0' && (
					<Row className="shop-sale-value">
						<Col md={{ span: 12}}>
							<label className="shop-sale-message">{MSG_INFO_SHOP_SALE_NON_COURSE}</label>
						</Col>
					</Row>
				)}
				{shiftFlg == '0' && (
					<Row className="shop-sale-value">
						<Col md={{ span: 12}}>
							<label className="shop-sale-message">{MSG_INFO_SHOP_SALE_NON_SHIFT}</label>
						</Col>
					</Row>
				)}

				<Row className="shop-sale-value">
					<Col md={{ span: 12}}>
						<table className={"shop-sale-calendar"}>
							<thead>
								<tr>
									<th className={"th-shop-sale color-sunday"}>日</th>
									<th className={"th-shop-sale"}>月</th>
									<th className={"th-shop-sale"}>火</th>
									<th className={"th-shop-sale"}>水</th>
									<th className={"th-shop-sale"}>木</th>
									<th className={"th-shop-sale"}>金</th>
									<th className={"th-shop-sale color-saturday"}>土</th>
								</tr>
							</thead>
							{fncGetShopSaleCalendar()}
						</table>
					</Col>
				</Row>

				<Row className="shop-sale-value">
					<Col md={{ span: 2, offset: 9}}>
					</Col>
					<Col md={{ span: 1 }}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
				</Row>
			</Form>

			<Modal show={showsale} onHide={fncOnClickSaleModalClose}>
				<Modal.Header closeButton>
					<Modal.Title>-営業設定-</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="shop-sale-value">
						<div className={"div-shop-sale-title"} >
							<label>
								<input
									type="radio"
									className="rdo-shop-sale-holiday"
									name="regular_holiday"
									value="0"
									onChange={fncOnChangeHoliday}
									checked={holidaykbn === "0"}
								/> 営 業
							</label>
						</div>
						<div className={"div-shop-sale-list-time"}>
							<label>
								<input
									type="radio"
									className="rdo-shop-sale-holiday"
									name="regular_holiday"
									value="1"
									onChange={fncOnChangeHoliday}
									checked={holidaykbn === "1"}
								/> 休 業
							</label>
						</div>
					</Row>
					<Row className="shop-sale-value">
						<div className={"div-shop-sale-title"} >
							<label className="label-shop-sale-padding">営業時間1</label>
						</div>
						<TimePicker
							baseClassName="div-shop-sale-list-time"
							selected={startTime1}
							onChange={date => setStartTime1(date)}
							disabled={holidaykbn === '1'? 'disabled' : ''}
							id={"dtStartTime1"}
							nextFocusID={"dtCloseTime1"}
						/>
						<div className="label-shop-sale-reiod">
							<label className="label-shop-sale-padding">～</label>
						</div>
						<TimePicker
							baseClassName="div-shop-sale-list-time"
							selected={closeTime1}
							onChange={date => setCloseTime1(date)}
							disabled={holidaykbn === '1'? 'disabled' : ''}
							id={"dtCloseTime1"}
						/>
					</Row>
					<Row className="shop-sale-value">
						<div className={"div-shop-sale-title"} >
							<label className="label-shop-sale-padding">営業時間2</label>
						</div>
						<TimePicker
							baseClassName="div-shop-sale-list-time"
							selected={startTime2}
							onChange={date => setStartTime2(date)}
							disabled={holidaykbn === '1'? 'disabled' : ''}
							id={"dtStartTime2"}
							nextFocusID={"dtCloseTime2"}
						/>
						<div className="label-shop-sale-reiod">
							<label className="label-shop-sale-padding">～</label>
						</div>
						<TimePicker
							baseClassName="div-shop-sale-list-time"
							selected={closeTime2}
							onChange={date => setCloseTime2(date)}
							disabled={holidaykbn === '1'? 'disabled' : ''}
							id={"dtCloseTime2"}
						/>
					</Row>
					<Row className="shop-sale-value">
						<div className={"div-shop-sale-title"} >
							<label className="label-shop-sale-padding">営業時間3</label>
						</div>
						<TimePicker
							baseClassName="div-shop-sale-list-time"
							selected={startTime3}
							onChange={date => setStartTime3(date)}
							disabled={holidaykbn === '1'? 'disabled' : ''}
							id={"dtStartTime3"}
							nextFocusID={"dtCloseTime3"}
						/>
						<div className="label-shop-sale-reiod">
							<label className="label-shop-sale-padding">～</label>
						</div>
						<TimePicker
							baseClassName="div-shop-sale-list-time"
							selected={closeTime3}
							onChange={date => setCloseTime3(date)}
							disabled={holidaykbn === '1'? 'disabled' : ''}
							id={"dtCloseTime3"}
						/>
					</Row>
					<Row className="shop-sale-value">
						<div className={"div-shop-sale-title"} >
							<label className="label-shop-sale-padding">営業時間4</label>
						</div>
						<TimePicker
							baseClassName="div-shop-sale-list-time"
							selected={startTime4}
							onChange={date => setStartTime4(date)}
							disabled={holidaykbn === '1'? 'disabled' : ''}
							id={"dtStartTime4"}
							nextFocusID={"dtCloseTime4"}
						/>
						<div className="label-shop-sale-reiod">
							<label className="label-shop-sale-padding">～</label>
						</div>
						<TimePicker
							baseClassName="div-shop-sale-list-time"
							selected={closeTime4}
							onChange={date => setCloseTime4(date)}
							disabled={holidaykbn === '1'? 'disabled' : ''}
							id={"dtCloseTime4"}
						/>
					</Row>
					<Row className="shop-sale-value">

					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Row className="shop-sale-value">
						<Col md={{ span: 6}}>
							<Button block className="btn-back" onClick={fncOnClickSaleModalClose}>
								戻 る
							</Button>
						</Col>
						<Col md={{ span: 6}}>
							<Button
								block
								className="btn-ok"
								onClick={fncOnClickModalOk}
							>
								登 録
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>
		</>
	);

}

export default ShopSaleForm;