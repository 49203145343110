import React from 'react';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

function Notification(type, message) {
  switch (type) {
    case 'info':
      NotificationManager.info(message);
      break;
    case 'success':
      NotificationManager.success('成功メッセージ', message);
      break;
    case 'warning':
      NotificationManager.warning('警告メッセージ', message);
      break;
    case 'error':
      NotificationManager.error('エラーメッセージ', message, 5000, () => {
        window.location.href = '/' + window.location.search;
      });
      localStorage.clear();
    break;
  }
}

export default Notification;