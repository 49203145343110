import React, { useState, useEffect } from 'react';

import '../Login.css';
import useHandleFormData from 'Utils/FormHandlers/useHandleFormData'
import { FormInput } from 'Utils/FormHandlers/HandleFormProperties'
import { Button, Col, Row, FormControl, Form } from 'react-bootstrap';

const defaultState = {
	password : "",
	username : "",
	id : '',
}

const style_css ={
	color : '#0000FF',
	fontSize :'',
	hover:{
		color : '#FF0000',
	}
}

function LoginForm({ onSubmitData, errors, loading, dbconn }) {
	const [fieldData, handleDataFormat] = useHandleFormData(defaultState);
	const credentialError = 'ユーザーIDまたはパスワードが正しくありません。';
	const [css_style] = useHandleFormData(style_css);

	function handleOnSubmit(e) {
		e.preventDefault();
		fieldData.id = dbconn.id;
	 	onSubmitData(fieldData);
	}

	function handleMouseOver(e){
		//e.color = '#FF0000';
		//e.currentTarget().style.color = '#FF0000';
		//$("#a").css("color", "#FF0000");
		//e.currentTarget.setAttribute('color', "#FF0000");
		e.target.style.color = style_css.hover.color;
	}
	function handleMouseOut(e){
		e.target.style.color = style_css.color;
	}

	return (
		<>
			<Form onSubmit={handleOnSubmit}>
				<Row className="justify-content-md-center">
					<Col md={1}></Col>
					<Col md={2} className="login-input-label" >
				    	<label>
							ユ ー ザ ー I D
						</label>
					</Col>
					<Col md={5}>
				    	<FormControl
					    	className="login-input-field"
						    type="text"
						    placeholder="登録したユーザーIDを入力してください"
							value={fieldData.username}
							onChange={handleDataFormat}
						    name="username"
						    {...FormInput}
						    required
					    />	
					</Col>
				</Row>
				<Row className="justify-content-md-center">
					<Col md={1}></Col>
					<Col md={2} className="login-input-label">
				   		<label>
							パ ス ワ ー ド
						</label>
					</Col>
					<Col md={5}>			
						 <FormControl
							className="login-input-field"
						    type="password" 
						    placeholder="登録したパスワードを入力してください"
						    onChange={handleDataFormat}
						    name="password"
						    {...FormInput}
						    required
						/>	
					</Col>						
				</Row>
				<div className="error-credentials">			
					{(errors && errors.error == 'invalid_credentials')  ?  credentialError : errors.message }
				</div>	
				<Row className="justify-content-md-center">
					<Col md={3} className="login-input-label">
						<Button className="login-button login" type="submit" disabled={loading}>
							{loading ? '読み込み中。 。 。' : 'ロ グ イ ン'}
						</Button>
					</Col>	
				</Row>
			</Form>		
		</>
	
	);
}

 export default LoginForm;