import React, { useState, useEffect } from 'react';

import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import DashBoardForm from "./DashBoardForm/DashBoardForm";
import {fncgGetUserLoginCheck, fncgGetDbconn} from "Utils/Common/ComCom";
import {PRM_ID} from "../../Utils/Common/ComConst";
import {useLocation} from "react-router-dom";

function DashBoard({ history, location }) {
	const [user, setUser] = useState('');
	const [urlpram, setUrlPram] = useState('');
	const [dbconn, setDbConn] = useState('');
	const [start, setStart] = useState('0');
	const path = useLocation().pathname;
	const handleSetUser = userData => setUser(userData);
	const handleSetUrlPram = dat => setUrlPram(dat);
	const handleSetDbConn = dat => setDbConn(dat);
	const handleSetStart = dat => setStart(dat);

	/**
	 * URLパラメータ取得
	 */
	useEffect(() => {
		fncgGetDbconn({history, location, handleSetUrlPram, handleSetDbConn, handleSetStart});
	}, [])

	/**
	 * スタッフ情報取得
	 */
	useEffect(() => {
		if(start == '0') return;
		// ログインチェックを行いユーザ情報を返却する
		fncgGetUserLoginCheck({dbconn, urlpram, path, user, handleSetUser, history})
	}, [start])

	return 	(
		<>
			{(user != "" && user != undefined && dbconn.id != '' && dbconn.id != undefined)&& (
				<Layout headerText="ダッシュボード" _dbconn={dbconn} _user={user}>
					<DashBoardForm
						history={history}
						dbconn={dbconn}
					/>
				</Layout >
				//<></>
			)}
		</>
	);
}

export default DashBoard;
