import React, {useEffect, useState} from 'react';

import '../../../Utils/Common/com-style.css';
import '../Holiday.css';
import Http from 'Utils/Axios';
import CustomModal from 'Utils/Modal/CustomModal';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import '../../../Utils/Common/react-datepicker.css';
import {
	MSG_CMP_ADD,
	MSG_NG_ADD, SEARCH_ID,
} from "../../../Utils/Common/ComConst";
import {fncgSetUrlPram} from "../../../Utils/Common/ComCom";

const defaultState = {
	start_date : "",
	id : "",
}

function HolidayForm({ history, user, shop, dbconn }) {
	const [fieldData] = useState({defaultState});
	const [listData, setListData] = useState('');
	const [calendar, setCalendar] = useState('');
	const [dateSearch, setDateSearch] = useState('');
	const [result, setResult] = useState('');
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [loading, setLoading] = useState('0');
	const strPram = fncgSetUrlPram(dbconn);

	/**
	 * 初期表示
	 */
	useEffect(() => {
		var objDate = new Date();
		let searchID = localStorage.getItem(SEARCH_ID);
		if(searchID != '' && searchID != undefined){
			searchID = JSON.parse(searchID);
			objDate = new Date(searchID);
		}

		//--- 日付設定
		var strDate = objDate.getFullYear() + '/' + (objDate.getMonth() + 1) + '/01';
		var strDateTitle = objDate.getFullYear() + '年' + (objDate.getMonth() + 1) + '月';

		//--- カレンダータイトル
		setCalendar(strDateTitle);

		//--- 検索日
		setDateSearch(objDate);

		//--- 検索
		fncSearchCalendar(strDate);

	}, [])

	/**
	 * 検索
	 */
	function fncSearchCalendar($strDate){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		setLoading('1');
		fieldData.start_date = $strDate;
		fieldData.id = dbconn.id;

		Http.post('api/get-holiday', {
			...fieldData
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setListData(response.data);
			}else{
				setListData('');
			}
			setLoading('0');
		});
	}

	/**
	 * 前月
	 */
	function fncOnClickBefore(){
		//--- 前月計算
		dateSearch.setDate(1);
		dateSearch.setMonth(dateSearch.getMonth() - 1);

		//--- フォーマット変換
		var strDate = dateSearch.getFullYear() + '/' + (dateSearch.getMonth() + 1 ) + '/01';
		var strDateTitle = dateSearch.getFullYear() + '年' + (dateSearch.getMonth() + 1 ) + '月';
		var objDate = new Date(strDate);

		//--- 検索条件保存
		localStorage.setItem(SEARCH_ID, JSON.stringify(objDate));

		//--- カレンダータイトル
		setCalendar(strDateTitle);

		//--- 検索日
		setDateSearch(objDate);

		//--- 検索
		fncSearchCalendar(strDate);
	}

	/**
	 * 次月
	 */
	function fncOnClickNext(){
		//--- 次月計算
		dateSearch.setDate(1);
		dateSearch.setMonth(dateSearch.getMonth() + 1);

		//--- フォーマット変換
		var strDate = dateSearch.getFullYear() + '/' + (dateSearch.getMonth() + 1 ) + '/01';
		var strDateTitle = dateSearch.getFullYear() + '年' + (dateSearch.getMonth() + 1 ) + '月';
		var objDate = new Date(strDate);

		//--- 検索条件保存
		localStorage.setItem(SEARCH_ID, JSON.stringify(objDate));

		//--- カレンダータイトル
		setCalendar(strDateTitle);

		//--- 検索日
		setDateSearch(objDate);

		//--- 検索
		fncSearchCalendar(strDate);
	}

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		localStorage.setItem(SEARCH_ID, "");
		history.push('/menu' + strPram);
	}

	/**
	 * 更新ボタン
	 */
	function fncOnClickUpdate(calData){
		if(dbconn.id == '' || dbconn.id == undefined) return;

		setLoading('1');

		//--- データ格納
		var aryData ={
			shop: shop.shop_id,
			update_id : user.staff_id,
			holiday_calendar : calData,
			id : dbconn.id,
		};

		//-- POST
		Http.post('/api/upd-holiday', {
			...aryData,
		}).then(response => {
			const strStatus = response.data.status;
			if(strStatus == '200'){
				setResult('1');
				setModalMsg(MSG_CMP_ADD);
			}else{
				setResult('0');
				const strError = response.data.error;
				setModalMsg(MSG_NG_ADD + strError);
			}
			//setShow(true);
		}).catch(error => {
			setResult('0');
			if (error.response) {
				var strErrMng = '';
				strErrMng = MSG_NG_ADD;
				strErrMng += error.response.data;
				setModalMsg(strErrMng);
				//alert(error.response.data)
			}
		}).finally(() => {
			setLoading('0');
			setShow(true);
		})
	}

	/**
	 * 休日区分選択
	 * @param e
	 */
	function fncOnChangeHoliday(e){
		//--- 休日区分取得
		let strID = e.currentTarget.getAttribute('data-id');
		let strHolidayFlg = listData[strID].holiday_flg;

		//--- 休日区分変更
		if(strHolidayFlg == '0'){
			strHolidayFlg = '1';
			document.getElementById('spn-holiday-1-' + strID ).textContent = '祝日予定';

		}else{
			strHolidayFlg = '0';
			document.getElementById('spn-holiday-1-' + strID ).textContent = '　';
			//document.getElementById('btn-Calendar-' + strID ).style.backgroundColor ='#FFFFFF';
			document.getElementById('btn-Calendar-' + strID ).className = 'btn-holiday-day';
		}

		//--- 休日区分格納
		listData[strID].holiday_flg = strHolidayFlg;
		document.getElementById('hdn-holiday-flg-' + strID ).value = strHolidayFlg;

		//--- 更新
		fncOnClickUpdate(listData[strID]);
	}

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);

		//--- 再表示
		if(result == '1'){
			setListData('');
			//-- フォーマット変換
			var strDate = dateSearch.getFullYear() + '/' + (dateSearch.getMonth() + 1 ) + '/01';
			//-- 検索
			fncSearchCalendar(strDate);
		}
	}

	/**
	 * 一覧作成
	 * @returns {*}
	 */
	function fncGetHolidayCalendar(){
		var lstCalendar = [];
		var lstDay = [];
		var intDay = 0;
		for(var intIdx in listData){
			intDay += 1;

			lstDay.push(
				<td className="td-holiday" key={intIdx}>
					{listData[intIdx].calender_day == ''?
						<></>
						:
						<Button block className={(listData[intIdx].holiday_flg == '1' || listData[intIdx].week_kbn == '6')? "btn-holiday-day-holiday" : "btn-holiday-day"} id={"btn-Calendar-" + intIdx} onClick={fncOnChangeHoliday} data-id={intIdx}>
							<input type="hidden" id={'hdn-calendar-date-' + intIdx} value={listData[intIdx].calendar_date} />
							<input type="hidden" id={'hdn-week-kbn-' + intIdx} value={listData[intIdx].week_kbn} />
							<input type="hidden" id={'hdn-holiday-flg-' + intIdx} value={listData[intIdx].holiday_flg} />

							<div>
								<span>
									{listData[intIdx].calender_day}
								</span>
							</div>
							{listData[intIdx].holiday_flg == '1'?
								<>
									<div><span id={'spn-holiday-1-' + intIdx}>{'祝日'}</span></div>
									<div><span id={'spn-holiday-2-' + intIdx}><br/></span></div>
									<div><span id={'spn-holiday-3-' + intIdx}><br/></span></div>
									<div><span id={'spn-holiday-4-' + intIdx}><br/></span></div>
								</>
								:
								<>
									<div><span id={'spn-holiday-1-' + intIdx}>{'　'}</span></div>
									<div><span id={'spn-holiday-2-' + intIdx}><br/></span></div>
									<div><span id={'spn-holiday-3-' + intIdx}><br/></span></div>
									<div><span id={'spn-holiday-4-' + intIdx}><br/></span></div>
								</>
							}
						</Button>
					}
				</td>
			);

			if(intDay != 7){
				continue;
			}

			lstCalendar.push(
				<tr key={intIdx}>
					{lstDay}
				</tr>
			);

			intDay = 0;
			lstDay = [];
		}


		if(lstCalendar.length > 0){
			return(
				<tbody>
				{lstCalendar}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	return (
		<>
			<Form>
				<div>
					<Row className="holiday-form-row">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>

				<Row className="holiday-value">
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-ago"
							onClick={fncOnClickBefore}
							disabled={loading == '1'? 'disabled' : ''}
						>
							前 月
						</Button>
					</Col>
					<Col md={{ span: 10}}>
						<label className="label-holiday-date">{calendar}</label>
					</Col>
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-next"
							onClick={fncOnClickNext}
							disabled={loading == '1'? 'disabled' : ''}
						>
							次 月
						</Button>
					</Col>
				</Row>
				<Row className="holiday-value">
					<Col md={{ span: 12}}>
						<label className="holiday-message">※ 祝日とする日付をクリックしてください。</label>
					</Col>
				</Row>
				<Row className="holiday-value">
					<Col md={{ span: 12}}>
						<table className={"holiday-calendar"}>
							<thead>
								<tr>
									<th className={"th-holiday color-sunday"}>日</th>
									<th className={"th-holiday"}>月</th>
									<th className={"th-holiday"}>火</th>
									<th className={"th-holiday"}>水</th>
									<th className={"th-holiday"}>木</th>
									<th className={"th-holiday"}>金</th>
									<th className={"th-holiday color-saturday"}>土</th>
								</tr>
							</thead>
							{fncGetHolidayCalendar()}
						</table>
					</Col>
				</Row>

				<Row className="holiday-value">
					<Col md={{ span: 2, offset: 9}}>
					</Col>
					<Col md={{ span: 1 }}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
				</Row>
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>
		</>
	);

}

export default HolidayForm;