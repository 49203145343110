import React, {useEffect, useState} from 'react';

import './DmMng.css';
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import DmMngForm from "./DmMngForm/DmMngForm";
import {fncgSetUrlPram, fncgGetUserLoginCheck, fncgGetDbconn} from "Utils/Common/ComCom";
import {PRM_ID} from "../../../Utils/Common/ComConst";
import {useLocation} from "react-router-dom";

function DmMng({ match, history, location }) {
	const [user, setUser] = useState('');
	const [urlpram, setUrlPram] = useState('');
	const [dbconn, setDbConn] = useState('');
	const [start, setStart] = useState('0');
	const path = useLocation().pathname;
	const handleSetUser = userData => setUser(userData);
	const handleSetUrlPram = dat => setUrlPram(dat);
	const handleSetDbConn = dat => setDbConn(dat);
	const handleSetStart = dat => setStart(dat);

	/**
	 * 初期処理
	 */
	useEffect(() => {
		fncgGetDbconn({history, location, handleSetUrlPram, handleSetDbConn, handleSetStart});
	}, [])

	/**
	 * スタッフ情報取得
	 */
	useEffect(() => {
		if(start == '0') return;
		// ログインチェックを行いユーザ情報を返却する
		fncgGetUserLoginCheck({dbconn, urlpram, path, user, handleSetUser, history})
	}, [start])

	/**
	 * メニュー画面遷移
	 * @param data
	 */
	const onSubmitMoveBackDisp = data => {
		const strPram = fncgSetUrlPram(data);
		history.push('/menu' + strPram);
	}

	return (
		<>
			{(user != "" && user != undefined && dbconn.id != '' && dbconn.id != undefined) &&(
				<Layout headerText="DM管理 -DM送信-" _dbconn={dbconn} _user={user}>
					<DmMngForm
						history={history}
						user={user}
						data={''}
						dbconn={dbconn}
						submitReturn={onSubmitMoveBackDisp}
					/>
				</Layout >
			)}
		</>
	);

}

export default DmMng;