import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../MailTmpMng.css';
import Http from '../../../../Utils/Axios';
import usePost from '../../../../Utils/EndPoints/usePost';
import useHandleFormData from '../../../../Utils/FormHandlers/useHandleFormData';
import CustomModal from '../../../../Utils/Modal/CustomModal';
import { FormInput } from '../../../../Utils/FormHandlers/HandleFormProperties';
import {Form, FormControl, Button, Col, Row, Modal} from 'react-bootstrap';
import {
	MSG_CMP_ADD,
	MSG_CMP_UPD,
	MSG_CMP_DEL,
	MSG_NG_ADD,
	MSG_NG_UPD,
	MSG_NG_DEL,
	APP_TOP,
	MSG_SYSTEM_UPD_BACK,
	MSG_CMP_SEND_MAIL,
	MSG_NG_SEND_MAIL
} from "../../../../Utils/Common/ComConst";
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";

const defaultState = {
	mail_id : "",
	mail_kind : "0",
	mail_subject : "",
	mail_body : "",
	use_stop_flg : "0",
	update_id : "",
	mode : "",
	id : "",
};

const item_max_list = 9;

function MailTmpMngAddForm({ history, user, data, dbconn, submitReturn }) {
	const [fieldData, handleDataFormat, setManualField] = useHandleFormData({ ...defaultState});
	const [defData] = useHandleFormData({ ...defaultState});
	const [kind, setKind] = useState('');
	const [item, setItem] = useState('');
	const [mailtmp, setMailTmp] = useState('');
	const [textflg, setTextFlg] = useState('0');
	const [itemlistflg, setItemListFlg] = useState('0');
	const [itemlistindex, setItemListIndex] = useState(0);

	const [result, submitPost, errors] = usePost();
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [errMode, setErrMode] = useState('0');
	const [loading, setLoading] = useState('0');
	const strPram = fncgSetUrlPram(dbconn);
	const [showTestMailModal, setShowTestMailModal] = useState(false);
	const [isTestMail, setIsTestMail] = useState(false);
	const [shopMail, setShopMail] = useState('');

	/**
	 * ページ離脱イベント
	 * @param e
	 */
	window.onbeforeunload = function(e){
		//--- 確認メッセージ表示(トリガーとしてのみ有効、メッセージは内容は反映されない)
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			e.returnValue = '';
		}
	}

	/**
	 * データ取得
	 */
	useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
		if(data != '' && data != undefined){
			setLoading('1');
			if(data.mode == '1'){
				//-- 新規
				let aryData = {
					id : dbconn.id,
				}
				Http.post('api/init-mail', {
					...aryData
				}).then(response => {
					if (Object.keys(response.data).length > 0) {
						fieldData.mail_kind = response.data.Kind[0]["com_kbn"];
						defData.mail_kind = response.data.Kind[0]["com_kbn"];
						setKind(response.data.Kind);
						setItem(response.data.Item);
						setShopMail(response.data.ShopMail)

						if (Object.keys(response.data.Item).length > 0) {
							if(Object.keys(response.data.Item).length <= item_max_list){
								setItemListFlg('2');
							}
						}
					}
					setLoading('0');
				});
			}else{
				//-- 編集
				data.id = dbconn.id;
				Http.post('api/get-mail', {
					...data
				}).then(response => {
					if (Object.keys(response.data).length > 0) {
						setMailTmp(response.data.MailTmp);
						setKind(response.data.Kind);
						setItem(response.data.Item);
						setShopMail(response.data.ShopMail)

						if (Object.keys(response.data.Item).length > 0) {
							if(Object.keys(response.data.Item).length <= item_max_list){
								setItemListFlg('2');
							}
						}
					}
					setLoading('0');
				});
			}
		}else{
            history.push(APP_TOP + strPram);
        }

	}, [])

	/**
	 * メール種別作成
	 * @returns {*}
	 */
	function fncSetKind(){
		var aryKind = [];
		if(kind == "") return;

		for(var intIdx in kind){
			aryKind.push(
				<option value={kind[intIdx].com_kbn} key={intIdx}>
					{kind[intIdx].com_val1}
				</option>
			);
		}
		if(aryKind.length > 0){
			return(
				<>
					{aryKind}
				</>
			);
		}else{
			return(<option value='' key='0'></option>);
		}
	}

	/**
	 * 項目一覧作成
	 * @returns {*}
	 */
	function fncGetItemList(){
		var lstItem = [];

		for(var intIdx in item){
			//if(intIdx < itemlistindex) continue;
			//if(intIdx >= (itemlistindex + item_max_list)) break;
			lstItem.push(
				<tr key={intIdx}>
					<td className="td-item-no" >
						{Number(intIdx) + 1}
					</td>
					<td className="td-item-sel" >
						<Button
							block
							className="btn_item_sel"
							onClick={fncOnClickSelItem}
							data-id={intIdx}
							disabled={loading == '1'? 'disabled' : ''}
						>
							選 択
						</Button>
						<input type="hidden" id={'hdn-mail-mark-' + intIdx} value={item[intIdx].com_val2}></input>
					</td>
					<td className="td-item-nm" >
						{item[intIdx].com_val1 != null? item[intIdx].com_val1 : ''}
					</td>
					<td className="td-item-mark" >
						{item[intIdx].com_val2 != null? item[intIdx].com_val2 : ''}
					</td>
				</tr>
			);
		}

		if(lstItem.length > 0){
			return(
				<tbody className={"tbody-tmp-mng"}>
					{lstItem}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	/**
	 * メールテンプレート情報設定
	 */
	useEffect(() => {
		if(mailtmp != "" && mailtmp != undefined){
			//--- データ格納
			//-- メールID
			if(mailtmp[0].mail_id != null){
				fieldData.mail_id = mailtmp[0].mail_id;
			}
			//-- メール種別
			if(mailtmp[0].mail_kind != null){
				fieldData.mail_kind = mailtmp[0].mail_kind;
			}
			//-- 件名
			if(mailtmp[0].mail_subject != null){
				fieldData.mail_subject = mailtmp[0].mail_subject;
			}
			//-- 本文
			if(mailtmp[0].mail_body != null){
				fieldData.mail_body = mailtmp[0].mail_body;
			}
			//-- 使用状態
			if(mailtmp[0].use_stop_flg != null){
				fieldData.use_stop_flg = mailtmp[0].use_stop_flg;
			}
			//-- ユーザー
			fieldData.update_id = user.staff_id;
			//-- モード
			fieldData.mode = data.mode;

			defData.mail_kind = mailtmp[0].mail_kind;
			defData.mail_subject = mailtmp[0].mail_subject;
			defData.mail_body = mailtmp[0].mail_body;
			defData.use_stop_flg = mailtmp[0].use_stop_flg;
		}
	}, [mailtmp])

	/**
	 * 件名クリック
	 * @param e
	 */
	function fncOnClickSubject(e){
		setTextFlg('1');
	}

	/**
	 * 本文クリック
	 * @param e
	 */
	function fncOnClickBody(e){
		setTextFlg('2');
	}

	/**
	 * 項目選択ボタン
	 */
	function fncOnClickSelItem(e){
		const intID = e.currentTarget.getAttribute('data-id');
		var strMailMark = document.getElementById('hdn-mail-mark-' + intID).value;
		var objTextArea = document.getElementById('mail_body');
		var objText = document.getElementById('mail_subject');

		if(textflg == '1'){
			insertTextAtCursor(objText, strMailMark, textflg);
		}else if(textflg == '2'){
			insertTextAtCursor(objTextArea, strMailMark, textflg);
		}
	}

	/**
	 * 項目挿入
	 * @param el
	 * @param text
	 */
	function insertTextAtCursor(el, text, flg) {
		var val = el.value, endIndex, range, doc = el.ownerDocument;
		if (typeof el.selectionStart == "number"
			&& typeof el.selectionEnd == "number") {
			endIndex = el.selectionEnd;
			el.value = val.slice(0, endIndex) + text + val.slice(endIndex);
			el.selectionStart = el.selectionEnd = endIndex + text.length;
			if(flg == '1'){
				fieldData.mail_subject = el.value;
			}else if(flg == '2'){
				fieldData.mail_body = el.value;
			}
		} else if (doc.selection != "undefined" && doc.selection.createRange) {
			el.focus();
			range = doc.selection.createRange();
			range.collapse(false);
			range.text = text;
			range.select();
		}
	}

	/**
	 * 項目一覧前へ
	 * @param e
	 */
	function fncOnClickItemBef(e){
		var intIdx = itemlistindex - item_max_list;
		//--- 表示位置判定
		if(intIdx < 0){
			intIdx = 0;
		}
		//--- フラグ判定
		if(intIdx == 0){
			//-- 前ボタン使用不可
			setItemListFlg('0');
		}else{
			setItemListFlg('3');
		}
		setItemListIndex(intIdx);
	}

	/**
	 * 項目一覧次へ
	 * @param e
	 */
	function fncOnClickItemNext(e){
		var intIdx = itemlistindex + item_max_list;
		//--- 表示位置判定
		if(item != '' && item != undefined){
			if(intIdx > item.length - 1){
				intIdx = item.length - 1;
			}
		}else{
			intIdx = 0;
		}
		//--- フラグ判定
		if(intIdx + item_max_list >= item.length - 1){
			setItemListFlg('1');
		}else{
			setItemListFlg('3');
		}
		setItemListIndex(intIdx);
	}

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		//--- 変更チェック
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			if(window.confirm(MSG_SYSTEM_UPD_BACK)){
				//-- 戻る
				submitReturn();
			}
		}else{
			//-- 戻る
			submitReturn();
		}
	}

	/**
	 * 変更項目チェック
	 * @returns {string}
	 */
	function fncChkBackData(){
		if(fieldData.mail_kind != defData.mail_kind ||
			fieldData.mail_subject != defData.mail_subject ||
			fieldData.mail_body != defData.mail_body ||
			fieldData.use_stop_flg != defData.use_stop_flg){
			return '1';
		}
		return '0';
	}

	/**
	 *
	 * 更新ボタン
	 */
	function fncOnClickUpdate(e){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		setLoading('1');
		e.preventDefault();
		//--- データ格納
		fieldData.mode = data.mode;
        fieldData.update_id = user.staff_id;
		fieldData.id = dbconn.id;
		//--- チェック
		submitPost('api/chk-mail',{
			...fieldData,
		});
	}

	const fncOnClickOpenTestMailModal = e => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
		setLoading('1');
		e.preventDefault();
		//--- データ格納
		fieldData.mode = data.mode;
		fieldData.update_id = user.staff_id;
		fieldData.id = dbconn.id;
		//--- チェック
		submitPost('api/chk-mail',{...fieldData});
		setIsTestMail(!isTestMail);
	}

    /**
     * チェック結果エラー
     */
    useEffect(() => {
    	if(errors == undefined) return;
        if (Object.keys(errors).length > 0) {
			window.scrollTo(0, 0);
			setLoading('0');
			setIsTestMail(!isTestMail);
		}
    }, [errors])

    /**
     * チェック結果正常
     */
    useEffect(() => {
		if(dbconn.id == '' || dbconn.id == undefined) return;
		if (isTestMail) {
			updateShowTestMailModal(!showTestMailModal);
			setLoading('0');
			setIsTestMail(!isTestMail);
			return;
		}
        if (Object.keys(result).length > 0) {
        	//--- 登録
            //-- POST
            Http.post('/api/add-mail', {
                ...fieldData,
            }).then(response => {
                const strStatus = response.data.status;
                if(strStatus == '200'){
					setErrMode('0');
                    if(data.mode == '1'){
                        setModalMsg(MSG_CMP_ADD);
                    }else if(data.mode == '2'){
                        setModalMsg(MSG_CMP_UPD);
                    }else if(data.mode == '3'){
                        setModalMsg(MSG_CMP_DEL);
                    }
                }else{
                    setErrMode('1');
                    const strError = response.data.error;
                    if(data.mode == '1'){
                        setModalMsg(MSG_NG_ADD + strError);
                    }else if(data.mode == '2'){
                        setModalMsg(MSG_NG_UPD + strError);
                    }else if(data.mode == '3'){
                        setModalMsg(MSG_NG_DEL + strError);
                    }
                }
                //setShow(true);
            }).catch(error => {
                if (error.response) {
					setErrMode('1');
					var strErrMng = '';
					if(data.mode == '1'){
						strErrMng = MSG_NG_ADD;
					}else if(data.mode == '2'){
						strErrMng = MSG_NG_UPD;
					}else if(data.mode == '3'){
						strErrMng = MSG_NG_DEL;
					}
					strErrMng += error.response.data;
					setModalMsg(strErrMng);
                    //alert(error.response.data)
                }
            }).finally(() => {
				setLoading('0');
				setShow(true);
            })
        }
    }, [result]);

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
        setShow(false);
        if(isTestMail) return setIsTestMail(!isTestMail);
		if(errMode == '0'){
			submitReturn();
		}
	}

	/**
	 * テスト送信モーダルの表示変更
	 */
	const updateShowTestMailModal = bool => setShowTestMailModal(bool);

	/**
	 * テスト送信モーダルの表示変更
	 */
	const updateShopMail = bool => setShopMail(bool);

	/**
	 * テスト送信ボタン押下
	 */
	const fncOnClickSendTestMail = () => {
		setLoading('1');
		Http.post('api/send-test-mail', {
			...fieldData,
			shopMail
		}).then(response => {
			setShowTestMailModal(!showTestMailModal);
			response.data.status === '200'
				? setModalMsg(MSG_CMP_SEND_MAIL)
				: setModalMsg(MSG_NG_SEND_MAIL);
		}).finally(() => {
			setLoading('0');
			setShow(true);
			setIsTestMail(!isTestMail);
		});
	}

	return (
		<>
			<Form className="mail-tmp-mng-form" >
				<div>
					<Row className="mail-tmp-mng-form-row">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<Row className="mail-tmp-mng-form-row">
					<div className="width-mail-label padding-left-15">
						<label>メール種別</label>
					</div>
					<Col md={{ span: 3}}>
						<Form.Control
							as="select"
							className="sel-kind"
							name='mail_kind'
							value={fieldData.mail_kind}
							onChange={handleDataFormat}
							disabled={data.mode != '1'? 'disabled' : ''}
						>
							{fncSetKind()}
						</Form.Control>
					</Col>
					<Col md={{ span: 6}}>
						<label className={"mail-label-msg"}>※会員登録またはパスワード再発行の場合、本文に必ず「会員登録再発行URL(@Url)」を設定してください。</label>
					</Col>
				</Row>
				<Row className="mail-tmp-mng-form-row">
					<div className="width-mail-label padding-left-15">
						<label>件名<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<Col md={{ span: 6}}>
						<FormControl
							className="input-mail-tmp-mng-field"
							type="text"
							value={fieldData.mail_subject}
							name="mail_subject"
							onChange={handleDataFormat}
							onClick={fncOnClickSubject}
							maxLength="100"
							readOnly={data.mode === '3'? 'readOnly' : ''}
							id="mail_subject"
							{...FormInput}
						/>
					</Col>
					<div className="col-md-mail-3">
						<label className={"text-header"}>　▼置換項目</label>
					</div>
				</Row>
				{(errors.mail_subject) && (
					<Row className="mail-tmp-mng-error-row">
						<Col md={{ span: 1}}></Col>
						<Col md={{ span: 6}}>
							<span className="error-span">{errors.mail_subject[0]}</span>
						</Col>
					</Row>
				) }

				<Row className="mail-tmp-mng-body-row">
					<div className="width-mail-label padding-left-15">
						<label>本文<a style={{color:"#FF0000"}}>[必須]</a></label>
					</div>
					<Col md={{ span: 6}}>
						<textarea
							className="input-mail-tmp-mng-field"
							rows="20"
							value={fieldData.mail_body}
							onChange={handleDataFormat}
							onClick={fncOnClickBody}
							name="mail_body"
							maxLength="1000"
							id="mail_body"
							readOnly={data.mode === '3'? 'readOnly' : ''}
						/>
					</Col>
					<div className="col-md-mail-5">
						<table className={"mail-tmp-mng-item"}>
							<thead className={"thead-tmp-mng"}>
								<tr>
									<th className={"th-item-no"}>No</th>
									<th className={"th-item-sel"}>選択</th>
									<th className={"th-item-nm"}>項目名</th>
									<th className={"th-item-mark"}>記号</th>
								</tr>
							</thead>
							{fncGetItemList()}
						</table>
					</div>
				</Row>
				{(errors.mail_body) && (
					<Row className="mail-tmp-mng-error-row">
						<Col md={{ span: 1}}></Col>
						<Col md={{ span: 6}}>
							<span className="error-span">{errors.mail_body[0]}</span>
						</Col>
					</Row>
				) }
				<Row className="mail-tmp-mng-form-row">
					<div className="width-mail-label padding-left-15">
						<label>使用状態</label>
					</div>
					<Col md={{ span: 1}}>
						<label>
							<input
								type="radio"
								className="radio-mail-tmp-mng"
								name="use_stop_flg"
								value="0"
								onChange={handleDataFormat}
								checked={fieldData.use_stop_flg === "0"}
								disabled={data.mode === '3'? 'disabled' : ''}
							/> 使用
						</label>
					</Col>
					<Col md={{ span: 2}}>
						<label>
							<input
								type="radio"
								className="radio-mail-tmp-mng"
								name="use_stop_flg"
								value="1"
								onChange={handleDataFormat}
								checked={fieldData.use_stop_flg === "1"}
								disabled={fieldData.mail_kind == '0' || fieldData.mail_kind == '1' || data.mode === '3'? 'disabled' : ''}
							/> 使用停止
						</label>
					</Col>
				</Row>

				<Row className="mail-tmp-mng-button-container">
					<Col md={{ span: 2, offset: 7 }}>
						<Button
							block
							className="btn-primary"
							onClick={fncOnClickOpenTestMailModal}
							disabled={loading === '1'}
						>
							テスト送信
						</Button>
					</Col>
					<Col md={{ span: 2 }}>
						<Button
							block
							className="btn-save"
							onClick={fncOnClickUpdate}
							disabled={loading == '1'? 'disabled' : ''}
						>
							{data.mode == '1'? '登 録' : data.mode == '2'? '更 新' : '削 除'}
						</Button>
					</Col>
					<Col md={{ span: 1 }}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
				</Row>

				<input type="text" className="dmy-text" name="dummy" />
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>

			<TestMailAddressFormModal
				show={showTestMailModal}
				handleShow={updateShowTestMailModal}
				fncOnSubmit={fncOnClickSendTestMail}
				mail={shopMail}
				handleMail={updateShopMail}
				loading={loading}
			/>
		</>
	);

}

export default MailTmpMngAddForm;

const TestMailAddressFormModal = ({show, handleShow, fncOnSubmit, mail, handleMail, loading}) => {
	const [error, setError] = useState('');

	const fncOnChangeMail = value => {
		emailValidation(value);
		handleMail(value);
		return mail;
	}

	const emailValidation = value => {
		if (!value) return setError('※メールアドレスを入力してください');
		const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (!regex.test(value)) return setError('※正しい形式でメールアドレスを入力してください');
		return setError('');
	}

	return (
		<>
			<Modal show={show} onHide={handleShow}>
				<Modal.Header closeButton>
					<Modal.Title>テスト送信</Modal.Title>
				</Modal.Header>
				<Modal.Body className="mx-auto" style={{width: '90%'}}>
					<Row>
						以下のアドレスにテスト送信します。
					</Row>
					<Row className="pb-0">
						▼送信先メールアドレス（変更可能です）
					</Row>
					<Row className="pt-0">
						<FormControl
							type="email"
							value={mail}
							onChange={e => fncOnChangeMail(e.target.value)}
							name="email"
							disabled={loading === '1'}
							{...FormInput}
						/>
					</Row>
					{error && (
						<Row className="mail-tmp-mng-error-row">
							<div>
								<span className="text-danger">{error}</span>
							</div>
						</Row>
					)}
					<Row>
						よろしければ、「送信」ボタンをクリックしてください。
					</Row>
					<Row className="pt-0">
						※テスト送信では、サンプル情報を件名・本文に挿入して送信します。
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Row className="px-2 py-1 w-100 mx-auto">
						<Col md={{ span: 6 }}>
							<Button
								block
								className="btn-back"
								onClick={() => handleShow(!show)}
								disabled={loading === '1'}
							>
								戻 る
							</Button>
						</Col>
						<Col md={{ span: 6 }}>
							<Button
								block
								className="btn-nomal"
								onClick={fncOnSubmit}
								disabled={error || loading === '1'}
							>
								送 信
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}