// リロードの無限ループ対策として、同条件のバージョン不一致に対して上限を設けます。
// 上限を超えた場合はサーバーから見て正常なバージョンを暫定で投げます。
// ※ 上限時APPを最新にする手段はF5やブラウザ再起動による手動更新のみとなります。
const CHECK_COUNT = 3;
// セッションストレージ用 >>
const KEY_APP_VER = (ver) => {
    return 'app_ver_' + ver + '_' + APP_VER;
} 
const KEY_SERV_APP_VER = 'serv_app_ver';
/** クライアントのアプリケーションバージョン */
export const APP_VER = process.env.REACT_APP_VERSION;

// API連続呼び出し対策
let MessageStack = null;

/**
 * APIへ提出するバージョンを取得します。
 * チェック上限を超えた場合はローカルバージョンでなく、サーバー識別バージョンを返却します。
 */
export function GetRequestVersion() {
    try {
        let serv_ver = sessionStorage.getItem(KEY_SERV_APP_VER);
        let k = KEY_APP_VER(serv_ver);
        let v = Number(sessionStorage.getItem(k));
        if (v >= CHECK_COUNT && serv_ver) return serv_ver;
    } catch(error) {
    }
    return APP_VER;
}

/**
 * APIから返却されたバージョンチェック結果を確認します。
 * @param {response} source レスポンス
 * @returns false=バージョン不一致（チェック上限未満はリロード処理が入ります）
 */
export default function VersionCheck(source) {
    if (!source) return true;
    if (source.status === 409) {
        if (storageCheck(source.data.version)) {
            let msg = source.data.message;
            let href = source.data.href;
            if (!MessageStack) {
                MessageStack = (msg, href) => {
                    // メッセージがあれば標準ダイアログ
                    if (msg) alert(msg.replace(/<br>/g, '\n'));
                    // アンロードイベントの無効化
                    window.onbeforeunload = null;
                    // パス指定があれば移動。無ければ更新。
                    if (href) {
                        window.location.href = href + window.location.search;
                    } else {
                        window.location.reload(true);
                    }
                };
                MessageStack(msg, href);
            }
        }
        return false;
    } else {
        removeStorage();
    }
    return true;
}

/**
 * セッションストレージのチェック上限を確認します。
 * @returns false=チェック上限
 */
function storageCheck(ver) {
    try {
        let k = KEY_APP_VER(ver);
        let v = Number(sessionStorage.getItem(k));
        sessionStorage.setItem(k, v + 1);
        sessionStorage.setItem(KEY_SERV_APP_VER, ver);
        removeStorage(k);
        return v < CHECK_COUNT;
    } catch(error) {
        console.error(error)
        return false;
    }
}

/**
 * バージョンチェックで利用しているストレージを削除します。
 */
function removeStorage(unremovedKey) {
    for (var i = sessionStorage.length - 1; i >= 0; i--) {
        let key = sessionStorage.key(i);
        if (key && key.indexOf('app_ver_') >= 0 && key !== unremovedKey) sessionStorage.removeItem(key);
    }
}