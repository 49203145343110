import React from 'react';
import './NotFound.css';

import Layout from 'layouts/Default/Default';

function NotFound() {
    return (
      <Layout mainContentClass="flex no-padding"> 
        <div className="notfound-background">
            <h1 className="not-found-label"> 404 </h1>
            <span>申し訳ありませんが、お探しのページが見つかりません。</span>
        </div>
      </Layout> 
  );


}

export default NotFound;