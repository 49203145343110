import React from "react";
import DatePicker from "react-datepicker";
import { IoMdTime } from "react-icons/io";
import './TimePicker.css';

function TimePicker(props) {
    let enterd = false;

	function handleBlur(e) {
		let time = document.getElementById(props.id).value;
		// 空の場合何もしない
		if (!time || time.replace(/\s/g, '') === '') return;
		// 全角と時間として認識できない文字を変換・除外
		time = time.replace(/[０-９：]/g, function(s) {
			return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
		}).replace(/[^0-9:]/g, '');

        let h = 0, m = 0, s = 0;
		if (time.indexOf(':') < 0) {
			if (time.length >= 4) {
				//４桁以上は先頭２桁を時として次の２桁を分で変換
				h = time.substr(0, 2);
                m = time.substr(2, 2);
			} else if (time.length === 3) {
				//３桁は先頭１桁を時、次の２桁を分
				h = time.substr(0, 1);
                m = time.substr(1);
			} else {
				//２桁以下は時だけ、0分
				h = time;
			}
		} else {
            var sp = time.split(':');
            h = sp[0];
            m = sp[1];
        }
        // 24h = 0m, 60m = 0mで丸め
        h = h % 24;
        m = m % 60;
        
		// time -> Date変換の兼ね合いで適当な年月日を設定します
		let date = new Date('2000/1/1 ' + h + ':' + m + ':' + s);
		props.onChange(date);

        if (!enterd) return;
        enterd = false;
        // 次の入力へフォーカス（指定なしの場合は下記文字列置換のID）
        let nextID = props.nextFocusID ?? props.id.replace('Start', 'End');
        if (props.id !== nextID && document.getElementById(nextID)) document.getElementById(nextID).focus();
	}
    function handleFocus() {
        document.getElementById(props.id).select();
    }
	function handleIconOnClick(){
		document.getElementById(props.id).focus();
	}
    function handleKeyDown(e) {
        enterd = false;
        if (e.isComposing || e.keyCode === 13) {
            enterd = true;
        }
    }
    return (
        <>
        <div className={props.baseClassName ?? 'div-time-picker'}>
            <DatePicker
                className="date-picker-time"
                showPopperArrow={false}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                showTimeSelect
                showTimeSelectOnly
                timeCaption="時間"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                selected={props.selected}
                onChange={props.onChange}
                timeIntervals={props.timeIntervals ?? 5}
                disabled={props.disabled}
                id={props.id}
                autoComplete="off"
            />
        </div>
        <div className={"div-calendar-icon"}>
            <a onClick={handleIconOnClick}>
                <IoMdTime/>
            </a>
        </div>
        </>
    );
}
export default TimePicker;