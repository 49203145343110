// import React from 'react';
import React, { useState, useEffect } from 'react';


import './Default.css';
import Http from 'Utils/Axios';
import Footer from 'Component/Footer/Footer';
import CardHeader from 'Component/CardHeader/CardHeader';
import Notification from 'Component/Notification/Notification';
import Header from 'Component/Header/Header';
import { Container } from 'react-bootstrap';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';


function Layout(props) { 

	const { history, mainContentClass, _dbconn, _user } = props;
	const mainContentClassName = mainContentClass ? `${mainContentClass} main-content` : 'main-content'
	return (
		<div className="wrapper">
			<Container>	
				<Header history={history} _dbconn={_dbconn} _user={_user}/>
				{props.headerText &&
	 				<CardHeader text={props.headerText} />
				}
				<div className={mainContentClassName}>
					{props.children}
				</div>
				<NotificationContainer/>
			</Container>
			<Footer />
		</div>
	);
}

export default Layout;