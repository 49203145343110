import React from "react";

export const PRM_ID = 'id';
export const PRM_USER_ID = 'user_id';
export const USER_ID = 'user-id';
export const DISP_FG = 'disp-flg';
export const SEARCH_ID = 'search-id';
export const SHIFT_ID = 'shift-id';
export const RESERVE_ID = 'reserve-id';

export const APP_TOP = '/';

export const MSG_CMP_ADD = '登録が完了しました。';
export const MSG_CMP_UPD = '編集が完了しました。';
export const MSG_CMP_DEL = '削除が完了しました。';
export const MSG_CMP_PW_UPD = 'パスワードを設定しました。';

export const MSG_CMP_RES_CAN = '予約のキャンセルが完了しました。';

export const MSG_NG_ADD = '登録に失敗しました。\n';
export const MSG_NG_UPD = '編集に失敗しました。\n';
export const MSG_NG_DEL = '削除に失敗しました。\n';

export const MSG_NG_RES_CAN = '予約のキャンセルに失敗しました。';
export const MSG_NG_NUM = '数字を入力してください。';
export const MSG_NOT_DATA = '該当データが存在しません。';
export const MSG_NG_DUP_DATE = '適用開始日と適用終了日の範囲で日付が重複しています。';
export const MSG_NG_TIME = '時間の入力に誤りがありあます。 \n※From < Toで入力してください。\n※時間1 < 時間2 < 時間3 < 時間4で入力してください。';
export const MSG_NG_DUP_NUMBER = '表示順が重複しています。'
export const MSG_NG_SHOP_WEEK_INPUT = '未設定の曜日別営業時間があります。\n各曜日に営業時間または休日を設定してください。\n(祝日は未設定でも構いません)';

export const MSG_NG_DEL_RESERVE = '予約が既に存在するため、削除できません。';

export const MSG_UPD_SHOP_SALE = '\n登録済みの営業時間(日別)が上書きされています。\n「営業時間管理(日別)」より営業時間を確認してください。';
export const MSG_UPD_STAFF_SHIFT = '\n「シフト」ボタンをクリックして、\n各スタッフのシフト情報を確認してください。';

export const MSG_CMP_SEND_MAIL = 'メールの送信が完了しました。';
export const MSG_NG_SEND_MAIL = 'メールの送信に失敗しました。';

export const MSG_INFO_DATE_INPUT = '※日付は「yyyy/mm/dd」で入力してください。';

export const MSG_GUIDE_SHIMEI_STAFF = '予約時に指定したスタッフを選択します。';
export const MSG_GUIDE_WARIATE_STAFF = '通常は指名スタッフを選択します。「選択なし」に対してスタッフを指定する場合、該当スタッフを選択します。';
export const MSG_GUIDE_TANTO_STAFF = '実際にコースを担当するスタッフを選択します。';
export const MSG_GUIDE_USER_NOTE = '予約時に会員様から入力されたご要望が表示されます。';
export const MSG_GUIDE_STAFF_NOTE = '店舗側でのメモを入力できます。コースにおける注意書きや、　　　　\n担当者間の情報共有などに活用できます。';

export const MSG_RESERVE_STAFF_NON_SHOPTIME = '予約状況が取得できません。表示対象の店舗時間および営業時間が登録されていない可能性があります。';
export const MSG_RESERVE_STAFF_NON_STAFFTIME = '予約状況が取得できません。スタッフのシフト時間が登録されていない可能性があります。シフト時間を確認してください。';
export const MSG_RESERVE_STAFF_HOLIDAY_SHOP = '店舗休業日です。';

export const MST_SHOP_SALE_UPD_SHIFT = 'のシフトが更新されました。\n詳細はスタッフシフト管理-スタッフシフト登録-からご確認ください。';

export const MSG_SYSTEM_BEFORE_UNLOAD = 'ログアウトしますがよろしいですか？';

export const MSG_SYSTEM_UPD_BACK = '内容が変更されています。\n保存せずに戻ってよろしいでしょうか？';

export const MSG_CNF_ALL_SHIFT = '月の全スタッフのシフトが登録されます。\n既に登録済のシフトがある場合でも、各スタッフの曜日別出勤時間で\n上書きされますが、一括シフト登録を行ってよろしいですか？';

export const MSG_INFO_SHOP_SALE_MIDATA = '※未確定データあり(全部または一部)　曜日別営業時間を元に初期値が設定されます。';
export const MSG_INFO_SHOP_SALE_NON_BASE = '※基本営業時間、曜日別営業時間が未設定です。営業時間管理(曜日別)から登録を行ってください。';
export const MSG_INFO_SHOP_SALE_SAVE = '※未確定データが存在する場合、または営業時間を変更した場合は、「登録」ボタンをクリックして営業時間を確定してください。';
export const MSG_INFO_SHOP_SALE_NON_COURSE = '※コースが登録されていません(使用可能なコースがありません)。コース管理からコースを登録してください。';
export const MSG_INFO_SHOP_SALE_NON_SHIFT = '※スタッフのシフトが登録されていません。スタッフ管理からシフトを登録してください。';

export const months = [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月"
];

export const  years = [];
var dtNow = new Date();
for (var i = 1900; i <= dtNow.getFullYear() + 1; i++) {
    years.push(i);
}
export const  pwyears = [];
for (var i = dtNow.getFullYear(); i <= 2999; i++) {
    pwyears.push(i);
}
export const  shopyears = [];
for (var i = 1900; i <= dtNow.getFullYear() + 5; i++) {
    shopyears.push(i);
}