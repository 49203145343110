import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../StaffMng.css';
import Http from '../../../../Utils/Axios';
import useHandleFormData from '../../../../Utils/FormHandlers/useHandleFormData';
import CustomModal from '../../../../Utils/Modal/CustomModal';
import CustomConfModal from 'Utils/Modal/CustomConfModal';
import { FormInput } from '../../../../Utils/FormHandlers/HandleFormProperties';
import {Button, Col, Row, Form, FormControl} from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import {
	MSG_CMP_ADD, MSG_CNF_ALL_SHIFT,
	MSG_NG_ADD, MSG_NG_DUP_NUMBER,
	MSG_NG_NUM,
	MSG_NOT_DATA, MSG_UPD_STAFF_SHIFT, SEARCH_ID,
} from "../../../../Utils/Common/ComConst";
import {fncgSetUrlPram} from "../../../../Utils/Common/ComCom";

const defaultState = {
	staff_id : "",
	staff_nm : "",
	disp_on : "",
	disp_off: "",
	mode : "",
	update_id : "",
	id : "",
	shift_status : "",
}

let NowMonth = '';
let NextMonth = '';
let Next2Month = '';

function StaffMngListForm({ history, user, dbconn, submitMove }) {
	const [listData, setListData] = useState('');
	const [fieldData, handleDataFormat] = useHandleFormData({defaultState});
	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [result, setResult] = useState('');
	const [loading, setLoading] = useState('0');
	const [showConf, setShowConf] = useState(false);
	const strPram = fncgSetUrlPram(dbconn);

	/**
	 * 初期処理
	 */
	useEffect(() => {
		//--- ページ離脱イベントOFF
		window.onbeforeunload = null;

		//--- 翌月、翌々月取得
		let dtNow = new Date();
		NowMonth =  dtNow.getMonth() + 1;
		NextMonth = dtNow.getMonth() + 2;
		Next2Month = dtNow.getMonth() + 3;
		if(NowMonth > 12) NowMonth = NowMonth - 12;
		if(NextMonth > 12) NextMonth = NextMonth - 12;
		if(Next2Month > 12) Next2Month = Next2Month - 12;

		//--- 検索
		fncOnClickSearch();
	}, [])

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		localStorage.setItem(SEARCH_ID, '');
		history.push('/menu' + strPram);
	}

	/**
	 * 検索
	 */
	function fncOnClickSearch(){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		fieldData.id = dbconn.id;
		setLoading('1');

		Http.post('api/search-staff', {
			...fieldData
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setListData(response.data);
				setResult('1');
			}else{
				setListData('');
				setResult('0');
				setModalMsg(MSG_NOT_DATA);
				setShow(true);
			}
			setLoading('0');
		});
	}

	/**
	 * 新規ボタン
	 */
	function fncOnClickAddNew(){
		fieldData.staff_id = '';
		fieldData.mode = '1';
		fieldData.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldData));
		submitMove(fieldData);
	}

	/**
	 * 編集ボタン
	 */
	function fncOnClickStaffMngUpd(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strStaffID = document.getElementById('hdn-staff-id-' + strID ).value;
		fieldData.staff_id = strStaffID;
		fieldData.mode = '2';
		fieldData.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldData));
		submitMove(fieldData);
	}

	/**
	 * 削除ボタン
	 */
	function fncOnClickStaffMngDel(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strStaffID = document.getElementById('hdn-staff-id-' + strID ).value;
		fieldData.staff_id = strStaffID;
		fieldData.mode = '3';
		fieldData.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldData));
		submitMove(fieldData);
	}

	/**
	 * シフトボタン
	 * @param e
	 */
	function fncOnClickStaffMngShift(e){
		const strID = e.currentTarget.getAttribute('data-id');
		var strStaffID = document.getElementById('hdn-staff-id-' + strID ).value;
		var strStaffNm = document.getElementById('hdn-staff-nm-' + strID ).value;
		fieldData.staff_id = strStaffID;
		fieldData.staff_nm = strStaffNm;
		fieldData.mode = '4';
		fieldData.id = dbconn.id;
		localStorage.setItem(SEARCH_ID, JSON.stringify(fieldData));
		submitMove(fieldData);
	}

	/**
	 * 一括シフト登録
	 */
	function fncOnClickAllStaffShiftUpd(){
		if(dbconn.id == '' || dbconn.id == undefined) return;

		setLoading('1');

		//--- シフトステータス取得
		let strShiftStatus = '';
		let objShiftStatus0 = document.getElementById('shift_status0');
		let objShiftStatus1 = document.getElementById('shift_status1');
		let objShiftStatus2 = document.getElementById('shift_status2');
		if(objShiftStatus0.checked == true) {
			strShiftStatus = '0';
		}else if(objShiftStatus1.checked == true){
			strShiftStatus = '1';
		}else{
			strShiftStatus = '2';
		}
		//--- データ格納
		fieldData.id = dbconn.id;
		fieldData.update_id = user.staff_id;
		fieldData.shift_status = strShiftStatus;
        document.body.style.cursor = 'wait';
		//--- 実行
		Http.post('api/updall-staffshift', {
			...fieldData
		}).then(response => {
			const strStatus = response.data.status;
			setResult('0');
			if(strStatus == '200') {
				setResult('1');
				setModalMsg(MSG_CMP_ADD + MSG_UPD_STAFF_SHIFT);
			}else{
				setModalMsg(MSG_NG_ADD);
			}
			setShow(true);
		}).catch(error => {
			setResult('0');
			if (error.response) {
				var strErrMng = '';
				strErrMng = MSG_NG_ADD + error.response.data;
				setModalMsg(strErrMng);
				setShow(true);
			}
		}).finally(() => {
			setLoading('0');
            document.body.style.cursor = 'auto';
		})
	}

	/**
	 * 表示順更新
	 */
	function fncOnClickOrderUpdate(){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		setLoading('1');

		//-- POST
		var aryData = {
			listdata : [],
			id : dbconn.id,
		}
		aryData.listdata.push(listData);

		Http.post('/api/upd-staff-no', {
			...aryData,
		}).then(response => {
			const strStatus = response.data.status;
			setResult('0');
			if(strStatus == '200') {
				setResult('1');
				setModalMsg(MSG_CMP_ADD);
			}else if(strStatus == '300'){
				setModalMsg(MSG_NG_NUM);
			}else if(strStatus == '301'){
				//- 表示順重複
				setModalMsg(MSG_NG_DUP_NUMBER);
			}else{
				setModalMsg(MSG_NG_ADD);
			}
			setShow(true);
		}).catch(error => {
			if (error.response) {
				alert(error.response.data)
			}
		}).finally(() => {
			setLoading('0');
		})
	}

	/**
	 * 検索条件-表示する
	 * @param e
	 */
	function fncChangeDispOn(e){
		if( fieldData.disp_on == '1' ){
			fieldData.disp_on = '0'
		}else{
			fieldData.disp_on = e.target.value;
		}
	}

	/**
	 * 検索条件-表示する
	 * @param e
	 */
	function fncChangeDispOff(e){
		if( fieldData.disp_off == '1' ){
			fieldData.disp_off = '0'
		}else{
			fieldData.disp_off = e.target.value;
		}
	}

	/**
	 * 表示順変更
	 * @param e
	 */
	function fncChangeOrder(e){
		const intID = e.currentTarget.getAttribute('data-id');
		listData[intID].disp_no = e.target.value;
	}

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);
		//--- 再描画
		if(result == '1'){
			setListData('');
			fncOnClickSearch();
		}
	}

	/**
	 * 一括シフト登録確認メッセージ表示
	 */
	function fncShowConfModalShift(){
		let objShiftStatus0 = document.getElementById('shift_status0');
		let objShiftStatus1 = document.getElementById('shift_status1');
		let msgMonth = '';
		if(objShiftStatus0.checked == true) {
			msgMonth = NowMonth;
		}else if(objShiftStatus1.checked == true){
			msgMonth = NextMonth;
		}else{
			msgMonth = Next2Month;
		}
		setModalMsg(msgMonth + MSG_CNF_ALL_SHIFT);
		setShowConf(true);
	}

	/**
	 * モーダル確認画面OK
	 */
	function fncReturnConfModalOK(){
		//--- モーダルクローズ
		setShowConf(false);
		//--- 一括シフト登録
		fncOnClickAllStaffShiftUpd();
	}

	/**
	 * モーダル確認画面クローズ
	 */
	function fncReturnConfModalClose(){
		//--- モーダルクローズ
		setShowConf(false);
	}

	/**
	 * 一覧作成
	 * @returns {*}
	 */
	function fncGetStaffmngList(){
		var lststaffmng = [];

		for(var intIdx in listData){
			var strDispNo = listData[intIdx].disp_no;
			lststaffmng.push(
				<tr key={intIdx}>
					<td className="td-staffmng-no">
						{Number(intIdx) + 1}
					</td>
					<td className="td-staffmng-name">
						{listData[intIdx].staff_nm != null? listData[intIdx].staff_nm : ''}
					</td>
					<td className="td-staffmng-kana">
						{listData[intIdx].staff_kn != null? listData[intIdx].staff_kn : ''}
					</td>
					<td className="td-staffmng-tel">
						{(listData[intIdx].staff_tel != null? listData[intIdx].staff_tel : '')}
					</td>
					<td className="td-staffmng-mail">
						{listData[intIdx].staff_mail}
					</td>
					<td className="td-staffmng-disp">
						{listData[intIdx].disp_flg == '1'? '表示する' : '表示しない'}
					</td>
					<td className="td-staffmng-upd">
						<Button
							block
							className="btn-edit"
							onClick={fncOnClickStaffMngUpd}
							data-id={intIdx}
							disabled={loading == '1'? 'disabled' : ''}
						>
							編 集
						</Button>
						<input type="hidden" id={'hdn-staff-id-' + intIdx} value={listData[intIdx].staff_id}></input>
						<input type="hidden" id={'hdn-staff-nm-' + intIdx} value={listData[intIdx].staff_nm}></input>
					</td>
					<td className="td-staffmng-del">
						{(listData[intIdx].staff_id != '9999999999') ?
							<Button
								block
								className="btn-delete"
								onClick={fncOnClickStaffMngDel}
								data-id={intIdx}
								disabled={loading == '1'? 'disabled' : ''}
							>
								削 除
							</Button>
							:
							<></>
						}
					</td>
					<td className="td-staffmng-shift">
						<Button
							block
							className="btn-new"
							onClick={fncOnClickStaffMngShift}
							data-id={intIdx}
							disabled={loading == '1'? 'disabled' : ''}
						>
							ｼﾌﾄ
						</Button>
					</td>
					<td className="td-staffmng-order">
						<FormControl
							className="input-staff-mng-order"
							type="text"
							defaultValue={strDispNo || ''}
							onChange={fncChangeOrder}
							name={"disp_no"}
							data-id={intIdx}
						/>
					</td>
				</tr>
			);
		}

		if(lststaffmng.length > 0){
			return(
				<tbody>
				{lststaffmng}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	return (
		<>
			<Form>
				<div>
					<Row className="staff-mng-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<div className="text-header first-header">▼検索</div>
				<Row className="staff-mng-value">
					<div className="width-search-label div-staff-mng-search-title-nm">
						<label className="label-staff-mng-title">スタッフ名</label>
					</div>
					<div className={"div-staff-mng-search-nm"}>
						<FormControl
							className="input-staff-mng-nm"
							type="text"
							value={fieldData.staff_nm || ''}
							onChange={handleDataFormat}
							name="staff_nm"
							{...FormInput}
						/>
					</div>
					<div className={"div-staff-mng-search-disp"}>
						<label className={"label-staff-mng-padding"}>
							<input
								className="staff-mng-checkbox"
								type="checkbox"
								value="1"
								onChange={fncChangeDispOn}
								name="disp_on"
							/> 表示する
						</label>
					</div>
					<div className={"div-staff-mng-search-notdisp"}>
						<label className={"label-staff-mng-padding"}>
							<input
								className="staff-mng-checkbox"
								type="checkbox"
								value="1"
								onChange={fncChangeDispOff}
								name="disp_off"
							/> 表示しない
						</label>
					</div>
					<div className={"div-staff-mng-search-search"}>
						<Button
							block
							className="btn-search"
							onClick={fncOnClickSearch}
							disabled={loading == '1'? 'disabled' : ''}
						>
							検 索
						</Button>
					</div>
				</Row>

				<div>
					<Row className="staff-mng-row-header">
						<Col className={"staff-mng-shift-area"} md={{ span: 3}}>
							<label className={"staff-mng-month-padding"}>
								<input
									type="radio"
									className="radio-shift-status"
									name="shift_status"
									id="shift_status0"
									value="0"
									defaultChecked="true"
								/> {NowMonth + '月'}
							</label>
							<label>　</label>
							<label className={"staff-mng-month-padding"}>
								<input
									type="radio"
									className="radio-shift-status"
									name="shift_status"
									id="shift_status1"
									value="1"
								/> {NextMonth + '月'}
							</label>
							<label>　</label>
							<label className={"label-staff-mng-padding"}>
								<input
									type="radio"
									className="radio-shift-status"
									name="shift_status"
									id="shift_status2"
									value="2"
								/> {Next2Month + '月'}
							</label>
						</Col>

						<Col className={"shift-back-area"} md={{ span: 2}}>
							<Button
								block
								className="btn-save"
								title="スタッフ曜日別出勤時間を元に作成します。"
								onClick={fncShowConfModalShift}
								disabled={loading == '1'? 'disabled' : ''}
							>
								一括シフト登録
							</Button>
						</Col>
						<Col className={"shift-marge col-stff-list-4"} md={{ span: 4}}></Col>
						<Col md={{ span: 1}}>
							<Button
								block
								className="btn-new"
								onClick={fncOnClickAddNew}
								disabled={loading == '1'? 'disabled' : ''}
							>
								新 規
							</Button>
						</Col>
						<Col md={{ span: 2}}>
							<Button
								block
								className="btn-edit"
								onClick={fncOnClickOrderUpdate}
								disabled={loading == '1'? 'disabled' : ''}
							>
								表示順更新
							</Button>
						</Col>
					</Row>
				</div>

				<div className="text-header first-header">▼一覧</div>
				<Row className="staff-mng-value">
					<Col md={{ span: 12}}>
						<table className={"staff-mng-list"}>
							<thead>
								<tr>
									<th className={"th-staffmng-0"}>No</th>
									<th className={"th-staffmng-1"}>スタッフ名</th>
									<th className={"th-staffmng-2"}>カナ</th>
									<th className={"th-staffmng-3"}>電話番号</th>
									<th className={"th-staffmng-4"}>メールアドレス</th>
									<th className={"th-staffmng-5"}>表示有無</th>
									<th className={"th-staffmng-6"}>編集</th>
									<th className={"th-staffmng-7"}>削除</th>
									<th className={"th-staffmng-8"}>ｼﾌﾄ</th>
									<th className={"th-staffmng-9"}>表示順</th>
								</tr>
							</thead>
							{fncGetStaffmngList()}
						</table>
					</Col>
				</Row>
				<div>
					<Row className="staff-mng-row-header">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
			</Form>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>

			<CustomConfModal
				show={showConf}
				header={'メッセージ'}
				message={modalMsg}
				handleOK ={fncReturnConfModalOK}
				handleClose={fncReturnConfModalClose}
			/>

		</>
	);

}

export default StaffMngListForm;