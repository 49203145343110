import React, {useEffect, useState} from 'react';

import '../../../../Utils/Common/com-style.css';
import '../StaffShift.css';
import Http from 'Utils/Axios';
import CustomModal from 'Utils/Modal/CustomModal';
import DatePicker from 'react-datepicker';
import TimePicker from "../../../../Utils/FormHandlers/TimePicker";
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { IoIosCalendar, IoMdTime } from "react-icons/io";
import {
	MSG_CMP_ADD,
	MSG_NG_ADD,
	MSG_NG_TIME,
	MSG_SYSTEM_UPD_BACK,
	SEARCH_ID, SHIFT_ID,
	USER_ID,
} from "../../../../Utils/Common/ComConst";

const defaultState = {
	staff_id : "",
	start_date : "",
	id : "",
	update_id : "",
}

const defTime = {
	shift_kbn : '',
	open_time1 : '',
	open_time2 : '',
	open_time3 : '',
	open_time4 : '',
	close_time1 : '',
	close_time2 : '',
	close_time3 : '',
	close_time4 : '',
}

function StaffShiftForm({ history, user, shop, data, dbconn, submitReturn }) {
	const [fieldData, setFieldData] = useState({defaultState});
	const [listData, setListData] = useState('');
	const [calendar, setCalendar] = useState('');
	const [dateSearch, setDateSearch] = useState('');

	const [defdate, setDefDate] = useState('');
	const [showshift, setShowShift] = useState(false);
	const [id, setID] = useState('');
	const [shiftKbn, setShiftKbn] = useState('0');
	const [befFlg, setBefFlg] = useState('0');
	const [nextFlg, setNextFlg] = useState('0');
	const [startTime1, setStartTime1] = useState('');
	const [startTime2, setStartTime2] = useState('');
	const [startTime3, setStartTime3] = useState('');
	const [startTime4, setStartTime4] = useState('');
	const [closeTime1, setCloseTime1] = useState('');
	const [closeTime2, setCloseTime2] = useState('');
	const [closeTime3, setCloseTime3] = useState('');
	const [closeTime4, setCloseTime4] = useState('');

	const [show, setShow] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	const [loading, setLoading] = useState('0');

	const [defData] = useState({defTime});

	/**
	 * ページ離脱イベント
	 * @param e
	 */
	window.onbeforeunload = function(e){
		//--- 確認メッセージ表示(トリガーとしてのみ有効、メッセージは内容は反映されない)
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			e.returnValue = '';
		}
	}

	/**
	 * 初期表示
	 */
	useEffect(() => {
		var objDate = new Date();
		//--- ストレージ取得
		let shiftID = localStorage.getItem(SHIFT_ID);
		if(shiftID != '' && shiftID != undefined){
			shiftID = JSON.parse(shiftID);
			objDate = new Date(shiftID);
		}
		var strDate = objDate.getFullYear() + '/' + (objDate.getMonth() + 1) + '/01';
		var strDateTitle = objDate.getFullYear() + '年' + (objDate.getMonth() + 1) + '月';

		//--- カレンダータイトル
		setCalendar(strDateTitle);

		//--- 検索日
		setDateSearch(objDate);

		//--- 検索
		fncSearchCalendar(strDate);

	}, [])

	/**
	 * 前月
	 */
	function fncOnClickBefore(){
		//--- 前月計算
		dateSearch.setDate(1);
		dateSearch.setMonth(dateSearch.getMonth() - 1);

		//--- フォーマット変換
		var strDate = dateSearch.getFullYear() + '/' + (dateSearch.getMonth() + 1 ) + '/01';
		var strDateTitle = dateSearch.getFullYear() + '年' + (dateSearch.getMonth() + 1 ) + '月';
		var objDate = new Date(strDate);

		//--- 検索条件保存
		localStorage.setItem(SHIFT_ID, JSON.stringify(objDate));

		//--- カレンダータイトル
		setCalendar(strDateTitle);

		//--- 検索日
		setDateSearch(objDate);

		//--- 検索
		fncSearchCalendar(strDate);
	}

	/**
	 * 次月
	 */
	function fncOnClickNext(){
		//--- 次月計算
		dateSearch.setDate(1);
		dateSearch.setMonth(dateSearch.getMonth() + 1);

		//--- フォーマット変換
		var strDate = dateSearch.getFullYear() + '/' + (dateSearch.getMonth() + 1 ) + '/01';
		var strDateTitle = dateSearch.getFullYear() + '年' + (dateSearch.getMonth() + 1 ) + '月';
		var objDate = new Date(strDate);

		//--- 検索条件保存
		localStorage.setItem(SHIFT_ID, JSON.stringify(objDate));

		//--- カレンダータイトル
		setCalendar(strDateTitle);

		//--- 検索日
		setDateSearch(objDate);

		//--- 検索
		fncSearchCalendar(strDate);
	}

	/**
	 * 戻るボタン
	 */
	function fncOnClickBack(){
		localStorage.setItem(SHIFT_ID, "");
		submitReturn(dbconn);
	}

	/**
	 * 更新ボタン
	 */
	function fncOnClickUpdate(){
		if(dbconn.id == '' || dbconn.id == undefined) return;

		setLoading('1');

		//--- データ格納
		var aryData ={
			staff_id: data.staff_id,
			update_id : user.staff_id,
			staffshift : [],
			id : dbconn.id,
			sel_index : id,
		};
		aryData.staffshift.push(listData);

		//-- POST
		Http.post('/api/upd-staffshift', {
			...aryData,
		}).then(response => {
			const strStatus = response.data.status;
			if(strStatus == '200'){
				setModalMsg(MSG_CMP_ADD);
			}else{
				const strError = response.data.error;
				setModalMsg(MSG_NG_ADD + strError);
			}
			//setShow(true);
		}).catch(error => {
			if (error.response) {
				if (error.response) {
					var strErrMng = '';
					strErrMng = MSG_NG_ADD;
					strErrMng += error.response.data;
					setModalMsg(strErrMng);
				}
			}
		}).finally(() => {
			setLoading('0');
			setShow(true);
		})
	}

	/**
	 * 営業時間変更
	 */
	function fncOnClickModalOpen(e){
		//--- 表示データ取得
		var strID = e.currentTarget.getAttribute('data-id');
		var strShiftKbn = document.getElementById('hdn-shift-kbn-' + strID ).value;
		var strOpenTime1 = document.getElementById('hdn-work-start-time1-' + strID ).value;
		var strCloseTime1 = document.getElementById('hdn-work-end-time1-' + strID ).value;
		var strOpenTime2 = document.getElementById('hdn-work-start-time2-' + strID ).value;
		var strCloseTime2 = document.getElementById('hdn-work-end-time2-' + strID ).value;
		var strOpenTime3 = document.getElementById('hdn-work-start-time3-' + strID ).value;
		var strCloseTime3 = document.getElementById('hdn-work-end-time3-' + strID ).value;
		var strOpenTime4 = document.getElementById('hdn-work-start-time4-' + strID ).value;
		var strCloseTime4 = document.getElementById('hdn-work-end-time4-' + strID ).value;

		defData.shift_kbn = strShiftKbn;
		defData.open_time1 = strOpenTime1;
		defData.open_time2 = strOpenTime2;
		defData.open_time3 = strOpenTime3;
		defData.open_time4 = strOpenTime4;
		defData.close_time1 = strCloseTime1;
		defData.close_time2 = strCloseTime2;
		defData.close_time3 = strCloseTime3;
		defData.close_time4 = strCloseTime4;

		//--- ID格納
		setID(strID);

		//--- 現在日付取得
		var dtNow = new Date();
		var strDate = dtNow.getFullYear() + '/' + (dtNow.getMonth() + 1) + '/' + dtNow.getDate() + ' ';

		//--- 表示データ格納
		setShiftKbn(strShiftKbn);
		if(strOpenTime1 != ''){
			setStartTime1(new Date(strDate + strOpenTime1 + ':00'));
		}else{
			setStartTime1('');
		}
		if(strOpenTime2 != ''){
			setStartTime2(new Date(strDate + strOpenTime2 + ':00'));
		}else{
			setStartTime2('');
		}
		if(strOpenTime3 != ''){
			setStartTime3(new Date(strDate + strOpenTime3 + ':00'));
		}else{
			setStartTime3('');
		}
		if(strOpenTime4 != ''){
			setStartTime4(new Date(strDate + strOpenTime4 + ':00'));
		}else{
			setStartTime4('');
		}
		if(strCloseTime1 != ''){
			setCloseTime1(new Date(strDate + strCloseTime1 + ':00'));
		}else{
			setCloseTime1('');
		}
		if(strCloseTime2 != ''){
			setCloseTime2(new Date(strDate + strCloseTime2 + ':00'));
		}else{
			setCloseTime2('');
		}
		if(strCloseTime3 != ''){
			setCloseTime3(new Date(strDate + strCloseTime3 + ':00'));
		}else{
			setCloseTime3('');
		}
		if(strCloseTime4 != ''){
			setCloseTime4(new Date(strDate + strCloseTime4 + ':00'));
		}else{
			setCloseTime4('');
		}

		//--- モーダル画面表示
		setShowShift(true);

	}

	/**
	 * モーダル営業時間設定
	 */
	function fncOnClickModalOk(){
		//--- 時間設定
		fncSetCalenderData();

		//--- データチェック
		//-- POST
		Http.post('/api/chk-staffshift', {
			...listData[id],
		}).then(response => {
			const strStatus = response.data.status;
			if(strStatus == '200'){
				//- データ格納
				setListData(listData);
				//- モーダルクローズ
				setShowShift(false);
				//- 登録
				fncOnClickUpdate();
			}else if(strStatus == '300'){
				setModalMsg(MSG_NG_TIME);
				setShow(true);
			}

		}).catch(error => {
			if (error.response) {
				alert(error.response.data)
			}
		}).finally(() => {

		})
	}

	/**
	 * 休業区分選択
	 * @param e
	 */
	function fncOnChangeHoliday(e){
		setShiftKbn(e.currentTarget.value);
		/*
		if(e.currentTarget.value != '0'){
			setStartTime1(null);
			setStartTime2(null);
			setStartTime3(null);
			setStartTime4(null);
			setCloseTime1(null);
			setCloseTime2(null);
			setCloseTime3(null);
			setCloseTime4(null);
		}
		*/
	}

	/**
	 * モーダルクローズ
	 */
	function fncOnClickShiftModalClose() {
		//--- 変更チェック
		let flgCng = fncChkBackData();
		if(flgCng == '1'){
			if(window.confirm(MSG_SYSTEM_UPD_BACK)){
				//-- 初期に戻す
				listData[id].work_start_time1 = defData.open_time1;
				listData[id].work_end_time1 = defData.close_time1;
				listData[id].work_start_time2 = defData.open_time2;
				listData[id].work_end_time2 = defData.close_time2;
				listData[id].work_start_time3 = defData.open_time3;
				listData[id].work_end_time3 = defData.close_time3;
				listData[id].work_start_time4 = defData.open_time4;
				listData[id].work_end_time4 = defData.close_time4;
				listData[id].shift_kbn = defData.shift_kbn;
				//-- 閉じる
				setShowShift(false);
			}
		}else{
			//-- 閉じる
			setShowShift(false);
		}
	}

	/**
	 * 変更項目チェック
	 * @returns {string}
	 */
	function fncChkBackData(){
		//--- 時間設定
		fncSetCalenderData();

		//--- 判定
		if(listData[id].work_start_time1 != defData.open_time1 ||
			listData[id].work_end_time1 != defData.close_time1 ||
			listData[id].work_start_time2 != defData.open_time2 ||
			listData[id].work_end_time2 != defData.close_time2 ||
			listData[id].work_start_time3 != defData.open_time3 ||
			listData[id].work_end_time3 != defData.close_time3 ||
			listData[id].work_start_time4 != defData.open_time4 ||
			listData[id].work_end_time4 != defData.close_time3 ||
			listData[id].shift_kbn != defData.shift_kbn){
			return '1';
		}
		return '0';
	}

	/**
	 * 日別出勤時間情報設定
	 */
	function fncSetCalenderData(){
		var strHours = '';
		var strMinutes = '';
		//--- 変更内容を反映
		listData[id].shift_kbn = shiftKbn;
		if(startTime1 != '' && startTime1 != null){
			strHours = ('00' + startTime1.getHours()).slice(-2);
			strMinutes = ('00' + startTime1.getMinutes()).slice(-2);
			listData[id].work_start_time1 = strHours + ':' + strMinutes;
		}else{
			listData[id].work_start_time1 = '';
		}
		strHours = '';
		strMinutes = '';
		if(startTime2 != '' && startTime2 != null){
			strHours = ('00' + startTime2.getHours()).slice(-2);
			strMinutes = ('00' + startTime2.getMinutes()).slice(-2);
			listData[id].work_start_time2 = strHours + ':' + strMinutes;
		}else{
			listData[id].work_start_time2 = '';
		}
		strHours = '';
		strMinutes = '';
		if(startTime3 != '' && startTime3 != null){
			strHours = ('00' + startTime3.getHours()).slice(-2);
			strMinutes = ('00' + startTime3.getMinutes()).slice(-2);
			listData[id].work_start_time3 = strHours + ':' + strMinutes;
		}else{
			listData[id].work_start_time3 = '';
		}
		strHours = '';
		strMinutes = '';
		if(startTime4 != '' && startTime4 != null){
			strHours = ('00' + startTime4.getHours()).slice(-2);
			strMinutes = ('00' + startTime4.getMinutes()).slice(-2);
			listData[id].work_start_time4 = strHours + ':' + strMinutes;
		}else{
			listData[id].work_start_time4 = '';
		}
		strHours = '';
		strMinutes = '';
		if(closeTime1 != '' && closeTime1 != null){
			strHours = ('00' + closeTime1.getHours()).slice(-2);
			strMinutes = ('00' + closeTime1.getMinutes()).slice(-2);
			listData[id].work_end_time1 = strHours + ':' + strMinutes;
		}else{
			listData[id].work_end_time1 = '';
		}
		strHours = '';
		strMinutes = '';
		if(closeTime2 != '' && closeTime2 != null){
			strHours = ('00' + closeTime2.getHours()).slice(-2);
			strMinutes = ('00' + closeTime2.getMinutes()).slice(-2);
			listData[id].work_end_time2 = strHours + ':' + strMinutes;
		}else{
			listData[id].work_end_time2 = '';
		}
		strHours = '';
		strMinutes = '';
		if(closeTime3 != '' && closeTime3 != null){
			strHours = ('00' + closeTime3.getHours()).slice(-2);
			strMinutes = ('00' + closeTime3.getMinutes()).slice(-2);
			listData[id].work_end_time3 = strHours + ':' + strMinutes;
		}else{
			listData[id].work_end_time3 = '';
		}
		strHours = '';
		strMinutes = '';
		if(closeTime4 != '' && closeTime4 != null){
			strHours = ('00' + closeTime4.getHours()).slice(-2);
			strMinutes = ('00' + closeTime4.getMinutes()).slice(-2);
			listData[id].work_end_time4 = strHours + ':' + strMinutes;
		}else{
			listData[id].work_end_time4 = '';
		}
	}

	/**
	 * モーダル画面クローズ
	 */
	function fncReturnModalClose(){
		setShow(false);
	}

	/**
	 * 検索
	 */
	function fncSearchCalendar($strDate){
		if(dbconn.id == '' || dbconn.id == undefined) return;
		setLoading('1');

		fieldData.staff_id = data.staff_id;
		fieldData.start_date = $strDate;
		fieldData.id = dbconn.id;
		fieldData.update_id = user.staff_id;

		Http.post('api/get-staffshift', {
			...fieldData
		}).then(response => {
			if (Object.keys(response.data).length > 0) {
				setListData(response.data);
				//setDefDate(response.data[0].def_date_flg);
				setBefFlg(response.data[0].bef_flg);
				setNextFlg(response.data[0].next_flg);
			}else{
				setListData('');
				setBefFlg('0');
				setNextFlg('0');
			}
			setLoading('0');
		});
	}

	/**
	 * 一覧作成
	 * @returns {*}
	 */
	function fncGetStaffShiftCalendar(){
		var lstStaffShift = [];
		var lstDay = [];
		var intDay = 0;
		for(var intIdx in listData){
			intDay += 1;

			lstDay.push(
				<td className="td-staff-shift" key={intIdx}>
					{listData[intIdx].calender_day == ''?
						<></>
						:
						<Button
							block
							className={(listData[intIdx].shift_kbn == '1' || listData[intIdx].shift_kbn == '2')? "btn-staff-shift-day-holiday" : "btn-staff-shift-day"}
							onClick={fncOnClickModalOpen}
							data-id={intIdx}
							disabled={listData[intIdx].def_date_flg == '2' ? 'disabled' : ''}
						>
							<input type="hidden" id={'hdn-calendar-date-' + intIdx} value={listData[intIdx].calendar_date} />
							<input type="hidden" id={'hdn-shift-kbn-' + intIdx} value={(listData[intIdx].shift_kbn != null && listData[intIdx].shift_kbn != '')? listData[intIdx].shift_kbn : '0'} />
							<input type="hidden" id={'hdn-work-start-time1-' + intIdx} value={listData[intIdx].work_start_time1} />
							<input type="hidden" id={'hdn-work-end-time1-' + intIdx} value={listData[intIdx].work_end_time1} />
							<input type="hidden" id={'hdn-work-start-time2-' + intIdx} value={listData[intIdx].work_start_time2} />
							<input type="hidden" id={'hdn-work-end-time2-' + intIdx} value={listData[intIdx].work_end_time2} />
							<input type="hidden" id={'hdn-work-start-time3-' + intIdx} value={listData[intIdx].work_start_time3} />
							<input type="hidden" id={'hdn-work-end-time3-' + intIdx} value={listData[intIdx].work_end_time3} />
							<input type="hidden" id={'hdn-work-start-time4-' + intIdx} value={listData[intIdx].work_start_time4} />
							<input type="hidden" id={'hdn-work-end-time4-' + intIdx} value={listData[intIdx].work_end_time4} />

							<div>
								<span>
									{listData[intIdx].calender_day}
								</span>
							</div>
							{listData[intIdx].shift_kbn == '1'?
								<>
									<div><span >{'休業日'}</span></div>
									<div><span><br/></span></div>
									<div><span><br/></span></div>
									<div><span><br/></span></div>
								</>
								:
								listData[intIdx].shift_kbn == '2'?
									<>
										<div><span >{'店舗休業'}</span></div>
										<div><span><br/></span></div>
										<div><span><br/></span></div>
										<div><span><br/></span></div>
									</>
									:
									<>
										<div>
											<span>
												{listData[intIdx].work_start_time1 == ''? <br/> : listData[intIdx].work_start_time1 + '~' + listData[intIdx].work_end_time1}
											</span>
										</div>
										<div>
											<span>
												{listData[intIdx].work_start_time2 == ''? <br/> : listData[intIdx].work_start_time2 + '~' + listData[intIdx].work_end_time2}
											</span>
										</div>
										<div>
											<span>
												{listData[intIdx].work_start_time3 == ''? <br/> : listData[intIdx].work_start_time3 + '~' + listData[intIdx].work_end_time3}
											</span>
										</div>
										<div>
											<span>
												{listData[intIdx].work_start_time4 == ''? <br/> : listData[intIdx].work_start_time4 + '~' + listData[intIdx].work_end_time4}
											</span>
										</div>
									</>
							}
						</Button>
					}
				</td>
			);

			if(intDay != 7){
				continue;
			}

			lstStaffShift.push(
				<tr key={intIdx}>
					{lstDay}
				</tr>
			);

			intDay = 0;
			lstDay = [];
		}

		if(lstStaffShift.length > 0){
			return(
				<tbody>
				{lstStaffShift}
				</tbody>
			);
		}else{
			return(<tbody></tbody>);
		}
	}

	return (
		<>
			<Form>
				<div>
					<Row className="shop-shift-form-row">
						<Col md={{ span: 1, offset: 11}}>
							<Button
								block
								className="btn-back"
								onClick={fncOnClickBack}
								disabled={loading == '1'? 'disabled' : ''}
							>
								戻 る
							</Button>
						</Col>
					</Row>
				</div>
				<Row className="staff-shift-value">
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-ago"
							onClick={fncOnClickBefore}
							disabled={loading == '1' || befFlg == '0' ? 'disabled' : ''}
						>
							前 月
						</Button>
					</Col>
					<Col md={{ span: 10}}>
						<label className="label-staff-shift-date">{calendar}</label>
					</Col>
					<Col md={{ span: 1}}>
						<Button
							block
							className="btn-next"
							onClick={fncOnClickNext}
							disabled={loading == '1' || nextFlg == '0' ? 'disabled' : ''}
						>
							次 月
						</Button>
					</Col>
				</Row>

				<Row className="staff-shift-value">
					<Col md={{ span: 12}}>
						<label>{data.staff_nm}</label>
					</Col>
				</Row>

				<Row className="staff-shift-value">
					<Col md={{ span: 12}}>
						<table className={"staff-shift-calendar"}>
							<thead>
								<tr>
									<th className={"th-staff-shift color-sunday"}>日</th>
									<th className={"th-staff-shift"}>月</th>
									<th className={"th-staff-shift"}>火</th>
									<th className={"th-staff-shift"}>水</th>
									<th className={"th-staff-shift"}>木</th>
									<th className={"th-staff-shift"}>金</th>
									<th className={"th-staff-shift color-saturday"}>土</th>
								</tr>
							</thead>
							{fncGetStaffShiftCalendar()}
						</table>
					</Col>
				</Row>

				<Row className="staff-shift-value">
					<Col md={{ span: 2, offset: 9}}>
					</Col>
					<Col md={{ span: 1 }}>
						<Button
							block
							className="btn-back"
							onClick={fncOnClickBack}
							disabled={loading == '1'? 'disabled' : ''}
						>
							戻 る
						</Button>
					</Col>
				</Row>
			</Form>

			<Modal show={showshift} onHide={fncOnClickShiftModalClose}>
				<Modal.Header closeButton>
					<Modal.Title>-シフト設定-</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="staff-shift-value">
						<Col md={{ span: 2 }}>
							<label>
								<input
									type="radio"
									className="rdo-shift-kbn"
									name="shift_kbn"
									value="0"
									onChange={fncOnChangeHoliday}
									checked={shiftKbn === "0"}
								/>出 勤
							</label>
						</Col>
						<Col md={{ span: 2 }}>
							<label>
								<input
									type="radio"
									className="rdo-shift-dbn"
									name="shift_kbn"
									value="1"
									onChange={fncOnChangeHoliday}
									checked={shiftKbn === "1"}
								/>休 暇
							</label>
						</Col>
						<Col md={{ span: 3 }}>
							<label>
								<input
									type="radio"
									className="rdo-shift-dbn"
									name="shift_kbn"
									value="2"
									onChange={fncOnChangeHoliday}
									checked={shiftKbn === "2"}
								/>店舗休業
							</label>
						</Col>
					</Row>
					<Row className="staff-shift-value">
						<div className="div-staff-shift-title">
							<label className="label-staff-shift-padding">出勤時間1</label>
						</div>
						<TimePicker
							baseClassName={"div-staff-shift-list-time"}
							selected={startTime1}
							onChange={date => setStartTime1(date)}
							disabled={shiftKbn === '0'? '' : 'disabled'}
							id={"dtStartTime1"}
							nextFocusID={"dtCloseTime1"}
						/>
						<div className="label-staff-shift-reiod">
							<label className="label-staff-shift-padding">～</label>
						</div>
						<TimePicker
							baseClassName={"div-staff-shift-list-time"}
							selected={closeTime1}
							onChange={date => setCloseTime1(date)}
							disabled={shiftKbn === '0'? '' : 'disabled'}
							id={"dtCloseTime1"}
						/>
					</Row>
					<Row className="staff-shift-value">
						<div className="div-staff-shift-title">
							<label className="label-staff-shift-padding">出勤時間2</label>
						</div>
						<TimePicker
							baseClassName={"div-staff-shift-list-time"}
							selected={startTime2}
							onChange={date => setStartTime2(date)}
							disabled={shiftKbn === '0'? '' : 'disabled'}
							id={"dtStartTime2"}
							nextFocusID={"dtCloseTime2"}
						/>
						<div className="label-staff-shift-reiod">
							<label className="label-staff-shift-padding">～</label>
						</div>
						<TimePicker
							baseClassName={"div-staff-shift-list-time"}
							selected={closeTime2}
							onChange={date => setCloseTime2(date)}
							disabled={shiftKbn === '0'? '' : 'disabled'}
							id={"dtCloseTime2"}
						/>
					</Row>
					<Row className="staff-shift-value">
						<div className="div-staff-shift-title">
							<label className="label-staff-shift-padding">出勤時間3</label>
						</div>
						<TimePicker
							baseClassName={"div-staff-shift-list-time"}
							selected={startTime3}
							onChange={date => setStartTime3(date)}
							disabled={shiftKbn === '0'? '' : 'disabled'}
							id={"dtStartTime3"}
							nextFocusID={"dtCloseTime3"}
						/>
						<div className="label-staff-shift-reiod">
							<label className="label-staff-shift-padding">～</label>
						</div>
						<TimePicker
							baseClassName={"div-staff-shift-list-time"}
							selected={closeTime3}
							onChange={date => setCloseTime3(date)}
							disabled={shiftKbn === '0'? '' : 'disabled'}
							id={"dtCloseTime3"}
						/>
					</Row>
					<Row className="staff-shift-value">
						<div className="div-staff-shift-title">
							<label className="label-staff-shift-padding">出勤時間4</label>
						</div>
						<TimePicker
							baseClassName={"div-staff-shift-list-time"}
							selected={startTime4}
							onChange={date => setStartTime4(date)}
							disabled={shiftKbn === '0'? '' : 'disabled'}
							id={"dtStartTime4"}
							nextFocusID={"dtCloseTime4"}
						/>
						<div className="label-staff-shift-reiod">
							<label className="label-staff-shift-padding">～</label>
						</div>
						<TimePicker
							baseClassName={"div-staff-shift-list-time"}
							selected={closeTime4}
							onChange={date => setCloseTime4(date)}
							disabled={shiftKbn === '0'? '' : 'disabled'}
							id={"dtCloseTime4"}
						/>
					</Row>

				</Modal.Body>
				<Modal.Footer>
					<Row className="staff-shift-value">
						<Col md={{ span: 6}}>
							<Button block className="btn-back" onClick={fncOnClickShiftModalClose}>
								戻 る
							</Button>
						</Col>
						<Col md={{ span: 6}}>
							<Button block className="btn-ok" onClick={fncOnClickModalOk}>
								登 録
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>

			<CustomModal
				show={show}
				header={'メッセージ'}
				message={modalMsg}
				handleClose={fncReturnModalClose}
			/>
		</>
	);

}

export default StaffShiftForm;