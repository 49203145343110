import React, {useEffect, useState} from 'react';

import './ToolMng.css';
import Http from 'Utils/Axios';
import Layout from 'layouts/Default/Default';
import ToolMngListForm from "./ToolMngList/ToolMngListForm";
import {fncgGetUserLoginCheck, fncgGetDbconn} from "Utils/Common/ComCom";
import {PRM_ID} from "../../../Utils/Common/ComConst";
import {useLocation} from "react-router-dom";


function ToolMng({ match, history, location }) {
	const [user, setUser] = useState('');
	const [urlpram, setUrlPram] = useState('');
	const [dbconn, setDbConn] = useState('');
	const [start, setStart] = useState('0');
	const path = useLocation().pathname;
	const handleSetUser = userData => setUser(userData);
	const handleSetUrlPram = dat => setUrlPram(dat);
	const handleSetDbConn = dat => setDbConn(dat);
	const handleSetStart = dat => setStart(dat);

	/**
	 * 初期処理
	 */
	useEffect(() => {
		fncgGetDbconn({history, location, handleSetUrlPram, handleSetDbConn, handleSetStart});
	}, [])

	/**
	 * スタッフ情報取得
	 */
	useEffect(() => {
		if(start == '0') return;
		// ログインチェックを行いユーザ情報を返却する
		fncgGetUserLoginCheck({dbconn, urlpram, path, user, handleSetUser, history})
	}, [start])

	return (
		<>
			{user != "" && user != undefined &&(
				<Layout headerText="機材管理 -機材一覧-" _dbconn={dbconn} _user={user}>
					<ToolMngListForm
						history={history}
						user={user}
						dbconn={dbconn}
					/>
				</Layout >
			)}
		</>
	);

}

export default ToolMng;